import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { ActionableCard, Page } from "@/components";
import styles from "./style";
import TaxStatementBanner from "@/assets/banners/tax_statement.jpg";
import SalaryLedgerBanner from "@/assets/banners/salary_ledger.jpg";
import LeaveTypeWiseSummary from "@/assets/banners/leave_type_wise_summary.jpg";
import ResourceAvailabilityBanner from "@/assets/banners/resource_availability.jpg";
import PayrollLeaveData from "@/assets/banners/payroll_leave_data.jpg";
import LoanLedgerBanner from "@/assets/banners/loan_ledger.png";
import Check_in_Check_out_status from "@/assets/banners/Check-in_check-out_status.png";
import Employee_presence_hour_breakup from "@/assets/banners/Employee_presence_hour_breakup.png";
import Daily_attendance_status from "@/assets/banners/Daily_attendance_status.png";
import Employees_present_absent_report from "@/assets/banners/Employees_present_absent_report.png";
import AttendanceDetailedReport from "@/assets/banners/AttendanceDetailedReport.png";
import LeaveBalancesBanner from "@/assets/banners/leave_balances.jpg";
import LeaveStatementBanner from "@/assets/banners/leave_statement.jpg";
import Modules from "@/config/Modules";
import { AccessManager } from "@/helpers/";
import { AuthContext } from "@/contexts";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";

class Reports extends Component {
    static contextType = AuthContext;

    render() {
        const { classes } = this.props;
        return (
            <Page title="Reports">
                <Grid container justify="flex-start" spacing={3}>
                    {
                        AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization) &&
                        <UserRole routePermissions={[ Permissions.TAX, Permissions.SALARY_LEDGER_REPORT ]}>
                            <Grid item xs={12}>
                                <Typography component="div" className={classes.separator}>Payroll Reports</Typography>
                            </Grid>
                        </UserRole>
                    }
                    {
                        AccessManager.isModuleAccess(Modules.TAX, this.context.organization) &&
                        <UserRole routePermissions={[ Permissions.TAX ]}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/tax-statement` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={TaxStatementBanner}
                                        title="Tax Statement"
                                        description="View tax report for all employees in a given period of time"
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                    {
                        AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization) && 
                        <UserRole routePermissions={[ Permissions.SALARY_LEDGER_REPORT ]}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/salary-ledger` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={SalaryLedgerBanner}
                                        title="Salary Ledger"
                                        description="Ledger of salary items for all employees"
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                    {
                        AccessManager.isModuleAccess(Modules.ATTENDANCE, this.context.organization) && 
                        <UserRole routePermissions={[ Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS ]}>
                            <Grid item xs={12}>
                                <Typography component="div" className={classes.separator}>Attendance Reports</Typography>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/daily-attendance-status` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={Daily_attendance_status}
                                        title="Daily Attendance Status"
                                        description="View summary of employees attendance"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/check-in-out-status` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={Check_in_Check_out_status}
                                        title="Early/late check-in/out Status"
                                        description="View employees early/late check-in/out status"
                                    />
                                </Link>
                            </Grid>
                            {/*
                                <Grid item md={4} sm={6} xs={12}>
                                    <Link to={{ pathname: `/reports/presence-hour-breakup` }} style={{ textDecoration: 'none', color: "primary" }}>
                                        <ActionableCard
                                            image={Employee_presence_hour_breakup}
                                            title="Presence Hour Breakup"
                                            description="View employee weekly presence hour breakup"
                                        />
                                    </Link>
                                </Grid>
                            */}
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/monthly-attendance-summary` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={Employees_present_absent_report}
                                        title="Monthly Attendance Summary"
                                        description="View monthly attendance summary for employees"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/employee-attendance-summary` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={AttendanceDetailedReport}
                                        title="Employee Attendance Summary"
                                        description="View employee wise attendance summary for selected duration"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/resource-availablility` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={ResourceAvailabilityBanner}
                                        title="Resource Availablility"
                                        description="Provides a comprehensive view of the team members' availability during a specific time frame"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/leave-balances` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={LeaveBalancesBanner}
                                        title="Leaves Taken & Balance"
                                        description="Detailed leave taken and balance report for each leave type, categorized as paid and unpaid"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                    <Link to={{ pathname: `/reports/leaves-type-wise-summary` }} style={{ textDecoration: 'none', color: "primary" }}>
                                        <ActionableCard
                                            image={LeaveTypeWiseSummary}
                                            title="Leaves Type Wise Summary"
                                            description="View comprehensive leave details of each employee for a particular leave type"
                                        />
                                    </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/payroll-leave-data` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={PayrollLeaveData}
                                        title="Leave Data For Payroll"
                                        description="The leave data for payroll report shows your total working days, payable days, encashed leave and loss of pay if any"
                                    />
                                </Link>
                            </Grid>
                            {
                                AccessManager.isModuleAccess(Modules.LEAVE_ENCASHMENT, this.context.organization) && 
                                <Grid item md={4} sm={6} xs={12}>
                                    <Link to={{ pathname: `/reports/leaves-statement` }} style={{ textDecoration: 'none', color: "primary" }}>
                                        <ActionableCard
                                            image={LeaveStatementBanner}
                                            title="Leaves Statement"
                                            description="Statement for employee leave balances"
                                        />
                                    </Link>
                                </Grid>
                            }
                        </UserRole>
                    }
                    {
                        AccessManager.isModuleAccess(Modules.LOAN, this.context.organization) &&
                        <UserRole routePermissions={[ Permissions.LOAN_FULL_ACCESS ]}>
                            <Grid item xs={12}>
                                <Typography component="div" className={classes.separator}>Loan Reports</Typography>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/reports/loan-ledger` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={LoanLedgerBanner}
                                        title="Loan Ledger"
                                        description="Ledger of loans for all employees"
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                </Grid>
            </Page>
        );
    }

}

export default withRouter(withStyles(styles)(Reports));
