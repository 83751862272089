import React from "react";
import { withRouter } from "react-router-dom";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";

class PayrollLeaveDataRow extends DLTableRow {
	renderCells() {
		const { data, classes } = this.props;
		
		return [
			<DLTableColumn key={`PLD-${data.id}-Column-1`}
				withSeparator={true}
                stickyHeader={true}	>
				<EmployeeAvatar data={{
					employee: {
						id: data.id,
						first_name: data.first_name,
						last_name: data.last_name,
						code: data.code
					}
				}} classes={classes} />
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-2`}
				align={"left"}
				withSeparator={false}>
				{data.payroll_leaves.total_days}
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-4`}
				align={"left"}
				withSeparator={false}>
				{data.payroll_leaves.holidays}
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-5`}
				align={"left"}
				withSeparator={false}>
				{data.payroll_leaves.payable_days}
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-6`}
				align={"left"}
				withSeparator={false}>
				{data.payroll_leaves.encashable}
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-7`}
				align={"left"}
				withSeparator={true}>
				{data.payroll_leaves.loss_of_pay}
			</DLTableColumn>,
			<DLTableColumn
				key={`PLD-${data.id}-Column-3`}
				align={"left"}
				withSeparator={false}>
				{data.payroll_leaves.paid_days}
			</DLTableColumn>
		];
	}
}

PayrollLeaveDataRow.Columns = [
	{
		key: "employee_details",
		label: "Employee",
		align: "left",
		isSortable: false,
		withSeparator: true,
        stickyHeader:true,
	},
	{
		key: "total_days",
		label: "Total Days",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "holidays",
		label: "Holidays",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "payable_days",
		label: "Payable Days",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "encashed_days",
		label: "Encashed Days",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "loss_of_pay",
		label: "Loss Of Pay",
		align: "left",
		isSortable: false,
		withSeparator: true
	},
	{
		key: "paid_days",
		label: "Paid Days",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
];

export default withRouter(withStyles(styles)((PayrollLeaveDataRow)));
