import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
	Button,
	Icon,
	Menu,
	MenuItem,
	CardActions
} from "@material-ui/core";
import { DLTable, BulkAction } from "@/components";
import EmployeePayItemTableRow from "./EmployeePayItemTableRow";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";
import { AuthContext } from "@/contexts";
import UserRole from "@/components/UserRole";
import ModuleManager from "@/components/ModuleManager";
import { Permissions } from "@/config/Permissions";
import AddPayItemModal from "../../pages/PayItemAdd";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import Modules from "@/config/Modules";
import Divider from '@material-ui/core/Divider';


class EmployeePayItems extends Component {
	static contextType = AuthContext;

	constructor (props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();

		this.state = {
			anchorEl: null,
			payItems: {},
			payItemsList: [],
			isModalOpen: false,
		};

		this.getPayItems();
	
	}

	modalClose = () => {
		this.setState({ isModalOpen: false }, this.getPayItems);
	}

	
	getPayItems = async () => {
		try {
			let payItems = await Api.pay_items.get({ per_page: 0, filter: { employee_id: this.props.employee.id } });
			var createNew = payItems.data;
			createNew = payItems.data;
			createNew = [...createNew, { name: "Create New Pay Item", type: "payItem", id: "payItem" }];
			payItems.data = createNew;
			this.setState({ payItems: payItems.data });
		} catch (e) {
			// TODO: Handle exception properly here
		}
	};

	addPayItem = async payItemId => {
		if (payItemId === "payItem") {
			this.setState({ isModalOpen: true, anchorEl: null })

		} else {
			this.setState({ anchorEl: null });
			const { employee } = this.props;
			this.props.setLoader(true);
			try {

				await Api.employees(employee.id).pay_items.post({ pay_item_id: payItemId });
				this.tableRef && this.tableRef.current.fetchData();
				this.props.setSnackbar(true, "New pay item added successfully");
				this.getPayItems();
			} catch (e) {
				HttpErrorHandler.intercept(e)
					.on(422, response => {
						const errors = _.values(_.mapValues(response.data, value => value[0]));
						if (errors && errors.length) {
							this.props.setSnackbar(true, errors[0], "error");
						} else {
							this.props.setSnackbar(true, "Something went wrong, please try again", "error")
						}
					})
					.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
					.go();
			}
			this.props.setLoader(false);
		}
	};


	async deleteItem(selected) {
		const { employee } = this.props;
		this.props.setLoader(true);
		const isNotPermanent = selected.filter(function (selected) {
			return !selected.is_permanent ;
		});
		try {
				await Api.employees(employee.id).pay_items.delete_multiple.delete({  ids: isNotPermanent.map(e => e.id), })
				this.props.setSnackbar(true, "Selected items deleted successfully");
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}


	getItems() {
		let items = [{
				name: "Delete Selected",
				color: "secondary",
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={"Delete Pay Item"}
						message={`Are you sure to delete ${selected.length} pay item${selected.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							await this.deleteItem(selected);
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}];
		return items;
	}
	onSelectionChange = selected => this.actionbarRef.current.setSelected(selected);

	render() {

		const { employee } = this.props;
		const { anchorEl, payItems } = this.state;
	
		const renderMenuItem = (item, index) => {
			return <>
				{item.id === "payItem" && <Divider />}
				<MenuItem
					key={index}
					children={item.name}
					onClick={() => this.addPayItem(item.id)}
				/>
			</>
		}

		return (
			<>
				<ModuleManager modulePermissions={[ Modules.PAYROLL ]}>
					<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
							<CardActions>
								{
									_.size(payItems) > 0 &&
									<Fragment>
										<Button variant="outlined" size="medium"
											aria-owns={anchorEl ? "long-menu" : undefined}
											aria-haspopup="true"
											color="primary"
											onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
										>
											Add Pay Item &nbsp; <Icon>keyboard_arrow_down</Icon>
										</Button>
										<BulkAction
											ref={this.bulkAction}
											options={this.getItems()}
										/>
										<Menu
											id="long-menu"
											anchorEl={anchorEl}
											open={!!anchorEl}
											PaperProps={{ style: { minWidth: 190 } }}
											onClose={() => this.setState({ anchorEl: null })}
											children={_.map(payItems, renderMenuItem)}
										/>
									</Fragment>
								}
							</CardActions>
					</UserRole>
				</ModuleManager>
				{
					this.state.isModalOpen &&
					<AddPayItemModal
						title={"Add Pay Item"}
						onClose={this.modalClose}
						onUpdate={this.getPayItems}	
						test={this.getPayItemList}
 					/>
				}
				<DLTable
					enableSelection
					ref={this.tableRef}
					RowTemplate={EmployeePayItemTableRow}
					api={Api.employees(employee.id).pay_items}
					rowProps={{ employee, getPayItems: this.getPayItems }}
					bulkAction={this.bulkAction}
				/>
			</>
		);
	}
}


const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(EmployeePayItems);
