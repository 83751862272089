import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Tooltip, IconButton } from "@material-ui/core";
import { ArchiveOutlined, UnarchiveOutlined, EditOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";

class BankAccountTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		}
	}

	archive = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.bank_accounts(data.id).delete()
			store.dispatch(setSnackbar(true, `Account ${data.title} (${data.account_number}) archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.bank_accounts(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `Account ${data.title} (${data.account_number}) restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data } = this.props;
		return [
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.bank.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-2`}
				align={"left"}
				withSeparator>
				{data.title}
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-3`}
				align={"left"}
				withSeparator>
				{data.account_number}
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-4`}
				align={"left"}>
				{data.iban}
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-5`}
				align={"left"}>
				{data.swift}
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-6`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				{
					!data.is_archived &&
					<Tooltip title="Edit Bank Account">
						<Link to={{ pathname: `/settings/accounts/${data.id}` }} style={{ textDecoration: 'none', color: "black" }}>

							<IconButton
								onClick={() => this.props.history.push(`/settings/accounts/${data.id}`)}
								size="small"
								color="primary"
							>
								<EditOutlined color="primary" />
							</IconButton>
						</Link>
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`BankAccount-${data.id}-Column-7`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				{
					!data.is_archived ? (
						<>
							<Tooltip title="Archive Bank Account">
								<IconButton
									onClick={() => this.setState({ isModalOpen: true })}
									size="small"
									color="secondary"
								>
									<ArchiveOutlined color="secondary" />
								</IconButton>
							</Tooltip>

							<ConfirmModal 
								title="Archive Bank Account" 
								isOpen={this.state.isModalOpen} 
								message={`Are you sure you want to archive account "${data.title}" (${data.account_number}) of ${data.bank.name}?`} 
								onClickCancel={() => {
									this.setState({ isModalOpen: false });
								}}
								onClickConfirm={this.archive}/>
						</>
					) : (
						<>
							<Tooltip title="Restore Bank Account">
								<IconButton
									onClick={() => this.setState({ isModalOpen: true })}
									size="small"
									color="primary"
								>
									<UnarchiveOutlined color="primary" />
								</IconButton>
							</Tooltip>

							<ConfirmModal 
								title="Restore Bank Account" 
								isOpen={this.state.isModalOpen} 
								message={`Are you sure you want to restore account "${data.title}" (${data.account_number}) of ${data.bank.name}?`} 
								onClickCancel={() => {
									this.setState({ isModalOpen: false });
								}}
								onClickConfirm={this.restore}/>
						</>
					)
				}
			</DLTableColumn>
		]
	}

}

BankAccountTableRow.Columns = [
	{
		key: "bank.name",
		label: "Bank",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "title",
		label: "Account Title",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "account_number",
		label: "Account Number",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "iban",
		label: "IBAN",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "swift",
		label: "Swift Code",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

export default withRouter(BankAccountTableRow);