import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogTitle, DialogActions, Grid, Typography, TextField, DialogContent, Chip } from "@material-ui/core";
import { Stats, Money, FormattedNumericInput, InfographicMessage } from "@/components";
import { Api } from "@/helpers";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import { setList as setPayItems } from "@/redux/actions/payitemActions";
import styles from "./style";
import _ from "lodash";
import { EmployeeAvatar } from "@/components";
import classNames from "classnames";
import { Add, Remove } from "@material-ui/icons";

class LeaveAdjustmentModal extends Component {

    static defaultProps = {
        viewOnly: false,
    };

    state = {
        isLoading: false,
        leaves: {},
        errors: {}
    };

    encashments() {
        const { data } = this.props;
        return data.leave_balances.filter(item => item.is_encashment);
    }

    deductions() {
        const { data } = this.props;
        return data.leave_balances.filter(item => item.is_deduction);
    }

    handleChange(leave_id, year, count) {
        this.setState({
            leaves: {
                ...this.state.leaves,
                [leave_id]: {
                    ...this.state.leaves[leave_id],
                    [year]: !parseInt(count) ? 0 : count
                }
            },
            errors: {
                ...this.state.errors,
                [`${leave_id}_${year}`]: null
            }
        });
    }

    validate() {
        const { leaves, errors } = this.state;
        const { data, setSnackbar } = this.props;
        let validated = true;
        data.leave_balances.forEach(item => {
            if (_.isUndefined(leaves[item.leave_id]) || _.isUndefined(leaves[item.leave_id][item.year])) {
                leaves[item.leave_id] = {
                    ...leaves[item.leave_id],
                    [item.year]: item.adjustment
                };
                
            } else if (!parseInt(leaves[item.leave_id][item.year])) {
                leaves[item.leave_id][item.year] = 0;
            }

            if (leaves[item.leave_id][item.year] < 0 || leaves[item.leave_id][item.year] > Math.abs(item.balance)) {
                errors[`${item.leave_id}_${item.year}`] = `Must be between 0 and ${Math.abs(item.balance)}`;
                validated = false;

            } else if (leaves[item.leave_id][item.year] != parseInt(leaves[item.leave_id][item.year])) {
                errors[`${item.leave_id}_${item.year}`] = `Decimals not allowed`;
                validated = false;
            }
        });

        this.setState({ leaves: { ...leaves }, errors: { ...errors }});
        if (!validated)
            setSnackbar(true, "Please fix the highlighted errors!", "error");

        return validated;
    }

    save = async () => {
        if (!this.validate()) return;
        
        const { leaves } = this.state;
        const { data, onUpdate, onClose } = this.props;
        const leave_item = data.pay_items.find(item => item.details.is_leave);
        const payload = { _method: 'put', leaves };
        
        const not_changed = data.leave_balances.every(item => item.adjustment == leaves[item.leave_id][item.year]);
        if (not_changed) return _.isFunction(onClose) && onClose();

        try {
            this.props.setLoader(true);
            const payrun = await Api.payslips(data.id)
                .payslip_item(leave_item.pay_item_id)
                .post(payload);

            _.isFunction(onUpdate) && onUpdate(payrun);
            _.isFunction(onClose) && onClose();
            this.props.setSnackbar(true, `Leaves updated for ${data.employee.full_name} (${data.employee.code})`);
        } catch (e) {
            this.props.setSnackbar(true, `Unable to update leaves, please try again`, "error");
        } finally {
            this.props.setLoader(false);
        }
    };

    renderCalcRow(title, value, isTotal = false) {
        const { classes } = this.props;
        return (
            <Grid item container alignItems="baseline" className={classNames(classes.calculationRow, (value < 0) && 'sub', isTotal && 'total')}>
                <Grid className={classes.calculationHeading}>{title}</Grid>
                <Grid className={classes.calculationValue}>
                    {
                        isTotal
                        ? <span>=</span>
                        : (
                            (!isTotal && value < 0)
                                ? <Remove fontSize="inherit"/>
                                : <Add fontSize="inherit"/>
                        )
                    }
                    <span>{isTotal ? value : Math.abs(value)}</span></Grid>
            </Grid>
        );
    }

    renderItems(records, singleColumn = false) {
        const { classes, data, viewOnly } = this.props;
        const { errors } = this.state;
        return (
            <Grid className={classNames(classes.fieldListContainer, singleColumn && 'multi')} container xs={12}>
                {
                    records.map(item => {
                        return (
                            <Grid item className={classes.fieldContainer}>
                                <Typography>
                                    <Grid container xs={12} className={classes.calculation}>
                                        <Grid item container className={classes.leaveTitle}>
                                            <span>{item.leave_name} (Year {item.year})</span>
                                        </Grid>
                                        { this.renderCalcRow('Earned Leaves', item.earned) }
                                        { this.renderCalcRow('Availed Leaves', -item.availed) }
                                        {
                                            !!item.settled &&
                                            this.renderCalcRow('Already Adjusted', item.settled)
                                        }
                                        {
                                            !!item.expired &&
                                            this.renderCalcRow('Non-encashable', -item.expired)
                                        }
                                        { this.renderCalcRow('Balance', item.balance, true) }
                                        <Grid item container className={classNames(classes.calculationRow)}>
                                            <Grid className={classes.calculationHeading}>{item.is_encashment ? 'Encashment' : 'Deduction'}</Grid>
                                            <Grid className={classes.calculationField}>
                                                <TextField
                                                    key={`LeaveItem-${item.leave_id}`}
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={data.is_paid || viewOnly || item.balance == 0}
                                                    size={"small"}
                                                    onChange={(e) => {this.handleChange(item.leave_id, item.year, e.currentTarget.value)}}
                                                    onBlur={() => this.validate()}
                                                    defaultValue={item.adjustment}
                                                    error={!!errors[`${item.leave_id}_${item.year}`]}
                                                    helperText={errors[`${item.leave_id}_${item.year}`]}
                                                    FormHelperTextProps={{ variant: "standard" }}
                                                    InputProps={{ inputComponent: FormattedNumericInput }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        );
                    })
                }
            </Grid>
        );
    }

    getAdjustment = item => {
        const { leaves } = this.state;
        if (_.isUndefined(leaves[item.leave_id]) || _.isUndefined(leaves[item.leave_id][item.year])) {
            return parseInt(item.adjustment);
        }
        return parseInt(leaves[item.leave_id][item.year] || 0);
    };

    totalAmount = () => {
        const { data } = this.props;
        return data.leave_balances.reduce((total, leave) => {
            const adjustment = this.getAdjustment(leave) * (leave.is_deduction ? -1 : 1);
            const adjustedLeavds = leave.balance_leaves.reduce((entries, entry) => {
                let remaining = adjustment - _.sumBy(entries, 'quantity');
                if (remaining) {
                    if (Math.abs(entry.quantity) > Math.abs(remaining)) {
                        entry.quantity = remaining;
                        entry.amount = entry.quantity * entry.rate;
                    }
                    entries.push(entry);
                }
                return entries;
            }, []);

            return total + _.sumBy(adjustedLeavds, 'amount');
        }, 0);
    };

    render() {
        const { classes, isOpen, data, viewOnly } = this.props;
        const encashments = this.encashments();
        const deductions  = this.deductions();
        const encashment_count = _.sumBy(encashments, this.getAdjustment);
        const deduction_count = _.sumBy(deductions, this.getAdjustment);
        const totalLeaves = encashment_count - deduction_count;
        const totalAmount = this.totalAmount();
        return (
            <Dialog
                fullWidth
                maxWidth={"sm"}
                open={isOpen}
                aria-labelledby="leave-adjustment-modal-title"
                onClose={this.props.onClose}>
                <DialogTitle id="leave-adjustment-modal-title">
                    <EmployeeAvatar data={data}/>
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <Grid container direction="row">
                        {
                            !!encashments.length &&  
                            <Grid item sm className={classes.column}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.encashmentHeader}>
                                    <Typography variant="h6" color="primary">Encashments</Typography>
                                    <Chip label={encashment_count} size="small" color="primary"/>
                                </Grid>
                                {this.renderItems(encashments, !deductions.length)}
                            </Grid>
                        }
                        {
                            !!deductions.length && 
                            <Grid item sm className={classes.column}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.deductionHeader}>
                                    <Typography variant="h6" color="secondary">Deductions</Typography>
                                    <Chip label={deduction_count} size="small" color="secondary"/>
                                </Grid>
                                {this.renderItems(deductions, !encashments.length)}
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                {
                    !encashments.length && !deductions.length &&
                    <DialogContent>
                        <InfographicMessage
                            illustration="leaves"
                            message="Leave details not available"
                        />
                    </DialogContent>
                }
                <DialogActions className={(!!encashments.length || !!deductions.length) && classes.footer}>
                    {
                        (!!encashments.length || !!deductions.length) &&
                        <div className={classes.statsContainer}>
                            <Stats label="Total Leaves" className={classes.successColor}>
                                {totalLeaves}
                            </Stats>
                            <Stats color={totalAmount > 0 ? 'primary' : 'secondary'}>=</Stats>
                            <Stats label={totalAmount > 0 ? 'Encashment' : 'Deduction'} color={totalAmount > 0 ? 'primary' : 'secondary'}>
                                <Money amount={totalAmount}/>
                                {/* <Money amount={parseFloat((totalAmount / totalLeaves).toFixed(2)) * totalLeaves}/> */}
                            </Stats>
                        </div>
                    }
                    {
                        (!data.is_paid && !viewOnly && (!!encashments.length || !!deductions.length)) ? (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={this.save}>
                                Save
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={this.props.onClose}>
                                Close
                            </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({ payItems: state.payitem.list });

const mapDispatchToProps = (dispatch) => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setPayItems: (...args) => dispatch(setPayItems(...args)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LeaveAdjustmentModal));
