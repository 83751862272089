const styles = theme => {
	const drawerWidth = 300;

	return ({
		infographicMessage: {
			marginBottom: theme.spacing(2)
		},
		header: {
			display: "flex",
			height: "100px",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			backgroundColor: theme.palette.divider
		},

		clock: {
			flex: 1,
			fontSize: "4rem",
			fontWeight: "lighter",
			lineHeight: 1,
			marginRight: theme.spacing(2),
			paddingRight: theme.spacing(2),
			borderRight: `1px ${theme.palette.primary.light} solid`,

			[theme.breakpoints.down("sm")]: {
				fontSize: "2rem",
			}
		},

		dateContainer: {
			marginRight: theme.spacing(2),
			paddingRight: theme.spacing(2),
			borderRight: `1px ${theme.palette.primary.light} solid`
		},

		year: {
			fontSize: "2.5rem",
			fontWeight: "lighter",
			lineHeight: 1,
		},
		amountFont: {
			fontSize: "1.2rem"
		},
		date: {
			fontSize: "0.85rem"
		},

		statsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between"
		},

		stats: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-end",

			"& .MuiTypography-root": {
				fontSize: "3rem",
				lineHeight: 1
			},

			"&.right": {
				marginLeft: theme.spacing(2)
			}
		},

		attendanceWidget: {
			display: "flex",
			flexDirection: "row",
			alignItems: "flex-start"
		},

		attendanceWidgetSheet: {
			flex: 1,
			marginLeft: theme.spacing(2),
			overflow: "auto"
		},

		fab: {
			marginRight: "10px",
		},
		statusPaid: {
			backgroundColor: "blue",
			paddingTop: "10px",
			paddingRight: "10px",
			paddingBottom: "10px",
			paddingLeft: "10px"
		},
		extendedIcon: {
			marginRight: theme.spacing(1),

			[theme.breakpoints.down("sm")]: {
				marginRight: 0
			}
		},

		editFab: {
			width: 32,
			height: 32,
			minHeight: 32,
			marginLeft: theme.spacing(0.5),
			boxShadow: theme.shadows[1]
		},

		editFabIcon: {
			fontSize: "1.2rem"
		},

		extendedText: {
			[theme.breakpoints.down("sm")]: {
				display: "none"
			}
		},

		chip: {
			paddingLeft: "6px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		},

		chipText: {
			fontSize: "0.8125rem",
			lineHeight: "1.43"
		},

		avatar: {
			border: `1px ${theme.palette.borderColor.main} solid`,
			boxShadow: theme.shadows[2]
		},
		employeeItem: {
			padding: 0
		},
		createBtn: {
			margin: "10px"
		},
		extraImage: {
			width: "40px",
			background: theme.palette.extraImage.main,
			height: "40px",
			borderRadius: "6px",
			cursor: "pointer",
			marginLeft: "4px"

		},
		extraImageText: {
			fontSize: "12px",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "bolder"
		},
		reimburseImage: {
			width: "40px",
			height: "40px",
			borderRadius: "6px",
			margin: "0px 2px",
			cursor: "pointer"
		},
		root: {
			display: "flex"
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		menuButton: {
			marginRight: 36
		},
		hide: {
			display: "none"
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap"
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		drawerClose: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: "hidden",
			width: "176px",
			[theme.breakpoints.up("sm")]: {
				width: "176px"
			}
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			// padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		//============================
		sideBarAnimate: {
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			})
		},
		renderDayLabelGridContainer: { minHeight: "60px", display: "grid", flex: 1, borderLeft: "1px solid #DBEAFF" },

		dayStyle: {
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "normal",
			fontSize: "16px",
			color: "#153F6D"
		},
		dateStyle: {
			textTransform: "capitalize",
			fontSize: "12px",
			color: "#666666",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily
		},
		displayFlex: {
			display: "flex"
		},
		positionRelative: { position: "relative" },
		firstRowFirstContainer: {
			borderRight: "1px solid #DBEAFF", position: "relative",
			height: "60px"
		},
		addButtonStyle: {
			right: "-5px",
			zIndex: 200
		},
		drawerContainer: {
			position: "absolute", top: "7px", left: "25px", zIndex: 1
		},
		dateRangeDateContainer: {
			fontSize: "18px",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "500"
		},
		filterGridContainer: { position: "absolute", top: "12px", right: "10px", width: "90px" },
		gridTopBorder: {
			padding: theme.spacing(1),
			borderTop: "1px solid #DBEAFF",
		},
		thirdGridRowContainer: {
			display: "flex", borderBottom: "1px solid #DBEAFF"
		},
		thirdRowGridContainerText: {
			padding: "6px 10px 6px 10px"
		},
		employeeText: {
			fontFamily: theme.typography.fontFamily,
			fontWeight: "500",
			fontSize: "16px",
			lineHeight: "16.63px",
			color: "black"
		},
		outOfHundred: {
			color: "#2A9DFF", fontWeight: "bold",
		},
		shiftContainerArrayDiv: {
			display: "flex", paddingTop: "1px", paddingBottom: "1px"
		},
		shiftContainerTextType: {
			fontSize: "12px",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "400",
			color: "#959595"
		},
		shiftContainerTime: {
			fontSize: "12px",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "800",
			color: "black"
		},
		gridPlusContainer: {
			minHeight: "60px",
			display: "flex",
			flex: 1,
		},
		plusIconButtonStyle: {
			alignItems: "center", display: "flex", flex: 1, padding: "5px", margin: "3px",
			borderRadius: "8px", backgroundColor: `white`, justifyContent: "space-evenly"
		},
		renderItemContainer: {
			display: "flex", flex: 1, padding: "5px", margin: "3px", borderRadius: "8px",
			justifyContent: "space-between",
		},
		popoverStyle: {
			"& .Popover-tipShape": {
				fill: "white",
				stroke: "#D3D3D3",
				strokeDasharray: "0 0 20"
			},		
			zIndex: 1
		},
		rosterItemGridStyle:{
			minHeight: "60px",
            display: "flex",
            flex: 1,

		}



	})
};
export default styles;