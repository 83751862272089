import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import WarningIcon from "@material-ui/icons/Warning";
import PermissionHOC from "@/components/PermissionHOC";
import { Permissions } from "@/config/Permissions";
import styles from "./style";
import moment from "moment";

class PackageAlerts extends React.Component {

	render() {
		const { classes, isSeatAvailable, isGracePeriod, graceEndDate } = this.props;
		return (
			!isSeatAvailable &&
			<Alert icon={<WarningIcon/>} variant="filled" className={classes.alertStyle} severity="warning">
				Your Organization has reached Employee Limit. To upgrade your package please contact at <Link href="mailto:salam@hassanabbasi.com" color="inherit">hello@taknhwa.pk</Link>.
			</Alert>,
			isGracePeriod && this.props.hasPermission([Permissions.BILLING_FULL_ACCESS]) &&
			<Alert icon={<WarningIcon/>} variant="filled" className={classes.alertStyle} severity="warning">
				Please process your invoice to continue using Al-Burhan Attendance, your account will be disabled on {moment(graceEndDate).format("MMMM Do, YYYY")}.
			</Alert>

		);
	}
}

const mapStateToProps = (state) => ({
	isSeatAvailable: state.general.isSeatAvailable,
	isGracePeriod: state.general.isGracePeriod,
	graceEndDate: state.general.graceEndDate
});

export default connect(mapStateToProps, null)(withStyles(styles)(PermissionHOC(PackageAlerts)));
