import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles"
import styles from "./style.js";
import _ from "lodash";
import {
    Button,
    Icon,
    InputAdornment,
    TextField,
    Grid,
    Card,
    CircularProgress,
    Typography

} from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api.js";
import { HttpErrorHandler } from "@/helpers";
import { ShiftChip, InfographicMessage } from "@/components";
import AddShiftModal from "@/pages/ShiftsAdd";


class ShiftsPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            data: [],
            popupOpen: false,
            addShiftModal: false
        }
    }


    async componentDidMount() {
        await this.fetchShifts()
    }

    fetchShifts = async (shouldCall = false) => {
        try {
            var responseData = [];
            if (shouldCall) {
                this.props.fetchShift();
                const response = await Api.shifts.get();
                responseData = response.data
            }
            else {
                const { shiftData = [] } = this.props;
                responseData = shiftData;
            }
            responseData.forEach(element => {
                element.checked = false
            });
            const { employeeData } = this.props;

            employeeData.shifts.forEach((item) => {
                responseData.forEach((subItem) => {
                    if (item.id === subItem.id) {
                        subItem.checked = true;
                    }
                })
            })
            this.setState({ data: responseData, isLoading: false })
        }
        catch (e) {
            console.log(e)
            this.setState({ data: [], isLoading: false })

        }
    }

    search = () => {
        const { data, search } = this.state;
        return _.filter(data, item => {
            return _.includes(`${item.name}`.toLowerCase(), search.toLowerCase())
        })
    }

    submitRooster = async () => {
        const { employeeData, } = this.props;
        var data = this.search();
        data = data.filter(item => item.checked);
        var shifts = []

        data.forEach(item => {
            shifts.push(item.id)
        })

        this.props.setLoader(true);
        //idher rosterID lagani hai
        const { employee_id, date, roster_id } = employeeData;

        try {
            let postObj;
            if (shifts.length) {
                postObj = {
                    employee_id,
                    date,
                    shifts
                }
            } else {
                postObj = {
                    employee_id,
                    date
                }
            }
            await Api.rosters(roster_id).roster_assignments.post(postObj);
            await this.props.getRosterAssignments();
            this.props.setLoader(false);
            this.props.onClose();
        } catch (e) {
            HttpErrorHandler.intercept(e)
                    .on(422, response => {
                        const errors = _.values(_.mapValues(response.data, value => value[0]));
                        if (errors && errors.length) {
                            this.props.setSnackbar(true, errors[0], "error");
                        } else {
                            this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                        }
                    })
                    .on(403, (response) => {
                        this.props.setSnackbar(
                            true,
                            response.message || "Something went wrong, please try again later",
                            "error"
                        )
                    })
                    .go();
            console.log(e);
            this.props.setLoader(false);
            this.props.onClose();
        }

    }

    emptyView() {
        const message = "You don't have any shifts yet, add one now!"
        return (
            <InfographicMessage
                illustration="shifts"
                message={message}
                actionLabel={"Add Shift"}
                actionIcon={"add"}
                actionHandler={() => { this.props.setShiftModal(true) }}
            />
        );
    }

    render() {
        const {
            title,
            classes
        } = this.props;
        const { isLoading } = this.state;
        const data = this.search();
        return (

            <Card raised>
                <Grid xs={12} container style={{ backgroundColor: "white", paddingLeft: "10px" }}>
                    <Grid xs={12} style={{ paddingRight: "10px", paddingTop: "10px" }}>
                        <Typography style={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{title}</Typography>
                        <TextField
                            className={classes.employeeListSearchBox}
                            fullWidth
                            placeholder={"Search"}
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={this.state.search}
                            onChange={e => { this.setState({ search: e.target.value }) }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon>search</Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    {isLoading ? (<div style={{ justifyContent: "center", display: "flex", width: "97%", paddingBottom: "10px" }}><CircularProgress /></div>) : data.length ? (<>
                        {data.map((item) => {
                            return (
                                <Grid key={item.id} item xs={12} sm={12} md={6} style={{ paddingTop: "10px", paddingRight: "10px", width: "5px" }}>
                                    <ShiftChip shift={item} isActive={item.checked} key={item.id} onClick={() => {
                                        var checkedChange = data
                                        checkedChange.forEach(element => {
                                            if (element.id === item.id) {
                                                element.checked = !item.checked;
                                            }
                                        });
                                        this.setState({ data: checkedChange })
                                    }} />
                                </Grid>
                            )
                        })}</>
                    ) : (<div style={{ height: "200px", justifyContent: "center", display: "flex", width: "97%", paddingBottom: "20px" }}>{this.emptyView()}</div>)
                    }
                    {data.length ? (

                        <Grid item xs={12} style={{ padding: "10px 10px 10px 0px" }}>
                            <Button variant="contained" onClick={this.submitRooster} color="primary" size="small" fullWidth >Save</Button>
                        </Grid>) : (null)
                    }
                </Grid>

                {
                    this.props.addShiftModal &&
                    <AddShiftModal
                        avoidRoute={true}
                        onClose={() => { this.props.setShiftModal(false); }}
                        title={"Add Shifts"}
                        onUpdate={async () => {
                            this.props.setShiftModal(false)
                            this.fetchShifts(true)
                        }} />
                }

            </Card>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ShiftsPopup));