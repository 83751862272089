import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField } from "@material-ui/core";
import { EditOutlined, Launch } from "@material-ui/icons";
import { FormattedNumericInput } from "@/components";
import LeaveAdjustmentModal from "@/components/Modals/LeaveAdjustmentModal/index.js";
import LateAdjustmentModal from "@/components/Modals/LateAdjustmentModal/index.js";
import HalfDayAdjustmentModal from "@/components/Modals/HalfDayAdjustmentModal/index.js";
import Config from "@/config";
import styles from "./style.js";
import { bool, func, object } from "prop-types";

class LeaveTextField extends Component {
    
    static propTypes = {
        payItem: object.isRequired,
        payslip: object.isRequired,
        viewOnly: bool,
        onUpdate: func
    }
    
    static defaultProps = {
        viewOnly: false
    };

    state = {
        isLeavesModalOpen: false,
        isLatesModalOpen: false,
        isHalfDayModalOpen: false
    };

    render() {
        const { payslip, payItem, viewOnly, onUpdate } = this.props;
        const Icon = viewOnly ? Launch : EditOutlined;
        return (
            (payItem.is_leave || payItem.is_late || payItem.is_half_day) &&
            <>
                <TextField
                    label={payItem.details.name}
                    variant="outlined"
                    size={"small"}
                    value={Math.abs(payItem.amount) || 0}
                    FormHelperTextProps={{ variant: "standard" }}
                    fullWidth
                    disabled
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">{Config.CURRENCY_SYMBOL}</InputAdornment>),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon
                                    onClick={() => this.setState({
                                        isLatesModalOpen    : payItem.is_late,
                                        isLeavesModalOpen   : payItem.is_leave,
                                        isHalfDayModalOpen  : payItem.is_half_day
                                    })}
                                    style={{cursor: "pointer"}}
                                    color="primary"
                                />
                            </InputAdornment>
                        ),
                        inputComponent: FormattedNumericInput
                    }}
                />
                {
                    this.state.isLeavesModalOpen &&
                    <LeaveAdjustmentModal
                        isOpen={this.state.isLeavesModalOpen}
                        data={payslip}
                        viewOnly={viewOnly}
                        onUpdate={onUpdate}
                        onClose={() => this.setState({ isLeavesModalOpen: false })}
                    />
                }
                {
                    this.state.isLatesModalOpen &&
                    <LateAdjustmentModal
                        isOpen={this.state.isLatesModalOpen}
                        data={payslip}
                        viewOnly={viewOnly}
                        onUpdate={onUpdate}
                        onClose={() => this.setState({ isLatesModalOpen: false })}
                    />
                }
                {
                    this.state.isHalfDayModalOpen &&
                    <HalfDayAdjustmentModal
                        isOpen={this.state.isHalfDayModalOpen}
                        data={payslip}
                        viewOnly={viewOnly}
                        onUpdate={onUpdate}
                        onClose={() => this.setState({ isHalfDayModalOpen: false })}
                    />
                }
            </>
        );
    }
}

export default withStyles(styles)(LeaveTextField);
