import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Icon, Button, Grid, TextField, Typography } from "@material-ui/core";
import { DatePicker, } from "@material-ui/pickers";
import Config from "@/config";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import styles from "./style";
import {setLoader,setSnackbar} from "@/redux/actions/general";
import { DateRange, Stepper, Stats } from "@/components";
import moment from "moment";
import _ from "lodash";

class PayrunPeriod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            from: props.payrun && props.payrun.id ? moment(props.payrun.from) : moment().startOf("month"),
            to: props.payrun && props.payrun.id ? moment(props.payrun.to) : moment().endOf("month"),
            name: props.payrun && props.payrun.id ? props.payrun.name : moment().format("MMMM Y"),
            isTyped: null,
            errors: {}
        };
    }

    isValidData = () => {
        var errors = {}
        if (this.state.name === "") {
            errors.name = "Payrun name field is required";
            this.props.setSnackbar(true, errors.name, "error");

        } else if(this.state.name.length > 20){
            errors.name = "Payrun name can not exceed 20 characters";
            this.props.setSnackbar(true, errors.name, "error");
        }

        const max = this.state.from.clone();
        if (max.startOf("month").add(1, "month").daysInMonth() < this.state.from.date()) {
            max.date(max.daysInMonth());
        } else {
            max.date(this.state.from.date() - 1);
        }
        
        if (this.state.to.isAfter(max, "date")) {
            errors.to = "Payrun duration could not be more than a month";
            this.props.setSnackbar(true, errors.to, "error");
        }

        this.setState({ errors: errors });
        return (_.size(errors) > 0) ? false : true;
    };

    changeDate() {
        const { from, isTyped } = this.state;
        if(!isTyped) {
            this.setState({
                name : moment(from).format("MMMM Y")
            });
        }
    }

    handleChange(value, field) {
        this.setState({
            [field] : value,
            isTyped : value !== ""
        });
    }

    next() {
        const { stepper } = this.props;
        const { from, to, name } = this.state;
        if (this.isValidData()) {
            stepper && stepper.next({ from, to, name });
        }
    }


    headerActions() {
        const { stepper, step, payrun } = this.props;
        return (
            stepper.isCompleted(step) && (!payrun || !payrun.id) &&
            <IconButton onClick={() => stepper.activateStep(step)}><Icon color="primary">edit</Icon></IconButton>
        );
    }

    summary() {
        const { from, to } = this.state;
        const {  classes } = this.props;
        return (
            <>
                <Grid container style={{flexDirection:"column"}}>
                    <Typography color="primary" component="div" className={classes.text}>{this.state.name}</Typography>
                </Grid>
                <Grid container justifyContent="flex-end" style={{flexDirection:"row"}}>
                    <Typography component="div" className={classes.textDate}>{moment(from).format("MMM Do, Y") + "  -"}</Typography>
                    <Typography component="div" className={classes.textDate} style={{marginLeft:4}}>{moment(to).format("MMM Do, Y")}</Typography>
                </Grid>
            </>
        );
    }

    render() {
        const { stepper, payrun, step, isActive, classes, ...otherProps } = this.props;
        const { from, to, name } = this.state;

        return (
            <Stepper.Step
                title="Payroll Period"
                step={step}
                isActive={isActive}
                summary={this.summary()}
                stepper={stepper}
                canEdit={!payrun || !payrun.id}
                {...otherProps}>
                
                <Grid container spacing={2} alignItems="center">
                    <Grid item className={classes.taxReportActions}>
                        <DateRange
                            onChange={(from, to) => this.setState({ from, to }, this.changeDate)}
                            from={from}
                            to={to}
                            isMonth={true}/>
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            inputProps={{
                                maxLength: 20,
                              }}
                            error={!!this.state.errors.name}
                            size={"small"}
                            label="Name your payrun"
                            variant="outlined"
                            value={name || ''}
                            onChange={e => this.handleChange(e.target.value, "name")}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>this.next()}>
                            Next
			            </Button>
                    </Grid>
                </Grid>
            </Stepper.Step>
        )
    }
}
const mapDispatchToProps=dispatch => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null,mapDispatchToProps)(withRouter(withStyles(styles)(PayrunPeriod)));


