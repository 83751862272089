import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker } from '@material-ui/pickers';
import { Card, Chip, Hidden, Typography } from "@material-ui/core";
import _ from "lodash";
import TimeModal from "@/components/Modals/TimeModal";
import { DLTable, Clock } from "@/components";
import Filter from "@/components/Filter";
import AttendanceTableRow from "./AttendanceTableRow";
import { Api } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import moment from "moment";
import styles from "./style";
import FilterData from "./FilterData"
import classNames from "classnames";

class AttandanceList extends Component {

    _attendanceSheet = null;
    constructor (props) {
        super(props);
        this.state = {
            date: moment(),
            attendanceSheet: [],
            stats: {
                prsents: 0,
                absents: 0
            },
            timeModal: {
                title: "",
                initialTime: "",
                isOpen: false,
                onCancel: () => { },
                onDone: () => { }
            },
            filter: null,
            fields: FilterData
        };
        this.getAttendance();
    };

    getAttendance = async (filters) => {
        const { filters: oldFilters } = this.state;
        this.setState({ filters }, () => {
            if (!!oldFilters && this._attendanceSheet) {
                this._attendanceSheet.fetchData(true)
            }
        });

    };

    updateAttendanceLog = async date => {
        date = moment(date);
        this.props.setLoader(true);
        this.setState({ date }, () => {
            if (this._attendanceSheet) {
                this._attendanceSheet.fetchData(true)
            }
        });
        this.props.setLoader(false);
    };

    updateStats = ({ meta: { stats } }) => {
        this.setState({ stats });
    };

    promptTime = (title, initialTime) => {
        return new Promise((resolve, reject) => {
            this.setState({
                timeModal: {
                    isOpen: true,
                    title,
                    initialTime,
                    onCancel: () => {
                        this.setState({ timeModal: { ...this.state.timeModal, isOpen: false } })
                        reject();
                    },
                    onDone: (time) => {
                        this.setState({
                            timeModal: {
                                ...this.state.timeModal,
                                isOpen: false
                            }
                        }, () => {
                            resolve(time);
                        });
                    }
                }
            });
        });
    }

    render() {
        const { date, stats, timeModal, filters, fields } = this.state;
        const { classes } = this.props;
        return (
            <>
                <div className={classes.attendanceWidget}>
                    <Hidden only="xs">
                        <Card>
                            <DatePicker
                                margin="normal"
                                label="Calendar"
                                variant="static"
                                format="dd MMMM, Y"
                                value={date}
                                maxDate={moment()}
                                onChange={this.updateAttendanceLog} />
                        </Card>
                    </Hidden>
                    <Card className={classes.attendanceWidgetSheet} raised>
                        <div className={classes.header}>
                            {/* <Typography className={classes.dateContainer} component="div">
                                <div className={classes.year}>{date.format('Y')}</div>
                                <div className={classes.date}>{date.format('MMMM Do')}</div>
                            </Typography> */}
                            <Clock classes={{ clock: classes.clock }} component="div" color="secondary" />
                            <Typography className={classes.statsContainer} component="div">
                                <div className={classes.stats}>
                                    <Chip size="small" label="Presents" style={{backgroundColor:"#00D454", color:"white"}} />
                                    <Typography component="div" style={{color:"#00D454"}}>{stats.presents || 0}</Typography>
                                </div>
                                <div className={classNames([classes.stats, "right"])}>
                                    <Chip size="small" label="Absents" style={{backgroundColor:"#281B1B", color:"white"}} />
                                    <Typography component="div" style={{color:"#281B1B"}}>{stats.absents || 0}</Typography>
                                </div>
                            </Typography>
                        </div>
                        <div className={classes.filter}>
                            <Filter
                                fields={fields}
                                filters={filters || []}
                                withQueryParams
                                onUpdate={this.getAttendance}
                            />
                        </div>
                        {
                            !!filters &&
                            <DLTable
                            ref={table => this._attendanceSheet = table}
                            api={Api.attendance}
                                params={{ date: date.format("YYYY-MM-DD"), filter: filters }}
                                onLoad={this.updateStats}
                                RowTemplate={AttendanceTableRow}
                                rowProps={{ date, getTime: this.promptTime }}
                                hideHeader
                            />
                        }
                        <TimeModal
                            title={timeModal.title}
                            isOpen={timeModal.isOpen}
                            onCancel={timeModal.onCancel}
                            onDone={timeModal.onDone}
                            initialTime={moment(timeModal.initialTime)}
                        />
                    </Card>
                </div>
            </>
            );
    }

}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(AttandanceList));