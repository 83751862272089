import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import {setSnackbar} from '@/redux/actions/general';
import styles from "./style";

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
};

class CustomizedSnackbar extends React.Component {
  
	// function binding 
	handleClose = () => {
		this.props.dispatch(setSnackbar(false, "", this.props.variant))
	};

	render() {
		const { visible, classes, className, message, onClose, variant, dispatch, ...other } = this.props;
		const Icon = variantIcon[variant];
		return (        
			<Snackbar
				anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
				}}
				open={visible}
				autoHideDuration={6000}
				onClose={this.handleClose}
			>
				<SnackbarContent
					className={classNames(classes[variant], className)}
					aria-describedby="client-snackbar"
					message={
						<span id="client-snackbar" className={classes.message}>
							<Icon className={classNames(classes.icon, classes.iconVariant)} />
							{message}
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={this.handleClose}
						>
							<CloseIcon className={classes.icon} />
						</IconButton>,
					]}
					{...other}
				/>
			</Snackbar>
		);
	}
}

const mapStateToProps = (state) => ({
	visible: state.general.snackbar.visible,
	onClose: state.general.snackbar.onClose,
	message: state.general.snackbar.message,
	variant: state.general.snackbar.variant
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: func => dispatch(func)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomizedSnackbar));
