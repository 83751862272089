var authVariables = {
	token: {
        access_token: null,
        access_expiry: 0
    }
};

export default function (state = authVariables, action) {
	switch (action.type) {
		case 'SET_TOKEN':
			return state = {
				...state,
				token: action.token
			}

		case 'RESET_TOKEN':
			return state = {
				...state,
				token: authVariables.token
			}

				
		default:
			return state;
	}
}