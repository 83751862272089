const styles = theme => ({

    root: {
        "&:not(:last-child)": {
            marginRight: theme.spacing(2)
        }
    },

    value: {
        fontSize: 24,
        fontWeight: "bold"
    },

    label: {
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "uppercase"
    }

});

export default styles;