import Constants from "./Constants";
import Env from "@/helpers/Env";
import PaymentMethod from "./PaymentMethod";
import Permissions from "./Permissions";
import Modules from "./Modules";

export default {

	appURL: Env.select({
		production: "https://attendance.seeratcircles.com/",
        staging: "https://attendance.seeratcircles.com/",
        development: "https://attendance.seeratcircles.com/"
	}),

    apiEndpoint: Env.select({
        production: "https://api.attendance.seeratcircles.com/v1/",
        staging: "https://api.attendance.seeratcircles.com/v1/", 
        development: "https://api.attendance.seeratcircles.com/v1/"
    }),
    
    DISPLAY_DATE_FORMAT: "dd-MMM-yyyy",
    DISPLAY_DATE_FORMAT_LABEL: "D-MMM-Y",
    SERVER_DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
    SERVER_DATE_FORMAT: "YYYY-MM-DD",
    
    CURRENCY_SYMBOL: 'Rs',
    PERCENTAGE_SYMBOL: '%',
    
};

export {
	Constants,
    PaymentMethod,
    Permissions,
    Modules
};
