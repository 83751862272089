import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bool, func, object } from "prop-types";
import {
    InputAdornment,
    TextField,
    Grid,
    CircularProgress,
    IconButton,
    Typography
} from "@material-ui/core";
import { DeleteOutline as DeleteIcon, AddCircle as AddIcon, Warning as WarningIcon } from "@material-ui/icons";
import { Money,FormattedNumericInput, ReimbursementTextField, LoanTextField, LeaveTextField } from "@/components";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import styles from "./style";
import { setSnackbar } from "@/redux/actions/general";
import _ from "lodash";

class PayslipItemTextField extends Component {
    
    static propTypes = {
        payItem: object.isRequired,
        payslip: object.isRequired,
        viewOnly: bool,
        onUpdate: func
    };

    static defaultProps = {
        viewOnly: false
    };

    state = {
        loading: false
    };

    handleChange = (field, value, item) => {
        if (this.props.payslip.isPaid || this.props.viewOnly) return;
        // check if it is not number e.g. - sign if not then parseFloat it else skip parseFloat
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value);
        }
        if (field === "amount") {
            field = "rate";
            value = Math.round(value / item.quantity);
        }
        this.setState({
            item: {
                ...item,
                [field]: value,
            },
        });
    };

    update = async (field, value, item) => {
        if (this.props.payslip.isPaid || this.props.viewOnly) return;

        const { onUpdate } = this.props;
        this.handleChange(field, value, item);
        try {
            const payrun = await Api.payslips(this.props.payslip.id)
                .payslip_item(item.pay_item_id)
                .post({
                    rate:this.state.item.rate,
                    _method: "put"
                });

            _.isFunction(onUpdate) && onUpdate(payrun);
        } catch (e) {
            this.props.setSnackbar(
                true,
                `Unable to update ${field} for ${item.details.name}, please try again`,
                "error"
            );
        }
    };

    addItem = async (item, type) => {
        const { payslip, setSnackbar, onUpdate } = this.props;
        this.setState({loading: true})
        try {
            const payrun = await Api.payslips(payslip.id).payslip_item.post({
                pay_item_id: item.details.id,
                loan_request_id: item.is_loan ? item.transaction.id : undefined,
                reimbursement_id: item.is_reimbursable ? item.transaction.id : undefined
            });
            setSnackbar(true, `${item.details.name} added to the payslip of ${payslip.employee.full_name}`);
            _.isFunction(onUpdate) && onUpdate(payrun);
           
        } catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                    setSnackbar(true, errors[0], "error");
                } else {
                   setSnackbar(true, "Something went wrong, please try again", "error");
                }
            })
            .default(() => setSnackbar(true,"Something went wrong, please try again","error"))
            .go();
        }
        this.setState({loading: false})
    };
    
    removeItem = async item => {
        const { setSnackbar, onUpdate, payslip } = this.props;
        this.setState({loading: true})
        try {
            let payrun = null;
            payrun = await Api.payslips(payslip.id)
                .payslip_item(item.pay_item_id)
                .delete({
                    loan_request_id: item.is_loan ? item.transaction.loan_request_id :  undefined,
                    reimbursement_id: item.is_reimbursable ? item.transaction.id : undefined
                });
            setSnackbar(true, `${item.details.name} removed from the payslip of ${payslip.employee.full_name}`);
            _.isFunction(onUpdate) && onUpdate(payrun);
           
        } catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                    this.props.setSnackbar(true, errors[0], "error");
                } else {
                    this.props.setSnackbar(true, "Something went wrong, please try again", "error");
                }
            })
                .default(() => this.props.setSnackbar(true,"Something went wrong, please try again","error"))
                .go();
        }
        this.setState({loading: false})
    };

    render() {
        const {payItem, payslip, classes, isReminder, viewOnly, onUpdate} = this.props;
        const {loading} = this.state;
        const isProRated = payItem.quantity < 1 && payItem.quantity > 0;
        const amount = parseFloat((payItem.rate * payItem.quantity).toFixed(2));
        
        return(
            <>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        {
                            payItem.is_reimbursable &&
                            <ReimbursementTextField payItem={payItem}/>
                        }
                        {
                            payItem.is_loan &&
                            <LoanTextField payItem={payItem} showSign={isReminder} viewOnly={payslip.is_paid || viewOnly || isReminder} onUpdate={onUpdate}/>
                        }
                        {
                            (payItem.is_leave || payItem.is_late || payItem.is_half_day) &&
                            <LeaveTextField payslip={payslip} payItem={payItem} viewOnly={viewOnly} onUpdate={onUpdate}/>
                        }
                        {
                            !payItem.is_reimbursable &&
                            !payItem.is_loan &&
                            !payItem.is_leave &&
                            !payItem.is_late &&
                            !payItem.is_half_day &&
                            <TextField
                                fullWidth
                                label={`${payItem.details.name} ${payItem.details.is_overtime ? `(${payItem.quantity}hr${payItem.quantity == 1 ? '' : 's'})` : ''}`}
                                disabled={payItem.is_fixed_rate || payItem.is_auto_calculated || payslip.is_paid || viewOnly || isReminder}
                                variant="outlined"
                                size={"small"}
                                onChange={(e) => { this.handleChange("amount", e.currentTarget.value, payItem)}}
                                onBlur={(e) => this.update("amount", e.currentTarget.value.replace(/[^0-9.-]/,"" ), payItem)}
                                defaultValue={amount}
                                FormHelperTextProps={{ variant: "standard"}}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">{Config.CURRENCY_SYMBOL}</InputAdornment>),
                                    inputComponent: FormattedNumericInput
                                }}
                            />
                        }
                        {
                            isProRated &&
                            <div className={classes.proRateContainer} >
                                <WarningIcon className={classes.warningIcon}/>
                                <div style={{ margin:"0px 0px 0px 5px", display:"flex", flexDirection:"row"}}>
                                    <Typography style={{ fontSize:"70%", fontFamily:"Roboto" }}> 
                                        Prorated adjustment <Money amount={payItem.amount - payItem.rate || (payItem.details && payItem.amount - payItem.details.rate)} />
                                    </Typography>
                                </div>
                            </div>
                        }
                    </Grid>
                    {
                        !payItem.is_permanent &&
                        !payslip.is_paid &&
                        <Grid item xs="auto">
                            {
                                loading ? (
                                    <CircularProgress size={25}/>
                                ) : (
                                    isReminder ? (
                                        <IconButton size="small" onClick={() => this.addItem(payItem)}>
                                            <AddIcon color="primary"/>
                                        </IconButton>
                                    ) : (
                                        <IconButton size="small" onClick={() => this.removeItem(payItem)}>
                                            <DeleteIcon color="secondary"/>
                                        </IconButton>
                                    )
                                )
                            }
                        </Grid>
                    }
                </Grid>
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PayslipItemTextField)))