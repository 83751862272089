import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles"
import styles from "./style.js";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import moment from "moment";
import { ShiftsDropdown, } from "@/components";
import Config from "@/config";
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";

class ShiftsModal extends Component {

    static propTypes = {
        employee: PropTypes.object.isRequired,
        mode: PropTypes.oneOf(["new", "edit", "exit"]),
        employeeShifts: PropTypes.object,
        title: PropTypes.string,
        value: PropTypes.number,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onUpdate: PropTypes.func
    };

    static defaultProps = {
        mode: "new",
        title: "New Shift",
        onUpdate: () => { }
    };
    
    state = {
        errors: {},
        employeeShifts: {},
        start_date: "",
        shift_id: null,
        employeeIds: [],
    };

    validate() {
		const employeeShifts = this.employeeShifts();
		const optionalFields = [
			"shift","end_date", "flags"
		];

		var errors = {};
		_.forOwn(employeeShifts, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});
		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });
		return !_.size(errors);
	}
    employeeShifts() {
        return {
            shift: "",
            shift_id: "",
            start_date: "",
            ...this.props.shifts,
            ...this.state.employeeShifts
        };
    }

    componentDidMount() {
        if(this.props.mode == "bulk"){
            this.assignShifts();
        }
    }

    assignShifts = async () => {
        const { data } = this.props;
        var { employeeIds } = this.state;
        const empIds = data.map(employee => employee.id);
        this.setState({
            employeeIds: [
                ...employeeIds,
                ...empIds
            ]
        });
    };


    handleChange = (value, fieldName) => {
        const employeeShifts = this.employeeShifts();
        const { errors } = this.state;
        if (errors[fieldName]) {
            errors[fieldName] = false;
        }

        this.setState({
            employeeShifts: {
                ...employeeShifts,
                [fieldName]: value
            },
            errors
        });
    }


    prepareData = () => {
        let employeeShifts = this.employeeShifts();
        let data = {
            shift_id: employeeShifts.shift_id ,
        }
        if (employeeShifts.start_date) {
            data.start_date = moment(employeeShifts.start_date ).format(Config.SERVER_DATE_FORMAT);
        }
        return data;
    }

    save = async (e) => {
        e.preventDefault();
        var data = this.prepareData();

        if(!this.validate())return
      
        this.props.setLoader(true);
        var { employeeIds, shift_id, start_date } = this.state;
        const { shifts, employee: employee_id, mode } = this.props;
        try {
            if(this.props.mode == "bulk"){
                await Api.employees.storeMultiple.patch({employee_ids: employeeIds,shift_id: data.shift_id,start_date: moment(data.start_date).format(Config.SERVER_DATE_FORMAT)});
            }
            else if (!shifts) {
                await Api.employees(employee_id).shifts.post(data);
            }
            else
            {
                await Api.employees(employee_id).shifts(shifts.id).put(data);
            }
            this.props.setSnackbar(true, "Shift saved successfully");
            this.props.onUpdate();
            this.props.onClose();

        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    this.setState({ errors: _.mapValues(response.data, value => value[0]) })
                    this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
                })
                .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
                .go();
        }
        this.props.setLoader(false);
    };

    render() {
        const { title, mode, fixedDate = false } = this.props;
        const { errors } = this.state;
        const employeeShifts = this.employeeShifts();
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={mode}
				onBackdropClick={this.props.onClose}
                aria-labelledby="employee-shifts-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="employee-shifts-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ShiftsDropdown
                                filter={{ shifts: true }}
                                value={ employeeShifts.shift_id || ""}
                                onChange={shift_id => {
                                    if (shift_id) {
                                        this.handleChange(shift_id, "shift_id")
                                    }
                                    else {
                                        this.handleChange("", "shift_id")
                                    }
                                }}
                                textFieldProps={{
                                    size: "small",
                                    error: !!errors.shift_id,
                                    helperText: errors.shift_id
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                autoOk
                                fullWidth
                                size="small"
                                disabled={fixedDate}
                                error={!!errors.start_date}
                                helperText={errors.start_date}
                                label="Start Date"
                                inputVariant="outlined"
                                format={Config.DISPLAY_DATE_FORMAT}
                                value={employeeShifts.start_date ||null}
                                onChange={start_date => this.handleChange(start_date, "start_date")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="default" size="small">Cancel</Button>
                    <Button variant="contained" onClick={this.save} color="primary" size="small">Save</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ShiftsModal));