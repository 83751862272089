const styles = theme => ({

    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    center: {
        height: "calc(100vh - 105px)",
        justifyContent: "center"
    },

    message: {
        marginTop: theme.spacing(2),
        fontSize: theme.spacing(2.5),
        fontWeight: "500"
    },

    submessage: {
        fontSize: theme.spacing(2)
    },

    fab: {
        marginTop: theme.spacing(2)
    },

    fabIcon: {
        marginRight: theme.spacing(1)
    }

});

export default styles;