import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { DLTable, InfographicMessage, Page }from "@/components";
import Api from "@/helpers/Api";
import InvoicesTableRow from "./InvoicesTableRow";
import InvoicePreviewModal from "../Modals/InvoicePreviewModal";

class Invoices extends Component {
	_table = null;

	state = {
		organization : {},
		isAddingPurchase: false
	}

	reload = () => {
		this.setState({ isAddingPurchase: false });	
		this._table && this._table.fetchData();
	};
	
	organization(){
		return {
			...this.props.organization,
			...this.state.organization
		}
	}

	emptyView(archived = false) {
		const { organization } = this.props;
		return (
			<InfographicMessage
				illustration="invoices"
				message={`${organization.name} doesn't have any invoice yet!`}
			/>
		);
	}


	render() {
		const organization = this.organization();
		return (
			<>
				<DLTable
					ref={table => this._table = table}
					api={Api.organizations(organization.id).invoices}
					RowTemplate={InvoicesTableRow}
					rowProps={{}}
					emptyView={this.emptyView()}
				/>
				<Switch>
					<Route exact path={`${this.props.match.url}/:invoice_no`}>
						<InvoicePreviewModal open={true}/>
					</Route>
				</Switch>
			</>
		);
	}
}

export default withRouter(Invoices);