import { Api } from "@/helpers";
import Types from "@/components/Filter/types";

const data = [
	{
		key: "name",
		label: "Employee Name",
		type: Types.string,
		example: 'Ahmed'
	},
	{
		key: "code",
		label: "Employee Code",
		type: Types.string,
		example: 'EMP-054'
	},
	{
		key: "work_email",
		label: "Work Email",
		type: Types.string,
		example: 'ahmed@company.com'
	},
	{
		key: "personal_email",
		label: "Personal Email",
		type: Types.string,
		example: 'ahmed@gmail.com'
	},
	{
		key: "mobile",
		label: "Mobile Number",
		type: Types.string,
		example: '0333-1234567'
	},
	{
		key: "cnic",
		label: "CNIC",
		type: Types.string,
		example: '42101-1234567-8'
	},
	{
		key: "ntn",
		label: "NTN",
		type: Types.string,
		example: '123456-7'
	},
	{
		key: "current_position.joined_at",
		label: "Joining Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "current_position.confirmed_at",
		label: "Confirmation Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "shifts.id",
		label: "Shift",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.shifts.get({ per_page: 0, sort: { column: 'name', order: 'asc' } });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: "current_position.designation_id",
		label: "Designation",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.designations.get({ per_page: 0, sort: { column: 'name', order: 'asc' }  });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: "current_position.department_id",
		label: "Department",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.departments.get({ per_page: 0, sort: { column: 'name', order: 'asc' }  });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	}
];

export default data;