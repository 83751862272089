const styles = theme => { return ({
	root: {
		width: "100%",
	},
	
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	card: {
		padding: 8,
		width: "100%",
		display: "flex",
		flex: 1,
		minHeight: 70,
		flexDirection: "column",
		justifyContent: "space-around"
	},
	cardHeading: {
		margin: 0,
		padding: 0,
		fontSize: "12px",
		fontWeight: "bold",
		whiteSpace: "nowrap"
	},
	cardNumber: {
		marginTop: 16,
		fontWeight: "bold"
	},
	chartHeadingContainer: {
		padding: 16,
		display: "flex",
		flex: 1,
		backgroundColor: "#ffffff",
		justifyContent: "space-between",
		[theme.breakpoints.down("md")]: {
			display: "block"
		}
		
	},
	chartHeading: {
		fontSize: 30, 
		color: "#444",
		fontWeight: "bold",
		float: "left",
		[theme.breakpoints.down("md")]: {
			display: "block",
			float: "none"
		},
		[theme.breakpoints.only("xs")]: {
			fontSize: 20
		}
	},
	chartStatsContianer: {
		display: "flex",
		flex: 1,
		justifyContent: "flex-end",
		[theme.breakpoints.down("md")]: {
			marginTop: theme.spacing(1)
		},
		[theme.breakpoints.only("xs")]: {
			display: "block"
		}
	},
	chartStatsInnerContianer: {
		marginRight: theme.spacing(2),

		[theme.breakpoints.only("xs")]: {
			display: "flex",
			marginRight: 0,
			paddingBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			justifyContent: "flex-end"
		}
	},
	chartStatsHeading: {
		fontSize: 12,
		fontWeight: "bold",
		color: "#444",
		[theme.breakpoints.only("xs")]: {
			fontSize: 10
		}
	},
	chartStatsValue: {
		color: "#444",
		fontWeight: "bold",
		[theme.breakpoints.only("xs")]: {
			fontSize: 18
		}
	},
	infoIcon: {
        color: theme.palette.label.main,
		lineHeight: 1,
		[theme.breakpoints.only("xs")]: {
			fontSize: 20
		}
	},
	statsCardGrid: {
	    display: "flex",
		flexDirection: "row",
		[theme.breakpoints.up('md')]: {
			flex: 1
		}
	},
	chartSubtitle: {
		color: "rgba(0, 0, 0, 0.5)",
		fontWeight: "normal",
		fontSize: 12,
		display: "block"
	},
	employeeRetentionIcon: {
		lineHeight: 1.33,
		fontSize: 12,
		marginLeft: 3
	},
	
	// Reminder Stepper 
	reminderRoot: {
		padding: 16,
		borderTop: "1px solid",
		borderTopColor: theme.palette.borderColor.main,
	},
	reminderIcon: {
		width: 17,
		height: 17
	},
	noTopBorder: {
		borderTop: 0
	},
	stepRoot: {
		zIndex: 1,
		color: "#000",
		width: 35,
		height: 35,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		float: "left",
		marginRight: 8
	},
	steperLabel: {
		marginLeft: "44px",
		fontSize: 20,
		fontWeight: 500,
		verticalAlign: "middle"
	},
	stepperSubLebel: {
		fontSize: 16,
		marginTop: theme.spacing(1),
		margintRight: 0,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		marginLeft: theme.spacing(1)
	},
	backgroundBirthday: {
		backgroundColor: "#47C8F1"
	},

	backgroundAnniversery: {
		backgroundColor: "#BB79EF"
	},

	backgroundProbation: {
		backgroundColor: "#F1AD47"
	},
	backgroundPayItem: {
		backgroundColor: "#F14747"
	},
	list: {
		margin: "8px 0px 0px 44px"
	},
	nameAndDate: {
		display: "flex",
		flex: 1,
		justifyContent: "space-between",
		marginLeft: theme.spacing(1)
	},
	ellipsis: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},
	employeeAvatar: {
		// border: "1px solid #E0E0E0",
		width: 25,
		height: 25,
		border: `1px ${theme.palette.borderColor.main} solid`,
        boxShadow: theme.shadows[2],
	},
	dueItemName: {
		display: "flex",
		marginLeft: 44,
		flex: 1,
		color: "#000",
		textDecoration: "none",
		alignItems: "center",
		cursor: "pointer",
		marginTop: theme.spacing(1)
	},
	dueItemHeading: {
		marginLeft: "44px"
	},
	dueItem: {
		justifyContent: "space-between",
		marginLeft: "37px"
	},
	areaSeries: {
		backgroundColor: "#539",
		color: "#893"
	},
	employeeLink: {
		color: "#000",
		textDecoration: "none",
		cursor: "pointer"
	},
	name: {
		marginTop: theme.spacing(1),
		fontSize: 14, 
		fontWeight: "normal",
		display: "flex",
		flex: 1,
		alignItems: "center",
	},
	reminderDate: {
		fontSize: 14, 
		fontWeight: 300
	},
	hiddenSM: {
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	hiddenMD: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	dueItemDivider: {
		marginRight: "-16px",
		paddingRight: "16px",
		paddingBottom: "16px",
		borderBottomColor: theme.palette.borderColor.main,
		borderBottom: "1px solid",
	},
	chartBackground: {
		background: "linear-gradient(180deg, #2A97FF 58.65%, #006EED 94.91%)"
	},
	attendancePresent: {
		fontSize: "40%",
		fontWeight: "bold"
	},
	attendanceSup: {
		fontSize: "50%",
	},
	attendanceValue: {
		lineHeight: 1
	},
	emptyMsgPayItem: {
		marginLeft: 44
	},
	emptyMsg: {
		fontSize: "12px",
		color: "rgba(0, 0, 0, 0.4)",
		marginTop: theme.spacing(1)
	},
	infographicMessage: {
		marginBottom: theme.spacing(2)
	},
	employeeLimit: {
		padding: 8
	}
})};
export default styles;