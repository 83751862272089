import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Card,
	CardActions,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import { DateRange, DLTable, EmployeeDropdown, PayItemDropdown, BackButton, InfographicMessage, Page } from "@/components";
import SalaryLedgerRow from "./SalaryLedgerRow";
import { Api } from "@/helpers";
import styles from "./style";

class SalaryLedger extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.state = {
			from: null,
			to: null,
			employee: null,
			payItem: null,
			anchorEl: null
		};
	}

	export = () => {
		const { from, to, employee, payItem } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.salary_ledger.export.getUrl({
			from: from.format("YYYY-MM-DD"),
			to: to.format("YYYY-MM-DD"),
			filter: {
				employee: employee || undefined,
				pay_item: payItem || undefined
			}
		}))
	};

	reload = () => {
		this._table && this._table.fetchData(true);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="salary_ledger"
				message="Salaries are not yet paid to employees!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, to, employee, payItem } = this.state;
        return (
			<Page title="Salary Ledger">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<div className={classes.taxReportFilter}>
							<DateRange
								onChange={(from, to) => this.setState({ from, to }, this.reload)}/>
							<EmployeeDropdown
								textFieldProps={{ margin: "dense" }}
								value={employee}
								onChange={employee => this.setState({ employee }, this.reload)}
							/>
							<PayItemDropdown
								textFieldProps={{ margin: "dense" }}
								value={payItem}
								onChange={payItem => this.setState({ payItem }, this.reload)}
							/>
						</div>
						<IconButton
							onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
							<Icon>more_vert</Icon>
						</IconButton>
						<Menu
							id="long-menu"
							anchorEl={this.state.anchorEl}
							open={!!this.state.anchorEl}
							onClose={() => this.setState({ anchorEl: null })}
							PaperProps={{
								style: {
									width: 200,
								},
							}}>
							
							<MenuItem onClick={this.export}>Export Ledger</MenuItem>
						</Menu>
					</CardActions>
					{
						from && to &&
						<DLTable
							ref={ref => this._table = ref}
							api={Api.reports.salary_ledger}
							params={{
								from: from.format("YYYY-MM-DD"),
								to: to.format("YYYY-MM-DD"),
								filter: {
									employee: employee || undefined,
									pay_item: payItem || undefined
								}
							}}
							defaultOrder="desc"
							RowTemplate={SalaryLedgerRow}
							emptyView={this.emptyView()}/>
					}
				</Card>
			</Page>
        );
    }

}

export default withRouter(withStyles(styles)(SalaryLedger));