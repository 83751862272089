import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Link, Route, Switch, withRouter } from "react-router-dom"
import { Card, CardActions, Grid } from "@material-ui/core";
import { Page, BackButton, DLTable, InfographicMessage } from "@/components";
import InvoicesTableRow from "@/components/Invoices/InvoicesTableRow";
import InvoicePreviewModal from "@/components/Modals/InvoicePreviewModal";
import { Api } from "@/helpers";
import FilterData from "@/pages/Invoices/FilterData";
import { setLoader } from "@/redux/actions/general";
import styles from "./style";
import _ from "lodash";
import Filter from "@/components/Filter";

class Invoice extends Component {

	tableRef = null;
	
	constructor(props) {
		super(props);
        
		this.state = {
			fields: FilterData,
			filters: null
		};
	}
  
	emptyView() {
		const { classes } = this.props;
		return (
			<div className={classes.infographicMessage}>
				<InfographicMessage
					illustration="invoices"
					message="You don't have any Invoices yet!"
				/>
			</div>
		);
	}

	onSearch = filters => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, async () => {
			if (!!oldFilters && this.tableRef) {
				this.tableRef.fetchData();
			}
			this.props.setLoader(false);
		});
	};

	render() {
		const { fields, filters } = this.state;
		return (
			<Page title="Invoices">
				<Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
					<BackButton route="/settings" label="Back to Settings" />
				</Link>
				<Card raised>
					<Grid container justifyContent="flex-end">
						<CardActions>
							<Filter
								fields={fields}
								filters={filters || []}
								withQueryParams
								onUpdate={this.onSearch}
							/>
						</CardActions>
					</Grid>
					{
						!!filters &&
						<DLTable
							ref={ref => this.tableRef = ref}
							api={Api.invoices}
							params={{ filter: filters }}
							RowTemplate={InvoicesTableRow}
							emptyView={this.emptyView()}
							setLoader={this.props.setLoader}
						/>
					}
					<Switch>
						<Route exact path={`${this.props.match.url}/:invoice_no`}>
							<InvoicePreviewModal open={true}/>
						</Route>
					</Switch>
				</Card>
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Invoice)));