import React, { Component } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from "@material-ui/core";
import { Api } from "@/helpers";
import { DateRange, PDFViewer } from "@/components";
import styles from "./style";
import { capitalize } from "lodash";

class TaxCertificateModal extends Component {

    static propTypes = {
        employee: Proptypes.object.isRequired,
        onClose: Proptypes.func.isRequired,
        open: Proptypes.bool.isRequired
    };

    state = {
        from: null,
        to: null,
        enableDownload: false
    };

    download = () => {
        const { employee } = this.props;
        const { from, to } = this.state;
        if (from && to && employee && employee.id) {
            const link = document.createElement('a');
            link.href = Api.reports.tax_certificate(employee.id).getUrl({ from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") });
            link.setAttribute('download', `wht-certificate-${employee.code}.pdf`);
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    render() {
        const { from, to, enableDownload } = this.state;
        const { classes, employee, onClose, open } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                scroll="paper"
				onBackdropClick={onClose}
                aria-labelledby="tax-certificate-dialog-title"
                aria-describedby="tax-certificate-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="tax-certificate-dialog-title">
                    <span>
                        {
                            employee && employee.first_name && employee.last_name &&
                            <>
                                <Chip className={classes.chip} size="small" color="primary" label={employee.code} />
                                <span className={classes.employeeName}>{capitalize(employee.first_name)} {capitalize(employee.last_name)}</span>
                            </>
                        }    
                        Witholding Tax Certificate
                    </span>
                    <DateRange
                        stacked
                        onChange={(from, to) => this.setState({ from, to }, this.reload)} />
                </DialogTitle>
                <DialogContent dividers={true}>
                    {
                        from && to && employee && employee.id &&
                        <PDFViewer
                            url={
                                Api.reports.tax_certificate(employee.id).getUrl({
                                    from: from.format("YYYY-MM-DD"),
                                    to: to.format("YYYY-MM-DD")
                                })
                            }
                            onLoadSuccess={() => this.setState({ enableDownload: true })}
                            onLoadError={() => this.setState({ enableDownload: false })}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button size={"small"} onClick={onClose}>Cancel</Button>
                    <Button
                        size={"small"}
                        onClick={this.download}
                        disabled={!from || !to || !employee || !employee.id || !enableDownload}
                        color="primary">
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

export default withStyles(styles)(TaxCertificateModal);