var generalVarables = {
	snackbar: {
		visible: false,
		onClose: () => { },
		message: '',
		variant: 'success'
	},
	loader: {
		visible: false,
		primaryColor: 'primary'
	},
	title: 'Dashboard',
	filterCounter: 1,
	activeEmployees: 1,
	seatsAllowed: 0,
	isSeatAvailable: true,
	isGracePeriod: false,
	graceEndDate: 0
};

export default function (state = generalVarables, action) {
	switch (action.type) {
		case 'SET_SNACKBAR':
			return state = {
				...state,
				snackbar: action.snackbar
			};

		case 'RESET_SNACKBAR':
			return state = {
				...state,
				snackbar: generalVarables.snackbar
			};

		case 'SET_LOADER':
			return state = {
				...state,
				loader: action.loader
			};

		case 'SET_TITLE': {
			return state = {
				...state,
				title: action.title
			};
		}

		case 'SET_FILTER':
			return state = {
				...state,
				filterCounter: action.payload
			};
		
		case 'SET_ACTIVE_EMPLOYEES': {
			const activeEmployees = Math.max(action.employees, 1);
			console.log(
				"SET_ACTIVE_EMPLOYEES",
				activeEmployees,
				action.seatsAllowed,
				action.seatsAllowed == 0 || (action.seatsAllowed - activeEmployees) > 0
			);
			return state = {
				...state,
				activeEmployees,
				seatsAllowed: action.seatsAllowed,
				isSeatAvailable: action.seatsAllowed == 0 || (action.seatsAllowed - activeEmployees) > 0,
				isGracePeriod: action.isGracePeriod,
				graceEndDate: action.graceEndDate
			};
		}
		case 'ADD_ACTIVE_EMPLOYEES': {
			const activeEmployees = Math.max(state.activeEmployees + action.employees, 1);
			return state = {
				...state,
				activeEmployees,
				isSeatAvailable: state.seatsAllowed == 0 || (state.seatsAllowed - activeEmployees) > 0
			};
		}
		case 'SUBTRACT_ACTIVE_EMPLOYEES':{
			const activeEmployees = Math.max(state.activeEmployees - action.employees, 1);
			return state = {
				...state,
				activeEmployees,
				isSeatAvailable: state.seatsAllowed == 0 || (state.seatsAllowed - activeEmployees) > 0
			};
		}
		default:
			return state;
	}
}