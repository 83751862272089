const style = theme => ({

    container: {
        cursor: "pointer",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        columnGap: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.color.orange.warning,
        
        "&:focus, &:hover, &.MuiChip-clickable:hover, &.MuiChip-clickable:focus": {
            backgroundColor: theme.color.orange.warning
        },

        "& .MuiChip-label": {
            borderRadius: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textAlign: "center",
            backgroundColor: "#FFFFFF",
            color: theme.color.orange.warning
        },

        "& .MuiChip-icon": {
            color: "white",
            fontSize: "1.5em",
            margin: 0
        }
    }

});

export default style;