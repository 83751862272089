import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Switch,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    InputLabel,
} from "@material-ui/core";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";
import styles from "./style";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withRouter } from "react-router-dom";
import { Constants } from "@/config";

class AddDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            employee_id: "",
            id: "",
            name: "",
            category: "",
            file: "",
            suggestedCategory: [],
            for_office_only: 0,
            send_email: 0,
            organization_document: 0,
            employee_document: 0,
            title: props.title,
            isEdit: false,
        };
    }

    async componentDidMount() {
        if (this.props.data && this.props.data.id > 0) {
            if (this.props.employeeDocument === true) {
                this.props.setLoader(true);
                this.setState({ isLoading: true, isEdit: true });
                let response = await Api.employee_document(
                    this.props.data.id
                ).get();
                this.setState(
                    {
                        id: response.id,
                        name: response.name,
                        category: response.category,
                        for_office_only: response.for_office_only,
                        organization_document: response.organization_document,
                        employee_document: response.employee_document,
                        file: response.file_name,
                        isEdit: true,
                    },
                    () => {
                        this.props.setLoader(false);
                    }
                );
            } else {
                this.props.setLoader(true);
                this.setState({ isLoading: true, isEdit: true });
                let response = await Api.organization_document(
                    this.props.data.id
                ).get();
                this.setState(
                    {
                        employee_id: response.employee_id,
                        id: response.id,
                        name: response.name,
                        category: response.category,
                        for_office_only: response.for_office_only,
                        organization_document: response.organization_document,
                        employee_document: response.employee_document,
                        file: response.file_name,
                        isEdit: true,
                    },
                    () => {
                        this.props.setLoader(false);
                    }
                );
            }
        }
        if (this.props.employeeDocument === true) {
            let categoryResponse = await Api.employee_document.categories.get();
            this.setState({ suggestedCategory: categoryResponse });
        } else {
            let categoryResponse = await Api.organization_document.categories.get();
            this.setState({ suggestedCategory: categoryResponse });
        }
    }

    handleChange = (val, fieldName) => {
        this.setState({ [fieldName]: val });
    };

    validate() {
        var errors = {};
        if (this.state.name === "") {
            errors["name"] = `${_.startCase("name")} field is required`;
        }
        if (this.state.category === "") {
            errors["category"] = `${_.startCase("category")} field is required`;
        }
        if (this.state.file === "") {
            errors["file"] = `${_.startCase("file")} field is required`;
        }
        if (_.size(errors)) {
            this.props.setSnackbar(
                true,
                "Please fix the highlighted errors",
                "error"
            );
        }
        this.setState({ errors });
        return !(_.size(errors) > 0);
    }

    createDocumentItem = async () => {
        if (!this.validate()) return;
        this.props.setLoader(true);
        var data = new FormData();
        data.append("name", this.state.name);
        data.append("category", this.state.category.toLowerCase());
        if (this.props.employeeDocument === false && !this.state.isEdit) {
            data.append("send_email", this.state.send_email === true ? 1 : 0 || 0);
        }
        if (typeof this.state.file === "object") {
            data.append("file", this.state.file);
        }
        try {
            if (this.props.employeeDocument === true) {
                if (!this.state.isEdit) {
                    data.append("employee_id", this.props.employee.id || "");

                }

                data.append(
                    "for_office_only",
                    this.state.for_office_only === true ? 1 : 0 || 0
                );
                if (!this.state.id) {
                    await Api.employee_document.post(data);
                } else {
                    data.append("_method", "put");
                    await Api.employee_document(this.state.id).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                }
            } else {
                if (!this.state.id) {
                    await Api.organization_document.post(data);
                } else {
                    data.append("_method", "put");
                    await Api.organization_document(this.state.id).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                }
            }
            this.props.setSnackbar(
                true,
                this.state.id ? "Documents updated" : "Documents Added"
            );
            this.props.onUpdate();
            this.props.setLoader(false);
            this.closeDialog();
        } catch (e) {
            console.log(e, "error========");
            HttpErrorHandler.intercept(e)
                .on(422, (response) => {
                    console.log({ response });
                    this.setState(
                        {
                            errors: _.mapValues(
                                response.data,
                                (value) => value[0]
                            ),
                        },
                        () => {
                            console.log(this.state.errors);
                        }
                    );
                    this.props.setSnackbar(
                        true,
                        "Please fix the highlighted errors",
                        "error"
                    );
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
        this.props.setLoader(false);
    };
    handleOnChange = (e) => {
        console.log(e.target.files[0]);
        this.setState({ file: e.target.files[0] });
    };

    closeDialog = () => {
        this.props.onClose();
    };

    render() {
        const { classes } = this.props;
        const { errors, send_email } = this.state;
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={"new"}
                onBackdropClick={this.props.onClose}
                aria-labelledby="version-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="version-dialog-title">
                    {this.state.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name}
                                placeholder="Name"
                                label="Name"
                                value={this.state.name}
                                onChange={(e) => this.handleChange(e.target.value, "name")}
                                variant="outlined"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={this.state.category}
                                getOptionLabel={(option) => option}
                                onChange={(_, newValue) => this.setState({ category: newValue })}
                                onInputChange={(_, newInputValue) => this.setState({ category: newInputValue })}
                                options={this.state.suggestedCategory}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ marginTop: "5px" }}
                                        fullWidth
                                        error={!!errors.category}
                                        helperText={errors.category}
                                        placeholder="Category"
                                        label="Category"
                                        value={this.state.category}
                                        // onChange={(e) =>
                                        //     this.handleChange(
                                        //         e.target.value,
                                        //         "category"
                                        //     )
                                        // }
                                        variant="outlined"
                                        size={"small"}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid style={{ color: "#2F80ED", cursor: "pointer", }} item xs={3} sm={3} md={4}>
                            <div style={{ flexDirection: "row", cursor: "pointer", }}>
                                <label
                                    className="custom-file-upload"
                                    style={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        position: "relative",
                                        background: "white",
                                        boxSizing: "border-box",
                                        borderRadius: "6px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "105px",
                                        height: "35px",
                                        fontFamily: "Roboto",
                                        cursor: "pointer",
                                        border: "2px solid",
                                        borderColor: "#2F80ED",
                                        verticalAlign: "top",
                                    }}
                                >
                                    <input
                                        accept={_.flatten(_.values(Constants.DocMimeTypes)).join(",")}
                                        type="file"
                                        style={{
                                            display: "block",
                                            cursor: "pointer",
                                            pointerEvents: "auto",
                                            outline: "none",
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            overflow: "hidden",
                                            filter: "alpha(opacity=0)",
                                            opacity: "0",
                                        }}
                                        onChange={this.handleOnChange}
                                    />
                                    <i style={{ cursor: "pointer", pointerEvents: "auto" }} className="fa fa-cloud-upload"/> Attach file
                                </label>
                                <div className={classes.attachementError}>
                                    {errors.file}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8} className={classes.labelGrid}>
                            {
                                typeof this.state.file === "object" ? (
                                    <InputLabel>{this.state.file.name}</InputLabel>
                                ) : (
                                    <InputLabel>{this.state.file}</InputLabel>
                                )
                            }
                        </Grid>
                        {
                            this.props.employeeDocument === true &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    labelPlacement="start"
                                    className={classes.switch}
                                    label="Only for office use"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.state.for_office_only}
                                            size={"small"}
                                            onChange={() => this.handleChange(!this.state.for_office_only, "for_office_only")}
                                        />
                                    }
                                />
                            </Grid>
                        }
                        {
                            (this.props.employeeDocument === false && !this.state.isEdit) &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    labelPlacement="start"
                                    className={classes.switch}
                                    label="Notify employees"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={send_email}
                                            size={"small"}
                                            onChange={() => this.handleChange(!this.state.send_email,"send_email")}
                                        />
                                    }
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        onClick={this.closeDialog}
                        color="default">
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={this.createDocumentItem}
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(withStyles(styles)(AddDocument))
);
