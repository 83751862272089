import React, { Component } from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { Button, Grid, Icon, Tooltip } from "@material-ui/core";
import { Stepper, Stats, Money } from "@/components";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./style";
import { Api, AccessManager, HttpErrorHandler } from "@/helpers";
import { AuthContext } from "@/contexts";
import { Permissions } from "@/config/Permissions";
import Modules from "@/config/Modules";

class PayrunSummary extends Component {

    static propTypes = {
        payrun: PropTypes.object.isRequired,
        pageType: PropTypes.oneOf(["add", "view"])
    }

    static contextType = AuthContext;

    footerActions() {
        const { pageType, payrun } = this.props;
        return (
            pageType !== "view" && payrun &&
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.updateStatus()}>
                Approve Payroll
            </Button>
        );
    }

    updateStatus = async (status = true) => {
        const { payrun, onUpdate } = this.props;
        if (payrun.id) {
            this.props.setLoader(true);
            try {
                const { payrun } = this.props;
                const _payrun = await Api.payruns(payrun.id).put({ is_approved: status });
                onUpdate(_payrun);
            } catch (e) {
                HttpErrorHandler.intercept(e)
                    .on(403, response => this.props.setSnackbar(true, response.data.message, 'error'))
                    .default(_ => this.props.setSnackbar(true, 'Something went wrong, please try again', 'error'))
                    .go();
            }
            this.props.setLoader(false);
        }
    };

    hasPermission = (routePermissions) => {
        if (!this.context || !this.context.user) return false;
        let hasPermission = false;

        routePermissions.forEach((routePermission, index) => {
            if (this.context.user.permission_names.indexOf(routePermission) !== -1) {
                hasPermission = true;
            }
        });

        return hasPermission;
    }

    isTaxModule = (payrun) => {
        const { classes } = this.props;
        return (
            payrun.taxes !== 0 ? (
                <>
                    <Stats label="Total Payrun" className={classes.successColor}>
                        <Money amount={(payrun.total_payment)} />
                    </Stats>
                    <Stats color="secondary">-</Stats>
                    <Stats label="Taxes" color="secondary">
                        <Money amount={(payrun.taxes)} />
                    </Stats>
                    <Stats color="primary">=</Stats>
                    <Stats label="Employee Payments" color="primary">
                        <Money amount={(payrun.employee_payment)} />
                    </Stats>
                </>
            ) : (
                <Stats label="Employee Payments" color="primary">
                    <Money amount={(payrun.total_payment)} />
                </Stats>
            )
        )
    }

    render() {
        const { classes, pageType, payrun, step, stepper, isActive, ...otherProps } = this.props;

        return (
            <Stepper.Step
                title="Payrun Summary"
                step={step}
                showContent={true}
                isActive={isActive}
                stepper={stepper}
                footerActions={this.footerActions()}
                canEdit={pageType === "view" && payrun && !payrun.is_locked && this.hasPermission([Permissions.PAYROLL_EDITABLE, Permissions.PAYROLL_FULL_ACCESS])}
                onEdit={() => this.updateStatus(false)}
                {...otherProps}>
                {
                    payrun &&
                    <Grid container direction="row" justify="space-between">
                        {
                            pageType === "view" &&
                            <Grid item>
                                <Grid container>
                                    <Stats label="Payroll Period">
                                        {moment(payrun.to).format("MMM Y")}
                                        <Tooltip title={moment(payrun.from).format("DD/MM/Y") + " to " + moment(payrun.to).format("DD/MM/Y")} aria-label="Add" placement="top">
                                            <Icon className={classes.infoIcon}>info</Icon>
                                        </Tooltip>
                                    </Stats>
                                    <Stats label="Total Employees">{payrun.total_employees}</Stats>
                                </Grid>
                            </Grid>
                        }
                        <Grid item>
                            <Grid container>
                                {this.isTaxModule(payrun)}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Stepper.Step>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setLoader: status => dispatch(setLoader(status)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PayrunSummary)));