import React, { Component } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { InfographicMessage } from "@/components";
import { pdfjs, Document, Page } from "react-pdf";
import styles from "./style";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFViewer extends Component {

    state = {
        numPages: 1
    };

    onLoadSuccess = (e) => {
        this.setState({ numPages: e.numPages });
        this.props.onLoadSuccess(e);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.url !== nextProps.url || this.state.numPages !== nextState.numPages;
    }

    loadingView() {
        const { classes } = this.props;
        return (
            <div className={classes.loaderContainer}>
                <CircularProgress />
            </div>
        );
    }

    errorView() {
        return (
			<InfographicMessage
				illustration="not_found"
				message="Unable to find the specified document"
			/>
		);
    }

    render () {
        const { numPages } = this.state;
        const { classes, url, onLoadError } = this.props;
        return (
            <Document
                className={classes.document}
                file={{
                    url,
                    withCredentials: true
                }}
                loading={this.loadingView()}
                onLoadSuccess={this.onLoadSuccess}
                error={this.errorView()}
                onLoadError={onLoadError}
            >
                {
                    [...Array(numPages).keys()].map(index => (
                        <Page
                            key={`pdf-page-${index + 1}`}
                            pageNumber={1 + index}
                            className={classes.documentPage}
                            renderTextLayer={false}
                            renderMode="svg"
                            loading={this.loadingView()}
                        />
                    ))
                }
            </Document>
        )
    }

}

PDFViewer.propTypes = {
    url: Proptypes.string.isRequired,
    onLoadSuccess: Proptypes.func,
    onLoadError: Proptypes.func
};

PDFViewer.defaultProps = {
    onLoadSuccess: () => {},
    onLoadError: () => {}
};

export default withStyles(styles)(PDFViewer);