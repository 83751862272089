import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import NoRecord from '../../assets/icons/no-use-bg.svg';
import { withRouter } from "react-router-dom";
import React from "react";
import _ from 'lodash';

class ResourceAvailabilityRow extends DLTableRow {

    columnExtra = () => {
        const { data } = this.props;

        var records = data.records
        if (records && records.length < 31) {
            var skip = 31 - records.length;
            for (var i = 0; i < skip; i++) {
                return (<DLTableColumn  colSpan={skip}
                    key={`ResourceAvailability-${records.date}-Column-${i}`}
                    align={"center"}
                    style={{
                        backgroundImage: `url(${NoRecord})`
                    }}>
                </DLTableColumn>)
            }
        }
    }

    renderCells() {
        const { data, classes, leaves_color } = this.props;

        return [
            <DLTableColumn
                stickyHeader={true}
                key={`ResourceAvailability-${data.employee_id}-Column-1`}
                withSeparator={true}>
                <EmployeeAvatar data={{
                    employee: {
                        id: data.employee_id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        code: data.code
                    }
                }} classes={classes} />
            </DLTableColumn>,
            data.records.map((record, i) => {
                return (
                    <DLTableColumn
                        key={`ResourceAvailability-${record.date}-Column-${i}`}
                        align={"center"}
                        withSeparator={i == 30 ? false : true}
                        style={{
                            backgroundColor: (record.status == "H" ? "#FF7300" :null),
                            color: record.status == "H" ? "white" : record.status == "A" ? "#281B1B" : !!leaves_color ? leaves_color[record.status]: "#64182A",
                        }}>
                        {record.status}

                    </DLTableColumn>
                )
            }),
            this.columnExtra(),
        ];
    }
}

ResourceAvailabilityRow.Columns = props => {
    var dataColumnsArray = Array.from(Array(31), (_, i) => i + 1)
    var dateColumns = {}
    var columns = [{
        key: 'employee.first_name',
        label: 'Employee',
        align: 'left',
        isSortable: false,
        stickyHeader:true,
        withSeparator: true
    }];

    dateColumns = _.map(dataColumnsArray, (label) => {
        return {
            label: label,
            align: "center",
            withSeparator: label == 31 ? false : true
        }
    });
    columns.push(...dateColumns)
    return columns

}
export default withRouter(ResourceAvailabilityRow)