import { ArchiveOutlined, EditOutlined, KeyboardCapslock, SettingsOutlined, UnarchiveOutlined, Replay } from "@material-ui/icons";
import { DLTableColumn, DLTableRow, SVGIcons, ModuleManager, Money } from "@/components";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { setSnackbar } from "@/redux/actions/general";
import store from "@/redux";
import styles from "./style";
import theme from "@/themeStyles";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Modules } from "@/config";

class PayItemTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isEditModalOpen: false,
			data: {},
		}
	}

	modalclose = () => { this.setState({ isEditModalOpen: false }); }


	archive = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.pay_items(data.id).delete()
			store.dispatch(setSnackbar(true, `Pay item "${data.name}" archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.pay_items(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `Pay item "${data.name}" restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data, classes } = this.props;
		return [
			<DLTableColumn
				key={`Payrun-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-2`}
				align={"left"}
				withSeparator>
				{data.type}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-3`}
				align={"right"}>
				{data.default_quantity}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-4`}
				align={"right"}>
				{data.is_percentage ? `${data.default_rate}%` : <Money amount={data.default_rate}/>}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-5`}
				align={"left"}>
				{data.default_frequency}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-6`}
				fillWidth={35}
			>
				{
					data.is_permanent &&
					<Tooltip title="System generated">
						<SettingsOutlined className={classes.statusIcon} />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-7`}
				fillWidth={35}
			>
				{
					data.is_default &&
					<Tooltip title="Adds automatically for new employees">
						<SVGIcons icon="default" />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-8`}
				fillWidth={35}
			>
				{
					data.is_on_slip &&
					<Tooltip title="Will be displayed on payslip">
						<SVGIcons icon="on_slip" />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-9`}
				fillWidth={35}
			>
				{
					data.is_taxable &&
					<Tooltip title="Taxable">
						<SVGIcons icon="taxable" />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-10`}
				fillWidth={35}
			>
				{
					data.is_fixed_rate &&
					<Tooltip title="Fixed Rate">
						<SVGIcons icon="fixed_rate" />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-12`}
				withSeparator
				fillWidth={35}
			>
				<ModuleManager modulePermissions={[ Modules.REIMBURSEMENT ]}>
					{
						data.is_reimbursable &&
						<Tooltip title="Reimbursable salary item, employees need to submit request to avail reimbursements.">
							<Replay style={{ color: theme.palette.statusLabelIcon.main }} />
						</Tooltip>
					}
				</ModuleManager>
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-13`}
				isCompact={true}
				align={"center"}
				fillWidth={35}
			>
				{
					data.is_editable && !data.is_archived &&
					<Tooltip title="Edit Pay Item">
						<Link to={{ pathname: `/settings/payitems/${data.id}` }} style={{ textDecoration: 'none', color: "black" }}>
							<IconButton
								onClick={() => {
									this.props.history.push({
										pathname: `/settings/payitems/${data.id}`,
									})
								}}
								size="small"
								color="primary">
								<EditOutlined />
							</IconButton>
						</Link>
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-14`}
				isCompact={true}
				align={"center"}
				fillWidth={35}
			>
				{
					!data.is_permanent && !data.is_archived &&
					<>
						<Tooltip title="Archive Pay Item">
							<IconButton
								onClick={() => this.setState({ isModalOpen: true })}
								size="small"
								color="secondary">
								<ArchiveOutlined />
							</IconButton>
						</Tooltip>
						<ConfirmModal
							title="Archive Payitem"
							isOpen={this.state.isModalOpen}
							message={`Are you sure you want to archive pay item ${data.name}?`}
							onClickCancel={() => {
								this.setState({ isModalOpen: false });
							}}
							onClickConfirm={this.archive} />
					</>
				}
				{
					data.is_archived &&
					<>
						<Tooltip title="Restore Pay Item">
							<IconButton
								onClick={() => this.setState({ isModalOpen: true })}
								size="small"
								color="primary">
								<UnarchiveOutlined />
							</IconButton>
						</Tooltip>

						<ConfirmModal
							title="Restore Payitem"
							isOpen={this.state.isModalOpen}
							message={`Are you sure you want to restore pay item ${data.name}?`}
							onClickCancel={() => {
								this.setState({ isModalOpen: false });
							}}
							onClickConfirm={this.restore} />
					</>
				}
			</DLTableColumn>,
		]
	}

}

PayItemTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "type",
		label: "Type",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "quantity",
		label: "Quantity",
		align: "right",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "rate",
		label: "Rate",
		align: "right",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "frequency",
		label: "Frequency",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: "Status",
		align: "left",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 6
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

export default withRouter(withStyles(styles)(PayItemTableRow));