import React, { Component } from "react";
import { Button, Modal, Paper } from "@material-ui/core";
import { TimePicker } from '@material-ui/pickers';
import moment from "moment";

class TimeModal extends Component {
    state = {
        time: null
    };

    close = () => {
        this.setState({ time: null });
        this.props.onCancel();
    };

    done = (time) => {
        this.setState({ time: null });
        this.props.onDone(moment(time).isValid() ? time : moment());
    };

    render() {
        const { title, initialTime, isOpen } = this.props;
        const { time } = this.state;
        const selectedTime = time || initialTime || moment();
        return (
            <Modal
                style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                aria-labelledby={title}
                aria-describedby={title}
                open={isOpen}
                onClose={this.close}
            >
                <Paper style={{width: 310, maxHeight: "calc(100% - 64)", margin: 32, overflow: "auto", outline: "none"}}>
                    <TimePicker
                        value={selectedTime}
                        onChange={time => this.setState({ time: moment(time) })}
                        variant="static"/>

                    <div style={{padding: 5, display: "flex", justifyContent: "flex-end"}}>
                        <Button onClick={this.close} color="default">Cancel</Button>
                        <Button onClick={() => this.done(selectedTime)} color="primary">{title}</Button>
                    </div>
                </Paper>
            </Modal>
        );
    }
}

export default TimeModal;