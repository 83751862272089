import Types from "@/components/Filter/types";
import { Constants } from "@/config";

const data = [
	{
		key: "status",
		label: "Status",
		type: Types.selection,
		options: [
			{
				key: "unpaid",
				label: "Unpaid"
			},
			{
				key: Constants.Reimbursable.FLAG_PAID,
				label: "Paid"
			}
		]
	},
	{
		key: "start_date",
		label: "Start Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "end_date",
		label: "End Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "due_date",
		label: "Due Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "employees_count",
		label: "Employee Count",
		type: Types.number,
		example: 15
	},
	{
		key: "net_total",
		label: "Invoice Amount",
		type: Types.number,
		example: 5600
	}
];

export default data;