import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { PayrunSummary, Stepper } from "@/components";
import PayrunPeriod from "./PayrunPeriod";
import EmployeeSelection from "./EmployeeSelection";
import Payslips from "./Payslips";
import styles from "./style";

class PayrunForm extends Component {

	static defaultProps = {
		payrun: {}
	};

	componentDidMount() {
		if (this.props.payrun.is_approved) {
			return this.props.history.push("/payroll/" + this.props.payrun.id);
		}
	}

	render() {
		const { payrun, onUpdate } = this.props;
		return (
			<Stepper initialStep={payrun.id ? 2 : 0} payrun={payrun} onUpdate={onUpdate}>
				<PayrunPeriod />
				<EmployeeSelection />
				<Payslips />
				<PayrunSummary />
			</Stepper>
		);
	}
}

export default withStyles(styles)(PayrunForm);