import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
    Tooltip,
    IconButton,
    Icon,
    Menu,
    MenuItem,
    Chip,
} from "@material-ui/core";

import LoanPaymentModal from "@/components/Modals/PaymentModalLoan";
import {
    DoneOutlined,
    ClearOutlined,
    RemoveRedEyeOutlined,
} from "@material-ui/icons";
import {
    DLTableRow,
    DLTableColumn,
    UserRole,
    EmployeeAvatar,
    Money,
} from "@/components";
import store from "@/redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import styles from "./style";
import moment from "moment";
import { Constants } from "@/config";
import Theme from "@/themeStyles";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Permissions } from "@/config/Permissions";
import { currencyFormatter } from "@/helpers/generalHelper";
import _, { isInteger } from "lodash";

class LoanTableRow extends DLTableRow {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            imageIndex: 0,
            selectedLoan: "",
            isApproving: false,
            isRejecting: false,
            isDeleting: false,
            approveOrReject: null,
            paymentModal: false,
            ungrantLoan: false,
            deductLoanModal: false,
        };
    }

    deleteLoan = async (id) => {
        this.setState({ isDeleting: false });
        store.dispatch(setLoader(true));
        try {
            store.dispatch(setLoader(true));
            await Api.loan_request(id).delete();
            store.dispatch(setSnackbar(true, `loan deleted successfully`));
            this.props.reload();
        } catch (e) {

            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
            if (errors && errors.length) {
                    store.dispatch(
                    setSnackbar(true, errors[0], "error"))
            } else {
                    store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error"))
            }
        })
            .default(_ =>     store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error")))
            .go();
        }
        store.dispatch(setLoader(false));
    };

    ungrantLoan = async (id) => {
        this.setState({ ungrantLoan: false });
        store.dispatch(setLoader(true));
        try {
            store.dispatch(setLoader(true));
            await Api.loan_request(id).delete_granted.delete();
            store.dispatch(setSnackbar(true, `loan ungrant successfully`));
            this.props.reload();
        } catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
            if (errors && errors.length) {
                    store.dispatch(
                    setSnackbar(true, errors[0], "error"))
            } else {
                    store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error"))
            }
        })
            .default(_ =>     store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error")))
            .go();
        }
        store.dispatch(setLoader(false));
    };

    updateStatus = async (data, status) => {
        try {
            store.dispatch(setLoader(true));
            try {
                await Api.loan_request(data.id).put({ status });
                if (status === Constants.Loan.FLAG_REJECTED) {
                    store.dispatch(
                        setSnackbar(
                            true,
                            `Loan rejected for ${data.employee.first_name} ${data.employee.last_name}`,
                            "error"
                        )
                    );
                } else {
                    store.dispatch(
                        setSnackbar(
                            true,
                            `Loan approved for ${data.employee.first_name} ${data.employee.last_name}`,
                            "success"
                        )
                    );
                }
                this.setState({ isModalOpen: false });
                this.props.reload();
            } catch (e) {
                HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                        store.dispatch(
                    setSnackbar(true, errors[0], "error") );
                } else {
                    store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error") );
                }
            })
                .default(_ =>     store.dispatch(
                    setSnackbar(true, "Something went wrong, please try again", "error")) )
                .go();
                store.dispatch(
                    setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                );
            }
        } catch (e) {}
        store.dispatch(setLoader(false));
    };

    returnStatusColor(status) {
        switch (status) {
            case Constants.Loan.FLAG_PENDING:
                return Theme.palette.tableBorder.main;
            case Constants.Loan.FLAG_APPROVED:
                return Theme.palette.success.main;
            case Constants.Loan.FLAG_REJECTED:
                return Theme.color.red.persianRed;
            case Constants.Loan.FLAG_GRANT:
                return Theme.palette.primary.main;
            case Constants.Loan.FLAG_PARTIAL:
                return "#002652";
            case Constants.Loan.RETURNED:
                return Theme.palette.primary.main;
            default:
                break;
        }
    }

    renderCells() {
        const { data, classes } = this.props;
        const { isApproving, isRejecting, isDeleting, ungrantLoan } =
            this.state;
        return [
            <DLTableColumn
                withSeparator={true}
                key={`Loan-${data.id}-Column-1`}
            >
                <EmployeeAvatar data={data} classes={classes} />
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-2`}
                align={"right"}
            >
                <Money amount={data.loan_amount} />
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-3`}
                align={"left"}
            >
                {data.duration_type == "Months" ? `${isInteger(data.duration/12)? (`${data.duration/12}  Years`) : `${data.duration} ${data.duration_type}`}` : `${data.duration} ${data.duration_type}`}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                colSpan={2}
                key={`Loan-${data.id}-Column-3`}
                align={"left"}
            >
              {`${data.cycle} ${data.cycle_type}`}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-4`}
                align={"right"}
            >
                <Money amount={data.return_amount} />
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-4`}
                align={"right"}
            >
                {data.loan_stats.remaining_amount > 0 ? (
                    <Money amount={data.loan_stats.remaining_amount} />
                ) : (
                    "-"
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-4`}
                align={"right"}
            >
                {data.next_deduction_date ? moment(data.next_deduction_date).format("DD MMM, YYYY") : "-"}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-5`}
                align={"left"}
            >
                {moment(data.created_at).format("DD MMM, YYYY")}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-6`}
                align={"left"}
            >
                <Chip
                    size="small"
                    label={data.status.toUpperCase()}
                    color={"primary"}
                    style={{
                        backgroundColor: this.returnStatusColor(data.status),
                        color: Theme.color.white.main,
                    }}
                />
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-7`}
                align={"right"}
                isCompact={true}
                fillWidth={35}
            >
                {data.status === Constants.Loan.FLAG_PENDING && (
                    <>
                        <Tooltip title="Approve Loan Request">
                            <IconButton
                                onClick={() =>
                                    this.setState({
                                        isApproving: true,
                                        approveOrReject:
                                            Constants.Loan.FLAG_PENDING,
                                    })
                                }
                                size="small"
                                color="primary"
                            >
                                <DoneOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-7`}
                align={"right"}
                isCompact={true}
                fillWidth={35}
            >
                {data.status === Constants.Loan.FLAG_PENDING && (
                <Tooltip title="Reject Loan Request">
                    <IconButton
                        onClick={() => this.setState({ isRejecting: true })}
                        size="small"
                        className={classes.rejectButton}
                    >
                        <ClearOutlined />
                    </IconButton>
                </Tooltip>
                   )}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-8`}
                align={"right"}
                isCompact={true}
                fillWidth={35}
            >
                <Tooltip title="View Loan Request">
                    <Link to={{ pathname: `/loans/${data.id}` }} style={{ textDecoration: 'none', color: "primary" }}>
                        <IconButton size="small">
                            <RemoveRedEyeOutlined color="primary" />
                        </IconButton>
                    </Link>
                </Tooltip>
            </DLTableColumn>,
            <DLTableColumn>
                <UserRole
                    routePermissions={[
                        Permissions.ROLE_VIEWABLE,
                        Permissions.ROLE_EDITABLE,
                        Permissions.ROLE_FULL_ACCESS,
                    ]}
                >
                    {(data.status === "approved" ||
                        data.status === "pending" ||
                        data.status === "granted" ||
                        data.status === "partial") && (
                        <Tooltip title="More Options">
                            <IconButton
                                onClick={(e) =>
                                    this.setState({ anchorEl: e.currentTarget })
                                }
                                size="small"
                            >
                                <Icon>more_vert</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                </UserRole>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                    PaperProps={{
                        style: {
                            minWidth: 200,
                        },
                    }}>
                    {
                        data.status === Constants.Loan.FLAG_PENDING &&
                        <>
                            <Link to={{ pathname: `/loans/${data.id}` }} style={{ textDecoration: 'none', color: "rgba(0, 0, 0, 0.8)" }}>
                                <MenuItem>Edit</MenuItem>
                            </Link>
                            <MenuItem onClick={() => this.setState({ anchorEl: null, isDeleting: true })}>
                                Delete Request
                            </MenuItem>
                        </>
                    }
                    {
                        data.status === Constants.Loan.FLAG_APPROVED &&
                        <MenuItem onClick={() => this.setState({ anchorEl: null, paymentModal: true })}>
                            Grant Loan
                        </MenuItem>
                    }
                    {
                        data.status === Constants.Loan.FLAG_GRANT &&
                        data.paid_loan.manual_granted &&
                        <MenuItem onClick={() => this.setState({ anchorEl: null, ungrantLoan: true })}>
                            Ungrant Loan
                        </MenuItem>
                    }
                    {
                        (data.status === Constants.Loan.FLAG_GRANT || data.status === Constants.Loan.FLAG_PARTIAL) &&
                        <MenuItem onClick={() => this.setState({ anchorEl: null, deductLoanModal: true })}>
                            Deduct Loan
                        </MenuItem>
                    }
                </Menu>
            </DLTableColumn>,
            isApproving && (
                <UserRole
                    routePermissions={[
                        Permissions.ROLE_FULL_ACCESS,
                        Permissions.ROLE_EDITABLE,
                    ]}
                >
                    <ConfirmModal
                        title="Approve Loan Request"
                        isOpen={isApproving}
                        message={`Are you sure want to approve this Loan Request?`}
                        onClickCancel={() =>
                            this.setState({ isApproving: false })
                        }
                        onClickConfirm={() => {
                            this.setState({ isApproving: false });
                            this.updateStatus(
                                data,
                                Constants.Loan.FLAG_APPROVED
                            );
                        }}
                    />
                    ,
                </UserRole>
            ),
            isRejecting && (
                <ConfirmModal
                    title="Reject Loan Request"
                    isOpen={isRejecting}
                    message={`Are you sure want to reject this Loan Request?`}
                    onClickCancel={() => this.setState({ isRejecting: false })}
                    onClickConfirm={() => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                            this.setState({ isRejecting: false });
                        }
                        this.updateStatus(
                            data,
                            Constants.Loan.FLAG_REJECTED
                        );
                    }}
                />
            ),
            isDeleting && (
                <ConfirmModal
                    title="Delete Loan"
                    isOpen={isDeleting}
                    message={`Are you sure want to delete this loan?`}
                    onClickCancel={() => this.setState({ isDeleting: false })}
                    onClickConfirm={() => {
                        this.deleteLoan(data.id);
                    }}
                />
            ),
            ungrantLoan && (
                <ConfirmModal
                    title="Ungrant Loan"
                    isOpen={ungrantLoan}
                    message={`Are you sure want to ungrant this loan amounting ${currencyFormatter(data.loan_amount, true)}?`}
                    onClickCancel={() => this.setState({ ungrantLoan: false })}
                    onClickConfirm={() => {
                        this.ungrantLoan(data.id);
                    }}
                />
            ),
            this.state.paymentModal && (
                <LoanPaymentModal
                    loan={data}
                    open={this.state.paymentModal}
                    onClose={() => this.setState({ paymentModal: false })}
                    onUpdate={this.props.reload}
                    heading={"Grant Loan"}
                />
            ),
            this.state.deductLoanModal && (
                <LoanPaymentModal
                    loan={[data]}
                    open={this.state.deductLoanModal}
                    onClose={() => this.setState({ deductLoanModal: false })}
                    onUpdate={this.props.reload}
                    method = {"deduction"}
                    heading={"Deduct Loan"}
                />
            ),
        ];
    }
}

LoanTableRow.Columns = [
    {
        key: "first_name",
        label: "Employee",
        align: "left",
        isSortable: false,
        withSeparator: true,
    },
    {
        key: "loan_amount",
        label: "Loan",
        align: "right",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "duration",
        label: "Duration",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "cycle",
        label: "Repayment Cycle",
        align: "left",
        colSpan:2,
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "return_amount",
        label: "Instalment",
        align: "right",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "remaining_amount",
        label: "Balance",
        align: "right",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "next_payment",
        label: "Next Payment",
        align: "right",
        isSortable: false,
        withSeparator: false,
    },

    {
        key: "created_at",
        label: "Request Date",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "status",
        label: "Status ",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "action",
        label: "",
        align: "right",
        isSortable: false,
        colSpan: 4,
        withSeparator: false,
    },
];

export default withRouter(withStyles(styles)(LoanTableRow));
