import clsx from "clsx";
import React, { PureComponent } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { IconButton, withStyles, Grid, Button } from "@material-ui/core";
import MyToolbar from "./myToolbar";
import moment from "moment";
import { DateRange } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";


class CustomElements extends PureComponent {
    componentDidMount() {
        moment.updateLocale("en", {
            week: {
                dow: 1
            }
        });
    }
    state = {
        selectedDate: this.props.date || new Date(),
        open: false
    };

    handleWeekChange = date => {
        this.setState({ selectedDate: moment(date).startOf("week") }, () => {
            this.props.onDateChange(date);
        });
    };

    formatWeekSelectLabel = (date, invalidLabel) => {
        let dateClone = date;

        return dateClone && moment(dateClone).isValid()
            ?
            `${moment(dateClone).startOf("isoWeek").format("DD")} - ${moment(dateClone).endOf("isoWeek").format("DD")} ${moment(date).format("MMM YYYY")}`
            : invalidLabel;
    };

    renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
        const { classes } = this.props;

        let dateClone = moment(date).clone();
        let selectedDateClone = moment(selectedDate).clone();

        const start = moment(selectedDateClone).startOf("week").toDate();
        const end = moment(selectedDateClone).endOf("week").toDate();
        const dayIsBetween = moment(dateClone).isBetween(start, end, null, []);
        const isFirstDay = moment(dateClone).isSame(start, "day");
        const isLastDay = moment(dateClone).isSame(end, "day");

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay
        });

        const dayClassName = clsx(classes.day);

        return (
            <div>
                <div className={wrapperClassName}>
                    <IconButton className={dayClassName}>
                        <span>{dateClone.format("DD")}</span>
                    </IconButton>
                </div>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    minDate={moment("1-1-1500", "DD-MM-YYYY")}
                    maxDate={moment("1-1-5000", "DD-MM-YYYY")}
                    open={this.state.open}
                    value={this.props.selectedDate}
                    onChange={this.handleWeekChange}
                    renderDay={this.renderWrappedWeekDay}
                    labelFunc={this.formatWeekSelectLabel}
                    ToolbarComponent={MyToolbar}
                    cancelLabel={<span onClick={() => { this.setState({ open: false }) }} style={{ color: "#2F80ED" }}>Cancel</span>}
                    onAccept={() => { this.setState({ open: false }) }}
                    TextFieldComponent={() => {
                        return <Grid
                            className={classes.dateText}
                            onClick={() => { this.setState({ open: true }) }}>
                            <Button type="submit" startIcon={<DateRange color={"primary"} />} variant="outlined" color={"primary"} sx={{ borderRadius: 28 }}>{` ${moment(this.props.selectedDate).startOf("isoWeek").format("DD")} - ${moment(this.props.selectedDate).endOf("isoWeek").format("DD")} ${moment(this.props.selectedDate).format("MMM YYYY")}`}</Button>
                        </Grid>
                    }}
                />
            </MuiPickersUtilsProvider>
        );
    }



}

const styles = createStyles(theme => ({
    dayWrapper: {
        position: "relative"
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit"
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main} `,
        borderRadius: "50%"
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
        color: "#676767"
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%"
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%"
    },

    dateText: {
        display: "flex", justifyContent: "center",
        alignItems: "center",
        fontFamily: theme.typography.fontFamily,
        fontSize: "15.6px",
        fontWeight: "500",
    }
}));

export default withStyles(styles)(CustomElements);
