import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { Api, Utility } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style";
import _ from "lodash";
class AddDepartment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: this.props.textFieldValue || "",
			code: (this.props.textFieldValue && Utility.getInitials(this.props.textFieldValue))|| "",
			departmentId: "",
			errors: {},
			title: props.title || "Add Department"
		};
	}

	async componentDidMount() {
		if (this.props.match.params && this.props.match.params.id > 0) {
			this.props.setLoader(false);
			this.setState({ isLoading: true, isEdit: true });
			let response = await Api.departments(
				this.props.match.params.id
			).get();
			this.setState({
				name: response.name,
				code: response.code,
				departmentId: response.id
			}, () => {
				this.props.setLoader(false);;
			});
		}
	}

	isValidData = () => {
		var errors = {}
		if (this.state.code === "") {
			errors.code = "Code field is required";
		}
		if (this.state.name === "") {
			errors.name = "Name field is required";
		}
		this.setState({ errors: errors });
		return (_.size(errors) > 0) ? false : true;
	};


	closeDialog = () => {
		if (this.props.onClose) {
			this.props.onClose()
		} else {
			this.props.history.push("/settings/departments");
		}
	}


	createDepartment = async () => {
		if (this.isValidData()) {
			var data = new FormData();
			this.props.setLoader(true);
			data.append("name", this.state.name);
			data.append("code", this.state.code);
			try {
				if (!this.state.departmentId) {
					await Api.departments.post(data);
				} else {
					data.append("_method", "put");
					await Api.departments(this.state.departmentId).post(data, {
						headers: {
							"Content-Type": "multipart/form-data",
						}
					}
					);
				}
				this.props.setSnackbar(true, this.state.departmentId ? "Department Updated" : "Department Added");
				this.props.onUpdate();
				this.closeDialog(); 
			}
			catch (e) {
				this.setState({ errors: { ...e.response } }, () => {
					this.props.setLoader(false);
				})
			}
			this.props.setLoader(false);
		}
	}

	handleChange = (e, fieldName) => {
		this.setState({ [fieldName]: e.target.value })
		if (this.state.errors[fieldName] === true) {
			this.setState({
				errors: {
					...this.state.errors,
					[fieldName]: false
				}
			});
		}
	};

	render() {

		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={"new"}
				onBackdropClick={this.closeDialog}
				aria-labelledby="version-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="version-dialog-title">{this.state.title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								size="small"
								fullWidth
								error={this.state.errors.name}
								helperText={this.state.errors.name}
								label="Name"
								placeholder="Name"
								value={this.state.name}
								onChange={(e) => this.handleChange(e, "name")}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								size="small"
								fullWidth
								error={this.state.errors.code}
								helperText={this.state.errors.code}
								placeholder="Code"
								label="Code"
								id="confirmPassword"
								value={this.state.code}
								onChange={(e) => this.handleChange(e, "code")}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={() => {
						this.closeDialog();
					}} color="default">Cancel</Button>
					<Button variant="contained" size="small" onClick={this.createDepartment} color="primary">Save</Button>
				</DialogActions>
			</Dialog>
		)

	}
}
AddDepartment.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	departments: state.departments.list,
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddDepartment)));