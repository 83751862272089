import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
	Card,
	CardActions,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import { DateRange, DLTable, EmployeeDropdown, BackButton, InfographicMessage, Page } from "@/components";
import LeaveStatementRow from "./LeaveStatementRow";
import { Api } from "@/helpers";
import styles from "./style";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { setLoader, setSnackbar } from "@/redux/actions/general";

class LeaveStatement extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.state = {
			from: null,
			to: null,
			employee: null,
			anchorEl: null,
			needEmailConfirmation: false
		};
	}

	export = (type = 'xlsx') => {
		const { from, to, employee } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.employees(employee).leave_wallet.export.getUrl({ from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD"), type }))
	};

	email = async () => {
		const { from, to, employee } = this.state;
		this.setState({ needEmailConfirmation: false });

		try {
			await Api.employees(employee).leave_wallet.email.post({
				from: from.format("YYYY-MM-DD"),
				to: to.format("YYYY-MM-DD")
			});
			this.props.setSnackbar(true, "Statement emailed successfully");

		} catch (e) {
			this.props.setSnackbar(true, "Unable to send statement via email, please try again later", 'error');
		}
	};

	reload = () => {
		this._table && this._table.fetchData(true);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="LeaveStatement"
				message="No transactions for selected employee!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, to, employee, needEmailConfirmation } = this.state;
        return (
			<Page title="Leaves Statement">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<div className={classes.taxReportFilter}>
							<DateRange onChange={(from, to) => this.setState({ from, to }, this.reload)}/>
							<EmployeeDropdown
								textFieldProps={{ margin: "dense" }}
								value={employee}
								onChange={employee => this.setState({ employee }, this.reload)}
							/>
						</div>
						{
							!!from && !!to && !!employee &&
							<>
								<IconButton
									onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
									<Icon>more_vert</Icon>
								</IconButton>
								<Menu
									id="long-menu"
									anchorEl={this.state.anchorEl}
									open={!!this.state.anchorEl}
									onClose={() => this.setState({ anchorEl: null })}
									PaperProps={{
										style: {
											width: 200,
										},
									}}>
									
									<MenuItem onClick={() => this.export('xlsx')}>Export XLSX</MenuItem>
									<MenuItem onClick={() => this.export('pdf')}>Export PDF</MenuItem>
									<div className={classes.divider}></div>
									<MenuItem onClick={() => this.setState({ needEmailConfirmation: true })}>Send via Email</MenuItem>
								</Menu>
								<ConfirmModal
									isOpen={needEmailConfirmation}
									message="Are you sure to send statement to the selected employee?"
									onClose={() => this.setState({ needEmailConfirmation: false })}
									onClickConfirm={() => this.email()}
								/>
							</>
						}
					</CardActions>
					{
						from && to &&
						<DLTable
							ref={ref => this._table = ref}
							api={Api.employees(employee).leave_wallet}
							params={{ from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") }}
							defaultOrder="desc"
							RowTemplate={LeaveStatementRow}
							emptyView={this.emptyView()}
							showPagination={false}
						/>
					}
				</Card>
			</Page>
        );
    }

}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(LeaveStatement)));