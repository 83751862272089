
export function setList(list, isReset = true) {
    return {
        type: 'SET_PAYITEMS',
        list,
        isReset
    }
}

export function setMeta(meta) {
    return {
        type: 'SET_META',
        meta,
    }
}
