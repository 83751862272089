export function setSnackbar(visible = false, message = '', variant = 'success', onClose = (() => { })) {
    return {
        type: 'SET_SNACKBAR',
        snackbar: {
            visible: visible,
            message: message,
            variant: variant,
            onClose: onClose
        },
    }
}

export function setLoader(visible = false, primaryColor = 'primary') {
    return {
        type: 'SET_LOADER',
        loader: {
            visible: visible,
            primaryColor: primaryColor
        },
    }
}

export function resetSnackbar() {
    return {
        type: 'RESET_SNACKBAR',
    }
}

export function setTitle(title) {
    return {
        type: 'SET_TITLE',
        title
    }
}

export function setFilterCount(payload) {
    return {
        type: "SET_FILTER",
        payload
    }
}

export function setActiveEmployees(employees = 1, seatsAllowed = 0, isGracePeriod = false, graceEndDate = 0) {
    return {
        type: 'SET_ACTIVE_EMPLOYEES',
        employees,
        seatsAllowed,
        isGracePeriod,
        graceEndDate
    }
}

export function addActiveEmployees(employees = 1) {
    return {
        type: 'ADD_ACTIVE_EMPLOYEES',
        employees
    }
}

export function subtractActiveEmployees(employees = 1) {
    return {
        type: 'SUBTRACT_ACTIVE_EMPLOYEES',
        employees
    }
}