import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    MenuItem,
    DialogContent,
} from "@material-ui/core";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style";
import _ from "lodash";
import classNames from "classnames";

class AddSuggestionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoanAmount: 0,
            duration: 0,
            cycle: 0,
            returnAmount: 0,
            message: "",
            suggesstionId: null,
            errors: {},
            title: props.title || "Add Suggestion",
            filter: [
                {
                    field: "loan_request_id",
                    operator: "and",
                    isEdit: false,
                    durationType: null,
                    cycleType: null,
                    value: this.props.data && this.props.data.loan_request_id,
                    method: "is",
                },
            ],
        };
    }

    async componentDidMount() {
        const { data, loan } = this.props;
        if (this.props.data && this.props.data.id) {
            this.props.setLoader(false);
            this.setState({ isLoading: true, isEdit: true });
            this.setState(
                {
                    LoanAmount: data.loan_amount,
                    duration: data.duration,
                    message: data.message,
                    returnAmount: data.return_amount,
                    cycle: data.cycle,
                    suggesstionId: data.id,
                    durationType: data.duration_type,
                    cycleType: data.cycle_type,
                },
                () => {
                    this.props.setLoader(false);
                }
            );
        } else if (this.props.loan && this.props.loan.id) {
            this.props.setLoader(false);
            this.setState(
                {
                    LoanAmount: loan.loan_amount,
                    duration: loan.duration,
                    returnAmount: loan.return_amount,
                    cycle: loan.cycle,
                    durationType: loan.duration_type,
                    cycleType: loan.cycle_type,
                },
                () => {
                    this.props.setLoader(false);
                }
            );
        }
    }

    isValidData = () => {
        var errors = {};
        const { data, loan } = this.props;
        if (
            this.state.cycle ==
                ((data && data.cycle) || (loan && loan.cycle)) &&
            this.state.returnAmount ==
                ((data && data.return_amount) ||
                    (loan && loan.return_amount)) &&
            this.state.LoanAmount ==
                ((data && data.loan_amount) || (loan && loan.loan_amount))
        ) {
            errors["duration"] = `${_.startCase("message")} field is required`;
            this.props.setSnackbar(
                true,
                "Nothing seems to change in suggestions",
                "error"
            );
        }

        if ( this.state.LoanAmount == "" ) {
            errors["LoanAmount"] = `${_.startCase("Loan Amount")} field is required`;
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error" );
        }
        if ( this.state.returnAmount == "" ) {
            errors["returnAmount"] = `${_.startCase("return Amount")} field is required`;
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error" );
        }
        if ( this.state.cycle == "" ) {
            errors["cycle"] = `${_.startCase("cycle")} field is required`;
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error" );
        }
        if ( this.state.cycleType == "" ) {
            errors["cycleType"] = `${_.startCase("cycle Type")} field is required`;
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error" );
        }
        this.setState({ errors: errors });
        return _.size(errors) > 0 ? false : true;
    };

    closeDialog = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    prepareData = () => {
        var durationNew = "";
        if (this.state.cycleType === "Years") {
            durationNew = (this.state.LoanAmount / this.state.returnAmount) * (this.state.cycle * 12);
            durationNew = durationNew / 12;
        } else if (this.state.cycleType === "Months") {
            durationNew = (this.state.LoanAmount / this.state.returnAmount) *  this.state.cycle;
        }
        
        let data = {
            _method: this.state.suggesstionId && `PUT`,
            loan_request_id :  this.props.loan && this.props.loan.id,
            loan_amount : this.state.LoanAmount,
            duration:Math.ceil(durationNew),
            duration_type:  this.state.cycleType === "Years" ? "Years" : `Months`,
            cycle_type: this.state.cycleType,
            return_amount: this.state.returnAmount,
            message: this.state.message,
            cycle: this.state.cycle,
        }
        return data;
    }

    createSuggestion = async () => {
        if (this.isValidData()) {
            var data = this.prepareData();
            try {
                this.props.setLoader(true);
                if (!this.state.suggesstionId) {
                    await Api.loan_requests(this.props.loan && this.props.loan.id).suggestion.post(data);
                } else {
                    await Api.loan_requests(this.props.data && this.props.data.id).loan_suggestion(this.state.suggesstionId).post(data);
                }
                this.props.setSnackbar( true,this.state.suggesstionId ? "Suggestion Updated" : "Suggestion Added" );
                this.props.onUpdate();
                this.closeDialog();
            } catch (e) {
                HttpErrorHandler.intercept(e)
                    .on(422, (response) => {
                        const errors = _.values(_.mapValues(response.data, value => value[0]));
                        if (errors && errors.length) {
                            this.props.setSnackbar(true, errors[0], "error");
                        } else {
                            this.props.setSnackbar(
                                true,
                                "Something went wrong, please try again",
                                "error"
                            );
                        }
                    })
                    .default((_) =>
                        this.props.setSnackbar(
                            true,
                            "Something went wrong, please try again later",
                            "error"
                        )
                    )
                    .go();
                this.setState({ errors: { ...e.response } }, () => {
                    this.props.setLoader(false);
                });
            }
            this.props.setLoader(false);
        }
    };

    handleChange = (value, fieldName) => {

        this.setState({ [fieldName]: value ,
            errors: {
                ...this.state.errors,
                [fieldName]: false
            } });
    };

    render() {
        const { open, classes } = this.props;
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onBackdropClick={this.closeDialog}
                aria-labelledby="version-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="version-dialog-title">
                    {this.state.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                type="number"
                                fullWidth
                                error={this.state.errors.LoanAmount}
                                helperText={this.state.errors.LoanAmount}
                                label="Loan Amount"
                                value={this.state.LoanAmount}
                                onChange={(e) =>
                                    this.handleChange(
                                        e.target.value,
                                        "LoanAmount"
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                type="number"
                                helperText={this.state.errors.cycle}
                                error={!!this.state.errors.cycle}
                                label="Cycle"
                                className={classNames([classes.textFieldType])}
                                style={{ marginRight: 5 }}
                                value={this.state.cycle}
                                onChange={(e) =>
                                    this.handleChange(e.target.value, "cycle")
                                }
                                variant="outlined"
                            />
                            <TextField
                                select
                                size="small"
                                label="Cycle Type"
                                variant="outlined"
                                error={!!this.state.errors.cycleType}
                                helperText={this.state.errors.cycleType}
                                value={this.state.cycleType || ""}
                                className={classNames([classes.textFieldType])}
                                onChange={(e) =>
                                    this.handleChange(
                                        e.target.value,
                                        "cycleType"
                                    )
                                }
                            >
                                <MenuItem value="Months">Months</MenuItem>
                                <MenuItem value="Years">Years</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                error={this.state.errors.returnAmount}
                                helperText={this.state.errors.returnAmount}
                                label="Return Amount"
                                placeholder="Return Amount"
                                type="number"
                                value={this.state.returnAmount}
                                onChange={(e) =>
                                    this.handleChange(
                                        e.target.value,
                                        "returnAmount"
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                error={this.state.errors.message}
                                helperText={this.state.errors.message}
                                label="Message"
                                multiline={true}
                                rows={4}
                                value={this.state.message}
                                onChange={(e) =>
                                    this.handleChange(e.target.value, "message")
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        onClick={() => {
                            this.closeDialog();
                        }}
                        color="default"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={this.createSuggestion}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withStyles(styles)(AddSuggestionModal))
);
