import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { ArchiveOutlined, Inbox } from "@material-ui/icons";
import { DLTable, Tabs, InfographicMessage, Page, BulkAction, BackButton, PermissionHOC } from "@/components";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import _ from "lodash";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import BankTableRow from "./BankTableRow";
import { Api, HttpErrorHandler } from "@/helpers";

class Bank extends Component {
	constructor(props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.bulkAction = React.createRef();
		this.tableRef = React.createRef();
		this.state = {
			activeTabIndex: 0,
			selectedCount: 0,
		}
	}

	addNew = () => {
		this.props.history.push("/settings/banks/add");
	};

	emptyView(archived = false) {
		const message = !archived
			? "You don't have any bank yet, add one now!"
			: "Yaayyy! all banks are active and in use";
		return (
			<InfographicMessage
				illustration="bank"
				message={message}
				actionLabel={archived ? "" : "Add Bank"}
				actionIcon={archived ? "" : "add"}
				actionHandler={this.addNew}
			/>
		);
	}

	async delete(selected) {
		this.props.setLoader(true);
		try {
			await Api.banks.delete_multiple.delete({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items archived successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error");
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	async restore(selected) {
		this.props.setLoader(true);
		try {
			await Api.banks.restore_multiple.patch({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items restored successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error");
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	getItems() {
		const { activeTabIndex} = this.state
		var buttonName = !!activeTabIndex ? "Restore Selected" : "Archive Selected";
		var buttonColors = !!activeTabIndex ? "primary" : "secondary";

		let items = [{
				name: buttonName,
				color: buttonColors,
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={!!activeTabIndex ? "Restore Bank" : "Archive Bank"}
						message={`Are you sure you want to ${!!activeTabIndex ? "restore" : "archive"}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							if (!!activeTabIndex) {
								await this.restore(selected);}
							else{
								await this.delete(selected);
							}
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}];
		return items;
	}

	actionBar() {
		const { activeTabIndex } = this.state;

		return (
			<CardActions>
				<Link to={{ pathname: `/settings/banks/add` }} style={{ textDecoration: 'none', color: "black" }}>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						onClick={this.addNew}>
						<Icon>add</Icon>
						Add Bank
					</Button>
				</Link>
				<BulkAction
					ref={this.bulkAction}
					options={this.getItems()}
				/>
			</CardActions>
		);
	}
	
	onSelectionChange = selected => {
		this.setState({ selectedCount : selected.length});
	}
	
	render() {
		return (
			<Page title="Banks">
				<BackButton route="/settings" label="Back to Settings" />
				<Card raised>
					<Tabs 
						beforePanel={this.actionBar()}
						onChange={activeTabIndex => this.setState({ activeTabIndex }, this.onSelectionChange([]))}>
						<Tabs.Tab
							icon={<Inbox />}
							label="Active">
							<DLTable
								enableSelection
								ref={this.tableRef}
								api={Api.banks}
								RowTemplate={BankTableRow}
								bulkAction={this.bulkAction}
								emptyView={this.emptyView()} />
						</Tabs.Tab>
						<Tabs.Tab
							icon={<ArchiveOutlined />}
							label="Archived">
							<DLTable
								enableSelection
								ref={this.tableRef}
								api={Api.banks}
								params={{ filter: { archived: true } }}
								RowTemplate={BankTableRow}
								bulkAction={this.bulkAction}
								emptyView={this.emptyView(true)} />
						</Tabs.Tab>
					</Tabs>
				</Card>
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(Bank));
