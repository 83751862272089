import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import { AuthContext } from "@/contexts";
import OrganizationBranchModal from "@/components/Modals/OrganizationBranchModal";
import PermissionHOC from "@/components/PermissionHOC";
import { Permissions } from "@/config/Permissions";

class BranchDropdown extends Component {

	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this._asyncDropDown = React.createRef();
	}

	static defaultProps = {
		label: "Branch",
		onChange: () => { }
	};

	static propTypes = {
		textFieldProps: PropTypes.object,
		value: PropTypes.number,
		onChange: PropTypes.func
	};

	state = {
		branch: null,
		isModalOpen: false,
		organization: null,
		textFieldValue: ""
	};

	modalclose = () => {
		this.setState({ isModalOpen: false }, async () => {
			await this._asyncDropDown.current.loadOptions()
		});
	}

	async componentDidMount() {
		this.loadSelected();
		const organization = await Api.organizations(this.context.user.organization_id).get();
		this.setState({ organization });
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value && (!this.state.branch || (this.state.branch.id !== this.props.value))) {
			this.loadSelected();
		}
	}

	async loadSelected() {
		if (this.props.value) {
			try {
				const branch = await Api.organizations(this.context.user.organization_id).branches(this.props.value).get();
				return this.setState({ branch });
			} catch (e) { }
		}
		this.setState({ branch: null });
	}

	getOptions = async (keyword = "") => {
		this.setState({ textFieldValue: keyword })
		const {  hasPermission } = this.props;
		this.setState({ loading: true });
		const response = await Api.organizations(this.context.user.organization_id).branches.get({ per_page: 50, filter: { name: keyword } });
		if(hasPermission([Permissions.ORGANIZATION_EDITABLE,Permissions.ORGANIZATION_FULL_ACCESS])){
			var createNew = response.data;
			createNew = [...createNew, { name: "Create New Branch", type: "branch" }];
			response.data = createNew;
		}
		return response.data;
	};

	renderOption = option => (
		<div>
			{option.name}
		</div>
	);

	onChange = (event, branch) => {
		if (branch && branch.id) {
			this.setState({ branch });
			this.props.onChange((branch && branch.id) || null);
		} else if (branch === null) {
			this.props.onChange(null)
		} else {
			this.setState({ isModalOpen: true,textFieldValue: this.state.textFieldValue })
		}
	};

	render() {
		const { classes, label, disabled, textFieldProps } = this.props;
		return (
			<>
				<AsyncDropdown
					ref={this._asyncDropDown}
					classes={{ root: classes.dropdown, paper: classes.dropdown }}
					label={label}
					textFieldProps={textFieldProps}
					getOptions={this.getOptions}
					getOptionLabel={option => option.name}
					getOptionSelected={(option, value) => option.id === value.id}
					onChange={this.onChange}
					value={this.state.branch}
					disabled={!!disabled}
				/>
				{
					this.state.isModalOpen &&
					<OrganizationBranchModal
						backRoute={"goback"}
						avoidRoute={true}
						title={"Add Branch"}
						organization={this.state.organization}
						textFieldValue={this.state.textFieldValue}
						isOpen={this.state.isModalOpen}
						onUpdate={async () => await this._asyncDropDown.current.loadOptions()}
						onClose={this.modalclose}
					/>
				}
			</>
		);
	}

}

export default withStyles(styles)(PermissionHOC(BranchDropdown));