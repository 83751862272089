const styles = theme => ({

	addressHeader: {
		display: "flex",
		justifyContent: "space-between"
	},

	actions: {
		padding: theme.spacing(2)
	},

	actionButton: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},

	actionButtonIcon: {
		marginRight: theme.spacing(1)
	},
	labelGrid: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: theme.spacing(2),
		alignItems: 'center'
	},
	mtop: {
		marginTop: theme.spacing(1.5)
	},
	containerGrid: {
		marginTop: theme.spacing(2),
	},
	textField: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	marginVertical: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	formControl: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		marginTop: theme.spacing(1)
	},
	footer: {
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(2),
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0
		}
	},
	createBtn: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},

});

export default styles;