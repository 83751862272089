import axios from "axios";
import qs from "qs";
import moment from "moment";
import Config from "@/config";
import Store from "@/redux";
import { setToken, resetToken } from "@/redux/actions/auth";
import { setSnackbar } from "@/redux/actions/general";
import { HttpErrorHandler } from "@/helpers";

// Allowed HTTP methods
var methods = ["get", "post", "put", "delete", "patch"];

let baseURL  = Config.apiEndpoint.replace(/(.+)\/$/, "$1");

var getApi = function(type = "access") {
	const token = Store.getState().auth.token;

	return axios.create({
		baseURL: baseURL,
		responseType: "json",
		headers: token ? { Authorization: `Bearer ${token[type + "_token"]}`} : {}
	});
}

const redirectToLogin = function() {
	Store.dispatch(resetToken());
	window.location.reload();
}

// Add support for dynamically generated APIs
var apiHandler = function (url) {
	const token = Store.getState().auth.token;
	var Api = getApi();
	
	return {
		get: (target, name) => {
			var apiURL = url || "";
			var apiCall = (params, config = null) => {
				if (name === "getUrl") {
					if (params === true) {
						params = { t: moment().valueOf() };
					} else if (!params) {
						params = { };
					}
					params.token = token.access_token;
					params.version = "1.0"; 
					params.platform = "web";
					return `${Api.defaults.baseURL}${apiURL}` + (typeof params === "object" ? `?${qs.stringify(params)}` : "");

				} else if (methods.indexOf(name) === -1) {
					return new Proxy({}, apiHandler(`${apiURL}/${name}/${String(params || "")}`));
				}
				return new Promise((resolve, reject) => {
					if(params instanceof FormData) {
						params.append("version", "1.0");
						params.append("platform", "web");
					
					} else {
						params = { ...params, version: "1.0", platform: "web" };
					}

					if ((["get", "delete"].indexOf(name) !== -1) && params) {
						apiURL += `?${qs.stringify(params)}`;
					}

					Api[name](apiURL, params, config)
					.then(response => {
						if(apiURL.match(/logout\?/g)) {
							redirectToLogin();

						} else {
							Store.dispatch(setToken({
								access_token: token.access_token, 
								access_expiry: token.access_expiry + 1740  // 29 minutes
							}))

							resolve(response.data)
						}
					})
					.catch(error => {
						// TODO: do other generalized error handling
						HttpErrorHandler
							.intercept(error)
							.on(401, response => {
								if(apiURL !== "me") {
									Store.dispatch(setSnackbar(true, response.data.message, "error"));
									redirectToLogin();
								}
							})
							.on(402, response => {
								Store.dispatch(setSnackbar(true, response.data.message, "error"));
								window.location.reload();
							})
							.default(() => reject(error))
							.go();
						
					});
				});
			}
			apiCall.__proto__ = new Proxy({}, apiHandler(`${apiURL}/${name}`));

			return apiCall;
		}
	}
}

// Expose APIs
export default new Proxy({}, apiHandler());