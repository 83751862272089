const styles = theme => ({

    formField: {
        "&:not(:last-child)": {
            marginBottom: theme.spacing(2)
        }
    },

    cardActionsRight: {
        flexDirection: "row-reverse",
        padding: theme.spacing(2),

        "& > *:not(:last-child)": {
            marginLeft: theme.spacing(1)
        }
    },

    letterheadPicker: {
        marginRight: 0,
        maxWidth: "100%",
        height: 200,
        width: 154
    },

    letterheadPickerOverlay: {
        flexDirection: "column",
        justifyContent: "center",

        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    },

    flex: {
        flex: 1,

        [theme.breakpoints.down("xs")]: {
            flex: "unset"
        }
    },

    attendanceRoot: {
        marginTop: theme.spacing(2)
    },

    labelGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },

});

export default styles