import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style.js";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel

} from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import { DatePicker } from "@material-ui/pickers";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
class HolidayModal extends Component {
    static propTypes = {
        mode: PropTypes.oneOf(["new", "edit", "exit"]),
        holidays: PropTypes.object,
        title: PropTypes.string,
        onClose: PropTypes.func,
        onUpdate: PropTypes.func
    };

    static defaultProps = {
        mode: "new",
        title: "New Holiday",
        onUpdate: () => { }
    };

    state = {
        errors: {},
        holiday: {},
        type: ""
    };

    holiday() {
        return {
            name: "",
            comment: "",
            type: "",
            date: moment(moment.now(), "x").format("YYYY-MM-DD HH:mm:ss"),
            ...this.props.holiday,
            ...this.state.holiday
        };
    }

    handleChange(value, field) {
        const holiday = this.holiday();
        const { errors } = this.state;
        if (errors[field]) {
            errors[field] = false;
        }
        this.setState({
            holiday: {
                ...holiday,
                [field]: value
            },
            errors
        });
    }

    validate() {
        var errors = {};
        if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });
		return !_.size(errors);
    }

    prepareData = () => {
        let holiday = this.holiday();
        let data = {
            id: holiday.id,
            date: moment((holiday.date)).format("YYYY-MM-DD"),
            comment: holiday.comment
        }
        return data;
    }

    save = async (e) => {
        e.preventDefault();
        if (!this.validate()) return;
       const {holiday:id} =this.props
        var data = this.prepareData();
        this.props.setLoader(true);
        try {
            if (!id) {
                await Api.organization_calendar.post(data);
            } else {
                await Api.organization_calendar(id.id).put(data);
            }
            this.props.setSnackbar(true, "saved successfully");
            this.props.onUpdate();
            this.props.onClose();
        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        response.message || "Something went wrong, please try again later",
                        "error"
                    )
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )

                .go();
        }
        this.props.setLoader(false);
    };

    render() {

        const { mode, title } = this.props;
        const { errors } = this.state;
        const holiday = this.holiday();
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={mode}
				onBackdropClick={this.props.onClose}
                aria-labelledby="employee-holiday-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="employee-holiday-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatePicker
                                autoOk
                                fullWidth
                                size="small"
                                error={!!errors.date}
                                helperText={errors.date}
                                label="Date"
                                inputVariant="outlined"
                                format={Config.DISPLAY_DATE_FORMAT}
                                value={holiday.date||null}
                                onChange={(e) => {
                                    this.handleChange(e, "date")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={holiday.comment || ""}
                                error={!!errors.comment}
                                helperText={errors.comment}
                                fullWidth
                                id="outlined-multiline-static"
                                label="Comment"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={(e) => this.handleChange(e.target.value, "comment")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="default" size="small">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={this.save} color="primary" size="small">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(HolidayModal));
