import _ from "lodash";
var payitem = {
	list:[],
	meta: {}
};

export default function (state = payitem, action) {
	switch (action.type) {
		case "SET_META":
			return {
				...state,
				meta: action.meta
			}

		case "SET_PAYITEMS":
			var list = []
			if (!action.isReset) {
				list = state.list
			}
			list.push(action.list);

			return {
				...state,
				list: _.flattenDeep(list)
			}

		default:
			return state;
	}
}
