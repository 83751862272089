const styles = (theme) => ({
	root: {
		width: "100%",
	},

	avatar: {
		width: 40,
		height: 40,
		border: `1px ${theme.palette.borderColor.main} solid`,
		boxShadow: theme.shadows[2],
	},

	paidFab: {
		height: "24px !important",
		fontSize: "0.8125 rem !important",
		fontWeight: "normal !important",
		boxShadow: theme.shadows[1],
	},

	employeeListRoot: {
		height: "calc(100vh - 200px)",
		minHeight: "100%",
		overflow: "hidden",
	},
	fileInput: {
		display: "block",
		cursor: "pointer",
		outline: "none",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		overflow: "hidden",
		filter: "alpha(opacity=0)",
		opacity: "0",
	},
	employeeList: {
		paddingTop: 0,
		height: "100%",
		overflow: "auto",
	},

	employeeListSearchItem: {
		position: "sticky",
		top: 0,
		borderBottom: `1px rgba(0, 0, 0, 0.12) solid`,
		backgroundColor: "#ffffff",
		zIndex: 1,
	},

	employeeListSearchBox: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	employeeListAvatar: {
		border: `2px #fff solid`,
		boxShadow: theme.shadows[2],
	},

	employeeListIcon: {
		minWidth: "unset",
		marginLeft: theme.spacing(1),
	},
	employeePaginationRoot: {
		borderBottom: 0,
	},
	employeePaginationToolbarRoot: {
		paddingLeft: 0,
	},
	taxReportActions: {
		display: "flex",
		justifyContent: "space-between",
	},
	text: {
		fontSize: "25px",
		whiteSpace: "pre-line",
		fontWeight: "bold",
		textAlign: "right",
	},
	textDate: {
		fontSize: "15px",
		whiteSpace: "pre-line",
		fontWeight: "bold",
	},
	reminderItemCount: {
		width: "25.54px",
		height: "13.95px",
		paddingTop: " 0px",
		paddingLeft: "2px",
		paddingBottom: "4.61556px",
		gap: "8.72px",
		borderRadius: "25px",
		textAlign: "center",
		backgroundColor: "#FFFFFF",
	},
	reminderItemContainer: {
		backgroundColor: theme.color.orange.warning,
		flexDirection: "row",
		justifyContent: "center",
		columnGap: "6.4px",
		alignItems: "center",
		alignContent: "center",
		cursor: "pointer",
		marginRight: "5px",
	},
	itemContainer: {
		display: "flex",
		flexDirection: "row",
		padding: 3,
		justifyContent: "space-between",
	},
	deductionAmount: {
		display: "flex",
		flexDirection: "column",
		fontWeight: 500,
		color: "#E20613",
		fontStyle: "normal",
	},
	earningAmount: {
		display: "flex",
		flexDirection: "column",
		fontWeight: 500,
		color: "#153F6D",
		fontStyle: "normal",
	},
	payItemName: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: "#153F6D",
		fontWeight: 300,
		fontStyle: "normal",
	},
	leaveName: {
		display: "flex",
		flexDirection: "row",
		color: "#153F6D",
		fontWeight: 300,
		fontStyle: "normal",
		alignItems: "center"
	},
	notificationIcon:{
		color: "white",
		width: "25.54px",
		height: "20.95px",
	},
	netPayable:{
		fontWeight: 500,
		fontStyle: "normal",
		color: "#2A9DFF",
	},
	pointer: {
		cursor: "pointer"
	}
});

export default styles;
