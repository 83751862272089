import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from '@material-ui/core';
import classNames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import styles from "./style.js";
import _ from "lodash";

class DLTableColumn extends React.Component {

    render() {
        const { isCompact, withSeparator, align, variant, classes, children, fillWidth, toggleSwitch, onClick, style, stickyHeader, ...columnProps } = this.props;
        var columnClasses = {
            [classes.DLTableColumn]: true,
            DLTableColumnSeparated: withSeparator,
            DLTableColumnCompact: isCompact,
            DLTableColumnStickyHeader:stickyHeader,
        };

        return (
            <TableCell
                onClick={onClick}
                {...columnProps}
                padding={isCompact ? "none" : null}
                variant={variant}
                style={style}
                align={align}
                width={(fillWidth && children && (!_.isArray(children) || (_.isArray(children) && children.length && _.some(children)))) ? fillWidth : undefined}
                className={classNames(columnClasses)}>
                {children}
                {
                    variant === "head" && toggleSwitch &&
                    <Switch
                        size="small"
                        color="primary"
                        checked={this.props.headingToggle}
                        onChange={(e) => {
                            this.setState({ headingToggle: !this.props.headingToggle }, () => {
                                this.props.changeHeadingToggle(!this.props.headingToggle)
                            })
                        }}
                    />
                }
            </TableCell>
        );
    }

}

DLTableColumn.defaultProps = {
    isCompact: false,
    withSeparator: false,
    align: "left",
    variant: "body",
    fillWidth: null,
    toggleSwitch: false
}

DLTableColumn.propTypes = {
    key: PropTypes.string,
    isCompact: PropTypes.bool,
    withSeparator: PropTypes.bool,
    align: PropTypes.oneOf(["left", "right", "center"]),
    variant: PropTypes.oneOf(["head", "body"]),
    fillWidth: PropTypes.number,
    toggleSwitch: PropTypes.bool
}

export default withStyles(styles)(DLTableColumn);