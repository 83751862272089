import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, TextField, Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./style";
import _ from "lodash";
import { Api, Utility } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
class AddDesignation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			name: this.props.textFieldValue || "",
			code:( this.props.textFieldValue && Utility.getInitials(this.props.textFieldValue)) || "",
			designationId: "",
			designation: {}
		};
	}
	isEdit = () => {

		return (this.props.match.params && this.props.match.params.designationId > 0);
	}
	async componentDidMount() {
		if (this.props.match.params && this.props.match.params.id > 0) {

			this.props.setLoader(false);
			this.setState({ isLoading: true, isEdit: true });
			let response = await Api.designations(
				this.props.match.params.id
			).get();
			this.setState({
				designation: response,
				name: response.name,
				code: response.code,
				designationId: response.id
			}, () => {
				this.props.setLoader(false);
			});
		}
	}

	isValidData() {
		var errors = {};
		if (this.state.code === "") {
			errors.code = "Code field is required";
		}
		if (this.state.name === "") {
			errors.name = "Name field is required";
		}
		this.setState({ errors: errors });
		return (_.size(errors) > 0) ? false : true;
	}

	closeDialog = () => {
		if (this.props.onClose) {
			this.props.onClose()
		} else {
			this.props.history.push("/settings/designations");
		}
	}

	createDesignation = async () => {
		if (this.isValidData()) {
			var data = new FormData();
			this.props.setLoader(true);
			data.append("name", this.state.name);
			data.append("code", this.state.code);
			try {
				if (!this.state.designationId) {
					await Api.designations.post(data);
				} else {
					data.append("_method", "put");
					await Api.designations(this.state.designationId).post(data, {
						headers: {
							"Content-Type": "multipart/form-data",
						}
					}
					);
				}
				this.props.setSnackbar(true, this.state.designationId ? "Designation Updated" : "Designation Added");
				this.props.onUpdate();
				this.closeDialog()
			}
			catch (e) {
				this.setState({ errors: { ...e.response } }, () => {
					this.props.setLoader(false);
				})
			}
			this.props.setLoader(false);
		}
	}


	handleChange = (e, fieldName) => {
		this.setState({ [fieldName]: e.target.value });
		if (this.state.errors[fieldName] === true) {
			this.setState({
				errors: {
					...this.state.errors,
					[fieldName]: false
				}
			});
		}
	};

	render() {

		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={"new"}
				onBackdropClick={this.closeDialog}
				aria-labelledby="version-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="version-dialog-title">{this.isEdit() ? "Edit Designation" : "Add Designation"}</DialogTitle>
				<DialogContent dividers>
					<Grid item xs={12}>
						<TextField
							size={"small"}
							fullWidth
							error={this.state.errors.name}
							helperText={this.state.errors.name}
							placeholder="Name"
							label="Name"
							value={this.state.name}
							onChange={(e) => this.handleChange(e, "name")}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 15 }}>
						<TextField
							size={"small"}
							fullWidth
							error={this.state.errors.code}
							helperText={this.state.errors.code}
							placeholder="Code"
							label="Code"
							id="confirmPassword"
							value={this.state.code}
							onChange={(e) => this.handleChange(e, "code")}
							variant="outlined"
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						size="small"
						onClick={this.closeDialog}
						color="default"
						children="Cancel" />
					<Button size="small" variant="contained" onClick={this.createDesignation} color="primary">Save</Button>
				</DialogActions>
			</Dialog>
		)

	}
}

AddDesignation.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(AddDesignation)));
