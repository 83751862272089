import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Tooltip,
	IconButton,
	Chip
} from "@material-ui/core";
import {
	DoneOutlined,
	ClearOutlined,
	EditOutlined,
} from "@material-ui/icons";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import store from "@/redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import moment from "moment";
import { connect } from "react-redux";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";

class CorrectionRequestTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isConfirmModalOpen: false
		}
	}

	isSelectable = () => {
		const { data: { is_pending } = {} } = this.props;
		return !!is_pending;
	};

	onConfirm = async () => {
		const { isConfirmModalOpen } = this.state;
		const { data } = this.props;
		try {
			this.props.setLoader(true);
			await Api.attendance_correction(data.id).put({
				status: isConfirmModalOpen === "approve" ? "approved" : "rejected"
			});
			await this.props.tableRef.fetchData();
			this.setState({ isConfirmModalOpen: false });
		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { data, classes } = this.props;
		const { isConfirmModalOpen, isEditing } = this.state;
		return [
			<DLTableColumn key={`Attendance-${data.id}-Column-1`} withSeparator={true}>
				<EmployeeAvatar data={data} classes={classes}/>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-2`}
				align={"left"}
				withSeparator={false}
				style={{whiteSpace: "nowrap"}}>
				{data.original_time ? moment.unix(data.original_time).format("DD MMMM, Y") : moment.unix(data.correct_time).format("DD MMMM, Y")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-3`}
				align={"left"}
				withSeparator={false}>
				<Chip
					size="small"
					label={data.attendance.type}
					variant="outlined"
					color={data.attendance.type == "check-in" ? "primary" : "secondary"}
				/>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-4`}
				align={"left"}
				withSeparator={false}>
				{data.original_time ? moment.unix(data.original_time).format("DD MMMM, Y") : "—"}
				<br/>
				{data.original_time ? moment.unix(data.original_time).format("hh:mm A") : ""}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-5`}
				align={"left"}
				withSeparator={false}
			>
				{data.correct_time ? moment.unix(data.correct_time).format("DD MMMM, Y") : "—"}
				<br/>
				{data.correct_time ? moment.unix(data.correct_time).format("hh:mm A") : ""}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-6`}
				align={"left"}
			>
				{data.message}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-7`}
				align={"left"}
				withSeparator={false}
				style={{whiteSpace: "nowrap"}}>
				{moment(data.created_at).format("DD MMMM, Y")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-8`}
				align={"left"}
			>
				<Chip size="small" label={data.is_pending ? "PENDING" : (data.is_approved ? "APPROVED" : "REJECTED")} color={data.is_pending ? "default" : (data.is_approved ? "primary" : "secondary")} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-9`}
				isCompact={true}
				align={"right"}
			>
				{
					data.is_pending &&
					<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>

						<Tooltip title="Reject Request">
							<IconButton
								onClick={() => this.setState({ isConfirmModalOpen: "reject" })}
								size="small"
								color="secondary"
							>
								<ClearOutlined />
							</IconButton>
						</Tooltip>
						
					</UserRole>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-10`}
				isCompact={true}
				align={"right"}
			>
				{
					data.is_pending &&
					<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>

						<Tooltip title="Approve Request">
							<IconButton
								onClick={() => this.setState({ isConfirmModalOpen: "approve" })}
								size="small"
								color="primary"
							>
								<DoneOutlined />
							</IconButton>
						</Tooltip>
						{
							!!isConfirmModalOpen &&
							<ConfirmModal
								isOpen={!!isConfirmModalOpen}
								title={isConfirmModalOpen === "approve" ? "Approve Request" : "Reject Request"}
								message={`Are you sure you want to ${isConfirmModalOpen} this request`}
								onClose={() => this.setState({ isConfirmModalOpen: false })}
								onClickCancel={() => this.setState({ isConfirmModalOpen: false })}
								onClickConfirm={this.onConfirm}
							/>
						}
					</UserRole>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-11`}
				align={"center"}
				isCompact={true}
				fillWidth={35}>
				{	
					<Tooltip title="Edit Request">
						<Link to={{ pathname: `/attendance/correction/${data.id}` }} style={{ textDecoration: 'none', color: "black" }}>
							<IconButton
								onClick={() => {
									this.props.history.push({
										pathname: `/attendance/correction/${data.id}`,
									})
								}}
								size="small"
								color="primary"
							>
								<EditOutlined />
							</IconButton>
						</Link>
					</Tooltip>
					
				}
			</DLTableColumn>,
		]
	}

}

CorrectionRequestTableRow.Columns = [
	{
		key: "first_name",
		label: "Employee",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "date",
		label: "Date",
		align: "left",
		isSortable: true,
		withSeparator: false,
		isCompact: false
	},
	{
		key: "type",
		label: "Type",
		align: "left",
		isSortable: true,
		withSeparator: false,
		isCompact: false
	},
	{
		key: "time",
		label: "Original Time",
		align: "left",
		isSortable: true,
		withSeparator: false,
		isCompact: false
	}, {
		key: "correct_time",
		label: "Correct Time",
		align: "left",
		isSortable: true,
		withSeparator: false,
		isCompact: false
	},
	{
		key: "message",
		label: "Message",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "request_date",
		label: "Request Date",
		align: "left",
		isSortable: true,
		withSeparator: false,
		isCompact: false
	},
	{
		key: "status",
		label: "Status",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "action",
		label: "",
		colSpan: 3,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true
	}
];
const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(
	connect(null, mapDispatchToProps)(withStyles(styles)(CorrectionRequestTableRow))
);
