import React from "react";
import { Icon, Typography } from "@material-ui/core";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error("ErrorBoundary", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div style={{ width: "100%", height: "100%", minHeight: 300, padding: 10, boxSizing: "border-box", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<Icon style={{ fontSize: "5rem" }} color="secondary">bug_report</Icon>
					<Typography component="div" variant="h6" align="center">Aah Ooh! Something went wrong</Typography>
				</div>
			);
		}

		return this.props.children; 
	}
}

export default ErrorBoundary;