import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";

class RoleDropdown extends Component {

    static defaultProps = {
        label: "",
        onChange: () => {}
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };
    
    state = {
        role: null
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.role || (this.state.role.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const role = await Api.roles(this.props.value).get();
                return this.setState({ role });
            } catch(e) { }
        }
        this.setState({ role: null });
    }
    
    loadOptions = async (keyword = "") => {
        this.setState({ loading: true });
        const response = await Api.roles.get({ per_page: 50, filter: { name: keyword } });
        return response.data;
    };

    onChange = (event, role) => {
        this.setState({ role });
        this.props.onChange((role && role.id) ? role : null);
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadOptions}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.onChange}
                value={this.state.role}
                disabled={!!disabled}
            />
        );
    }

}

export default withStyles(styles)(RoleDropdown);