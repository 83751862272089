import { blue } from "@material-ui/core/colors";

const styles = theme => ({
    containerGrid: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formControl: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        marginTop: theme.spacing(1)
    },
    labelGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    inputLabel: {
        textAlign: 'end'
    },
    footer: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    createBtn: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    hidden: {
        display: 'none'
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    labelPlacementStart: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "0px",
        marginLeft: "0px"
    },

    workingDays: {
        display: 'flex'
    },

    workingDayButton: {
        boxShadow: 'none',
        flex: 1
    },

    divider: {
        flex: '1 1 100%',
        marginTop: theme.spacing(1),
        marginRight: -theme.spacing(4),
        marginBottom: theme.spacing(1),
        marginLeft: -theme.spacing(4),
    }
});

export default styles