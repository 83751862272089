import React from "react";
import { connect } from "react-redux";
import { EmployeeInfo, BackButton, PageTitle, Page, InfographicMessage } from "@/components";
import { Card } from "@material-ui/core";
import { Star as UpdateIcon } from '@material-ui/icons';
import { withRouter, Link } from "react-router-dom";
class EmployeeAdd extends React.Component {

	render() {
		return (
			<Page title="Add Employee">
				<Link to={{ pathname: `/employees` }} style={{ textDecoration: 'none', color: "black" }}>
					<BackButton route="/employees" label="Back to Employees" />
				</Link>
				{
					this.props.isSeatAvailable ? (
						<Card raised>
							<EmployeeInfo employee={{}} pageType="add" />
						</Card>
					) : (
						<InfographicMessage
							illustration="crowded"
							message="It's too crowded! Need more room to hire employees."
							submessage="Contact at salam@hassanabbasi.com to upgrade your package"
							actionLabel="Upgrade Package"
							actionIcon={<UpdateIcon/>}
							actionHandler={() => window.open("mailto:salam@hassanabbasi.com")}/>
					)
				}
			</Page>
		);
	}

}

const mapStateToProps = state => ({
	isSeatAvailable: state.general.isSeatAvailable
});

export default connect(mapStateToProps)(withRouter(EmployeeAdd));