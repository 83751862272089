import React, { Component, Fragment } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress
} from "@material-ui/core";
import ContentLoader from "react-content-loader";
import { EmployeeInfoForm } from "@/components"
import PropTypes from "prop-types";

class EmployeeModal extends Component {

	_form = null;

	state = {
		isLoading: false,
		isSaving: false
	};

	render() {
		const { isOpen, title, loadingText, buttonText, onSave, onClose } = this.props;
		const { isSaving, isLoading } = this.state;

		return (
			<Dialog
				open={isOpen}
				onClose={onClose}
				fullWidth
				maxWidth="md"
				aria-labelledby="employee-title"
				aria-describedby="employee-description"
				onBackdropClick={onClose}>
				
				<DialogTitle id="employee-title">{title || 'Add Employee'}</DialogTitle>
				<DialogContent dividers>
					{
						isLoading &&
						<ContentLoader viewBox="0 0 568 354" style={{ marginBottom: -6, width: "100%" }}>
							{
								Array(5).fill().map((_, i) => (
									<Fragment key={i}>
										<rect x="0" y={i * 74} rx="4" ry="4" width="276" height="58" />
										<rect x="292" y={i * 74} rx="4" ry="4" width="276" height="58" />
									</Fragment>
								))
							}
						</ContentLoader>
					}
					<EmployeeInfoForm
						formRef={form => this._form = form}
						employee={{}}
						picturePosition="top"
						onSave={onSave}
						setLoader={isSaving => this.setState({ isSaving })}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button
						variant="contained"
						disabled={isSaving}
						onClick={e => this._form && this._form.save(e)}
						color="primary">
						{
							isSaving ? (
								<>
									<CircularProgress color={"primary"} size={20} />
									<span style={{ marginLeft: "15px" }}>{loadingText}</span>
								</>
							) : (
								buttonText
							)
						}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

EmployeeModal.propTypes = {
	isOpen		: PropTypes.bool,
	title		: PropTypes.string,
	buttonText	: PropTypes.string,
	loadingText	: PropTypes.string,
	employee	: PropTypes.object,
	onSave		: PropTypes.func,
	onClose		: PropTypes.func
};

EmployeeModal.defaultProps = {
	isOpen		: false,
	title		: '',
	buttonText	: 'Save',
	loadingText	: 'Saving',
	onSave		: () => {},
	onClose		: () => {}
};

export default EmployeeModal;