import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardHeader, Avatar, CardActions, IconButton, Icon, Typography } from "@material-ui/core";
import classNames from "classnames";
import styles from "./style";
import _ from "lodash";

class Step extends Component {

    headerActions() {
        const { classes, summary, step, stepper, alwaysShowSummary = false, canEdit = true, onEdit, headerActions } = this.props;
        return (
            !!stepper &&
            <>
                {
                    !!summary && (alwaysShowSummary || stepper.isCompleted(step)) &&
                    <Typography className={classes.stepSummary} component="div">{summary}</Typography>
                }
                { headerActions }
                {
                    stepper.isCompleted(step) && canEdit &&
                    <IconButton onClick={() => {
                        _.isFunction(onEdit)
                            ? onEdit(step)
                            : stepper.activateStep(step)
                    }}><Icon color="primary">edit</Icon></IconButton>
                }
            </>
        );
    }

    render () {
        const { classes, step, isActive, title, subTitle, footerActions, showContent, fullWidth, dense, children, refProp } = this.props;
        return (
            <Card ref={refProp} className={classNames([classes.step, { [classes.activeStep]: isActive }])} raised={isActive}>
                <CardHeader
                    classes={{ title: classes.stepTitle, subheader: classes.stepSubTitle, action: classes.stepHeaderAction }}
                    avatar={<Avatar className={classes.stepNumber}>{step}</Avatar>}
                    title={title}
                    subheader={subTitle}
                    action={this.headerActions()}
                />
                {
                    (isActive || (!isActive && showContent)) &&
                    <div className={classNames([
                        classes.stepContent,
                        {
                            [classes.stepContentFullWidth]: fullWidth,
                            [classes.stepContentDense]: dense
                        }
                    ])}>
                        {children}
                    </div>
                }
                {
                    isActive && !!footerActions &&
                    <CardActions className={classes.stepActions}>
                        {footerActions}
                    </CardActions>
                }
            </Card>
        );
    }

}

export default withStyles(styles)(Step);