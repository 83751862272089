import React from "react";
import store from "@/redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Icon, Menu, MenuItem, Chip, Grid } from "@material-ui/core";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, EmployeeAvatar, UserRole } from "@/components";
import TaxCertificateModal from "@/components/Modals/TaxCertificateModal";
import EmployeePositionModal from "@/components/Modals/EmployeePositionModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import QRModal from "@/components/Modals/QRModal";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import { Permissions } from "@/config/Permissions";
import { AuthContext } from "@/contexts";
import styles from "./style";
import moment from "moment";
class EmployeeTableRow extends DLTableRow {

    static contextType = AuthContext;

    state = {
        anchorEl: null
    };

    constructor(props) {
        super(props);
        this.state = {
            welcomeEmail: false,
            taxCertificate: false,
            isOpenQRModal: false,
            isOpenExitModal: false
        }
    }

    confirmationMessage() {
        const { data } = this.props;
        return (
            <span>
                Are you sure you want to archive {data.first_name} {data.last_name} <Chip size="small" label={data.code} />?
            </span>
        );
    }

    sendWelcomeEmail = () => {
        const { data } = this.props;
        Api.employees(data.id).welcome.post().then(() => {
            store.dispatch(setSnackbar(true, `Welcome email successfully sent to ${data.first_name} ${data.last_name} (${data.code})`));

        }).catch(error => {
            console.error(error);
            store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
        });

        this.setState({ welcomeEmail: false });
    };

    deleteEmployee() {
        return () => {
            const { data } = this.props;
            Api.employees(data.id).delete().then(() => {
                store.dispatch(setSnackbar(true, `${data.first_name} ${data.last_name} (${data.code}) archived successfully`));
                this.props.reload();

            }).catch(error => {
                console.error(error);
                store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
            });

            this.setState({ isOpenExitModal: false });
        };
    }

    renderCells() {
        const { data, classes } = this.props;
        return [
            <DLTableColumn
                key={`Employee-${data.id}-Column-2`}
                withSeparator={true}
                colSpan={2}>
                <EmployeeAvatar data={{
                    employee: {
                        id: data.id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        code: data.code
                    }
                }} classes={classes} />
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-3`}
                withSeparator={true}>
                {data.work_email}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-4`}
                withSeparator={true}
                align={"left"}>
                {data.mobile}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-5`}
                withSeparator={true}
                align={"left"}>
                {data.cnic}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-6`}
                align={"left"}>
                <Tooltip
                    placement="left"
                    title={
                        <Grid container spacing={1}>
                            <Grid item>
                                <Icon>info</Icon>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>Joining Date</Grid>
                                    <Grid item>{moment(data.current_position.joined_at).format("DD MMMM, YYYY")}</Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>Confirmation Date</Grid>
                                    <Grid item>{moment(data.current_position.confirmed_at).format("DD MMMM, YYYY")}</Grid>
                                </Grid>
                                {
                                    data.is_archived &&
                                    <Grid container spacing={1}>
                                        <Grid item>Exit Date</Grid>
                                        <Grid item>{moment(data.current_position.end_date).format("DD MMMM, YYYY")}</Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }>
                    <Chip
                        classes={{ label: classes.statusChip }}
                        size="small"
                        label={
                            <>
                                <Icon className={classes.statusChipIcon}>info</Icon>
                                <span>
                                    {
                                        data.is_archived
                                            ? data.current_position.end_reason && data.current_position.end_reason.toUpperCase()
                                            : "ACTIVE"
                                    }
                                </span>
                            </>
                        }
                        color={data.is_archived ? "secondary" : "primary"}
                    />
                </Tooltip>
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-7`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                <Tooltip title="View Employee">
                    <Link to={{ pathname: `/employees/${data.id}` }} style={{ textDecoration: 'none', color: "primary" }}>
                        <IconButton
                            onClick={() => this.props.history.push(`/employees/${data.id}`)}
                            size="small"
                            color="primary"
                        >
                            <RemoveRedEyeOutlined />
                        </IconButton>
                    </Link>
                </Tooltip>
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-8`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                <Tooltip title="More Options">
                    <IconButton
                        onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                        size="small"
                    >
                        <Icon>more_vert</Icon>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                    PaperProps={{
                        style: {
                            minWidth: 200,
                        },
                    }}>
                    {
                        !data.is_archived &&
                        <MenuItem onClick={() => this.setState({ anchorEl: null, welcomeEmail: true })}>Send Welcome Email</MenuItem>
                    }
                    <MenuItem onClick={() => this.setState({ anchorEl: null, taxCertificate: true })}>Witholding Tax Certificate</MenuItem>
                    <MenuItem onClick={() => this.setState({ anchorEl: null, isOpenQRModal: true })}>QR Code</MenuItem>
                    {
                        !data.is_archived && this.context.user.id !== data.id &&
                        <UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
                            <MenuItem onClick={() => this.setState({ anchorEl: null, isOpenExitModal: true })}>Exit</MenuItem>
                        </UserRole>
                    }
                </Menu>
                {
                    !data.is_archived &&
                    this.state.welcomeEmail &&
                    <ConfirmModal
                        isOpen={this.state.welcomeEmail}
                        title="Send Welcome Email"
                        message={`Are you sure to send Welcome Email to ${data.first_name} ${data.last_name}?`}
                        onClickConfirm={this.sendWelcomeEmail}
                        onClickCancel={() => this.setState({ welcomeEmail: false })}
                    />
                }
                {
                    this.state.isOpenQRModal &&
                    <QRModal
                        mode="exit"
                        open={this.state.isOpenQRModal}
                        employee={data}
                        onUpdate={this.props.reload}
                        onClose={() => this.setState({ isOpenQRModal: false })}
                    />
                }
                {
                    !data.is_archived &&
                    this.context.user.id !== data.id &&
                    this.state.isOpenExitModal &&
                    <EmployeePositionModal
                        mode="exit"
                        employee={data}
                        position={data.current_position.id}
                        onUpdate={this.props.reload}
                        onClose={() => this.setState({ isOpenExitModal: false })}
                    />
                }
                {
                    this.state.taxCertificate &&
                    <TaxCertificateModal
                        open={this.state.taxCertificate}
                        onClose={() => this.setState({ taxCertificate: false })}
                        employee={data}
                    />
                }
            </DLTableColumn>
        ]
    }

}

EmployeeTableRow.Columns = [
    {
        key: "code",
        label: "Code",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "first_name",
        label: "Employee Name",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "work_email",
        label: "Email",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "mobile",
        label: "Mobile",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "cnic",
        label: "CNIC#",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "status",
        label: "Status",
        align: "left",
        isSortable: false,
        withSeparator: false
    },
    {
        key: null,
        label: null,
        align: "center",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
];

export default withRouter(withStyles(styles)(EmployeeTableRow));
