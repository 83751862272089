const styles = theme => ({

	backdrop: {
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		position: "fixed",
		width: "100%",
		height: "100%",
		zIndex: 1000000
	},

    progress: {
        position: 'relative',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

});

export default styles;