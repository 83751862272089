const styles = theme => ({

    section: {
        marginTop: theme.spacing(2)
    },

    separator: {
        display: "flex",
        flex: 0,
        alignItems: "center",

        "&::after": {
            flex: 1,
            display: "block",
            content: '" "',
            height: 1,
            background: theme.palette.borderColor.main,
            marginLeft: theme.spacing(2)
        }
    }

});

export default styles