import Background from "@/assets/backgrounds/cover.png";
// import Illustration from "@/assets/backgrounds/login_illustration.png";

const styles = theme => ({

	root: {
		width: "100vw",
		height: "100vh",
		overflow: "auto",
		overflowX: "hidden",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundImage: `url(${Background})`,
		backgroundSize: "cover",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat",
		boxSizing: "border-box",
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),

		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5)
		},

		[theme.breakpoints.down("xs")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},

	frosted: {
		backgroundColor: 'rgba(255, 255, 255, 0.6)',
		backdropFilter: 'blur(15px)',
		borderRadius: theme.spacing(2)
	},

	logo: {
		height: 85,
		display: "block",
		margin: "auto",
		marginBottom: 10,
		backgroundSize: "contain"
	},

	heading: {
		color: "#000000"
	},

	form: {
		display: "flex",
		flexDirection: "column"
	},

	formControl: {
		borderRadius: "5px",
		backgroundColor: "#ffffff"
	},

	button: {
		marginTop: "8px",
		marginBottom: "4px"
	},

	supportiveButton: {
		float: "right"
	},

	buttonIcon: {
		position: "absolute",
		right: "10px"
	},

	infoGraphic: {
		marginTop: "40px",
		justifyContent: 'center', 
		alignItems: 'center', 
		display: 'flex'
	}
});

export default styles;