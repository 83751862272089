import React, { Component } from "react";
import _ from "lodash";

class Illustration extends Component {

    graphic = null;
    unmounted = false;

    state = {
        Graphic: null
    };

    async componentDidMount() {
        try {
            const module = await import(`./${_.startCase(this.props.type).replace(" ", "")}`);
            if (!this.unmounted) {
                this.setState({ Graphic: module.default });
            }
        } catch (e) { }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const { Graphic } = this.state;
        return Graphic ? <Graphic /> : null;
    }

}

export default Illustration;