import React from "react";
import { withRouter } from "react-router-dom";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import moment from "moment";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton, Grid } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";

class LeaveTypeWiseSummary extends DLTableRow {

	convertMinutes(minutes_time) {
		return moment.utc(Math.abs(minutes_time * 60 * 1000)).format("HH:mm");
	}

	renderCells() {
		const { data, classes } = this.props;
		
		return [
			<DLTableColumn key={`Leave-encashement-${data.id}-Column-0`}
				withSeparator={true}
				stickyHeader={true}	>
				<EmployeeAvatar data={{
					employee: {
						id: data.id,
						first_name: data.first_name,
						last_name: data.last_name,
						code: data.code
					}
				}} classes={classes} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-1`}
				align={"left"}
				withSeparator={false}>
				{data.leave.opening_balance}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-2`}
				align={"left"}
				withSeparator={false}>
				{data.leave.leave_quota}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-3`}
				align={"left"}
				withSeparator={false}>
				{data.leave.earned_leaves}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-4`}
				align={"left"}
				withSeparator={false}>
				{data.leave.availed_leaves}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-5`}
				align={"left"}
				withSeparator={false}>
				{data.leave.adjusted}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-6`}
				align={"left"}
				withSeparator={true}>
				{data.leave.balance}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-7`}
				align={"left"}
				withSeparator={false}>
				{data.leave.encashable_leaves}
			</DLTableColumn>,
			<DLTableColumn
				key={`Leave-encashement-${data.id}-Column-8`}
				align={"left"}
				withSeparator={false}>
				{data.leave.loss_of_pay}
			</DLTableColumn>,
		];
	}
}

LeaveTypeWiseSummary.Columns = [
	{
		key: "employee_details",
		label: "Employee",
		align: "left",
		isSortable: false,
		withSeparator: true,
		stickyHeader:true,
	},
	{
		key: "opening_balance",
		label: "Opening Balance",
		labelComponent: (
			<Grid container alignItems="center">
				Opening Balance
				<Tooltip title="Balance for Encashment & Loss of Pay for Previous Years">
					<IconButton size='small' disableFocusRipple={true} disableRipple={true}>
						<HelpOutline fontSize="small"/>
					</IconButton>
				</Tooltip>
			</Grid>
		),
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "leave_quota",
		label: "Leave Quota",
		labelComponent: (
			<Grid container alignItems="center">
				Leave Quota
				<Tooltip title="Yearly allowed leaves from January 1st to December 31st">
					<IconButton size='small' disableFocusRipple={true} disableRipple={true}>
						<HelpOutline fontSize="small"/>
					</IconButton>
				</Tooltip>
			</Grid>
		),
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "earned_leaves",
		label: "Earned Leaves",
		labelComponent: (
			<Grid container alignItems="center">
				Earned Leaves
				<Tooltip title="Leaves earned so far from yearly allowed leaves">
					<IconButton size='small' disableFocusRipple={true} disableRipple={true}>
						<HelpOutline fontSize="small"/>
					</IconButton>
				</Tooltip>
			</Grid>
		),
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "availed_leaves",
		label: "Availed Leaves",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "already_adjusted",
		label: "Already Adjusted",
		labelComponent: (
			<Grid container alignItems="center">
				Already Adjusted
				<Tooltip title="Leaves already encashed and deducted in payroll">
					<IconButton size='small' disableFocusRipple={true} disableRipple={true}>
						<HelpOutline fontSize="small"/>
					</IconButton>
				</Tooltip>
			</Grid>
		),
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "balance",
		label: "Balance",
		align: "left",
		isSortable: false,
		withSeparator: true
	},
	{
		key: "encashment",
		label: "Encashment",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "loss_of_pay",
		label: "Loss Of Pay",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
];

export default withRouter(withStyles(styles)((LeaveTypeWiseSummary)));
