const style = theme => ({

    footer: {
        padding: "20px",
        flexDirection:"row",
        display: "flex",
        borderTop: `2px #E7F1FF solid`,
        justifyContent: 'flex-end'
    }

});

export default style;