import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import theme from "@/themeStyles";
import { Utility } from "@/helpers";

class ShiftDays extends Component {

    render() {
        const { data, classes } = this.props;
        if (!data) return;

        var array = []
        const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        for (let index = 0; index < 7; index++) {
            array.push(
                <>
                    <div
                        className={classes.dayStyle}
                        style={{ color: data[days[index]] ? theme.color.blue.dodger : theme.color.silver.main }}
                    >{Utility.getInitials(days[index])}</div>
                </>
            )
        }
        return <div style={{ display: "flex" }}>{array}</div>
    };
}


export default withStyles(styles)(ShiftDays);