import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Fab, IconButton, Tooltip } from "@material-ui/core";
import {
	Link as LinkIcon,
	LinkOff as UnlinkIcon,
	PersonAdd as AddIcon
} from "@material-ui/icons";
import { DLTableRow, DLTableColumn } from "@/components";
import EmployeeSelectionModal from "@/components/Modals/EmployeeSelectionModal";
import EmployeeModal from "@/components/Modals/EmployeeModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import EmployeeChip from "@/components/EmployeeChip";
import styles from "./style";

class TerminalEmployeeTableRow extends DLTableRow {

	state = {
        anchorEl: null
    };

    constructor(props) {
		super(props);
		this.state = {
			selectEmployee: false,
			addingEmployee: false,
			confirmLinking: false,
			confirmUnlinking: false,
			selectedEmployee: null
		}
	}

	linkEmployee = async () => {
		const { data } = this.props;
		const { selectedEmployee } = this.state;
		this.props.setLoader(true);
		try {
			await Api.employees(selectedEmployee.id).terminal.patch({ uid : data.uid });
			this.setState({ confirmLinking: false, selectedEmployee: null, selectEmployee: false });
			this.props.setSnackbar(true, `"${selectedEmployee.first_name} ${selectedEmployee.last_name}" linked successfully`);
			this.props.reload();

		} catch (e) {
			console.error(e);
			this.props.setSnackbar(true, "Something went wrong, please try again later", "error");
		}
		this.props.setLoader(false);
	};

	unlinkEmployee = async () => {
		const { data } = this.props;
		this.props.setLoader(true);
		try {
			await Api.employees(data.employee.id).terminal.delete();
			this.setState({ confirmUnlinking: false });
			this.props.setSnackbar(true, `"${data.employee.first_name} ${data.employee.last_name}" unlinked successfully`);
			this.props.reload();

		} catch (e) {
			console.error(e);
			this.props.setSnackbar(true, "Something went wrong, please try again later", "error");
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { data, classes } = this.props;
		const { confirmLinking, confirmUnlinking, selectEmployee, selectedEmployee, addingEmployee } = this.state;
		return [
			<DLTableColumn
				key={`Employee-${data.uid}-Column-1`}
				align={"left"}
				withSeparator
				style={{ width: "50%" }}>
				<strong>{data.name}</strong><br/>
				<small>#{data.uid}</small>
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.uid}-Column-2`}
				align={"left"}
				style={{ width: "50%" }}>
				{
					data.employee && data.employee.id ? (
						<EmployeeChip employee={data.employee} selectable={false}>
							<Tooltip title="Remove link between ZKTeco User and your Employee">
								<IconButton
									size="small"
									color="primary"
									onClick={() => this.setState({ confirmUnlinking: true })}>
									<UnlinkIcon />
								</IconButton>
							</Tooltip>
							{
								confirmUnlinking &&
								<ConfirmModal
									isOpen
									title="Unlink Employee"
									message={`Are you sure you want remove link bwtween "${data.employee.first_name} ${data.employee.last_name}" and "${data.name}" (#${data.uid})`}
									onClickConfirm={this.unlinkEmployee}
									onClickCancel={() => this.setState({ confirmUnlinking: false })}
								/>
							}
						</EmployeeChip>
					) : (
						<>
							<Tooltip title="Link ZKTeco user with your Employee">
								<Fab
									className={classes.fab}
									onClick={_ => this.setState({ selectEmployee: true })}
									size="small"			
									// color="primary"
									variant="extended">
									<LinkIcon className={classes.extendedIcon} />
									<span className={classes.extendedText}>Link Employee</span>
								</Fab>
							</Tooltip>
							<Tooltip title="Add new employee to link with ZKTeco user">
								<IconButton
									size="small"
									color="primary"
									onClick={_ => this.setState({ addingEmployee: true })}>
									<AddIcon />
								</IconButton>
							</Tooltip>
						</>
					)
				}
				{
					selectEmployee &&
					<EmployeeSelectionModal
						isOpen
						title="Select Employee"
						buttonText="Select"
						onSubmit={selectedEmployee => this.setState({ selectedEmployee, confirmLinking: true })}
						onClose={() => this.setState({ selectEmployee: false })}
						multiSelect={false}
					/>
				}
				{
					addingEmployee &&
					<EmployeeModal
						isOpen
						onSave={selectedEmployee => this.setState({ selectedEmployee, addingEmployee: false, confirmLinking: true })}
						onClose={() => this.setState({ addingEmployee: false })}
					/>
				}
				{
					confirmLinking && selectedEmployee &&
					<ConfirmModal
						isOpen
						title="Link Employee"
						message={`Are you sure you want to link "${selectedEmployee.first_name} ${selectedEmployee.last_name}" with "${data.name}" (#${data.uid})`}
						onClickConfirm={this.linkEmployee}
						onClickCancel={() => this.setState({ selectedEmployee: null, confirmLinking: false })}
					/>
				}
			</DLTableColumn>
		]
	}

}

TerminalEmployeeTableRow.Columns = [
	{
		key: "name",
		label: "ZKTeco Users",
		align: "left",
		isSortable: false,
		withSeparator: true,
		style: { width: "50%" }
	},
	{
		key: "employee_id",
		label: "Employees",
		align: "left",
		isSortable: false,
		withSeparator: false,
		style: { width: "50%" }
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withStyles(styles, { withTheme: true })(connect(null, mapDispatchToProps)(TerminalEmployeeTableRow));