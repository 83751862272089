const style = theme => ({

    avatar: {
        width: 40,
        height: 40,
        border: `1px ${theme.palette.borderColor.main} solid`,
        boxShadow: theme.shadows[2]
    },

    statusChip: {
        display: "flex",
        paddingLeft: 5,
        justifyContent: "space-between",
        alignItems: "center"
    },

    statusChipIcon: {
        fontSize: "1rem",
        marginRight: 4
    }

});

export default style;