import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Icon, IconButton, Grid } from "@material-ui/core";
import { DLTable, Stepper, InfographicMessage } from "@/components";
import styles from "./style";
import _ from "lodash";
import { AuthContext } from "@/contexts";
import { AccessManager } from "@/helpers";
import PayslipTableRow from "./PayslipTableRow";
import { withRouter, Switch, Route } from "react-router-dom";
import { PayslipModal } from "@/components";

class Payslips extends Component {
	static contextType = AuthContext;
	state = {
		selectedPayslip: null,
		search: ""
	};

	headerActions() {
		const { stepper, step, activeStep } = this.props;
		return (
			activeStep > step &&
			<IconButton onClick={() => stepper.activateStep(step)}><Icon color="primary">edit</Icon></IconButton>
		);
	}

	emptyView() {
		return (
			<InfographicMessage	
				illustration="payslips"
				message={"Payslip not found"}
			/>
		);
	}

	footerActions() {
		const { stepper } = this.props;
		return (
			<Button
				variant="contained"
				color="primary"
				onClick={() => stepper && stepper.next()}>
				Next
			</Button>
		);
	}

	search = () => {
		const { payrun } = this.props;
		const { search } = this.state;
		return _.filter(payrun.payslips, slip => {
			return _.includes(`${slip.employee.first_name} ${slip.employee.last_name}`.toLowerCase(), search.toLowerCase()) ||
				   _.includes(slip.employee.code.toLowerCase(), search.toLowerCase()) ||
				   _.includes(slip.employee.work_email.toLowerCase(), search.toLowerCase()) ||
				   _.includes(slip.employee.personal_email.toLowerCase(), search.toLowerCase()) ||
				   _.includes(slip.employee.mobile.toLowerCase(), search.toLowerCase());
		});
	};

	render() {
		const { step, isActive, activeStep, stepper, onUpdate, payrun, ...otherProps } = this.props
		const isTaxModule = AccessManager.isModuleAccess("TAX", this.context.organization);
		const payslips = this.search();
		return (
			<Stepper.Step
				title="Payslips"
				step={step}
				isActive={isActive}
				fullWidth={true}
				dense={true}
				showContent={activeStep > step}
				headerActionsLeaves={this.headerActions()}
				footerActions={this.footerActions()}
				stepper={stepper}
				{...otherProps}>
				{
					(isActive || activeStep > step) &&
					<Grid item  xs={12} alignItems="stretch">
						 <DLTable
							data={payslips}
							RowTemplate={PayslipTableRow}
							rowProps={{
								isPreview: activeStep > step,
								onUpdate,
								search: this.state.search,
								setState: this.setState.bind(this),
								isTaxModule
							}}
							alternatingRowColors
							defaultOrder="asc"
							showPagination={false}
							emptyView={this.emptyView()}
						/>
					</Grid>
				}
				<Switch>
					<Route exact path="/payroll/:id/payslip/:payslipId">
						<PayslipModal 
							data={payslips}
							onUpdate={this.props.onUpdate}
							onClose={()=> this.props.history.push({pathname:`/payroll/${payrun.id}`})}
						/>
					</Route>
				</Switch>
			</Stepper.Step>
		);
	}
}	

export default withRouter(withStyles(styles)(Payslips));
