import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import { Chip } from "@material-ui/core";

class BankDropdown extends Component {

    static defaultProps = {
        label: "Bank",
        onChange: () => {}
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };
    
    state = {
        bank: null
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.bank || (this.state.bank.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const bank = await Api.banks(this.props.value).get();
                return this.setState({ bank });
            } catch(e) { }
        }
        this.setState({ bank: null });
    }
    
     loadBank = async (keyword = "") => {
        this.setState({ loading: true });
        const response = await Api.banks.get({
            per_page: 50,
            filter: { name: keyword }
        });
        return response.data;
    };

    renderOption = option => (
        <div className={this.props.classes.itemStyle}>
            {option.name}
            <Chip
                className={this.props.classes.dropdownChipRight}
                size="small"
                label={option.code}
            />
        </div>
    );

    onChange = (event, bank) => {
        this.setState({ bank });
        this.props.onChange((bank && bank.id) || null);
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ root: classes.dropdown, paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadBank}
                getOptionLabel={option => `${option.name}`}
                renderOption={this.renderOption}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.onChange}
                value={this.state.bank}
                disabled={!!disabled}
            />
        );
    }

}

export default withStyles(styles)(BankDropdown);