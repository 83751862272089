import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles"
import styles from "./style.js";
import PropTypes from "prop-types";
import _ from "lodash";
import { Constants } from "@/config";

import {
	MenuItem,
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControlLabel,
	Switch,
	CircularProgress
} from "@material-ui/core";
import moment from "moment";
import Config from "@/config";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import { FormattedNumericInput } from "@/components";

class EmployeeLeaveModal extends Component {

	static propTypes = {
		employee: PropTypes.object.isRequired,
		employee_leaves: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		title: "Edit Leave",
		onUpdate: () => { }
	};

	state = {
		errors: {},
		leaves: {},
		leaveCategories: [],
		loading: false
	};

	async componentDidMount() {
		this.setState({ loading: true });
		try {
			let { data } = await Api.leaves.get();
			this.setState({ leaveCategories: data });
		} catch (e) {

		} finally {
			this.setState({ loading: false });
		}
	}
	
	employeeleaves() {
		return {
			quantity: 0,
			frequency:"Annually",
			details: {},
			should_overwrite: true,
			...this.props.leaves,
			...this.state.leaves
		};
	}

	handleChange(value, field) {
		const leaves = this.employeeleaves();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			leaves: {
				...leaves,
				[field]: value
			},
			errors
		});
	}

	validate() {
		const leaves = this.employeeleaves();
		var errors = {};

		if(leaves.quantity < 0) {
			errors["quantity"] = "Leave quantity must be greater then or equal to 0"
		}

		if(!leaves.id) {
			errors["leave_id"] = "Leave field is required"
		}
	
		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	prepareData = () => {
		let leaves = this.employeeleaves();
		let data = {
			id: leaves.details.id
		}

		
		if (!leaves.details.is_fixed_quantity) {
			data.quantity = leaves.quantity
		}

	    data.frequency = leaves.frequency;
        if (leaves.start_date) {
            data.start_date = moment(leaves.start_date).format(Config.SERVER_DATETIME_FORMAT);

        }
			if(leaves.start_date) {
				data.start_date = moment(leaves.start_date).format(Config.SERVER_DATE_FORMAT);
		}

		return data;
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const employee_leaves = this.employeeleaves();
		var data = this.prepareData();

		this.props.setLoader(true);
		try {
			const { employees = [], bulkMode } = this.props;
			if (employees.length && bulkMode) {
				await Api.employees.employee_leaves.bulk_assign.put({ 
					leave_id: employee_leaves.id,
					quantity: employee_leaves.quantity,
					should_overwrite: employee_leaves.should_overwrite,
					employees: _.map(employees, e => e.id) 
				});
				
				this.props.setSnackbar(true, `Leave added successfully for ${employees.length} employee${employees.length == 1 ? '' : 's'}`);
				_.isFunction(this.props.onUpdate) && this.props.onUpdate();
				_.isFunction(this.props.onClose) && this.props.onClose();

			} else {
				const _leaves = await Api.employees(this.props.employee.id).employee_leaves(employee_leaves.id).put(data);
				this.props.setSnackbar(true, "Leaves saved successfully");
				this.props.onUpdate(_leaves);
				this.props.onClose();
			}


		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	changeLeave = (index = 0) => {
		const leave = this.state.leaveCategories[index];

		const {
			id,
			quantity
		} = leave;

		const leaves = {
			id,
			details: leave,
			quantity: quantity
		};

		this.setState({ leaves });
	};

	renderLeaves = () => {
		const { leaveCategories } = this.state;
		const { errors } = this.state;
		if (errors["leave_id"]) {
			errors["leave_id"] = false;
		}
		return (
			leaveCategories.map((leaves, index) => (
				<MenuItem
					key={index}
					value={leaves}
					onClick={() => this.changeLeave(index)}
					children={leaves.name}
				/>
			))
		);
	};

	render() {
		const { isOpen, title, classes } = this.props;
		const { errors, loading } = this.state;
		const employee_leaves = this.employeeleaves();
		return (
			<Dialog 
				fullWidth
				maxWidth="xs"
				open={isOpen} 
				aria-labelledby="employee-payitem-dialog-title"
				onBackdropClick={this.propsonClose}
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-payitem-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{
								this.props.bulkMode ?
								<TextField
									select
									fullWidth
									label="Leave Category"
									variant="outlined"
									error={!!errors.leave_id}
									helperText={errors.leave_id}
									value={employee_leaves.details.name}
									children={this.renderLeaves()}
									FormHelperTextProps={{ variant: "standard" }}
									InputProps={{
										endAdornment: loading && (
											<CircularProgress
												size={20}
												color="inherit"
												className={classes.textFeildLoaderIndicator}
											/>
										)
									}}
									size={"small"}
								/>
								: 
								<TextField
									label="Leave"
									fullWidth
									size="small"
									value={employee_leaves.details.name || ""}
									variant="outlined"
									disabled
								/>
							}
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputProps={{
									inputComponent: FormattedNumericInput,
								}}
								fullWidth
								size="small"
								disabled={employee_leaves.details.is_fixed_quantity}
								label="Quantity"
								variant="outlined"
								error={!!errors.quantity}
								value={employee_leaves.quantity}
								onChange={e => this.handleChange(e.currentTarget.value, "quantity")}
								helperText={errors.quantity}
								FormHelperTextProps={{variant: "standard"}}
							/>
						</Grid>
						<Grid item xs={12} className={classes.hidden}>
							<TextField
								fullWidth
								size="small"
								select
								label="Frequency"
								variant="outlined"
								error={!!errors.frequency}
								helperText={errors.frequency}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee_leaves.frequency || ""}
								onChange={(e) => this.handleChange(e.target.value, "frequency")}>
								{Constants.EmployeeLeaves.leaveFrequency.map((frequency) => (
									<MenuItem key={frequency} value={frequency}>
										{frequency}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						{
							this.props.bulkMode && 
							<Grid item xs={12}>
								<FormControlLabel
									value="start"
									labelPlacement="start"
									className={classes.switch}
									label="Overwrite Leaves"
									control={
										<Switch
											color="primary"
											checked={employee_leaves.should_overwrite}
											size={"small"}
											onChange={(_) => this.handleChange(!employee_leaves.should_overwrite, "should_overwrite")} />
									}
								/>
							</Grid>
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color="default" size="small">Cancel</Button>
					<Button variant="contained" onClick={this.save} color="primary" size="small">Save</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeLeaveModal));