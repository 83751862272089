import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style.js";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Switch,
    FormControlLabel

} from "@material-ui/core";

import moment from "moment";
import { Constants } from "@/config";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import { FormattedNumericInput } from "@/components";

class LeavesModal extends Component {
    static propTypes = {
        mode: PropTypes.oneOf(["new", "edit", "exit"]),
        leaves: PropTypes.object,
        title: PropTypes.string,
        onClose: PropTypes.func,
        onUpdate: PropTypes.func
    };

    static defaultProps = {
        mode: "new",
        title: "New Leave",
        onUpdate: () => { }
    };
    state = {
        errors: {},
        leaves: {},
        position: {},
       
    };

    leaves() {
        return {
            name: "",
            code: "",
            description: "",
            quantity: "",
            ...this.props.leaves,
            ...this.state.leaves,
            frequency: "Annually",
        };
    }

    handleChange(value, field) {
        const leaves = this.leaves();
        const { errors } = this.state;
        if (errors[field]) {
            errors[field] = false;
        }
        this.setState({
            leaves: {
                ...leaves,
                [field]: value,
            },
            errors,
        });
    }

    validate() {
        const leaves = this.leaves();
        const optionalFields = [
            "organization_id",
            "updated_at",
            "is_archived",
            "created_at",
            "id",
            "is_deduct_unapproved_leave",
            "is_encashable",
            "allowed_in_probation",
            "is_auto_assign",
            "disabled",
            "quantity",
            "is_default",
        ];
        var errors = {};
        _.forOwn(leaves, (value, key) => {
            if (key === "quantity" && value !== 0 & (value < 0 || !value)) {
                errors[key] = `${_.startCase(key)} should be greater than or equal to zero`;
            } else if (optionalFields.indexOf(key) === -1 && !value) {
                errors[key] = `${_.startCase(key)} field is required`;
            } else if (key === "code" && (value.length > 6 || !value)) {
                errors[key] = `${_.startCase(key)} should be less than or equal to six`;
            }
        });
        if (_.size(errors)) {
            this.props.setSnackbar(
                true,
                "Please fix the highlighted errors",
                "error"
            );
        }
        console.log("Errors: ", errors);
        this.setState({ errors });
        return !_.size(errors);
    }


    prepareData = () => {
        let leaves = this.leaves();
        let data = {
            id: leaves.id,
            name: leaves.name,
            code: leaves.code,
            description: leaves.description,
            frequency: leaves.frequency,
            quantity: leaves.quantity,
            is_deduct_unapproved_leave: leaves.is_deduct_unapproved_leave,
            is_auto_assign: leaves.is_auto_assign,
            allowed_in_probation: leaves.allowed_in_probation,
            is_encashable: leaves.is_encashable,
        }

        data.editItemId = leaves.id

        if (!leaves.is_fixed_rate) {
            data.rate = leaves.rate
        }
        if (leaves.is_deduct_unapproved_leave) {
            leaves.is_auto_assign = true
        }

        if (!leaves.is_fixed_quantity) {
            data.quantity = leaves.quantity
        }


        data.frequency = leaves.frequency;
        if (leaves.start_date) {
            data.start_date = moment(leaves.start_date).format(Config.SERVER_DATETIME_FORMAT);
        }
        return data;
    }


    save = async (e) => {
        e.preventDefault();
        if (!this.validate()) return;
        const leaves = this.leaves();


        const { position: id } = this.props;
        var data = this.prepareData();
        this.props.setLoader(true);
        try {
            if (!id) {
                await Api.leaves.post(data);
            } else {

                await Api.leaves(leaves.id).put(data);
            }
            this.props.setSnackbar(true, "Employee Leave saved successfully");
            this.props.onUpdate();
            this.props.onClose();
        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        response.message || "Something went wrong, please try again later",
                        "error"
                    )
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
        this.props.setLoader(false);

    };

    render() {

        const { mode, title, classes } = this.props;
        const { errors, isActiveModal } = this.state;
        const leaves = this.leaves();
        const { position: id } = this.props;

        var message = !id
            ? `It will assign ${leaves.name} leaves to all employees`
            : "It will Overwrite leaves for all employees";

        return (
            <> <Dialog
                fullWidth
                maxWidth="xs"
                open={mode}
				onBackdropClick={this.props.onClose}
                aria-labelledby="employee-leaves-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="employee-leaves-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                fullWidth
                                size="small"
                                value={leaves.name || ""}
                                error={!!errors.name}
                                FormHelperTextProps={{ variant: "standard" }}
                                helperText={errors.name}
                                variant="outlined"
                                onChange={(e) => this.handleChange(e.target.value, "name")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="code"
                                fullWidth
                                size="small"
                                value={leaves.code || ""}
                                error={!!errors.code}
                                FormHelperTextProps={{ variant: "standard" }}
                                helperText={errors.code}
                                variant="outlined"
                                onChange={(e) => this.handleChange(e.target.value, "code")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                InputProps={{
									inputComponent: FormattedNumericInput,
								}}
                                fullWidth
                                size="small"
                                label="Quantity"
                                variant="outlined"
                                error={!!errors.quantity}
                                value={leaves.quantity}
                                onChange={e => this.handleChange(e.currentTarget.value, "quantity")}
                                helperText={errors.quantity}
                                FormHelperTextProps={{ variant: "standard" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                fullWidth
                                size="small"
                                error={!!errors.description}
                                helperText={errors.description}
                                value={leaves.description || ""}
                                FormHelperTextProps={{ variant: "standard" }}
                                variant="outlined"
                                onChange={(e) =>
                                    this.handleChange(e.target.value, "description")
                                }
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.hidden}>
                            <TextField
                                fullWidth
                                size="small"
                                select
                                label="Frequency"
                                variant="outlined"
                                error={!!errors.frequency}
                                helperText={errors.frequency}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={leaves.frequency || "Annually"}
                                onChange={(e) => this.handleChange(e.target.value, "frequency")}>
                                {Constants.EmployeeLeaves.leaveFrequency.map((frequency) => (
                                    <MenuItem key={frequency} value={frequency}>
                                        {frequency}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.hidden}>
                            <FormControlLabel
                                value="is_deduct_unapproved_leave"
                                FormHelperTextProps={{ variant: "standard" }}
                                className={classes.switch}
                                control={
                                    <Switch
                                        size="small"
                                        color="primary"
                                        checked={leaves.is_deduct_unapproved_leave}
                                        onChange={(_) =>
                                            this.handleChange(!leaves.is_deduct_unapproved_leave, "is_deduct_unapproved_leave")} />
                                }
                                label="Deduct unapproved leaves"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="is_auto_assign"
                                FormHelperTextProps={{ variant: "standard" }}
                                className={classes.switch}
                                control={
                                    <Switch
                                        size="small"
                                        color="primary"
                                        checked={leaves.is_auto_assign}
                                        onChange={(_) =>
                                            this.handleChange(!leaves.is_auto_assign, "is_auto_assign")} />
                                }
                                label="Adds automatically for new employees"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value={true}
                                FormHelperTextProps={{ variant: "standard" }}
                                className={classes.switch}
                                control={
                                    <Switch
                                        size="small"
                                        color="primary"
                                        checked={leaves.allowed_in_probation}
                                        onChange={(_) =>
                                            this.handleChange(!leaves.allowed_in_probation, "allowed_in_probation")} />
                                }
                                label="Allowed in probation"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value={true}
                                FormHelperTextProps={{ variant: "standard" }}
                                className={classes.switch}
                                control={
                                    <Switch
                                        size="small"
                                        color="primary"
                                        checked={leaves.is_encashable}
                                        onChange={(_) =>
                                            this.handleChange(!leaves.is_encashable, "is_encashable")} />
                                }
                                label="Unavailed Leaves are Encashable"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="default" size="small">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={this.save} color="primary" size="small">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LeavesModal));
