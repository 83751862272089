import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import { DLTableColumn, DLTableRow  } from "@/components";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import React from "react";
import { Tooltip, IconButton, Chip } from "@material-ui/core";
import Theme from "@/themeStyles";
import { setSnackbar } from "@/redux/actions/general";
import store from "@/redux";
import styles from "./style";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import HolidaysModal from "@/components/Modals/HolidaysModal";

class HolidaysTableRow extends DLTableRow {

    constructor (props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isEditing: false
        }
    }

    delete = async () => {
        this.setState({ isModalOpen: false });
        const { data } = this.props;
        try {
            await Api.organization_calendar(data.id).delete()
            store.dispatch(setSnackbar(true, `Holiday "${data.is_holiday ? "HoliDay" : "Working Day"}" delete successfully`));
            this.props.reload();
        } catch (e) {
            console.error(e);
            store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
        }
    };

    renderCells() {
        const { data, Holidays } = this.props;
        const { isEditing } = this.state;
        console.log(data)
        return [
            <DLTableColumn
                key={`HoliDay-${data.id}-Column-1`}
                withSeparator={true}>
                <strong>{data.date}</strong>
            </DLTableColumn>,

            <DLTableColumn
                key={`HoliDay-${data.id}-Column-5`}
                align={"left"}>
                {data.comment}
            </DLTableColumn>,

            <DLTableColumn
                key={`HoliDay-${data.id}-Column-7`}
                align={"left"}
            >
                <Chip size="small" label={data.is_holiday ? "Holiday" : "Working Day"} style={{ backgroundColor: (data.is_holiday ? "#FF6515" : Theme.palette.primary.main), color: Theme.color.white.main }} />
            </DLTableColumn>,

            <DLTableColumn
                key={`Employee-${data.id}-Column-7`}
                align={"center"}
                isCompact={true}
                fillWidth={35}>
                {
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => this.setState({ isEditing: " " })}
                            size="small"
                            color="primary">
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                }
                {
                    <HolidaysModal
                        mode={isEditing}
                        Request={Holidays}
                        holiday={data}
                        title="Edit"
                        onUpdate={this.props.reload}
                        onClose={() => this.setState({ isEditing: false })} />
                }
            </DLTableColumn>,

            <DLTableColumn
                key={`HoliDay-${data.id}-Column-14`}
                isCompact={true}
                align={"right"}
                fillWidth={35}
            >
                {
                    !data.is_archived &&
                    <>
                        <Tooltip title="Delete Shift Item">
                            <IconButton
                                onClick={() => this.setState({ isModalOpen: true })}
                                size="small"
                                color="secondary">
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                        <ConfirmModal
                            title="Delete"
                            isOpen={this.state.isModalOpen}
                            message={`Are you sure you want to delete item ${data.is_holiday ? "Holiday" : "Working Day"}?`}
                            onClickCancel={() => {
                                this.setState({ isModalOpen: false });
                            }}
                            onClickConfirm={this.delete} />
                    </>
                }
            </DLTableColumn>
        ]
    }

}

HolidaysTableRow.Columns = [
    {
        key: "date",
        label: "Date",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "comment",
        label: "Comment",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "status",
        label: "Status",
        align: "left",
        isSortable: true,

    },
    {
        key: null,
        label: null,
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
];

export default withRouter(withStyles(styles)(HolidaysTableRow));
