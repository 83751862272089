import React, { Component } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import PDFViewer from "@/components/PDFViewer";
import InfographicMessage from "@/components/InfographicMessage";
import { CloudDownload } from "@material-ui/icons";
import { Api } from "@/helpers";
import { Constants } from "@/config";
import styles from "./style";

class DocumentModal extends Component {
    static propTypes = {
        employee: Proptypes.object.isRequired,
        onClose: Proptypes.func.isRequired,
        isOpen: Proptypes.bool.isRequired,
    };

    state = {
        ext: null,
        to: null,
        url: null,
        enableDownload: false,
    };
    async componentDidMount() {
        const { data } = this.props;
        var exT = data.document_path.split(".");

        this.setState({ ext: exT[exT.length - 1] });
    }
    download = () => {
        const { data, employeeDocument } = this.props;
        if(employeeDocument === true){
            window.open(Api.employee_document(data.id).document.getUrl(), "_blank");
        }
        else{
            window.open(Api.organization_document(data.id).document.getUrl(), "_blank");
        }
    };

    render() {
        const { onClose, isOpen, data, employeeDocument, classes } = this.props;
        var file = "";
        var type  = "";

        if(employeeDocument === true){
            file = Api.employee_document(data.id).document.getUrl();
            type = this.state.ext;
        }
        else{
            file = Api.organization_document(data.id).document.getUrl();
            type = this.state.ext;
        }

        const documentIcon = Constants.DocMimeTypes.doc.indexOf(data.mime_type) != -1
						? "file_document"
						: (
							Constants.DocMimeTypes.spreadsheet.indexOf(data.mime_type) != -1
							? "file_spreadsheet"
							: (
								Constants.DocMimeTypes.presentation.indexOf(data.mime_type) != -1
								? "file_presentation"
								: (
									"file_unknown"
								)
							)
						);
      
        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                aria-labelledby="tax-documents-dialog-title"
                aria-describedby="tax-documents-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="tax-documents-dialog-title">
                    {data.name}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid className={classes.documentContainer}>
                        {
                            Constants.DocMimeTypes.pdf.indexOf(data.mime_type) != -1 &&
                            <PDFViewer url={file}/>
                        }
                        {
                            Constants.DocMimeTypes.image.indexOf(data.mime_type) != -1 &&
                            <img className={classes.documentPage} src={file} alt={data.name}/>
                        }
                        {
                            [...Constants.DocMimeTypes.pdf, ...Constants.DocMimeTypes.image].indexOf(data.mime_type) == -1 &&
                            <div className={classes.noPreview}>
                                <InfographicMessage
                                    illustration={documentIcon}
                                    message="Preview seems shy!"
                                    submessage="The document preview is shy and doesn't want to show itself, but don't worry! You can still download the file to view its contents."
                                    actionIcon={"cloud_download"}
                                    actionLabel="Download"
                                    actionHandler={this.download}
                                />
                            </div>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size={"small"} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        size={"small"}
                        onClick={this.download}
                        color="primary"
                        variant="contained"
                    >
                        <CloudDownload className={classes.buttonIcon} /> Download
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DocumentModal);
