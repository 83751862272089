export default theme => ({
  popoverContent:{
    maxWidth: 800, 
    padding: "0px"
  }, 
popoverList:{
    width: 289
  },
divButton:{
    margin: 8
  }
  
});
