const style = theme => ({

    paper: {
        width: "auto !important",
        minWidth: 250
    },

    container: {
        border: "1px #c4c4c4 solid",
        borderRadius: theme.spacing(0.5),
        width: "50%"
    },

    labelGrid: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingRight: theme.spacing(2),
        alignItems: 'center',
        flex: 1
    },

    parentModule: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderBottom: "1px #c4c4c4 solid",

        "&:last-child": {
            border: "none"
        }
    },

    childModule: {
        marginLeft: theme.spacing(3),
        marginRight: -theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderTop: "1px #c4c4c4 solid",
    }

});

export default style;