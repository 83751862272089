import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Tooltip, IconButton } from "@material-ui/core";
import { ArchiveOutlined, UnarchiveOutlined, EditOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, UserRole } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import { Permissions } from "@/config/Permissions";

class DesignationTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isEditModalOpen: false,
			data: {},
		}
	}


	archive = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.designations(data.id).delete()
			store.dispatch(setSnackbar(true, `${data.name} archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.designations(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `${data.name} restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data } = this.props;
		return [
			<DLTableColumn
				key={`Designation-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`Designation-${data.id}-Column-2`}
				align={"left"}>
				{data.code}
			</DLTableColumn>,

			<UserRole routePermissions={[ Permissions.DESIGNATION_EDITABLE, Permissions.DESIGNATION_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Designation-${data.id}-Column-6`}
					isCompact={true}
					align={"center"}
					fillWidth={35}>
					{
						!data.is_archived &&
						<Tooltip title="Edit Designation">
							<Link to={{ pathname: `/settings/designations/${data.id}` }} style={{ textDecoration: 'none', color: "black" }}>
								<IconButton
									size="small"
									color="primary">
									<EditOutlined />
								</IconButton>
							</Link>
						</Tooltip>
					}
				</DLTableColumn>
			</UserRole>,
			<UserRole routePermissions={[ Permissions.DESIGNATION_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Designation-${data.id}-Column-7`}
					isCompact={true}
					align={"center"}
					fillWidth={35}>
					{
						!data.is_archived ? (
							<>
								<Tooltip title="Archive Designation">
									<IconButton
										onClick={() => this.setState({ isModalOpen: true })}
										size="small"
										color="secondary"
									>
										<ArchiveOutlined />
									</IconButton>
								</Tooltip>

								<ConfirmModal
									title="Archive Designation"
									isOpen={this.state.isModalOpen}
									message={`Are you sure you want to archive designation "${data.name}"?`}
									onClickCancel={() => {
										this.setState({ isModalOpen: false });
									}}
									onClickConfirm={this.archive}/>
							</>
						) : (
							<>
								<Tooltip title="Restore Designation">
									<IconButton
										onClick={() => this.setState({ isModalOpen: true })}
										size="small"
										color="primary"
									>
										<UnarchiveOutlined />
									</IconButton>
								</Tooltip>

								<ConfirmModal
									title="Restore Designation"
									isOpen={this.state.isModalOpen}
									message={`Are you sure you want to restore designation "${data.name}"?`}
									onClickCancel={() => {
										this.setState({ isModalOpen: false });
									}}
									onClickConfirm={this.restore}/>
							</>
						)
					}
				</DLTableColumn>
			</UserRole>
		]
	}

}

DesignationTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "code",
		label: "Code",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

export default withRouter(DesignationTableRow);