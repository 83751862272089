import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn, ShiftDays } from "@/components";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import ConfirmModal from "../Modals/ConfirmModal";
import ShiftsModal from "../Modals/ShiftsModal";
import moment from "moment";
import _ from "lodash";
import { Permissions } from "@/config/Permissions";
import { UserRole } from "@/components";
import { humanizedDuration } from "@/helpers/generalHelper";
class ShiftsTableRow extends DLTableRow {

    state = {
        isEditing: false,
        isDeleting: false
    };


    delete = async () => {
        this.setState({ isDeleting: false });
        this.props.setLoader(true);
        try {
            const { employee, data } = this.props;
            await Api.employees(employee.id).shifts(data.id).delete();
            this.props.setSnackbar(true, "Shift deleted successfully");
            this.props.reload();
            this.props.getShifts();

        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(500, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .on(403, response => {
                    const errors = _.values(_.mapValues(response.data.message, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors, "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )

                .go();
        }
        this.props.setLoader(false);
    };

    renderCells() {
        const { employee, data, LeaveRequest, tableRef: { state: { meta: { total: totalShifts } } } } = this.props;
        const { isEditing, isDeleting } = this.state;
        return [
            <DLTableColumn
                key={`Employee-${data.id}-Column-1`}
                align="left"
                withSeparator={true}>
                {data.details.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-2`}
                align="left"
                withSeparator={false}>
                <ShiftDays data={data.details} />
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-3`}
                align="left"
                withSeparator={false}>
                {moment(data.details.start_time, "HH:mm:ss").format("hh:mm A")} to {moment(data.details.end_time, "HH:mm:ss").format("hh:mm A")}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-4`}
                align="right"
                withSeparator={false}>
                {humanizedDuration(data.details.grace_time / 60)}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-5`}
                align="right"
                withSeparator={false}>
                {humanizedDuration(data.details.shift_hours)}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-6`}
                align="left"
                withSeparator={false}>
                {data.start_date}
            </DLTableColumn>,

            // <DLTableColumn
            //     key={`Employee-${data.id}-Column-7`}
            //     align="left"
            //     withSeparator={false}>
            //     {data.end_date || "Till Date"}
            // </DLTableColumn>,

            <DLTableColumn
                key={`Employee-${data.id}-Column-7`}
                align={"right"}
                isCompact={true}
                fillWidth={35}
            >
                <UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
                    <>
                        <Tooltip title="Edit Leaves">
                            <IconButton
                                onClick={() => this.setState({ isEditing: "edit" })}
                                size="small"
                                color="primary"
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        {
                            isEditing &&
                            <ShiftsModal
                                mode={isEditing}
                                Request={LeaveRequest}
                                fixedDate={totalShifts <= 1}
                                shifts={data}
                                employee={employee.id}
                                title="Edit Shifts"
                                onUpdate={this.props.reload}
                                onClose={() => this.setState({ isEditing: false })}
                            />
                        }
                    </>
                </UserRole>
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-9`}
                align={"center"}
                isCompact={true}
                fillWidth={35}
            >
                <UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
                    <>
                        {
                            totalShifts > 1 &&
                            <Tooltip title="Delete Shift">
                                <IconButton
                                    onClick={() => this.setState({ isDeleting: true })}
                                    size="small"
                                    color="secondary"
                                >
                                    <DeleteOutlined />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            isDeleting &&
                            <ConfirmModal
                                title="Delete Leaves"
                                isOpen={isDeleting}
                                message={`Are you sure want to delete ${data.details.name} shift?`}
                                onClickCancel={() => this.setState({ isDeleting: false })}
                                onClickConfirm={this.delete}
                            />
                        }
                    </>
                </UserRole>
            </DLTableColumn>
        ];
    }

}

ShiftsTableRow.Columns = [
    {
        key: "Shift",
        label: "Shift",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "week_days",
        label: "Days",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "timing",
        label: "Timing",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "grace_time",
        label: "Grace Time",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "working_hours",
        label: "Working Hours",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "start_date",
        label: "Start date",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    // {
    //     key: "end_date",
    //     label: "End date",
    //     align: "left",
    //     isSortable: true,
    //     withSeparator: false
    // },
    {
        key: "action",
        label: "",
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
];

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(ShiftsTableRow);