export default theme => ({

    chip: {
        marginTop: -5,
        marginRight: theme.spacing(1)
    },

    employeeName: {
        marginRight: theme.spacing(1),

        "&::after": {
            display: "inline",
            content: "'-'",
            marginLeft: theme.spacing(1)
        }
    },
    name: {
        lineHeight: 0
    },

    code: {
        lineHeight: 0
    },

    documentContainer: {
        alignSelf: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    documentPage: {
        boxShadow: theme.shadows[2],
        borderRadius: 5,
        overflow: "hidden",
        maxWidth: "100%",
        height: "auto !important"
    },

    noPreview: {
        padding: 100
    },

    buttonIcon: {
        marginRight: theme.spacing(1)
    }
    
});