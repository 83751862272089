export default theme => ({

    filterPanel: {
        margin: 0,
        boxShadow: "none",
        borderTop: "1px solid",
        borderTopColor: theme.palette.borderColor.main,

        "&.Mui-expanded": {
            margin: 0
        }
    },

    filterPanelDetails: {
        display: "block",
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },

    row: {
        marginTop: 0
    },

    operator: {
        padding: "3px 0px 2px 10px"
    },

    filterValue: {
        flex: 1
    },

    addFilterButton: {
        padding: "4px 10px 4px 5px"
    } 

});