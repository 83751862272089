import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import AddDesignationModal from "./../../pages/AddDesignation";
import _ from "lodash";

class DesignationDropdown extends Component {
    constructor(props) {
        super(props);
        this._asyncDropDown = React.createRef();
    }
    static defaultProps = {
        label: "Designation",
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        designation: null,
        isModalOpen: false,
        textFieldValue: ""
    };

    modalclose = () => {
        this.setState({ isModalOpen: false }, async () => {
            await this._asyncDropDown.current.loadOptions()
        });
    }


    loadOptions = async (keyword = "") => {
        this.setState({ textFieldValue: keyword })
        if (this.state.designation != null && keyword === this.state.designation.name) {
            keyword = "";
        }

        this.setState({ loading: true });
        var response = await Api.designations.get({ per_page: 50, filter: { name: keyword } });
        var createNew = response.data;
        let designation = _.find(createNew, (item) => {
            return item.id === this.props.value;
        });
        this.setState({ designation });
        createNew = [...createNew, { name: "Create New Designation", type: "designation" }];
        response.data = createNew;
        return response.data;
    };

    onChange = (event, designation) => {
        if (designation && designation.id) {
            this.setState({ designation });
            this.props.onChange((designation && designation.id) || null);
        } else if (designation === null) {
            this.props.onChange(null)
        } else {
            this.setState({ isModalOpen: true, textFieldValue: this.state.textFieldValue })
        }
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <>
                <AsyncDropdown
                    ref={this._asyncDropDown}
                    classes={{ paper: classes.dropdown }}
                    label={label}
                    textFieldProps={textFieldProps}
                    getOptions={this.loadOptions}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.id === value}
                    onChange={this.onChange}
                    value={this.state.designation}
                    disabled={!!disabled}
                />
                {
                    this.state.isModalOpen &&
                    <AddDesignationModal
                        avoidRoute={true}
                        backRoute={"goback"}
                        textFieldValue={this.state.textFieldValue}
                        onClose={this.modalclose}
                        onUpdate={async () => { await this._asyncDropDown.current.loadOptions() }} />
                }
            </>
        );
    }

}

export default withStyles(styles)(DesignationDropdown);