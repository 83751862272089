import Types from "@/components/Filter/types";

const data = [
	{
		key: 'name',
		label: 'Name',
		type: Types.string,
		example: 'Al-Burhan'
	},
    {
		key: 'city',
		label: 'City',
		type: Types.string,
		example: 'Karachi'
	},
    {
		key: 'state',
		label: 'Province',
		type: Types.string,
		example: 'Sindh'
	},
    {
		key: 'created_at',
		label: 'Created At',
		type: 'date',
		example: '2022/03/23'
	},
    {
		key: 'country',
		label: 'Country',
		type: Types.string,
		example: 'Pakistan'
	},
	{
		key: 'status',
		label: 'Status',
		type: Types.selection,
		options: [
			{
				key: 'active',
				label: 'Active'
			},
			{
				key: 'archived',
				label: 'Archived'
			},
		]
	}
];

export default data;