import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, Icon, Grid } from "@material-ui/core";
import { DLTable, InfographicMessage, Page, BulkAction } from "@/components";
import LeaveTableRow from "./LeaveRequestTableRow";
import Filter from "@/components/Filter";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import LeaveRequestModal from "@/components/Modals/LeaveRequestModal";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import _ from "lodash";
import styles from "./style";
import { AuthContext } from "@/contexts";
import FilterData from "./LeaveRequestFilterData";

class LeavesRequestList extends Component {
    static contextType = AuthContext;
    constructor (props) {
        super(props);
        this.tableRef = React.createRef();
        this.bulkAction = React.createRef();
        this.state = {
            isEditing: false,
            filters: null,
            fields: FilterData
        };
    }

    onUpdate = () => {
        this.props.onUpdate();
    };

    onSearch = filters => {
        const { filters: oldFilters } = this.state;
        this.setState({ filters }, () => {
            if (!!oldFilters && this.tableRef && this.tableRef.current) {
                this.tableRef.current.fetchData();
            }
        });
    }

    async setLeaveStatus(selected, status) {
        this.props.setLoader(true);
        try {
            await Api.leave_requests.update_all.patch({
                ids: selected.map(e => e.id),
                status: status 
            });
            this.props.setSnackbar(true, status == "approved" 
                ? `${selected.length} leave${selected.length == 1 ? '' : 's'} approved`
                : ` ${selected.length} leave${selected.length == 1 ? '' : 's'} rejected`
            );
        } catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                    this.props.setSnackbar(true, errors[0], "error");
                } else {
                    this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                }
            })
            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
            .go();
        }
        this.props.setLoader(false);
        
        if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.fetchData();
        }
    }

    getItems() {
        let items = [];
        items.push({
            name: "Approve",
            action: (_, action) => action.setState({ enableLeaveModal: true }),
            render: (selected, action) => (
                action.state.enableLeaveModal &&
                <ConfirmModal
                    isOpen={action.state.enableLeaveModal}
                    title={"Approve Leave Correction"}
                    message={`Are you sure to approve leave correction for ${selected.length} employee${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
                    onClickConfirm={async () => {
                        await this.setLeaveStatus(selected, "approved");
                        action.setState({ enableLeaveModal: false, activeOption: null });
                    }}
                />
            )
        });

        items.push({
            name: "Reject",
            action: (_, action) => action.setState({ disableLeaveModal: true }),
            render: (selected, action) => (
                action.state.disableLeaveModal &&
                <ConfirmModal
                    isOpen={action.state.disableLeaveModal}
                    title={"Reject Leave Request"}
                    message={`Are you sure to reject Leave request for ${selected.length} employee${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel={() => action.setState({ disableLeaveModal: false, activeOption: null })}
                    onClickConfirm={async () => {
                        await this.setLeaveStatus(selected, "rejected");
                        action.setState({ disableLeaveModal: false, activeOption: null });
                    }}
                />
            )
        });
        
        return items;
    }

    emptyView() {
        const message = "You don't have any leave request yet, add one now!"

        return (
            <InfographicMessage
                illustration="leaveRequest"
                message={message}
                actionLabel={"Add leave Request "}
                actionIcon={"add"}
                actionHandler={() => this.setState({ isEditing: "new" })}
            />
        );
    }

    render() {
        const { filters, isEditing, fields } = this.state;
       
        return (
            <Page title="Leaves Request">
                <Card raised>
                <Grid container justifyContent={"space-between"}>
                    <CardActions>
                        <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={() => this.setState({ isEditing: "new" })} >
                            <Icon>add</Icon>
                            Add Leaves
                        </Button> 
                        <BulkAction
                            ref={this.bulkAction}
                            options={this.getItems()}
                        />
                    </CardActions>
                    <CardActions>
                        <Filter
                            fields={fields}
                            filters={
                                filters || [{
                                    field: 'status',
                                    method: 'is',
                                    value: 'pending',
                                    operator: 'and'
                                }]
                            }
                            withQueryParams
                            onUpdate={this.onSearch}
                        />
                    </CardActions>
                    </Grid>
                    {
                        !!filters &&
                        <DLTable
                            enableSelection
                            bulkAction={this.bulkAction}
                            ref={this.tableRef}
                            api={Api.leave_requests}
                            params={{ filter: filters }}
                            RowTemplate={LeaveTableRow}
                            emptyView={this.emptyView(true)}
                        />
                    }
                </Card>
                {
                    isEditing && (
                    <LeaveRequestModal
                        mode={isEditing}
                        title="Add Leave"
                        onUpdate={() => this.tableRef.current.fetchData()}
                        onClose={() => this.setState({ isEditing: false })}
                    />
                )}
            </Page>
        );
    }
}

const mapStateToProps = state => ({
    filterCounter: state.general.filterCounter
});

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeavesRequestList));
