const style = theme => ({
	
	switch: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: 0
	}

});

export default style;