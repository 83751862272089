import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles"
import PropTypes from 'prop-types';
import { Card, Grid, Typography, Icon, CardActionArea } from '@material-ui/core';
// Current Folder files 
import classNames from 'classnames';
import styles from './style.js';
import moment from 'moment';


class ShiftChip extends Component {

    render() {
        const { classes, isActive, shift } = this.props;
        return (
            <Card className={classNames(classes.root, { [classes.active]: isActive })}>
                <CardActionArea onClick={() => this.props.onClick(shift.id)}>
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                    >

                        <Grid xs={10} item container direction="column" style={{padding:"5px"}}>
                            <Typography
                                component="div"
                                variant="subtitle1"
                                style={{ color: isActive ? "white" : "black" }}
                            >
                                {shift.name}
                            </Typography>
                            <Typography
                                variant="caption"
                                style={{ color: isActive ? "white" : "black" }}
                            >
                                {moment(shift.start_time,"hh:mm:ss").format("hh:mma")}-{moment(shift.end_time,"hh:mm:ss").format("hh:mma")}
                            </Typography>
                        </Grid>
                        <Grid xs={2} container justifyContent={"center"}>
                            <Grid item>
                                <Icon color="disabled" className={classes.checkmark}>{isActive ? "check_circle" : "radio_button_unchecked"} </Icon>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    }
}

ShiftChip.propTypes = {
    isActive: PropTypes.bool.isRequired,
    shift: PropTypes.object.isRequired
}
export default withStyles(styles)(ShiftChip);
