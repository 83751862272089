import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Proptypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Api } from "@/helpers";
import { PDFViewer } from "@/components";

class InvoicePreviewModal extends Component {

    static propTypes = {
        invoice: Proptypes.object,
        onClose: Proptypes.func,
        open: Proptypes.bool.isRequired
    };

    state = {
        enableDownload: false
    };

    getInvoiceNo() {
        const { invoice, match: { params: { invoice_no = null } } } = this.props;
        return invoice_no || (invoice && invoice.invoice_no) || "";
    }

    download = () => {
        const link = document.createElement('a');
        link.href = Api.invoices(this.getInvoiceNo()).export.getUrl();
        link.setAttribute('download', `invoice-${this.getInvoiceNo()}.pdf`);
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    onClose = () => {
        if (this.props.onClose) {
			this.props.onClose();
		} else if (this.props.history.length > 1) {
			this.props.history.goBack();
		} else {
    		this.props.history.push(this.props.match.url.break("/").splice(0, -1).join("/"));
		}
    }

    render() {
        const { enableDownload } = this.state;
        const { open } = this.props;
        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                scroll="paper"
				aria-labelledby="invoice-preview-dialog-title"
                aria-describedby="invoice-preview-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="invoice-preview-dialog-title">Invoice # {this.getInvoiceNo()}</DialogTitle>
                <DialogContent dividers={true}>
                    <PDFViewer
                        url={Api.invoices(this.getInvoiceNo()).export.getUrl()}
                        onLoadSuccess={() => this.setState({ enableDownload: true })}
                        onLoadError={() => this.setState({ enableDownload: false })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button size={"small"} onClick={this.onClose}>Cancel</Button>
                    <Button
                        size={"small"}
                        onClick={this.download}
                        disabled={!enableDownload}
                        color="primary">
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

export default withRouter(InvoicePreviewModal);