import React from "react";
import { withRouter } from "react-router-dom";
import { DLTableRow, DLTableColumn } from "@/components";
import _ from 'lodash';
import NoRecord from '../../assets/icons/no-use-bg.svg';


class EmployeeAttendanceSummaryRow extends DLTableRow {

    columnExtra = () => {
        const { data } = this.props;

        var records = data.records
        if (records && records.length < 31) {
            var skip = 31 - records.length;
            for (var i = 0; i < skip; i++) {
                return (<DLTableColumn nowrap colSpan={skip}
                    key={`Attendance-${records.date}-Column-${i}`}
                    align={"center"}
                    withSeparator={true}
                    style={{
                        backgroundImage: `url(${NoRecord})`
                    }}>
                </DLTableColumn>)
            }
        }
    }


    renderCells() {
        const { data } = this.props;
        var total_days =  data.days + data.holidays
        if (data.sub_total_hours === undefined) {
            return [
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-1`}
                    align={"center"}
                    
                    withSeparator={true}>
                    {data.month}
                </DLTableColumn>,
                data.records.map((record, i) => {
                    return (
                        <DLTableColumn
                            key={`Attendance-${record.date}-Column-${i}`}
                            align={"center"}
                            fillWidth={45}
                            style={{
                                backgroundColor: (record.status == "H" ? "#FF7300" : null),
                                backgroundImage: record.status == " " ? `url(${NoRecord})` : null,
                                color: record.status == "H" ? "white"
                                    :
                                    (record.status == "A" ? "#281B1B"
                                        :
                                        (record.status == "P" ? "#00D454"
                                            :
                                            (record.status == "LT" ? "#FABD60"
                                                :
                                                (record.status == "HD" ? "#00C7D4"
                                                    :
                                                    "#64182A")))
                                    ),
                            }}
                            withSeparator={true}>
                            {record.status}
                        </DLTableColumn>
                    )
                })
                ,
                this.columnExtra(),
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-33`}
                    align={"center"}
                    withSeparator={true}>
                    {data.hours === 0 ? "00:00" : data.hours}
                </DLTableColumn>,
                 <DLTableColumn
                 key={`Attendance-${data.month}-Column-34`}
                 align={"center"}
                 withSeparator={true}>
                 {data.month_days}
             </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-35`}
                    align={"center"}
                    withSeparator={true}>
                    {data.holidays}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-36`}
                    align={"center"}
                    
                    withSeparator={true}>
                    {data.days}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-37`}
                    align={"center"}
                    withSeparator={true}>
                    {data.presents}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-38`}
                    align={"center"}
                    withSeparator={true}>
                    {data.absents}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-39`}
                    align={"center"}
                    withSeparator={true}>
                    {data.leaves}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-40`}
                    align={"center"}
                    
                    withSeparator={true}>
                    {data.late}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-41`}
                    align={"center"}
                    withSeparator={false}>
                    {data.half_days}
                </DLTableColumn>
            ];
        } else {
            return [
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-36`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {"TOTAL"}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-37`}
                    align={"center"}
                    variant={"head"}
                    colSpan={31}
                    withSeparator={true}>
                    {" "}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-38`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_hours === 0 ? "00:00" : data.sub_total_hours}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-39`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.total_month_days}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-40`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_holiday}
                </DLTableColumn>,
                  <DLTableColumn
                  key={`Attendance-${data.month}-Column-41`}
                  align={"center"}
                  variant={"head"}
                  withSeparator={true}>
                  {data.sub_total_days}
              </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-42`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_presents}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-43`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_absents}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-44`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_leaves}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-45`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={true}>
                    {data.sub_total_late}
                </DLTableColumn>,
                <DLTableColumn
                    key={`Attendance-${data.month}-Column-46`}
                    align={"center"}
                    variant={"head"}
                    withSeparator={false}>
                    {data.sub_total_half_days}
                </DLTableColumn>
            ];
        }
    }
}

EmployeeAttendanceSummaryRow.Columns = props => {
    var dataColumnsArray = Array.from(Array(31), (_, i) => i + 1)
    var dateColumns = {}
    var columns = [{
        key: "employee_month",
        label: "Month",
        align: "center",
        isSortable: false,
        withSeparator: true,
    },]
    dateColumns = _.map(dataColumnsArray, (label) => {
        return {
            label: label,
            align: "center",
            withSeparator: true
        }
    })
    var columnDate = [
        {
            key: "hours",
            label: "Hours",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "total",
            label: "Total Days",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "holidays",
            label: "Holidays",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "working_days",
            label: "Working Days",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "present",
            label: "Presence",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "absent",
            label: "Absence",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "leaves",
            label: "Leaves",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "late",
            label: "Lates",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "halfDay",
            label: "Half Days",
            align: "center",
            isSortable: false,
        }
    ]
    columns.push(...dateColumns, ...columnDate)
    return columns
}

export default withRouter(EmployeeAttendanceSummaryRow)