import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, CardActions, Icon, Grid } from "@material-ui/core";
import { DLTable, BulkAction, InfographicMessage } from "@/components";
import EmployeeOtherIncomeModal from "@/components/Modals/EmployeeOtherIncomeModal";
import EmployeeOtherIncomeTableRow from "./EmployeeOtherIncomeTableRow";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import PermissionHOC from "@/components/PermissionHOC";
import UserRole from "@/components/UserRole";
import Filter from "@/components/Filter";
import Methods from "@/components/Filter/methods";
import { Permissions } from "@/config/Permissions";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";
import moment from "moment";
import FilterData from "./FilterData";

class EmployeeOtherIncome extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();

		this.state = {
			isAddingOtherIncome: false,
			isFilterOpen: false,
			filters: null
		};
	}

	taxYear() {
		return moment().month() >= 6 ? moment().year() + 1 : moment().year();
	}

	getOtherIncomes = filters => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			!!oldFilters && this.reload();
		});
	};

	reload = () => {
		this.setState({ isAddingOtherIncome: false });
		this.tableRef.current && this.tableRef.current.fetchData();
	};

	emptyView() {
		const { employee, hasPermission } = this.props;
		if (hasPermission([Permissions.EMPLOYEES_FULL_ACCESS])) {
			return (
				<InfographicMessage
					illustration="OtherIncome"
					message={`${employee.first_name} doesn't have any other income yet, add one now!`}
					actionLabel={"Add Other Income"}
					actionIcon={"add"}
					actionHandler={() => this.setState({ isAddingOtherIncome: true })}
				/>
			);
		}
		return (
			<InfographicMessage
				illustration="OtherIncome"
				message={`${employee.first_name} doesn't have any other income yet`}
			/>
		);
	}
	async deleteItem(selected) {
		const { employee } = this.props;
		this.props.setLoader(true);
			try {
				await Api.employees(employee.id).other_incomes.delete_multiple.delete({  ids: selected.map(e => e.id), })
				this.props.setSnackbar(true, "Selected items deleted successfully");
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();

		}
	}


	getItems() {
		let items = [{
				name: "Delete Selected",
				color: "secondary",
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={"Delete Other Income"}
						message={`Are you sure to delete ${selected.length} other income${selected.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							await this.deleteItem(selected);
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}]
		return items;
	}
	
	render() {
		const { employee } = this.props;
		const { isAddingOtherIncome, filters } = this.state;
		const dateFilter = FilterData[0];

		return (
			<>
				<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
					<Grid container justifyContent={"space-between"}>
					<CardActions style={{ flex: 1 }}>
							<Button
								variant="outlined"
								size="medium"
								color="primary"
								onClick={() => this.setState({ isAddingOtherIncome: true })}>
								<Icon>add</Icon>
								Add Other Income
							</Button>
							<BulkAction
								ref={this.bulkAction}
								options={this.getItems()}
							/> 
					</CardActions>
					<CardActions>
						<Filter
							fields={FilterData}
							filters={
								filters || [
									{
										operator: "and",
										field: dateFilter.key,
										method: Methods[dateFilter.type][4].key,
										value: moment().year(this.taxYear() - 1).month(6).startOf("month").format("YYYY-MM-DD")
									},
									{
										operator: "and",
										field: dateFilter.key,
										method: Methods[dateFilter.type][3].key,
										value: moment().year(this.taxYear()).month(5).endOf("month").format("YYYY-MM-DD")
									}
								]
							}
							withQueryParams
							onUpdate={this.getOtherIncomes}
						/>
					</CardActions>
					</Grid>
				</UserRole>
				{
					!!filters &&
					<DLTable
						enableSelection
						ref={this.tableRef}
						api={Api.employees(employee.id).other_incomes}
						params={{ filter: filters }}
						rowProps={{ employee }}
						RowTemplate={EmployeeOtherIncomeTableRow}
						emptyView={this.emptyView()}
						bulkAction={this.bulkAction}
					/>
				}
				{
					isAddingOtherIncome &&
					<EmployeeOtherIncomeModal
						title={"Add Other Income"}
						isOpen={isAddingOtherIncome}
						employee={employee}
						onUpdate={this.reload}
						onClose={() => this.setState({ isAddingOtherIncome: false })}
					/>
				}

			</>
		);
	}
}

const mapStateToProps = state => ({
	filterCounter: state.general.filterCounter
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionHOC(EmployeeOtherIncome));
