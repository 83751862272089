import React from "react";
import {
    TextField,
    IconButton,
    InputAdornment,
    Icon,
    ListItemIcon,
    Grid,
    Tooltip,
    Chip,
    Fab,
    Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import { EditOutlined, NotificationsActive, Launch } from "@material-ui/icons";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { DLTableRow, DLTableColumn, Money, EmployeeAvatar, ReminderNotificationChip } from "@/components";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import PropTypes from "prop-types";
import styles from "./style";
import { AuthContext } from "@/contexts";
import PaymentStatusModal from "@/components/Modals/PaymentStatusModal";
import { Api } from "@/helpers";
import LeaveAdjustmentModal from "@/components/Modals/LeaveAdjustmentModal";
import LateAdjustmentModal from "@/components/Modals/LateAdjustmentModal";
import HalfDayAdjustmentModal from "@/components/Modals/HalfDayAdjustmentModal";
import classNames from "classnames";
import RemindersModal from "@/components/Modals/RemindersModal";
import Theme from "@/themeStyles";

const dataColor = [
    { backgrounColor: "#E4FBEC" },
    { backgrounColor: "#E4F7FB" },
    { backgrounColor: "#F2E4FB" },
    { backgrounColor: "#FBF2E4" },
    { backgrounColor: "#FBE4F5" }
];
class PayslipTableRow extends DLTableRow {
    static propTypes = {
        employee: PropTypes.object.isRequired,
        data: PropTypes.object,
        onUpdate: PropTypes.func,
    };

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            showingPaymentDetails: false,
            isLatesModalOpen: false,
            isHalfDaysModalOpen: false,
            isReminderOpen:false
        };
    }
    
    onRemovePayment = () => {
        this.setState({ showingPaymentDetails: false });
        this.props.onRemovePayment();
    };
    
    data() {
        const { data } = this.props;
        return {
            employee: data.employee,
            rate: 0,
            quantity: 0,
            name: "",
            ...this.props.data,
            ...this.state.data,
        };
    }

    earnings() {
        return this.data().pay_items.filter(item =>
            !item.is_reminder && (item.details.type !== 'Taxes')  && (
            (item.details.is_earning && item.amount > 0) ||
            (item.details.is_deduction && item.amount < 0)||
            (item.details.is_salary && item.amount == 0)
            )
        );
    }

    deductions() {
        return this.data().pay_items.filter(item =>
            !item.is_reminder && (item.details.type !== 'Taxes') && (
            (item.details.is_deduction && item.amount > 0) ||
            (item.details.is_earning && item.amount < 0)) ||
            (item.details.is_leave && item.amount == 0) ||
            (item.details.is_late && item.amount == 0) ||
            (item.details.is_half_day && item.amount == 0)
        );
    }

    onClick(item) {
        if(!!item && item.details.is_leave) {
            this.setState({isLeavesModalOpen : true})

        } else if(!!item && item.details.is_late) {
            this.setState({isLatesModalOpen : true})

        } else if(!!item && item.details.is_half_day) {
            this.setState({isHalfDaysModalOpen: true})
        }
    }

    renderPayItem = (item = null, isEarning = true) => {
        const { classes } = this.props;
        return (
            <Grid
                key={`PayslipItem-${(item && item.id) || 'empty'}`}
                className={classNames(classes.itemContainer, !!item && (item.details.is_leave || item.details.is_late || item.details.is_half_day) && classes.pointer)} 
                onClick={() => this.onClick(item)}>
                <Grid className={classes.payItemName}>
                    {!!item ? item.details.name : ""}
                    {!!item && item.details.is_overtime && ` (${item.quantity} hr${item.quantity == 1 ? '' : 's'})`}
                    {
                        !!item && (item.details.is_leave || item.details.is_late || item.details.is_half_day) &&
                        <Typography variant="span" color="primary" style={{lineHeight: 0}}><Launch style={{fontSize: '1em'}}/></Typography>
                    }
                </Grid>
                <Grid className={isEarning ? classes.earningAmount : classes.deductionAmount}>
                    {
                        !!item
                            ? <Money amount={Math.abs(item.amount)}/>
                            : "—"
                    }
                </Grid>
            </Grid>
        );
    };

    taxes() {
        return this.data().pay_items.filter(item => !item.is_reminder && (item.details.type === 'Taxes'));
    }
    
    renderCells() {
        const { classes, rowIndex, isPreview, isView, isTaxModule } = this.props;
        const { showingPaymentDetails } = this.state
        const data = this.data();
        let count = 0;
        return [
            <DLTableColumn
                key={`PayslipItem-${data.id}-Column-0`}
                isCompact
                withSeparator
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: 'top',
                    paddingTop: '25px',
                    textAlign: 'center'
                }}>
                #{rowIndex + 1}
            </DLTableColumn>,
            <DLTableColumn
                key={`PayslipItem-${data.id}-Column-1`}
                withSeparator={true}
                align={"top"}
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <EmployeeAvatar data={data} />
                    {
                        !(isView || isPreview) &&
                        !data.is_paid &&
                        data.reminders &&
                        !!data.reminders.length &&
                        (
                            <ReminderNotificationChip
                                count={data.reminders.length}
                                onClick={() => this.setState({ isReminderOpen: true })}
                            />
                        )
                    }
                </Grid>
            </DLTableColumn>,
            <DLTableColumn
                align="right"
                withSeparator={true}
                key={`PayslipItem-${data.id}-Column-2`}
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}>
                { this.earnings().map(item => this.renderPayItem(item)) }
                { !this.earnings().length && this.renderPayItem() }
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={true}
                key={`PayslipItem-${data.id}-Column-3`}
                width="150"
                align="right"
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}>
                { this.deductions().map(item => this.renderPayItem(item, false)) }
                { !this.deductions().length && this.renderPayItem(null, false) }
            </DLTableColumn>,
           isTaxModule && 
           <DLTableColumn
                key={`PayslipItem-${data.id}-Column-4`}
                withSeparator={true}
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}>
                { this.taxes().map(item => this.renderPayItem(item, false)) }
                { !this.taxes().length && this.renderPayItem(null, false) }
            </DLTableColumn>,
            <DLTableColumn
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}
                align={"right"}
                key={`PayslipItem-${data.id}-Column-5`}
                withSeparator={!isPreview || isView}>
                <Grid className={classes.netPayable}>
                    <Money amount={data.net_payable} />
                </Grid>
            </DLTableColumn>,
            !(isPreview || isView) &&
            <DLTableColumn
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}
                align={"center"}
                key={`PayslipItem-${data.id}-Column-6`}
                isCompact={true}
            >
               <Link to={{pathname:`/payroll/${data.payrun_id}/payslip/${data.id}` }} >
                    <IconButton size="small" color="primary" >
                        <EditOutlined/>
                    </IconButton>
                </Link>
            </DLTableColumn>,
            isView &&   
            <DLTableColumn
                align={"left"}
                key={`Payslip-${data.id}-Column-7`}
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top",
                }}>
                <>
                    {
                        data.is_paid ? (
                            <Tooltip title="Click to view payment details">
                                <Fab
                                    onClick={() => this.setState({ showingPaymentDetails: true })}
                                    color={"primary"}
                                    className={classes.paidFab}
                                    size="small"
                                    component="span"
                                    variant="extended">
                                    PAID
                                </Fab>
                            </Tooltip>
                        ) : (
                            <Chip size="small" label={"UNPAID"} color={"secondary"} />
                        )
                    }
                </>
                {
                    showingPaymentDetails && isView &&
                    <PaymentStatusModal
                        isOpen={showingPaymentDetails}
                        payslip={data}
                        onClose={() => this.setState({ showingPaymentDetails: false })}
                        onDelete={this.onRemovePayment}
                    />
                }
            </DLTableColumn>,
            isView &&
            <DLTableColumn
                key={`Payrun-${data.id}-Column-8`}
                isCompact={true}
                align={"right"}
                fillWidth={35}
                style={{
                    backgroundColor: dataColor[rowIndex % 5].backgrounColor,
                    verticalAlign: "top"
                }}>
                <Tooltip style={{display:"block",  marginTop: "5px",}} title="Download Payslip">
                    <IconButton 
                        size="small"
                        color="primary"
                        onClick={() => window.open(Api.payruns(data.payrun_id).export.payslips.getUrl({ employees: [data.employee.id] }))}>
                        <Icon>cloud_download</Icon>
                    </IconButton>
                </Tooltip>
            </DLTableColumn>,
            this.state.isLeavesModalOpen &&
            <LeaveAdjustmentModal
                isOpen={this.state.isLeavesModalOpen}
                data={data}
                viewOnly={isView || isPreview}
                onUpdate={this.props.onUpdate}
                onClose={() => this.setState({ isLeavesModalOpen: false })}
            />,
            this.state.isLatesModalOpen &&
            <LateAdjustmentModal
                isOpen={this.state.isLatesModalOpen}
                data={data}
                viewOnly={isView || isPreview}
                onUpdate={this.props.onUpdate}
                onClose={() => this.setState({ isLatesModalOpen: false })}
            />,
            this.state.isHalfDaysModalOpen &&
            <HalfDayAdjustmentModal
                isOpen={this.state.isHalfDaysModalOpen}
                data={data}
                viewOnly={isView || isPreview}
                onUpdate={this.props.onUpdate}
                onClose={() => this.setState({ isHalfDaysModalOpen: false })}
            />,
            this.state.isReminderOpen &&
            <RemindersModal
                payslip={data}
                isOpen={this.state.isReminderOpen}
                onUpdate={this.props.onUpdate}
                onClose={() => this.setState({ isReminderOpen: false })}
            />
            
        ];
    }
}

PayslipTableRow.Columns = (props) => {
    return [
        {
            key: "name",
            label: "Employee Name",
            labelComponent: (
                <Grid style={{ padding: "15px", flex: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={props.search}
                        onChange={(e) => {
                            props.setState({ search: e.target.value });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            ),
            align: "left",
            isSortable: true,
            withSeparator: true,
            isCompact: true,
            colSpan: 2
        },
        {
            key: "earnings",
            label: "Earnings",
            align: "center",
            isSortable: true,
            withSeparator: true,
        },
        {
            key: "deductions",
            label: "Deductions",
            align: "center",
            isSortable: true,
            withSeparator: true,
        },
        props.isTaxModule  && {
            key: "taxes",
            label: "Taxes",
            align: "center",
            isSortable: true,
            withSeparator: true,
        },
        {
            key: "net_payable",
            label: "Net Payable",
            align: "right",
            isSortable: true,
            withSeparator: !props.isPreview || props.isView,
        },
        props.isView && {
            key: "status",
            label: "Status",
            align: "left",
            isSortable: true,
        },
        !props.isPreview && {
            key: "action",
            label: "",
            align: "right",
            colSpan:2,
            isSortable: true
        },
    ];
};
const mapDispatchToProps = (dispatch) => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(withStyles(styles)(PayslipTableRow)));
