import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

class Clock extends Component {

    state = {
        time: moment()
    };

    componentDidMount() {
        setInterval(() => {
        	this.setState({ time: moment() });
        }, 1000);
    }

    render() {
        const { classes, component = "div", color = "secondary" } = this.props;
        const { time } = this.state;
        return (
            <Typography className={classes && classes.clock} component={component} color={color}>
                {time.format('hh:mm A')}
            </Typography>
        );
    }

}

export default Clock;