import React, { Component } from "react";
import {
    withRouter, Switch,
    Route,
    Link
} from "react-router-dom";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { ArchiveOutlined, Inbox } from "@material-ui/icons";
import {
    DLTable, Tabs, InfographicMessage, Page,
    BulkAction,
    PermissionHOC,
    BackButton
} from "@/components";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { connect } from "react-redux";
import ShiftTableRow from "./ShiftsTableRow";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import AddShiftModal from "../ShiftsAdd";
import { AuthContext } from "@/contexts";

class Shifts extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.actionBarRef = React.createRef();
        this.bulkAction = React.createRef();
        this.tableRef = React.createRef();

    }
    state = {
        selectedTab: 0,
        activeTabIndex : 0

    };
    onUpdate = () => {
        this.props.onUpdate();
    };

    handleChange = () => {
        this._table && this._table.fetchData();
    }


    reload = () => {
        this.setState({ isModalOpen: false });
        this.tableRef && this.tableRef.current.fetchData();;
    };


    addNew = () => {
        this.props.history.push("/settings/shifts/add");
    };

    async delete(selected) {
        const { activeTabIndex} = this.state;
        this.props.setLoader(true);
        try {
            await Api.shifts.delete_multiple.delete({ ids: selected.map(e => e.id) });
            this.props.setSnackbar(true, `Selected items archived successfully`);
        } 
        catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                    this.props.setSnackbar(true, errors[0], "error");
                } else {
                    this.props.setSnackbar(true, "Something went wrong, please try again", "error");
                }
            })
            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
            .go();
        }
        this.props.setLoader(false);
        if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.fetchData();
        }
    }
    async restore(selected) {
        const { activeTabIndex} = this.state;
        this.props.setLoader(true);
        try {
            await Api.shifts.restore_multiple.patch({ ids: selected.map(e => e.id) });
            this.props.setSnackbar(true, `Selected items restored successfully`);
        } 
        catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
                const errors = _.values(_.mapValues(response.data, value => value[0]));
                if (errors && errors.length) {
                    this.props.setSnackbar(true, errors[0], "error");
                } else {
                    this.props.setSnackbar(true, "Something went wrong, please try again", "error");
                }
            })
            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
            .go();
        }
        this.props.setLoader(false);
        if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.fetchData();
        }
    }


    getItems() {
        const { activeTabIndex} = this.state;
        var buttonName = !!activeTabIndex ? "Restore Selected" : "Archive Selected";
        var buttonColors = !!activeTabIndex ? "primary" : "secondary";
            var items = [{
                    name: buttonName,
                    color: buttonColors,
                    action: (_, action) => action.setState({ enableLeaveModal: true }),
                    render: (selected, action) => (
                        action.state.enableLeaveModal &&
                        <ConfirmModal
                            isOpen={action.state.enableLeaveModal}
                            title={!!activeTabIndex ? "Restore Shift" : "Archive Shift"}
                            message={`Are you sure to ${!!activeTabIndex ? "restore" : "archive"} ${selected.length} Shift${selected.length == 1 ? '' : 's'}?`}
                            onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
                            onClickConfirm={async () => {
                                if (!!activeTabIndex) {
                                    await this.restore(selected);
                                }
                                else{
                                    await this.delete(selected);
                                }
                                action.setState({ enableLeaveModal: false, activeOption: null });
                            }}
                        />
                    )
                }];
        return items;
    }

    emptyView(archived = false) {
        const message = !archived
            ? "You don't have any shifts yet, add one now!"
            : "Yaayyy! all shifts are active and in use";
        return (
            <InfographicMessage
                illustration="shifts"
                message={message}
                actionLabel={archived ? "" : "Add Shift"}
                actionIcon={archived ? "" : "add"}
                actionHandler={this.addNew}
            />
        );
    }

    actionBar() {
    
        return (
                <CardActions>
                    <Link to={{ pathname: `/settings/shifts/add` }} style={{ textDecoration: 'none', color: "black" }}>
                        <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={this.addNew}>
                            <Icon>add</Icon>
                            Add Shift
                        </Button>
                    </Link>
                <BulkAction
                        ref={this.bulkAction}
                        options={this.getItems()}
                    />
                </CardActions>
        );
    }
    onSelectionChange = (selected) => this.actionBarRef.current.setSelected(selected);



    render() {
        return (
            <Page title="Shifts">
                <BackButton route="/settings" label="Back to Settings" />
                <Card raised>
                    <Tabs
                    onChange={activeTabIndex => this.setState({ activeTabIndex })}
                        beforePanel={this.actionBar()}>
                        <Tabs.Tab
                            icon={<Inbox />}
                            label="Active">
                            <DLTable
                                enableSelection
                                ref={this.tableRef}
                                api={Api.shifts}
                                RowTemplate={ShiftTableRow}
                                bulkAction={this.bulkAction}
                                emptyView={this.emptyView()}
                                rowProps={{ context: this.context }}
                            />
                        </Tabs.Tab>
                        <Tabs.Tab
                            icon={<ArchiveOutlined />}
                            label="Archived">
                            <DLTable
                                enableSelection
                                ref={this.tableRef}
                                api={Api.shifts}
                                params={{ filter: { archived: true } }}
                                RowTemplate={ShiftTableRow}
                                bulkAction={this.bulkAction}
                                emptyView={this.emptyView(true)}
                                rowProps={{ context: this.context }}
                            />
                        </Tabs.Tab>
                    </Tabs>
                </Card>
                <Switch>
                
                    <Route path="/settings/shifts/add">
                        <AddShiftModal
                            title={"Add Shifts"}
                            backRoute={"/settings/shifts"}
                            onUpdate={this.reload} />
                    </Route>
                    <Route path="/settings/shifts/:id">
                        <AddShiftModal
                            title={"Edit Shifts"}
                            backRoute={"/settings/shifts"}
                            onUpdate={this.reload} />
                    </Route>
                </Switch>
            </Page>
        );
    }

}
const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(Shifts));
