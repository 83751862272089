import Modules from "../config/Modules";
import { size, intersection } from "lodash";
class AccessManager {
	static isModuleAccess(moduleName, organization) {
    var modules = [];
    if(organization){
      if(organization.is_super_organization){
        return true;
      }else{
        if(organization.current_purchase){
          modules = organization.current_purchase.modules;
        }
      }
    }
    return (modules.indexOf(moduleName) === -1) ? false : true;
  }
  static isPurchaseExpired(organization){
    if(organization && organization.is_grace_period) {
      return false;
    }
    return (organization) ? organization.is_purchase_expired : true;
  }

  static isRoleAccess(key, organization) {
    if (organization !== undefined && organization.current_purchase && organization.current_purchase.modules) {
      const modules = organization.current_purchase.modules;

      if (key === "attendance") {
        let result = modules.map(module => module === Modules.ATTENDANCE && true);
        return result.includes(true) ? true : false;

      } else if (key === "employees") {
        let result = modules.map(module => (module === Modules.EMPLOYEE_HISTORY || module === Modules.PAYROLL || module === Modules.ATTENDANCE) && true);
        return result.includes(true) ? true : false;

      } else if (key === "employees_attendance") {
        let result = modules.map(module => module === Modules.ATTENDANCE && true);
        return result.includes(true) ? true : false;

      } else if (key === "payroll") {
        let result = modules.map(module => module === Modules.PAYROLL && true);
        return result.includes(true) ? true : false;

      } else if (key === "bank_account") {
        let result = modules.map(module => module === Modules.PAYROLL && true);
        return result.includes(true) ? true : false;

      } else if (key === "tax_report") {
        let result = modules.map(module => module === Modules.TAX && true);
        return result.includes(true) ? true : false;

      } else if (key === "salary_report") {
        let result = modules.map(module => module === Modules.PAYROLL && true);
        return result.includes(true) ? true : false;

      } else if (key === "payitem") {
        let result = modules.map(module => module === Modules.PAYROLL && true);
        return result.includes(true) ? true : false;

      }
      return true;

    }
    return false;
  }

  static hasPermission(requiredPermissions, user) {
    if (!user || !user.roles) return;

    if (user.roles.name === "Super Admin") {
      return true;
    }

    return !!size(intersection(requiredPermissions, user.permission_names));
  }
}

export default AccessManager;
