import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { arrayOf, bool, func, number, oneOf, oneOfType, shape, string } from "prop-types";
import {
	TextField,
	MenuItem,
	Paper,
    Popper,
    Grow,
    MenuList,
    Backdrop,
    Chip,
    Typography,
    IconButton,
    FormLabel
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Search } from "@material-ui/icons";
import _ from "lodash";
import Methods from "./methods";
import Types from "./types";
import styles from "./style";
import moment from "moment";
import qs from "qs";

const operators = ['and', 'or'];

class Filter extends Component {

    searchField = null;

    queryChangeInterval = null;

    static propTypes = {
        fields: arrayOf(shape({
            key: string.isRequired,
            label: string.isRequired,
            type: oneOf(_.keys(Methods)).isRequired,
            options: arrayOf(shape({
                key: string,
                label: string
            })),
            example: string
        })).isRequired,
        filters: arrayOf(shape({
            operator: string.isRequired,
            field: string.isRequired,
            method: string.isRequired,
            value: oneOfType(string, number).isRequired
        })),
        onUpdate: func.isRequired,
        withQueryParams: bool,
        preLoaded: bool
    };

    static defaultProps = {
        filters: [],
        withQueryParams: false,
        preLoaded: false
    };

    state = {
        text: "",
        showBackdrop: false,
        selectedOption: undefined,
        filter: { },
        asyncOptions: { },
        searchQuery: null
    };

    async componentDidMount() {
        console.log("testing", this.searchField);
        const { filters, withQueryParams, preLoaded } = this.props;
        if (!preLoaded) {
            if (withQueryParams) {
                this.queryChangeInterval = setInterval(() => this.updateFiltersFromQuery(), 10);
            } else {
                this.updateFilters(filters);
            }
        }

        await this.loadAsyncOptions();
    }

    async componentDidUpdate() {
        await this.loadAsyncOptions();
    }

    componentWillUnmount() {
        if (this.queryChangeInterval) clearInterval(this.queryChangeInterval);
    }

    async loadAsyncOptions() {
        const { fields } = this.props;
        const { asyncOptions } = this.state;
        const asyncFields = fields.filter(field => 
            field.type === Types.selection &&
            _.isFunction(field.options) &&
            !asyncOptions[field.key]
        );
        
        if (asyncFields.length) {
            let newOptions = { ...asyncOptions };
            asyncFields.forEach(field => {
                newOptions[field.key] = [];
            });
            this.setState({ asyncOptions: newOptions });

            for (let index = 0; index < asyncFields.length; index++) {
                const field = asyncFields[index];
                if (asyncOptions[field.key]) continue;

                newOptions[field.key] = await field.options();
            }
            this.setState({ asyncOptions: { ...newOptions } });
        }
    }

    validateFilters(filters) {
        return filters.reduce((validFilters, filter) => {
            const field = this.getField(filter.field);
            const method = this.getMethod(filter.method, filter.field);
            if (field && method && filter.value && operators.includes(filter.operator)) {
                if (field.type === Types.date) {
                    const date = moment(filter.value);
                    if (!date.isValid())
                        return validFilters;
                    else
                        filter.value = date.format('YYYY/MM/DD');

                } else if (field.type === Types.number) {
                    if (!filter.value.match(/^-?[0-9]+(\.[0-9]+)?$/)) return validFilters;
                }

                validFilters.push(filter);
            }
            return validFilters;
        }, []);
    }

    updateFiltersFromQuery() {
        const { location, filters } = this.props;
        const { searchQuery } = this.state;
        const newSearchQuery = location.search.substr(1);
        
        if (searchQuery === newSearchQuery) return;
        if (!location.search) return this.updateFilters(filters);
        
        const params = qs.parse(location.search.substr(1));
        if (!params.filter || !_.isArray(params.filter)) {
            params.filter = [];
        }

        this.updateFilters(params.filter);
        this.setState({ searchQuery: newSearchQuery });
    }

    updateFilters(filters) {
        const { location, onUpdate, withQueryParams } = this.props;
        const validatedFilters = this.validateFilters(filters);
        
        _.isFunction(onUpdate) && onUpdate(validatedFilters);
        if (withQueryParams && !location.search) this.updateFilterQuery(validatedFilters);
    }

    updateFilterQuery(_filters = null) {
        const { history, filters, withQueryParams = false } = this.props;
        if (withQueryParams) {
            history.replace({ search: `?${qs.stringify({ filter: _filters || filters })}` });

        } else {
            this.updateFilters(_filters || filters);
        }
    }

    getField(value, isLabel = false) {
        const { fields } = this.props;
        const { filter } = this.state;
        return fields.find(field => {
            if (isLabel) {
                return field.label == value;
            }
            return field.key == (value || filter.field)
        });
    }

    getMethod(value, fieldKey = null) {
        const { filter } = this.state;
        const field = this.getField(fieldKey);
        if (field && Methods[field.type]) {
            return Methods[field.type].find(m => m.key == (value || filter.method));
        }
        return null;
    }

    getFieldOptions(fieldKey) {
        const { asyncOptions } = this.state;
        const field = this.getField(fieldKey);
        
        return _.isFunction(field.options) ? asyncOptions[field.key] : field.options;
    }

    getFieldOption(value, fieldKey) {
        const options = this.getFieldOptions(fieldKey);
        return !!options && options.find(o => o.key == value);
    }

    getOptions() {
        const { fields } = this.props;
        const { filter, asyncOptions } = this.state;
        let options = [];

        if (!filter.field) {
            options = fields.filter(f => f.type !== Types.selection || _.isArray(f.options) || _.isArray(asyncOptions[f.key]));
        
        } else {
            const field = this.getField();
            if (!filter.method) {
                options = Methods[field.type];
    
            } else if (field.options) {
                options = this.getFieldOptions(field.key);

            }
        }

        return options;
    }

    getFilteredOptions(text) {
        return this.getOptions().filter(option => {
            const label = _.isString(option) ? option : option.label;
            return label.toLowerCase().search(text.toLowerCase()) >= 0;
        });
    }

    handleSearchChange = text => {
        this.setState({
            text,
            selectedOption: (!this.getFilteredOptions(text).length || !text) ? undefined : 0
        });
    };

    handleSearchKeyDown = event => {
        const { filters } = this.props;
        const { selectedOption = -1, text, filter } = this.state;
        const filteredOptions = this.getFilteredOptions(text);
        const field = this.getField(filter.field);

        switch(event.key) {
            case 'ArrowUp': {
                event.preventDefault();
                if (field && field.type === Types.date && filter.method) {
                    const date = moment(text);
                    this.setState({
                        text: (date.isValid() ? date : moment()).subtract(7, 'days').format('YYYY/MM/DD')
                    });

                } else {
                    const newOption = (selectedOption - 1) < 0 ? (filteredOptions.length - 1) : (selectedOption - 1);
                    this.setState({
                        selectedOption: newOption < 0 ? undefined : newOption
                    });
                }
                break;
            }
            case 'ArrowDown': {
                event.preventDefault();
                if (field && field.type === Types.date && filter.method) {
                    const date = moment(text);
                    this.setState({
                        text: (date.isValid() ? date : moment()).add(7, 'days').format('YYYY/MM/DD')
                    });

                } else {
                    const newOption = filteredOptions.length ? ((selectedOption + 1) % filteredOptions.length) : -1;
                    this.setState({
                        selectedOption: newOption < 0 ? undefined : newOption
                    });
                }
                break;
            }
            case 'ArrowLeft': {
                if (field && field.type === Types.date && filter.method) {
                    event.preventDefault();
                    const date = moment(text);
                    this.setState({
                        text: (date.isValid() ? date : moment()).subtract(1, 'day').format('YYYY/MM/DD')
                    });
                }
                break;
            }
            case 'ArrowRight': {
                if (field && field.type === Types.date && filter.method) {
                    event.preventDefault();
                    const date = moment(text);
                    this.setState({
                        text: (date.isValid() ? date : moment()).add(1, 'day').format('YYYY/MM/DD')
                    });
                }
                break;
            }
            case 'Enter':
            case 'Tab': {
                event.preventDefault();
                if (this.getOptions().length) {
                    filteredOptions[selectedOption] && this.handleOptionSelect(filteredOptions[selectedOption]);

                } else if (text) {
                    this.handleOptionSelect(text);
                }
                break;
            }
            case ' ': {
                if (selectedOption >= 0 && !this.getFilteredOptions(text + " ").length) {
                    event.preventDefault();
                    event.key = 'Enter';
                    this.handleSearchKeyDown(event);
                }
                break;
            }
            case 'Backspace': {
                if (!text && (filter.field || filter.method)) {
                    const key = !!filter.method ? 'method' : 'field';

                    this.setState({
                        text: key == 'field' ? this.getField().label : this.getMethod().label,
                        filter: {
                            ...filter,
                            [key]: null
                        }
                    });
                } else if (!text && filters.length) {
                    const lastFilter = filters[filters.length - 1];
                    const _field = this.getField(lastFilter.field);
                    const _option = this.getFieldOption(lastFilter.value, _field.key);
                    this.setState({
                        text: _field.type === Types.selection ? (_option ? _option.label : '—') : lastFilter.value,
                        filter: {
                            ...lastFilter,
                            value: null
                        }
                    });
                    this.updateFilterQuery(_.dropRight(filters, 1));
                }
                break;
            }
            default:
                console.log(event.key);
        }
    };

    handleSearchFocus = _ => {
        this.setState({
            showBackdrop: true,
            selectedOption: undefined
        });
        this.searchField && this.searchField.querySelector('input').focus();
    };

    handleSearchBlur = _ => {
        this.setState({
            showBackdrop: false,
            selectedOption: undefined
        });
    };

    handleOptionSelect = option => {
        const { filters } = this.props;
        const { filter } = this.state;
        let value = _.isString(option) ? option : option.key;

        const key = !filter.field ? 'field' : (!filter.method ? 'method' : 'value');
        
        if (key == 'field') {
            const field = this.getField(value);
            if (Methods[field.type].length == 1) {
                filter.method = Methods[field.type][0].key;
            }
        }

        if (key == 'value') {
            const field = this.getField(filter.field);
            
            // If value is a date, it should be valid
            if (field.type === Types.date) {
                const date = moment(value);
                value = (date.isValid() ? date : moment()).format('YYYY/MM/DD');
            }

            this.updateFilterQuery(filters.concat([{
                ...filter,
                value,
                operator: 'and' // TODO: make this editable as well
            }]));
            this.setState({ text: '', filter: {} });

        } else {
            this.setState({
                text: '',
                filter: {
                    ...filter,
                    [key]: value
                },
                selectedOption: undefined
            });
        }

        this.searchField && this.searchField.querySelector('input').focus();
    };

    handleRemoveFilter = (index) => {
        const { filters } = this.props;
        filters.splice(index, 1);
        this.updateFilterQuery([ ...filters ]);
    };

    getPlaceholder() {
        const { fields } = this.props;
        const { filter } = this.state;
        const placeholder = [];
        
        if (!filter.field) {
            placeholder.push(fields[0].label);
        }
        
        const field = this.getField(filter.field || fields[0].key);
        if (!filter.method) {
            placeholder.push(Methods[field.type][0].label);
        }

        switch(field.type) {
            case Types.number:
                placeholder.push(field.example || '100');
                break;

            case Types.date:
                placeholder.push(field.example || moment().format('YYYY/MM/DD'));
                break;

            case Types.selection:
                const options = this.getFieldOptions(field.key);
                placeholder.push(field.example || (options.length ? options[0].label : 'Something'));
                break;

            case Types.string:
            default:
                placeholder.push(field.example || 'Something');
                break;
        }

        return `${placeholder.join(' ')}`;
    }

    render() {
        const { classes, filters } = this.props;
        const { text, selectedOption, asyncOptions, filter, showBackdrop } = this.state;
        const options = this.getOptions();
        const filteredOptions = this.getFilteredOptions(text);
        const field = this.getField();
        
		return (
            <>
                <FormLabel
                    htmlFor="search-field"
                    className={classes.searchbarContainer}
                    onFocus={this.handleSearchFocus}
                    onBlur={this.handleSearchBlur}>
                    <Paper variant="outlined" className={classes.searchbar}>
                        {
                            !!filters.length &&
                            filters.filter(f =>
                                f.type !== Types.selection ||
                                _.isArray(f.options) ||
                                _.isArray(asyncOptions[f.key])
                            )
                            .map((filter, i) => {
                                const field = this.getField(filter.field);
                                const method = this.getMethod(filter.method, filter.field);
                                const option = this.getFieldOption(filter.value, filter.field);
                                const value = (field.type == Types.selection) ? (option ? option.label : '—' ) : filter.value;
                                return (
                                    <>
                                        {/* {
                                            i !== 0 &&
                                            <Typography component="small">{filter.operator}</Typography>
                                        } */}
                                        <Chip
                                            key={`${i}-${filter.field}`}
                                            className={classes.filterChip}
                                            label={`${field.label} ${method.label} "${value}"`}
                                            onDelete={() => this.handleRemoveFilter(i)}
                                        />
                                    </>
                                );
                            })
                        }
                        {
                            !!filter.field &&
                            <Typography component="span">{field.label}</Typography>
                        }
                        {
                            !!filter.method &&
                            <Typography component="span">{Methods[field.type].find(m => m.key == filter.method).label}</Typography>
                        }
                        <TextField
                            ref={ref => this.searchField = ref }
                            id="search-field"
                            fullWidth
                            size="small"
                            value={text}
                            className={classes.searchField}
                            onChange={e => this.handleSearchChange(e.currentTarget.value)}
                            onKeyDown={this.handleSearchKeyDown}
                            placeholder={this.getPlaceholder()}
                            InputProps={{ disableUnderline: true }}
                        />
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={e => { 
                                e.key = 'Enter';
                                this.handleSearchKeyDown(e)
                            }}>
                            <Search />
                        </IconButton>
                        <Popper
                            className={classes.popper}
                            open={showBackdrop && !!options.length}
                            anchorEl={this.searchField}
                            role={undefined}
                            transition>
                            {
                                ({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}>
                                        <Paper className={classes.dropdown}>
                                            <MenuList autoFocusItem={false} id="auto-complete-menu">
                                                {
                                                    filteredOptions.map((option, index) => (
                                                        <MenuItem
                                                            key={`${index}-${_.isString(option) ? option.key : option}`}
                                                            selected={index === selectedOption}
                                                            onClick={() => this.handleOptionSelect(option)}>
                                                            {_.isString(option) ? option : option.label}
                                                        </MenuItem>
                                                    ))
                                                }
                                                {
                                                    !filteredOptions.length &&
                                                    <MenuItem disabled>Options not available</MenuItem>
                                                }
                                            </MenuList>
                                        </Paper>
                                    </Grow>
                                )
                            }
                        </Popper>
                        <Popper
                            className={classes.popper}
                            open={showBackdrop && field && field.type === Types.date && filter.method}
                            anchorEl={this.searchField}
                            role={undefined}
                            transition>
                            {
                                ({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}>
                                        <Paper className={classes.dropdown}>
                                            <DatePicker
                                                margin="normal"
                                                label="Calendar"
                                                variant="static"
                                                format="yyyy/mm/dd"
                                                value={moment(text).format('YYYY/MM/DD')}
                                                onChange={date => this.handleOptionSelect(moment(date).format('YYYY/MM/DD'))}
                                                onClick={() => {
                                                    this.searchField && this.searchField.focus()
                                                }}
                                            />
                                        </Paper>
                                    </Grow>
                                )
                            }
                        </Popper>
                    </Paper>
                </FormLabel>
                <Backdrop className={classes.backdrop} open={showBackdrop}/>
            </>
		);
	}
}

export default withRouter(withStyles(styles)(Filter));