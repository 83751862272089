import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Api, Utility } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Grid, TextField, Button, InputLabel, Card } from "@material-ui/core";
import _ from "lodash";
import styles from "./style";
import { BackButton, PageTitle, Page } from "@/components";

class AddBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            errors: {},
            name: this.props.textFieldValue || "",
            code: (this.props.textFieldValue && Utility.getInitials(this.props.textFieldValue)) || "",
            bankId: "",
            bank: {}
        }
    }

    async componentDidMount() {
        if (this.props.match.params && this.props.match.params.id > 0) {
            this.props.setLoader(true);
            this.setState({ isLoading: true, isEdit: true });
            let bank = await Api.banks(
                this.props.match.params.id
            ).get();
            this.setState({
                id: bank.id,
                name: bank.name,
                code: bank.code,
                bankId: bank.id,
            }, () => {
                this.props.setLoader(false);
            });
        }
    }

    isValidData = () => {
        var errors = {}
        if (this.state.code === "") {
            errors["code"] = `${_.startCase("code")} field is required`;
        }
        if (this.state.name === "") {
            errors["name"] = `${_.startCase("name")} field is required`;
        }
        this.setState({ errors });
        return !(_.size(errors) > 0);
    };

    createBank = async () => {
        if (this.isValidData()) {
            var data = new FormData();
            data.append("name", this.state.name);
            data.append("code", this.state.code);
            try {
                if (!this.state.id) {
                    await Api.banks.post(data);
                } else {
                    data.append("_method", "put");
                    await Api.banks(this.state.id).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    );
                }
                this.props.setSnackbar(true, this.state.id ? "Bank Updated" : "Bank Added");
                this.props.history.push("'/settings/banks'");
            }
            catch (e) {
                this.setState({ errors: { ...e.response } }, () => {
                    this.props.setLoader(false);
                })
            }
            this.props.setLoader(false);
        }
    }

    handleChange = (e, fieldName) => {
        this.setState({ [fieldName]: e.target.value })

        if (this.state.errors[fieldName] === true) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [fieldName]: false
                }
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Page title="Banks">
                <Link to={{ pathname: `/settings/banks` }} style={{ textDecoration: 'none', color: "black" }}>
                    <BackButton route="/settings/banks" label="Back to Banks" />
                </Link>
                {
                    !this.state.bankId &&
                    <PageTitle>Add Bank</PageTitle>
                }
                <Card raised>
                    <Grid container className={classes.containerGrid}>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Name
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                error={this.state.errors.name}
                                placeholder="Bank Name"
                                className={classNames(classes.textField)}
                                value={this.state.name}
                                onChange={e => this.handleChange(e, "name")}
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Code
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                error={this.state.errors.code}
                                placeholder="Code"
                                id="code"
                                className={classNames(classes.textField)}
                                value={this.state.code}
                                onChange={e => this.handleChange(e, "code")}
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classNames(classes.containerGrid, classes.footer)}>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <Button variant="outlined" size="large" color="primary" className={classes.createBtn} onClick={this.createBank}>
                                {this.state.bankId !== "" ? "Update" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Page>
        );
    }
}

AddBank.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(AddBank)));