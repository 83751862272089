import _ from 'lodash'
var DesignationsVariables = {
    list: [],
    meta: {},
    archiveMeta: {},
    archiveList: []
}

export default function (state = DesignationsVariables, action) {
    var list = []
    switch (action.type) {
        case 'DESIGNATIONS_LIST':
            if (!action.isReset) {
                list = state.list
            }
            list.push(action.list);

            return state = {
                ...state,
                list: _.flattenDeep(list)
            }

        case 'DESIGNATIONS_META':
            return state = {
                ...state,
                meta: action.meta
            }
        
        case 'DESIGNATIONS_ARCHIVE_LIST':
            if (!action.isReset) {
                list = state.archiveList
            }
            list.push(action.archiveList);

            return state = {
                ...state,
                archiveList: _.flattenDeep(list)
            }

        case 'DESIGNATIONS_ARCHIVE_META':
            return state = {
                ...state,
                archiveMeta: action.archiveMeta
            }

        default:
            return state
    }
}