const styles = theme => ({

    DateInput_input_1: {
        backgroundColor: "red"
    },
    dateText: {
        position: "absolute",
        display: "flex", justifyContent: "center",
        right: "2px",
        top: "-7px",
        alignItems: "center",
        fontFamily: theme.typography.fontFamily,
        fontSize: "15.6px",
        fontWeight: "500",
        height: "60px",
    }

});

export default styles;