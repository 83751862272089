import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Icon,
    CardActions
} from "@material-ui/core";
import { InfographicMessage, DLTable } from "@/components";
import ShiftsTableRow from "./ShiftsTableRow";
import { Api } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import ShiftsModal from "../Modals/ShiftsModal";

import _ from "lodash";

class EmployeeShifts extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isEditing: false,
            shifts: {},
            date:""
        };

        this.getShifts();
    }

    _table = null;

    getShifts = async () => {
        let shifts = await Api.shifts.get({ per_page: 0, filter: { employee_id: this.props.employee.id } });
        this.setState({ shifts: shifts.data });

    };
    
    emptyView() {
        return (
            <InfographicMessage
                illustration="shifts"
                message="You don't have any shifts yet"
                actionLabel={"Add New "}
            actionIcon={"add"}
            actionHandler={() => this.setState({ isEditing: "new" })}
            />
        );
    }
    

    render() {
        const { employee } = this.props;
        const { shifts,isEditing} = this.state;

        return (
            <>
                {
                    // _.size(shifts) > 0 &&
                    <CardActions>
                      <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                onClick={() => this.setState({ isEditing: "new" })} >

                            <Icon>add</Icon>
                            Add Shift
                            </Button>
                        
                    </CardActions>
                }
                <DLTable
                    ref={table => this._table = table}
                    api={Api.employees(employee.id).shifts}
                    emptyView={this.emptyView()}
                    RowTemplate={ShiftsTableRow}
                    rowProps={{ employee, getShifts: this.getShifts }}
                />
                   {isEditing && (
                    <ShiftsModal
                        mode={isEditing}
                        employee={employee.id}
                        title="Add Shift"
                        onUpdate={() => {
                            this._table && this._table.fetchData()
                        }}
                        onClose={() => this.setState({ isEditing: false })}
                    />
                )}
            </>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeShifts);
