import React from "react";
import { PlanInfo, BackButton, PageTitle, Page } from "@/components";
import { Card  } from "@material-ui/core";

class PlanAdd extends React.Component {

	render() {
		return (
			<Page title="Plans">
				<BackButton route="/settings/plans" label="Back to Plans" />
				<PageTitle>Add Plan</PageTitle>
				<Card raised>
					<PlanInfo plan={{}} pageType="add" />
				</Card>
			</Page>
		);
	}
	
}

export default PlanAdd;