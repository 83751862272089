const styles = theme => ({
	
	infoIcon: {
		fontSize: "12px",
		marginLeft: "2px",
		padding: 0,
		color: theme.palette.label.main
	}

});

export default styles;