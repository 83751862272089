import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { BackButton, PageTitle, Page } from "@/components";
import PayrunForm from "./form";
import styles from "./style";
import { withRouter, Link } from "react-router-dom";

class PayrunAdd extends Component {

	render() {
		const { classes } = this.props;
		return (
			<Page title="Payroll">
				<div className={classes.root}>
					<Link to={{ pathname: `/payroll` }} style={{ textDecoration: 'none', color: "black" }}>
						<BackButton route="/payroll" label="Back to Payroll" />
					</Link>
					<PageTitle>Run Payroll</PageTitle>
					<PayrunForm />
				</div>
			</Page>
		);
	}
}

export default withRouter(withStyles(styles)(PayrunAdd));
