import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Card,
	CardActions,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import { DLTable, BackButton, InfographicMessage, Page } from "@/components";
import LeaveBalanceRow from "./LeaveBalanceRow";
import { Api } from "@/helpers";
import styles from "./style";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

class LeaveBalance extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.state = {
			date: moment(),
			anchorEl: null
		};
	}

	export = () => {
		const { date } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.leave_balance.export.getUrl({ year: date.year() }))
	};

	reload = () => {
		this._table && this._table.fetchData(true);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="leave_balances"
				message="No records available for the selected year!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { date } = this.state;
		return (
			<Page title="Leaves Taken & Balance">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<div className={classes.taxReportFilter}>
							<DatePicker
                                margin="dense"
                                label="Year"
                                inputVariant="outlined"
                                format="yyyy"
                                value={date}
								views={["year"]}
                                maxDate={moment()}
                                onChange={date => this.setState({ date: moment(date)}, () => this.reload())}
                            />
						</div>
						<IconButton
							onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
							<Icon>more_vert</Icon>
						</IconButton>
						<Menu
							id="long-menu"
							anchorEl={this.state.anchorEl}
							open={!!this.state.anchorEl}
							onClose={() => this.setState({ anchorEl: null })}
							PaperProps={{
								style: {
									width: 200,
								},
							}}>

							<MenuItem onClick={this.export}>Export in Excel</MenuItem>
						</Menu>
					</CardActions>
					{
						date &&
						<DLTable
							ref={ref => this._table = ref}
							api={Api.reports.leave_balance}
							params={{ year: date.year() }}
							RowTemplate={LeaveBalanceRow}
							emptyView={this.emptyView()} />
					}
				</Card>
			</Page>
		);
	}

}

export default withRouter(withStyles(styles)(LeaveBalance));