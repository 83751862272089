import React from 'react';
import { withRouter } from "react-router-dom";
import { FormControl, Select, MenuItem, TextField, OutlinedInput } from '@material-ui/core';
import { DLTableRow, DLTableColumn } from '@/components';
import { currencyFormatter } from '@/helpers/generalHelper';
import { Api } from '@/helpers';
import { PaymentMethod } from '@/config';
import _ from 'lodash';

class EmployeePaymentTableRow extends DLTableRow {

    constructor(props) {
        super(props);
        this.state = {
            account: '',
            check: '',
            amount: '',
            accounts: [],
            employeeAccount :[]
        };
    }

    static getDerivedStateFromProps(props, state) {
        switch(props.paymentMethod) {
            case PaymentMethod.CASH:
                state.account = '';
                state.check = '';
                break;
            case PaymentMethod.CHECK:
                if(props.method !== "deduction"){
                    state.account = '';
                }
                break;
            case PaymentMethod.IBFT:
                state.check = null
                break;
            case PaymentMethod.BANK_TRANSFER:
                break;
            default:
                state.check = '';
                break;
        }
        return state;
    }

    amountChangeHandler() {
        return event => {
            var { data, onPaymentChange } = this.props;
            _.isFunction(onPaymentChange) && onPaymentChange(data.id, {
                amount: event.target.value.replace(/[^0-9.-]/,"" ),
                check:this.state.check,
                account:this.state.account

            });
            this.setState({ amount: event.target.value });
        };
    }
    checkChangeHandler() {
        return event => {
            var { data, onPaymentChange } = this.props;
            _.isFunction(onPaymentChange) && onPaymentChange(data.id, {
                check: event.target.value,
                amount:this.state.amount,
                account:this.state.account
            });
            this.setState({ check: event.target.value });
        };
    }
    employeeBankChangeHandler() {
        return event => {
            var { data, onPaymentChange } = this.props;
            _.isFunction(onPaymentChange) && onPaymentChange(data.id, {
                check:this.state.check,
                amount:this.state.amount,
                account: event.target.value
            });
            this.setState({ account: event.target.value });
        };
    }
    bankChangeHandler() {

        return event => {
            var { data, onPaymentChange } = this.props;
            _.isFunction(onPaymentChange) && onPaymentChange(data.id, {
                account: event.target.value,
                check:this.state.check,
                amount:this.state.amount,
            });
            this.setState({ account: event.target.value });
        };
    }

    isSelectable() {
        const { paymentMethod } = this.props;
        return( (paymentMethod !== PaymentMethod.BANK_TRANSFER || (paymentMethod === PaymentMethod.BANK_TRANSFER && this.accounts().length) || this.props.method === "deduction"));
    }

    loadAccounts() {
        const { data, paymentMethod } = this.props;
        const accounts = this.accounts();
        if (([PaymentMethod.IBFT, PaymentMethod.BANK_TRANSFER].indexOf(paymentMethod) !== -1 && !accounts.length && !this.loadingAccounts) || ([PaymentMethod.CHECK].indexOf(paymentMethod) !== -1 && !accounts.length && !this.loadingAccounts && this.props.method === "deduction")) {
            this.loadingAccounts = true;
            Api.employees(data.employee.id).bank_accounts.get().then(accounts => {
                this.setState({ accounts: accounts.data });
            });
        }
    }

    accounts() {
        const { data, paymentMethod, sourceBank, onPaymentChange, isSelected, onSelection } = this.props;
        const { accounts } = this.state;

        if (paymentMethod === PaymentMethod.BANK_TRANSFER ) {

            const filteredAccounts = _.filter(accounts, account => account.bank.id === sourceBank);
            const selectedAccount = this.getSelectedAccount();
            if (selectedAccount && selectedAccount.bank.id !== sourceBank) {
                this.setState({ account: '' });
                _.isFunction(onPaymentChange) && onPaymentChange(data.id, {
                    account: ''
                });
            }

            if (!filteredAccounts.length && isSelected) {
                onSelection();
            }
            return filteredAccounts;
        }
        return accounts;
    }

    getSelectedAccount() {
        const { accounts, account } = this.state;
        return _.find(accounts, { id: account });
    }

    renderCells() {
        this.loadAccounts();
        const { data, paymentMethod } = this.props;
        const { check, account,amount } = this.state;
        return [
            <DLTableColumn
                key={`Payment-Payslip-${data.id}-Column-1`}>
                {data.employee.first_name} {data.employee.last_name}
                <div><small>{data.employee.code}</small></div>
            </DLTableColumn>,
            <DLTableColumn
                key={`Payment-Payslip-${data.id}-Column-2`}
                align="right">
                {currencyFormatter(data.loan_amount)}
            </DLTableColumn>,
            this.props.method === "deduction" &&  
            <DLTableColumn
                key={`Payment-Payslip-${data.id}-Column-2`}
                align="right">
                {currencyFormatter(data.loan_stats.current_remaining)}
            </DLTableColumn>,
            this.props.method === "deduction" &&
            <DLTableColumn  key={`Payment-Payslip-${data.id}-Column-3`}
                align="right">
                {<TextField
                        value={amount}
                        type = "number"
                        variant={'outlined'}
                        onChange={this.amountChangeHandler()}
                        size = "small"
                        style={{padding: '8px 0', minWidth: '250px', maxWidth: '300px', width: '100%'}}
                    />
                }
            </DLTableColumn>,
            (paymentMethod === PaymentMethod.CHECK && this.props.method === "deduction" &&
                <DLTableColumn key={`Payment-Payslip-${data.id}-Column-3`}>
                    {<FormControl
                        variant="outlined"
                        size = "small"
                        key={3}
                        style={{padding: '8px 0', minWidth: '250px', maxWidth: '300px', width: '100%'}}>
                            <Select
                            // error={_.find(this.props.payments, (pay) => { return pay.payslip === data.id && pay.isError }) !== undefined}
                            value={account}
                            onChange={this.bankChangeHandler()}
                            size = "small"
                            key={2}
                            input={
                                <OutlinedInput
                                    name="bankAccount"
                                    id="employeebankAccount"
                                    labelWidth={0}
                                    key={2}
                                    size = "small"
                                    placeholder="Bank Account"/>
                            }>
                            {
                                _.map(this.accounts(), (account) => {
                                    return (
                                        <MenuItem
                                            key={`employee-${data.employee.id}-employee-account-${account.id}`}
                                            value={account.id}>
                                            
                                            {account.bank.name + " ("+ account.account_number +")"}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>}
                </DLTableColumn>),
            (paymentMethod !== PaymentMethod.CASH &&
                <DLTableColumn
                    key={`Payment-Payslip-${data.id}-Column-4`}
                    align= "left">
                    {([PaymentMethod.IBFT, 'Bank Transfer'].indexOf(paymentMethod) !== -1) && 
                        <FormControl
                            variant="outlined"
                            size = "small"
                            key={2}
                            style={{padding: '8px 0', minWidth: '250px', maxWidth: '300px', width: '100%'}}>
                            <Select
                                // error={_.find(this.props.payments, (pay) => { return pay.payslip === data.id && pay.isError }) !== undefined}
                                value={account}
                                onChange={this.employeeBankChangeHandler()}
                                size = "small"
                                key={2}
                                input={
                                    <OutlinedInput
                                        name="bankAccount"
                                        id="bankAccount"
                                        labelWidth={0}
                                        key={2}
                                        size = "small"
                                        placeholder="Bank Account"/>
                                }>
                                {
                                    _.map(this.accounts(), (account) => {
                                        return (
                                            <MenuItem
                                                key={`employee-${data.employee.id}-account-${account.id}`}
                                                value={account.id}>
                                                
                                                {account.bank.name + " ("+ account.account_number +")"}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        paymentMethod === PaymentMethod.CHECK &&
                        <TextField
                            value={check}
                            variant={'outlined'}
                            onChange={this.checkChangeHandler()}
                            placeholder={'Check #'}
                            size = "small"
                            style={{padding: '8px 0',minWidth: '250px', maxWidth: '300px', width: '100%'}}/>
                    }
                </DLTableColumn>
            )
        ];
    }

}

EmployeePaymentTableRow.Columns = props => {
    var columns = [
        {
            key: 'employee.first_name',
            label: 'Employee',
            align: 'left',
            isSortable: false,
            withSeparator: false
        },
        {
            key: 'net_payable',
            label: 'Loan Amount',
            align: 'right',
            isSortable: true,
            withSeparator: false
        },
        props.method ==="deduction"  && {
            key: 'remaining_amount',
            label: 'Remaining Amount',
            align: 'right',
            isSortable: true,
            withSeparator: false
        },
        props.method ==="deduction"  && {
            key: 'deducted',
            label: 'Deduct Amount',
            align: 'left',
            isSortable: true,
            withSeparator: false
        },
        props.method ==="deduction" && props.paymentMethod === PaymentMethod.CHECK &&  {
            key: 'acoount',
            label: 'Bank Account',
            align: 'left',
            isSortable: true,
            withSeparator: false

        }
    ];
    if (props.paymentMethod !== PaymentMethod.CASH) {
        columns.push({
            key: 'id',
            label: PaymentMethod.CHECK === props.paymentMethod ? 'Check Number' : 'Bank Account',
            align: 'left',
            isSortable: false,
            withSeparator: false
        });
    }
    return columns;
};

export default withRouter(EmployeePaymentTableRow);