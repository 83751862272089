import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, AppBar, CardMedia, Fade, Menu, MenuItem, Toolbar, Typography, Fab } from "@material-ui/core";
import { AccountCircle, PowerSettingsNew, Menu as MenuIcon } from "@material-ui/icons";
import { AuthContext } from "@/contexts";
import { Utility, Api, AccessManager } from "@/helpers";
import styles from "./style";
import { setLoader } from "@/redux/actions/general";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";

class Header extends Component {

	static contextType = AuthContext;

	accountMenuCTA = {};

	constructor(props) {
		super(props);
		this.state = {
			isMenuOpen: false
		};
	}

	openMenu = () => {
		this.setState({ isMenuOpen: true });
	};

	closeMenu = () => {
		this.setState({ isMenuOpen: false });
	};

	editProfile = () => {
		const admin = this.context.user;
		this.props.history.push(`/employees/${admin.id}/info`, { admin });
		this.closeMenu();
	};

	logout = () => {
		this.props.setLoader(true);
		this.closeMenu();
		this.context.logout(() => {
			this.props.setLoader(false);	
		});
	this.props.history.push("/");
};

	render() {
		const { classes, drawerAction, title } = this.props;
		const { isMenuOpen } = this.state;
		const admin = this.context.user;
		return (
			<AppBar position="sticky" className={classes.root}>
				<Toolbar disableGutters className={classes.toolbar}>
					<div className={classes.logoContainer}>
						{
							!!drawerAction &&
							<MenuIcon
								aria-haspopup="true"
								className={classes.menuIcon}
								onClick={drawerAction} />
						}
						<CardMedia
							className={classes.logo}
							component="img"
							image={Api.organizations(this.context.user.organization_id).logo.getUrl(true)}
							title="Al-Burhan Attendance" />
					</div>

					{/* TODO: Heading isn't centered yet (text align center is not sufficient) */}
					<Typography
						className={classes.title}
						variant="h6"
						color="primary">
						{title}
					</Typography>

					<Fab
						ref={accountMenuCTA => this.accountMenuCTA = accountMenuCTA}
						className={classes.fab}
						variant="extended"
						size="small"
						onClick={this.openMenu}>

						<Avatar
							className={classes.avatar}
							src={Api.employees(this.context.user.id).avatar.getUrl()}>
							{Utility.getInitials(`${this.context.user.first_name} ${this.context.user.last_name}`)}
						</Avatar>
						<span className={classes.extendedText}>{this.context.user.first_name} {this.context.user.last_name}</span>
					</Fab>

					<Menu
						TransitionComponent={Fade}
						anchorEl={this.accountMenuCTA}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						transformOrigin={{ vertical: "bottom", horizontal: "right" }}
						open={isMenuOpen}
						onClose={this.closeMenu}>
						{
							!AccessManager.isPurchaseExpired(this.context.organization) &&
							<UserRole routePermissions={[Permissions.COMPANY_DASHBOARD]}>
								<Link to={{ pathname: `/employees/${admin.id}/info`, state: { admin } }} style={{ textDecoration: 'none', color: "black" }}>
									<MenuItem onClick={this.editProfile}>
										<AccountCircle className={classes.extendedIcon} /> My Account
									</MenuItem>
								</Link>
							</UserRole>
						}
						<MenuItem onClick={this.logout}>
							<PowerSettingsNew className={classes.extendedIcon} /> Logout
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		);
	}

}

const mapStateToProps = state => ({
	title: state.general.title
});

const mapDispatchToProps = (dispatch) => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));