import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import moment from "moment";
import Config from "@/config";
import styles from "./style";
import _ from "lodash";
class EditInvoicesModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			invoice: {},
			title: props.title || "Edit"
		};
	}

	invoice() {
		return {
			employees_count: 0,
			plan_price: 0,
			start_date: null,
			end_Date: null,
			...this.props.invoice,
			...this.state.invoice
		};
	}


	prepareData = () => {
		let invoice = this.invoice();
		let data = {
			employees_count : invoice.employees_count,
	   		plan_price : invoice.plan_price,
			start_date: moment(invoice.start_date).format("YYYY-MM-DD"),
			end_Date: moment(invoice.end_Date).format("YYYY-MM-DD"),
			created_at: moment(invoice.created_at).format("YYYY-MM-DD"),
			due_date: moment(invoice.due_date).format("YYYY-MM-DD"),
		}
		
		return data;
	}

	validate() {
		const invoice = this.invoice();
		const requiredFields = ["employees_count", "plan_price", "start_date", "end_date", "created_at", "due_date"];
		var errors = {};
		
		_.forOwn(invoice, (value, key) => {
			key = (key == "created_at") ? "issue_date" : key;
			if (requiredFields.indexOf(key) !== -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	handleChange(value, field) {
		const invoice = this.invoice();
		const { errors } = this.state;

		this.setState({
			invoice: {
				...invoice,
				[field]: value
			},
			errors: {
				...errors,
				[field]: false
			}
		});
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const invoice = this.invoice();
		var data = this.prepareData();

		this.props.setLoader(true);
		try {
			const _invoice = await Api.invoices(invoice.invoice_no).put(data);
			this.props.setSnackbar(true, "Invoice saved successfully");
			this.props.onUpdate(_invoice);
			this.props.onClose();

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};


	render() {
		const invoice = this.invoice();
		const { errors } = this.state;
		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={"new"}
				aria-labelledby="version-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="version-dialog-title">{this.state.title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								size="small"
								fullWidth
								disabled={true}
								error={errors.name}
								helperText={errors.name}
								label="Invoice Number"
								placeholder="Invoice Number"
								value={invoice.invoice_no}
								onChange={(e) => this.handleChange(e.target.value, "employees_count")}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.start_date}
								helperText={errors.start_date}
								FormHelperTextProps={{ variant: "standard" }}
								label="Start Date"
								inputVariant="outlined"
								format= {Config.DISPLAY_DATE_FORMAT}
								value={invoice.start_date}
								onChange={e => this.handleChange(e, "start_date")}
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.end_date}
								helperText={errors.end_date}
								FormHelperTextProps={{ variant: "standard" }}
								label="End Date"
								minDate={invoice.start_date ? invoice.start_date : null}
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={invoice.end_date}
								onChange={e => this.handleChange(moment(e).format("YYYY-MM-DD"), "end_date")}
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.created_at}
								helperText={errors.created_at}
								FormHelperTextProps={{ variant: "standard" }}
								label="Issue Date"
								minDate={invoice.start_date ? invoice.start_date : null}
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={invoice.created_at}
								onChange={e => this.handleChange(moment(e).format("YYYY-MM-DD"), "created_at")}
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.due_date}
								helperText={errors.due_date}
								FormHelperTextProps={{ variant: "standard" }}
								label="Due Date"
								minDate={invoice.start_date ? invoice.start_date : null}
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={invoice.due_date}
								onChange={e => this.handleChange(moment(e).format("YYYY-MM-DD"), "due_date")}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								size="small"
								fullWidth
								type="number"
								error={errors.employees_count}
								helperText={errors.employees_count}
								label="Number of Employees"
								placeholder="Number of Employees"
								value={invoice.employees_count}
								onChange={(e) => this.handleChange(e.target.value, "employees_count")}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								size="small"
								type="number"
								fullWidth
								error={errors.plan_price}
								helperText={errors.plan_price}
								placeholder="Plan Price"
								label="Plan Price"
								value={invoice.plan_price}
								onChange={(e) => this.handleChange(e.target.value, "plan_price")}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
				<Button onClick={this.props.onClose} color="default" size="small">Cancel</Button>
					<Button variant="contained" onClick={this.save} color="primary" size="small">Save</Button>
				</DialogActions>
			</Dialog>
		)

	}
}
EditInvoicesModal.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	departments: state.departments.list,
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditInvoicesModal)));