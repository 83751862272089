import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
	TextField,
	Button,
	Grid,
	MenuItem,
	IconButton,
	Tooltip,
	ExpansionPanel,
	ExpansionPanelDetails,
	Menu
} from "@material-ui/core";
import { DateRange } from "@/components";
import { DeleteOutlined, Add as AddIcon } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from "@material-ui/pickers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Config from "@/config";
import _ from "lodash";
import moment from "moment";
import styles from "./style";
import Theme from "@/themeStyles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from "@/redux";
import { setFilterCount } from "@/redux/actions/general";

const methods = {
	string: [
		"is",
		"contains",
		"starts with",
		"ends with",
	],
	number: [
		"is",
		"greater than",
		"less than",
		"greater than or equals to",
		"less than or equals to",
		"between"
	],
	date: [
		"is",
		"before",
		"after",
		"same or before",
		"same or after",
		"between"
	],
	selection: [
		"is"
	],
	callSelection: [
		"is"
	]
}
class FilterAccordion extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			addedFilters: [
				this.props.defaultFilter || this.props.data[0],
			],
			filters: {
				status: "active"
			},
			filterData: this.props.data,
			expanded: true,
			anchorEl: null,
			onFilterSelect: this.onFilterSelect,
			callLoader: false
		};
	}

	componentWillUnmount() {
		Store.dispatch(setFilterCount(1))
	}

	updateFilterCount = () => {
		const { addedFilters } = this.state;
		Store.dispatch(setFilterCount(addedFilters.length));
	}

	static getDerivedStateFromProps(props, state) {
		if (props.isOpen && _.size(state.addedFilters) === 0) {
			state.onFilterSelect(state.filterData[0])
		}
		return state;
	}

	handleChange(value, field) {
		const { filters } = this.state;
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			filters: {
				...filters,
				[field]: value
			},
			errors
		});
	}

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	validate = () => {
		const { addedFilters } = this.state;
		var errors = 0;
		addedFilters.forEach((filter, index) => {
			if (!filter.value && _.size(addedFilters) > 1) {
				errors += 1;
				this.onValueChange(index, "");
			}
		});

		if ((errors > 0)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}

		return !!(errors === 0);
	};

	onSearch = (e) => {
		e.preventDefault();
		if (!this.validate()) return;
		this.props.onSearch((_.size(this.state.addedFilters) > 1 || !!this.state.addedFilters[0].value ? this.state.addedFilters : []));
	};

	onReset = () => {
		let addedFilters = [];
		if (this.props.defaultFilter) {
			addedFilters.push(this.props.defaultFilter);
		}
		this.setState({ addedFilters }, () => { this.updateFilterCount() })
		this.props.onSearch(addedFilters, true);
	}

	onFilterSelect = (filter) => {
		const { addedFilters } = this.state;

		addedFilters.push({
			...filter,
			operaor: "AND",
			method: methods[filter.type][0]
		});
		this.setState({ addedFilters }, () => {
			this.updateFilterCount();
		});
	};

	onMethodChange = (index, method) => {
		const { addedFilters } = this.state;

		addedFilters[index] = {
			...addedFilters[index],
			method
		};
		this.setState({ addedFilters });
	};

	onValueChange = (index, value, valueIndex) => {
		const { addedFilters } = this.state;
		var error = false;

		if (!value) {
			error = `Value is required`
		}

		if (valueIndex === 1) {
			value = [((_.isArray(addedFilters[index].value) && addedFilters[index].value[0]) || ""), value];
		} else if (valueIndex === 0) {
			value = [value, (_.isArray(addedFilters[index][value]) && addedFilters[index][value][1]) || ""];
		}
		addedFilters[index] = {
			...addedFilters[index],
			value,
			error
		};
		this.setState({ addedFilters });
	};

	onOperatorChange = (filter, index, operator) => {
		const { addedFilters } = this.state;
		addedFilters[index] = {
			...filter,
			operator,
			anchorEl: null
		};
		this.setState({ addedFilters, anchorEl: null });
	};

	onDelete = (filter, index) => {
		const { addedFilters } = this.state;
		_.remove(addedFilters, (filter, i) => i === index);
		this.setState({ addedFilters }, () => { this.updateFilterCount() });
	};

	onFieldChange = async (value, index) => {
		const { addedFilters } = this.state;
		const { data } = this.props;
		let filter = _.find(data, (f) => f.key === value)
		if (filter.type === "callSelection") {
			try {
				this.setState({ callLoader: true })
				const data = await filter.options();
				var array = [];
				data.forEach(item => {
					array.push({
						key: item.id,
						label: item.name
					})
				})
				filter.options = array;
			}
			catch (e) {
				this.setState({ callLoader: false })
			}
		}

		addedFilters[index] = {
			...addedFilters[index],
			...filter,
			value: ""
		};
		this.setState({ addedFilters, callLoader: false });
	}

	onClickeOperator = (index, anchorEl) => {
		const { addedFilters } = this.state;
		addedFilters[index] = {
			...addedFilters[index],
			anchorEl
		};
		this.setState({ addedFilters });
	};

	getOperator = (filter, index) => {
		const { classes } = this.props;

		return (
			<Grid item xs={12}>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<Button
							aria-controls="long-menu"
							aria-haspopup="true"
							aria-label="More"
							color="primary"
							className={classes.operator}
							onClick={(e) => this.onClickeOperator(index, e.currentTarget)}
						>
							{filter.operator || "AND"}
							<ExpandMoreIcon />
						</Button>
						<Menu
							id="long-menu"
							anchorEl={filter.anchorEl}
							open={Boolean(filter.anchorEl)}
							onClose={() => this.onClickeOperator(index, null)}>

							<MenuItem onClick={() => this.onOperatorChange(filter, index, "AND")}>AND</MenuItem>
							<MenuItem onClick={() => this.onOperatorChange(filter, index, "OR")}>OR</MenuItem>
						</Menu>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	getRow = (filter, index) => {
		const { classes, data } = this.props;
		const filterData = data;
		return (
			<Grid
				key={index}
				container
				spacing={2}
				alignItems="center"
				className={classes.row}>
				{
					index !== 0 &&
					this.getOperator(filter, index)
				}
				<Grid item xs={12} sm={3}>
					<TextField
						fullWidth
						select
						size="small"
						label="Field"
						variant="outlined"
						defaultValue={filter.key}
						value={filter.key}
						onChange={e => this.onFieldChange(e.target.value, index)}
					>
						{
							filterData.map(data => (
								<MenuItem key={data.key} value={data.key} >
									{data.label}
								</MenuItem>
							))
						}
					</TextField>
				</Grid>
				{
					filter.type !== "selection" && filter.type !== "callSelection" &&
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							select
							size="small"
							label="Method"
							variant="outlined"
							value={filter.method}
							onChange={e => this.onMethodChange(index, e.target.value)}
						>
							{
								methods[filter.type].map(operator => (
									<MenuItem key={operator} value={operator}>
										{operator}
									</MenuItem>
								))
							}
						</TextField>
					</Grid>
				}

				{
					filter.type === "selection" &&
					<Grid item xs={12} className={classes.filterValue}>
						<TextField
							fullWidth
							select
							size="small"
							label="Value"
							variant="outlined"
							error={!!filter.error}
							helperText={filter.error}
							FormHelperTextProps={{ variant: "standard" }}
							value={filter.value}
							onChange={e => this.onValueChange(index, e.target.value)}
						>
							{
								filter.options.map(status => (
									<MenuItem key={status.key} value={status.key}>
										{status.label}
									</MenuItem>
								))
							}
						</TextField>
					</Grid>
				}
				{
					filter.type === "callSelection" &&
					<Grid item xs={12} className={classes.filterValue}>
						<TextField
							fullWidth
							select
							size="small"
							label="Value"
							variant="outlined"
							error={!!filter.error}
							helperText={filter.error}
							FormHelperTextProps={{ variant: "standard" }}
							value={filter.value}
							onChange={e => this.onValueChange(index, e.target.value)}
						>
							{
								filter.options.map(status => (
									<MenuItem key={status.key} value={status.key}>
										{status.label}
									</MenuItem>
								))
							}
						</TextField>
					</Grid>
				}
				{
					(["string", "number"].indexOf(filter.type) !== -1) &&
					<Grid item xs={12} className={classes.filterValue}>
						<TextField
							fullWidth
							size="small"
							type={filter.type === "string" ? "text" : filter.type}
							label="Value"
							variant="outlined"
							value={filter.value || ""}
							onChange={e => this.onValueChange(index, e.target.value)}
							error={!!filter.error}
							helperText={filter.error}
							FormHelperTextProps={{ variant: "standard" }}
						/>
					</Grid>
				}
				{
					filter.type === "date" &&
					filter.method === "between" &&
					<Grid item xs={12} className={classes.filterValue}>
						<DateRange onChange={(from, to) => {
							console.log("FilterAccordion -> getRow -> (from, to)", from, to)
							this.onValueChange(index, moment(from).format('YYYY-MM-DD'), 0)
							this.onValueChange(index, moment(to).format('YYYY-MM-DD'), 1)
						}} />
					</Grid>
				}
				{
					filter.type === "date" &&
					filter.method !== "between" &&
					<Grid item xs={12} className={classes.filterValue}>
						<DatePicker
							autoOk
							fullWidth
							size="small"
							error={!!filter.error}
							label="Value"
							inputVariant="outlined"
							format={Config.DISPLAY_DATE_FORMAT}
							value={_.isArray(filter.value) ? filter.value[0] : filter.value || null}
							onChange={e => this.onValueChange(index, moment(e).format('YYYY-MM-DD'))}
							helperText={filter.error}
							FormHelperTextProps={{ variant: "standard" }}
						/>
					</Grid>
				}
				<Grid item zeroMinWidth>
					{this.state.callLoader ? (
						<CircularProgress size={25} />
					) : (
						<Tooltip title="Delete Filter">
							<IconButton
								onClick={() => this.onDelete(filter, index)}
								size="small"
								color="secondary">
								<DeleteOutlined />
							</IconButton>
						</Tooltip>)
					}
				</Grid>
			</Grid >
		);
	};

	render() {
		const {
			isOpen,
			classes,
			data,
			removeBorder = false
		} = this.props;

		const {
			addedFilters,
			expanded
		} = this.state;
		if (!isOpen) {
			return null;
		}
		return (
			<ExpansionPanel expanded={expanded} className={classes.filterPanel} style={{ borderTop: removeBorder ? null : "1px solid", borderTopColor: removeBorder ? Theme.color.white.main : Theme.palette.borderColor.main, }}>
				<ExpansionPanelDetails classes={{ root: classes.filterPanelDetails }}>
					{
						addedFilters.map((filter, index) => {
							return this.getRow(filter, index);
						})
					}
					<Grid container spacing={2} justify="space-between">
						<Grid item>
							<Button
								size="small"
								variant="contained"
								className={classes.addFilterButton}
								onClick={() => this.onFilterSelect(data[0])}>
								<AddIcon />
								Add Filter
							</Button>
						</Grid>
						<Grid item>
							<Grid container spacing={1}>
								<Grid item>
									<Button
										size="small"
										variant="contained"
										onClick={this.onReset}>
										Reset
									</Button>
								</Grid>
								<Grid item>
									<Button
										size="small"
										variant="contained"
										color="primary"
										onClick={this.onSearch}>
										Apply
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

FilterAccordion.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	onSearch: PropTypes.func,
	defaultFilter: PropTypes.object,
	data: PropTypes.array
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(FilterAccordion));