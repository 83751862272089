import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
    Chip,
    FormControl,
    FormGroup,
    MenuItem,
    TextField
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import styles from "./style";
import moment from "moment";

class DateRange extends Component {

    constructor(props) {
        super(props);
        var range = this.props.isMonth ? this.getRange(this.props.from, this.props.to) : true;

        this.state = {
            from: this.props.isMonth ? this.props.from : moment().year(this.taxYear() - 1).month(6).startOf("month"),
            to: this.props.isMonth ? this.props.to : moment().year(this.taxYear()).month(5).endOf("month"),
            range: this.props.isMonth ? range : "current-tax-year"
        };
    }

    componentDidMount() {
        this.props.onChange(this.state.from, this.state.to);
    }

    getRange(from, to) {
        if(moment(from).isSame(moment().startOf("month")) && moment(to).isSame(moment().endOf("month"))) {
            return "current-month";
        } else if (moment(from).isSame(moment().subtract(1, "month").startOf("month")) && moment(to).isSame(moment().subtract(1, "month").endOf("month"))) {
            return "previous-month";
        } else if (moment(from).isSame(moment().add(1, "month").startOf("month")) && moment(to).isSame(moment().add(1, "month").endOf("month"))) {
            return "next-month";
        } else {
            return "custom";
        }
    }

    taxYear() {
        return moment().month() >= 6 ? moment().year() + 1 : moment().year();
    }

    handleChange(key, value) {
        const filter = this.state;
        if (key === "range") {
            filter.range = value;

            switch(value) {
                case "current-tax-year":
                    filter.from = moment().year(this.taxYear() - 1).month(6).startOf("month");
                    filter.to = moment().year(this.taxYear()).month(5).endOf("month");
                    break;
    
                case "previous-tax-year":
                    filter.from = moment().year(this.taxYear() - 2).month(6).startOf("month");
                    filter.to = moment().year(this.taxYear() - 1).month(5).endOf("month");
                    break;
    
                case "current-year":
                    filter.from = moment().startOf("year");
                    filter.to = moment().endOf("year");
                    break;
    
                case "previous-year":
                    filter.from = moment().subtract(1, "year").startOf("year");
                    filter.to = moment().subtract(1, "year").endOf("year");
                    break;

                case "current-month":
                    filter.from = moment().startOf("month");
                    filter.to = moment().endOf("month");
                    break;
    
                case "previous-month":
                    filter.from = moment().subtract(1, "month").startOf("month");
                    filter.to = moment().subtract(1, "month").endOf("month");
                    break;
                
                case "next-month":
                    filter.from = moment().add(1, "month").startOf("month");
                    filter.to = moment().add(1, "month").endOf("month");
                    break;  
  
                default: break;
            } 
            
        } else {
            filter[key] = moment(value);
        }
        
        if (value !== "custom") {
            this.props.onChange(filter.from, filter.to);
        }

		this.setState({ filter });
    }

    render() {
        const { classes, stacked, isMonth, custom_format } = this.props;
        const { range, from, to } = this.state;
        return (
            isMonth 
            ?  
                <FormGroup className={classNames({ [classes.row]: !stacked })}>
                    <FormControl>
                        <TextField
                            label="Period"
                            select
                            SelectProps={{ classes: { outlined: classes.inputSelect } }}
                            margin="dense"
                            variant="outlined"
                            value={range}
                            onChange={e => this.handleChange("range", e.target.value)}
                        >
                            <MenuItem className={classes.dropdownItem} value="previous-month">
                                Previous Month
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().subtract(1, "month").format("MMMM, YYYY")}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="current-month">
                                Current Month
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().format("MMMM, YYYY")}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="next-month">
                                Next Month
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().add(1, "month").format("MMMM, YYYY")}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="custom">Custom Date Range</MenuItem>
                        </TextField>
                    </FormControl>
                    {
                        range === "custom" &&
                        <>
                            <DatePicker
                                margin="dense"
                                label="From"
                                inputVariant="outlined"
                                format="dd MMMM, yyyy"
                                value={from}
                                maxDate={to}
                                onChange={date => this.handleChange("from", date)}
                            />
                            <DatePicker
                                margin="dense"
                                label="To"
                                inputVariant="outlined"
                                format="dd MMMM, yyyy"
                                value={to}
                                minDate={from}
                                onChange={date => this.handleChange("to", date)}
                            />
                        </>
                    }
                </FormGroup>
            :
                <FormGroup className={classNames({ [classes.row]: !stacked })}>
                    <FormControl>
                        <TextField
                            label="Period"
                            select
                            SelectProps={{ classes: { outlined: classes.inputSelect } }}
                            margin="dense"
                            variant="outlined"
                            value={range}
                            onChange={e => this.handleChange("range", e.target.value)}
                        >
                            <MenuItem className={classes.dropdownItem} value="current-tax-year">
                                Current Tax Year
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={`${this.taxYear() - 1} - ${this.taxYear()}`}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="previous-tax-year">
                                Previous Tax Year
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={`${this.taxYear() - 2} - ${this.taxYear() - 1}`}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="current-year">
                                Current Year
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().year()}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="previous-year">
                                Previous Year
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().year() - 1}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="current-month">
                                Current Month
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().format("MMMM, YYYY")}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="previous-month">
                                Previous Month
                                <Chip
                                    className={classes.dropdownChip}
                                    size="small"
                                    label={moment().subtract(1, "month").format("MMMM, YYYY")}
                                />
                            </MenuItem>
                            <MenuItem className={classes.dropdownItem} value="custom">Custom Date Range</MenuItem>
                        </TextField>
                    </FormControl>
                    {
                        range === "custom" && custom_format ?
                        <>
                            <DatePicker
                                margin="dense"
                                views={["month", "year"]}
                                label="From"
                                inputVariant="outlined"
                                format="MMMM, yyyy"
                                value={from}
                                maxDate={to}
                                onChange={date => this.handleChange("from", date)}
                            />
                            <DatePicker
                                margin="dense"
                                views={["month", "year"]}
                                label="To"
                                inputVariant="outlined"
                                format="MMMM, yyyy"
                                value={to}
                                minDate={from}
                                onChange={date => this.handleChange("to", date)}
                            />
                        </> 
                        : 
                        range === "custom" ? <>
                            <DatePicker
                                margin="dense"
                                label="From"
                                inputVariant="outlined"
                                format="dd MMMM, yyyy"
                                value={from}
                                maxDate={to}
                                onChange={date => this.handleChange("from", date)}
                            />
                            <DatePicker
                                margin="dense"
                                label="To"
                                inputVariant="outlined"
                                format="dd MMMM, yyyy"
                                value={to}
                                minDate={from}
                                onChange={date => this.handleChange("to", date)}
                            />
                        </> : ""
                    }
                </FormGroup>
        );
    }

}

DateRange.defaultProps = {
    onChange: () => { },
    stacked: false
};

DateRange.propTypes = {
    onChange: PropTypes.func,
    stacked: PropTypes.bool
};

export default withStyles(styles)(DateRange);