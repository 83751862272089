import React, { Component } from "react";
import {
	Button,
	Grid,
	Checkbox,
	MenuItem,
	TextField,
	InputLabel,
	FormControlLabel,
	CardActions,
	CardContent,
	InputAdornment
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Check, Close } from "@material-ui/icons";
import styles from "./style";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import _ from "lodash";
import { FormattedNumericInput } from "@/components";
import Modules from "@/components/Modules";
import { Constants } from "@/config";
import classNames from "classnames";
import Config from '@/config';

class PlanInfo extends Component {
	static propTypes = {
		plan: PropTypes.object,
		onUpdate: PropTypes.func,
	};

	static defaultProps = {
		plan: {},
		onUpdate: () => {},
	};

	state = {
		errors: {},
		plan: {},
	};
	plan() {
		return {
			title: "",
			price: "",
			discount: "",
			trial_period: "",
			grace_period: "",
			no_employees: 1,
			type: "",
			modules: [],
			...this.props.plan,
			...this.state.plan,
		};
	}
	
	isNew() {
		return !this.props.plan || !this.props.plan.id;
	}
	
	moduleChange = (newModules) => {
		var plan = this.plan();
		plan.modules = newModules;
		this.setState({ plan });
	};
	
	handleChange(value, field) {
		const plan = this.plan();
		const { errors } = this.state;
		
		this.setState({
			plan: {
				...plan,
				[field]: value,
			},
			errors: {
				...errors,
				[field]: false,
			},
		});
	}

	avatar = null;
	imageChangeHandler = (e) => {
		this.avatar = e ? e.target.files[0] : null;
	};

	validate() {
		const plan = this.plan();
		var errors = {};

		const optionalFields = ["modules", "discount", "validity_type", "validity", "trial_period_type", "grace_period_type" ];
		_.forOwn(plan, (value, key) => {
			if (optionalFields.indexOf(key) === -1) {
				if (key == "discount" && (value < 0 || value > 100)) {
					errors[key] = `${_.startCase(key)} should be a valid percentage between 0 and 100`;

				} else if (["trial_period", "grace_period"].indexOf(key) !== -1 && value < 0) {
					errors[key] = `${_.startCase(key)} can not be negative`;

				} else if (_.isNil(value) || _.isEmpty(value + "")) {
					errors[key] = `${_.startCase(key)} field is required`;
				}
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(
				true,
				"Please fix the highlighted errors",
				"error"
			);
		}
		this.setState({ errors });
		if(!plan.modules.length){
			this.props.setSnackbar(true, "Minimum 1 module need to be enable", "error");
			return false;
		}

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();

		if (!this.validate()) return;

		const plan = this.plan();
		const data = new FormData();
		_.forOwn(plan, (value, key) => {
			// Send null values as empty string
			if (_.isNil(value)) {
				value = "";
			}
			
			if (key === "modules") {
				value = JSON.stringify(value);
			}
			data.append(key, value);
		});

		this.props.setLoader(true);
		try {
			if (this.isNew()) {
				await Api.plans.post(data);
				this.props.setSnackbar(true, "New Plan added successfully");
				this.props.history.replace("/settings/plans");
			} else {
				await Api.plans(plan.id).post(data);
				this.props.setSnackbar(true, "Plan information saved successfully");
			}
		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, (response) => {
					this.setState({
						errors: _.mapValues(response.data, (value) => value[0]),
					});
					this.props.setSnackbar(
						true,
						"Please fix the highlighted errors",
						"error"
					);
				})
				.default((_) =>
					this.props.setSnackbar(
						true,
						"Something went wrong, please try again later",
						"error"
					)
				)
				.go();
		}
		this.props.setLoader(false);
	};

	resetForm() {
		this.setState({ plan: {} });
	}

	static getDerivedStateFromProps(props, state) {
		return { plan: { ...props.plan, ...state.plan } };
	}

	render() {
		const { classes } = this.props;
		const { errors } = this.state;
		const plan = this.plan();
		return (
			<>
				<CardContent>
					<Grid container className={classes.containerGrid}>
						<Grid item className={classes.labelGrid} xs={3} sm={3} md={4}>
							<InputLabel>Title</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<TextField
								placeholder="Title"
								className={classNames(classes.textField)}
								variant="outlined"
								error={!!errors.title}
								helperText={errors.title}
								value={plan.title || ""}
								margin="dense"
								onChange={(e) => this.handleChange(e.target.value, "title")}
							/>
						</Grid>
						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Type</InputLabel>
						</Grid>
						<Grid container xs={7} sm={7} md={8}>
							<TextField
								select
								variant="outlined"
								error={!!errors.type}
								helperText={errors.type}
								value={plan.type || ""}
								className={classNames(
									classes.textField,
									classes.marginVertical
								)}
								margin="dense"
								onChange={(e) => this.handleChange(e.target.value, "type")}
							>
								<MenuItem value="One Time">One Time</MenuItem>
								<MenuItem value="Recurring">Reccuring</MenuItem>
							</TextField>
						</Grid>

						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Price</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<TextField
								className={classNames(classes.textField)}
								InputProps={{
									endAdornment: <InputAdornment position="end">{Config.CURRENCY_SYMBOL}</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								variant="outlined"
								error={!!errors.price}
								helperText={errors.price}
								value={plan.price}
								margin="dense"
								onChange={(e) => this.handleChange(e.currentTarget.value, "price")}
							/>
						</Grid>

						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Discount</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<TextField
								className={classNames(classes.textField)}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								variant="outlined"
								error={!!errors.discount}
								helperText={errors.discount}
								value={plan.discount}
								margin="dense"
								onChange={(e) => this.handleChange(e.currentTarget.value, "discount")}
							/>
						</Grid>

						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Unlimited Employees</InputLabel>
						</Grid>
						<Grid container xs={7} sm={7} md={8}>
							<FormControlLabel
								control={
									<Checkbox
										checked={plan.no_employees == "0"}
										onChange={(e) => this.handleChange(plan.no_employees == "0" ? "" : "0", "no_employees")}
										name="unlimited_employees"
										color="primary"
									/>
								}
							/>
						</Grid>

						{
							plan.no_employees != "0" &&
							<>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>Number of Employees</InputLabel>
								</Grid>
								<Grid container xs={7} sm={7} md={8}>
									<TextField
										placeholder="Number of Employees"
										className={classNames(classes.textField)}
										InputProps={{
											endAdornment: <InputAdornment position="end">Employees</InputAdornment>,
											inputComponent: FormattedNumericInput,
										}}		
										variant="outlined"
										error={!!errors.no_employees}
										helperText={errors.no_employees}
										value={plan.no_employees}
										margin="dense"
										onChange={(e) => { 
											e.currentTarget.value.charAt(0) !== "0" &&
											this.handleChange(e.currentTarget.value, "no_employees")}
										}
									/>
								</Grid>
							</>
						}

						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Trial Period</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<TextField
								placeholder="Trial Period"
								className={classNames(classes.textField)}
								InputProps={{
									endAdornment: <InputAdornment position="end">Days</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								variant="outlined"
								error={!!errors.trial_period}
								helperText={errors.trial_period}
								value={plan.trial_period}
								margin="dense"
								onChange={(e) =>
									this.handleChange(e.currentTarget.value, "trial_period")
								}
							/>
						</Grid>
						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Grace Period</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<TextField
								placeholder="Grace Period"
								className={classNames(classes.textField)}
								InputProps={{
									endAdornment: <InputAdornment position="end">Days</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								variant="outlined"
								error={!!errors.grace_period}
								helperText={errors.grace_period}
								value={plan.grace_period}
								margin="dense"
								onChange={(e) =>
									this.handleChange(e.currentTarget.value, "grace_period")
								}
							/>
						</Grid>
					</Grid>

					<Grid container className={classes.containerGrid} alignItems="flex-start">
						<Grid item xs={3} sm={3} md={4} className={classNames([classes.labelGrid, classes.mtop])}>
							<InputLabel>Modules</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<Modules onUpdate={this.moduleChange} />
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.footer}>
					<Grid container xs={3} sm={3} md={4} className={classes.labelGrid}></Grid>
					<Grid item xs={7} sm={7} md={8}>
						<Button
							variant="contained"
							size="large"
							color="primary"
							className={classes.createBtn}
							onClick={this.save}
						>
							<Check className={classes.actionButtonIcon} />
							{ this.isNew() ? "Create" : "Save" }
						</Button>
						<Button
							variant="contained"
							size="large"
							color="secondary"
							className={classes.createBtn}
							onClick={() => this.props.history.push("/settings/plans")}
						>
							<Close className={classes.actionButtonIcon} />
							Cancel
						</Button>
					</Grid>
				</CardActions>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
	connect(
		null,
		mapDispatchToProps
	)(withStyles(styles, { withTheme: true })(PlanInfo))
);
