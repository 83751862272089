import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles"
import PropTypes from 'prop-types';
import { Card, Grid, Avatar, Typography, Icon, CardActionArea } from '@material-ui/core';
// Current Folder files 
import classNames from 'classnames';
import styles from './style.js';
import { Api } from "@/helpers";


class EmployeeChip extends Component {

    render() {
        const { classes, isActive, isLocked, employee, selectable, children } = this.props;
        return (
            <Card className={classNames(classes.root, { [classes.active ]: isActive })}>
                <CardActionArea onClick={() => this.props.onClick(employee.id)}>
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                    >
                        <Grid item>
                            <Avatar className={classes.avatar} src={Api.employees(employee.id).avatar.getUrl(true)}>
                                {employee.first_name} {employee.last_name}
                            </Avatar>
                        </Grid>
                        <Grid item container direction="column">
                            <Typography
                                component="div"
                                variant="subtitle1"
                                className={classes.name}>
                                {employee.first_name} {employee.last_name}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.code}>
                                {employee.code}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid item container wrap="nowrap" alignItems="center">
                                { children }
                                {
                                    isLocked &&
                                    <Icon color="secondary" className={classes.lock}>lock</Icon>
                                }
                                {
                                    selectable &&
                                    <Icon color="disabled" className={classes.checkmark}>{isActive ? "check_circle" : "radio_button_unchecked"} </Icon>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    }
}

EmployeeChip.propTypes = {
    isActive:  PropTypes.bool,
    selectable: PropTypes.bool,
    employee: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

EmployeeChip.defaultProps = {
    isActive: false,
    selectable: true,
    onClick: () => {}
};

export default withStyles(styles)(EmployeeChip);
