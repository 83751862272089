const styles = theme => ({

    backFab: {
        boxShadow: theme.shadows[2],
        background: "#FFFFFF",
        height: "25px !important",
        minWidth: "25px !important",
        fontSize: "0.7rem"
    },
    
    withMargin: {
        marginBottom: theme.spacing(2)
    },

    extendedFabIcon: {
        fontSize: "1.2rem",
        marginRight: theme.spacing(1)
    },

    extendedFabText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }

});

export default styles