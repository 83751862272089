const style = theme => ({
	
	fab: {
		[theme.breakpoints.down("sm")]: {
			padding: "0 !important"
		},
		marginRight: theme.spacing(1)
	},

	extendedIcon: {
		marginRight: theme.spacing(1),

		[theme.breakpoints.down("sm")]: {
			marginRight: 0
		}
	},

	extendedText: {
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},

	connected: {
		color: "green"
	}

});

export default style;