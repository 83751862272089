const style = theme => ({

    statusChip: {
        display: "flex",
        paddingLeft: 5,
        justifyContent: "space-between",
        alignItems: "center"
    },

    statusChipIcon: {
        fontSize: "1rem",
        marginRight: 4
    }

});

export default style;