import moment from "moment";
import styles from "./style";
import { Api } from "@/helpers";
import React, { Component } from "react";
import { DatePicker } from "@material-ui/pickers";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import MonthlyAttendanceSummaryRow from "./MonthlyAttendanceSummaryRow";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import { BackButton, DLTable, Page, InfographicMessage } from "@/components";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";
import { setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import NoRecord from '../../assets/icons/no-use-bg.svg';
class MonthlyAttendanceSummary extends Component {

    _table = null;

    constructor (props) {
        super(props);
        this.state = {
            from: moment().format("YYYY-MM"),
            year: null,
            department: null,
            designation: null,
            branch: null,
            dataColumn: "",
			anchorEl: null
        };
    }

    reload = () => {

        this.props.setLoader(true);
        this._table && this._table.fetchData();
        this.props.setLoader(false);
    };

    export = () => {
		const { from, department, designation, branch } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.employee_absent_present.export.getUrl({
			date: moment(from || moment()).format("YYYY-MM"),
            department: department || undefined,
            designation: designation || undefined,
            branch: branch || undefined
		}))
	};

    emptyView() {
        return (
            <InfographicMessage
                illustration="AbsentReport"
                message="No attendance found!"
            />
        );
    }

    render() {
        const { classes } = this.props;
        const { from, department, designation, branch } = this.state;
        return (
            <Page title="Monthly Attendance Summary">
                <BackButton route="/reports" label="Back to Reports" />
                <Card raised>
                    <CardActions className={classes.taxReportActions}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <DatePicker
                                    fullWidth
                                    margin="dense"
                                    views={["month", "year"]}
                                    maxDate={moment().format("YYYY-MM-DD")}
                                    label="Select Month"
                                    inputVariant="outlined"
                                    value={from || moment().format("YYYY-MM")}
                                    onChange={from => this.setState({ from }, this.reload)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <BranchDropdown
                                    textFieldProps={{ margin: "dense" }}
                                    value={branch}
                                    onChange={branch => this.setState({ branch }, this.reload)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DepartmentDropdown
                                    textFieldProps={{ margin: "dense" }}
                                    value={department}
                                    onChange={department => this.setState({ department }, this.reload)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DesignationDropdown
                                    textFieldProps={{ margin: "dense", size: "small" }}
                                    value={designation}
                                    onChange={designation => this.setState({ designation }, this.reload)}
                                />
                            </Grid>
                        </Grid>
                        <Button 
							size="medium"
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
                    </CardActions>
                    <CardActions style={{flexDirection:"center", justifyContent: "center" }}>
                        <Grid style={{flexDirection:"center" }}>
                            <span id="option-label" className={classes.label}>
                            <span style={{ backgroundImage: `url(${NoRecord})`, height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>  <text >No Record </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "  #00D454", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>  <text >Present: P </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "#FF7300", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>   <text >Holiday: H </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "#64182A", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>      <text >Leave: L </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "#FABD60", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Late: LT </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "#00C7D4", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Half Day: HD </text>
                            </span>
                            <span id="option-label" className={classes.label}>
                                <span style={{ backgroundColor: "#281B1B", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Absent: A </text>
                            </span>
                        </Grid>
                    </CardActions>
                    <DLTable
                        ref={ref => this._table = ref}
                        api={Api.reports.employee_absent_present}
                        params={{
                            date: moment(from || moment()).format("YYYY-MM"),
                            department: department || undefined,
                            designation: designation || undefined,
                            branch: branch || undefined,
                            per_page: 50
                        }}
                        stickyHeader1={true}
                        setLoader={this.props.setLoader}
                        RowTemplate={MonthlyAttendanceSummaryRow}
                        emptyView={this.emptyView()}
                    />
                </Card>
            </Page>
        );
    }

}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(MonthlyAttendanceSummary)));
