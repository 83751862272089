import React, { Component } from "react";
import { Button, CardActions, Icon } from "@material-ui/core";
import { DLTable, InfographicMessage, BulkAction } from "@/components";
import EmployeeBankModal from "@/components/Modals/EmployeeBankModal";
import EmployeeBankTableRow from "./EmployeeBankTableRow";
import { Api, HttpErrorHandler } from "@/helpers";
import UserRole from "@/components/UserRole";
import { connect } from "react-redux";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { AccessManager } from "@/helpers/";
import { Permissions } from "@/config/Permissions";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";

class EmployeeBankAccounts extends Component {
	constructor(props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();
	}

	_table = null;

	state = {
		isAddingAccount: false,
		isFullAccess: false,
	};

	reload = () => {
		this.setState({ isAddingAccount: false });
		this.tableRef.current && this.tableRef.current.fetchData();
	};

	emptyView() {
		const { employee } = this.props;
		if (AccessManager.hasPermission([Permissions.EMPLOYEES_FULL_ACCESS])) {
			return (
				<InfographicMessage
					illustration="bank"
					message={`${employee.first_name} doesn't have any bank account yet, add one now!`}
					actionLabel={"Add Bank Account"}
					actionIcon={"add"}
					actionHandler={() => this.setState({ isAddingAccount: true })}
				/>
			);
		}
		return (
			<InfographicMessage
				illustration="bank"
				message={`${employee.first_name} doesn't have any bank account yet`}
			/>
		);
	}


	async deleteItem(selected) {
		const { employee } = this.props;
		this.props.setLoader(true);
			try {
				await Api.employees(employee.id).bank_accounts.delete_multiple.delete({  ids: selected.map(e => e.id), })
				this.props.setSnackbar(true, "Selected items deleted successfully");
			}
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}


	getItems() {
		let items = [{
				name: "Delete Selected",
				color: "secondary",
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={"Delete Bank Account"}
						message={`Are you sure to delete ${selected.length} bank account${selected.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							await this.deleteItem(selected);
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}];
		return items;
	}


	onSelectionChange = selected => {
		this.setState({ selectedAccount : selected});
		this.actionbarRef.current.setSelected(selected);
	}

	render() {
		const { employee } = this.props;
		const { isAddingAccount } = this.state;
		
		return (
			<>
				<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
					<CardActions>
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							onClick={() => this.setState({ isAddingAccount: true })}>
							<Icon>add</Icon>
							Add Bank Account
						</Button>
						<BulkAction
							ref={this.bulkAction}
							options={this.getItems()}
						/>
					</CardActions>
					<DLTable
						enableSelection
						ref={this.tableRef}
						rowProps={{ employee }}
						emptyView={this.emptyView()}
						RowTemplate={EmployeeBankTableRow}
						bulkAction={this.bulkAction}
						api={Api.employees(employee.id).bank_accounts}
					/>
				</UserRole>
				{
					isAddingAccount &&
					<EmployeeBankModal
						title={"Add Bank Account"}
						isOpen={isAddingAccount}
						employee={employee}
						onUpdate={this.reload}
						onClose={() => this.setState({ isAddingAccount: false })}
					/>
				}
			</>
		);
	}
}
const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)((EmployeeBankAccounts));
