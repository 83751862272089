import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "@/components/WeekPicker/react_dates_overrides.css";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";

import CustomeWeekPicker from "./customWeekPicker";

class WeekPicker extends Component {
  constructor(props) {
    super(props);
    const currentMoment = moment();
    this.state = {
      focused: false,
      selectedWorkWeek: currentMoment.isoWeek(),
      selectedYear: currentMoment.year(),
      workWeekMarginLeft: 0
    };
    this.onDateChange = this.onDateChange.bind(this);
  }






  onDateChange = date => {
    const selectedYear = date.year();
    const selectedWorkWeek = date.isoWeek();
    this.setState({
      selectedYear,
      selectedWorkWeek,
      focused: false
    }, () => { this.props.setWeekDate(date) });
  };

  previousWeek = () => {
    const { selectedWorkWeek, selectedYear } = this.state;
    let newSelectedWorkWeek = selectedWorkWeek - 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 0) {
      newSelectedWorkWeek = 52;
      newSelectedYear = selectedYear - 1;
    }
    const date = moment()
      .year(newSelectedYear)
      .isoWeek(newSelectedWorkWeek);
    this.setState({
      selectedYear: newSelectedYear,
      selectedWorkWeek: newSelectedWorkWeek,
      hoveredDays: this.calculateActiveWeek(date)
    });
  };

  nextWeek = () => {
    const { selectedWorkWeek, selectedYear } = this.state;
    let newSelectedWorkWeek = selectedWorkWeek + 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 53) {
      newSelectedWorkWeek = 1;
      newSelectedYear = selectedYear + 1;
    }
    const date = moment()
      .year(newSelectedYear)
      .isoWeek(newSelectedWorkWeek);
    this.setState({
      selectedYear: newSelectedYear,
      selectedWorkWeek: newSelectedWorkWeek,
      hoveredDays: this.calculateActiveWeek(date)
    });
  };


  calculateActiveWeek = date => {
    const mon = date.clone().startOf("isoweek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };






  render() {
    const {  selectedWorkWeek, selectedYear } = this.state;
    const date = moment(this.props.weekDate)
      .year(selectedYear)
      .isoWeek(selectedWorkWeek);
    return <CustomeWeekPicker selectedDate={date} onDateChange={this.onDateChange} nextWeek={this.nextWeek } previousWeek={this.previousWeek}/>
  }
}

export default withStyles(styles, { withTheme: true })(WeekPicker);
