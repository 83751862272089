import React from "react";
import { OrganizationInfo, OrganizationBranches, BackButton, PageTitle, Invoices, Page, Tabs, Purchases, PermissionHOC } from "@/components";
import { Card  } from "@material-ui/core";
import Api from "@/helpers/Api";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setSnackbar } from "@/redux/actions/general";
import styles from "./style";

class OrganizationEdit extends React.Component {

	state = {
		organization: null
	};

	async componentDidMount() {
		if (this.props.match.params.id) {
			try {
				const organization = await Api.organizations(this.props.match.params.id).get();
				this.setState({ organization });
			} catch (e) {
				this.props.history.replace("/organizations");
				this.props.setSnackbar(true, "Unable to get organization details, please try again", "error");
			}
		}
	}

	setOrganization = organization => {
		return new Promise(resolve => {
			this.setState({ organization }, resolve);
		});
	};

	render() {
		const { hasPermission, permissions } = this.props;
		const { organization } = this.state;
		this.organizationData = {}

		return (
			<Page title="Organizations">
				<BackButton route="/organizations" label="Back to Organizations" />
				<PageTitle>Edit Organization</PageTitle>
				<Card raised>
					{
						organization && organization.id &&
						<Tabs
							variant="routed"
							tabsProps={{
							variant: "scrollable"
							}}>
							<Tabs.Tab
								path="/info"
								label="Information">
									<OrganizationInfo organization={organization} onUpdate={this.setOrganization} />
							</Tabs.Tab>
							<Tabs.Tab
								path="/branches"
								label="Branches">
									<OrganizationBranches organization={ organization }/>
							</Tabs.Tab>
							<Tabs.Tab
								path="/purchases"
								label="Purchases">
									<Purchases organization={ organization } hasPermission={ hasPermission } permissions={ permissions }/>
							</Tabs.Tab>	
							<Tabs.Tab
								path="/invoices"
								label="Invoices">
									<Invoices organization={ organization } hasPermission={ hasPermission } permissions={ permissions }/>
							</Tabs.Tab>
						</Tabs>
					}
				</Card>
			</Page>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(PermissionHOC(OrganizationEdit))));