import React from "react";
import { withRouter } from "react-router-dom";
import { DLTableRow, DLTableColumn, Money } from "@/components";
import { IconButton, Tooltip} from "@material-ui/core";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { RemoveRedEyeOutlined, ArchiveOutlined, UnarchiveOutlined } from "@material-ui/icons";

class PlanTableRow extends DLTableRow {

    constructor(props) {
        super(props);
        this.state = {
            archiving: false
        }
    }

    confirmationMessage() {
        const { data } = this.props;
        return (
            <span>
                Are you sure you want to archive 2 {data.title}?
            </span>
        );
    }

    
    archive = async () => {
		this.setState({ isModalOpen: false });
		
		const { data } = this.props;
		try {
			await Api.plans(data.id).delete();
			store.dispatch(setSnackbar(true, `${data.title} archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

    restore = async () => {
		this.setState({ isModalOpen: false });
		
		const { data } = this.props;
		try {
			await Api.plans(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `${data.title} restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};
    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                key={`Plan-${data.id}-Column-1`}
                withSeparator={true}>
                {data.title}
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-2`}
                withSeparator={false}>
                {data.type}
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-3`}
                withSeparator={false}>
                {`${data.trial_period} ${data.trial_period_type}`}
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-4`}
                withSeparator={false}>
                {`${data.grace_period} ${data.grace_period_type}`}
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-5`}
                withSeparator={false}
                align={"left"}>
                {data.no_employees || 'Unlimited'}
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-6`}
                align={"left"}
                withSeparator={false}>
                <Money amount={data.price} />
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-7`}
                align={"left"}
                withSeparator={false}>
                {data.discount}%
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-8`}
                isCompact={true}
                align={"right"}
                fillWidth={35}>  
                {
                    !data.is_archived && 
                    <>
                        <Tooltip title="View Plan">
                            <IconButton
                                onClick={() => this.props.history.push(`/settings/plans/${data.id}`)}
                                size="small"
                                color="primary">
                                <RemoveRedEyeOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Plan-${data.id}-Column-9`}
                isCompact={true}
                align={"center"}
                fillWidth="35">
                {
                    !data.is_archived  ? (
                        <>
                            <Tooltip title="Archive Plan">
                                <IconButton
                                    onClick={() => this.setState({ isModalOpen: true })}
                                    size="small"
                                    color="secondary"
                                >
                                    <ArchiveOutlined />
                                </IconButton>
                            </Tooltip>

                            <ConfirmModal 
                                title="Archive Plan" 
                                isOpen={this.state.isModalOpen} 
                                message={`Are you sure you want to archive plan "${data.title}"?`} 
                                onClickCancel={() => {
                                    this.setState({ isModalOpen: false });
                                }}
                                onClickConfirm={this.archive}/>
                        </>
                    ) : (
                        // !data.is_trial &&
                        <>
                            <Tooltip title="Restore Plan">
                                <IconButton
                                    onClick={() => this.setState({ isModalOpen: true })}
                                    size="small"
                                    color="primary"
                                >
                                    <UnarchiveOutlined />
                                </IconButton>
                            </Tooltip>

                            <ConfirmModal 
                                title="Restore Plan" 
                                isOpen={this.state.isModalOpen} 
                                message={`Are you sure you want to restore plan "${data.title}"?`} 
                                onClickCancel={() => {
                                    this.setState({ isModalOpen: false });
                                }}
                                onClickConfirm={this.restore}/>
                        </>
                    )
                }
            </DLTableColumn>
        ]
    }

}

PlanTableRow.Columns = [
    {
        key: "title",
        label: "Title",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "type",
        label: "Type",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "trail_period",
        label: "Trail Period",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "grace_period",
        label: "Grace Period",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "no_employees",
        label: "No. Employees",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "price",
        label: "Per Employee Price",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "dicsount",
        label: "Discount",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: null,
        label: null,
        align: "center",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
];

export default withRouter(PlanTableRow);