import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import loanTableRow from "./LoanTableRow";
import loanRepayTableRow from "./LoanRepayTableRow";
import classNames from "classnames";
import AddSuggestionModal from "../../components/Modals/AddSuggestionModal";
import { Permissions } from "@/config/Permissions";
import { UserRole, DLTable, InfographicMessage } from "@/components";

import {
    Grid,
    TextField,
    Button,
    InputLabel,
    Card,
    Switch,
    Icon,
    MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import styles from "./style";
import { BackButton, PageTitle, Page, EmployeeDropdown } from "@/components";
import moment from "moment";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import { Constants } from "@/config";
import store from "@/redux";

class AddLoan extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            errors: {},
            loan_amount: "",
            loan: "",
            message: "",
            cycle: "",
            duration: "",
            employee: "",
            data: [],
            suggesstion: [],
            return_amount: "",
            disabled: false,
            durationType: null,
            cycleType: "Months",
            isLoading: false,
            isView: false,
            isOpen: false,
            isEdit: false,
            status: "pending",
            remainingAmount: null,
            sequential: false,
            filter: [
                {
                    field: "loan_request_id",
                    operator: "and",
                    value: this.props.match.params.loansId,
                    method: "is",
                },
            ],
            defaultFilter: null,
        };
    }
    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func,
    };

    async getData() {
        var transactionData = []
        if (this.props.match.params && this.props.match.params.id > 0) {
            store.dispatch(setLoader(true));
            this.setState({ isLoading: true });
            let loan = await Api.loan_request(this.props.match.params.id).get();

            this.setState(
                {
                    loan: loan,
                    loan_amount: loan.loan_amount,
                    cycle: loan.cycle,
                    message: loan.message || "",
                    duration: loan.duration,
                    employee: loan.employee_id,
                    return_amount: loan.return_amount,
                    isLoading: false,
                    remaining: loan.remaining,
                    status: loan.status,
                    sequential: loan.is_sequential,
                    durationType: loan.duration_type,
                    cycleType: loan.cycle_type,
                    isEdit: true,
                },
                () => {
                    store.dispatch(setLoader(false));
                }
            );
        } else if (
            this.props.match.params &&
            this.props.match.params.loansId > 0
        ) {
            store.dispatch(setLoader(true));
            this.setState({ isLoading: true, isView: true });
            let loan = await Api.loan_request(
                this.props.match.params.loansId
            ).get();
            this.setState(
                {
                    // data: loan.repay_loans,
                    suggesstion: loan.suggestions,
                    loan_amount: loan.loan_amount,
                    cycle: loan.cycle,
                    message: loan.message || "",
                    duration: loan.duration,
                    employee: loan.employee_id,
                    return_amount: loan.return_amount,
                    isLoading: false,
                    status: loan.status,
                    sequential: loan.is_sequential,
                    durationType: loan.duration_type,
                    cycleType: loan.cycle_type,
                    loan: loan,
                },
                () => {
                    store.dispatch(setLoader(false));
                }
            );
            if(loan.repay_loans.length>0){
                transactionData.push(
                    ...loan.repay_loans)
            }
            if(loan.paid_loan !==null){
                transactionData.push(loan.paid_loan)
            }
        }
    this.setState({data: transactionData});
    }

    async componentDidMount() {
        this.getData()
    }

    emptyView() {
        return (
            <InfographicMessage
                illustration="suggestions"
                message={"You don't have any Suggestions yet"}
                 />
        );
    }

    isValidData = () => {
        const { isView } = this.state;
        var errors = {};
        if (this.state.employee === "" && !isView) {
            errors["employee"] = `${_.startCase("employee")} field is required`;
        }
        if (this.state.message === "") {
            errors["message"] = `${_.startCase("message")} field is required`;
        }
        if (this.state.return_amount === "") {
            errors["return_amount"] = `${_.startCase(
                "return_amount"
            )} field is required`;
        }
        
        if (this.state.cycle === "") {
            errors["cycle"] = `${_.startCase("cycle")} field is required`;
        }
        if (this.state.loan_amount === "") {
            errors["loan_amount"] = `${_.startCase(
                "loan_amount"
            )} field is required`;
        }

        if (_.size(errors)) {
            this.props.setSnackbar(
                true,
                "Please fix the highlighted errors",
                "error"
            );
        }
        this.setState({ errors });
        return !(_.size(errors) > 0);
    };


    createloan = async () => {
        if (this.isValidData()) {
            var data = new FormData(); 
            var durationNew  = "";
            if (this.state.cycleType === "Years") {
				durationNew = (this.state.loan_amount / this.state.return_amount) * (this.state.cycle * 12);
				durationNew = durationNew / 12;
			} else if (this.state.cycleType === "Months") {
				durationNew = (this.state.loan_amount / this.state.return_amount) * this.state.cycle;
			}
            data.append("message", this.state.message);
            data.append("loan_amount", this.state.loan_amount);
            data.append("return_amount", this.state.return_amount);
            data.append("manual", true);
            data.append("sequential", this.state.sequential === true ? 1 : 0);
            data.append("duration", `${Math.round(durationNew)}`);
            data.append("cycle", `${this.state.cycle} `);
            data.append("duration_type", this.state.cycleType === "Years"? "Years":`Months`);
            data.append("cycle_type", `${this.state.cycleType} `);
            if (this.state.loan !== "") {
                try {
                    data.append("_method", "put");
                    store.dispatch(setLoader(true));
                    await Api.loan_request(this.state.loan.id).post(data);
                    this.props.setSnackbar(
                        true,
                        "Loan request updated for employee"
                    );
                    store.dispatch(setLoader(false));
                    this.props.history.push("/loans");
                } catch (e) {
                    store.dispatch(setLoader(false));
                    HttpErrorHandler.intercept(e)
                        .on(422, (response) => {
                            this.setState({
                                errors: _.mapValues(
                                    response.data,
                                    (value) => value[0]
                                ),
                            });
                            if (
                                _.size(response.data) === 1 &&
                                _.has(response.data, "message")
                            ) {
                                this.props.setSnackbar(
                                    true,
                                    response.data["message"],
                                    "error"
                                );
                            } else {
                                this.props.setSnackbar(
                                    true,
                                    "Please fix the highlighted errors",
                                    "error"
                                );
                            }
                        })
                        .default((_) =>
                            this.props.setSnackbar(
                                true,
                                "Something went wrong, please try again later",
                                "error"
                            )
                        )
                        .go();
                }
            } else {
                try {
                    store.dispatch(setLoader(true));

                    await Api.loan_request(this.state.employee).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    this.props.setSnackbar(
                        true,
                        "Loan request added for employee"
                    );
                    store.dispatch(setLoader(false));
                    this.props.history.push("/loans");
                } catch (e) {
                    store.dispatch(setLoader(false));
                    HttpErrorHandler.intercept(e)
                        .on(422, (response) => {
                            this.setState({
                                errors: _.mapValues(
                                    response.data,
                                    (value) => value[0]
                                ),
                            });
                            if (
                                _.size(response.data) === 1 &&
                                _.has(response.data, "message")
                            ) {
                                this.props.setSnackbar(
                                    true,
                                    response.data["message"],
                                    "error"
                                );
                            } else {
                                this.props.setSnackbar(
                                    true,
                                    "Please fix the highlighted errors",
                                    "error"
                                );
                            }
                        })
                        .default((_) =>
                            this.props.setSnackbar(
                                true,
                                "Something went wrong, please try again later",
                                "error"
                            )
                        )
                        .go();
                    
                }
            }
        }
    };

    handleChange = (e, fieldName) => {
        if (
            fieldName === "employee" ||
            fieldName === "status" ||
            fieldName === "sequential"
        ) {
            if (
                fieldName === "status" &&
                this.state.status === Constants.Loan.FLAG_PAID
            ) {
                return;
            } else if (
                fieldName === "status" &&
                (e === Constants.Loan.FLAG_REJECTED ||
                    e === Constants.Loan.FLAG_PENDING)
            ) {
                this.setState({ [fieldName]: e, pay_in_next_payroll: false });
            } else {
                 
                    this.setState({ [fieldName]: e });
                
            }
        } else {
            this.setState({ [fieldName]: e.target.value });
        }
    };

    handleChangeDropdown(event) {
        this.setState({ cycleType: event.target.value });
    }

    render() {
        const { classes } = this.props;
        const { isView, errors, disabled, isLoading, filter, isEdit } = this.state;
        return (
            <>
                <Page title="Loan">
                    <Link to={{ pathname: `/loans` }} className={classes.link}>
                        <BackButton
                            route="/loans"
                            label="Back to Loan Request"
                        />
                    </Link>
                    {!isView && <PageTitle>Add Loan</PageTitle>}
                    <Card raised>
                        <div className={classes.paymentHeader}>
                            Loan Details
                        </div>
                        <Grid container className={classes.containerGrid}>
                            <Grid item xs={3} sm={3}  md={4} className={classes.labelGrid} >
                                <InputLabel>Employee</InputLabel>
                            </Grid>
                            <Grid item xs={7} sm={7} md={8}>
                                <div className={classNames(classes.textField)}>
                                    <EmployeeDropdown
                                        disabled={disabled || isView}
                                        label={
                                            this.state.employee
                                                ? ""
                                                : "Employee"
                                        }
                                        placeholder="Employee"
                                        textFieldProps={{
                                            error: !!errors.employee,
                                            helperText: errors.employee,
                                            FormHelperTextProps: {
                                                variant: "standard",
                                            },
                                        }}
                                        onChange={(employee) =>
                                            this.handleChange(
                                                employee,
                                                "employee"
                                            )
                                        }
                                        value={this.state.employee}
                                        isActive={true}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={4} className={classes.labelGrid} >
                                <InputLabel>Requested Amount</InputLabel>
                            </Grid>
                            <Grid container xs={7} sm={7} md={8}>
                                <TextField
                                    disabled={isView}
                                    type="number"
                                    error={!!errors.loan_amount}
                                    helperText={errors.loan_amount}
                                    FormHelperTextProps={{
                                        variant: "standard",
                                    }}
                                    placeholder="Requested Amount"
                                    className={classNames([
                                        classes.textField,
                                        classes.marginTextInput,
                                    ])}
                                    value={this.state.loan_amount || ""}
                                    onChange={(e) =>
                                        this.handleChange(e, "loan_amount")
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={4} className={classes.labelGrid} >
                                <InputLabel>
                                    Amount to be deducted every
                                </InputLabel>
                            </Grid>
                            <Grid container xs={7} sm={7} md={8}>
                                <TextField
                                    disabled={isView}
                                    error={!!errors.cycle}
                                    helperText={errors.cycle}
                                    FormHelperTextProps={{
                                        variant: "standard",
                                    }}
                                    placeholder="Return Cycle"
                                    className={classNames([
                                        classes.textFieldType,
                                        classes.marginTextInput,
                                    ])}
                                    value={this.state.cycle || ""}
                                    onChange={(e) =>
                                        this.handleChange(e, "cycle")
                                    }
                                    type="number"
                                    variant="outlined"
                                />
                                  <TextField
                                    select
                                    disabled={isView}
                                    placeholder="Cycle Type"
                                    variant="outlined"
                                    error={!!errors.cycleType}
                                    helperText={errors.cycleType}
                                    value={this.state.cycleType || "Months"}
                                    className={classNames([
                                        classes.textFieldType,
                                        classes.marginTextInput,
                                    ])}
                                    onChange={(e) =>
                                        this.handleChangeDropdown(e, "cycleType")
                                    }
                                >
                                    <MenuItem value="Months">Months</MenuItem>
                                    <MenuItem value="Years">Years</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={3} sm={3} md={4} className={classes.labelGrid} >
                                <InputLabel>Return Amount</InputLabel>
                            </Grid>
                            <Grid container xs={7} sm={7} md={8}>
                                <TextField
                                    disabled={isView}
                                    error={!!errors.return_amount}
                                    helperText={errors.return_amount}
                                    FormHelperTextProps={{
                                        variant: "standard",
                                    }}
                                    type="number"
                                    placeholder="Return Amount"
                                    className={classNames([
                                        classes.textField,
                                        classes.marginTextInput,
                                    ])}
                                    value={this.state.return_amount || ""}
                                    onChange={(e) =>
                                        this.handleChange(e, "return_amount")
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                sm={3}
                                md={4}
                                className={classes.labelGrid}
                            >
                                <InputLabel> Message</InputLabel>
                            </Grid>
                            <Grid container xs={7} sm={7} md={8}>
                                <TextField
                                    disabled={isView}
                                    size="small"
                                    error={this.state.errors.message}
                                    helperText={this.state.errors.message}
                                    placeholder="Message"
                                    className={classNames([
                                        classes.textField,
                                        classes.marginTextInput,
                                    ])}
                                    multiline={true}
                                    rows={6}
                                    id="message"
                                    value={this.state.message || ""}
                                    onChange={(e) =>
                                        this.handleChange(e, "message")
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                sm={3}
                                md={4}
                                className={classes.labelGrid}
                            >
                                <InputLabel>Sequential</InputLabel>
                            </Grid>
                            <Grid container xs={7} sm={7} md={8}>
                                <Switch
                                    disabled={isView}
                                    checked={this.state.sequential}
                                    onChange={() =>
                                        this.handleChange(
                                            !this.state.sequential,
                                            "sequential"
                                        )
                                    }
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classNames(
                                classes.containerGrid,
                                classes.footer
                            )}
                        >
                            {!disabled && !isLoading && (
                                <>
                                    <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}
                                    ></Grid>
                                    {!isView && (
                                        <Grid item xs={7} sm={7} md={8}>
                                            <UserRole
                                                routePermissions={[
                                                    Permissions.ROLE_EDITABLE,
                                                    Permissions.ROLE_FULL_ACCESS,
                                                ]}
                                            >
                                                <Button
                                                    className={
                                                        classes.actionButton
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.createloan}
                                                >
                                                    {isEdit ? "Save" : "Create"}
                                                </Button>
                                            </UserRole>
                                            <Button
                                                className={classes.actionButton}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    this.props.history.push(
                                                        "/loans"
                                                    )
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Card>
                    {isView && (
                        <Card raised style={{ marginTop: 20 }}>
                            <div className={classes.paymentHeader}>
                                <span> Suggestion Details </span>
                                {this.state.status ===
                                    Constants.Loan.FLAG_PENDING && (
                                    <Button
                                        className={classes.createBtn}
                                        variant="outlined"
                                        size="medium"
                                        // color="primary"
                                        onClick={() =>
                                            this.setState({ isOpen: true })
                                        }
                                    >
                                        <Icon>add</Icon>
                                        Add Suggestion
                                    </Button>
                                )}
                            </div>
                            <DLTable
                                showPagination={false}
                                api={Api.loan_requests(this.props.match.params.loansId).suggestions}
                                RowTemplate={loanTableRow}
                                // params={{ filter }}
                                emptyView={this.emptyView()}
                                ref={this.tableRef}
                                defaultOrder="desc"
                            />
                        </Card>
                    )}
                    {this.state.data.length > 0 && (
                        <Card raised style={{ marginTop: 20 }}>
                            <div className={classes.paymentHeader}>
                                <span> Transaction Details </span>
                            </div>
                            <DLTable
                                showPagination={false}
                                data={this.state.data}
                                rowProps={{ onUpdate:()=>this.getData() }}
                                RowTemplate={loanRepayTableRow}
                                ref={this.tableRef}
                                defaultOrder="desc"
                            />
                        </Card>
                    )}

                    {this.state.isOpen && (
                        <AddSuggestionModal
                            open={this.state.isOpen}
                            onClose={() => this.setState({ isOpen: false })}
                            title="Add Suggestion"
                            loan={this.state.loan}
                            onUpdate={() => {
                                this.tableRef.current.fetchData();
                            }}
                        ></AddSuggestionModal>
                    )}
                </Page>
            </>
        );
    }
}

AddLoan.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
    connect(null, mapDispatchToProps)(withStyles(styles)(AddLoan))
);
