import React, { Component } from "react";
import { withRouter, Switch, Route, Link } from "react-router-dom";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { ArchiveOutlined, Inbox } from "@material-ui/icons";
import { 
	BackButton, 
	DLTable, 
	BulkAction, 
	InfographicMessage, 
	Page, 
	PermissionHOC, 
	Tabs, 
	UserRole
} from "@/components";
import PayItemTableRow from "./PayItemTableRow";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import { AuthContext } from "@/contexts";
import { Permissions } from "@/config/Permissions";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import AddPayItemModal from "@/pages/PayItemAdd"

class PayItem extends Component {
	constructor(props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();
		this.state = {
			activeTabIndex: 0,
			selectedCount: 0
		}
	}

	static contextType = AuthContext;

	addPayitem = (e) => {
		e && e.preventDefault();
		this.props.history.push({
			pathname: "/settings/payitems/add"
		});
	};

	reload = () => {
		this.tableRef.current.fetchData();
	};

	emptyView(archived = false) {
		const hasPermission = this.props.hasPermission([Permissions.PAYITEM_EDITABLE, Permissions.PAYITEM_FULL_ACCESS]);
		const message = !archived
			? "You don't have any pay item yet, add one now!"
			: "Yaayyy! all pay items are active and in use";
		return (
			<InfographicMessage
				illustration="pay_item"
				message={message}
				actionLabel={archived ? "" : hasPermission ? "Add Pay Item" : ""}
				actionIcon={archived ? "" : hasPermission ? "add" : ""}
				actionHandler={this.addPayitem}
			/>
		);
	}

	
	async restore(selected) {
		const { activeTabIndex} = this.state;
		this.props.setLoader(true);
		try {
			await Api.pay_items.restore_multiple.patch({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items restored successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error");
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	async delete(selected) {
		const { activeTabIndex} = this.state;
		this.props.setLoader(true);
		try {
			await Api.pay_items.delete_multiple.delete({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items archived successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error");
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}


	getItems() {
		const { activeTabIndex} = this.state;
		var buttonName = !!activeTabIndex ? "Restore Selected" : "Archive Selected";
		var buttonColors = !!activeTabIndex ? "primary" : "secondary";
			var items = [{
					name: buttonName,
					color: buttonColors,
					action: (_, action) => action.setState({ enableLeaveModal: true }),
					render: (selected, action) => (
						action.state.enableLeaveModal &&
						<ConfirmModal
							isOpen={action.state.enableLeaveModal}
							title={!!activeTabIndex ? "Restore Pay Item" : "Archive Pay item"}
							message={`Are you sure to ${!!activeTabIndex ? "restore" : "archive"} ${selected.length} Pay item${selected.length == 1 ? '' : 's'}?`}
							onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
							onClickConfirm={async () => {
								if (!!activeTabIndex) {
									await this.restore(selected);
								}
								else{
									await this.delete(selected);
								}
								action.setState({ enableLeaveModal: false, activeOption: null });
							}}
						/>
					)
				}];
		return items;
	}

	actionBar() {
		const { activeTabIndex } = this.state;

		const items = [
			{
				function: "archiveOrRestore",
				name: `${!activeTabIndex ? "Archived" : "Restore"} selected`,
				modal: {
					title: `${!activeTabIndex ? "Archived" : "Restore"} Pay item`,
					message: `Are you sure you want to ${!activeTabIndex ? "archived" : "restore"} ${this.state.selectedCount} pay items`
				}
			}
		];

		return (
			<UserRole routePermissions={[Permissions.PAYITEM_EDITABLE, Permissions.PAYITEM_FULL_ACCESS]}>
					<CardActions>
						<Link to={{ pathname: `/settings/payitems/add` }} style={{ textDecoration: 'none', color: "black" }}>
							<Button
								variant="outlined"
								size="medium"
								color="primary">
								<Icon>add</Icon>
								Add Payitem
							</Button>
						</Link>
						<BulkAction
							ref={this.bulkAction}
							options={this.getItems()}
						/>
					</CardActions>
			</UserRole>
		);
	}

	onSelectionChange = selected => {
		this.setState({ selectedCount: selected.length });
		this.actionbarRef.current.setSelected(selected);
	}

	render() {
		return (
			<Page title="Pay Items">
				<Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
					<BackButton route="/settings" label="Back to Settings" />
				</Link>
				<Card raised>
					<Tabs
						onChange={activeTabIndex => this.setState({ activeTabIndex })}
						beforePanel={this.actionBar()}
					>
						<Tabs.Tab
							icon={<Inbox />}
							label="Active">
							<DLTable
								enableSelection
								api={Api.pay_items}
								ref={this.tableRef}
								RowTemplate={PayItemTableRow}
								emptyView={this.emptyView()}
								bulkAction={this.bulkAction}
							/>
						</Tabs.Tab>
						<Tabs.Tab
							icon={<ArchiveOutlined />}
							label="Archived">
							<DLTable
								enableSelection
								api={Api.pay_items}
								ref={this.tableRef}
								params={{ filter: { archived: true } }}
								RowTemplate={PayItemTableRow}
								emptyView={this.emptyView(true)}
								bulkAction={this.bulkAction}
							/>
						</Tabs.Tab>
					</Tabs>
					<Switch>
					
						<Route exact path="/settings/payitems/add">
							<AddPayItemModal
								backRoute={"/settings/payitems"}
								onUpdate={this.reload}
							/>
						</Route>
						<Route path="/settings/payitems/:payItemId">
							<AddPayItemModal
								backRoute={"/settings/payitems"}
								onUpdate={this.reload}
							/>
						</Route>
					</Switch>
				</Card>
			</Page>
		);
	}
}
const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(PayItem));
