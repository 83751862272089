import React, { Component } from "react";
import { CardMedia, Typography, Button } from "@material-ui/core";
import { Page } from "@/components";
import { Grid } from "@material-ui/core";
import ErrorBanner from "@/assets/backgrounds/error_404.png";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import "typeface-roboto";

class Error extends Component {

	render() {
		const { classes } = this.props;
		console.log(classes)
		return (
			<Page title="">
				<Grid spacing={0}>
					<Grid xs={12} className={classes.main}>
						<CardMedia className={classes.banner} src={ErrorBanner} component="img"></CardMedia>
						<Typography component="div" className={classes.text}>Sorry! Page not found</Typography>
						<Button
							className={classes.backButton}
							variant="contained"
							color="primary"
							onClick={this.props.history.goBack}>
							Go back
						</Button>
					</Grid>
				</Grid>
			</Page>
		);
	}
}

export default withStyles(styles)(Error);
