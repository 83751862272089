import React from "react";
import { OrganizationInfo, BackButton, PageTitle, Page } from "@/components";
import { Card  } from "@material-ui/core";

class OrganizationAdd extends React.Component {

	render() {
		return (
			<Page title="Organizations">
				<BackButton route="/organizations" label="Back to Organizations" />
				<PageTitle>Add Organization</PageTitle>
				<Card raised>
					<OrganizationInfo employee={{}} organization={{}} pageType="add" />
				</Card>
			</Page>
		);
	}
	
}

export default OrganizationAdd;