import {
    EditOutlined,
    ClearOutlined,
    DoneOutlined,
    DeleteOutlined,
    RemoveRedEyeOutlined,
    NewReleases,
    ToggleOn,
    ToggleOff
} from "@material-ui/icons";
import { DLTableColumn, DLTableRow } from "@/components";
import { Api, HttpErrorHandler } from "@/helpers";
import DocumentModal from "../Modals/DocumentModal";
import DocumentRequestModal from "../Modals/DocumentRequestModal";
import React from "react";
import { Button, Tooltip, IconButton, Chip } from "@material-ui/core";
import store from "@/redux";
import _ from "lodash";
import styles from "./style";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Constants } from "@/config";
import Theme from "@/themeStyles";
import AddDocumentModal from "../../pages/AddDocument";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import moment from "moment";

class DocumentTableRow extends DLTableRow {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isDelete: false,
            isEdit: false,
            isRequest: false,
            isConfirmModalOpen: false,
        };
    }
    returnStatusColor(status) {
        switch (status) {
            case Constants.Reimbursable.FLAG_PENDING:
                return Theme.palette.tableBorder.main;
            case Constants.Reimbursable.FLAG_APPROVED:
                return Theme.palette.success.main;
            case Constants.Reimbursable.FLAG_REJECTED:
                return Theme.color.red.persianRed;
            case Constants.Reimbursable.FLAG_PAID:
                return Theme.palette.primary.main;
            default:
                break;
        }
    }

    delete = async (e) => {
        e.preventDefault();
        this.setState({ isDelete: false });
        const { data } = this.props;
        try {
            await Api.employee_document(data.id).delete();
            store.dispatch(
                setSnackbar(
                    true,
                    `Document "${data.name}" deleted successfully`
                )
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            HttpErrorHandler.intercept(e)
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.message) ||
                            "Something went wrong, please try again later",
                        "error"
                    );
                })
                .on(422, (response) => {
                    this.setState({
                        errors: _.mapValues(response.data, (value) => value[0]),
                    });
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.leave_request) ||
                            "Please fix the highlighted errors",
                        "error"
                    );
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
    };

    onConfirm = async () => {
        const { isConfirmModalOpen } = this.state;
        const { data } = this.props;
        try {
            store.dispatch(setLoader(true));
            var status = isConfirmModalOpen === "approve" ? "approved" : "rejected";
            await Api.employee_document(data.id).put({
                status,
                _method: "put",
            });

            store.dispatch(setSnackbar(true, `Request ${status}`, "success"));
            this.setState({ isConfirmModalOpen: false });
            await this.props.tableRef.fetchData();
            
        } catch (e) {
            store.dispatch(
                setSnackbar(
                    true,
                    "Something went wrong, please try again later",
                    "error"
                )
            );
        }
        store.dispatch(setLoader(false));
    };
    renderCells() {
        const { data, classes } = this.props;
        return [
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-1`}
                withSeparator={true}
            >
                {data.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-2`}
                align={"left"}
            >
                {data.category}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-2`}
                align={"left"}
            >
                {data.for_office_only ? <ToggleOn color="primary" /> : <ToggleOff/>}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-3`}
                align={"left"}
            >
                {moment(data.updated_at).format("MMM Do, YYYY")}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-4`}
                align={"left"}
            >
                <Chip
                    size="small"
                    label={data.status.toUpperCase()}
                    color={"primary"}
                    style={{
                        backgroundColor: this.returnStatusColor(data.status),
                        color: Theme.color.white.main,
                    }}
                />
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-5`}
                isCompact={true}
                align={"left"}
                fillWidth={35}>
                {
                    !!data.pending_request &&
                    <Tooltip title="View Request">
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => this.setState({ isRequest: true })}>
                            <NewReleases/>
                        </IconButton>
                    </Tooltip>
                }
            </DLTableColumn>,    
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-6`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                {data.status === "pending" && (
                    <Tooltip title="Approve Request">
                        <IconButton
                            onClick={() =>
                                this.setState({ isConfirmModalOpen: "approve" })
                            }
                            size="small"
                            color="primary"
                        >
                            <DoneOutlined />
                        </IconButton>
                    </Tooltip>
                )}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-7`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                {data.status === "pending" && (
                    <Tooltip title="Reject Request">
                        <IconButton
                            onClick={() =>
                                this.setState({
                                    isConfirmModalOpen: "reject",
                                })
                            }
                            size="small"
                            color="secondary"
                        >
                            <ClearOutlined />
                        </IconButton>
                    </Tooltip>
                )}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-8`}
                align={"right"}
                fillWidth={35}
            >
               <IconButton variant="outlined" 
                    size="small"
                    color="primary"
					onClick={() => this.setState({ isModalOpen: true })}
                 >
					 <RemoveRedEyeOutlined />
                </IconButton>
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-9`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                {data.status !== "rejected" && (
                    <Tooltip title="Edit Document">
                        <IconButton
                            onClick={() => {
                                this.setState({ isEdit: true });
                            }}
                            size="small"
                            color="primary"
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                )}
            </DLTableColumn>,
            <DLTableColumn
                key={`EmployeeDocument-${data.id}-Column-10`}
                isCompact={true}
                fillWidth={35}
                align={"center"}
            >
                {data.status === "pending" && (
                    <>
                        <Tooltip title="Delete document">
                            <IconButton
                                onClick={() =>
                                    this.setState({ isDelete: true })
                                }
                                size="small"
                                color="secondary"
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>
                        <ConfirmModal
                            title="Delete document"
                            isOpen={this.state.isDelete}
                            message={`Are you sure you want to Delete document ${data.name}?`}
                            onClickCancel={() => {
                                this.setState({ isDelete: false });
                            }}
                            onClickConfirm={this.delete}
                        />
                    </>
                )}
            </DLTableColumn>,
            this.state.isModalOpen && (
                <DocumentModal
                    employeeDocument={true}
                    isOpen={this.state.isModalOpen}
                    onClose={() => this.setState({ isModalOpen: false })}
                    data={data}
                />
            ),
            this.state.isRequest && (
                <DocumentRequestModal
                    employeeDocument={true}
                    isOpen={this.state.isRequest}
                    onClose={() => this.setState({ isRequest: false })}
                    data={data}
                    onUpdate={this.props.reload}
                />
            ),
            this.state.isEdit && (
                <AddDocumentModal
                    employeeDocument={true}
                    title={"Add document"}
                    employee={this.props.employee}
                    data={data}
                    onClose={() => this.setState({ isEdit: false })}
                    onUpdate={this.props.reload}
                />
            ),
            !!this.state.isConfirmModalOpen && (
                <ConfirmModal
                    isOpen={!!this.state.isConfirmModalOpen}
                    title={
                        this.state.isConfirmModalOpen === "approve"
                            ? "Approve Request"
                            : "Reject Request"
                    }
                    message={`Are you sure you want to ${this.state.isConfirmModalOpen} this request`}
                    onClose={() => this.setState({ isConfirmModalOpen: false })}
                    onClickCancel={() =>
                        this.setState({ isConfirmModalOpen: false })
                    }
                    onClickConfirm={this.onConfirm}
                />
            ),
        ];
    }
}

DocumentTableRow.Columns = [
    {
        key: "name",
        label: "Name",
        align: "left",
        isSortable: false,
        withSeparator: true,
    },
    {
        key: "category",
        label: "Category",
        align: "left",
        withSeparator: false,
    },
    {
        key: "for_office_only",
        label: "Only for Office",
        align: "left",
        withSeparator: false,
    },
    {
        key: "updated_at",
        label: "Last Updated",
        align: "left",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "status",
        label: "Status",
        align: "left",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "",
        label: "",
        align: "center",
        isSortable: false,
        colSpan: 6,
        isCompact: true,
        withSeparator: false,
    },
];

export default withRouter(withStyles(styles)(DocumentTableRow));
