export default theme => ({

    documentContainer: {
        alignSelf: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    documentPage: {
        boxShadow: theme.shadows[2],
        borderRadius: 5,
        overflow: "hidden",
        maxWidth: "100%",
        height: "auto !important"
    },

    noPreview: {
        padding: 100
    },

    buttonIcon: {
        marginRight: theme.spacing(1)
    }
    
});