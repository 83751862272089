import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Switch, Route } from "react-router-dom";
import { Card, Grid, CardActions } from "@material-ui/core";
import { DLTable, InfographicMessage, BulkAction } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import AttendanceCorrectionModal from "@/components/Modals/AttendanceCorrectionModal";
import Filter from "@/components/Filter";
import CorrectionRequestTableRow from "./CorrectionRequestTableRow";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { AuthContext } from "@/contexts";
import _ from "lodash";
import styles from "./style";
import FilterData from "./CorrectionRequestFilterData";

class CorrectionRequestList extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();

		this.state = {
			filters: null,
			fields: FilterData
		};
	}

	reload = () => {
		this.setState({ isModalOpen: false });
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	};

	search = filters => { 
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			!!oldFilters && this.reload();
		});
	};

	async setAttendanceStatus(selected, status) {
		this.props.setLoader(true);
		try {
			await Api.attendance_correction.update_all.patch({ ids: selected.map(e => e.id), status: status });
			this.props.setSnackbar(true, status
				? `${selected.length} attendance ${status} successfully`
				: `${selected.length} attendance ${status} successfully`
			);
			
		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
				.on(403, response => this.props.setSnackbar(true, response.message || "Something went wrong, please try again later", "error"))
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	getItems() {
		let items = [];
		items.push({
			name: "Approve Attendance Correction",
			action: (_, action) => action.setState({ enableAccountAccessModal: true }),
			render: (selected, action) => (
				action.state.enableAccountAccessModal &&
				<ConfirmModal
					isOpen = {action.state.enableAccountAccessModal}
					title = {"Approve Attendance Correction"}
					message = {`Are you sure to approve attendance correction for ${selected.length} employee${selected.length == 1 ? '' : 's'}?`}
					onClickCancel = {() => action.setState({ enableAccountAccessModal: false, activeOption: null })}
					onClickConfirm = {async () => {
						await this.setAttendanceStatus(selected, "approved");
						action.setState({ enableAccountAccessModal: false, activeOption: null });
					}}
				/>
			)
		});

		items.push({
			name: "Reject Attendance Correction",
			action: (_, action) => action.setState({ disableAccountAccessModal: true }),
			render: (selected, action) => (
				action.state.disableAccountAccessModal &&
				<ConfirmModal
					isOpen = {action.state.disableAccountAccessModal}
					title = {"Reject Attendance Request"}
					message = {`Are you sure to reject attendance correction for ${selected.length} employee${selected.length == 1 ? '' : 's'}?`}
					onClickCancel = {() => action.setState({ disableAccountAccessModal: false, activeOption: null })}
					onClickConfirm = {async () => {
						await this.setAttendanceStatus(selected, "rejected");
						action.setState({ disableAccountAccessModal: false, activeOption: null });
					}}
				/>
			)
		});
		return items;
	}

	emptyView() {	
		return (
			<InfographicMessage
				illustration="attendanceRequest"
				message="You don't have any request yet"
			/>
		);
	}

	render() {
		const { filters: filters, fields: fields } = this.state;
		return (
			<>
				<Card raised >
					<Grid container justifyContent={"space-between"}>
						<CardActions>
							{/* <Link to = {{ pathname: `/attendance/correction/add` }} style = {{ textDecoration: 'none', color: "black" }}>
								<Button
									variant="outlined"
									size="medium"
									color="primary">
									<Icon>add</Icon>
									Add New
								</Button>
							</Link> */}
							<BulkAction
								ref={this.bulkAction}
								options={this.getItems()}
							/>
						</CardActions>
						<CardActions>
							<Filter
								fields={fields}
								filters={
									filters || [{
										field: 'status',
										method: 'is',
										value: 'pending',
										operator: 'and'
									}]
								}
								withQueryParams
								onUpdate={this.search}
							/>
						</CardActions>
					</Grid>
					{
						!!filters &&
						<DLTable
							ref={this.tableRef}
							api={Api.attendance_correction}
							params={{ filter: filters }}
							bulkAction={this.bulkAction}
							RowTemplate={CorrectionRequestTableRow}
							emptyView={this.emptyView(true)}
							enableSelection
							dataCheck
						/>
					}
				</Card>
				<Switch>
					<Route path="/attendance/correction/add">
						<AttendanceCorrectionModal
							backRoute={"/attendance/correction"}
							onUpdate={this.reload}
						/>
					</Route>
					<Route path="/attendance/correction/:id">
						<AttendanceCorrectionModal
							backRoute={"/attendance/correction"}
							title={"Edit Correction Request"}
							onUpdate={this.reload}
						/>
					</Route>
				</Switch>
			</>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(
	connect(null, mapDispatchToProps)(withStyles(styles)(CorrectionRequestList))
);