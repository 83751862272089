import React from "react";
import { withRouter } from "react-router-dom";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn } from "@/components";
import moment from "moment";

class LeaveStatementRow extends DLTableRow {

    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                key={`LeaveStatement-${data.id}-Column-1`}
                withSeparator={true}>
                {moment(data.date).format("DD-MM-YYYY")}
            </DLTableColumn>,
            <DLTableColumn
                key={`LeaveStatement-${data.id}-Column-2`}
                withSeparator={true}>
                {data.description}
            </DLTableColumn>,
            <DLTableColumn
                key={`LeaveStatement-${data.id}-Column-3`}
                align="right">
                {data.quantity > 0 ? data.quantity : "-"}
            </DLTableColumn>,
            <DLTableColumn
                key={`LeaveStatement-${data.id}-Column-4`}
                align="right">
                {data.quantity < 0 ? (data.quantity * -1) : "-"}
            </DLTableColumn>,
            <DLTableColumn
                key={`LeaveStatement-${data.id}-Column-5`}
                align="right">
                {data.balance || 0}
            </DLTableColumn>
        ];
    }

}

LeaveStatementRow.Columns = [
    {
        key: "date",
        label: "Date",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "description",
        label: "Description",
        align: "left",
        isSortable: false,
        withSeparator: true
    },
    {
        key: null,
        label: "Earned",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: null,
        label: "Deducted",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: null,
        label: "Running Balance",
        align: "right",
        isSortable: false,
        withSeparator: false
    }
];

export default withRouter(LeaveStatementRow);