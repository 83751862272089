import moment from "moment";
import styles from "./style";
import { Api } from "@/helpers";
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PayrollLeaveDataRow from "./PayrollLeaveDataRow";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import { BackButton, DLTable, Page, InfographicMessage, DateRange } from "@/components";
import { setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";

class PayrollLeaveData extends Component {

	_table = null;
	
	constructor(props) {
		super(props);
		this.state = {
			from: null,
			to: null,
			anchorEl: null,
			department: null,
			designation: null,
			branch: null
		};
	}

	export = () => {
		const { from, to, department, designation, branch } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.payroll_leave_data.export.getUrl({
			from: moment(from).format("YYYY-MM-DD"),
			to: moment(to).format("YYYY-MM-DD"),
			department: department || undefined,
			designation: designation || undefined,
			branch: branch || undefined,
		}))
	};

	reload = () => {
		this._table && this._table.fetchData();
		this.props.setLoader(false);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="PayrollLeave"
				message="No records available for selected filters"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, to, department, designation, branch } = this.state;
		return (
			<Page title="Leave Data For Payroll">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<Grid container spacing={1}>
							<Grid item xs="auto">
								<DateRange
									onChange={(from, to) => this.setState({ from, to }, this.reload)} 
									from={from || moment().startOf("month")}
									to={to || moment().endOf("month")}
									isMonth={true}
								/>
							</Grid>
							<Grid item xs="auto">
								<BranchDropdown
									textFieldProps={{ margin: "dense" }}
									value={branch}
									onChange={branch => this.setState({ branch }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DepartmentDropdown
									textFieldProps={{ margin: "dense" }}
									value={department}
									onChange={department => this.setState({ department }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DesignationDropdown
									textFieldProps={{ margin: "dense", size:"small" }}
									value={designation}
									onChange={designation => this.setState({ designation }, this.reload)}
								/>
							</Grid>
						</Grid> 
						<Button
							size="medium"
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
					</CardActions>
					<DLTable
						ref={ref => this._table = ref}
						api={from & to ? Api.reports.payroll_leave_data : null}
						params={{
							from: moment(from).format("YYYY-MM-DD"),
							to: moment(to).format("YYYY-MM-DD"),
							department: department || undefined,
							designation: designation || undefined,
							branch: branch || undefined,
							per_page: 50
						}}
						setLoader={this.props.setLoader}
						RowTemplate={PayrollLeaveDataRow}
						emptyView={this.emptyView()}
					/>
				</Card>
			  
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PayrollLeaveData)));
