import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, Icon, Grid, Badge, Link as ALink, MenuItem } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { DLTable, InfographicMessage, Page, UserRole, BulkAction } from "@/components";
import EmployeePayItemModal from "@/components/Modals/EmployeePayItemModal";
import AssignShiftModal from "@/components/Modals/ShiftsModal";
import EmployeeLeaveModal from "@/components/Modals/EmployeeLeaveModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import ExitEmployeeModal from "@/components/Modals/ExitEmployeeModal";
import { Link } from "react-router-dom";
import { AuthContext } from "@/contexts";
import { Api, HttpErrorHandler, AccessManager } from "@/helpers/";
import Modules from "@/config/Modules";
import { Permissions } from "@/config/Permissions";
import { setSnackbar, setLoader, subtractActiveEmployees } from "@/redux/actions/general";
import EmployeeTableRow from "./EmployeeTableRow";
import FilterData from "./FilterData"
import styles from "./style";
import _ from "lodash";
import Filter from "@/components/Filter";

class Employee extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();

		this.state = {
			isFilterOpen: false,
			items: [],
			// filter: [{
			// 	operator: "and",
			// 	field: "status",
			// 	method: "is",
			// 	value: "active"
			// }],
			filters: null,
			fields: FilterData
		};
		this.getEmployee();
	}

	componentDidMount() {
		this.meCallForEmployeeLimit();
		this.addToFilter();
	}

	meCallForEmployeeLimit = async () => {
		try {
			const response = await Api.me.post();
			this.setState({
				isEmployeeLimit: response.organization.current_purchase.employee_limit_full,
				isSuperOrganization:response.organization.is_super_organization
			})
		} catch (e) {
		}
	}

	addToFilter = async () => {
		AccessManager.isModuleAccess(Modules.BRANCH, this.context.organization) && await this.getBranches();
		// await this.getDepartments();
		// await this.getDesignations();
	}

	addEmployee = () => {
		this.props.history.push("/employees/add");
	};

	emptyView() {
		var {isEmployeeLimit} = this.state
		return (
			<InfographicMessage
				illustration="employee"
				message={isEmployeeLimit===false && "You don't have any employee yet, add first employee now!"}
				actionLabel={isEmployeeLimit===false && "Add Employee"}
				actionIcon={isEmployeeLimit===false && "add"}
				actionHandler={this.addEmployee}
			/>
		);
	}

	mapDataForFilter = (data) => {
		return _.map(data, (item) => {
			return {
				label: item.name,
				key: item.id
			}
		});
	};

	getBranches = async () => {
		const { fields } = this.state;
		try {
			const branches = await Api.organizations(this.context.user.organization_id).branches.get();
			this.setState({
				filterFields: fields.concat([{
					key: "branch.id",
					label: "Branch",
					type: "selection",
					options: this.mapDataForFilter(branches.data)
				}])
			});
		} catch (e) { }
	};

	getEmployee = async (filters) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			if (!!oldFilters && this.tableRef && this.tableRef.current) {
				this.tableRef.current.fetchData();
			}
		});
	};

	async setGeofencingStatus(selected, active) {
		this.props.setLoader(true);
		try {
			await Api.employees.update_bulk_geofencing.patch({ employee_ids: selected.map(e => e.id), is_geofencing: active });
            this.props.setSnackbar(true, `Geo-fencing ${active ? 'enabled' : 'disabled'} for ${selected.length} employee${selected.length == 1 ? '' : 's'}`);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
				.on(403, response => this.props.setSnackbar(true, response.message || "Something went wrong, please try again later", "error"))
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	}

	async setAccountStatus(selected, active) {
		const { user } = this.context;
		this.props.setLoader(true);

		const filterUsers = selected.filter(function (selected) {
			return selected.id !== user.id ;
		});

		try {
			await Api.updateActive.patch({ employee_ids: filterUsers.map(e => e.id), is_active: active });
			this.props.setSnackbar(true, active
				? `${filterUsers.length} accounts activated successfully`
				: `${filterUsers.length} accounts de-activated successfully`
			);
			
		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
				.on(403, response => this.props.setSnackbar(true, response.message || "Something went wrong, please try again later", "error"))
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	}

	getItems() {
		let items = [];

		if (AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization)) {
			items.push({
				name: "Set Pay Item",
				action: (_, action) => action.setState({ bulkAddPayItem: true }),
				render: (selected, action) => (
					action.state.bulkAddPayItem &&
					<EmployeePayItemModal
						bulkMode
						isOpen={action.state.bulkAddPayItem}
						employees={selected}
						title="Set Pay Item"
						onClose={() => action.setState({ bulkAddPayItem: false, activeOption: null })}
					/>
				)
			});
		}
		
		items.push({
			name: "Assign Leave",
			action: (_, action) => action.setState({ bulkAddLeave: true }),
			render: (selected, action) => (
				action.state.bulkAddLeave &&
				<EmployeeLeaveModal
					bulkMode
					isOpen={action.state.bulkAddLeave}
					employees={selected}
					title="Assign Leave"
					onClose={() => action.setState({ bulkAddLeave: false, activeOption: null })}
				/>
			)
		});

		items.push({
			name: "Assign Shift",
			action: (_, action) => action.setState({ assignShiftModal: true }),
			render: (selected, action) => (
				action.state.assignShiftModal &&
				<AssignShiftModal
					open={action.state.assignShiftModal}
                    data={selected}
                    mode={"bulk"}
                    onClose={() => action.setState({ assignShiftModal: false, activeOption: null })}
                />
			)
		});

		items.push({
			name: "Enable Geo-fencing",
			action: (_, action) => action.setState({ enableGeofencingModal: true }),
			render: (selected, action) => (
				action.state.enableGeofencingModal &&
				<ConfirmModal
					isOpen={action.state.enableGeofencingModal}
					title={"Enable Geo-fencing"}
					message={`Are you sure you want to enable geo-fencing for ${selected.length} employees${selected.length == 1 ? '' : 's'}?`}
					onClickCancel={() => action.setState({ enableGeofencingModal: false, activeOption: null })}
					onClickConfirm={() => {
						this.setGeofencingStatus(selected, true);
						action.setState({ enableGeofencingModal: false, activeOption: null });
					}}
				/>
			)
		});

		items.push({
			name: "Disable Geo-fencing",
			action: (_, action) => action.setState({ disableGeofencingModal: true }),
			render: (selected, action) => (
				action.state.disableGeofencingModal &&
				<ConfirmModal
					isOpen={action.state.disableGeofencingModal}
					title={"Disable Geo-fencing"}
					message={`Are you sure you want to disable geo-fencing for ${selected.length} employees${selected.length == 1 ? '' : 's'}?`}
					onClickCancel={() => action.setState({ disableGeofencingModal: false, activeOption: null })}
					onClickConfirm={() => {
						this.setGeofencingStatus(selected, false);
						action.setState({ disableGeofencingModal: false, activeOption: null });
					}}
				/>
			)
		});

		items.push({
			name: "Enable Account Access",
			action: (_, action) => action.setState({ enableAccountAccessModal: true }),
			render: (selected, action) => {
				const employees = selected.filter(e => e.id != this.context.user.id);
				return (
					action.state.enableAccountAccessModal &&
					<ConfirmModal
						isOpen={action.state.enableAccountAccessModal}
						title={"Enable Account Access"}
						message={`Are you sure to enable account access for ${employees.length} employee${employees.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableAccountAccessModal: false, activeOption: null })}
						onClickConfirm={() => {
							this.setAccountStatus(employees, true);
							action.setState({ enableAccountAccessModal: false, activeOption: null });
						}}
					/>
				);
			}
		});

		items.push({
			name: "Disable Account Access",
			action: (_, action) => action.setState({ disableAccountAccessModal: true }),
			render: (selected, action) => {
				const employees = selected.filter(e => e.id != this.context.user.id);
				return (
					action.state.disableAccountAccessModal &&
					<ConfirmModal
						isOpen={action.state.disableAccountAccessModal}
						title={"Disable Account Access"}
						message={`Are you sure to disable account access for ${employees.length} employee${employees.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ disableAccountAccessModal: false, activeOption: null })}
						onClickConfirm={() => {
							this.setAccountStatus(employees, false);
							action.setState({ disableAccountAccessModal: false, activeOption: null });
						}}
					/>
				);
			}
		});
		
		items.push({
			name: "Exit Employee",
			action: (_, action) => action.setState({ exitEmployeeModal: true }),
			render: (selected, action) => (
				action.state.exitEmployeeModal &&
				<ExitEmployeeModal
					open={action.state.exitEmployeeModal}
					data={selected.filter(e => e.id != this.context.user.id && !e.is_archived)}
					onSave={data => {
						this.props.subtractActiveEmployees(data.length);
						this.tableRef.current && this.tableRef.current.fetchData();
					}}
					onClose={() => action.setState({ exitEmployeeModal: false, activeOption: null })}
				/>
			)
		});
		
		return items;
	}

	onSelectionChange = (selected) => {
		const { user } = this.context;
		this.actionbarRef.current.setSelected(
			selected.filter(
				({ is_archived, id }) => !is_archived && user.id !== id
			)
		);
	};

	render() {
		const { filters, fields } = this.state;
		return (
			<Page title="Employees">
				<Card raised>
					<Grid container justifyContent={"space-between"}>
						<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
							<CardActions>
								<Link to={{ pathname: `/employees/add` }} style={{ textDecoration: 'none', color: "primary" }}>
									<Button
										variant="outlined"
										size="medium"
										color="primary"
										onClick={this.addEmployee}>

										<Icon>add</Icon>
										Add Employee
									</Button>
								</Link>
								<BulkAction
									ref={this.bulkAction}
									options={this.getItems()}
								/>
							</CardActions>
							<CardActions>
								<Filter
									fields={fields}
									filters={filters || [{ operator: "and", field: "status", method: "is", value: "active" }]}
									withQueryParams
									onUpdate={this.getEmployee}
								/>
							</CardActions>
						</UserRole>
					</Grid>
					{
						!!filters &&
						<DLTable
							enableSelection
							ref={this.tableRef}
							api={Api.employees}
							params={{ filter: filters }}
							emptyView={this.emptyView()}
							RowTemplate={EmployeeTableRow}
							bulkAction={this.bulkAction}
						/>
					}
				</Card>
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
	subtractActiveEmployees: (...args) => dispatch(subtractActiveEmployees(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Employee));