import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, Chip, Icon, IconButton } from "@material-ui/core";
import { RemoveRedEyeOutlined, DeleteOutlined } from "@material-ui/icons";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn, Money } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import styles from "./style";
import ModuleManager from "@/components/ModuleManager";
import Modules from "@/config/Modules";
import moment from "moment";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import { AuthContext } from "@/contexts";
import _ from "lodash";
class PayrunTableRow extends DLTableRow {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            deleting: false
        }
    }

    confirmationMessage() {
        const { data, classes } = this.props;
        return (
            <span>
                Are you sure you want to delete payrun for {moment(data.to).format("MMMM Y")}
                <Tooltip title={`${moment(data.from).format("DD/MM/Y")} to ${moment(data.to).format("DD/MM/Y")}`} placement={"top"}>
                    <Icon className={classes.infoIcon}>info</Icon>
                </Tooltip>?
            </span>
        );
    }

    deletePayrun() {
        return () => {
            const { data } = this.props;
            Api.payruns(data.id).delete().then(() => {
                store.dispatch(setSnackbar(true, `Payrun for ${moment(data.to).format("MMMM Y")} deleted successfully`));
                this.props.reload();

            }).catch(e => {
                HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        store.dispatch(setSnackbar(true, errors[0], "error"));
                    } else {
                        store.dispatch(setSnackbar(true, "Something went wrong, please try again", "error"))
                    }

                })
                .on(403, response => store.dispatch(setSnackbar(true, response.data.message, 'error')))
                .default(_ => store.dispatch(setSnackbar(true, "Something went wrong, please try again", "error")))
                .go();
            });

            this.setState({ deleting: false });
        };
    }

    isSelectable = () => {
        const { data: { is_locked, is_approved } = {} } = this.props;
        return !is_locked && !is_approved;
    };

    renderCells() {
        const { data, classes } = this.props;
        return [
            <DLTableColumn
                key={`Payrun-${data.id}-Column-1`}
                withSeparator>
                {data.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-2`}
                withSeparator>
                {moment(data.from).format("MMM DD, Y") + " - " + moment(data.to).format("MMM DD, Y")}
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-3`}
                align={"right"}>
                {data.total_employees}
            </DLTableColumn>,
            <ModuleManager
                modulePermissions={[Modules.TAX,
                ]}>
                <DLTableColumn
                    key={`Payrun-${data.id}-Column-4`}
                    align={"right"}>
                    <Money amount={data.employee_payment}/>
                </DLTableColumn></ModuleManager>,
            <ModuleManager
                modulePermissions={[Modules.TAX,
                ]}>
                <DLTableColumn
                    key={`Payrun-${data.id}-Column-5`}
                    align={"right"}>
                    <Money amount={data.taxes}/>
                </DLTableColumn>
            </ModuleManager>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-6`}
                align={"right"}>
                <Money amount={data.total_payment}/>
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-7`}>
                <Chip size="small" label={data.is_draft ? "DRAFT" : (data.is_paid ? "PAID" : "UNPAID")} color={data.is_draft ? "default" : (data.is_paid ? "primary" : "secondary")} />
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-8`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                <Tooltip title="View Payroll">
                    <Link to={{ pathname: `/payroll/${data.id}` }} style={{ textDecoration: 'none', color: "primary" }}>
                        <IconButton
                            size="small"
                            color="primary"
                        >
                            <RemoveRedEyeOutlined />
                        </IconButton>
                    </Link>
                </Tooltip>
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-9`}
                isCompact={true}
                align={"center"}
                fillWidth={35}
            >
                {
                    !data.is_locked && !data.is_approved &&
                    <UserRole routePermissions={[Permissions.PAYROLL_FULL_ACCESS]}>
                        <Tooltip title="Delete Payroll">
                            <IconButton
                                onClick={() => this.setState({ deleting: true })}
                                size="small"
                                color="secondary"
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>

                        <ConfirmModal
                            title="Delete Payrun"
                            isOpen={this.state.deleting}
                            message={this.confirmationMessage()}
                            onClickCancel={() => {
                                this.setState({ deleting: false });
                            }}
                            onClickConfirm={this.deletePayrun()} />
                    </UserRole>
                }
            </DLTableColumn>
        ]
    }

}

PayrunTableRow.Columns = [
    {
        key: "name",
        label: "name",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "to",
        label: "Period",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "total_employees",
        label: "Employees",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "employee_payment",
        label: "Employee Payment",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "taxes",
        label: "Taxes",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "total_payment",
        label: "Total Payment",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "status",
        label: "Status",
        align: "left",
        isSortable: false,
        withSeparator: false
    },
    {
        key: "action",
        label: null,
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
]


export default withRouter(withStyles(styles)(PayrunTableRow));