import { blue } from "@material-ui/core/colors";

const style = theme => ({

    container: {
        marginRight: '-6px'
    },

    image: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        cursor: 'pointer',
        background: blue['50'],
        color: blue['500'],
        fontSize: '0.7em'
    },

    fallbackIcon: {
        width: '0.7em',
        height: '0.7em'
    },
    
    extraImages: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        cursor: 'pointer',
        fontSize: '0.7em',
        marginLeft: '5px',
        background: blue['50'],
        color: blue['500']
    }

});

export default style;