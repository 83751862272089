import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
    Tooltip,
    IconButton,
    Icon,
    Menu,
    MenuItem,
    Chip,
} from "@material-ui/core";
import {   ArrowForward } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, UserRole, Money } from "@/components";
import AddSuggestionModal from "../../components/Modals/AddSuggestionModal";
import store from "@/redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import moment from "moment";
import { Constants } from "@/config";
import Theme from "@/themeStyles";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Permissions } from "@/config/Permissions";

class LoanTableRow extends DLTableRow {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isApproving: false,
            isRejecting: false,
            isEditing: false,
            isDeleting: false,
        };
    }

    deleteLoan = async (id) => {
        this.setState({ isDeleting: false });
        store.dispatch(setLoader(true));
        try {
            store.dispatch(setLoader(true));
            await Api.loan_requests( this.props.match.params.id ||
                this.props.match.params.loansId).loan_suggestion(id).delete();
            store.dispatch(setSnackbar(true, `Loan deleted successfully`));
            this.props.reload();
        } catch (e) {
            console.error(e);
            store.dispatch(
                setSnackbar(
                    true,
                    "Something went wrong, please try again later",
                    "error"
                )
            );
        }
        store.dispatch(setLoader(false));
    };

    updateStatus = async (data, status) => {
        try {
            store.dispatch(setLoader(true));
            try {
                await Api.loan_request(data.id).put({ status });
                if (status === Constants.Reimbursable.FLAG_REJECTED) {
                    store.dispatch(
                        setSnackbar(
                            true,
                            `Loan rejected for ${data.employee.first_name}`,
                            "error"
                        )
                    );
                } else {
                    store.dispatch(
                        setSnackbar(
                            true,
                            `Loan approved for ${data.employee.first_name}`
                        )
                    );
                }
                this.setState({ isModalOpen: false });
                this.props.reload();
            } catch (e) {
                console.error(e);
                store.dispatch(
                    setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                );
            }
        } catch (e) {}
        store.dispatch(setLoader(false));
    };

    returnStatusColor(status) {
        switch (status) {
            case Constants.Loan.FLAG_PENDING:
                return Theme.palette.tableBorder.main;
            case Constants.Loan.FLAG_APPROVED:
                return Theme.palette.success.main;
            case Constants.Loan.FLAG_REJECTED:
            default:
                break;
        }
    }

    renderCells() {
        const { data } = this.props;
        const { isEditing } = this.state;
        const { isDeleting } = this.state;
        return [
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-1`}
                align={"left"}
            >
                {moment(data.request_date).format("DD MMM, YYYY")}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-2`}
                align={"left"}
            >
                {data.loan_amount === data.previous_loan_amount ? (
                    <Money amount={data.loan_amount} />
                ) : (
                    <span>
                        {" "}
                        <span style={{ textDecoration: "line-through" }}>
                            <Money amount={data.previous_loan_amount} />
                        </span> 
                        {" "}<ArrowForward style={{height:12,width:12, marginTop:5}}/> <Money amount={data.loan_amount} />{" "}
                    </span>
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-3`}
                align={"left"}
            >
                {data.duration == data.previous_duration &&
                data.duration_type == data.previous_duration_type ? (
                    `${data.duration} ${data.duration_type}`
                ) : (
                    <span>
                        {" "}
                        <span style={{ textDecoration: "line-through" }}>
                            
                            {data.previous_duration}{" "}
                            {data.previous_duration_type} 
                        </span> 
                        {" "}<ArrowForward style={{height:12,width:12,marginTop:5}}/> {data.duration} {data.duration_type}
                    </span>
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-4`}
                align={"left"}
            >
                {data.cycle == data.previous_cycle &&
                data.cycle_type == data.previous_cycle_type ? (
                    `${data.cycle} ${data.cycle_type}`
                ) : (
                    <span>
                        {" "}
                        <span style={{ textDecoration: "line-through" }}>
                            {" "}
                            {data.previous_cycle} {data.previous_cycle_type}
                        </span>{" "}
                        <ArrowForward style={{height:12,width:12,marginTop:5}}/> {data.cycle} {data.cycle_type}{" "}
                    </span>
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-5`}
                align={"left"}
            >
                {data.return_amount === data.previous_return_amount ? (
                    <Money amount={data.return_amount} />
                ) : (
                    <span>
                        {" "}
                        <span style={{ textDecoration: "line-through" }}>
                            <Money amount={data.previous_return_amount} />
                        </span> 
                        {" "}<ArrowForward style={{height:12,width:12 ,marginTop:5}}/> <Money amount={data.return_amount} />{" "}
                    </span>
                )}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-10`}
                align={"left"}
            >
                <Chip
                    size="small"
                    label={data.status.toUpperCase()}
                    color={"primary"}
                    style={{
                        backgroundColor: this.returnStatusColor(data.status),
                        color: Theme.color.white.main,
                    }}
                />
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Loan-${data.id}-Column-11`}
                align={"left"}
            >
                <UserRole
                    routePermissions={[
                        Permissions.ROLE_VIEWABLE,
                        Permissions.ROLE_EDITABLE,
                        Permissions.ROLE_FULL_ACCESS,
                    ]}
                >
                    {data.status === Constants.Reimbursable.FLAG_PENDING && (
                        <Tooltip title="More Options">
                            <IconButton
                                onClick={(e) =>
                                    this.setState({ anchorEl: e.currentTarget })
                                }
                                size="small"
                            >
                                <Icon>more_vert</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                </UserRole>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                    PaperProps={{
                        style: {
                            minWidth: 200,
                        },
                    }}
                >
                    <MenuItem
                        onClick={() =>
                            this.setState({ isEditing: true, anchorEl: null })
                        }
                    >
                        {"Edit"}
                    </MenuItem>
                    {data.status === Constants.Reimbursable.FLAG_PENDING && (
                        <>
                            <MenuItem
                                onClick={() =>
                                    this.setState({
                                        anchorEl: null,
                                        isDeleting: true,
                                    })
                                }
                            >
                                Delete
                            </MenuItem>
                        </>
                    )}
                </Menu>
            </DLTableColumn>,
            isDeleting && (
                <ConfirmModal
                    title="Delete Loan"
                    isOpen={isDeleting}
                    message={`Are you sure want to delete this loan suggestion?`}
                    onClickCancel={() => this.setState({ isDeleting: false })}
                    onClickConfirm={() => {
                        this.deleteLoan(data.id);
                    }}
                />
            ),
            isEditing && data.status === Constants.Reimbursable.FLAG_PENDING && (
                <AddSuggestionModal
                    open={this.state.isEditing}
                    onClose={() => this.setState({ isEditing: false })}
                    title="Add Suggestion "
                    data={data}
                    loanId={
                        this.props.match.params.id ||
                        this.props.match.params.loansId
                    }
                    onUpdate={() => {
                        this.props.reload();
                    }}
                ></AddSuggestionModal>
            ),
        ];
    }
}

LoanTableRow.Columns = [
    {
        key: "created_at",
        label: "Suggested Date",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "loan_amount",
        label: "Loan Amount",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "duration",
        label: "Duration",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "cycle",
        label: "Cycle",
        align: "left",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "return_amount",
        label: "Return Amount",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "status",
        label: "Status ",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "action",
        label: "",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
];

export default withRouter(withStyles(styles)(LoanTableRow));
