import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, Grid, InputAdornment, TextField } from "@material-ui/core";
import { FormattedNumericInput } from "@/components";
import PictureViewModal from "@/components/Modals/PictureViewModal";
import Api from "@/helpers/Api.js";
import Config from "@/config";
import styles from "./style.js";
import _ from "lodash";
import { object } from "prop-types";
import { BrokenImage } from "@material-ui/icons";

class ReimbursementTextField extends Component {
    static propTypes = {
        payItem: object.isRequired
    }
    
    state = {
        imageIndex: 0,
        isPictureViewModalOpen: false
    };
    
    viewPicture = (imageIndex) => {
        this.setState({
            isPictureViewModalOpen: true,
            imageIndex
        });
    };

    images() {
        const { payItem } = this.props;
        const images = payItem.transaction.reimbursable_images;
        return images.map(image => Api.reimbursable(payItem.transaction.id).image.getUrl({ image: image.id }));
    }

    renderImages() {
        const { classes } = this.props;
        const images = this.images();
        return (
            !!images.length &&
            <Grid container alignItems="center" justifyContent="flex-end" spacing={0.5} className={classes.container}>
                <Grid sm="auto" item>
                    <Avatar
                        variant="rounded"
                        className={classes.image}
                        src={images[0]}
                        onClick={() => this.viewPicture(0)}>
                        <BrokenImage className={classes.fallbackIcon}/>
                    </Avatar>
                </Grid>
                {
                    (images.length > 1) &&
                    <Grid sm="auto" item>
                        <Avatar
                            variant="rounded"
                            className={classes.extraImages}
                            onClick={() => this.viewPicture(1)}>
                            +{images.length - 1}
                        </Avatar>
                    </Grid>
                }
            </Grid>
        );
    }
    
    render() {
        const { payItem } = this.props;
        const { isPictureViewModalOpen, imageIndex } = this.state;
        return (
            payItem.is_reimbursable &&
            <>
                <TextField
                    label={payItem.details.name}
                    variant="outlined"
                    size={"small"}
                    value={payItem.transaction.approved_amount || 0}
                    FormHelperTextProps={{ variant: "standard" }}
                    fullWidth
                    disabled
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">{Config.CURRENCY_SYMBOL}</InputAdornment>),
                        endAdornment: this.renderImages(),
                        inputComponent: FormattedNumericInput
                    }}
                />
                {
                    isPictureViewModalOpen && (
                    <PictureViewModal
                        images={this.images()}
                        defaultIndex={imageIndex}
                        onClose={() => this.setState({ isPictureViewModalOpen: false })}
                    />
                )}
            </>
        );
    }
}

export default withStyles(styles)(ReimbursementTextField);
