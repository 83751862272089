import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Card, Grid, Typography, Tooltip, Icon, Link } from "@material-ui/core";
import { Warning as WarningIcon } from '@material-ui/icons';
import { Page, Money, ModuleManager } from "@/components";
import ReminderWidget from "./ReminderWidget";
import PayrunChart from "./PayrunChart";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api } from "@/helpers";
import styles from "./style";
import Ticker from 'react-ticker';
import { Modules } from "../../config";
import { AuthContext } from "@/contexts";
import Alert from '@material-ui/lab/Alert';

class Dashboard extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			stats: {
				attendance: 0,
				activeEmployees: 0,
				employeeRetention: 0,
				averageSalary: 0,
				genderRatio: {
					male: 0,
					female: 0
				},
				lastPayrollAmount: 0,
				totalPayrun: 0,
				averagePayrunAmount: 0,
				payrunData: [],
			},
			reminders: {
				birthdays: [],
				jobAnniversaries: [],
				endingProbations: [],
				duePayItems: []
			},
			ticker: !!(window.innerWidth < 1250 && window.innerWidth > 960),
			isEmployeeLimit: false
		};

		this.getStats();
		this.getReminders();

		window.addEventListener('resize', () => {
			if (window.innerWidth < 1250 && window.innerWidth > 960) {
				this.setState({ ticker: true })
			} else {
				this.setState({ ticker: false })
			}
		})
	}

	componentDidMount() {
		this.meCallForEmployeeLimit()
	}

	meCallForEmployeeLimit = async () => {
		try {
			const response = await Api.me.post();
			this.setState({
				isEmployeeLimit: response.organization.current_purchase.employee_limit_full
			})
		} catch (e) {
		}
	}
	getStats = async () => {
		try {
			let stats = await Api.stats.get();
			this.setState({ stats });
		} catch (e) {
		}
	};

	getReminders = async () => {
		try {
			let reminders = await Api.reminders.get();
			this.setState({ reminders });
		} catch (e) {

		}
	};

	attendanceValue = (present, employee) => {
		const { classes } = this.props;
		return (
			<>
				<div className={classes.attendanceValue}>
					{present}<sup className={classes.attendanceSup}>/{employee}</sup>
				</div>
				<div className={classes.attendancePresent}>PRESENT{present !== 1 && "S"}</div>
			</>
		);
	};

	cards = (moveText = false) => {
		const { classes } = this.props;
		const { stats } = this.state;
		let boxData = [
			// {
			// 	label: "TOTAL PAYROLL RUNS",
			// 	color: "#B723A6",
			// 	value: stats.totalPayrun,
			// 	ticker: true
			// },
			{
				label: "LAST PAYROLL",
				color: "#29B364",
				value: <Money amount={stats.lastPayrollAmount} />,
				modulePermissions: [
					Modules.PAYROLL
				]
			},
			{
				label: "NEXT PAYROLL",
				color: "#EE3939",
				value: <Money amount={stats.nextEstimatedPayroll} />,
				modulePermissions: [
					Modules.PAYROLL
				]
			},
			{
				label: "AVERAGE SALARY",
				color: "#29B364",
				value: <Money amount={stats.averageSalary} />,
				modulePermissions: [
					Modules.PAYROLL
				]
			},
			{
				label: "ATTENDANCE",
				color: "#0CC4EC",
				value: this.attendanceValue(stats.attendance, stats.activeEmployees),
				modulePermissions: [
					Modules.ATTENDANCE
				]
			},
			// {
			// 	label: "ACTIVE EMPLOYEES",
			// 	color: "#6060CD",
			// 	value: stats.activeEmployees,
			// 	ticker: true
			// },
			{
				label: (
					<>
						EMPLOYEE RETENTION
						<Tooltip title="For the period of last 1 year from today" placement={"right"} className={classes.employeeRetentionIcon}>
							<Icon className={classes.infoIcon} fontSize="small">info</Icon>
						</Tooltip>
					</>
				),
				color: "#EE3939",
				value: stats.employeeRetention + "%",
				ticker: true,
				modulePermissions: [
					Modules.PAYROLL,
					Modules.ATTENDANCE,
					Modules.EMPLOYEE_HISTORY,
					Modules.ROLE_AND_PERMISSION
				]
			},
			{
				label: "MALE VS FEMALE",
				color: "#B723A6",
				value: `${stats.genderRatio.male}:${stats.genderRatio.female}`,
				modulePermissions: [
					Modules.PAYROLL,
					Modules.ATTENDANCE,
					Modules.EMPLOYEE_HISTORY,
					Modules.ROLE_AND_PERMISSION
				]
			}
		];

		return boxData.map((data, index) => {
			return (
				<ModuleManager key={index} modulePermissions={data.modulePermissions}>
					<Grid item xs={12} sm={4} className={classes.statsCardGrid}>
						<Card className={classes.card} raised>
							{
								data.ticker ? (
									<Ticker move={moveText && !!data.ticker} mode="await">
										{() => {
											return <Typography align="center" variant="caption" className={classes.cardHeading}>{data.label}</Typography>
										}}
									</Ticker>
								) : (
									<Typography align="left" variant="caption" className={classes.cardHeading}>{data.label}</Typography>
								)
							}

							<Typography align="right" className={classes.cardNumber} variant="h5" style={{ color: data.color }}>{data.value}</Typography>
						</Card>
					</Grid>
				</ModuleManager>
			);
		});
	};

	render() {
		const { classes } = this.props;
		const { stats, reminders, ticker } = this.state;

		return (
			<Page title="Dashboard">
				<Grid container spacing={2}>

					<Grid item sm={12} className={classes.hiddenSM}>
						<Grid container spacing={2}>
							{this.cards(ticker)}
						</Grid>
					</Grid>
					<Grid item xs={12} md={8}>
						<Card raised>
							<PayrunChart stats={stats} />
						</Card>
					</Grid>
					<Grid item sm={4} className={classes.hiddenSM}>
						<Card raised>
							<ReminderWidget reminders={reminders} />
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} className={classes.hiddenMD}>
						<Grid container spacing={2} className={classes.cardContainer}>
							{this.cards()}
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.hiddenMD}>
						<Card raised>
							<ReminderWidget reminders={reminders} />
						</Card>
					</Grid>
				</Grid>
			</Page>
		);
	}
}

const mapStateToProps = state => ({
	activeEmployees: state.general.activeEmployees
});

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Dashboard));