const style = theme => ({

    alertStyle:{
        marginBottom: 10,
		marginTop: 0,
        fontFamily:theme.typography.fontFamily
    }

});

export default style;