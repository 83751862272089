import moment from "moment";
import styles from "./style";
import Config from "@/config";
import { Api } from "@/helpers";
import React, { Component } from "react";
import { DatePicker } from "@material-ui/pickers";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CheckInOutStatusRow from "./CheckInOutStatusRow";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";
import { BackButton, DLTable, Page, InfographicMessage } from "@/components";
import { setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";

class CheckInOutStatus extends Component {

	_table = null;
	
	constructor(props) {
		super(props);
		this.state = {
			from: null,
			department: null,
			designation: null,
			branch: null,
			anchorEl: null
		};
	}

	export = () => {
		const { from, department, designation, branch } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.check_in_out.export.getUrl({
			date: moment(from || moment()).format("YYYY-MM-DD"),
			department: department || undefined,
			designation: designation || undefined,
			branch: branch || undefined
		}))
	};

	reload = () => {
		this._table && this._table.fetchData();
			this.props.setLoader(false);

	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="CheckStatus"
				message="No attendance found!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, department, designation, branch} = this.state;
		return (
			<Page title="Early/late check-in check-out status">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<Grid container spacing={1}>
							<Grid item xs="auto">
								<DatePicker
									fullWidth
									margin="dense"
									label="Select Date"
									maxDate={moment().format("YYYY-MM-DD")}
									inputVariant="outlined"
									format={Config.DISPLAY_DATE_FORMAT}
									value={from || moment().format(Config.SERVER_DATE_FORMAT)}
									onChange={from => this.setState({ from }, this.reload)}
								/>
							</Grid>
							<Grid item xs="auto">
								<BranchDropdown
									textFieldProps={{ margin: "dense" }}
									value={branch}
									onChange={branch => this.setState({ branch }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DepartmentDropdown
									textFieldProps={{ margin: "dense" }}
									value={department}
									onChange={department => this.setState({ department }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DesignationDropdown
									textFieldProps={{ margin: "dense", size:"small" }}
									value={designation}
									onChange={designation => this.setState({ designation }, this.reload)}
								/>
							</Grid>
						</Grid> 
						<Button 
							size="medium"
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
					</CardActions>
					<DLTable
						ref={ref => this._table = ref}
						api={Api.reports.check_in_out}
						params={{
							date: moment(from || moment()).format("YYYY-MM-DD"),
							department: department || undefined,
							designation: designation || undefined,
							branch: branch || undefined,
							per_page: 50
						}}
						setLoader={this.props.setLoader}
						RowTemplate={CheckInOutStatusRow}
						emptyView={this.emptyView()}
					/>
				</Card>
			  
			</Page>
		);
	}

}
const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(CheckInOutStatus)));
