import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { DLTableRow, DLTableColumn, Money } from "@/components";
import styles from "./style";
class ReimbursableTableRow extends DLTableRow {


    renderCells() {
        const { data } = this.props;
        const exceedAmount = data.remaining - data.approved_amount;

        return [
            <DLTableColumn
                withSeparator={true}
                key={`Reimbursable-${data.id}-Column-1`}
                align={"left"}>
                {`${data.employee.first_name} ${data.employee.last_name}`}

            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Reimbursable-${data.id}-Column-2`}
                align={"left"}>
                {data.pay_item.name}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Reimbursable-${data.id}-Column-4`}
                align={"right"}>
                <Money amount={data.remaining}/>
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Reimbursable-${data.id}-Column-3`}
                align={"right"}>
                <Money amount={data.requested_amount}/>
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Reimbursable-${data.id}-Column-5`}
                align={"right"}>
                <Money amount={exceedAmount < 0 ? exceedAmount : 0.00}/>
            </DLTableColumn>
        ]
    }
}

ReimbursableTableRow.Columns = [
    {
        key: "first_name",
        label: "Employee",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "payitem",
        label: "Reimbursement",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "remaining_amount",
        label: "Quota",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "approved_amount",
        label: "Approved Amount",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "exceedAmount",
        label: "Exceed Amount",
        align: "right",
        isSortable: true,
        withSeparator: false
    },


];

export default withRouter(withStyles(styles)(ReimbursableTableRow));

