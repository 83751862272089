const styles = theme => ({

    root: {
        marginBottom: theme.spacing(2)
    },
    
    step: {
        position: "relative",
        overflow: "unset",

        "&:not(:last-child)": {
            marginBottom: theme.spacing(6),

            "&::after": {
                content: "' '",
                display: "block",
                position: "absolute",
                top: "100%",
                left: theme.spacing(4.43),
                width: 5,
                height: theme.spacing(6),
                backgroundColor: theme.palette.primary.main
            }
        }
    },

    activeStep: {
        "& $stepNumber": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        }
    },

    stepNumber: {
        border: `2px ${theme.palette.primary.main} solid`,
        backgroundColor: "#fff",
        color: theme.palette.primary.main
    },

    stepTitle: {
        fontSize: "1.5rem"
    },

    stepSubTitle: {
        fontSize: "1rem"
    },

    stepHeaderAction: {
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        marginTop: 0,
        marginRight: 0,

        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },

    stepSummary: {
        fontWeight: "bold"
    },

    stepContent: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingLeft: theme.spacing(9.5)
    },

    stepContentFullWidth: {
        paddingLeft: theme.spacing(2)
    },

    stepContentDense: {
        paddingRight: 0,
        paddingBottom: 0,

        "&$stepContentFullWidth": {
            paddingLeft: 0
        }
    },

    stepActions: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2)
    }

});

export default styles;