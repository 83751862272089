export default theme => ({

    dialogueTextBox: {
        display: "flex", 
        alignItems: "center",
        flex: "1"
    },

    dialogueText: {
        marginLeft: theme.spacing(2),
        fontFamily: theme.typography.fontFamily 
    },
    message: {
         marginLeft: theme.spacing(3),
         fontFamily: theme.typography.fontFamily 
      },

});