import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	Tooltip
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import { connect } from "react-redux";
import { HelpOutline } from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler, Utility, AccessManager } from "@/helpers";
import { PermissionsInfo } from "@/config/Permissions";
import { AuthContext } from "@/contexts";

class RolePermissionModal extends Component {

	static contextType = AuthContext;

	static propTypes = {
		role: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onUpdate: PropTypes.func,
		onClose: PropTypes.func
	};

	static defaultProps = {
		title: "Add Role",
		onUpdate: () => { }
	};

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			add_permissions: {},
			remove_permissions: {},
			isSuperAdmin: false
		};
	}

	componentDidMount() {
		this.mapData();
		this.isSuperAdminCheck();
	}

	isSuperAdminCheck() {
		if (this.context.user.id && this.context.user.roles.name === "Super Admin")
			this.setState({ isSuperAdmin: true });
	}

	mapData = () => {
		if (!this.isNew()) {
			var add_permissions = {};
			_.map(this.props.role.permissions, (permission) => {
				_.find(PermissionsInfo, (p, key) => { 
					_.map(p, (item) => {
						if(item.value === permission.name) {
							add_permissions[key] = item.value 
						}
					})
					 
				})
			});

			this.setState({ add_permissions });
		}
	}

	role() {
		return {
			id: "",
			name: "",
			permissions: [],
			...this.props.role,
			...this.state.role
		};
	}

	isNew() {
		return !this.props.role || !this.props.role.id;
	}

	isDisabled() {
		return !this.isNew() && !this.role().is_update_allow;
	}

	handleChange(value, field) {
		const role = this.role();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			role: {
				...role,
				[field]: value
			},
			errors
		});
	}

	validate() {
		const role = this.role();
		const optionalFields = ["total_users"];
		var errors = {};

		if (this.isNew()) {
			optionalFields.push('id');
		}

		_.forOwn(role, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (!Object.keys(this.state.add_permissions).length && !_.size(errors)) {
			this.props.setSnackbar(true, "Atleast 1 permission is required", "error");
			return false;
		}
		
		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	getRoleData = () => {
		const role = this.role();
		const { add_permissions } = this.state;

		var roleData = {
			"name": role.name,
			"permissions": _.values(add_permissions)
		};

		if (!this.isNew()) {
			roleData.id = role.id;
		}

		return roleData;
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const role = this.getRoleData();
		this.props.setLoader(true);
		try {
			var _role = null;
			if (this.isNew()) {
				_role = await Api.roles.post(role);
				this.props.setSnackbar(true, "New role added successfully");

			} else {
				_role = await Api.roles(role.id).put(role);
				this.props.setSnackbar(true, "Role updated successfully");
			}
			this.props.onClose();
			this.props.onUpdate(_role);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, Utility.firstValueFromObject(response.data), "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	onClickRadio = (permission, name) => {
		const { add_permissions } = this.state;
		
		if (add_permissions[name] === permission) {
			delete add_permissions[name];
			this.setState({ add_permissions });

		} else {
			this.setState({
				add_permissions: {
					...add_permissions,
					[name]: permission
				}
			})
		}

	}

	getTooltip = (permission) => {
		const { classes } = this.props;
		return (
			<Tooltip
				placement="right"
				title={permission}>
				<HelpOutline className={classes.statusChipIcon} />
			</Tooltip>
		)
	}

	render() {
		const { isOpen, title } = this.props;
		const { errors, add_permissions, isSuperAdmin } = this.state;
		const role = this.role();
		return (
			<Dialog
				fullWidth
				maxWidth="sm"
				open={isOpen}
				onBackdropClick={this.props.onClose}
				aria-labelledby="employee-bank-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-bank-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Name"
								fullWidth
								error={!!errors.name}
								value={role.name || ""}
								onChange={e => this.handleChange(e.target.value, "name")}
								variant="outlined"
								helperText={errors.name}
								FormHelperTextProps={{ variant: "standard" }}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={{ marginTop: 8 }}>
						{
							_.map(PermissionsInfo, (permission, key) => {
								if (key === "super_organization" && !isSuperAdmin) return null;

								const isRoleAccess = AccessManager.isRoleAccess(key, this.context.organization);
								if (!isRoleAccess) return;

								return (
									<Grid item xs={12} sm={6} key={key}>
										<FormControl component="fieldset">
											<FormLabel component="legend">{_.replace(_.upperCase(key), "_", " ")}</FormLabel>
											<RadioGroup aria-label="gender" name={key} value={add_permissions[key] === undefined ? " " : add_permissions[key]}  onChange={(e) => this.onClickRadio(e.target.value, key)}>
												{
													_.map(permission, (item) => {
														return (
															<FormControlLabel
																key={item.value}
																value={item.value}
																control={
																	<Radio onClick={_ => this.onClickRadio(item.value, key)} size="small" />
																}
																label={<><span>{item.label}</span> <span>{this.getTooltip(item.tooltip)}</span></>} />
														)
													})
												}
											</RadioGroup>
										</FormControl>
									</Grid>
								);
							})
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.props.onClose} color="default">Cancel</Button>
					<Button size="small" variant="contained" onClick={this.save} disabled={this.isDisabled()} color="primary">Save</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(RolePermissionModal));