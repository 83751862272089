import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Avatar, ListItemText } from '@material-ui/core';
import styles from "./style";
import { Utility, Api } from "@/helpers";
import { withRouter, Link } from "react-router-dom";

class EmployeeAvatar extends Component {

    render() {
        const { data, classes } = this.props;
        return (
            <Link to={`/employees/${data.employee.id}`}  className={classes.link} >
                <Grid container spacing={2} alignItems="center" wrap="nowrap" className={classes.cursor}>
                    <Grid item>
                        <Avatar className={classes.avatar} src={Api.employees(data.employee.id).avatar.getUrl()}>
                            {Utility.getInitials(`${data.employee.first_name} ${data.employee.last_name}`)}
                        </Avatar>
                    </Grid>
                    <Grid item >
                        <ListItemText className={classes.text}
                            primary={`${data.employee.first_name} ${data.employee.last_name} `}
                            primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
                            secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                            secondary={data.employee.code}
                        />
                    </Grid>
                </Grid>
            </Link>
        );
    }

}

export default withRouter(withStyles(styles)(EmployeeAvatar));