import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import AddDepartmentModal from "./../../pages/AddDepartment";
import _ from "lodash";

class DepartmentDropdown extends Component {
    constructor(props) {
        super(props);
        this._asyncDropDown = React.createRef();
      }
    static defaultProps = {
        label: "Department",
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        department: null,
        isModalOpen: false,
        textFieldValue: ""
    };

    modalclose = () => {
        this.setState({ isModalOpen: false }, async () => {
            await this._asyncDropDown.current.loadOptions()
        });
    }


    loadOptions = async (keyword = "") => {
        this.setState({ textFieldValue: keyword })
        if (this.state.department != null && keyword === this.state.department.name) {
            keyword = "";
        }

        this.setState({ loading: true });
        var response = await Api.departments.get({ per_page: 50, filter: { name: keyword } });
        var createNew = response.data;
        let department = _.find(createNew, (item) => {
            return item.id === this.props.value;
        });
        this.setState({ department });
        createNew = [...createNew, { name: "Create New Department", type: "department" }];
        response.data = createNew;
        return response.data;
    };

    onChange = (event, department) => {
        if (department && department.id) {
            this.setState({ department });
            this.props.onChange((department && department.id) || null);
        } else if (department === null) {
            this.props.onChange(null)
        } else {
            this.setState({ isModalOpen: true, textFieldValue: this.state.textFieldValue })
        }
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (<>
            <AsyncDropdown
                ref={this._asyncDropDown}
                classes={{ paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadOptions}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value}
                onChange={this.onChange}
                value={this.state.department}
                disabled={!!disabled}
            />
            {
                this.state.isModalOpen &&
                <AddDepartmentModal
                    avoidRoute={true}
                    backRoute={"goback"}
                    textFieldValue={this.state.textFieldValue}
                    onClose={this.modalclose}
                    onUpdate={async () => { await this._asyncDropDown.current.loadOptions() }} />
            }
        </>
        );
    }

}

export default withStyles(styles)(DepartmentDropdown);