const style = theme => ({

    dropdown: {
        minWidth: 200
    },

    dropdownChip: {
        marginRight: theme.spacing(1),
        boxShadow: theme.shadows[1]
    },

    dropdownChipRight: {
        marginLeft: theme.spacing(1),
        boxShadow: theme.shadows[1]
    },
    textField: {
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	formControl : {
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		marginTop: theme.spacing(1)
	},
    itemStyle: {
        width: "100%", height: "40px",
        alignItems: "center",
        paddingLeft: "10px",
        display: "flex",
    }
});

export default style;