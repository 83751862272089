import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn } from "@/components";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutlined, ToggleOff, ToggleOn } from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api } from "@/helpers";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import EmployeeLeaveModal from "@/components/Modals/EmployeeLeaveModal";
import { Permissions } from "@/config/Permissions";
import { UserRole } from "@/components";
class EmployeeLeaveTableRow extends DLTableRow {

    state = {
        isEditing: false,
        isDeleting: false
    };

    delete = async () => {
        this.setState({ isDeleting: false });
        this.props.setLoader(true);
        try {
            const { employee, data } = this.props;
            await Api.employees(employee.id).employee_leaves(data.id).delete();
            this.props.setSnackbar(true, "Leave deleted successfully");
            this.props.reload();
            this.props.getLeave();

        } catch (e) {
            this.props.setSnackbar(true, "Something went wrong, please try again", "error");
        }
        this.props.setLoader(false);
    };

    renderCells() {
        const { employee, data } = this.props;
        const { isEditing, isDeleting } = this.state;

        return [
            <DLTableColumn
                key={`Employee-${data.id}-Column-1`}
                align="left"
                withSeparator={true}>
                {data.details.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-2`}
                align="right"
                withSeparator={false}>
                {data.quantity - data.leave_days}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-3`}
                align="right"
                withSeparator={false}>
                {data.quantity}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-4`}
                align="left"
                withSeparator={false}>
                {data.details.allowed_in_probation ? "Yes" : "No"}
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-5`}
                align="center"
                withSeparator={false}>
                {
                    data.details.is_deduct_unapproved_leave
                        ? <ToggleOn color="primary" />
                        : <ToggleOff color="secondary" />
                }
            </DLTableColumn>,
            <UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
                <DLTableColumn
                    key={`Employee-${data.id}-Column-6`}
                    align={"center"}
                    isCompact={true}
                    fillWidth={35}>
                    
                    {
                        <Tooltip title="Edit Leaves">
                            <IconButton
                                onClick={() => this.setState({ isEditing: true })}
                                size="small"
                                color="primary"
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    }
                    {

                        <EmployeeLeaveModal
                            isOpen={isEditing}
                            employee={employee}
                            leaves={data}
                            title="Edit Leaves"
                            onUpdate={this.props.reload}
                            onClose={() => this.setState({ isEditing: false })}
                        />
                    }
                </DLTableColumn>
            </UserRole>,
            <UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
                <DLTableColumn
                    key={`Employee-${data.id}-Column-7`}
                    align={"center"}
                    isCompact={true}
                    fillWidth={35}>
                    
                    {
                        !data.is_permanent &&
                        <Tooltip title="Delete Leaves">
                            <IconButton
                                onClick={() => this.setState({ isDeleting: true })}
                                size="small"
                                color="secondary"
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        isDeleting &&
                        <ConfirmModal
                            title="Delete Leave"
                            isOpen={isDeleting}
                            message={`Are you sure want to delete ${data.details.name} leave?`}
                            onClickCancel={() => this.setState({ isDeleting: false })}
                            onClickConfirm={this.delete}
                        />
                    }
                </DLTableColumn>
            </UserRole>
        ];
    }

}

EmployeeLeaveTableRow.Columns = [
    {
        key: "leaves",
        label: "Leaves",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "leave_days",
        label: "Remaining",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "quantity",
        label: "Quantity",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "isAllowedInProbation",
        label: "Allowed in Probation",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "isDefault",
        label: "Is Default",
        align: "center",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "action",
        label: "",
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2
    }
];

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeLeaveTableRow);