import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import styles from "./style";

class Stats extends Component {
    
    render () {
        const { classes, className, children, color, label } = this.props;
        return (
            <Grid item className={classes.root}>
                <Typography
                    className={classNames(classes.value, className)}
                    component="div"
                    variant="h3"
                    color={color}>
                    {children}
                </Typography>
                <Typography className={classes.label}>{label}</Typography>
            </Grid>
        );
    }

};

export default withStyles(styles)(Stats);