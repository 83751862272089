import React, { Component } from "react";
import { Button, Grid, MenuItem, TextField, Typography, CardActions, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Check, Close } from "@material-ui/icons";
import styles from "./style";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { ImagePicker, UserRole } from "@/components";
import PlanDropdown from "@/components/Dropdowns/PlanDropdown";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler, Utility } from "@/helpers";
import Config from "@/config";
import _ from "lodash";
import StatesDropdown from '@/components/Dropdowns/StatesDropdown';
import CountryDropdown from '@/components/Dropdowns/CountryDropdown.js';
import MaskedInput from "react-text-mask";
import moment from "moment";
import Logo from "@/assets/logo/rsz_al_burhan_logo.png";
import { Permissions } from "@/config/Permissions";
import { AuthContext } from "@/contexts";
import { Constants } from "@/config";

class OrganizationInfo extends Component {

	static contextType = AuthContext;


	static propTypes = {
		organization: PropTypes.object,
		employee: PropTypes.object,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		employee: {},
		organization: {},
		onUpdate: () => { }
	};

	state = {
		errors: {},
		employee: {},
		organization: {}
	};

	employee() {
		return {
			code: "",
			first_name: "",
			last_name: "",
			work_email: "",
			password: "",
			department: "",
			designation: "",
			mobile: "",
			cnic: "",
			gender: "",
			dob: moment(),
			present_address: "",
			present_city: "",
			present_state: "",
			present_country: "",
			...this.props.employee,
			...this.state.employee
		};
	}
	organization() {
		return {
			name: "",
			address: "",
			city: "",
			state: "",
			country: "",
			plan_id: "",
			...this.props.organization,
			...this.state.organization
		};
	}

	isNew() {
		return !this.props.organization || !this.props.organization.id;
	}

	handleChange(value, field, isOrganization) {
		if (isOrganization) {
			const organization = this.organization();
			const { errors } = this.state;
			if (errors[field]) {
				errors[field] = false;
			}
			
			this.setState({
				organization: {
					...organization,
					[field]: value
				},
				errors
			});

		} else {
			const employee = this.employee();
			const { errors } = this.state;
			if (errors[field]) {
				errors[field] = false;
			}
			this.setState({
				employee: {
					...employee,
					[field]: value
				},
				errors
			});
		}
	}

	avatar = null;
	imageChangeHandler = (e) => {
		this.avatar = e ? e.target.files[0] : null;
	};

	validate() {
		const employee = this.employee();
		const organization = this.organization();
		const optionalFields = ["father_name", "logo", "registration", "personal_email", "phone", "emergency_contact", "skype", "ntn", "deleted_at", "is_archived", "is_inactive", "has_single_address", "is_tax_exempted", "role", "is_purchase_expired", "is_geofencing", "seats_allowed"];
		var errors = {};

		if (!this.isNew()) {
			optionalFields.push("password");
			optionalFields.push("department");
			optionalFields.push("designation");
			optionalFields.push("joined_at");
			optionalFields.push("confirmed_at");
			optionalFields.push("registration");
			optionalFields.push("plan_id");
		}
		if (this.isNew()) {
			_.forOwn(employee, (value, key) => {
				if (value && key === "mobile") {
					if (value.match(/[a-zA-Z]+/g))
						errors[key] = `must not include special characters`;

				} else if (optionalFields.indexOf(key) === -1 && !value) {
					errors[key] = `${_.startCase(key)} field is required`;

				} else if (["work_email"].indexOf(key) !== -1 && value && !Utility.validateEmail(value)) {
					errors[key] = "Invalid email address";

				} else if (key === "password" && value && value.length < 6) {
					errors[key] = "Password must be at least 6 characters";

				} else if (key === "cnic" && !Utility.validateCNIC(value)) {
					errors[key] = "Invalid CNIC format, should be like 42101-1234567-1";

				}
			});
		}
		_.forOwn(organization, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				if (key === "is_super_organization" && this.context.organization && this.context.organization.is_super_organization) return;

				errors[key] = `${_.startCase(key)} field is required`;

			}
		});


		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
		
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const employee = this.employee();
		const organization = this.organization();
		const data = new FormData();
		_.forOwn(organization, (value, key) => {
			if (_.isNil(value)) {
				value = "";
			}
			data.append(key, value);
		});
		if (this.isNew()) {
			_.forOwn(employee, (value, key) => {
				// Send null values as empty string
				if (_.isNil(value)) {
					value = "";
				}
				// Format dates as per server standard
				if (["dob"].indexOf(key) !== -1) {
					value = moment(value || "").format(Config.SERVER_DATETIME_FORMAT);
				}
				data.append(key, value);
			});
		}
		// Append display picture (if changed)
		if (this.avatar) {
			data.append("avatar", this.avatar);
		}
		this.props.setLoader(true);
		try {
			var _organization;
			if (this.isNew()) {
				_organization = await Api.organizations.post(data);
				this.props.setSnackbar(true, "New organization added successfully");
				this.props.history.replace(`/organizations/` + _organization.id);
			} else {
				data.append("_method", "put");
				_organization = await Api.organizations(organization.id).post(data);
				this.props.setSnackbar(true, "Organization information saved successfully");
				if (_.isFunction(this.props.onUpdate)) {
					this.props.onUpdate(_organization);
				}
			}
		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.go();
		}
		this.props.setLoader(false);
	};

	resetForm() {
		this.setState({ employee: {} });
	}

	maskedInput(mask) {
		return function (props) {
			const { inputRef, value, ...otherProps } = props;
			const maskValue = mask.map(char => char instanceof RegExp ? "_" : char).join("");
			const showMask = value && value.match(maskValue);

			return (
				<MaskedInput
					ref={ref => inputRef(ref ? ref.inputElement : null)}
					value={value}
					mask={mask}
					showMask={showMask}
					{...otherProps}
				/>
			);
		}
	}

	CNICMask = this.maskedInput([/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]);
	NTNMask = this.maskedInput([/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]);

	render() {
		const { classes } = this.props;
		const { errors } = this.state;
		const employee = this.employee();
		const organization = this.organization();
		return (
			<>
				<CardContent>
					<Grid className={classes.root} container spacing={2}>
						<Grid item sm={9} xs={12}>
							<Grid container spacing={2}>
								<Grid item sm={this.isNew() ? 6 : 12} xs={12}>
									<TextField
										fullWidth
										label=" Organization Name"
										error={!!errors.name}
										helperText={errors.name}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.name || ""}
										onChange={e => this.handleChange(e.target.value, "name", true)}
										variant="outlined"
									/>
								</Grid>
								{
									this.isNew() &&
									<Grid item sm={6} xs={12}>
										<PlanDropdown
											onChange={plan => this.handleChange(plan.id, "plan_id", true)}
											textFieldProps={{
												error: !!errors.plan_id,
												helperText: errors.plan_id,
												FormHelperTextProps: { variant: "standard" }
											}}
										/>
									</Grid>
								}
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										label="Organization Address"
										error={!!errors.address}
										helperText={errors.address}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.address || ""}
										onChange={e => this.handleChange(e.target.value, "address", true)}
										variant="outlined"
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<TextField
										fullWidth
										label="City"
										error={!!errors.city}
										helperText={errors.city}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.city || ""}
										onChange={e => this.handleChange(e.target.value, "city", true)}
										variant="outlined"
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									{organization.country === "Pakistan"
										?
										<StatesDropdown
											fullWidth
											label={"State/Region"}
											variant="outlined"
											error={!!errors.state}
											helperText={errors.state}
											optionLabel={organization.state}
											FormHelperTextProps={{ variant: "standard" }}
											value={organization.state || ""}
											onChange={states => this.handleChange(states, "state", true)}
										/>
										:
										<TextField
											fullWidth
											label="State"
											error={!!errors.state}
											helperText={errors.state}
											FormHelperTextProps={{ variant: "standard" }}
											value={organization.state || ""}
											onChange={e => this.handleChange(e.target.value, "state", true)}
											variant="outlined"
										/>
									}
								</Grid>
								<Grid item sm={12} xs={12}>
									<CountryDropdown
										fullWidth
										label="Country"
										optionLabel={organization.country}
										variant="outlined"
										textFieldProps={{
											error: !!errors.country,
											helperText: errors.country,
											FormHelperTextProps: { variant: "standard" }
										}}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.country || ""}
										onChange={(country) => this.handleChange(country, "country", true)}
									/>
								</Grid>
								{
									this.isNew() &&
									<Grid item sm={12} xs={12}>
										<Grid container spacing={2}>
											<Grid item sm={12} xs={12}>
												<Typography component="h5" variant="h5">Authorized Person</Typography>
											</Grid>
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													label="First Name"
													variant="outlined"
													error={!!errors.first_name}
													helperText={errors.first_name}
													FormHelperTextProps={{ variant: "standard" }}
													value={employee.first_name || ""}
													onChange={e => this.handleChange(e.target.value, "first_name")}
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													label="Last Name"
													variant="outlined"
													value={employee.last_name || ""}
													error={!!errors.last_name}
													helperText={errors.last_name}
													FormHelperTextProps={{ variant: "standard" }}
													onChange={e => this.handleChange(e.target.value, "last_name")}
												/>
											</Grid>
											{
												employee && !employee.id &&
												<>
													<Grid item sm={12} xs={12}>
														<form noValidate>
															<TextField
																fullWidth
																label="Work Email"
																inputProps={{
																	autoComplete: "disabled",
																}}
																variant="outlined"
																value={employee.work_email || ""}
																error={!!errors.work_email}
																helperText={errors.work_email}
																FormHelperTextProps={{ variant: "standard" }}
																onChange={e => this.handleChange(e.target.value, "work_email")}
															/>
														</form>
													</Grid>
													<Grid item sm={6} xs={12}>
														<form noValidate>
															<TextField
																fullWidth
																label="Password"
																type="password"
																error={!!errors.password}
																helperText={errors.password}
																FormHelperTextProps={{ variant: "standard" }}
																variant="outlined"
																value={employee.password || ""}
																onChange={e => this.handleChange(e.target.value, "password")}
															/>
														</form>
													</Grid>
													<Grid item sm={6} xs={12}>
														<TextField
															fullWidth
															label="Employee Code"
															variant="outlined"
															error={!!errors.code}
															helperText={errors.code}
															FormHelperTextProps={{ variant: "standard" }}
															value={employee.code || ""}
															onChange={e => this.handleChange(e.target.value, "code")}
														/>
													</Grid>
													<Grid item sm={6} xs={12}>
														<TextField
															fullWidth
															label="Department"
															variant="outlined"
															error={!!errors.department}
															helperText={errors.department}
															FormHelperTextProps={{ variant: "standard" }}
															value={employee.department || ""}
															onChange={e => this.handleChange(e.target.value, "department")}
														/>
													</Grid>
													<Grid item sm={6} xs={12}>

														<TextField
															fullWidth
															label="Designation"
															variant="outlined"
															error={!!errors.designation}
															helperText={errors.designation}
															FormHelperTextProps={{ variant: "standard" }}
															value={employee.designation || ""}
															onChange={e => this.handleChange(e.target.value, "designation")}
														/>
													</Grid>
												</>
											}
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													label="Mobile Number"
													variant="outlined"
													value={employee.mobile || ""}
													error={!!errors.mobile}
													helperText={errors.mobile}
													FormHelperTextProps={{ variant: "standard" }}
													onChange={e => this.handleChange(e.target.value, "mobile")}
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													label="CNIC Number"
													error={!!errors.cnic}
													helperText={errors.cnic}
													FormHelperTextProps={{ variant: "standard" }}
													variant="outlined"
													value={employee.cnic || ""}
													onChange={e => this.handleChange(e.target.value, "cnic")}
													InputProps={{
														inputComponent: this.CNICMask
													}}
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													select
													label="Gender"
													variant="outlined"
													error={!!errors.gender}
													helperText={errors.gender}
													FormHelperTextProps={{ variant: "standard" }}
													value={employee.gender || ""}
													onChange={e => this.handleChange(e.target.value, "gender")}
												>
													<MenuItem value="Male">Male</MenuItem>
													<MenuItem value="Female">Female</MenuItem>
												</TextField>
											</Grid>
											<Grid item sm={6} xs={12}>
												<DatePicker
													fullWidth
													error={!!errors.dob}
													helperText={errors.dob}
													FormHelperTextProps={{ variant: "standard" }}
													label="Birthday"
													maxDate={moment().format("YYYY-MM-DD")}
													inputVariant="outlined"
													format="dd MMMM, yyyy"
													value={moment(employee.dob || "")}
													onChange={date => this.handleChange(date, "dob")}
												/>
											</Grid>
											<Grid item sm={12} xs={12}>
												<TextField
													fullWidth
													label="Address"
													error={!!errors.present_address}
													helperText={errors.present_address}
													FormHelperTextProps={{ variant: "standard" }}
													value={employee.present_address || ""}
													onChange={e => this.handleChange(e.target.value, "present_address")}
													variant="outlined"
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<TextField
													fullWidth
													label="City"
													error={!!errors.present_city}
													helperText={errors.present_city}
													FormHelperTextProps={{ variant: "standard" }}
													value={employee.present_city || ""}
													onChange={e => this.handleChange(e.target.value, "present_city")}
													variant="outlined"
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												{employee.present_country === "Pakistan"
													?
													<StatesDropdown
														fullWidth
														label={"State/Region"}
														variant="outlined"
														error={!!errors.present_state}
														helperText={errors.present_state}
														FormHelperTextProps={{ variant: "standard" }}
														value={employee.present_state || ""}
														onChange={states => this.handleChange(states, "present_state")}
													/>
													:
													<TextField
														fullWidth
														label="State"
														error={!!errors.present_state}
														helperText={errors.present_state}
														FormHelperTextProps={{ variant: "standard" }}
														value={employee.present_state || ""}
														onChange={e => this.handleChange(e.target.value, "present_state")}
														variant="outlined"
													/>
												}
											</Grid>
											<Grid item sm={12} xs={12}>
												<CountryDropdown
													fullWidth
													label="Country"
													textFieldProps={{
														error: !!errors.present_country,
														helperText: errors.present_country,
														FormHelperTextProps: { variant: "standard" }
													}}
													FormHelperTextProps={{ variant: "standard" }}
													value={employee.present_country || ""}
													onChange={(country) => this.handleChange(country, "present_country")}
												/>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						</Grid>
						<Grid container sm={3} xs={12} justify={"center"}>
							<ImagePicker
								title="Profile image"
								rounded
								src={
									organization.id ?
										Api.organizations(organization.id).logo.getUrl(true) :
										(Logo)
								}
								onSelect={this.imageChangeHandler}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<UserRole routePermissions={[Permissions.ORGANIZATIONS_FULL_ACCESS]}>
						<Button
							className={classes.actionButton}
							variant="contained"
							color="primary"
							onClick={this.save}
						>
							<Check className={classes.actionButtonIcon} />
							Save
						</Button>
					</UserRole>
					<Button
						className={classes.actionButton}
						variant="contained"
						color="secondary"
						onClick={() => this.props.history.push("/organizations")}
					>
						<Close className={classes.actionButtonIcon} />
						Cancel
					</Button>
				</CardActions>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(OrganizationInfo)));
