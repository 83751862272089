import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Dialog, DialogActions, DialogTitle, DialogContent
} from "@material-ui/core";
import { Constants, Modules } from "@/config";
import _ from "lodash";
import styles from "./style";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Api from "@/helpers/Api";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import ModuleManager from "@/components/ModuleManager";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FormattedNumericInput } from "@/components";

class PayItemAdd extends Component {
	constructor (props) {
		super(props);

		this.state = {
			id: "",
			name: "",
			description: "",
			type: Constants.payitem.type[0],
			taxType: Constants.payitem.taxType[0],
			paidTo: "Employee",
			rate: 0,
			quantity: 1,
			accountFor: Constants.payitem.accountFor[18],
			paymentFrequency: Constants.payitem.paymentFrequency[0],
			isOnPayslip: true,
			isPercentage: false,
			isReimbursable: false,
			isDefault: true,
			fixedRate: false,
			fixedQuantity: true,
			errors: {}
		};
	}

	async componentDidMount() {
		if (this.props.match.params && this.props.match.params.payItemId > 0) {
			this.props.setLoader(false);
			this.setState({ isLoading: true, isEdit: true });
			let response = await Api.pay_items(
				this.props.match.params.payItemId
			).get();
			this.setState({
				id: response.id,
				name: response.name,
				description: response.description,
				type: response.type,
				taxType: response.tax_type,
				paidTo: response.paid_to,
				rate: response.default_rate,
				quantity: response.default_quantity,
				accountFor: response.account_for,
				paymentFrequency: response.default_frequency,
				isOnPayslip: response.is_on_slip,
				isPercentage: response.is_percentage,
				isReimbursable: response.is_reimbursable,
				isDefault: response.is_default,
				fixedRate: response.is_fixed_rate,
				fixedQuantity: response.is_fixed_quantity,
				errors: {}
			}, () => {
				this.props.setLoader(false);
			});
		}
	}

	/*********************** Additional Functions to be used within component ***************/
	handleChange = (val, fieldName) => {
		this.setState({ [fieldName]: val })
		if (this.state.errors[fieldName] === true) {
			this.setState({
				errors: {
					...this.state.errors,
					[fieldName]: false
				}
			});
		}
	};

	isValidData = () => {
		var errors = {}
		if (this.state.name === "") {
			errors.name = "Name field is required"
		}
		if (this.state.description === "") {
			errors.description = "Description field is required"
		}
		if (this.state.rate === "") {
			errors.rate = "Rate field is required"
		}
		if (this.state.quantity === "") {
			errors.quantity = "Quantity field is required"
		}
		if (this.state.isPercentage && (this.state.rate < 0 || this.state.rate > 100)) {
			errors.rate = "Rate must be a valid percentage between 0 to 100"
		}
		this.setState({ errors: errors });
		return (_.size(errors) > 0) ? false : true;
	};

	closeDialog = () => {
		if (this.props.onClose) {
			this.props.onClose();
		} else if (this.props.history.length > 1) {
			this.props.history.goBack();
		} else {
			this.props.history.push("/settings/payitems");
		}
	}

	createPayItem = async () => {
		if (this.isValidData()) {

			var data = new FormData();
			this.props.setLoader(true);
			data.append("name", this.state.name);
			data.append("description", this.state.description);
			data.append("type", this.state.type);
			data.append("tax_type", this.state.taxType);
			data.append("paid_to", this.state.paidTo);
			data.append("account_for", this.state.accountFor);
			data.append("default_rate", this.state.rate || 0);
			data.append("default_quantity",this.state.type!=="Loans" ? this.state.quantity:1);
			data.append("default_frequency", this.state.paymentFrequency);	
			data.append("is_default", this.state.isDefault?1:0);
			data.append("is_on_slip", this.state.isOnPayslip?1:0);
			data.append("is_percentage", this.state.isPercentage?1:0);
			data.append("is_reimbursable", this.state.isReimbursable?1:0);
			data.append("is_fixed_rate", this.state.fixedRate?1:0);
			data.append("is_fixed_quantity",this.state.type!=="Loans" ?this.state.fixedQuantity?1:0:this.state.fixedQuantity?0:1);
			try {
				if (!this.state.id) {
					await Api.pay_items.post(data);
				} else {
					data.append("_method", "put");
					await Api.pay_items(this.state.id).post(data, {
						headers: {
							"Content-Type": "multipart/form-data",
						}
					}
					);
				}
				this.props.setSnackbar(true, this.state.id ? "Pay Item Updated" : "Pay Item Added");
				this.props.onUpdate();
				this.props.history.push("/settings/payitems");
			}
			catch (e) {
				this.setState({ errors: { ...e.response } }, () => {
					this.props.setLoader(false);
				})
			}
			this.props.setLoader(false);
		}

	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={true}
				onBackdropClick={this.closeDialog}
				aria-labelledby="version-dialog-title"
				aria-describedby="payitem-dialog-description"
				onClose={this.props.onClose}
			>
				<DialogTitle id="version-dialog-title">{!(this.props.match && this.props.match.params && this.props.match.params.payItemId > 0) ? "Add Pay Item" : "Edit Pay Item"}</DialogTitle>
				<DialogContent dividers id="payitem-dialog-description">
					<Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
						<Grid item xs={12}>
							<TextField
								style={{ marginTop: "5px" }}
								fullWidth
								error={this.state.errors.name}
								helperText={this.state.errors.name}
								placeholder="Name"
								label="Name"
								value={this.state.name}
								onChange={(e) => this.handleChange(e.target.value, "name")}
								variant="outlined"
								size={"small"}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								style={{ marginTop: "5px" }}
								fullWidth
								error={this.state.errors.description}
								helperText={this.state.errors.description}
								placeholder="Description"
								label="Description"
								value={this.state.description}
								onChange={(e) => this.handleChange(e.target.value, "description")}
								variant="outlined"
								size={"small"}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl
								variant="outlined" className={classes.formControl}>
								<InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Type"
									value={this.state.type}
									style={{ height: "40px" }}
									onChange={(e) => this.handleChange(e.target.value, "type")}
								>
									{
										_.map(Constants.payitem.type, (type, typeIndex) => {
											return (
												<MenuItem value={type} key={typeIndex}>{type}</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} className={classes.hidden}>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="demo-simple-select-outlined-label">Tax Type</InputLabel>

								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Tax type"
									value={this.state.taxType}
									onChange={(e) => this.handleChange(e.target.value, "taxType")}
									style={{ height: "40px" }}

								>
									{
										_.map(Constants.payitem.taxType, (type, typeIndex) => {
											return (
												<MenuItem value={type} key={typeIndex}>{type}</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} className={classes.hidden}>
							<TextField
								style={{ marginTop: "5px" }}
								fullWidth
								error={this.state.errors.paidTo}
								helperText={this.state.errors.paidTo}
								placeholder="Paid to"
								label="Paid to"
								value={this.state.paidTo}
								onChange={(e) => this.handleChange(e.target.value, "paidTo")}
								variant="outlined"
								size={"small"}
							/>
						</Grid>
						<Grid item xs={12} className={classes.hidden}>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="demo-simple-select-outlined-label">Account for</InputLabel>

								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Account for"
									value={this.state.accountFor}
									onChange={(e) => this.handleChange(e.target.value, "accountFor")}
									style={{ height: "40px" }}
								>
									{
										_.map(Constants.payitem.accountFor, (accountFor, accountForIndex) => {
											return (
												<MenuItem value={accountFor} key={accountForIndex}>{accountFor}</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item alignItems="center" xs={12}>
							<Grid container spacing={1}>
								<Grid item xs>
									<TextField
										style={{ marginTop: "5px" }}
										fullWidth
										InputProps={{
											inputComponent: FormattedNumericInput,
										}}
										error={this.state.errors.rate}
										helperText={this.state.errors.rate}
										placeholder="Default Rate"
										label="Default Rate"
										value={this.state.rate}
										onChange={(e) => this.handleChange(e.currentTarget.value.replace(/[^0-9]/g, ""), "rate")}
										variant="outlined"
										size={"small"}
									/>
								</Grid>
								<Grid item xs="auto">
									<ButtonGroup size="large" color="primary" className={classes.buttonGroup}>
										<Button
											variant={this.state.isPercentage ? "outlined" : "contained"}
											onClick={() => this.handleChange(false, "isPercentage")}
											style={{ width: "40px" }}
										>Rs</Button>
										<Button
											variant={!this.state.isPercentage ? "outlined" : "contained"}
											onClick={() => this.handleChange(true, "isPercentage")}
											style={{ width: "40px" }}
										>%</Button>
									</ButtonGroup>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="outlined" className={classNames(classes.formControl)}>
								<InputLabel id="demo-simple-select-outlined-label">Payment Frequency</InputLabel>
								<Select
									value={this.state.paymentFrequency}
									onChange={(e) => this.handleChange(e.target.value, "paymentFrequency")}
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									label="Payment Frequency"
									style={{ height: "40px" }}
								>
									{
										_.map(Constants.payitem.paymentFrequency, (paymentFrequency, paymentFrequencyIndex) => {
											return (
												<MenuItem value={paymentFrequency} key={paymentFrequencyIndex}>{paymentFrequency}</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								className={classes.labelPlacementStart}
								labelPlacement="start"
								label="Fixed Rate"
								control={
									<Switch
										checked={this.state.fixedRate}
										onChange={() => this.handleChange(!this.state.fixedRate, "fixedRate")}
										value="checkedB"
										color="primary"
										size={"small"}
									/>
								}
							/>

						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								className={classes.labelPlacementStart}
								labelPlacement="start"
								label="Adds automatically for new employees"
								control={
									<Switch
										checked={this.state.isDefault}
										onChange={() => this.handleChange(!this.state.isDefault, "isDefault")}
										value="checkedB"
										color="primary"
										size={"small"}
									/>
								}
							/>

						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								className={classes.labelPlacementStart}
								labelPlacement="start"
								label="Will be displayed on payslip"
								control={
									<Switch
										checked={this.state.isOnPayslip}
										onChange={() => this.handleChange(!this.state.isOnPayslip, "isOnPayslip")}
										value="checkedB"
										color="primary"
										size={"small"}
									/>
								}

							/>
						</Grid>

						<ModuleManager modulePermissions={[ Modules.REIMBURSEMENT ]}>
							<Grid item xs={12}>
								<FormControlLabel
									className={classes.labelPlacementStart}
									labelPlacement="start"
									label="Reimbursable"
									control={
										<Switch
											checked={this.state.isReimbursable}
											onChange={() => this.handleChange(!this.state.isReimbursable, "isReimbursable")}
											value="checkedB"
											color="primary"
											size={"small"}
										/>
									}
								/>
							</Grid>
						</ModuleManager>

					</Grid>
				</DialogContent>
				<DialogActions>

					<Button size="small" color="black" className={classes.createBtn} onClick={() => {
						this.closeDialog()
					}}>
						Cancel
					</Button>
					<Button variant="contained" size="small" color="primary" className={classes.createBtn} onClick={this.createPayItem}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PayItemAdd)));
