import React from 'react';
import ReactDOM from 'react-dom';

export default class SearchBox extends React.Component {

  render() {
    return <input ref="input" {...this.props} type="text" style={{
        font: "inherit",
        width: "100%",
        borderWidth: 1,
        height: "0.1876em",
        display: "block",
        borderRadius: 4,
        padding: "16px 14px",
        boxSizing: "border-box",
        fontFamily: "sans-serif",
        borderColor: "rgb(118, 118, 118)"
        }} />;
  }
  onPlacesChanged = () => {
    if (this.props.onPlacesChanged) {
      this.props.onPlacesChanged(this.searchBox.getPlaces());
    }
  }
  componentDidMount() {
    const { googleMaps } = this.props;
    var input = ReactDOM.findDOMNode(this.refs.input);
    this.searchBox = new googleMaps.places.SearchBox(input);
    this.searchBoxListener = this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }
  componentWillUnmount() {
    const { googleMaps } = this.props;
    // https://developers.google.com/maps/documentation/javascript/events#removing
    new googleMaps.event.removeListener(this.searchBoxListener);
  }
}