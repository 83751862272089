const style = theme => ({

    root: { },

    active: {
        backgroundColor: theme.palette.primary.main,

        "& $name, & $code, & $checkmark": {
            color: "#ffffff"
        }
    },

    avatar: {
        width: 40,
        height: 40,
        border: `1px ${theme.palette.borderColor.main} solid`,
        boxShadow: theme.shadows[2],
        margin: theme.spacing(1)
    },

    name: {
        lineHeight: 1
    },

    code: {
        lineHeight: 1
    },

    checkmark: {
        margin: theme.spacing(1)
    },

    lock: {
        marginLeft: theme.spacing(1)
    }

});

export default style;