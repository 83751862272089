import { EditOutlined, DeleteOutlined, RemoveRedEyeOutlined } from "@material-ui/icons";
import { DLTableColumn, DLTableRow} from "@/components";
import { Api, HttpErrorHandler } from "@/helpers";
import DocumentModal from "../../components/Modals/DocumentModal";
import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AddDocumentModal from "../AddDocument";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import styles from "./style";
import moment from "moment";
import _ from "lodash";

class DocumentTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isEditing : false,
			isDelete:false
		}
	}


	delete = async (e) => {
        e.preventDefault();
        this.setState({ isDelete: false });
        const { data } = this.props;
        try {
            await Api.organization_document(data.id).delete();
            store.dispatch(
                setSnackbar(true, `Document "${data.name}" deleted successfully`)
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            HttpErrorHandler.intercept(e)
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.message) || "Something went wrong, please try again later",
                        "error"
                    )
                })
                .on(422, (response) => {
                    this.setState({
                        errors: _.mapValues(response.data, (value) => value[0]),
                    });
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.leave_request) || "Please fix the highlighted errors",
                        "error"
                    );
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
    };

	renderCells() {
		const { data } = this.props;
		return [
			<DLTableColumn
				key={`Document-${data.id}-Column-1`}
				withSeparator={true}>
				{data.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Document-${data.id}-Column-2`}
				align={"left"}>
				{data.category}
			</DLTableColumn>,
			<DLTableColumn
				key={`Document-${data.id}-Column-3`}
				align={"left"}>
                {moment(data.updated_at).format("MMM Do, YYYY")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Document-${data.id}-Column-4`}
				align={"right"}
				isCompact={true}
				fillWidth={35}
			>
				<IconButton variant="outlined" 
               		color="primary"
					onClick={() => this.setState({ isModalOpen: true })}
                 >
					 <RemoveRedEyeOutlined />
                </IconButton>
			</DLTableColumn>,
			<DLTableColumn
				key={`Document-${data.id}-Column-5`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				{
					<Tooltip title="Edit Document">
						
							<IconButton
								onClick={() => {
								this.setState({isEditing:true})
								}}
								size="small"
								color="primary">
								<EditOutlined />
							</IconButton>
						
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Document-${data.id}-Column-6`}
				isCompact={true}
				fillWidth={35}
				align={"center"}>
				{(
					<>
						<Tooltip title="Delete Document">
							<IconButton
								onClick={() => this.setState({ isDelete: true })}
								size="small"
								color="secondary">
								<DeleteOutlined />
							</IconButton>
						</Tooltip>
						<ConfirmModal
							title="Delete Document"
							isOpen={this.state.isDelete}
							message={`Are you sure you want to delete the document "${data.name}"?`}
							onClickCancel={() => this.setState({ isDelete: false })}
							onClickConfirm={this.delete}
						/>
					</>
				)}
			</DLTableColumn>,
			this.state.isModalOpen &&
			<DocumentModal
				employeeDocument =	{false}
				isOpen={this.state.isModalOpen}
				onClose = {() => this.setState({ isModalOpen: false })}
				data={data}
			/>,
			this.state.isEditing &&
			<AddDocumentModal
				employeeDocument = {false}
				title={"Edit Document"}
				data={data}
				employee={this.props.employee}
				onClose = {() => this.setState({ isEditing: false })}
				onUpdate={this.props.reload}
			/>
		]
	}

}

DocumentTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: false,
		withSeparator: true
	},
	{
		key: "category",
		label: "Category",
		align: "left",
		withSeparator: false
	},
	{
		key: "updated_at",
		label: "Last Updated",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "",
		label: "",
		align: "right",
		colSpan:3,
		isSortable: false,
		withSeparator: false,
	}
];

export default withRouter(withStyles(styles)(DocumentTableRow));
