import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Proptypes from "prop-types";
import classNames from "classnames";
import { Card, CardMedia, IconButton, Icon, Fab } from "@material-ui/core";
import styles from "./style";

class ImagePicker extends Component {

	static defaultProps = {
		title: "Select Image",
		rounded: false,
		mode: "cover",
		onSelect: () => {}
	};

	static propTypes = {
		title: Proptypes.string,
		src: Proptypes.string.isRequired,
		onSelect: Proptypes.func,
		rounded: Proptypes.bool,
		mode: Proptypes.oneOf(["contain", "cover"])
	};

	static _id = 0;

	constructor (props) {
		super(props);
		this.state = {
			src: props.src,
			_id: ++ImagePicker._id
		};
	}

	imageHandler = event => {
		const URL = window.URL || window.webkitURL;
		const files = Array.from(event.target.files);
		this.setState({ src: URL.createObjectURL(files[0]) });
		this.props.onSelect(event);
	}

	reset = () => {
		const { src } = this.props;
		this.setState({ src });
		if (this.uploader && this.uploader.value) {
			this.uploader.value = null;
		}
		this.props.onSelect();
	}

	render () {
		const { classes, rounded, title, mode } = this.props;
		const { src, _id } = this.state;
		return (
			<Card className={classNames([classes.root, rounded ? classes.rounded : null])}>
				<CardMedia
					className={classes.image}
					style={{backgroundSize: mode}}
					image={src}
					title={title}>
				</CardMedia>
				<input
					ref={uploader => this.uploader = uploader} 
					accept="image/*"
					className={classes.uploadFile}
					id={`image-picker-input-${_id}`}
					multiple={false}
					type="file"
					onInput={this.imageHandler}/>

				<div className={classes.overlay}>
					<label htmlFor={`image-picker-input-${_id}`}>
						<Fab
							color="primary"
							size="small"
							component="span"
							variant="extended">
							<Icon className={classes.extendedIcon}>image</Icon> <span className={classes.extendedText}>Select Image</span>
						</Fab>
					</label>
					{
						src !== this.props.src &&
						<IconButton
							color="default"
							size="small"
							onClick={this.reset}>
							<Icon>settings_backup_restore</Icon>
						</IconButton>
					}
				</div>
			</Card>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ImagePicker);