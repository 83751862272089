import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";
import { BackButton, Page, Tabs, OrganizationBranches } from "@/components";
import OrganizationInfo from "./OrganizationInfo";
import LetterheadSettings from "./LetterheadSettings";
import { Api } from "@/helpers";
import { AuthContext } from "@/contexts";
import { Link } from "react-router-dom";
import Modules from "@/config/Modules";
class Organization extends Component {

	static contextType = AuthContext;

	state = {
		organization: {},
		option: {}
	};

	async componentDidMount() {
		const { organization_id, branch } = this.context.user;

		const organization = await Api.organizations(organization_id).get();
		const option = await Api.options.get({ group: "letterhead" });

		this.setState({ 
			organization: {
				...organization,
			},
			option: {
				...option
			}
		});
	}

	onUpdate = async organization => {
		const response = await Api.organizations(this.context.user.organization_id).get();
		const branch = response.branches
		this.setState({ 
			organization: {
				...organization,
				zoom: branch[0].zoom, 
				latitude: branch[0].latitude, 
				longitude: branch[0].longitude
			}
		 });
	};

	render() {
		const { organization, option } = this.state;
		return (
			<Page title="Organization">
				<Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
					<BackButton route="/settings" label="Back to Settings" />
				</Link>
				<Card raised>
					{
						organization && organization.id &&
						<Tabs
							variant="routed"
							tabsProps={{
								variant: "scrollable"
							}}>
							<Tabs.Tab
								hidden={[
									Modules.EMPLOYEE_HISTORY,
									Modules.ATTENDANCE,
									Modules.PAYROLL,
									Modules.BRANCH
								]}
								path="/info"
								label="Organization Info">

								<Grid container justify="flex-start" spacing={3}>
									<Grid item xs={12}>
										<OrganizationInfo
											organization={organization}
											onUpdate={this.onUpdate} />
									</Grid>
								</Grid>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[ Modules.BRANCH ]}
								path="/branches"
								label="Branches">
								<OrganizationBranches
									isSettingPage
									history={this.props.history}
									organization={organization}
								/>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[
									Modules.EMPLOYEE_HISTORY,
									Modules.ATTENDANCE,
									Modules.PAYROLL,
									Modules.BRANCH
								]}
								path="/letterhead"
								label="Letterhead">
								<LetterheadSettings
									organization={organization}
									option = {option}
								/>
							</Tabs.Tab>
						</Tabs>
					}
				</Card>
			</Page >
		);
	}

}

export default Organization;