import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, Popper, TextField } from "@material-ui/core";
import styles from "./style";

Popper.defaultProps = {
    placement: "bottom-start"
};

class AsyncDropdown extends Component {

    state = {
        open: false,
        options: [],
        loading: false,
        keyword: ""
    };

    componentDidMount() {
        this.loadOptions();
    }

    async loadOptions(keyword = "") {
        this.setState({ loading: true });
        const options = await this.props.getOptions(keyword);
        this.setState({ keyword, options, loading: false });
    }

    renderInput = params => {
        return (
            <TextField
                {...params}
                {...this.props.textFieldProps}
                variant="outlined"
                label={this.props.label}
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {this.state.loading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }}
            />)
    };

    renderOption = (option, state) => {
        return (
            <span className={this.props.classes.itemStyle} style={{
                borderTop: option.type ? "1px solid #CCCC" : null
            }}>
                {option.name}
            </span>
        );
    }

    render() {
        const { open, options, loading, keyword } = this.state;
        const { value, disabled } = this.props;

        return (
            <Autocomplete
                classes={{
                    popper: this.props.classes.paper,
                    option: this.props.classes.option,
                    ...this.props.classes
                }}
                open={open}
                onOpen={() => this.setState({ open: true })}
                onClose={() => this.setState({ open: false })}
                PopperComponent={Popper}
                getOptionSelected={this.props.getOptionSelected}
                getOptionLabel={this.props.getOptionLabel}
                options={options}
                filterOptions={x => x}
                loading={loading}
                autoComplete
                renderInput={this.renderInput}
                noOptionsText={this.props.noOptionsText(keyword)}
                onChange={this.props.onChange}
                onInputChange={(_, keyword) => this.loadOptions(keyword)}
                value={value}
                disabled={disabled}
                renderOption={this.props.renderOption ? this.props.renderOption : this.renderOption}
            />
        );
    }

}

AsyncDropdown.defaultProps = {
    onChange: () => { },
    noOptionsText: keyword => !keyword ? "Type to search available options" : `"${keyword}" not found`
};

AsyncDropdown.propTypes = {
    getOptions: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    getOptionSelected: PropTypes.func.isRequired,
    renderOption: PropTypes.func,
    textFieldProps: PropTypes.object,
    onChange: PropTypes.func,
    noOptionsText: PropTypes.func
};

export default withStyles(styles)(AsyncDropdown);