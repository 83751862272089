const styles = theme => ({

    systemSettings: {
        marginTop: theme.spacing(2)
    },
    main: {
        alignItems : "center",
        display : "flex",
        flexDirection : "row" ,
        marginTop : "40px"
    },
    banner: {
        width : "40vw",
        height : "30vw",
        display: "inline-block"
    },
    textHeading : {
        marginTop : "20px",
        marginBottom : "20px",
        fontSize : "60px",
        fontWeight : "bold"
    },
    text : {
        fontSize : "22px",
        whiteSpace: "pre-line"
    },
    underlineStyle: {
        textDecorationLine: "underline",
        textDecorationStyle: "solid",
        textDecorationColor: "#0D72FF"
    },
    backButton : {
        paddingLeft : "30px",
        paddingRight : "30px"
    },
    section : {
        display : "inline-block"
    }
});

export default styles