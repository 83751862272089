import React from "react";
import { withRouter, Link } from "react-router-dom";
import { DLTableRow, DLTableColumn, UserRole, Money } from "@/components";
import { IconButton, Tooltip, Chip } from "@material-ui/core";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import moment from "moment";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { RemoveRedEyeOutlined, ArchiveOutlined, UnarchiveOutlined } from "@material-ui/icons";
import { Permissions } from "@/config/Permissions";
import Theme from "@/themeStyles";

class OrganizationTableRow extends DLTableRow {

    constructor (props) {
        super(props);
        this.state = {
            archiving: false
        }
    }

    confirmationMessage() {
        const { data } = this.props;
        return (
            <span>
                Are you sure you want to archive {data.name}?
            </span>
        );
    }
    archive = async () => {
        this.setState({ isModalOpen: false });

        const { data } = this.props;
        try {
            await Api.organizations(data.id).delete();
            store.dispatch(setSnackbar(true, `${data.name} archived successfully`));
            this.props.reload();

        } catch (e) {
            console.error(e);
            store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
        }
    };

    returnStatusColor(status) {
        switch (status) {
            case "unpaid":
                return Theme.color.red.persianRed
            case "paid":
                return Theme.palette.primary.main;
            default:
                break;
        }
    }

    restore = async () => {
        this.setState({ isModalOpen: false });

        const { data } = this.props;

        try {
            await Api.organizations(data.id).restore.patch();
            store.dispatch(setSnackbar(true, `${data.name} restored successfully`));
            this.props.reload();

        } catch (e) {
            console.error(e);
            store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
        }
    };

    renderCells() {
        const { data } = this.props;
        const isTrial = moment().isSameOrBefore(data.trial_end);
        const isEnded = data.end_date && moment().isAfter(data.end_date);
        return [
            <DLTableColumn
                key={`Organization-${data.id}-Column-1`}
                withSeparator={true}>
                {data.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-3`}>
                {data.city}
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-4`}>
                {data.state}
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-5`}>
                {data.active_employees}
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-6`}>
                {data.plan_title}
            </DLTableColumn>,
            <DLTableColumn
               key={`Organization-${data.id}-Column-7`}>
               <Money amount={data.dues}/>
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-8`}
                align={"center"}>
                <Chip size="small" label={isEnded ? "INACTIVE" : (isTrial ? "TRIAL" : "ACTIVE")} color={isEnded ? "secondary" : (isTrial ? "warning" : "primary")}/>
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-9`}>
                {moment.utc(data.created_at).local().format("DD MMMM, YYYY")}
            </DLTableColumn>,
            <DLTableColumn
                key={`Organization-${data.id}-Column-10`}
                isCompact={true}
                align={"center"}
                fillWidth={35}>
                {
                    !data.is_archived &&
                    <Tooltip title="View Organization">
                        <Link to={{ pathname: `/organizations/${data.id}` }} style={{ textDecoration: 'none', color: "primary" }}>
                            <IconButton
                                size="small"
                                color="primary">
                                <RemoveRedEyeOutlined />
                            </IconButton>
                        </Link>
                    </Tooltip>
                }
            </DLTableColumn>,
            <UserRole routePermissions={[Permissions.ORGANIZATIONS_FULL_ACCESS]}>
                <DLTableColumn
                    key={`Organization-${data.id}-Column-11`}
                    isCompact={true}
                    align={"center"}
                    fillWidth="35">
                    {
                        !data.is_archived ? (
                            <>
                                <Tooltip title="Archive Organization">
                                    <IconButton
                                        onClick={() => this.setState({ isModalOpen: true })}
                                        size="small"
                                        color="secondary"
                                    >
                                        <ArchiveOutlined />
                                    </IconButton>
                                </Tooltip>

                                <ConfirmModal
                                    title="Archive Organization"
                                    isOpen={this.state.isModalOpen}
                                    message={`Are you sure you want to archive organization "${data.name}"?`}
                                    onClickCancel={() => {
                                        this.setState({ isModalOpen: false });
                                    }}
                                    onClickConfirm={this.archive} />
                            </>
                        ) : (
                            <>
                                <Tooltip title="Restore Organization">
                                    <IconButton
                                        onClick={() => this.setState({ isModalOpen: true })}
                                        size="small"
                                        color="primary"
                                    >
                                        <UnarchiveOutlined />
                                    </IconButton>
                                </Tooltip>

                                <ConfirmModal
                                    title="Restore Organization"
                                    isOpen={this.state.isModalOpen}
                                    message={`Are you sure you want to restore organization "${data.name}"?`}
                                    onClickCancel={() => {
                                        this.setState({ isModalOpen: false });
                                    }}
                                    onClickConfirm={this.restore} />
                            </>
                        )
                    }
                </DLTableColumn>
            </UserRole>
        ]
    }

}

OrganizationTableRow.Columns = [
    {
        key: "name",
        label: "Name",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "city",
        label: "City",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "state",
        label: "Province",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "active_employees",
        label: "Active Employees",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "plan_title",
        label: "Plan",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "dues",
        label: "Dues",
        align: "left",
        isSortable: true,
        withSeparator: false

    },
    {
        key: "status",
        label: "Status",
        align: "center",
        isSortable: false,
        withSeparator: false
    },
    {
        key: "created_at",
        label: "Member Since",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "action",
        label: "",
        align: "left",
        withSeparator: false,
        colSpan: 2
    }
];

export default withRouter(OrganizationTableRow);