import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Page } from "@/components";
import { setLoader } from "@/redux/actions/general";
import styles from "./style";
import ReimbursableList from "./ReimbursableList";

class Reimbursables extends Component {

	render() {
		const { classes } = this.props;
		return (
			<Page title="Reimbursements">
				<div className={classes.root}>
					<ReimbursableList />
				</div>
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Reimbursables));