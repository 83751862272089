import React from 'react';
import { ReactComponent as Reimbursement } from '../../assets/icons/reimbursment.svg';

function ReimbursementIcon() {

  return(
      <Reimbursement fill="currentColor"/>
  )
}
export default ReimbursementIcon;
