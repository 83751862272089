import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import BankDropdown from '@/components/Dropdowns/BankDropdown.js';
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
class EmployeeBankModal extends Component {

	static propTypes = {
		employee: PropTypes.object.isRequired,
		bankAccount: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onUpdate: PropTypes.func,
		onClose: PropTypes.func
	};

	static defaultProps = {
		title: "Add Bank Account",
		onUpdate: () => { }
	};

	constructor (props) {
		super(props);
		this.state = {
			errors: {},
			bankAccount: {}
		};
	}

	bankAccount() {
		return {
			bank_id: "",
			title: this.props.employee ? `${this.props.employee.first_name} ${this.props.employee.last_name}` : "",
			account_number: "",
			iban: "",
			swift: "",
			branch_name: "",
			branch_code: "",
			branch_address: "",
			...this.props.bankAccount,
			...this.state.bankAccount
		};
	}

	isNew() {
		return !this.props.bankAccount || !this.props.bankAccount.id;
	}

	handleChange(value, field) {
		const bankAccount = this.bankAccount();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			bankAccount: {
				...bankAccount,
				[field]: value
			},
			errors
		});
	}

	validate() {
		const bankAccount = this.bankAccount();
		const optionalFields = ["iban", "swift", "branch_name", "branch_code", "branch_address", "deleted_at", "is_archived"];
		var errors = {};

		_.forOwn(bankAccount, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const bankAccount = this.bankAccount();
		this.props.setLoader(true);
		try {
			var _bankAccount = null;
			if (this.isNew()) {
				_bankAccount = await Api.employees(this.props.employee.id).bank_accounts.post(bankAccount);
				this.props.setSnackbar(true, "New bank account added successfully");

			} else {
				_bankAccount = await Api.employees(this.props.employee.id).bank_accounts(bankAccount.id).put(bankAccount);
				this.props.setSnackbar(true, "Bank account updated successfully");
			}
			this.props.onUpdate(_bankAccount);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					const errors = _.values(_.mapValues(response.data, value => value[0]));
					if (errors && errors.length) {
						this.props.setSnackbar(true, errors[0], "error");
					} else {
						this.props.setSnackbar(true, "Something went wrong, please try again", "error")
					}
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	render() {
		const { isOpen, title } = this.props;
		const { errors } = this.state;
		const bankAccount = this.bankAccount();
		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={isOpen}
				aria-labelledby="employee-bank-dialog-title"
				onBackdropClick={this.props.onClose}
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-bank-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<BankDropdown
								value={bankAccount.bank_id || 0}
								onChange={bankId => this.handleChange(bankId, "bank_id")}
								disabled={!this.isNew()}
								textFieldProps={{
									error: !!errors.bank_id,
									helperText: errors.bank_id,
									FormHelperTextProps: { variant: "standard" },
									size: "small"
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Title"
								fullWidth
								error={!!errors.title}
								value={bankAccount.title || ""}
								onChange={e => this.handleChange(e.target.value, "title")}
								variant="outlined"
								helperText={errors.title}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Account Number"
								fullWidth
								variant="outlined"
								error={!!errors.account_number}
								value={bankAccount.account_number || ""}
								onChange={e => this.handleChange(e.target.value, "account_number")}
								helperText={errors.account_number}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="IBAN"
								fullWidth
								variant="outlined"
								error={!!errors.iban}
								value={bankAccount.iban || ""}
								onChange={e => this.handleChange(e.target.value, "iban")}
								helperText={errors.iban}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Swift Code"
								fullWidth
								variant="outlined"
								error={!!errors.swift}
								value={bankAccount.swift || ""}
								onChange={e => this.handleChange(e.target.value, "swift")}
								helperText={errors.swift}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Branch Name"
								variant="outlined"
								fullWidth
								error={!!errors.branch_name}
								value={bankAccount.branch_name || ""}
								onChange={e => this.handleChange(e.target.value, "branch_name")}
								helperText={errors.branch_name}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Branch Code"
								variant="outlined"
								fullWidth
								error={!!errors.branch_code}
								value={bankAccount.branch_code || ""}
								onChange={e => this.handleChange(e.target.value, "branch_code")}
								helperText={errors.branch_code}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Branch Address"
								fullWidth
								variant="outlined"
								error={!!errors.branch_address}
								value={bankAccount.branch_address || ""}
								onChange={e => this.handleChange(e.target.value, "branch_address")}
								helperText={errors.branch_address}
								FormHelperTextProps={{ variant: "standard" }}
								size="small"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.props.onClose} color="default">Cancel</Button>
					<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
						<Button size="small" variant="contained" onClick={this.save} color="primary">Save</Button>
					</UserRole>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeBankModal);