import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Fade, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Header } from "@/components";
import { AuthContext } from "@/contexts";
import { Menu } from "./drawerMenu";
import { setTitle } from "@/redux/actions/general";
import classNames from "classnames";
import styles from "./style";
import UserRole from "../UserRole";
import ModuleManager from "../ModuleManager";
import { AccessManager } from "@/helpers/";


class DrawerContainer extends Component {

	static contextType = AuthContext;

	constructor (props) {
		super(props);
		this.state = {
			open: false
		};
	}
	toggleDrawer = () => {
		this.setState({ open: !this.state.open });
	};

	selectMenu = item => {
		this.props.history.push(item.route, item.state);
		this.setState({ open: false });

		// TODO: Find better way of changing title
		this.props.setTitle(item.title);
	};

	isSelected = item => this.props.location.pathname === item.route;

	render() {
		const { classes, children } = this.props;
		return (
			<div className={classes.root}>
				<Header drawerAction={this.toggleDrawer} />

				<div className={classes.drawerContainer}>
					{
						!AccessManager.isPurchaseExpired(this.context.organization) &&
						<Drawer
							className={classes.drawer}
							classes={{ paper: classNames(classes.drawerPaper, { open: this.state.open }) }}
							variant="permanent"
							open>
							<List>
								{
									Menu.map((MenuItem, index) => {
										return (
											<ModuleManager key={index}
												modulePermissions={MenuItem.modulePermissions}>
												<UserRole
													key={index}
													routePermissions={MenuItem.routePermissions}>
													<Link to={MenuItem.route} className={classes.link}>
														<ListItem
															button
															className={classNames(classes.menu, { [classes.menuActive]: this.isSelected(MenuItem) })}
														>
															<ListItemIcon className={classes.menuIcon}>
																<MenuItem.Icon />
															</ListItemIcon>
															<ListItemText className={classes.menuWrapper}>
																<span className={classes.menuText}>
																	{MenuItem.title}
																</span>
															</ListItemText>
														</ListItem>
													</Link>
												</UserRole>
											</ModuleManager>
										)
									})
								}
							</List>
						</Drawer>
					}

					<div className={classes.childrenContainer}>
						{children}
						<Fade in={this.state.open}>
							<div className={classes.childrenContainerOverlay} onClick={this.toggleDrawer}></div>
						</Fade>
					</div>
				</div>
			</div>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setTitle: title => dispatch(setTitle(title))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DrawerContainer)));