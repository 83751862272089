import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";

class EmployeeDropdown extends Component {

    static defaultProps = {
        label: "Employee",
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        employee: null
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.employee || (this.state.employee.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const employee = await Api.employees(this.props.value).get();
                return this.setState({ employee });
            } catch (e) { }
        }
        this.setState({ employee: null });
    }

    getOptions = async (keyword = "") => {
        this.setState({ loading: true });
        let filter = [];

        if(this.props.isActive) {
            filter.push({
                field: "status",
                value: "active",
                method: "is",
                operator: "and",
            });
        }

        if (keyword !== "") {
            filter.push({
                field: "search",
                value: keyword,
                method: "contains",
                operator: "and",
            });
        }
        const employees = await Api.employees.get({ per_page: 50, filter });
        return employees.data;
    };

    renderOption = option => {
        return (
            <div className={this.props.classes.itemStyle}>
                <Chip
                    className={this.props.classes.dropdownChip}
                    size="small"
                    label={option.code}
                />
                {option.first_name} {option.last_name}
            </div>
        )
    };

    onChange = (event, employee) => {
        this.setState({ employee });
        this.props.onChange((employee && employee.id) || null);
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ root: classes.dropdown, paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.getOptions}
                getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                renderOption={this.renderOption}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.onChange}
                value={this.state.employee}
                disabled={!!disabled}
            />
        );
    }

}

export default withStyles(styles)(EmployeeDropdown);