import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";

class LeavesDropdown extends Component {

    static defaultProps = {
        label: "Leaves",
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        leaves: null,
        autoLoadFirst: this.props.loadFirst || false
    };

    loadLeave = async (keyword = "") => {
        this.setState({ loading: true });
        let encashable_only = this.props.filter && this.props.filter.encashable_only ? 1 : 0
        const response = await Api.leaves.get({
            per_page: 0,
            filter: { name: keyword, encashable_only }
        });
        
        if(this.state.autoLoadFirst) {
            this.setState({ leaves: !!response.data[0] ? response.data[0] : null, autoLoadFirst: false });
            this.props.onChange((response.data[0] && response.data[0].id) || null);
        }
        return response.data;
    };

    onChange = (event, leaves = {}) => {
        this.setState({ leaves });
        this.props.onChange((leaves && leaves.id) || null);
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ root: classes.dropdown, paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadLeave}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.onChange}
                value={this.state.leaves}
                disabled={!!disabled}
            />
        );
    }

}

export default withStyles(styles)(LeavesDropdown);