import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import { InfographicMessage, PayslipPayItems, ReminderNotificationChip } from "@/components";
import styles from "./style";
import _ from "lodash";
import { EmployeeAvatar } from "@/components";
import { bool, object } from "prop-types";

class RemindersModal extends Component {

    static propTypes = {
        viewOnly: bool,
        payslip: object.isRequired
    };

    static defaultProps = {
        viewOnly: false,
    };

    getReminders = () => {
        const { payslip } = this.props;
        return payslip.reminders.map((reminder) => {
            return {
                id: `${reminder.type}-${reminder.details.id}`,
                rate: reminder.type == 'pay_item' ? reminder.details.rate : 0,
                quantity: reminder.type == 'pay_item' ? reminder.details.quantity : 0,
                details: reminder.type == 'pay_item' ? reminder.details.details : reminder.details.pay_item,
                transaction: {
                    ...reminder.details,
                    amount: reminder.type == 'loan' ? reminder.amount : 0
                },
                is_reimbursable: reminder.type == 'reimbursement',
                is_loan: reminder.type == 'loan'
            };
        });
    }

    render() {
        const { classes, isOpen, payslip } = this.props;
        const reminders = this.getReminders();
       
        return (
            <Dialog
                fullWidth
                maxWidth={"sm"}
                open={isOpen}
                aria-labelledby="reminder-modal-title"
                onClose={this.props.onClose}>
                <DialogTitle id="reminder-modal-title">
                    <EmployeeAvatar data={payslip}/>
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    {
                        !!reminders.length &&  
                        <PayslipPayItems
                            payslip={payslip}
                            payItems={reminders}
                            heading="Reminders"
                            headingAddon={<ReminderNotificationChip count={reminders.length}/>}
                            color="warning"
                            isReminder
                            multiColumn
                            onUpdate={this.props.onUpdate}
                        />
                    }
                    {
                        !reminders.length &&
                        <InfographicMessage
                            illustration="reminder"
                            message="All caught up!"
                        />
                    }
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={this.props.onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(RemindersModal);