import React, { Component } from "react";
import { 
	Button, 
	CardActions, 
	CardContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Check, Close } from "@material-ui/icons";
import styles from "./style";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler, Utility } from "@/helpers";
import Config from "@/config";
import EmployeeInfoForm from "./form";
import _ from "lodash";
import moment from "moment";
import { AuthContext } from "@/contexts";
import PermissionHOC from "@/components/PermissionHOC";
import {Permissions} from "@/config/Permissions";

class EmployeeInfo extends Component {
	static contextType = AuthContext;

	_form = null;

	static propTypes = {
		employee: PropTypes.object,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		employee: {},
		onUpdate: () => { }
	};

	state = {
		errors: {},
		employee: {}
	};

	employee() {
		return {
			code: "",
			branch_id: "",
			role: null,
			first_name: "",
			department_id: "",
			designation_id: "",
			father_name: "",
			last_name: "",
			cnic: "",
			ntn: "",
			gender: "",
			work_email: "",
			personal_email: "",
			password: "",
			mobile: "",
			phone: "",
			emergency_contact: "",
			skype: "",
			dob: null,
			joined_at:null,
			confirmed_at: null,
			present_address: "",
			present_city: "",
			present_state: "",
			present_country: "",
			permanent_address: "",
			permanent_city: "",
			permanent_state: "",
			permanent_country: "",
			is_tax_exempted: false,
			...this.props.employee,
			...this.state.employee
		};
	}

	isNew() {
		return !this.props.employee || !this.props.employee.id;
	}

	handleChange(value, field) {
		const employee = this.employee();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		const addressFields = ["present_address", "present_city", "present_state", "present_country","present_country"];
		if ((field === 'has_single_address' && value) || (employee.has_single_address && addressFields.indexOf(field) >= 0)) {
			employee.permanent_address = employee.present_address;
			employee.permanent_city = employee.present_city;
			employee.permanent_state = employee.present_state;
			employee.permanent_country = employee.present_country;

			errors.permanent_address = false;
			errors.permanent_city = false;
			errors.permanent_state = false;
			errors.permanent_country = false;
		}

		this.setState({
			employee: {
				...employee,
				[field]: value
			},
			errors
		});
	}

	avatar = null;
	imageChangeHandler = (e) => {
		this.avatar = e ? e.target.files[0] : null;
	};

	validate() {
		const employee = this.employee();
		const optionalFields = ["father_name", "work_email", "personal_email", "phone", "emergency_contact", "skype", "ntn", "password", "deleted_at", "is_archived", "is_inactive", "has_single_address", "is_tax_exempted", "role"];
		var errors = {};

		if (!this.isNew()) {
			optionalFields.push("department_id");
			optionalFields.push("designation_id");
			optionalFields.push("joined_at");
			optionalFields.push("confirmed_at");
		}

		_.forOwn(employee, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;

			} else if (["work_email", "personal_email"].indexOf(key) !== -1 && value && !Utility.validateEmail(value)) {
				errors[key] = "Invalid email address";

			} else if (key === "password" && value && value.length < 6) {
				errors[key] = "Password must be at least 6 characters";

			} else if (key === "cnic" && !Utility.validateCNIC(value)) {
				errors[key] = "Invalid CNIC format, should be like 42101-1234567-1";

			} else if (key === "ntn" && value && !Utility.validateNTN(value)) {
				errors[key] = "Invalid NTN format, should be like 1234567-1";
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;
		const optionalFields = ["is_tax_exempted", "is_archived", "deleted_at", "created_at", "updated_at", "department", "designation", "role"];
		const employee = this.employee();
		const data = new FormData();

		if (!this.isNew()) {
			optionalFields.push("joined_at");
			optionalFields.push("confirmed_at");
		}

		_.forOwn(employee, (value, key) => {
			// Ignored keys
			if (optionalFields.indexOf(key) !== -1) return;

			// Send null values as empty string
			if (_.isNil(value)) {
				value = "";
			}

			if (key === "is_tax_exempted") {
				value = (value ? 1 : 0);
			}

			// Format dates as per server standard
			if (["dob", "confirmed_at", "joined_at"].indexOf(key) !== -1) {
				value = moment(value || "").format(Config.SERVER_DATETIME_FORMAT);
			}
			data.append(key, value);
		});

		// Append display picture (if changed)
		if (this.avatar) {
			data.append("avatar", this.avatar);
		}

		this.props.setLoader(true);
		try {
			var _employee;
			if (this.isNew()) {
				_employee = await Api.employees.post(data);
				this.props.setSnackbar(true, "New employee added successfully");
				this.props.history.replace(`/employees/${_employee.id}`, { employee: _employee });

			} else {
				data.delete("password");
				data.append("_method", "put");
				_employee = await Api.employees(employee.id).post(data);
				this.props.setSnackbar(true, "Employee information saved successfully");
			}
			this.props.onUpdate(_employee);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					if(response.data.message){
						this.props.setSnackbar(true, response.data.message, "error");
					}else{
						this.setState({ errors: _.mapValues(response.data, value => value[0]) })
						this.props.setSnackbar(true, "Please fix the highlighted errors", "error");

					}
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	resetForm() {
		this.setState({ employee: {} });
	}

	render() {
		const { classes, employee, setLoader, hasPermission } = this.props;
		return (
			<>
				<CardContent>
					<EmployeeInfoForm
						formRef={form => this._form = form}
						employee={employee}
						onSave={employee => this.isNew() && this.props.history.replace(`/employees/${employee.id}`, { employee })}
						setLoader={setLoader}
					/>
				</CardContent>
				<CardActions className={classes.actions}>
					{hasPermission([Permissions.EMPLOYEES_FULL_ACCESS, Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS]) &&
						<Button
							className={classes.actionButton}
							variant="contained"
							color="primary"
							onClick={e => this._form && this._form.save(e)}
						>
							<Check className={classes.actionButtonIcon} />
							Save
						</Button>
					}
					<Button
						className={classes.actionButton}
						variant="contained"
						color="secondary"
						onClick={() => this.props.history.push("/employees")}
					>
						<Close className={classes.actionButtonIcon} />
						Cancel
					</Button>
				</CardActions>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PermissionHOC(EmployeeInfo))));
