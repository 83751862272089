import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@material-ui/core";
import styles from "./style";

class ActionableCard extends Component {

    render() {
        const { image, title, description, action, classes } = this.props;
        return (
            <Card className={classes.card} raised onClick={action}>
                <CardActionArea className={classes.card}>
                    <CardMedia
                        component="img"
                        alt={title}
                        height="200"
                        image={image}
                        title={title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">{title}</Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

}

export default withStyles(styles)(ActionableCard);