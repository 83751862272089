import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles"
import styles from "./style.js";
import PropTypes from "prop-types";
import _, { isFunction } from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	Switch,
	FormControlLabel,
	CircularProgress,
	InputAdornment
} from "@material-ui/core";
import moment from "moment";
import Config, { Constants } from "@/config";
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import { FormattedNumericInput } from "@/components";

class EmployeePayItemModal extends Component {

	static propTypes = {
		employee: PropTypes.object.isRequired,
		payItem: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		title: "Edit Pay Item",
		onUpdate: () => { }
	};

	state = {
		errors: {},
		payItem: {},
		payItems: [],
		loading: false
	};

	async componentDidMount() {
		this.setState({ loading: true });
		try {
			const { data } = await Api.pay_items.get();
			this.setState({ payItems: data });
		} catch (e) {

		} finally {
			this.setState({ loading: false });
		}
	}

	payItem() {
		return {
			rate: 0,
			quantity: 0,
			frequency: "",
			starts_from: "",
			details: {},
			should_overwrite: true,
			...this.props.payItem,
			...this.state.payItem
		};
	}

	handleChange(value, field) {
		const payItem = this.payItem();
		const { errors } = this.state;
		
		if (errors[field]) errors[field] = false;

		this.setState({
			payItem: {
				...payItem,
				[field]: value
			},
			errors
		});
	}

	validate() {
		const payItem = this.payItem();
		const optionalFields = [
			"index",
			"rate",
			"quantity",
			"starts_from",
			"is_reminder",
			"deleted_at",
			"is_archived",
			"is_permanent",
			"is_fixed_rate",
			"should_overwrite",
			"disabled",
			"remaining",
			"quota"
		];

		var errors = {};
		_.forOwn(payItem, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});
		if (payItem.details.is_percentage && (payItem.rate < 0 || payItem.rate > 100)) {
			errors.rate = "Rate must be a valid percentage between 0 to 100"
		}
		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	prepareData = () => {
		let payItem = this.payItem();
		let data = {
			id: payItem.details.id
		}

		data.editItemId = payItem.id
		data.should_overwrite = payItem.should_overwrite;
		if (!payItem.details.is_fixed_rate) {
			data.rate = payItem.rate
		}

		if (!payItem.details.is_fixed_quantity) {
			data.quantity = payItem.quantity
		}

		if (!payItem.is_permanent) {
			data.frequency = payItem.frequency;
			if (payItem.starts_from) {
				data.starts_from = moment(payItem.starts_from).format(Config.SERVER_DATE_FORMAT);
				data.is_reminder = payItem.is_reminder;
			} else {
				data.is_reminder = false;
			}
		}

		return data;
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;
		const payItem = this.payItem();
		var data = this.prepareData();
		this.props.setLoader(true);
		try {
			const { employees = [] } = this.props;
			if (employees.length) {
				// data.pay_item_id = payItem.id;
				// for (let i = 0; i < employees.length; i++) {
				// 	await Api.employees(employees[i].id).pay_items.post(data);
				// }

				const { is_fixed_rate } = payItem.details;
				const toOmit = [];
				if (is_fixed_rate) toOmit.push("rate");
				if (payItem.is_permanent)
					toOmit.push("frequency", "starts_from", "is_reminder");

				var pay_item = _.omit(payItem, toOmit);

				await Api.employees.pay_items.create_all.put({ pay_item, employees: _.map(employees, e => e.id) });
				
				this.props.setSnackbar(true, `Pay item added successfully for ${employees.length} employee${employees.length == 1 ? '' : 's'}`);
				_.isFunction(this.props.onSave) && this.props.onSave();
				_.isFunction(this.props.onClose) && this.props.onClose();

			} else {
				const _payItem = await Api.employees(this.props.employee.id).pay_items(payItem.pay_item_id).put(data);
				this.props.setSnackbar(true, "Employee pay item saved successfully");
				_.isFunction(this.props.onUpdate) && this.props.onUpdate(_payItem);
				_.isFunction(this.props.onClose) && this.props.onClose();
			}

		} catch (e) {
			console.log(e)
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					const errors = _.values(_.mapValues(response.data, value => value[0]));
					if (errors && errors.length) {
						this.props.setSnackbar(true, errors[0], "error");
					} else {
						this.props.setSnackbar(true, "Something went wrong, please try again", "error")
					}
				})

				.go();
		}
		this.props.setLoader(false);
	};

	changePayItem = (index = 0) => {
		const payitem = this.state.payItems[index];

		const {
			id,
			name,
			default_rate,
			default_quantity,
			default_frequency,
		} = payitem;

		const payItem = {
			id,
			name,
			pay_item_id: id,
			rate: default_rate,
			details: payitem,
			quantity: default_quantity,
			frequency: default_frequency,
			starts_from: moment().format(Config.SERVER_DATE_FORMAT),
		};

		this.setState({ payItem });
	};

	renderPayItems = () => {
		const { payItems } = this.state;
		return (
			payItems.map((payItem, index) => (
				!payItem.is_leave && !payItem.is_overtime && !payItem.is_witholding_tax &&
				<MenuItem
					key={index}
					value={payItem}
					onClick={() => this.changePayItem(index)}
					children={payItem.name}
				/>
			))
		);
	};

	render() {
		const { title, isOpen, classes, bulkMode } = this.props;
		const { errors, loading } = this.state;
		const payItem = this.payItem();
		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={isOpen}
				aria-labelledby="employee-payitem-dialog-title"
				onBackdropClick={this.props.onClose}
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-payitem-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{
								bulkMode ? (
									<TextField
										select
										fullWidth
										label="Pay item"
										variant="outlined"
										error={!!errors.pay_item_id}
										value={payItem.details.name}
										children={this.renderPayItems()}
										FormHelperTextProps={{ variant: "standard" }}
										InputProps={{
											endAdornment: loading && (
												<CircularProgress
													size={20}
													color="inherit"
													className={classes.textFeildLoaderIndicator}
												/>
											)
										}}
										size={"small"}
									/>
								) : (
									<TextField
										disabled
										fullWidth
										label="Pay Item"
										variant="outlined"
										value={payItem.details.name || ""}
									/>
								)
							}
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputProps={{
									endAdornment: <InputAdornment position="end">{
										payItem.details.is_percentage ? Config.PERCENTAGE_SYMBOL : Config.CURRENCY_SYMBOL
									}</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								fullWidth
								disabled={payItem.details.is_fixed_rate}
								label="Rate"
								variant="outlined"
								error={!!errors.rate}
								value={payItem.rate}
								onChange={e => this.handleChange(e.currentTarget.value, "rate")}
								helperText={errors.rate}
								FormHelperTextProps={{ variant: "standard" }}
								size={"small"}
							/>
						</Grid>
						{
							!payItem.is_permanent &&
							<Grid item xs={12}>
								<TextField
									fullWidth
									select
									label="Frequency"
									variant="outlined"
									error={!!errors.frequency}
									helperText={errors.frequency}
									FormHelperTextProps={{ variant: "standard" }}
									value={payItem.frequency || ""}
									onChange={e => this.handleChange(e.target.value, "frequency")}
									size={"small"}
								>
									{
										Constants.payitem.paymentFrequency.map(frequency => (
											<MenuItem key={frequency} value={frequency}>
												{frequency}
											</MenuItem>
										))
									}
								</TextField>
							</Grid>
						}
						{
							!payItem.is_permanent &&
							<Grid item xs={12}>
								<DatePicker
									autoOk
									fullWidth
									error={!!errors.starts_from}
									label="Start Date"
									inputVariant="outlined"
									format={Config.DISPLAY_DATE_FORMAT}
									value={payItem.starts_from || null}
									onChange={e => this.handleChange(
										moment(e).format(Config.SERVER_DATE_FORMAT),
										"starts_from"
									)}
									size={"small"}
								/>
							</Grid>
						}
						{
							!payItem.is_permanent &&
							!payItem.details.is_reimbursable &&
							<Grid item xs={12}>
								<FormControlLabel
									value="start"
									label="Is Reminder"
									labelPlacement="start"
									className={classes.switch}
									control={
										<Switch
											color="primary"
											checked={payItem.is_reminder}
											size={"small"}
											onChange={_ => { this.handleChange(!payItem.is_reminder, "is_reminder") }} />
									}
								/>
							</Grid>
						}
						{
							bulkMode &&
							<Grid item xs={12}>
								<FormControlLabel
									value="start"
									labelPlacement="start"
									className={classes.switch}
									label="Overwrite Pay Item"
									control={
										<Switch
											color="primary"
											checked={payItem.should_overwrite}
											size={"small"}
											onChange={(_) => this.handleChange(!payItem.should_overwrite, "should_overwrite")} />
									}
								/>
							</Grid>
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size={"small"} onClick={this.props.onClose} color="default">Close</Button>
					<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
						<Button
							size={"small"}
							variant="contained"
							onClick={this.save}
							disabled={bulkMode && (!payItem.details.name || loading)}
							color="primary">Save</Button>
					</UserRole>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeePayItemModal));