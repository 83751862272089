import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import { Chip } from "@material-ui/core";
import _ from "lodash";
import AddBankAccount from "./../../pages/AddBankAccount";

class BankAccountDropdown extends Component {
    constructor (props) {
        super(props);
        this._asyncDropDown = React.createRef();
    }
    static defaultProps = {
        label: "Bank Account",
        onChange: () => { },
        resultType: "value"
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        bank: null,
        isModalOpen: false,
        textFieldValue: ""
    };

    modalclose = () => {
        this.setState({ isModalOpen: false }, async () => {
            await this._asyncDropDown.current.loadOptions()
        });
    }

    componentDidMount() {
        this.loadSelected();
    }

    
    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.bank || (this.state.bank.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const bank = await Api.bank_accounts(this.props.value).get();
                return this.setState({ bank });
            } catch(e) { }
        }
        this.setState({ bank: null });
    }
    
    loadOptions = async (keyword = "") => {
        this.setState({ textFieldValue: keyword })
        if (this.state.bank != null && keyword === this.state.bank.name) {
            keyword = "";
        }
        this.setState({ loading: true });
        var response = await Api.bank_accounts.get();
        var createNew = response.data;
        let bank = _.find(createNew, (item) => {
            return item.id === this.props.value;
        });
        this.setState({ bank });
        createNew = [...createNew, { name: "Create new bank", type: "bank_account" }];
        response.data = createNew;
        return response.data;
    };

    renderOption = option => (
        <div className={this.props.classes.itemStyle}>
            {option.name ? option.name : option.bank.name}
            {option.type !== "bank_account" && <Chip
                className={this.props.classes.dropdownChipRight}
                size="small"
                label={option.account_number}
            />}
        </div>
    );

    onChange = (event, bank) => {
        this.setState({ bank });
        if (this.props.resultType === "object" && bank && bank.id) {
            return this.props.onChange(bank);
        }
        if (bank && bank.id) {
            this.setState({ bank });
            this.props.onChange((bank && bank.id) || null);
        } else if (bank === null) {
            return
        } else {
            this.setState({ isModalOpen: true,textFieldValue: this.state.textFieldValue })
        };
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <>
            <AsyncDropdown
                ref={this._asyncDropDown}
                classes={{ root: classes.dropdown, paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadOptions}
                getOptionLabel={option => `${option.name ? option.name : option.bank.name} - ${option.account_number}`}
                renderOption={this.renderOption}
                getOptionSelected={(option, value) => option.id === value}
                onChange={this.onChange}
                value={this.state.bank}
                disabled={!!disabled}
            />
                {
                    this.state.isModalOpen &&
                    <AddBankAccount
                        avoidRoute={true}
                        backRoute={"goback"}
                        onClose={this.modalclose}
                        textFieldValue={this.state.textFieldValue}
                        onUpdate={async () => { await this._asyncDropDown.current.loadOptions() }}
                    />
                }
            </>);
    }

}

export default withStyles(styles)(BankAccountDropdown);