import Types from "@/components/Filter/types";
import { Constants } from "@/config";
const data = [
	{
		key: "name",
		label: "Employee Name",
		type: Types.string,
		example: 'Ahmed'
	},
	{
		key: "employee.code",
		label: "Employee Code",
		type: Types.string,
		example: 'EMP-001'
	},
	{
		key: "employee.work_email",
		label: "Work Email",
		type: Types.string,
		example: 'ahmed@company.com'
	},
	{
		key: "created_at",
		label: "Request Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "loan_amount",
		label: "Amount",
		type: Types.number,
		example: 50000
	},
	{
		key: "status",
		label: "Status",
		type: Types.selection,
		options: [
			{
				key: "all_active",
				label: "All Active"
			},
			{
				key: Constants.Loan.FLAG_PENDING,
				label: "Pending"
			},
			{
				key: Constants.Loan.FLAG_REJECTED,
				label: "Rejected"
			},
			{
				key: Constants.Loan.FLAG_APPROVED,
				label: "Approved"
			},
			{
				key: Constants.Loan.FLAG_GRANT,
				label: "Granted"
			},
			{
				key: Constants.Loan.FLAG_PARTIAL,
				label: "Partial"
			},
			{
				key: Constants.Loan.FLAG_RETURNED,
				label: "Returned"
			},
		]
	}
];

export default data;