const styles = theme => {
	return ({
		infographicMessage: {
			marginBottom: theme.spacing(2)
		},
		header: {
			display: "flex",
			height: "100px",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			backgroundColor: theme.palette.divider
		},
		rejectButton: {
			color: theme.palette.danger.main
		},
		clock: {
			flex: 1,
			fontSize: "4rem",
			fontWeight: "lighter",
			lineHeight: 1,
			marginRight: theme.spacing(2),
			paddingRight: theme.spacing(2),
			borderRight: `1px ${theme.palette.primary.light} solid`,

			[theme.breakpoints.down("sm")]: {
				fontSize: "2rem",
			}
		},

		dateContainer: {
			marginRight: theme.spacing(2),
			paddingRight: theme.spacing(2),
			borderRight: `1px ${theme.palette.primary.light} solid`
		},

		year: {
			fontSize: "2.5rem",
			fontWeight: "lighter",
			lineHeight: 1,
		},
		amountFont: {
			fontSize: "1.2rem"
		},
		date: {
			fontSize: "0.85rem"
		},

		statsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between"
		},

		stats: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-end",

			"& .MuiTypography-root": {
				fontSize: "3rem",
				lineHeight: 1
			},

			"&.right": {
				marginLeft: theme.spacing(2)
			}
		},

		attendanceWidget: {
			display: "flex",
			flexDirection: "row",
			alignItems: "flex-start"
		},

		attendanceWidgetSheet: {
			flex: 1,
			marginLeft: theme.spacing(2),
			overflow: "auto"
		},

		fab: {
			marginRight: "10px",
		},
		statusPaid: {
			backgroundColor: "blue",
			paddingTop: "10px",
			paddingRight: "10px",
			paddingBottom: "10px",
			paddingLeft: "10px"
		},
		extendedIcon: {
			marginRight: theme.spacing(1),

			[theme.breakpoints.down("sm")]: {
				marginRight: 0
			}
		},

		editFab: {
			width: 32,
			height: 32,
			minHeight: 32,
			marginLeft: theme.spacing(0.5),
			boxShadow: theme.shadows[1]
		},

		editFabIcon: {
			fontSize: "1.2rem"
		},

		extendedText: {
			[theme.breakpoints.down("sm")]: {
				display: "none"
			}
		},

		chip: {
			paddingLeft: "6px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		},

		chipText: {
			fontSize: "0.8125rem",
			lineHeight: "1.43"
		},

		avatar: {
			border: `1px ${theme.palette.borderColor.main} solid`,
			boxShadow: theme.shadows[2]
		},
		employeeItem: {
			padding: 0
		},
		createBtn: {
			margin: "10px"
        },
        extraImage:{
            width: "40px",
            background: theme.palette.extraImage.main,
            height: "40px",
            borderRadius: "6px",
            cursor:"pointer",
			marginLeft:"4px"

        },
        extraImageText: {
            fontSize: "12px",
            textAlign: "center",
            fontFamily: 'Roboto',
            fontWeight: "bolder"  
        },
        reimburseImage: {
            width: "40px",
            height: "40px",
            borderRadius: "6px",
            margin: "0px 2px",
            cursor: "pointer"
        },
		link: {
			textDecoration: 'none', 
			color: "primary"
		}
	
	})
};
export default styles;