// TODO: [TN-353] Refactor ExitEmployeeModal to match code structure with other modals

import React, {
	Fragment,
	useState,
	forwardRef,
	useImperativeHandle,
} from "react";
import {
	Box,
	Grid,
	Slide,
	Switch,
	Avatar,
	Dialog,
	Button,
	MenuItem,
	TextField,
	CardActions,
	DialogTitle,
	DialogActions,
	FormControlLabel,
	Fade,
	Collapse,
} from "@material-ui/core";
import { DLTableColumn, DLTable, DLTableRow, EmployeeAvatar } from "@/components";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Api, HttpErrorHandler } from "@/helpers";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import store from "@/redux";
import Config from "@/config";
import { isFunction, pick, map, mapValues, values } from "lodash";
import moment from "moment";

const sepratorIds = [0];
const column = ["NAME", "JOINING DATE", "EXIT DATE", "STATUS"];
const statuses = [
	{ value: "dismissed", name: "Dismissed" },
	{ value: "demised", name: "Demised" },
	{ value: "handicapped", name: "Handicapped" },
	{ value: "retired", name: "Retired" },
	{ value: "resigned", name: "Resigned" },
];

const Status = (props) => (
	<TextField
		select
		fullWidth
		variant="outlined"
		FormHelperTextProps={{ variant: "standard" }}
		children={map(statuses, ({ name, value }) => (
			<MenuItem value={value} children={name} />
		))}
		{...props}
	/>
);

const PickerForDate = (props) => (
	<DatePicker
		autoOk
		fullWidth
		inputVariant="outlined"
		format={Config.DISPLAY_DATE_FORMAT}
		FormHelperTextProps={{ variant: "standard" }}
		{...props}
	/>
);

//Transitions
const SlideUp = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} /> );
const FadeIn = forwardRef((props, ref) => <Fade ref={ref} {...props} /> );
class RowTemplate extends DLTableRow {
	addParam = (param) => {
		const {
			params,
			rowIndex,
			setParams,
			data: {
				current_position,
				current_position: { start_date },
			} = {},
		} = this.props;
		const { end_date, end_reason } = params[rowIndex];

		params[rowIndex] = {
			...current_position,
			end_date: moment(
				moment(param.end_date).isBefore(start_date)
					? start_date
					: param.end_date || end_date
			).format(Config.SERVER_DATE_FORMAT),
			end_reason: param.end_reason || end_reason,
			start_date: moment(start_date).format(Config.SERVER_DATE_FORMAT),
		};

		setParams([...params]);
	};

	componentDidUpdate(prevProps) {
		const { allParam, applyAll } = this.props;
		if (
			allParam !== prevProps.allParam ||
			(applyAll !== prevProps.applyAll && !!applyAll)
		) {
			this.addParam(this.props.allParam);
		}
	}

	renderCells() {
		const {
			params,
			applyAll,
			rowIndex,
			allParam,
			data: {
				id,
				code,
				last_name,
				first_name,
				current_position: { start_date },
			} = {},
		} = this.props;

		const { end_date, end_reason } = params[rowIndex];
		const error = moment(applyAll ? allParam.end_date : end_date).isBefore(start_date);

		const row = [
			<Grid container spacing={2} alignItems="center">
				<Grid item>
					<EmployeeAvatar data={{
							employee: {
								id: id,
								first_name: first_name,
								last_name: last_name,
								code: code
							}
						}}
					/>
				</Grid>
		</Grid>,
			moment(start_date).format("DD MMMM, YYYY"),
			<Fragment>
				<PickerForDate
					value={end_date}
					disabled={!!applyAll}
					minDate={moment(start_date)}
					onChange={(end_date) => this.addParam({ end_date })}
				/>
				<Collapse in={error}>
					<Box m={1} />
					<Alert 
						severity="warning"
						children="This maybe an incorrect date please verify!"
					/>
				</Collapse>
			</Fragment>,
			<Status
				value={end_reason}
				placeholder="Status"
				disabled={!!applyAll}
				onChange={({ target: { value: end_reason } }) =>
					this.addParam({ end_reason })
				}
			/>,
		];

		return map(row, (item, index) => (
			<DLTableColumn
				children={item}
				withSeparator={sepratorIds.includes(index)}
				key={`Payment-Payslip-${index}-Column-1`}
			/>
		));
	}
}

RowTemplate.Columns = map(column, (label, index) => ({
	label,
	align: "left",
	key: `${label}_${index}`,
	withSeparator: sepratorIds.includes(index),
}));

const Content = ({
	applyAll,
	data = [],
	params = [],
	setParams = () => {},
}) => {
	const [allParam, setAllParam] = useState({
		end_date: moment(),
		end_reason: statuses[0].value,
	});

	const applyAllParam = (param) =>
		setAllParam({
			...allParam,
			...param,
		});

	return (
		<Box>
			<Box px={1.8} display="flex">
				<PickerForDate
					label="Exit Date"
					disabled={!applyAll}
					value={allParam.end_date}
					onChange={(end_date) => applyAllParam({ end_date })}
				/>
				<Box p={1} />
				<Status
					label="Status"
					disabled={!applyAll}
					value={allParam.end_reason}
					onChange={({ target: { value: end_reason } }) =>
						applyAllParam({ end_reason })
					}
				/>
			</Box>
			<Box p={1} />
			<DLTable
				data={data}
				RowTemplate={RowTemplate}
				rowProps={{
					allParam,
					params,
					applyAll,
					setParams,
				}}
			/>
		</Box>
	);
};

const Layout = ({
	data = [],
	loading,
	onClose = () => {},
	onSave = () => {},
	...props
}) => {
	const [error, setError] = useState(false);
	const [applyAll, setApplyAll] = useState(true);
	const [params, setParams] = useState(
		data.map(({ current_position, current_position: { start_date } }) => ({
			...current_position,
			end_reason: statuses[0].value,
			end_date: moment().format(Config.SERVER_DATE_FORMAT),
			start_date: moment(start_date).format(Config.SERVER_DATE_FORMAT),
		}))
	);

	const contentProps = {
		data,
		params,
		applyAll,
		setError,
		setParams,
		...props,
	};

	const save = async (data) => {
		store.dispatch(setLoader(true));
		try {
			await Api.employees.employee_positions.bulk_exit.put({
				data : data.map(employee => pick(employee, [
					'id',
					'employee_id',
					'end_date', 
					'end_reason'
				]))
			});

			isFunction(onSave) && onSave(data);
			isFunction(onClose) && onClose();

			// setSelected([]);
		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => store.dispatch(setSnackbar(true,  (values(mapValues(response.data, value => value[0]))[0]) , "error")))
				.default(_ => store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error")))
				.go();
		} finally {
			store.dispatch(setLoader(false));
		}
	};

	return (
		<Fragment>
			<Box display="flex" justifyContent="space-between">
				<DialogTitle id="exit-employee" children="Exit employee" />
				<CardActions>
					<FormControlLabel
						label="Apply to all"
						labelPlacement="start"
						style={{ marginRight: 0 }}
						control={
							<Switch
								name="checkedB"
								color="primary"
								checked={applyAll}
								onChange={({ target: { checked } }) => setApplyAll(checked)}
							/>
						}
					/>
				</CardActions>
			</Box>
			<Content {...contentProps} />
			<DialogActions>
				<Button onClick={onClose} color="default" children={"Cancel"} />
				<Button
					color="primary"
					disabled={error}
					children={"Save"}
					variant="contained"
					onClick={() => save(params)}
				/>
			</DialogActions>
		</Fragment>
	);
};

const DeleteAdminError = () => (
	<Alert severity="error">
		<AlertTitle>Exit Employee</AlertTitle>
		You can not exit yourself
	</Alert>
);

const ExitEmployeeModal = ({ ...props }, ref) => {
	const deleteAdmin = !props.data.length;

	return (
		<Dialog
			fullWidth
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="exit-employee"
			maxWidth={deleteAdmin ? "xs" : "md"}
			TransitionComponent={deleteAdmin ? SlideUp : FadeIn}
			children={deleteAdmin ? <DeleteAdminError/> : <Layout {...props}/>}
		/>
	);
};

export default forwardRef(ExitEmployeeModal);
