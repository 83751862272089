import React from "react";
import { withRouter, Link } from "react-router-dom";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn } from "@/components";
import moment from "moment";
import { Launch } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";

class LoanLedgerRow extends DLTableRow {

    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                key={`LoanLedger-${data.id}-Column-1`}
                withSeparator={true}>
                {data.paid_date ? moment(data.paid_date).format("DD-MM-YYYY") : "N/A"}
            </DLTableColumn>,
            <DLTableColumn
                key={`LoanLedger-${data.id}-Column-2`}
                withSeparator={true}>
                {
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {data.description}
                        </Grid>
                        <Grid item style={{padding: "0px", marginTop: "5px"}}>
                            {
                                data.is_manual_transaction ? 
                                    <Link to={`/loans/${data.loan_request_id}`} target="_blank">
                                        <Launch
                                            style={{cursor: "pointer", fontSize: "16px"}}
                                            color="primary"
                                        />
                                    </Link> :
                                    <Link to={{pathname:`/payroll/${data.payrun_id}/payslip/${data.payslip_id}` }} target="_blank">
                                        <Launch
                                            style={{cursor: "pointer", fontSize: "16px"}}
                                            color="primary"
                                        />
                                    </Link>
                            }
                        </Grid>
                    </Grid>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`LoanLedger-${data.id}-Column-3`}
                align="right">
                {currencyFormatter(data.amount > 0 ? data.amount : 0)}
            </DLTableColumn>,
            <DLTableColumn
                key={`LoanLedger-${data.id}-Column-4`}
                align="right">
                {currencyFormatter(data.amount < 0 ? (data.amount * -1) : 0)}
            </DLTableColumn>,
            <DLTableColumn
                key={`LoanLedger-${data.id}-Column-5`}
                align="right">
                {currencyFormatter(data.balance)}
            </DLTableColumn>
        ];
    }

}

LoanLedgerRow.Columns = [
    {
        key: "paid_date",
        label: "Date",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "description",
        label: "Description",
        align: "left",
        isSortable: false,
        withSeparator: true
    },
    {
        key: 'amount',
        label: "Granted",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: 'amount',
        label: "Deducted",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: 'balance',
        label: "Running Balance",
        align: "right",
        isSortable: false,
        withSeparator: false
    }
];

export default withRouter((withStyles(styles)(LoanLedgerRow)));