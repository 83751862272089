import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { DLTable, InfographicMessage, Page } from "@/components";
import PayrunTableRow from "./PayrunTableRow";
import Api from "@/helpers/Api";
import UserRole from "@/components/UserRole";
import PermissionHOC from "@/components/PermissionHOC";
import { AccessManager } from "@/helpers/";
import { AuthContext } from "@/contexts";

class Payrun extends Component {
	static contextType = AuthContext;
	addPayrun = () => {
		this.props.history.push("/payroll/run");
	};

	emptyView() {
		const { hasPermission, permissions } = this.props;
		if (hasPermission([permissions.PAYROLL_EDITABLE, permissions.PAYROLL_FULL_ACCESS])) {
			return (
				<InfographicMessage
					illustration="payroll"
					message="You haven't run Payroll yet, run your first payroll now!"
					actionLabel="Run Payroll"
					actionIcon="add"
					actionHandler={this.addPayrun}
				/>
			);
		}

		return (
			<InfographicMessage
				illustration="payroll"
				message="You haven't run Payroll yet!"
			/>
		);
	}

	render() {
		const { permissions } = this.props;
		const isTaxModule = AccessManager.isModuleAccess("TAX", this.context.organization);
		const removeColumn = !isTaxModule ? ["taxes","employee_payment"] : []
		return (
			<Page title="Payroll">
				<Card raised>
					<UserRole routePermissions={[permissions.PAYROLL_EDITABLE, permissions.PAYROLL_FULL_ACCESS]}>
						<CardActions>
							<Link to={{ pathname: `/payroll/run` }} style={{ textDecoration: 'none', color: "primary" }}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									onClick={this.addPayrun}>
									<Icon>add</Icon>
									Run Payroll
								</Button>
							</Link>
						</CardActions>
					</UserRole>
					<DLTable
						api={Api.payruns}
						RowTemplate={PayrunTableRow}
						removeColumn={removeColumn}
						emptyView={this.emptyView()} />
				</Card>
			</Page>
		);
	}
}

export default withRouter(PermissionHOC(Payrun));