import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style";
import _ from "lodash";
import BankDropdown from '@/components/Dropdowns/BankDropdown.js';

class AddBankAccount extends Component {

    constructor (props) {
        super(props);
        this.state = {
            bank_id: "",
            title: "",
            account_number: "",
            iban: "",
            swift: "",
            branch_name: "",
            branch_code: "",
            branch_address: "",
            bankAccountId: "",
            bank: {},
            errors: {},
            titleMain: props.title || "Add Bank Acccount"
        };
    }
    closeDialog = () => {
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            this.props.history.push("/settings/accounts");
        }
    }

    componentDidMount() {
        let params = {
            data: {
                data: { page: 1 }
            }
        };
        this.componentDidMountUpdateBankAccount();
    };
    async componentDidMountUpdateBankAccount() {
        if (this.props.match.params && this.props.match.params.bankId > 0) {
            this.props.setLoader(true);
            this.setState({ isLoading: true, isEdit: true });
            let response = await Api.bank_accounts(
                this.props.match.params.bankId
            ).get();
            this.setState({
                bank_id: response.bank.id,
                bankAccountId: response.id,
                title: response.title,
                account_number: response.account_number,
                iban: response.iban,
                swift: response.swift,
                branch_name: response.branch_name,
                branch_code: response.branch_code,
                branch_address: response.branch_address,
            }, () => {
                this.props.setLoader(false);
            });
        }
    }

    handleValidate = () => {
        var errors = {};
        if (this.state.bank_id === "") {
            errors.bank_id = "Bank field is required";
        }
        if (this.state.title === "") {
            errors.title = "Title field is required";
        }
        if (this.state.account_number === "") {
            errors.account_number = "Account field is required";
        }
        this.setState({ errors: errors });
        return (_.size(errors) > 0) ? false : true;
    };

    CreateBankAccount = async () => {
        if (this.handleValidate()) {
            var data = new FormData();
            this.props.setLoader(true);
            data.append("bank_id", this.state.bank_id);
            data.append("title", this.state.title);
            data.append("account_number", this.state.account_number);
            if(this.state.iban) {
                data.append("iban", this.state.iban);
            }
            if(this.state.swift) {
                data.append("swift", this.state.swift);
            }
            if(this.state.branch_name) {
                data.append("branch_name", this.state.branch_name);
            }
            if(this.state.branch_code) {
                data.append("branch_code", this.state.branch_code);
            }
            if(this.state.branch_address) {
                data.append("branch_address", this.state.branch_address);
            }
            try {
                if (!this.props.match.params.bankId) {
                    await Api.bank_accounts.post(data);
                } else {
                    data.append("_method", "put");
                    await Api.bank_accounts(this.props.match.params.bankId).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    );
                }
                this.props.setSnackbar(true, this.props.match.params.id ? "Bank Account Updated" : "Bank Account Added");
                this.props.history.push("/settings/accounts");
            }
            catch (e) {
                HttpErrorHandler.intercept(e)
				.on(422, response => {
					const errors = _.values(_.mapValues(response.data, value => value));
					if (errors && errors.length) {
						this.props.setSnackbar(true, errors, "error");
					} else {
						this.props.setSnackbar(true, "Something went wrong, please try again", "error")
					}
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		
            }
            this.props.setLoader(false);
        }
    }

    handleChange = (e, fieldName) => {
        if (fieldName === "bank_id") {
            this.setState({ [fieldName]: e });

        } else {
            this.setState({ [fieldName]: e.target.value });
            if (this.state.errors[fieldName] === true) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [fieldName]: false
                    }
                });
            }
        }
    };
    render() {
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={"new"}
                onBackdropClick={this.closeDialog}
                aria-labelledby="version-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="version-dialog-title">{this.state.titleMain}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <div >
                                <BankDropdown
                                    fullWidth
                                    value={this.state.bank_id || 0}
                                    onChange={bank_id => this.handleChange(bank_id, "bank_id")}
                                    textFieldProps={{
                                        size:"small",
                                        error: !!this.state.errors.bank_id,
										helperText: this.state.errors.bank_id,
                                        FormHelperTextProps: { variant: "standard" }
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={this.state.errors.title}
                                label="Title"
                                value={this.state.title}
                                fullWidth
                                size={"small"}
                                onChange={(e) => this.handleChange(e, "title")}
                                variant="outlined"
                                helperText={this.state.errors.title}
                                    FormHelperTextProps={{ variant: "standard" }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={this.state.errors.account_number}
                                label="Account Number"
                                fullWidth
                                size={"small"}
                                value={this.state.account_number}
                                onChange={(e) => this.handleChange(e, "account_number")}
                                variant="outlined"
                                helperText={this.state.errors.account_number}
                                FormHelperTextProps={{ variant: "standard" }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="IBAN"
                                fullWidth
                                error={this.state.errors.iban}
                                size={"small"}
                                value={this.state.iban}
                                onChange={(e) => this.handleChange(e, "iban")}
                                helperText={this.state.errors.iban}
                                FormHelperTextProps={{ variant: "standard" }}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Swift"
                                fullWidth
                                error={this.state.errors.swift}
                                size={"small"}
                                value={this.state.swift}
                                onChange={(e) => this.handleChange(e, "swift")}
                                helperText={this.state.errors.swift}
                                FormHelperTextProps={{ variant: "standard" }}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Branch Name"
                                size={"small"}
                                value={this.state.branch_name}
                                onChange={(e) => this.handleChange(e, "branch_name")}
                                error={this.state.errors.branch_name}
                                fullWidth
                                helperText={this.state.errors.branch_name}
                                FormHelperTextProps={{ variant: "standard" }}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Branch Code"
                                fullWidth
                                error={this.state.errors.branch_code}
                                helperText={this.state.errors.branch_code}
                                FormHelperTextProps={{ variant: "standard" }}
                                size={"small"}
                                value={this.state.branch_code}
                                onChange={(e) => this.handleChange(e, "branch_code")}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Branch Address"
                                fullWidth
                                size={"small"}
                                value={this.state.branch_address}
                                onChange={(e) => this.handleChange(e, "branch_address")}
                                error={this.state.errors.branch_address}
                                helperText={this.state.errors.branch_address}
                                FormHelperTextProps={{ variant: "standard" }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>

                    <Button size="small" color="black" onClick={() => {
                        this.closeDialog()
                    }}>
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" color="primary" onClick={this.CreateBankAccount}>
                        {this.state.bankAccountId !== "" ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>
        )

    }
}
AddBankAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    departments: state.departments.list,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddBankAccount)));