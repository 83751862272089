import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";


const countries = [
	"Sindh",
	"Khyber Pakhtunkhwa",
	"Punjab",
	"Balochistan",
	"Gilgit-Baltistan",
	"Azad Jammu and Kashmir"
];

const defaultProps = {
	label: "States/Province",
	options: countries,
};

class StatesDropdown extends Component {

	state = {

		country: "",
		open: false,
		options: [],
		loading: false,
		keyword: ""
	};

	renderInput = params => {
		return (
			<TextField variant="outlined"
				label={this.props.label}
				fullWidth
				InputProps={{
					endAdornment: (
						<React.Fragment>
							{this.state.loading && <CircularProgress color="inherit" size={20} />}
							{params.InputProps.endAdornment}
						</React.Fragment>
					),
					...params.InputProps,
				}}
				{...params}
				{...this.props.textFieldProps} />)
	};

	onChange = (event, country) => {
		this.setState({ country });
		this.props.onChange((country) || null);
	}

	renderOption = (option, state) => {
		return (
			<span className={this.props.classes.itemStyle} style={{
				borderTop: option.type ? "1px solid #CCCC" : null
			}}>
				{option.name}
			</span>
		);
	}

	render() {
		const { loading } = this.state;
		const { disabled, label } = this.props;
		return (
			<Autocomplete
				classes={{
					popper: this.props.classes.paper,
					option: this.props.classes.option,
					...this.props.classes
				}}

				id="disabled"
				autoComplete
				loading={loading}
				disabled={disabled}
				label={label}
				size={this.props.size}
				renderInput={this.renderInput}
				onChange={this.onChange}
				value={this.props.value}
				{...defaultProps} />
		);
	}

}

StatesDropdown.defaultProps = {
	onChange: () => { },
	noOptionsText: keyword => !keyword ? "Type to search available options" : `"${keyword}" not found`
};

StatesDropdown.propTypes = {
	getOptions: PropTypes.func.isRequired,
	optionLabel: PropTypes.func.isRequired,
	size: PropTypes.func.isRequired,
	renderOption: PropTypes.func,
	textFieldProps: PropTypes.object,
	onChange: PropTypes.func,
	noOptionsText: PropTypes.func
};

export default withStyles(styles)(StatesDropdown);