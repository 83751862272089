import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import {Typography, Avatar} from '@material-ui/core';
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import { Api } from "@/helpers";

import BirthdayIcon from "@/assets/dashboard/birthdays.png";
import AnniversaryIcon from "@/assets/dashboard/anniversary.png";
import BonusesIcon from "@/assets/dashboard/bonuses.png";
import DuePayItems from "@/assets/dashboard/duePayItems.png";

class ReminderWidget extends React.Component {

	onClickEmployee = (id) => {
		this.props.history.push(`/employees/${id}`);
	}

	render() {
		const { classes, reminders } = this.props;
		let duePayItems = _.values(_.groupBy(reminders.duePayItems, "id"));
		let stepData = [
			{
				data: reminders.birthdays,
				key: "dob",
				icon: BirthdayIcon,
				label: "Birthdays",
				className: "backgroundBirthday"
			},
			{
				data: reminders.jobAnniversaries,
				key: "joined_at",
				icon: AnniversaryIcon,
				label: "Job Anniversaries",
				className: "backgroundAnniversery"
			},
			{
				data: reminders.endingProbations,
				key: "confirmed_at",
				icon: BonusesIcon,
				label: "Ending Probation",
				className: "backgroundProbation"
			}
		];
		return (
			<>
				{
					stepData.map((stepItem, index) => {
						return (
							<div key={index} className={classNames(classes.reminderRoot, index === 0 && classes.noTopBorder)}>
								<div className={classNames(classes.stepRoot, classes[stepItem.className])} >
									<img src={stepItem.icon} alt={stepItem.label} className={classes.reminderIcon} />
								</div>
								<Typography className={classes.steperLabel}>{stepItem.label}</Typography>
								<div className={classes.list}>
									{	
										stepItem.data.length === 0 ? (
											<Typography className={classes.emptyMsg}>No {stepItem.label.toLocaleLowerCase()} in this month</Typography>
										) : (
											stepItem.data.map(item => {
												const date = stepItem.key === "dob" ? item[stepItem.key] : item.current_position[stepItem.key];
												return (
													<div key={item.id} className={classes.name}>
														<div className={classes.employeeLink} onClick={() => { this.onClickEmployee(item.id)}}>
															<Avatar sizes="small" className={classes.employeeAvatar} src={Api.employees(item.id).avatar.getUrl(true)} />
														</div>
														<span className={classes.nameAndDate}>
															<div className={classNames(classes.employeeLink)} onClick={() => { this.onClickEmployee(item.id)}}>
																<Typography>{item.first_name + " " + item.last_name}</Typography>
															</div>
															<Typography className={classes.reminderDate}>{moment(date).format('Do MMM')}</Typography>
														</span>
													</div>
												)
											})
										)
									}
								</div>
							</div>
						);
					})
				}
				<div className={classNames(classes.reminderRoot)}>
					<div className={classNames(classes.stepRoot, classes.backgroundPayItem)}>
						<img src={DuePayItems} alt={"Due Pay Items"} className={classes.reminderIcon} />
					</div>
					<Typography className={classes.steperLabel}>Due Pay Items</Typography>
					{
						duePayItems.length === 0 ? (
							<Typography className={classNames(classes.emptyMsg, classes.emptyMsgPayItem)}>No due pay items in this month</Typography>
						) : (

							duePayItems.map((data, dataIndex) => {
								return (
									<span key={dataIndex}>
										<div className={classes.dueItemName} onClick={() => this.onClickEmployee(data[0].id)}>
											<Avatar sizes="small" className={classes.employeeAvatar} src={Api.employees(data[0].id).avatar.getUrl(true)} />
											<Typography className={classNames(classes.steperLabel, classes.stepperSubLebel)}>{data[0].first_name + " " + data[0].last_name}</Typography>
										</div>
										<div className={classNames(classes.list, classes.dueItemDivider)} style={(dataIndex === (duePayItems.length - 1) )? {borderBottomWidth: 0, paddingTop: 0, paddingBottom: 0} : {}}>
											{
												data.map(item => {
													return (
														<Typography key={item.pay_item_name} className={classNames(classes.name, classes.dueItem)}>
															{item.pay_item_name}
															<span className={classes.reminderDate}>{moment(item.due_at).format('Do MMM')}</span>
														</Typography>
													)
												})
											}
										</div>
									</span>
								);
							})
						)
					}
				</div>
			</>
		);
	}
}

export default withStyles(styles)(withRouter(ReminderWidget));
