import React, { Component } from "react";
import { CardMedia, Typography } from "@material-ui/core";
import { Page } from "@/components";
import { Grid } from "@material-ui/core";
import ContactUs from "@/assets/backgrounds/contact_us.png";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import "typeface-roboto";

class Expire extends Component {

	render() {
		const { classes } = this.props;
		console.log(classes)
		return (
			<Page title="">
				<Grid container className={classes.main} spacing={3}>
					<Grid item  className={classes.section} sm={6} xs={12} style={{paddingLeft: '10vw'}}>
						<Typography component="div" className={classes.textHeading}>Whoops!</Typography>
						<Typography component="div" className={classes.text}>Your subscription has expired.</Typography>
						<Typography component="div" className={classes.text}>
							Please contact <a href="mailto:salam@hassanabbasi.com" className={classes.underlineStyle} style={{color : "#0D72FF"}}>salam@hassanabbasi.com</a>
						</Typography>
					</Grid>
					<Grid item align="center" className={classes.section} sm={6} xs={12}>
						<CardMedia className={classes.banner} src={ContactUs} component="img"></CardMedia>
					</Grid>
				</Grid>
			</Page>
		);
	}
}

export default withStyles(styles)(Expire);
