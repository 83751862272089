import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DLTable }from "@/components";
import  EmployeePositionModal from "@/components/Modals/EmployeePositionModal";
import EmployeePositionTableRow from "./EmployeePositionTableRow";
import { Api } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import {
	Button,
	Icon,
	CardActions
} from "@material-ui/core";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";

class EmployeePosition extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			employee: props.employee
		};
	}

	_table = null;

	getEmployee = async () => {
		try {
			const employee = await Api.employees(this.props.employee.id).get();
			this.props.onUpdate(employee);
			this.setState({ employee });
			this._table.fetchData();
		} catch (e) {
			this.props.history.replace("/employees");
			this.props.setSnackbar(true, "Unable to get employee details, please try again", "error");
		}
	}

	render() {
		const { isOpen, employee } = this.state;
		return (
			<>
				<UserRole routePermissions={[ Permissions.EMPLOYEES_FULL_ACCESS ]}>
					<CardActions>
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							onClick={() => this.setState({ isOpen: true })}>
							<Icon>add</Icon>
							Add New Position
						</Button>
					</CardActions>
				</UserRole>
				<DLTable
					ref={table => this._table = table}
					api={Api.employees(employee.id).employee_positions}
					RowTemplate={EmployeePositionTableRow}
					rowProps={{ employee, onUpdate: this.getEmployee }}
				/>
				{
					isOpen && 
					<EmployeePositionModal
						employee={employee}
						onUpdate={this.getEmployee}
						onClose={() => this.setState({ isOpen: false })}
					/>
				}
			</>
		);
	}
}


const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withRouter(EmployeePosition));
