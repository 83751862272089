import Types from "../Filter/types";

export default [
    {
        key: "date",
        label: "Date",
        type: Types.date,
        example: '2022/03/23'
    },
    {
        key: "amount",
        label: "Amount",
        type: Types.number,
        example: '50000'
    },
    {
        key: "comment",
        label: "Comment",
        type: Types.string,
        example: "Something"
    },
];