const styles = theme => ({

	divider: {
		height: 1,
		flex: 1,
		display: "flex",
		backgroundColor: theme.palette.borderColor.main
	}

});

export default styles;