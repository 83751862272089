import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, CardMedia, Grid, InputAdornment, TextField, Typography, Card, CardContent, Container } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import { 
	Page,
	InfographicMessage
} from "@/components";
import { AuthContext } from "@/contexts";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style";
import Logo from "@/assets/logo/rsz_al_burhan_logo.png";

class Reset extends Component {

	static contextType = AuthContext;
	
	constructor(props) {
		super(props);
		this.state = {
			credentials: {
				password: "",
				confirm_password: ""
			},
			isResetCodeValid: null
		};
	}

	async componentDidMount() {
		if(this.props.match.params.code) {
			try {
				await Api.reset.validate.post({ code: this.props.match.params.code});
				this.setState({ isResetCodeValid: true });

			} catch (err) {
				this.setState({ isResetCodeValid: false });
			}
		}
	}

	inputChangeHandler = (e) => {
		if (e.target.name) {
			const { credentials } = this.state;
			this.setState({
				credentials: {
					...credentials,
					[e.target.name]: e.target.value
				}
			});
		}
	};

	validate() {
		const { password, confirm_password } = this.state.credentials;

		if (!password && !confirm_password) {
			this.props.setSnackbar(true, "Both fields are required!", "error");
			return false;
		} else if (password !== confirm_password) {
			this.props.setSnackbar(true, "Confirm Password not match", "error");
			return false;
		}
		return true;
	}

	reset = async (e) => {
		e.preventDefault();

		if (!this.validate()) return;

		this.props.setLoader(true);
		try {
			const { password, confirm_password } = this.state.credentials;
			await Api.reset.password.post({ password, confirm_password, code: this.props.match.params.code });
			this.props.history.push('/');

		} catch(e) {
			HttpErrorHandler.intercept(e)
				.on(400, response => {
					this.props.setSnackbar(true, response.data.message, "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	render() {
		const { classes } = this.props;
		const { credentials, isResetCodeValid } = this.state;
		
		return (
			<Page title="Reset Password">
				<div className={classes.root}>
					<Grid item xs={12} sm={8} md={5}>
						<Card raised className={classes.frosted}>
							<CardContent>
								<CardMedia
									className={classes.logo}
									image={Logo}
									title="Al-Burhan Attendance"
								/>
								{
									!isResetCodeValid &&
									<Container className={classes.infoGraphic}>
										<InfographicMessage
											illustration="not_found"
											message={"Invalid or expired link"}
										/>
									</Container>
								}
								{
									isResetCodeValid &&
									<form className={classes.form} onSubmit={this.forgotPassword}>
										<Typography
											className={classes.heading}
											variant="h6"
											component="h1"
											align="center"
											gutterBottom>
											Reset Credentials
										</Typography>
										
										<TextField
											className={classes.formControl}
											placeholder="Password"
											value={credentials.password}
											name="password"
											type="password"
											onChange={this.inputChangeHandler}
											margin="dense"
											variant="outlined"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<Mail style={{ color: "grey" }} />
													</InputAdornment>
												),
											}}
										/>
										<TextField
											className={classes.formControl}
											placeholder="Confirm Password"
											value={credentials.confirm_password}
											name="confirm_password"
											onChange={this.inputChangeHandler}
											margin="dense"
											type="password"
											variant="outlined"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<Mail style={{ color: "grey" }} />
													</InputAdornment>
												),
											}}
										/>
										
										<Button
											className={classes.button}
											type="submit"
											onClick={this.reset}
											variant="contained"
											color="primary">
											Save
										</Button>
									</form>
								}
							</CardContent>
						</Card>
					</Grid>
				</div>
			</Page>
		);
	}

}

const mapDispatchToProps = (dispatch) => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Reset)));
