import Types from "./types";

export default {
    [Types.string]: [
        {
            key: "contains",
            label: "matches",
        },
        {
            key: "is",
            label: "is",
        },
        {
            key: "starts with",
            label: "starts with",
        },
        {
            key: "ends with",
            label: "ends with",
        },
    ],
    [Types.number]: [
        {
            key: "is",
            label: "is",
        },
        {
            key: "greater than",
            label: "is greater than",
        },
        {
            key: "less than",
            label: "is less than",
        },
        {
            key: "greater than or equals to",
            label: "is greater than or equals to",
        },
        {
            key: "less than or equals to",
            label: "is less than or equals to",
        }
    ],
    [Types.date]: [
        {
            key: "is",
            label: "is",
        },
        {
            key: "before",
            label: "is before",
        },
        {
            key: "after",
            label: "is after",
        },
        {
            key: "same or before",
            label: "is same or before",
        },
        {
            key: "same or after",
            label: "is same or after",
        }
    ],
    [Types.selection]: [
        {
            key: "is",
            label: "is"
        }
    ]
};