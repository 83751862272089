const style = theme => ({
    avatar: {
        width: 40,
        height: 40,
        border: `1px ${theme.palette.borderColor.main} solid`,
        boxShadow: theme.shadows[2]
    },
    link: {
        textDecoration: "none", 
        color: 'black'
    },
    cursor: {
        cursor: "pointer"
    },
    text:{
        overflow:"hidden" ,
        whiteSpace:"nowrap", 
        textOverflow:"ellipsis"
    }
});

export default style;