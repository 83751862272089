const style = theme => ({

    successColor: {
        color: theme.palette.success.main
    },

    column: {
        borderRight: `1px ${theme.palette.primary.background} solid`,
        
        '&:nth-last-child(1)': {
            borderRight: 'none'
        }
    },

    encashmentHeader: {
        backgroundColor: theme.palette.primary.background,
        padding: 15
    },
    
    deductionHeader:{
        backgroundColor: theme.palette.danger.background,
        padding: 15
    },

    fieldListContainer: {
        width: '100%',
        display: 'flex',
        
        '&.multi': {
            justifyContent: 'center',

            '& $fieldContainer': {
                width: "50%",

                '&:nth-last-child(1):nth-child(even), &:nth-last-child(2):nth-child(odd)': {
                    borderBottom: 'none'
                },

                '&:nth-child(odd) + $fieldContainer': {
                    borderLeft: `1px ${theme.palette.primary.background} solid`
                }
            }
        }
    },
    
    fieldContainer: {
        width: '100%',
        padding: theme.spacing(2),
        borderBottom: `1px ${theme.palette.primary.background} solid`,
        
        '&:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },

    calculationRow: {
		display: 'flex',
		flexDirection: 'row',
		padding: 3,
		justifyContent: 'space-between',
        alignItems: 'center',

        '&.sub $calculationHeading, &.sub $calculationValue': {
            color: '#E20613'
        },

        '&.total $calculationValue': {
            borderTop: `1px #153F6D solid`,
            borderBottom: `1px #153F6D solid`
        },

        '&.deduct $calculationValue': {
            borderTop: `1px #153F6D solid`
        },

        '& input': {
            textAlign: 'right',
            fontSize: '0.855rem',
            padding: theme.spacing(0.6)
        }
	},

    calculationHeading: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		color: '#153F6D',
		fontWeight: 300,
		fontStyle: 'normal',
        fontSize: '0.855rem',
        flex: 0.5
	},

    calculationValue: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontWeight: 500,
		color: '#153F6D',
		fontStyle: 'normal',
        fontSize: '0.855rem',
        flex: 0.5,
        paddingRight: theme.spacing(0.6),
        alignItems: 'center'
	},

    calculationField: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontWeight: 500,
		color: '#153F6D',
		fontStyle: 'normal',
        fontSize: '0.855rem',
        flex: 0.5
	},

    leaveTitle: {
        display: 'flex',
		flexDirection: 'row',
		fontWeight: 500,
		color: '#153F6D',
		fontStyle: 'normal',
        fontSize: '0.875rem',
        marginBottom: theme.spacing(1)
    },

    footer: {
        padding: '20px',
        flexDirection:'row', 
        display:'flex', 
        borderTop: `2px ${theme.palette.primary.background} solid`,
        justifyContent:'space-between'
    },
    
    statsContainer:{
        flexDirection:'row',
        display:'flex',
        justifyContent:'space-between'
    }

});

export default style;