import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	InputAdornment
} from '@material-ui/core';
import { connect } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import Config from "@/config";
import moment from "moment";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import { FormattedNumericInput } from "@/components";

class EmployeeOtherIncomeModal extends Component {

	static propTypes = {
		employee: PropTypes.object.isRequired,
		otherIncome: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onUpdate: PropTypes.func,
		onClose: PropTypes.func
	};

	static defaultProps = {
		title: "Add Other Income",
		onUpdate: () => { }
	};

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			otherIncome: {}
		};
	}

	otherIncome() {
		return {
			date: moment().format("YYYY-MM-DD"),
			amount: null,
			comment: "",
			...this.props.otherIncome,
			...this.state.otherIncome
		};
	}

	isNew() {
		return !this.props.otherIncome || !this.props.otherIncome.id;
	}

	handleChange(value, field) {
		const otherIncome = this.otherIncome();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			otherIncome: {
				...otherIncome,
				[field]: value
			},
			errors
		});
	}

	validate() {
		const otherIncome = this.otherIncome();
		const optionalFields = ["flags"];
		var errors = {};

		_.forOwn(otherIncome, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const otherIncome = this.otherIncome();
		this.props.setLoader(true);
		try {
			var _otherIncome = null;
			if (this.isNew()) {
				_otherIncome = await Api.employees(this.props.employee.id).other_incomes.post(otherIncome);
				this.props.setSnackbar(true, "New other income added successfully");

			} else {
				_otherIncome = await Api.employees(this.props.employee.id).other_incomes(otherIncome.id).put(otherIncome);
				this.props.setSnackbar(true, "Other Income updated successfully");
			}
			this.props.onUpdate(_otherIncome);
			this.props.onClose()

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	render() {
		const { isOpen, title } = this.props;
		const { errors } = this.state;
		const otherIncome = this.otherIncome();
		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={isOpen}
				aria-labelledby="employee-tax-dialog-title"
				onBackdropClick={this.props.onClose}
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-tax-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<DatePicker
								autoOk
								fullWidth
								error={!!errors.date}
								helperText={errors.date}
								FormHelperTextProps={{ variant: "standard" }}
								label="Date"
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={otherIncome.date || null}
								onChange={date => this.handleChange(moment(date).format(Config.SERVER_DATE_FORMAT), "date")}
								size={"small"}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputProps={{
									endAdornment: <InputAdornment position="end">{ Config.CURRENCY_SYMBOL }</InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								label="Amount"
								fullWidth
								error={!!errors.amount}
								value={otherIncome.amount || ""}
								onChange={e => this.handleChange(e.currentTarget.value, "amount")}
								variant="outlined"
								helperText={errors.amount}
								FormHelperTextProps={{ variant: "standard" }}
								size={"small"}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Comment"
								multiline
								fullWidth
								rows={5}
								variant="outlined"
								error={!!errors.comment}
								value={otherIncome.comment || ""}
								onChange={e => this.handleChange(e.target.value, "comment")}
								helperText={errors.comment}
								FormHelperTextProps={{ variant: "standard" }}
								size={"small"}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.props.onClose} color="default">Cancel</Button>
					<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
						<Button size="small" variant="contained" onClick={this.save} color="primary">Save</Button>
					</UserRole>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeOtherIncomeModal);