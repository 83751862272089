const style = theme => ({

    proRateContainer:{
        margin: "5px 0px 0px 5px",
        display: "flex",
        flexDirection: "row"
    },
    
    warningIcon:{
        height: 18,
        width: 18,
        color: "#F3BA51"
    }

});

export default style;