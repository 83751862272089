import React, { Component } from "react";
import Proptypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Api } from "@/helpers";
import { BankAccountDropdown, PDFViewer, InfographicMessage } from "@/components";

class SalaryDisbursementLetter extends Component {

	static propTypes = {
		payrun: Proptypes.number.isRequired,
		onClose: Proptypes.func.isRequired,
		open: Proptypes.bool
	};

	static defaultProps = {
		open: false
	};

	state = {
		bank: null,
		enableDownload: false
	};

	download = () => {
		const { payrun } = this.props;
		const { bank } = this.state;
		if (payrun && bank) {
			window.open(
				Api.payruns(payrun).export["bank-letter"](bank).getUrl(),
				"_blank"
			);
		}
	};

	render() {
		const { bank, enableDownload } = this.state;
		const { payrun, onClose, open } = this.props;
		return (
			<Dialog
				open={open}
				onClose={onClose}
				scroll="paper"
				onBackdropClick={this.props.onClose}
				aria-labelledby="salary-disbursement-dialog-title"
				aria-describedby="salary-disbursement-dialog-description"
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="salary-disbursement-dialog-title">
					<div>Salary Disbursement Letter</div>
					<BankAccountDropdown
						textFieldProps={{ margin: "dense" }}
						value={bank}
						onChange={bank => this.setState({ bank })} />
				</DialogTitle>
				<DialogContent dividers={true}>
					{
						payrun && bank ? (
							<PDFViewer
								url={Api.payruns(payrun).export["bank-letter"](bank).getUrl()}
								onLoadSuccess={() => this.setState({ enableDownload: true })}
								onLoadError={() => this.setState({ enableDownload: false })}
							/>
						) : (
							<InfographicMessage
								illustration="salary_letter"
								message="Please select bank to export salary disbursement letter"
							/>
						)
					}
				</DialogContent>
				<DialogActions>
					<Button size={"small"} onClick={onClose}>Cancel</Button>
					<Button
						size={"small"}
						onClick={this.download}
						disabled={!payrun || !bank || !enableDownload}
						color="primary">
						Download
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

}

export default SalaryDisbursementLetter;