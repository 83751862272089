import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Card, CardActions, Icon, Grid } from "@material-ui/core";
import {
    BackButton,
    DLTable,
    InfographicMessage,
    Page
} from "@/components";
import HolidaysTableRow from "./HolidaysTableRow";
import { Api } from "@/helpers";
import HolidaysModal from "@/components/Modals/HolidaysModal";

class Holiday extends Component {
    state = {
        isEditing: false,
        isDeleting: false
    };
    onUpdate = () => {
        this.props.onUpdate();
    };
    emptyView() {
        return (
            <InfographicMessage
                illustration="Holiday"
                message="You don't have any data yet"
                actionLabel={"Add New "}
                actionIcon={"add"}
                actionHandler={() => this.setState({ isEditing: "new" })}
            />
        );
    }

    render() {
        const { isEditing } = this.state;
        return (
            <Page title="Holidays">
                <Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
                    <BackButton route="/settings" label="Back to Settings" />
                </Link>
                <Card raised>
                    <CardActions>
                        <Grid container justify={"space-between"}>
                            <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                onClick={() => this.setState({ isEditing: "new" })} >
                                <Icon>add</Icon>
                                Add New
                            </Button>
                        </Grid>
                    </CardActions>
                    <DLTable
                        ref={_table => this._table = _table}
                        api={Api.organization_calendar}
                        RowTemplate={HolidaysTableRow}
                        emptyView={this.emptyView()}
                    />
                </Card>
                {isEditing && (
                    <HolidaysModal
                        mode={isEditing}
                        title="Add New"
                        onUpdate={() => {
                            this._table && this._table.fetchData()
                        }}
                        onClose={() => this.setState({ isEditing: false })} />
                )}
            </Page>
        );
    }
}

export default withRouter(Holiday);