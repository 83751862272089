import React, { Component } from "react";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { Inbox, ArchiveOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { DLTable, Tabs, InfographicMessage, Page, BackButton }from "@/components";
import PlanTableRow from "./PlanTableRow";
import Api from "@/helpers/Api";

class Plan extends Component {

	addPlan = () => {
		this.props.history.push("/settings/plans/add");
	};
	
	emptyView(archived = false) {
		const message = !archived
			? "You don't have any plan yet, add first plan now!"
			: "Yaayyy! all plans are active";
		return (
			<InfographicMessage
				illustration="salary_ledger"
				message={message}
				actionLabel={archived ? "" : "Add Plan"}
				actionIcon={archived ? "" : "add"}
				actionHandler={this.addPlan}
			/>
		);
	}

	actionBar() {
		return (
			<CardActions>
				<Button
					variant="outlined"
					size="medium"
					color="primary"
					onClick={this.addPlan}>
					<Icon>add</Icon> 
					Add Plan
				</Button>
			</CardActions>
		);
	}

	render() {
		return (
			<Page title="Plans">
				<Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
					<BackButton route="/settings" label="Back to Settings" />
				</Link>
				<Card raised>
					<Tabs beforePanel={this.actionBar()}>
						<Tabs.Tab
							icon={<Inbox />}
							label="Active">
							<DLTable
								api={Api.plans}
								RowTemplate={PlanTableRow}
								emptyView={this.emptyView()}/>
						</Tabs.Tab>
						<Tabs.Tab
							icon={<ArchiveOutlined />}
							label="Archived">
							<DLTable
								api={Api.plans}
								params={{ filter : { archived: true } }}
								RowTemplate={PlanTableRow}
								emptyView={this.emptyView(true)}/>
						</Tabs.Tab>
					</Tabs>
				</Card>
			</Page>
		);
	}
}

export default Plan;