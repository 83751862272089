import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card } from "@material-ui/core";
import { PageTitle, PlanInfo, BackButton, Page } from "@/components";
import { Api } from "@/helpers";
import { setSnackbar } from "@/redux/actions/general";
import styles from "./style";
import { setLoader } from "@/redux/actions/general";
class PlanEdit extends React.Component {
  state = {
    plan: {},
  };

  async componentDidMount() {
    this.props.setLoader(true);
    if (this.props.match.params.id) {
      try {
        const plan = await Api.plans(this.props.match.params.id).get();
        this.setEmployee(plan);
      } catch (e) {
        this.props.history.replace("/employees");
        this.props.setSnackbar(
          true,
          "Unable to get employee details, please try again",
          "error"
        );
      }
    }
  }

  setEmployee = (plan) => {
    return new Promise((resolve) => {
	  this.setState({ plan }, resolve);
	  this.props.setLoader(false);
	  
    });
  };

  render() {
    const { plan } = this.state;
    return (
      <Page title="Plans">
        <BackButton route="/settings/plans" label="Back to Plans" />
        <PageTitle>Edit Plan</PageTitle>
        <Card raised>
          <PlanInfo plan={plan} pageType="add" />
        </Card>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSnackbar: (...args) => dispatch(setSnackbar(...args)),
  setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PlanEdit)));
