import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Avatar,
	Chip,
	Fab,
	Icon,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Tooltip,
	Typography
} from "@material-ui/core";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import UserRole from "@/components/UserRole";
import { AuthContext } from "@/contexts";
import { Permissions } from "@/config/Permissions";
import PermissionHOC from "@/components/PermissionHOC";
class AttendanceTableRow extends DLTableRow {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			isCheckInModalOpen: false,
			isCheckOutModalOpen: false,
			isConfirmationModalOpen: false
		}
		this.extractData();
	}

	createAttendance = async (dateTime, isCheckIn = true) => {
		const { date, data, getTime } = this.props;
		try {
			const time = await getTime(isCheckIn ? "Check In" : "Check Out", dateTime);
			try {
				await Api.attendance.post({
					employee_id: data.id,
					force: true,
					type: isCheckIn ? "check-in" : "check-out",
					date: moment(date).format("YYYY-MM-DD"),
					time: time ? moment(moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm:ss")).unix() : null
				});
				store.dispatch(setSnackbar(true, `Attendance marked for ${data.first_name}`));
				this.props.reload();
				this.extractData();
			} catch (e) {
				console.error(e);
				store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
			}
		} catch (e) { }
	};

	updateAttendance  = async (id, dateTime, isCheckIn = false) => {
		const { data, getTime } = this.props;
		
		try {
			const time = await getTime(isCheckIn ? "Check In" : "Check Out", dateTime);
			try {
				await Api.attendance(id).put({
					force: true,
					time: time ? moment(moment(data.date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm:ss")).unix() : null
				});
				store.dispatch(setSnackbar(true, `Attendance update for ${data.first_name}`));
				this.props.reload();

			} catch (e) {
				console.error(e);
				store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
			}
		} catch (e) { }
	};

	deleteAttendance = async (id) => {

		try {
			await Api.attendance(id).delete();
			this.setState({ isConfirmationModalOpen: false });
			store.dispatch(setSnackbar(true, `Attendance delete successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	extractData = () => {
		const { data } = this.props;

		_.filter(data.attendance, (attendance) => {
			if (attendance.type === "check-in") {
				this.checkIn = attendance;
			}
		});

		_.filter(_.reverse(data.attendance), (attendance) => {
			if (attendance.type === "check-out") {
				this.checkOut = attendance;
			}
		});
	}

	renderCells() {
		const { isConfirmationModalOpen } = this.state;
		const {
			data,
			classes,
			hasPermission
		} = this.props;
		const permissionsArray = [Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS, Permissions.EMPLOYEES_ATTENDANCE_VIEWABLE, Permissions.ATTENDANCE_EDITABLE, (this.context.user.id === data.id && Permissions.ATTENDANCE_EDITABLE)]
		return [
			<DLTableColumn key={`Attendance-${data.id}-Column-1`}>
				<EmployeeAvatar data={{
					employee: {
						id: data.id,
						first_name: data.first_name,
						last_name: data.last_name,
						code: data.code
					}
				}} classes={classes} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-2`}
				align={"left"}>
				<Chip
					classes={{ label: classes.chip }}
					label={<><Icon className={classes.extendedIcon}>local_phone</Icon> {data.mobile}</>}
					size="medium"
				/>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-3`}
				isCompact={true}
				align={"center"}
			>
				{
					!data.check_in ? (
						<>
							<Tooltip title="Check In">
								<Fab
									className={classes.fab}
									onClick={() => this.createAttendance(data.check_in, true)}
									size="small"
									color="primary"
									variant="extended"
									disabled={!hasPermission(permissionsArray)}
								>
									<Icon className={classes.extendedIcon}>timer</Icon>
									<span className={classes.extendedText}>Check In</span>
								</Fab>
							</Tooltip>
						</>
					) : (
						<>
							<Tooltip title="Check In">
								<Chip
									classes={{ label: classes.chip }}
									label={
										<>
											<Icon
												className={classes.extendedIcon}
												color="primary">
												timer
											</Icon>
											<Typography
												component="span"
												color="primary"
												size="small"
												classes={{ root: classes.chipText }}>
												{moment.unix(data.check_in).format("h:mm A")}
											</Typography>
										</>
									}
									size="medium"
								/>
							</Tooltip>
							<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>
								<Tooltip title="Edit Check In">
									<Fab
										className={classes.editFab}
										onClick={() => this.updateAttendance(data.check_in_id, data.check_in, true)}
										size="small"
									>
										<Icon className={classes.editFabIcon} color="primary">edit</Icon>
									</Fab>
								</Tooltip>
							</UserRole>
							<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>
								<Tooltip title="Delete Check In">
									<Fab
										className={classes.editFab}
										onClick={() => this.setState({ isConfirmationModalOpen: "check-in" })}
										size="small"
									>
										<Icon className={classes.editFabIcon} color="secondary">delete</Icon>
									</Fab>
								</Tooltip>
								<ConfirmModal
									isOpen={isConfirmationModalOpen === "check-in"}
									title={"Confirm"}
									message={`Are you sure you want to delete check-in`}
									onClose={() => this.setState({ isConfirmationModalOpen: false })}
									onClickCancel={() => this.setState({ isConfirmationModalOpen: false })}
									onClickConfirm={() => this.deleteAttendance(data.check_in_id)}
								/>
							</UserRole>
						</>
					)
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-4`}
				isCompact={true}
				align={"center"}
			>
				{
					!data.check_out ? (
						<>
							<Tooltip title="Check Out">
								<span>
									<Fab
										onClick={() => this.createAttendance(data.check_out, false)}
										size="small"
										color="secondary"
										// disabled={!data.check_in}
										variant="extended"
										disabled={!data.check_in || !hasPermission(permissionsArray)}
									>
										<Icon className={classes.extendedIcon}>timer_off</Icon>
										<span className={classes.extendedText}>Check Out</span>
									</Fab>
								</span>
							</Tooltip>
						</>
					) : (
						<>
							<Tooltip title="Check Out">
								<Chip
									classes={{ label: classes.chip }}
									label={
										<>
											<Icon
												className={classes.extendedIcon}
												color="secondary">
												timer_off
											</Icon>
											<Typography
												component="span"
												color="secondary"
												size="small"
												classes={{ root: classes.chipText }}>
												{moment.unix(data.check_out).format("h:mm A")}
											</Typography>
										</>
									}
									size="medium"
								/>
							</Tooltip>
							<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>
								<Tooltip title="Edit Check Out">
									<Fab
										className={classes.editFab}
										onClick={() => this.updateAttendance(data.check_out_id, data.check_out, false)}
										size="small"
									>
										<Icon className={classes.editFabIcon} color="secondary">edit</Icon>
									</Fab>
								</Tooltip>
							</UserRole>
							<ConfirmModal
								isOpen={isConfirmationModalOpen === "check-out"}
								title={"Confirm"}
								message={`Are you sure you want to delete check-out`}
								onClose={() => this.setState({ isConfirmationModalOpen: false })}
								onClickCancel={() => this.setState({ isConfirmationModalOpen: false })}
								onClickConfirm={() => this.deleteAttendance(data.check_out_id)}
							/>
							<UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>
								<Tooltip title="Delete Check Out">
									<Fab
										className={classes.editFab}
										onClick={() => this.setState({ isConfirmationModalOpen: "check-out" })}
										size="small"
									>
										<Icon className={classes.editFabIcon} color="secondary">delete</Icon>
									</Fab>
								</Tooltip>
							</UserRole>
						</>
					)
				}
			</DLTableColumn>
		]
	}

}

AttendanceTableRow.Columns = [
	{
		key: "first_name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "mobile",
		label: "Mobile",
		align: "left",
		isSortable: true,
		withSeparator: false
	}, {
		key: "check_in",
		label: "Check In",
		align: "right",
		isSortable: true,
		withSeparator: false,
		isCompact: true
	}, {
		key: "check_out",
		label: "Check Out",
		align: "right",
		isSortable: true,
		withSeparator: false,
		isCompact: true
	}
];

export default withRouter(withStyles(styles)(PermissionHOC(AttendanceTableRow)));

