import { ArchiveOutlined, EditOutlined, UnarchiveOutlined } from "@material-ui/icons";
import { DLTableColumn, DLTableRow, SVGIcons,ShiftDays, UserRole} from "@/components";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import React from "react";
import { Tooltip, IconButton, Grid } from "@material-ui/core";
import { setSnackbar } from "@/redux/actions/general";
import store from "@/redux";
import styles from "./style";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { AccessManager } from "@/helpers";
import config, { Modules } from "@/config";
import { AuthContext } from "@/contexts";
import { humanizedDuration } from "@/helpers/generalHelper";
import { HelpOutline } from "@material-ui/icons";

class ShiftsTableRow extends DLTableRow {

	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		}
	}

	archive = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.shifts(data.id).delete()
			store.dispatch(setSnackbar(true, `Shift item "${data.name}" archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.shifts(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `Shift "${data.name}" restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data } = this.props;
		return [
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-2`}
				align={"left"}>
				<ShiftDays data={data} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-4`}
				align={"left"}>
				{moment(data.start_time, "hh:mm:ss").format("hh:mm A")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-5`}
				align={"left"} >
				{moment(data.end_time, "hh:mm:ss").format("hh:mm A")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-6`}
				align={"left"}>
				{humanizedDuration(data.grace_time / 60)}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-3`}
				align={"left"}>
				{humanizedDuration(data.shift_hours)}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-4`}
				align={"left"}>
				{humanizedDuration(data.working_hours)}
			</DLTableColumn>,
			<>
				{
					AccessManager.isModuleAccess(Modules.OVERTIME, this.context.organization) &&
					<DLTableColumn
						key={`Shiftrun-${data.id}-Column-6`}
						align={"left"}>
						{data.is_overtime_enabled ? `${data.overtime_grace} min${data.overtime_grace == 1 ? '' : 's'}` : '—'}
					</DLTableColumn>
				}
			</>,
			<>
				{
					AccessManager.isModuleAccess(Modules.OVERTIME, this.context.organization) &&
					<DLTableColumn
						key={`Shiftrun-${data.id}-Column-6`}
						align={"left"}>
						{data.is_overtime_enabled ? `${data.overtime_multiplier}x` : '—'}
					</DLTableColumn>
				}
			</>,
			<DLTableColumn
				key={`Payrun-${data.id}-Column-7`}
				fillWidth={35}>
				{
					data.is_auto_assign &&
					<Tooltip title="Assigned to new employees automatically">
						<SVGIcons icon="default" />
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-8`}
				isCompact={true}
				align={"right"}
				fillWidth={35}
			>
				{
					!data.is_archived &&
					<Tooltip title="Edit Shift Item">
						<IconButton
							onClick={() => this.props.history.push(`shifts/${data.id}`)}
							size="small"
							color="primary">
							<EditOutlined />
						</IconButton>
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Shiftrun-${data.id}-Column-9`}
				isCompact={true}
				align={"right"}
				fillWidth={35}
			>
				{
					!data.is_archived &&
					<>
						<Tooltip title="Archive Shift Item">
							<IconButton
								onClick={() => this.setState({ isModalOpen: true })}
								size="small"
								color="secondary">
								<ArchiveOutlined />
							</IconButton>
						</Tooltip>
						<ConfirmModal
							title="Archive Shifts"
							isOpen={this.state.isModalOpen}
							message={`Are you sure you want to archive shift item ${data.name}?`}
							onClickCancel={() => {
								this.setState({ isModalOpen: false });
							}}
							onClickConfirm={this.archive} />
					</>
				}
				{
					data.is_archived &&
					<>
						<Tooltip title="Restore Shifts">
							<IconButton
								onClick={() => this.setState({ isModalOpen: true })}
								size="small"
								color="primary">
								<UnarchiveOutlined />
							</IconButton>
						</Tooltip>

						<ConfirmModal
							title="Restore Shifts"
							isOpen={this.state.isModalOpen}
							message={`Are you sure you want to restore shift ${data.name}?`}
							onClickCancel={() => {
								this.setState({ isModalOpen: false });
							}}
							onClickConfirm={this.restore} />
					</>
				}
			</DLTableColumn>
		]
	}

}

ShiftsTableRow.Columns = props => {
	return [
		{
			key: "name",
			label: "Name",
			align: "left",
			isSortable: true,
			withSeparator: true
		},
		{
			key: "week_days",
			label: "Days",
			align: "left",
			withSeparator: false
		},
		{
			key: "start_time",
			label: "Start Time",
			align: "left",
			isSortable: true,
			withSeparator: false
		},
		{
			key: "end_time",
			label: "End Time",
			align: "left",
			isSortable: true,

		},
		{
			key: "grace_time",
			label: "Late After",
			align: "left",
			isSortable: true,
			withSeparator: false
		},
		{
			key: "shift_hours",
			label: "Shift Hours",
			align: "left",
			isSortable: false,
		},
		{
			key: "working_hours",
			label: "Required Working Hours",
			labelComponent: (
				<Grid container alignItems="center">
					Required Working Hours
					<Tooltip title="Working hours of employees below the required working hours will be considered as Half Day">
						<IconButton size='small' disableFocusRipple disableRipple>
							<HelpOutline fontSize="small"/>
						</IconButton>
					</Tooltip>
				</Grid>
			),
			align: "left",
			isSortable: false,
		},
		AccessManager.isModuleAccess(Modules.OVERTIME, props.context.organization) &&
		{
			key: "overtime_grace",
			label: "Overtime Threshold",
			align: "left",
			isSortable: true,
			withSeparator: false
		},
		AccessManager.isModuleAccess(Modules.OVERTIME, props.context.organization) &&
		{
			key: "overtime_multiplier",
			label: "Overtime Multiplier",
			align: "left",
			isSortable: true,
			withSeparator: false
		},
		{
			key: "status",
			label: "Status",
			align: "left",
			isSortable: false,
			withSeparator: false,
			isCompact: true,
			colSpan: 1
		},
		{
			key: null,
			label: null,
			align: "right",
			isSortable: false,
			withSeparator: false,
			isCompact: true,
			colSpan: 2
		}
	];
};

export default withRouter(withStyles(styles)(ShiftsTableRow));
