class Env {

	static select(options, defaultValue = null) {
		const environment = (process.env.REACT_APP_ENV || process.env.NODE_ENV || "development").toLowerCase();
		return options[environment] || defaultValue;
	}

}

export default Env;
