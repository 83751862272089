const style = theme => ({
    
    column: {
        borderRight: `1px ${theme.palette.primary.background} solid`,
        
        '&:nth-last-child(1)': {
            borderRight: 'none'
        }
    },
    
    header: {
        padding: theme.spacing(2),

        '&.primary': {
            backgroundColor: theme.palette.primary.background
        },

        '&.secondary': {
            backgroundColor: theme.palette.danger.background
        },

        '&.warning': {
            backgroundColor: theme.palette.warning.background,
            color: theme.palette.warning.main
        }
    },

    fieldListContainer: {
        width: '100%',
        display: 'flex',

        '&.multi': {
            justifyContent: 'center',

            '& $fieldContainer': {
                width: "50%",

                '&:nth-last-child(1):nth-child(even), &:nth-last-child(2):nth-child(odd)': {
                    borderBottom: 'none'
                },

                '&:nth-child(odd) + $fieldContainer': {
                    borderLeft: `1px ${theme.palette.primary.background} solid`
                }
            }
        }
    },

    fieldContainer: {
        width: '100%',
        padding: theme.spacing(2),
        borderBottom: `1px ${theme.palette.primary.background} solid`,
        
        '&:nth-last-child(1)': {
            borderBottom: 'none'
        }
    }

});

export default style;