import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn } from "@/components";
import moment from "moment";
import {
	EditOutlined,
	DeleteOutlined,
	ExitToApp,
	Refresh
} from "@material-ui/icons";
import {
	Tooltip,
	IconButton,
	Icon,
	Chip
} from "@material-ui/core";
import _ from "lodash";
import styles from "./style";
import { setSnackbar, setLoader, addActiveEmployees, subtractActiveEmployees } from "@/redux/actions/general";
import { Api } from "@/helpers";
import ConfirmModal from "../Modals/ConfirmModal";
import EmployeePositionModal from "../Modals/EmployeePositionModal";
import { AuthContext } from "@/contexts";
import { withStyles } from "@material-ui/core/styles";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";

class EmployeePosition extends DLTableRow {
	static contextType = AuthContext;

	state = {
		isEditing: false,
		isDeleting: false,
		anchorEl: null,
	};

	isDeletable() {
		return this.props.data.end_date === null;
	}

	delete = async () => {
		
		this.setState({ isDeleting: false });
		this.props.setLoader(true);
		try {
			const { employee, data, isSeatAvailable } = this.props;
			if (!isSeatAvailable && data.end_reason && data.start_reason != "joined") {
				this.props.setSnackbar(true, "Your Organization has reached Employee Limit. To upgrade your package please contact at hello@taknhwa.pk.", "error");

			} else {
				await Api.employees(employee.id).employee_positions(data.id).delete();
				this.props.setSnackbar(true, "Employee Position deleted successfully");
				this.onUpdate();
	
				// Deleting promoted/demoted position that's exited.
				if (data.end_reason && data.start_reason != "joined") {
					this.props.addActiveEmployees();
				
				// Deleting active "joined" position
				} else if (!data.end_reason && data.start_reason == "joined") {
					this.props.subtractActiveEmployees();
				}
			}

		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
		this.props.setLoader(false);
	};

	reactive = async () => {

		this.setState({ anchorEl: null });
		try {
			const { employee, data, isSeatAvailable } = this.props;
			if (!isSeatAvailable) return this.props.setSnackbar(true, 'Your Organization has reached Employee Limit. To upgrade your package please contact on hello@taknhwa.pk.', 'error');

			const position = { ...data, end_date: null, end_reason: null };

			await Api.employees(employee.id).employee_positions(data.id).put(position);
			this.props.setSnackbar(true, "Employee Position Re-active successfully");
			this.props.addActiveEmployees()
			this.onUpdate();

		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
	};

	onUpdate = () => {
		this.props.onUpdate();
	};

	getData = () => {
		const positions = this.props.tableRef.data();
		let data = this.props.data;
		const index = _.findIndex(positions, (position) => position.id === data.id);

		if (index > 0) {
			data.min_date = moment(positions[index - 1].start_date).add(1, 'day').format('YYYY-MM-DD');
		}
		return data;
	};

	renderCells() {
		const { employee, tableRef, classes } = this.props;
		const loggedInUser = this.context.user;
		const { isEditing, isDeleting } = this.state;
		const data = this.getData();

		return [
			<DLTableColumn
				key={`Employee-${data.id}-Column-1`}
				align="left"
				withSeparator={true}>
				{data.department.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-3`}
				align="left"
				withSeparator={false}>
				{data.designation.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-4`}
				align="left"
				withSeparator={false}>
				{moment(data.start_date).format("MMM Do, Y")}
				{
					data.start_reason === "joined" &&
					data.confirmed_at &&
					<Tooltip title={"Confirmation Date: " + moment(data.confirmed_at).format("MMM Do, Y")} placement={"right"} className={classes.iconTooltip}>
						<Icon className={classes.infoIcon}>info</Icon>
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-6`}
				align="left"
				withSeparator={false}>
				{data.end_date ? moment(data.end_date).format("MMM Do, Y") : "Till Date"}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-7`}
				align="left"
				withSeparator={false}
				isCompact={true}>
				<Chip
					label={(data.end_reason || data.start_reason).toUpperCase()}
					color={data.end_reason ? "secondary" : "primary"}
					size="small"
				/>
			</DLTableColumn>,
			<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-8`}
					align={"center"}
					isCompact={true}
					fillWidth={35}
				>
					<Tooltip title="Edit Position">
						<IconButton
							onClick={() => this.setState({ isEditing: "edit" })}
							size="small"
							color="primary">
							<EditOutlined />
						</IconButton>
					</Tooltip>
					{
						isEditing &&
						<EmployeePositionModal
							mode={isEditing}
							employee={employee}
							position={data.id}
							onUpdate={this.onUpdate}
							onClose={() => this.setState({ isEditing: false })} />
					}
				</DLTableColumn>
			</UserRole>,
			<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-9`}
					align={"center"}
					isCompact={true}
					fillWidth={35}>

					{
						tableRef.state.meta.total !== 1 &&
						employee.current_position.id === data.id &&
						<Tooltip title="Delete Position">
							<IconButton
								onClick={() => this.setState({ isDeleting: true })}
								size="small"
								color="secondary"
							>
								<DeleteOutlined />
							</IconButton>
						</Tooltip>
					}
					{
						isDeleting &&
						<ConfirmModal
							title="Delete Employee Position"
							isOpen={isDeleting}
							message={`Are you sure want to delete ${data.designation.name}?`}
							onClickCancel={() => this.setState({ isDeleting: false })}
							onClickConfirm={this.delete}
						/>
					}
				</DLTableColumn>
			</UserRole>
			,
			<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-10`}
					align={"center"}
					isCompact={true}
					fillWidth={35}>

					{
						employee.current_position.id === data.id &&
						loggedInUser.id !== data.employee_id &&
						!data.end_date &&
						<Tooltip title="Exit Employee">
							<IconButton
								aria-label="More"
								aria-owns={Boolean(this.state.anchorEl) ? "long-menu" : undefined}
								aria-haspopup="true"
								size="small"
								color="secondary"
								onClick={(e) => this.setState({ isEditing: "exit" })}>
								<ExitToApp />
							</IconButton>
						</Tooltip>
					}
					{
						employee.current_position.id === data.id && data.end_reason &&
						<Tooltip title="Re-activate Employee">
							<IconButton
								aria-label="More"
								aria-owns={Boolean(this.state.anchorEl) ? "long-menu" : undefined}
								aria-haspopup="true"
								size="small"
								color="secondary"
								onClick={this.reactive}>
								<Refresh />
							</IconButton>
						</Tooltip>
					}
				</DLTableColumn>
			</UserRole>
		];
	}

}

EmployeePosition.Columns = [
	{
		key: "department_name",
		label: "Department",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "designation",
		label: "Designation",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "start_date",
		label: "Start Date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "end_date",
		label: "End date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "end_reason",
		label: "",
		align: "left",
		isSortable: false,
		withSeparator: false,
		isCompact: true
	},
	{
		key: "action",
		label: "",
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 3
	}
];

const mapStateToProps = state => ({
	isSeatAvailable: state.general.isSeatAvailable
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
	addActiveEmployees: (...args) => dispatch(addActiveEmployees(...args)),
	subtractActiveEmployees: (...args) => dispatch(subtractActiveEmployees(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployeePosition));