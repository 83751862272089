import moment from "moment";
import styles from "./style";
import { Api } from "@/helpers";
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import EmployeeAttendanceSummaryRow from "./EmployeeAttendanceSummaryRow";
import EmployeeDropdown from "@/components/Dropdowns/EmployeeDropdown";
import { BackButton, DLTable, Page, InfographicMessage, DateRange } from "@/components";
import NoRecord from '../../assets/icons/no-use-bg.svg';
import { AuthContext } from "@/contexts";
class EmployeeAttendanceSummaryReport extends Component {
	static contextType = AuthContext;

	_table = null;

	constructor (props) {
		super(props);
		this.state = {
			employee: null,
			start_month: null,
			end_month: null,
			anchorEl: null
		};
	}

	reload = () => {
		this._table && this._table.fetchData();
	};

	export = () => {
		const { employee, start_month, end_month } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.attendance_detailed_report.export.getUrl({
			starting_date: moment(start_month || moment()).format("YYYY-MM"),
			ending_date: moment(end_month || moment()).format("YYYY-MM"),
			employee_id: employee || this.context.user.id
		}))
	};

	componentDidMount() {
		this.setState({ employee: this.context.user.id })
	}
	emptyView() {
		return (
			<InfographicMessage
				illustration="detailedReport"
				message="Please Select Employee!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { start_month, employee, end_month } = this.state;

		return (
			<Page title="Employee Attendance Summary">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions >
						<div className={classes.taxReportFilter}>
							<DateRange currentMonth={true}
								custom_format={true}
								onChange={(start_month, end_month) => this.setState({ start_month, end_month }, this.reload)} />
							<EmployeeDropdown
								textFieldProps={{ margin: "dense" }}
								value={employee}
								onChange={employee => this.setState({ employee }, this.reload)}
							/>
						</div>
						<Button 
							size="medium"
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
					</CardActions>
					<CardActions style={{ flexDirection: "center", justifyContent: "center" }}>
						<Grid style={{ flexDirection: "center" }}>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundImage: `url(${NoRecord})`, height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>  <text >No Record </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "  #00D454", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>  <text >Present: P </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "#FF7300", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}> </span>   <text >Holiday: H </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "#64182A", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>      <text >Leave: L </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "#FABD60", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Late: LT </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "#00C7D4", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Half Day: HD </text>
							</span>
							<span id="option-label" className={classes.label}>
								<span style={{ backgroundColor: "#281B1B", height: "10px", width: "10px", borderRadius: "5px", display: "inline-block" }}></span>  <text >Absent: A </text>
							</span>
						</Grid>
					</CardActions>
					{
						start_month && end_month &&	
						<DLTable
							ref={ref => this._table = ref}
							api={Api.reports.attendance_detailed_report}
							params={{
								starting_date: moment(start_month).format("YYYY-MM") ,
								ending_date: moment(end_month).format("YYYY-MM"),
								employee_id: employee || this.context.user.id
							}}
							RowTemplate={EmployeeAttendanceSummaryRow}
							emptyView={this.emptyView()}
						/>
					}
					</Card>
			</Page>
		);
	}

}

export default withRouter(withStyles(styles)(EmployeeAttendanceSummaryReport));