import React, { Component } from "react";
import { Grid, Checkbox, MenuItem, TextField, FormControlLabel, Typography, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { ImagePicker, FormattedNumericInput } from "@/components";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import RoleDropdown from "@/components/Dropdowns/RoleDropdown";
import { setSnackbar, addActiveEmployees } from "@/redux/actions/general";
import { Api, HttpErrorHandler, Utility } from "@/helpers";
import Config from "@/config";
import _ from "lodash";
import MaskedInput from "react-text-mask";
import moment from "moment";
import Male from "@/assets/silhouette/male.jpg";
import Female from "@/assets/silhouette/female.jpg";
import styles from "./style";
import ModuleManager from "@/components/ModuleManager";
import Modules from "@/config/Modules";
import { AuthContext } from "@/contexts";
import CountryDropdown from '@/components/Dropdowns/CountryDropdown.js';
import { ShiftsDropdown } from "@/components";
import StatesDropdown from '@/components/Dropdowns/StatesDropdown';
import PermissionHOC from "@/components/PermissionHOC";
import { Permissions } from "@/config/Permissions";

class EmployeeInfoForm extends Component {
	static contextType = AuthContext;

	_form = null;

	static propTypes = {
		employee: PropTypes.object,
		onSave: PropTypes.func,
		picturePosition: PropTypes.oneOf(['top', 'right']),
		formRef: PropTypes.func
	};

	static defaultProps = {
		employee: {},
		onSave: () => { },
		picturePosition: 'right',
		formRef: () => { }
	};

	state = {
		errors: {},
		employee: {},
		disabled: false
	};

	componentDidMount() {
		this.props.formRef(this);
		this.setFieldsDisabled();
	}

	componentWillUnmount() {
		this.props.formRef(null);
	}

	setFieldsDisabled = () => {
		this.setState({ disabled: !this.props.hasPermission([Permissions.EMPLOYEES_FULL_ACCESS, Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS]) });
	}

	employee() {
		return {
			code: "",
			branch_id: "",
			first_name: "",
			department_id: this.isNew() ? "" : this.props.employee.current_position.department_id,
			designation_id: this.isNew() ? "" : this.props.employee.current_position.designation_id,
			father_name: "",
			basic_salary: "",
			last_name: "",
			cnic: "",
			ntn: "",
			gender: "",
			work_email: "",
			personal_email: "",
			password: "",
			mobile: "",
			phone: "",
			emergency_contact: "",
			skype: "",
			dob: moment(),
			joined_at: this.isNew() ? moment() : moment(this.props.employee.current_position.joined_at),
			confirmed_at: this.isNew() ? moment() : moment(this.props.employee.current_position.confirmed_at),
			present_address: "",
			present_city: "",
			present_state: "",
			present_country: "",
			permanent_address: "",
			permanent_city: "",
			permanent_state: "",
			permanent_country: "",
			role: "",
			shift_id: "",
			is_tax_exempted: false,
			is_geofencing: 1,
			...this.props.employee,
			...this.state.employee
		};
	}

	isNew() {
		return !this.props.employee || !this.props.employee.id;
	}

	handleChange(value, field) {
		const employee = this.employee();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		const addressFields = ["present_address", "present_city", "present_state", "present_country"];
		if (field === 'has_single_address' && value) {
			employee.permanent_address = employee.present_address;
			employee.permanent_city = employee.present_city;
			employee.permanent_state = employee.present_state;
			employee.permanent_country = employee.present_country;

			errors.permanent_address = false;
			errors.permanent_city = false;
			errors.permanent_state = false;
			errors.permanent_country = false;
		}

		if (employee.has_single_address && addressFields.indexOf(field) >= 0) {
			const pField = `permanent_${field.split("_")[1]}`;
			employee[pField] = value;
			errors[pField] = false;
		}

		this.setState({
			employee: {
				...employee,
				[field]: value
			},
			errors
		});
	}

	avatar = null;
	imageChangeHandler = (e) => {
		this.avatar = e ? e.target.files[0] : null;
	};

	validate() {
		const employee = this.employee();
		const requiredFields = ["code", "first_name", "last_name", "mobile", "work_email", "cnic", "gender", "present_address", "present_city", "present_state", "present_country"];
		var errors = {};

		if (!employee.has_single_address) {
			requiredFields.push("permanent_address", "permanent_city", "permanent_state", "permanent_country");
		}

		if (this.context.hasModule(Modules.BRANCH)) {
			requiredFields.push("branch_id");
		}

		if (this.isNew() || !this.context.hasModule(Modules.EMPLOYEE_HISTORY)) {
			requiredFields.push("department_id", "designation_id", "joined_at", "confirmed_at");

			if (this.isNew()) {
				requiredFields.push("password", "current_position", "shift_id");

				if (this.context.hasModule(Modules.ROLE_AND_PERMISSION)) {
					requiredFields.push("role");
				}
			}
		}

		_.forOwn(employee, (value, key) => {
			if (requiredFields.indexOf(key) !== -1 && !value) {
				errors[key] = `${_.startCase(key.replace(/_id$/i, ""))} field is required`;

			} else if (["work_email", "personal_email"].indexOf(key) !== -1 && value && !Utility.validateEmail(value)) {
				errors[key] = "Invalid email address";

			} else if (key === "password" && value && value.length < 6) {
				errors[key] = "Password must be at least 6 characters";

			} else if (key === "cnic" && !Utility.validateCNIC(value)) {
				errors[key] = "Invalid CNIC format, should be like 42101-1234567-1";

			} else if (key === "ntn" && value && !Utility.validateNTN(value)) {
				errors[key] = "Invalid NTN format, should be like 1234567-1";
			}
		});

		this.setState({ errors });

		if (Object.keys(errors).length) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}

		return !Object.keys(errors).length;
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const employee = this.employee();
		const data = new FormData();
		_.forOwn(employee, (value, key) => {
			// Ignored keys
			if (["is_tax_exempted", "is_archived", "deleted_at", "created_at", "updated_at", "department", "designation"].indexOf(key) !== -1) return;

			// Send null values as empty string
			if (_.isNil(value)) {
				value = "";
			}

			if (key === "is_tax_exempted" || key === "is_geofencing") {
				value = (value ? 1 : 0);
			}

			// Format dates as per server standard
			if (["dob", "confirmed_at", "joined_at"].indexOf(key) !== -1) {
				value = moment(value || "").format(Config.SERVER_DATETIME_FORMAT);
			}

			// When Branch module not assigned.
			// TODO: Check the module avaiability via AccessManager
			if (key === "branch_id" && value === "") {
				value = this.context.user.branch_id;
			}

			// Don't send Designation, Department, Joining date, and Confirmation date if Employee History module is enabled
			if (
				!this.isNew()
				&& this.context.hasModule(Modules.EMPLOYEE_HISTORY)
				&& ['department_id', 'designation_id', 'joined_at', 'confirmed_at'].indexOf(key) !== -1
			) {
				return;
			}

			data.append(key, value);
		});

		// Append display picture (if changed)
		if (this.avatar) {
			data.append("avatar", this.avatar);
		}

		// Append current purchase id when updating for organizations which dont have employee history
		if(!this.context.hasModule(Modules.EMPLOYEE_HISTORY) && !this.isNew()) {
			var position_id = employee && employee.current_position.id ? employee.current_position.id : "";
			data.append("position_id", position_id)
		}

		this.props.setLoader(true);
		try {
			var _employee;
			if (this.isNew()) {
				_employee = await Api.employees.post(data);
				this.props.setSnackbar(true, "New employee added successfully");
				this.props.addActiveEmployees(1);
				// this.props.history.replace(`/employees/${_employee.id}`, { employee: _employee });

			} else {
				data.delete("role");
				data.delete("current_position");
				data.delete("password");
				data.delete("is_geofencing")
				data.append("_method", "put");
				_employee = await Api.employees(employee.id).post(data);
				this.props.setSnackbar(true, "Employee information saved successfully");
			}
			this.props.onSave(_employee);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					const errors = _.values(_.mapValues(response.data, value => value));
					if (errors && errors.length) {
						this.props.setSnackbar(true, errors[0], "error");
					} else {
						this.props.setSnackbar(true, "Something went wrong, please try again", "error")
					}
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	resetForm() {
		this.setState({ employee: {} });
	}

	maskedInput(mask) {
		return function (props) {
			const { inputRef, value, ...otherProps } = props;
			const maskValue = mask.map(char => char instanceof RegExp ? "_" : char).join("");
			const showMask = value && value.match(maskValue);

			return (
				<MaskedInput
					ref={ref => inputRef(ref ? ref.inputElement : null)}
					value={value}
					mask={mask}
					showMask={showMask}
					{...otherProps}
				/>
			);
		}
	}

	CNICMask = this.maskedInput([/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]);
	NTNMask = this.maskedInput([/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]);

	render() {
		const { classes, picturePosition } = this.props;
		const { errors, disabled } = this.state;
		const employee = this.employee();
		return (
			<Grid className={classes.root} container spacing={2} direction={picturePosition === 'right' ? 'row' : 'column-reverse'}>
				<Grid item sm={picturePosition === 'right' ? 9 : 12} xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item sm={6} xs={12}>
									<TextField
										fullWidth
										required
										disabled={disabled}
										label="Employee Code"
										error={!!errors.code}
										helperText={errors.code}
										FormHelperTextProps={{ variant: "standard" }}
										value={employee.code || ""}
										onChange={e => this.handleChange(e.target.value, "code")}
										variant="outlined"
									/>
								</Grid>

								<ModuleManager modulePermissions={[Modules.BRANCH]}>
									<Grid item sm={6} xs={12}>
										{
											disabled 
											?
												<TextField
													fullWidth
													disabled={disabled}
													label="Branch"
													value={employee.branch && employee.branch.name}
													variant="outlined"
													required
												/>	
											:
												<BranchDropdown
													value={employee.branch_id}
													onChange={branch_id => this.handleChange(branch_id, "branch_id")}
													textFieldProps={{
														required: true,
														error: !!errors.branch_id,
														helperText: errors.branch_id,
														FormHelperTextProps: { variant: "standard" }
													}}
												/>
										}
									</Grid>
								</ModuleManager>
							</Grid>
						</Grid>

						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								required
								disabled={disabled}
								label="First Name"
								variant="outlined"
								error={!!errors.first_name}
								helperText={errors.first_name}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.first_name || ""}
								onChange={e => this.handleChange(e.target.value, "first_name")}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								required
								disabled={disabled}
								label="Last Name"
								variant="outlined"
								error={!!errors.last_name}
								helperText={errors.last_name}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.last_name || ""}
								onChange={e => this.handleChange(e.target.value, "last_name")}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Father Name"
								variant="outlined"
								error={!!errors.father_name}
								helperText={errors.father_name}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.father_name || ""}
								onChange={e => this.handleChange(e.target.value, "father_name")}
							/>
						</Grid>
						{
							this.isNew() &&
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{
										this.context.hasModule(Modules.ROLE_AND_PERMISSION) &&
										<Grid item sm={6} xs={12}>
											<RoleDropdown
												value={(employee.role !== null && employee.role) ? employee.role : 0}
												label="Role"
												onChange={role => this.handleChange(role ? role.id : "", "role")}
												textFieldProps={{
													required: true,
													error: !!errors.role,
													helperText: errors.role,
													FormHelperTextProps: { variant: "standard" }
												}}
											/>
										</Grid>
									}
									{
										this.context.hasModule(Modules.ATTENDANCE) &&
										<Grid item sm={6} xs={12}>
											<ShiftsDropdown
												filter={{ shifts: true }}
												value={employee.shift_id !== null && employee.shift_id ? employee.shift_id : ""}
												onChange={shift_id => {
													if (shift_id) {
														this.handleChange(shift_id, "shift_id")
													}
													else {
														this.handleChange("", "shift_id")
													}
												}}
												textFieldProps={{
													required: true,
													error: !!errors.shift_id,
													helperText: errors.shift_id,
													FormHelperTextProps: { variant: "standard" }
												}}
											/>
										</Grid>
									}
								</Grid>
							</Grid>
						}
						{
							(this.isNew() || !this.context.hasModule(Modules.EMPLOYEE_HISTORY)) &&
							<>
								<Grid item sm={6} xs={12}>
									<DepartmentDropdown
										disabled={disabled}
										value={employee.department_id}
										onChange={department_id => this.handleChange(department_id, "department_id")}
										textFieldProps={{
											required: true,
											error: !!errors.department_id,
											helperText: errors.department_id,
											FormHelperTextProps: { variant: "standard" }
										}}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<DesignationDropdown
										disabled={disabled}
										value={employee.designation_id}
										onChange={designation_id => this.handleChange(designation_id, "designation_id")}
										textFieldProps={{
											required: true,
											error: !!errors.designation_id,
											helperText: errors.designation_id,
											FormHelperTextProps: { variant: "standard" }
										}}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<DatePicker
										fullWidth
										required
										disabled={disabled}
										label="Joining Date"
										inputVariant="outlined"
										format="dd MMMM, yyyy"
										error={!!errors.joined_at}
										helperText={errors.joined_at}
										FormHelperTextProps={{ variant: "standard" }}
										value={moment(employee.joined_at || "")}
										onChange={date => this.handleChange(date, "joined_at")}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<DatePicker
										fullWidth
										required
										disabled={disabled}
										label="Confirmation Date"
										inputVariant="outlined"
										format="dd MMMM, yyyy"
										error={!!errors.confirmed_at}
										helperText={errors.confirmed_at}
										FormHelperTextProps={{ variant: "standard" }}
										minDate={employee.joined_at}
										value={moment(employee.confirmed_at || "")}
										onChange={date => this.handleChange(date, "confirmed_at")}
									/>
								</Grid>
							</>
						}
						{
							this.isNew() &&
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item sm={6} xs={12}>
										<TextField
											InputProps={{
												endAdornment: <InputAdornment position="end">{ Config.CURRENCY_SYMBOL }</InputAdornment>,
												inputComponent: FormattedNumericInput,
											}}
											fullWidth
											label="Basic Salary"
											variant="outlined"
											error={!!errors.basic_salary}
											helperText={errors.basic_salary}
											FormHelperTextProps={{ variant: "standard" }}
											value={employee.basic_salary || ""}
											onChange={e => this.handleChange(e.currentTarget.value, "basic_salary")}
										/>
									</Grid>
								</Grid>
							</Grid>
						}
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								required
								disabled={disabled}
								label="Mobile Number"
								variant="outlined"
								value={employee.mobile || ""}
								error={!!errors.mobile}
								helperText={errors.mobile}
								FormHelperTextProps={{ variant: "standard" }}
								onChange={e => this.handleChange(e.target.value, "mobile")}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Phone Number"
								variant="outlined"
								error={!!errors.phone}
								helperText={errors.phone}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.phone || ""}
								onChange={e => this.handleChange(e.target.value, "phone")}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Emergency Number"
								variant="outlined"
								error={!!errors.emergency_contact}
								helperText={errors.emergency_contact}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.emergency_contact || ""}
								onChange={e => this.handleChange(e.target.value, "emergency_contact")}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Skype Name"
								error={!!errors.skype}
								helperText={errors.skype}
								FormHelperTextProps={{ variant: "standard" }}
								variant="outlined"
								value={employee.skype || ""}
								onChange={e => this.handleChange(e.target.value, "skype")}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item sm={6} xs={12}>
									<form noValidate>
										<TextField
											fullWidth
											required
											disabled={disabled}
											label="Work Email"
											variant="outlined"
											value={employee.work_email || ""}
											error={!!errors.work_email}
											helperText={errors.work_email}
											FormHelperTextProps={{ variant: "standard" }}
											onChange={e => this.handleChange(e.target.value, "work_email")}
										/>
									</form>
								</Grid>
								{
									this.isNew() &&
									<Grid item sm={6} xs={12}>
										<form noValidate>
											<TextField
												fullWidth
												required
												label="Password"
												type="password"
												error={!!errors.password}
												helperText={errors.password}
												FormHelperTextProps={{ variant: "standard" }}
												variant="outlined"
												value={employee.password || ""}
												onChange={e => this.handleChange(e.target.value, "password")}
											/>
										</form>
									</Grid>
								}
								<Grid item sm={6} xs={12}>
									<form noValidate>
										<TextField
											fullWidth
											disabled={disabled}
											label="Personal Email"
											variant="outlined"
											error={!!errors.personal_email}
											helperText={errors.personal_email}
											FormHelperTextProps={{ variant: "standard" }}
											value={employee.personal_email || ""}
											onChange={e => this.handleChange(e.target.value, "personal_email")}
										/>
									</form>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								required
								disabled={disabled}
								label="CNIC Number"
								error={!!errors.cnic}
								helperText={errors.cnic}
								FormHelperTextProps={{ variant: "standard" }}
								variant="outlined"
								value={employee.cnic || ""}
								onChange={e => this.handleChange(e.target.value, "cnic")}
								InputProps={{ inputComponent: this.CNICMask }}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="NTN #"
								variant="outlined"
								value={employee.ntn || ""}
								error={!!errors.ntn}
								helperText={errors.ntn}
								FormHelperTextProps={{ variant: "standard" }}
								onChange={e => this.handleChange(e.target.value, "ntn")}
								InputProps={{ inputComponent: this.NTNMask }}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<DatePicker
								fullWidth
								required
								disabled={disabled}
								error={!!errors.dob}
								helperText={errors.dob}
								FormHelperTextProps={{ variant: "standard" }}
								label="Birthday"
								maxDate={moment().format("YYYY-MM-DD")}
								inputVariant="outlined"
								format="dd MMMM, yyyy"
								value={moment(employee.dob || "")}
								onChange={date => this.handleChange(date, "dob")}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								required
								disabled={disabled}
								select
								label="Gender"
								variant="outlined"
								error={!!errors.gender}
								helperText={errors.gender}
								FormHelperTextProps={{ variant: "standard" }}
								value={employee.gender || ""}
								onChange={e => this.handleChange(e.target.value, "gender")}
							>
								<MenuItem value="Male">Male</MenuItem>
								<MenuItem value="Female">Female</MenuItem>
							</TextField>
						</Grid>
						<Grid item sm={6} xs={12}>
							<Grid container spacing={2}>
								<Grid item sm={12} xs={12}>
									<Typography component="h6" variant="h6">Present Address</Typography>
								</Grid>
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										required
										disabled={disabled}
										label="Address"
										variant="outlined"
										error={!!errors.present_address}
										helperText={errors.present_address}
										FormHelperTextProps={{ variant: "standard" }}
										value={employee.present_address || ""}
										onChange={e => this.handleChange(e.target.value, "present_address")}
									/>
								</Grid>
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										required
										disabled={disabled}
										label="City/Town"
										variant="outlined"
										value={employee.present_city || ""}
										error={!!errors.present_city}
										helperText={errors.present_city}
										FormHelperTextProps={{ variant: "standard" }}
										onChange={e => this.handleChange(e.target.value, "present_city")}
									/>
								</Grid>
								<Grid item sm={12} xs={12}>
									{employee.present_country === "Pakistan"
										?
										<StatesDropdown
											fullWidth
											disabled={disabled}
											label={"State/Region"}
											variant="outlined"
											textFieldProps={{
												required: true,
												error: !!errors.present_state,
												helperText: errors.present_state,
												FormHelperTextProps: { variant: "standard" }
											}}
											value={employee.present_state || ""}
											onChange={present_state => this.handleChange(present_state, "present_state")}
										/>
										:
										<TextField
											fullWidth
											required
											disabled={disabled}
											label="State/Region"
											variant="outlined"
											error={!!errors.present_state}
											helperText={errors.present_state}
											FormHelperTextProps={{ variant: "standard" }}
											value={employee.present_state || ""}
											onChange={e => this.handleChange(e.target.value, "present_state")}
										/>
									}
								</Grid>
								<Grid item sm={12} xs={12}>
									<CountryDropdown
										fullWidth
										disabled={disabled}
										label={"Country"}
										variant="outlined"
										textFieldProps={{
											required: true,
											error: !!errors.present_country,
											helperText: errors.present_country,
											FormHelperTextProps: { variant: "standard" }
										}}
										value={employee.present_country || ""}
										onChange={(country) => this.handleChange(country, "present_country")}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={6} xs={12}>
							<Grid container spacing={2}>
								<Grid item sm={12} xs={12}>
									<Typography
										className={classes.addressHeader}
										component="h6"
										variant="h6"
									>
										Permanent Address
										<FormControlLabel
											style={{ margin: 0 }}
											control={
												<Checkbox
													disabled={disabled}
													value={employee.has_single_address}
													checked={employee.has_single_address || false}
													label="Same as Present"
													onChange={e => this.handleChange(e.target.checked, "has_single_address")}
													color="primary"
													style={{ padding: 0 }}
												/>
											}
											label="Same as Present"
										/>
									</Typography>
								</Grid>
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										required={!employee.has_single_address}
										label="Address"
										variant="outlined"
										error={!!errors.permanent_address}
										helperText={errors.permanent_address}
										FormHelperTextProps={{ variant: "standard" }}
										value={employee.has_single_address ? employee.present_address : employee.permanent_address || ""}
										disabled={employee.has_single_address || disabled}
										onChange={e => this.handleChange(e.target.value, "permanent_address")}
									/>
								</Grid>
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										required={!employee.has_single_address}
										label="City/Town"
										variant="outlined"
										error={!!errors.permanent_city}
										helperText={errors.permanent_city}
										FormHelperTextProps={{ variant: "standard" }}
										value={employee.has_single_address ? employee.present_city : employee.permanent_city || ""}
										disabled={employee.has_single_address || disabled}
										onChange={e => this.handleChange(e.target.value, "permanent_city")}
									/>
								</Grid>
								<Grid item sm={12} xs={12}>
									{employee.present_country === "Pakistan"
										?
										<StatesDropdown
											fullWidth
											label={"State/Region"}
											variant="outlined"
											disabled={employee.has_single_address || disabled}
											textFieldProps={{
												required: !employee.has_single_address,
												error: !!errors.permanent_state,
												helperText: errors.permanent_state,
												FormHelperTextProps: { variant: "standard" }
											}}
											value={employee.has_single_address ? employee.present_state : employee.permanent_state || ""}
											onChange={permanent_state => this.handleChange(permanent_state, "permanent_state")}
										/>
										:
										<TextField
											fullWidth
											required={!employee.has_single_address}
											label="State/Region"
											variant="outlined"
											error={!!errors.permanent_state}
											helperText={errors.permanent_state}
											FormHelperTextProps={{ variant: "standard" }}
											value={employee.has_single_address ? employee.present_state : employee.permanent_state || ""}
											disabled={employee.has_single_address || disabled}
											onChange={e => this.handleChange(e.target.value, "permanent_state")}
										/>}
								</Grid>
								<Grid item sm={12} xs={12}>
									<CountryDropdown
										fullWidth
										label={"Country"}
										disabled={employee.has_single_address || disabled}
										variant="outlined"
										textFieldProps={{
											required: !employee.has_single_address,
											error: !!errors.permanent_country,
											helperText: errors.permanent_country,
											FormHelperTextProps: { variant: "standard" }
										}}
										value={(employee.has_single_address ? employee.present_country : employee.permanent_country) || ""}
										onChange={country_perment => this.handleChange(country_perment, "permanent_country")}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={picturePosition === 'right' ? 3 : 12} xs={12}>
					<Grid container justify="center" alignItems="center">
						<Grid item>
							<ImagePicker
								title="Profile image"
								disabled={disabled}
								rounded
								src={
									employee.id
										? Api.employees(employee.id).avatar.getUrl(true)
										: (
											employee.gender === "Female"
												? Female
												: Male
										)
								}
								onSelect={this.imageChangeHandler}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	isSeatAvailable: state.general.isSeatAvailable
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	addActiveEmployees: (...args) => dispatch(addActiveEmployees(...args))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PermissionHOC(EmployeeInfoForm))));
