import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, InputLabel, Card, MenuItem, Switch, InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import styles from "./style";
import { Constants } from "@/config";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { BackButton, Page, FormattedNumericInput } from "@/components";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import { AuthContext } from "@/contexts";

class AttendanceRules extends Component {

    static contextType = AuthContext;
    constructor (props) {
        super(props);
        this.state = {
            lateOccurrenceValue: '',
            lateDeductionValue: "",
            halfDayOccurrenceValue: "",
            halfDayDeductionValue: "",
            deductionTypeValue: " ",
            deductionLateTypeValue: "",
            deductionHalfDayTypeValue: "",
            deductFromOtherQuota: true,
            allowFromAllBranch: false,
            organizationGeofencing: false,
            salaryCalculate: Constants.EmployeeDailySalaryTypesDetail[0].value,
            errors: {},
        }
    }

    async componentDidMount() {
        this.props.setLoader(true);
        var response = await Api.options.get({ group: "attendance" });
        const deductionTypeKey          = _.find(response, ["key", "attendance_rule_leave_deduction_type"]);
        const deductionLateTypeKey      = _.find(response, ["key", "attendance_rule_late_deduction_type"]);
        const deductionHalfDayTypeKey      = _.find(response, ["key", "attendance_rule_half_day_deduction_type"]);
        const occurrenceKey             = _.find(response, ["key", "attendance_rule_late_occurrence"]);
        const deductionKey              = _.find(response, ["key", "attendance_rule_leave_deduction"]);
        const deductFromOtherQuota      = _.find(response, ["key", "attendance_rule_leave_deduct_from_other_quota"]);
        const allowFromAllBranch        = _.find(response, ["key", "attendance_rule_allow_attendance_from_all_branch"]);
        const salaryCalculate           = _.find(response, ["key", "salary_calculate_by_day"]);
        const halfDayOccurence          = _.find(response, ["key", "attendance_rule_half_day_occurrence"]);
        const halfDayDeduction          = _.find(response, ["key", "attendance_rule_half_day_leave_deduction"]);
        
        var organization = this.context.organization;
        this.setState({
            deductionTypeValue: (deductionTypeKey && deductionTypeKey.value) || "Manually",
            deductionLateTypeValue: (deductionLateTypeKey && deductionLateTypeKey.value) || "Monthly",
            deductionHalfDayTypeValue: (deductionHalfDayTypeKey && deductionHalfDayTypeKey.value) || "Monthly",
            lateOccurrenceValue: (occurrenceKey && occurrenceKey.value) || 0,
            lateDeductionValue: (deductionKey && deductionKey.value) || 0,
            halfDayOccurrenceValue: (halfDayOccurence && halfDayOccurence.value) || 0,
            halfDayDeductionValue: (halfDayDeduction && halfDayDeduction.value) || 0,
            deductFromOtherQuota: (deductFromOtherQuota && (deductFromOtherQuota.value && Boolean(parseInt(deductFromOtherQuota.value)))) || true,
            allowFromAllBranch: (allowFromAllBranch && (allowFromAllBranch.value && Boolean(parseInt(allowFromAllBranch.value)))) || false,
            salaryCalculate: (salaryCalculate ? salaryCalculate.value : Constants.EmployeeDailySalaryTypesDetail[0].value),
            organizationGeofencing: (organization ? organization.is_geofencing : true)
        });
        this.props.setLoader(false);
    }

    handleChange(value, field) {
        this.setState({ [field]: value })
        if (this.state.errors[field] === true) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [field]: false
                }
            });
        }
    }

    isValidData = () => {
        var errors = {}
        if (this.state.deductionTypeValue === "") {
            errors.deductionTypeValue = true
        }
        if (this.state.deductionLateTypeValue === "") {
            errors.deductionLateTypeValue = true
        }
        if (this.state.deductionHalfDayTypeValue === "") {
            errors.deductionHalfDayTypeValue = true
        }
        if (this.state.lateOccurrenceValue === "") {
            errors.lateOccurrenceValue = true
        }
        if (this.state.lateDeductionValue === "") {
            errors.lateDeductionValue = true
        }
        if (this.state.halfDayOccurrenceValue === "") {
            errors.halfDayOccurrenceValue = true
        }
        if (this.state.halfDayDeductionValue === "") {
            errors.halfDayDeductionValue = true
        }
        if (this.state.salaryCalculate === "") {
            errors.salaryCalculate = true
        }
        this.setState({ errors: errors })
        return (_.size(errors) > 0) ? false : true;
    };

    displayValueOfDeductionType = (type) => {
        switch (type) {
            case Constants.EmployeeLeaves.leaveDeduction[0]: // Manually
                return "Manual Deduction";
        
            default: // Monthly & Annually
                return `Auto Deduction (${type})`;
        }
    }

    save = async (e) => {
        if (!this.isValidData()) return;
        this.props.setLoader(true);
        try {
            await Api.options.post({
                group: "attendance",
                records: [
                    { key: "attendance_rule_late_occurrence", value: this.state.lateOccurrenceValue },
                    { key: "attendance_rule_leave_deduction", value: this.state.lateDeductionValue },
                    { key: "attendance_rule_half_day_occurrence", value: this.state.halfDayOccurrenceValue },
                    { key: "attendance_rule_half_day_leave_deduction", value: this.state.halfDayDeductionValue },
                    { key: "attendance_rule_leave_deduction_type", value: this.state.deductionTypeValue },
                    { key: "attendance_rule_late_deduction_type", value: this.state.deductionLateTypeValue },
                    { key: "attendance_rule_half_day_deduction_type", value: this.state.deductionHalfDayTypeValue },
                    { key: "attendance_rule_leave_deduct_from_other_quota", value: this.state.deductFromOtherQuota ? 1 : 0 },
                    { key: "attendance_rule_allow_attendance_from_all_branch", value: this.state.allowFromAllBranch ? 1 : 0 },
                    { key: Constants.Options[4], value: this.state.salaryCalculate }
                ]
            });

            await Api.organizations(this.context.organization.id).put({
                is_geo_fencing: this.state.organizationGeofencing
            });
            this.context.organization.is_geofencing = this.state.organizationGeofencing;

            this.props.setSnackbar(true, "Rules saved successfully");
            this.props.onUpdate();
            this.props.onClose();

        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .go();
        }
        this.props.setLoader(false);
    };

    render() {
        const { classes } = this.props;
        const { deductFromOtherQuota, deductionTypeValue, deductionLateTypeValue, deductionHalfDayTypeValue, allowFromAllBranch, organizationGeofencing } = this.state;
        return (
            <Page title="Attendance Rules">
                <Link to={{ pathname: `/settings/attendance` }} style={{ textDecoration: 'none', color: "black" }}>
                    <BackButton route="/settings/attendance" label="Back to settings" />
                </Link>
                <Card raised> 
                    <Grid container className={classes.containerGrid}>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                How to calculate daily salary
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                margin="dense"
                                size="small"
                                select
                                className={classNames(classes.textField)}
                                variant="outlined"
                                error={this.state.errors.salaryCalculate}
                                helperText={this.state.errors.salaryCalculate}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={this.state.salaryCalculate || ""}
                                onChange={(e) => this.handleChange(e.target.value, "salaryCalculate")}>
                                {
                                    Constants.EmployeeDailySalaryTypesDetail.map((salary_deduction_type) => (
                                            <MenuItem key={salary_deduction_type.key} value={salary_deduction_type.value}>
                                                {salary_deduction_type.label}
                                            </MenuItem>

                                    ))
                                }
                            </TextField>
                        </Grid>

                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Leave Deduction Type
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                margin="dense"
                                size="small"
                                select
                                className={classNames(classes.textField)}
                                variant="outlined"
                                error={this.state.errors.deductionTypeValue}
                                helperText={this.state.errors.deductionTypeValue}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={deductionTypeValue}
                                onChange={(e) => this.handleChange(e.target.value, "deductionTypeValue")}
                            >
                                {Constants.EmployeeLeaves.leaveDeduction.map(
                                    (deductionTypeValue) => (
                                        <MenuItem key={deductionTypeValue} value={deductionTypeValue}>
                                            {this.displayValueOfDeductionType(deductionTypeValue)}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>

                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Late Deduction Type
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                margin="dense"
                                size="small"
                                select
                                className={classNames(classes.textField)}
                                variant="outlined"
                                error={this.state.errors.deductionLateTypeValue}
                                helperText={this.state.errors.deductionLateTypeValue}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={deductionLateTypeValue}
                                onChange={(e) => this.handleChange(e.target.value, "deductionLateTypeValue")}
                            >
                                {Constants.EmployeeLates.lateDeduction.map(
                                    (deductionLateTypeValue) => (
                                        <MenuItem key={deductionLateTypeValue} value={deductionLateTypeValue}>
                                            {this.displayValueOfDeductionType(deductionLateTypeValue)}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>

                        <Grid item alignItems="center" xs={12}>
                            <Grid container>
                                <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                                    <InputLabel>
                                        On occurrence of
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={7} sm={7} md="auto">
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{
                                                this.state.lateOccurrenceValue> 1 ? 'lates' : 'late'
                                            }</InputAdornment>,
                                            inputComponent: FormattedNumericInput,
                                        }}
                                        error={this.state.errors.lateOccurrenceValue}
                                        placeholder=""
                                        className={classNames(classes.textField1)}
                                        value={this.state.lateOccurrenceValue}
                                        onChange={(e) => this.handleChange(e.currentTarget.value, "lateOccurrenceValue")}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3} sm={3} md="auto" className={classes.labelGrid}>
                                    <InputLabel>
                                        deduct
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={7} sm={7} md="auto">
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{
                                                this.state.lateDeductionValue > 1 ? 'days salary' : 'day salary'
                                            }</InputAdornment>,
                                            inputComponent: FormattedNumericInput,
                                        }}
                                        error={this.state.errors.lateDeductionValue}
                                        placeholder=""
                                        className={classNames(classes.textField1)}
                                        value={this.state.lateDeductionValue}
                                        onChange={(e) => this.handleChange(e.currentTarget.value, "lateDeductionValue")}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Half Day Deduction Type
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <TextField
                                margin="dense"
                                size="small"
                                select
                                className={classNames(classes.textField)}
                                variant="outlined"
                                error={this.state.errors.deductionHalfDayTypeValue}
                                helperText={this.state.errors.deductionHalfDayTypeValue}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={deductionHalfDayTypeValue}
                                onChange={(e) => this.handleChange(e.target.value, "deductionHalfDayTypeValue")}
                            >
                                {Constants.EmployeeLates.lateDeduction.map(
                                    (deductionHalfDayTypeValue) => (
                                        <MenuItem key={deductionHalfDayTypeValue} value={deductionHalfDayTypeValue}>
                                            {this.displayValueOfDeductionType(deductionHalfDayTypeValue)}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>

                        <Grid item alignItems="center" xs={12}>
                            <Grid container>
                                <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                                    <InputLabel>
                                        On occurrence of
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={7} sm={7} md="auto">
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{
                                                this.state.halfDayOccurrenceValue > 1 ? 'half days' : 'half day'
                                            }</InputAdornment>,
                                            inputComponent: FormattedNumericInput,
                                        }}
                                        error={this.state.errors.halfDayOccurrenceValue}
                                        className={classNames(classes.textField1)}
                                        value={this.state.halfDayOccurrenceValue}
                                        onChange={(e) => this.handleChange(e.currentTarget.value, "halfDayOccurrenceValue")}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3} sm={3} md="auto" className={classes.labelGrid}>
                                    <InputLabel>
                                        deduct
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={7} sm={7} md="auto">
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{
                                                this.state.halfDayDeductionValue > 1 ? 'days salary' : 'day salary'
                                            }</InputAdornment>,
                                            inputComponent: FormattedNumericInput,
                                        }}
                                        error={this.state.errors.halfDayDeductionValue}
                                        className={classNames(classes.textField1)}
                                        value={this.state.halfDayDeductionValue}
                                        onChange={(e) => this.handleChange(e.currentTarget.value, "halfDayDeductionValue")}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                                Exceeding leaves to be deducted from other leaves quota 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <Switch
                                checked={deductFromOtherQuota}
                                onChange={() => this.setState({deductFromOtherQuota: !deductFromOtherQuota})}
                                color="primary"
                            />
                        </Grid> */}
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                               Enable Geofenced Attendance 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <Switch
                                checked={organizationGeofencing}
                                onChange={() => this.setState({organizationGeofencing: !organizationGeofencing})}
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                            <InputLabel>
                               Allow attendance from all branches 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <Switch
                                checked={allowFromAllBranch}
                                onChange={() => this.setState({allowFromAllBranch: !allowFromAllBranch})}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classNames(classes.containerGrid, classes.footer)}>
                        <Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <Button variant="contained" size="large" color="primary" className={classes.createBtn}
                                onClick={this.save}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Page>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(
    null,
    mapDispatchToProps
)(withStyles({}, { withTheme: true })(withStyles(styles)(AttendanceRules)));
