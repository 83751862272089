import React, { Component } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Store, { Persistor } from "@/redux";
import DateFnsUtils from '@date-io/date-fns';
import Theme from "@/themeStyles";
import Router from "@/router";
import ReactGA from "react-ga4";
import { Constants } from './config';

import "material-design-icons/iconfont/material-icons.css";
import "typeface-roboto";

class App extends Component {

	componentDidMount() {
		ReactGA.initialize(Constants.GATrackingID, { testMode: process.env.NODE_ENV === 'development' });
	}

	render() {
		return (
			<Provider store={Store}>
				<PersistGate loading={null} persistor={Persistor}>
					<MuiThemeProvider theme={Theme}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Router />
						</MuiPickersUtilsProvider>	
					</MuiThemeProvider>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;