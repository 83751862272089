import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import DLTableColumn from './DLTableColumn';
import _ from 'lodash';

class DLTableRow extends React.Component {

    isSelectable() {
        return this.props.enableSelection;
    }

    renderCells() {
        const { data } = this.props;
        return Object.values(data).map((value, index) => {
            const random = data.id || (_.now() + Math.random());
            return <DLTableColumn key={`DLTableRow-${random}-Column-${index}`}>{_.isObject(value) ? value.toString() : value}</DLTableColumn>
        });
    }

    render() {
        const { data, enableSelection, isSelected, onSelection, checkboxSize } = this.props;
        return (
            <TableRow
                key={`DLTableRow-${data.id || (_.now() + Math.random())}`}
                hover
                role={'checkbox'}
                tabIndex={-1}
                selected={isSelected && this.isSelectable()}>
                {
                    enableSelection &&
                    <DLTableColumn
                        key={`DLTableRow-${data.id || (_.now() + Math.random())}-Column-Select`}
                        align={'center'}
                        withSeparator={true}
                        isCompact={true}>
                        <Checkbox
                            size={checkboxSize}
                            id={`DLTableRowCheckbox-${data.id}`}
                            checked={isSelected}
                            onClick={onSelection}
                            disabled={!this.isSelectable()} />
                    </DLTableColumn>
                }
                {
                    this.renderCells()
                }
            </TableRow>
        );
    }
}

DLTableRow.defaultProps = {
    data: {},
    enableSelection: false,
    isSelected: false,
}

DLTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    enableSelection: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelection: PropTypes.func
}

export default DLTableRow;