import _ from 'lodash';
var bankAccountVariables = {
    list: [],
    meta: {},
    archiveMeta: {},
    archiveList: []
};
export default function (state = bankAccountVariables, action) {
    var list = []
    switch (action.type) {
        case 'BANK_ACCOUNT_LIST':
            if (!action.isReset) {
                list = state.list
            }
            list.push(action.list);

            return state = {
                ...state,
                list: _.flattenDeep(list)
            }

        case 'BANK_ACCOUNT_META':
            return state = {
                ...state,
                meta: action.meta
            }
        case 'BANKACCOUNTS_ARCHIVE_LIST':
            if (!action.isReset) {
                list = state.archiveList
            }
            list.push(action.archiveList);

            return state = {
                ...state,
                archiveList: _.flattenDeep(list)
            }

        case 'BANKACCOUNTS_ARCHIVE_META':
            return state = {
                ...state,
                archiveMeta: action.archiveMeta
            }
        default:
            return state;
    }
}