import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Tabs as MaterialTabs, Tab as MaterialTab } from "@material-ui/core";
import Tab from "./Tab";
import styles from "./style";
import _ from "lodash";
import { Link } from "react-router-dom";
import Theme from "@/themeStyles"
import ModuleManager from "../ModuleManager";

class Tabs extends Component {

    constructor (props) {
        super(props);
        this.state = {
            selectedTab: props.initialTab || 0
        };
    }

    children() {
        const { children } = this.props;
        return _.isArray(children) ? children : [children];
    }

    isTabActive(tab) {
        return this.state.selectedTab === tab;
    }

    activateTab(selectedTab) {
        if (!this.isTabActive(selectedTab)) {
            this.setState({ selectedTab });
        }
    }

    tabPath(tab) {
        const { match } = this.props;
        var filtered = this.children().filter(function (el) {
            return el.props.path != null;
        });
        if(filtered[tab].props.path === null) {
            return
        }
        else {
             return `${match.path.replace(/\/$/, "")}/${filtered[tab].props.path.replace(/^\//, "")}`; 
    }
    }

    tabURL(tab) {
        const { match } = this.props;
        return `${match.url.replace(/\/$/, "")}/${this.children()[tab].props.path.replace(/^\//, "")}`;
    }

    handleChange = (e, tab) => {
        const { onChange, variant } = this.props;
        if (variant === "routed") {
            this.props.history.push(this.tabURL(tab));
        } else {
            this.activateTab(tab);
        }
        _.isFunction(onChange) && onChange(tab);
    };

    tabs() {
        const { classes, variant } = this.props;
        return this.children().map((child, i) => {
            const itemPath = `${this.props.match.url}${child.props.path}`
            const selectedPath = this.props.location.pathname;
            if (variant !== "routed") {
                return (
                    
                        <MaterialTab
                            key={i}
                            color="primary"
                            label={child.props.label}
                            icon={child.props.icon}
                            classes={{
                                wrapper: classes.tabItemsWrapper,
                                labelIcon: classes.tabItemsLabelIcon,
                                textColorInherit: classes.textColorInherit
                            }}
                            {...child.props.tabProps}
                        />
                )
            }
            return (
                <ModuleManager
                    modulePermissions={child.props.hidden}>
                    <Link underline="none" to={{ pathname: itemPath }}
                        style={{ color: itemPath === selectedPath ? Theme.color.blue.dodger : Theme.color.gray.main }}
                        className={classes.linkStyle}>
                        <MaterialTab
                            key={i}
                            color="primary"
                            label={child.props.label}
                            icon={child.props.icon}
                            classes={{
                                wrapper: classes.tabItemsWrapper,
                                labelIcon: classes.tabItemsLabelIcon,
                                textColorInherit: classes.textColorInherit
                            }}
                            {...child.props.tabProps}
                        />
                    </Link>
                </ModuleManager>)
        });
    }

    simplePanels() {
        return this.children().map((child, tab) => (
            this.isTabActive(tab) &&
            <child.type
                key={tab}
                {...child.props}
                tab={tab}
            />
        ));
    }

    routedPanels() {
        const { match } = this.props;
        var filtered = this.children().filter(function (el) {
            return el.props.path != null;
        });
        const { selectedTab } = this.state;
        return (
            <>
                <Route
                    path={match.path}
                    exact
                    render={() => (
                        <Redirect from={match.path} to={this.tabURL(selectedTab)} />
                    )}
                />
                {
                    filtered.map((child, tab) => (
                        <ModuleManager 
                            key={tab}
                            modulePermissions={child.props.hidden}
                        >
                            <Route
                                path={this.tabPath(tab)}
                                render={() => (
                                    <child.type
                                        {...child.props}
                                        tab={tab}
                                        tabs={this}
                                    />
                                )}
                            />
                        </ModuleManager>
                    ))
                }
            </>
        );
    }

    render() {
        const { classes, variant, beforePanel, tabsProps } = this.props;
        const { selectedTab } = this.state;
        var filtered = this.tabs().filter(function (el) {
            return el != null;
        });
        return (
            <div>
                <MaterialTabs
                    className={classes.tabsRoot}
                    value={selectedTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    {...tabsProps}>
                    {filtered}
                </MaterialTabs>
                {beforePanel}
                {
                    variant === "routed" &&
                    this.routedPanels()
                }
                {
                    variant !== "routed" &&
                    this.simplePanels()
                }
            </div>
        );
    }

}

Tabs.Tab = Tab;

export default withRouter(withStyles(styles)(Tabs));