const style = theme => ({
	
	link: {
		textDecoration: "none"
	},

	menuItem: {
		textDecoration: "none",
		color: "rgba(0, 0, 0, 0.87)"
	}

});

export default style;