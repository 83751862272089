import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/index.js';
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
    key: 'root',
    storage,
    timeout: null,
    whitelist: ['auth'],
    transforms: [
        encryptTransform({
            secretKey: 'j3ITLktLhz25jk6N',
            onError: function (error) {
                console.log(error)
            },
        }),
    ],
}


const middleware = applyMiddleware(thunk)

const reducerWithPersist = persistReducer(persistConfig, reducers);
const Store = createStore(reducerWithPersist, middleware);
export const Persistor = persistStore(Store);
export default Store;