import Config from '@/config';

export default { };

export function currencyFormatter(amount, symbol = true, isDeduction = false) {
    var formattedAmount = amount ? format(Number(Math.abs(amount)), 2) : "0.00";
    const currency = symbol ? Config.CURRENCY_SYMBOL : '';
    
    if (amount != null && typeof amount != "undefined") {
        const _amount = amount * -1;
        if(isCurrencyNegative(_amount, isDeduction)) {
            formattedAmount = `(${formattedAmount})`;
        }
    }
    return `${currency} ${formattedAmount}`;
}

function format(number, n, x) {
    const [formatNumber, amountIn] = handleLargeAmount(number);
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    const result = formatNumber.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    return `${result}${amountIn}`;
};

function handleLargeAmount(number) {
    let formatNumber = number;
    let amountIn = "";

    if (number > 999999999999) {
        formatNumber = Math.sign(number)*((number/1000000000000).toFixed(2));
        amountIn = "T";

    } else if (number > 999999999) {
        formatNumber = Math.sign(number)*((number/1000000000).toFixed(2));
        amountIn = "B";

    // } else if (number > 999999) {
    //     formatNumber = Math.sign(number)*((number/1000000).toFixed(2));
    //     amountIn = "M";
    }

    return [formatNumber, amountIn];
}

export function generateRandomColors(numColors, skipColorsHex = []) {
    var colors = [];

    while (colors.length < numColors) {
        var color = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');

        var skipFound = skipColorsHex.some(function(existingColor) {
            return existingColor === color;
        });

        if (!colors.includes(color) && !skipFound) {
            colors.push(color);
        }
    }

    return colors;
}

export function isCurrencyNegative(amount, isDeduction) {
    return (isDeduction && amount < 0) || (!isDeduction && amount > 0)
}
export function nFormatter(n) {
    if (n < 1e6) return n;
    // if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(0) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(0) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(0) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(0) + "T";
}

export function humanizedDuration(duration) {
    var hours = Math.floor(duration);
    var minutes = Math.round((duration - hours) * 60);

    return `${hours}h ${minutes}m`;
}