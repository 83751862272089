const styles = theme => ({
    containerGrid: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formControl : {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        marginTop: theme.spacing(1)
    },
    labelGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    inputLabel: {
        textAlign: 'end'
    },
    footer: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    createBtn: {
        width: '260px',
        height: '60px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
});

export default styles