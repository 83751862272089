const styles = theme => ({
    requestCorrectionGrid: {
        display: "block",
        marginBottom: "0px",
    },
    radioColor: {
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: "#2F80ED",
        }
    },
    dateBox:{
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
        verticalAlign:"center",
        padding: 8,
        paddingTop:11,
        cursor: "pointer"
    }
	
});

export default styles;