import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField
} from "@material-ui/core";
import MaskedInput from "react-text-mask";
import ContentLoader from "react-content-loader";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style.js";
import _ from "lodash";

class AttendanceTerminalModal extends Component {

	static propTypes = {
		terminal: PropTypes.object,
		title: PropTypes.string,
		mode: PropTypes.oneOf(['add', 'edit']),
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		isOpen: false,
		title: null,
		mode: 'add',
		onUpdate: () => { }
	};

	state = {
		errors: { },
		terminal: { },
		isLoading: false,
		fetchingSerial: false
	};

	async componentDidMount() {
		const { mode, match : { params : { id: terminalId } }, location : { state } } = this.props;
		if (mode === 'edit') {
			if (terminalId && !(state && state.terminal)) {
				this.setState({ isLoading: true });
				try {
					const terminal = await Api.terminals(terminalId).get();
					this.setState({ terminal });
	
				} catch (e) {
					this.props.onClose();
					this.props.setSnackbar(true, "Unable to get employee details, please try again", "error");
				}
				this.setState({ isLoading: false });
	
			} else if (state && state.terminal) {
				this.setState({ terminal: state.terminal });
			}
		}
	}

	terminal() {
		return {
			name: null,
			ip: null,
			port: '4370',
			passkey: null,
			...this.props.terminal,
			...this.state.terminal
		};
	}

	setErrors = response => {
		let errors = { };
		_.map(response.data, (errs, field) => {
			errors[field] = errs[0];
		});
		this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		this.setState({ errors });
	};

	handleChange(value, field) {
		const { errors, terminal } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		terminal[field] = value;

		this.setState({
			terminal,
			errors
		});
	}

	fetchTerminalInfo = async (e) => {
		const terminal = this.terminal();
		try {
			this.setState({ fetchingSerial: true });
			const info = await Api.terminals.test.get({
				ip		: terminal.ip,
				port	: terminal.port,
				passkey	: terminal.passkey
			});

			this.setState({
				terminal: {
					...this.terminal(),
					serial: info.serial
				}
			});

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(408, _ => {
					this.props.setSnackbar(true, "Unable to connect to ZKTeco Terminal", "error");
				})
				.on(422, this.setErrors)
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();

		} finally {
			this.setState({ fetchingSerial: false });
		}
	};

	validate() {
		const terminal = this.terminal();
		var optionalFields = [ 'serial', 'is_live', 'is_dynamic_ip', 'passkey' ];
		var errors = {};
		
		_.forOwn(terminal, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				let fieldName = _.startCase(key);
				switch(key) {
					case 'ip':
						fieldName = 'IP Address';
						break;
					default:
						break;
				}
				errors[key] = `${fieldName} is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const terminal = this.terminal();
		this.props.setLoader(true);
		try {
			if (!terminal.id) {
				await Api.terminals.post(terminal);
			} else {
				await Api.terminals(terminal.id).put(terminal);
			}
			this.props.setSnackbar(true, "ZKTeco Terminal saved successfully");
			this.props.onUpdate();
			this.props.onClose();

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, this.setErrors)
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	title() {
		const { title, mode } = this.props;
		return title || (_.startCase(mode) + " ZKTeco Terminal");
	}

	IPMask(props) {
		const { inputRef, ...otherProps } = props;
		
		return (
			<MaskedInput
				ref={ref => inputRef(ref ? ref.inputElement : null)}
				placeholderChar={' '}
				pipe={value => {
					if (value === '.' || value.endsWith('..')) return false;
				
					const parts = value.split('.');
				
					if (
						parts.length > 4 ||
						parts.some(part => part.match(/^0\d/) || part < 0 || part > 255)
					) {
						return false;
					}
				
					return value;
				}}
				mask={value => Array(value.length).fill(/[\d.]/)}
				showMask={true}
				{...otherProps}
			/>
		);
	}

	render() {
		const { isOpen, mode } = this.props;
		const { errors, isLoading, fetchingSerial } = this.state;
		const terminal = this.terminal();
        return (
			<Dialog 
				fullWidth
				maxWidth="xs"
				open={isOpen} 
				aria-labelledby="attendance-terminal-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="attendance-terminal-dialog-title">{this.title()}</DialogTitle>
				<form autoComplete="off">
					<DialogContent dividers>
						{
							isLoading &&
							<ContentLoader viewBox="0 0 396 250" style={{ marginBottom: -6, width: "100%" }}>
								<rect x="0" y="0" rx="2" ry="2" width="396" height="40" />
								<rect x="0" y="50" rx="2" ry="2" width="193" height="40" />
								<rect x="203" y="50" rx="2" ry="2" width="193" height="40" />
								<rect x="0" y="100" rx="2" ry="2" width="396" height="40" />
								<rect x="0" y="150" rx="2" ry="2" width="289.5" height="40" />
								<rect x="299.5" y="150" rx="2" ry="2" width="96.5" height="40" />
								<rect x="0" y="200" rx="2" ry="2" width="396" height="40" />
							</ContentLoader>
						}
						{
							!isLoading &&
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoFocus
										label="Name"
										variant="outlined"
										size="small"
										error={!!errors.name}
										helperText={errors.name}
										FormHelperTextProps={{ variant: "standard" }}
										value={terminal.name || ""}
										onChange={e => this.handleChange(e.target.value, "name")}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label="IP Address"
										variant="outlined"
										size="small"
										error={!!errors.ip}
										helperText={errors.ip}
										FormHelperTextProps={{ variant: "standard" }}
										value={terminal.ip || ""}
										onChange={e => this.handleChange(e.target.value, "ip")}
										InputProps={{ inputComponent: this.IPMask }}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label="Port"
										variant="outlined"
										size="small"
										error={!!errors.port}
										helperText={errors.port}
										FormHelperTextProps={{ variant: "standard" }}
										value={terminal.port || ""}
										onChange={e => this.handleChange(e.target.value, "port")}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Comm Key"
										variant="outlined"
										size="small"
										error={!!errors.passkey}
										helperText={errors.passkey}
										FormHelperTextProps={{ variant: "standard" }}
										value={terminal.passkey || ""}
										type="password"
										autoFill={false}
										onChange={e => this.handleChange(e.target.value, "passkey")}
									/>
								</Grid>
								<Grid item xs={mode === 'edit' ? 12 : 9}>
									<TextField
										fullWidth
										label="Serial #"
										variant="outlined"
										size="small"
										error={!!errors.serial}
										helperText={errors.serial}
										FormHelperTextProps={{ variant: "standard" }}
										value={terminal.serial || ""}
										disabled={true}
										// onChange={e => this.handleChange(e.target.value, "serial")}
									/>
								</Grid>
								{
									mode === 'add' &&
									<Grid item xs={3}>
										<Button
											fullWidth
											color="default"
											disabled={!terminal.ip || !terminal.port || fetchingSerial}
											onClick={this.fetchTerminalInfo}>
											{
												fetchingSerial
													? <CircularProgress size={20} color="primary" />
													: "Fetch"
											}
										</Button>
									</Grid>
								}
							</Grid>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.onClose} color="default">Cancel</Button>
						<Button variant="contained" type="submit" onClick={this.save} color="primary">Save</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withRouter(AttendanceTerminalModal)));