import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn, Money, UserRole } from "@/components";
import moment from "moment";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutlined, ToggleOff, ToggleOn } from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, AccessManager } from "@/helpers";
import ConfirmModal from "../Modals/ConfirmModal";
import EmployeePayItemModal from "../Modals/EmployeePayItemModal";
import { Permissions } from "@/config/Permissions";
import Modules from "@/config/Modules";
import { AuthContext } from "@/contexts";

class EmployeePayItemTableRow extends DLTableRow {

	static contextType = AuthContext;

	state = {
		isEditing: false,
		isDeleting: false
	};

	isEditable() {
		const { data } = this.props;
		return !data.details.is_overtime && !data.details.is_witholding_tax && !data.details.is_leave;
	}

	isSelectable = () => !this.props.data.is_permanent;

	delete = async () => {
		this.setState({ isDeleting: false });
		this.props.setLoader(true);
		try {
			const { employee, data } = this.props;
			await Api.employees(employee.id).pay_items(data.details.id).delete();
			await this.props.getPayItems();
			this.props.reload();
			this.props.setSnackbar(true, "Pay item deleted successfully");

		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { employee, data } = this.props;
		const { isEditing, isDeleting } = this.state;
		return [
			<DLTableColumn
				key={`Employee-${data.id}-Column-1`}
				align="left"
				withSeparator={true}>
				 {data.details.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-3`}
				align="right"
				withSeparator={false}>
				{
					AccessManager.isModuleAccess(Modules.REIMBURSEMENT, this.context.organization) &&
					data.details.is_reimbursable
						? <><Money amount={data.remaining < 0 ? 0 : data.remaining}/> / <Money amount={data.quota}/> Remaining</>
						: data.details.is_percentage ? `${data.rate}%` : <Money amount={data.rate}/>
				}
			</DLTableColumn>,
			<DLTableColumn 
				key={`Employee-${data.id}-Column-4`}
				align="left"
				withSeparator={false}>
				{data.frequency}
			</DLTableColumn>,
			<DLTableColumn 
				key={`Employee-${data.id}-Column-5`}
				align="left"
				withSeparator={false}>
				{data.starts_from ? moment(data.starts_from || employee.joined_at).format("MMM Do, Y") : "N/A"}
			</DLTableColumn>,
			<DLTableColumn 
				key={`Employee-${data.id}-Column-6`}
				align="center"
				withSeparator={false}>
				{
					data.is_reminder ? (
						<ToggleOn color="primary" />
					) : (
						<ToggleOff color="secondary" />
					)
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-7`}
				align={"center"}
				isCompact={true}
				fillWidth={35}
			>
				{
					this.isEditable() &&
					<UserRole routePermissions={[ Permissions.PAYITEM_EDITABLE, Permissions.PAYITEM_FULL_ACCESS ]}>
						<Tooltip title="Edit Pay Item">
							<IconButton
								onClick={() => this.setState({ isEditing: true })}
								size="small"
								color="primary"
							>
								<EditOutlined />
							</IconButton>
						</Tooltip>
					</UserRole>
				}
				{
					isEditing &&
					<EmployeePayItemModal
						isOpen={isEditing}
						employee={employee}
						payItem={data}
						title="Edit Pay Item"
						onUpdate={this.props.reload}
						onClose={() => this.setState({ isEditing: false })}
					/>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-8`}
				align={"center"}
				isCompact={true}
				fillWidth={35}
			>
				<UserRole routePermissions={[ Permissions.EMPLOYEES_FULL_ACCESS ]}>
					{
						!data.is_permanent &&
						<Tooltip title="Delete Pay Item">
							<IconButton
								onClick={() => this.setState({ isDeleting: true })}
								size="small"
								color="secondary"
							>
								<DeleteOutlined />
							</IconButton>
						</Tooltip>
					}
					{
						isDeleting &&
						<ConfirmModal
							title="Delete Pay Item"
							isOpen={isDeleting}
							message={`Are you sure want to delete ${data.details.name}?`}
							onClickCancel={() => this.setState({ isDeleting: false })}
							onClickConfirm={this.delete}
						/>
					}
				</UserRole>
			</DLTableColumn>
		];
	}

}

EmployeePayItemTableRow.Columns = [
	{
		key: "payitem",
		label: "Pay Item",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "rate",
		label: "rate",
		align: "right",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "frequency",
		label: "Frequency",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "start_date",
		label: "Start date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "isReminder",
		label: "Is Reminder",
		align: "center",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "action",
		label: "",
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeePayItemTableRow);