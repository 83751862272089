import React, { Component } from "react";
import { Button, CardActions, Icon } from "@material-ui/core";
import { DLTable, InfographicMessage, UserRole } from "@/components";
import OrganizationBranchModal from "@/components/Modals/OrganizationBranchModal";
import OrganizationBranchTableRow from "./OrganizationBranchTableRow";
import {
	Switch,
	Route,
	withRouter,
	Link
} from "react-router-dom";
import { Api } from "@/helpers";
import { Permissions } from "@/config/Permissions";
import { AuthContext } from "@/contexts";
class OrganizationBranches extends Component {
	static contextType = AuthContext;
	
	_table = null;

	state = {
		isAddingBranch: false
	};

	reload = () => {
		this.setState({ isAddingBranch: false });
		this._table && this._table.fetchData();
	};

	emptyView() {
		const { organization } = this.props;
		return (
			<UserRole routePermissions={[ Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS, Permissions.ORGANIZATIONS_FULL_ACCESS ]}>
				<InfographicMessage
					illustration="organization"
					message={`${organization.name} doesn't have any branch yet, add one now!`}
					actionLabel={"Add Branch"}
					actionIcon={"add"}
					actionHandler={() => this.setState({ isAddingBranch: true })}
				/>
			</UserRole>
		);
	}

	addNew = () => {
		const route = this.props.isSettingPage ? "/settings/organization/" : "/organizations/"
		this.props.history.push({
			pathname: route + this.props.organization.id + `/branches/add`,
			state: { branch: {} }
		})
	};


	render() {
		const { organization, isSettingPage } = this.props;
		const route = isSettingPage ? "/settings/organization/" : "/organizations/"
		return (
			<>
				{
					this.context.user.organization_id === organization.id &&
					<UserRole routePermissions={[ Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS, Permissions.ORGANIZATIONS_FULL_ACCESS ]}>
						<CardActions>
							<Link to={{ pathname: route + `${organization.id}/branches/add`, state: { branch: {} } }} style={{ textDecoration: 'none', color: "primary" }}>
								<Button
									variant="outlined"
									size="medium"
									color="primary">
									<Icon>add</Icon>
									Add Branch
								</Button>
							</Link>
						</CardActions>
					</UserRole>
				}
				<DLTable
					ref={table => this._table = table}
					api={Api.organizations(organization.id).list}
					rowProps={{ organization, isSettingPage }}
					RowTemplate={OrganizationBranchTableRow}
					emptyView={this.emptyView()}
				/>
				
				<Switch>
					<Route path={route + ":organizationId/branches/add"}>
						<OrganizationBranchModal
							title={"Add Branch"}
							onUpdate={this.reload}
							organization={organization}
						/>
					</Route>
					<Route path={route + ":organizationId/branches/:branchId"}>
						<OrganizationBranchModal
							title={this.context.user.organization_id === organization.id ? "Edit Branch" : "View Branch"}
							organization={organization}
							onUpdate={this.reload}
						/>
					</Route>
				</Switch>
			</>
		);
	}
}

export default withRouter(OrganizationBranches);