const styles = theme => ({

    systemSettings: {
        marginTop: theme.spacing(2)
    },
    main: {
        alignItems : "center",
        display : "flex",
        flexDirection : "column" 
    },
    banner: {
        marginTop : "-20px",
        width : "30vw",
        height : "25vw",
        display: "inline-block"
    },
    text : {
        marginTop : "20px",
        marginBottom : "20px",
        fontSize : "22px"
    },
    backButton : {
        paddingLeft : "30px",
        paddingRight : "30px"
    }
});

export default styles