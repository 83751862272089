import _ from 'lodash';
var bankVariables = {
    list: [],
    meta: {}
};
export default function (state = bankVariables, action) {
    var list = []
    switch (action.type) {
        case 'BANK_LIST':
            if (!action.isReset) {
                list = state.list
            }
            list.push(action.list);

            return state = {
                ...state,
                list: _.flattenDeep(list)
            }

        case 'BANK_META':
            return state = {
                ...state,
                meta: action.meta
            }

        case 'BANK_ARCHIVE_LIST':
            if (!action.isReset) {
                list = state.archiveList
            }
            list.push(action.archiveList);

            return state = {
                ...state,
                archiveList: _.flattenDeep(list)
            }

        case 'BANK_ARCHIVE_META':
            return state = {
                ...state,
                archiveMeta: action.archiveMeta
            }

        default:
            return state;
    }
}