import { Component } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "@/contexts";

class UserModules extends Component {

	static contextType = AuthContext;

	static propTypes = {
		permissions: PropTypes.object,
		modulePermissions: PropTypes.array.isRequired
	};


	hasPermission() {
		const { modulePermissions } = this.props;

		if (!this.context || !this.context.user) return;
		let hasPermission = false;
		if (this.context.user.organization.is_super_organization) {
			hasPermission = true
			return hasPermission

		} else if(this.context.user.organization.current_purchase) {
			modulePermissions.forEach((routePermission, index) => {
				if (this.context.user.organization.current_purchase.modules.indexOf(routePermission) !== -1) {
					hasPermission = true;
				}
			});
			return hasPermission;
		}
	}

	render() {
		const { children } = this.props;

		return this.hasPermission() ? children : null
	}
}

export default UserModules;
