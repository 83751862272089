import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, CardActions, Icon, Grid } from "@material-ui/core";
import { DLTable, InfographicMessage, Page, UserRole, PermissionHOC, BackButton } from "@/components";
import RolePermissionModal from "@/components/Modals/RolePermissionModal";
import RoleTableRow from "./RoleTableRow";
import { Api } from "@/helpers";
import { AuthContext } from "@/contexts";
import { Permissions } from "@/config/Permissions";

class Roles extends Component {

	static contextType = AuthContext;

	state = {
		isOpenAddModal: false
	};

	addNew = () => {
		this.props.history.push("/settings/banks/add");
	};

	emptyView(archived = false) {
		const hasPermission = this.props.hasPermission([ Permissions.ROLE_EDITABLE, Permissions.ROLE_FULL_ACCESS ]);
		return (
			<InfographicMessage
				 illustration="Permissions"
				message={"You don't have any Role yet, add one now!"}
				actionLabel={archived ? "" : hasPermission ? "Add Role" : ""}
				actionIcon={archived ? "" : hasPermission ? "add" : ""}
				actionHandler={this.addNew}
			/>
		);
	}

	actionBar() {
		return (
			<UserRole routePermissions={[ Permissions.ROLE_EDITABLE, Permissions.ROLE_FULL_ACCESS ]}>
				<CardActions>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						onClick={this.addNew}>
						<Icon>add</Icon>
						Add Role
					</Button>
				</CardActions>
			</UserRole>
		);
	}

	onUpdateModal = () => {
		this.setState({ isOpenAddModal: false });
		this._table && this._table.fetchData();
	}

	render() {
		const { isOpenAddModal } = this.state;
		return (
			<Page title="Roles & Permissions">
				<BackButton route="/settings" label="Back to Settings" />
				<Card raised>
					<UserRole routePermissions={[ Permissions.ROLE_EDITABLE, Permissions.ROLE_FULL_ACCESS ]}>
						<CardActions>
							<Grid container justify={"space-between"}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									onClick={() => { this.setState({ isOpenAddModal: true }) }}>

									<Icon>add</Icon>
									Add Role
								</Button>
							</Grid>
						</CardActions>
					</UserRole>
					<DLTable
						ref={table => this._table = table}
						api={Api.roles}
						RowTemplate={RoleTableRow}
						rowProps={{onUpdate: this.onUpdateModal}}
						emptyView={this.emptyView()} />

				</Card>
				{
					isOpenAddModal &&
					<RolePermissionModal
						title="Add Role"
						onClose={() => this.setState({ isOpenAddModal: false })}
						onUpdate={this.onUpdateModal}
						isOpen={isOpenAddModal}
					/>
				}
			</Page>
		);
	}
}

export default withRouter(PermissionHOC(Roles));
