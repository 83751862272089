import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style.js";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem

} from "@material-ui/core";
import { EmployeeDropdown, EmployeeLeavesDropdown, } from "@/components";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { Constants } from "@/config";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import classNames from "classnames";

class LeavesRequestModal extends Component {
    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        mode: PropTypes.oneOf(["new", "edit", "exit"]),
        leaveRequest: PropTypes.object,
        title: PropTypes.string,
        onClose: PropTypes.func,
        onUpdate: PropTypes.func
    };

    static defaultProps = {
        mode: "new",
        title: "New Leave",
        onUpdate: () => { }
    };

    constructor (props) {
        super(props);
        this.state = {
            errors: {},
            leave_id: "",
            leaveRequest: {},
            position: {},
            employee_id: "",
            status: "",
            start_date: moment(moment.now(), "x").format("YYYY-MM-DD HH:mm:ss"),
            end_date: "",
            disabled: false,
            isEdit: false,
            multipleDays: false
        };
    }

    componentDidMount() {
        const { position: id } = this.props;
        if (id) {
            this.setState({ isLoading: true, isEdit: true, disabled: true })
        }
    }

    leaveRequest() {
        return {
            employee_id: "",
            leave_id: "",
            status: "",
            reason: "",
            start_date: "",
            end_date: "",
            ...this.props.leaveRequest,
            ...this.state.leaveRequest
        };
    }

    prepareData = () => {
        let leaveRequest = this.leaveRequest();
        let data = {
            employee_id: leaveRequest.employee.first_name,
            leave_id: leaveRequest.leave.name,
            reason: leaveRequest.reason,
            status: leaveRequest.status,
            start_date: leaveRequest.start_date,
            end_date: leaveRequest.end_date,
        }
        return data;
    }

    createReimbursable = async () => {
        const leaveRequest = this.leaveRequest()
        if (this.validate()) {
            var data = new FormData();
            const { position: id } = this.props;

            data.append("employee_id", (this.state.employee || leaveRequest.employee_id));
            data.append("leave_id", (leaveRequest.leave_id));
            data.append("reason", (this.state.reason || leaveRequest.reason));
            data.append("start_date", moment((leaveRequest.start_date)).format("YYYY-MM-DD"));
            data.append("end_date", moment(leaveRequest.end_date || leaveRequest.start_date).format("YYYY-MM-DD"));
            data.append("status", (this.state.status || leaveRequest.status));

            try {
                this.props.setLoader(true);
                if (!id) {
                    await Api.leave_requests.post(data);
                    this.props.setSnackbar(true, "Leave added for employee");
                } else {
                    data.append("_method", "put");
                    await Api.leave_requests(leaveRequest.id).post(data);
                    this.props.setSnackbar(true, "Leave Updated for employee");
                  }
                this.props.onUpdate();
                this.props.onClose();
                this.props.setSnackbar(false)
            } catch (e) {
                HttpErrorHandler.intercept(e)
                    .on(422, response => {
                        const errors = _.values(_.mapValues(response.data, value => value[0]));
                        if (errors && errors.length) {
                            this.props.setSnackbar(true, errors[0], "error");
                        } else {
                            this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                        }
                    })
                    .on(403, (response) => {
                        this.props.setSnackbar(
                            true,
                            response.message || "Something went wrong, please try again later",
                            "error"
                        )
                    })
                    .go();
            }
            this.props.setLoader(false);
        }
    }

    handleChange = (value, fieldName) => {
        const leaveRequest = this.leaveRequest();
        const { errors } = this.state;
        if (fieldName === "employee") {
            this.setState({ [fieldName]: value });
            return
        }

        else {
            this.setState({
                leaveRequest: {
                    ...leaveRequest,
                    [fieldName]: value,
                },
                errors,
            });
        }
    };

    validate() {
        const leaveRequest = this.leaveRequest();
        const { isEdit } = this.state;
        var errors = {};

        if (((leaveRequest.employee_id === "" || this.state.employee_id) && (isEdit))) {
            errors["employee"] = `${_.startCase("employee")} field is required`;
        }
        if ((leaveRequest.leave_id === "")) {
            errors["leave_id"] = `${_.startCase("leave_id")} field is required`;
        }
        if ((leaveRequest.start_date === "")) {
            errors["start_date"] = `${_.startCase("start_date")} field is required`;
        }
        if ((leaveRequest.reason === "")) {
            errors["reason"] = `${_.startCase("reason")} field is required`;
        }

        if (!(leaveRequest.status)) {
            errors["status"] = `${_.startCase("status")} field is required`;
        }

        if (_.size(errors)) {
            this.props.setSnackbar(
                true,
                "Please fix the highlighted errors",
                "error"
            );
        }
        this.setState({ errors });
        return !(_.size(errors) > 0);
    }


    render() {

        const { mode, title, classes } = this.props;
        const { errors} = this.state;
        const leaveRequest = this.leaveRequest();
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={mode}
                onBackdropClick={this.closeDialog}
                aria-labelledby="employee-leaves-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="employee-leaves-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className={classNames(classes.textField)}>
                                <EmployeeDropdown
                                    disabled={this.state.disabled || this.state.isEdit}
                                    placeholder="Employee"
                                    textFieldProps={{
                                        error: !!errors.employee,
                                        helperText: errors.employee,
                                        size: "small",
                                        FormHelperTextProps: { variant: "standard" },
                                    }}
                                    onChange={(employee) => {
                                        this.handleChange(employee, "employee")
                                    }
                                    }
                                    value={this.state.employee || leaveRequest.employee_id || ""}
                                />
                            </div>
                        </Grid>
                        {
                            (this.state.employee || leaveRequest.employee_id) && <> <Grid item xs={12}>
                                <div className={classNames(classes.textField)}>

                                    <EmployeeLeavesDropdown
                                        textFieldProps={{
                                            error: !!errors.leave_id,
                                            helperText: errors.leave_id,
                                            size: "small",
                                            FormHelperTextProps: { variant: "standard" },
                                        }}
                                        value={leaveRequest.leave_id || ""}
                                        filter={{ leave: true }}
                                        employee={this.state.employee || leaveRequest.employee_id}
                                        onChange={leave_id => {
                                            if (leave_id) {
                                                this.handleChange(leave_id.leave_id, "leave_id")
                                            }
                                            else {
                                                this.handleChange("", "leave_id")
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <DatePicker
                                autoOk
                                fullWidth
                                size="small"
                                error={!!errors.start_date}
                                label="Start Date"
                                inputVariant="outlined"
                                className={classNames(classes.textField)}
                                format={Config.DISPLAY_DATE_FORMAT}
                                value={leaveRequest.start_date || null}
                                onChange={(e) => {
                                    this.handleChange(e, "start_date")
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                autoOk
                                fullWidth
                                size="small"
                                error={!!errors.start_date}
                                label="End Date"
                                inputVariant="outlined"
                                className={classNames(classes.textField)}
                                format={Config.DISPLAY_DATE_FORMAT}
                                value={leaveRequest.end_date || null}
                                onChange={(end_date) => {
                                    this.handleChange(end_date, "end_date")
                                }}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                label="Reason"
                                fullWidth
                                size="small"
                                value={leaveRequest.reason || ""}
                                variant="outlined"
                                error={!!errors.reason}
                                helperText={errors.reason}
                                FormHelperTextProps={{ variant: "standard" }}
                                onChange={(e) => this.handleChange(e.target.value, "reason")}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                select
                                label="Status"
                                variant="outlined"
                                error={!!errors.status}
                                helperText={errors.status}
                                FormHelperTextProps={{ variant: "standard" }}
                                value={leaveRequest.status || ""}
                                className={classNames([
                                    classes.textField,
                                    classes.marginTextInput,
                                ])}
                                onChange={(e) =>
                                    this.handleChange(e.target.value, "status")}>
                                <MenuItem value={Constants.leaveStatus.FLAG_APPROVED}>Approved</MenuItem>
                                <MenuItem value={Constants.leaveStatus.FLAG_REJECTED}>Rejected</MenuItem>
                                <MenuItem value={Constants.leaveStatus.FLAG_PENDING}>Pending</MenuItem>
                                )

                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="default" size="small">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={this.createReimbursable} color="primary" size="small">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LeavesRequestModal));
