import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import { setSnackbar, setLoader, addActiveEmployees, subtractActiveEmployees } from "@/redux/actions/general";
import styles from "./style.js";
import _ from "lodash";
import moment from "moment";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";


class EmployeePositionModal extends Component {

	static propTypes = {
		mode: PropTypes.oneOf(["new", "edit", "exit"]),
		employee: PropTypes.object.isRequired,
		position: PropTypes.number,
		onClose: PropTypes.func,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		mode: "new",
		title: "New Position",
		onUpdate: () => { }
	};

	state = {
		errors: {},
		position: { },
		positions: [],
		isLoading: true
	};

	position() {
		return {
			department_id: "",
			designation_id: "",
			start_date: "",
			start_reason: "joined",
			...this.props.position,
			...this.state.position
		};
	}
	async componentDidMount() {
		const { employee, position: positionId, mode } = this.props;
		const { position } = this.state;
		try {
			const response = await Api.employees(employee.id).employee_positions.get({ per_page: 0 });
			const stateData = {
				positions: response.data,
				position,
				isLoading: false
			};

			if (positionId) {
				stateData.position = _.find(response.data, { id: positionId });
				if (mode === "exit" && !stateData.position.end_date) {
					const minExitDate = moment(stateData.position.start_date);
					if (moment().isBefore(minExitDate)) {
						stateData.position.end_date = minExitDate.format(Config.SERVER_DATETIME_FORMAT);
					} else {
						stateData.position.end_date = moment().format(Config.SERVER_DATETIME_FORMAT);
					}
				}

			} else {
				const minStartDate = moment(this.minStartDate(response.data));
				if (this.currentPosition(response.data).end_reason) {
					stateData.position.start_date = this.minStartDate(response.data) || moment().format(Config.SERVER_DATETIME_FORMAT);
					stateData.position.confirmed_at = this.minStartDate(response.data) || moment().format(Config.SERVER_DATETIME_FORMAT);
				} else if (moment().isBefore(minStartDate)) {
					stateData.position.start_date = minStartDate.format(Config.SERVER_DATETIME_FORMAT);
				} else {
					stateData.position.start_date = moment().format(Config.SERVER_DATETIME_FORMAT);
				}
			}

			this.setState(stateData);
		} catch (e) {
			this.setState({ isLoading: false });
			this.props.onClose();
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
	}

	currentPosition(positions) {
		return (positions || this.state.positions)[0];
	}

	handleChange(value, field) {
		const { errors, position } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		position[field] = value;
		if (field === "start_date" && position.confirmed_at && moment(position.confirmed_at).isBefore(moment(value))) {
			position.confirmed_at = value;
		}

		this.setState({
			position,
			errors
		});
	}

	validate() {
		const { mode } = this.props;
		const position = this.position()
		var optionalFields = [ "is_joining" ];
		var errors = {};
		if(mode !== "exit") {
			optionalFields.push("end_date");
			optionalFields.push("end_reason");
		}

		if(mode !== "edit" || position.start_reason !== "joined") {
			optionalFields.push("confirmed_at");
		}

		_.forOwn(position, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const { employee, position: positionId, mode } = this.props;
		const { position } = this.state;
		this.props.setLoader(true);
		try {
			position.start_date = moment(position.start_date).format(Config.SERVER_DATE_FORMAT);
			if (position.confirmed_at) {
				position.confirmed_at = moment(position.confirmed_at).format(Config.SERVER_DATE_FORMAT);
			}

			if (position.end_date) {
				position.end_date = moment(position.end_date).format(Config.SERVER_DATE_FORMAT);
			}
			if (!positionId) {
				if (this.currentPosition().end_reason) {
					position.start_reason = "joined";
				}
				await Api.employees(employee.id).employee_positions.post(position);

				if (position.start_reason === "joined") {
					this.props.addActiveEmployees();
				}
			} else {
				await Api.employees(employee.id).employee_positions(position.id).put(position);
				if (mode === 'exit') {
					this.props.subtractActiveEmployees();
				}
			}
			this.props.setSnackbar(true, "Employee Position saved successfully");
			this.props.onUpdate();
			this.props.onClose();

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					const errors = _.values(_.mapValues(response.data, value => value));
					if (errors && errors.length) {
						this.props.setSnackbar(true, errors[0], "error");
					} else {
						this.props.setSnackbar(true, "Something went wrong, please try again", "error")
					}
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	canShow = (type) => {
		const { mode } = this.props;
		const { position } = this.state;

		switch(type) {
			case "default":
				return mode !== "exit";
			case "confirmed_at":
				return (mode === "new" && this.currentPosition().end_reason) || (mode === "edit" && position && position.confirmed_at);
			case "start_reason":
				return mode === "new" && !this.currentPosition().end_reason;
			case "end_date":
				return mode === "exit" || (position && position.end_date);
			case "end_reason":
				return mode === "exit" || (position && position.end_reason);
			default:
				return false;
		}
	}

	title() {
		const { mode } = this.props;
		return mode === "exit" ? "Exit Employee" : _.upperFirst(mode) + " Position";
	}

	minStartDate(defaultPositions = []) {
		const { position, positions } = this.state;
		const list = positions.length ? positions : defaultPositions;
		const indexOfPosition = _.findIndex(list, { id: position.id });
		const prevPosition = list[indexOfPosition + 1];
		if (prevPosition && prevPosition.end_reason) {
			return moment(prevPosition.end_date).add(1, "day");
		} else if (prevPosition) {
			return moment(prevPosition.start_date).add(1, "day");
		}
	}

	maxStartDate() {
		const { position, positions } = this.state;
		const indexOfPosition = _.findIndex(positions, { id: position.id });
		if (indexOfPosition >= 0) {
			const nextPosition = positions[indexOfPosition - 1];
			if (position.end_date && moment().isSameOrAfter(moment(position.end_date))) {
				return moment(position.end_date);
			} else if (nextPosition) {
				return moment(nextPosition.start_date).subtract(1, "day");
			}
		}
		return moment();
	}

	maxEndDate() {
		const { position, positions } = this.state;
		const indexOfPosition = _.findIndex(positions, { id: position.id });
		const nextPosition = positions[indexOfPosition - 1];
		if (nextPosition) {
			return moment(nextPosition.start_date).subtract(1, "day");
		}
	}

	render() {
		const { mode } = this.props;
		const { errors, isLoading, position } = this.state;
        return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={mode}
				aria-labelledby="employee-position-dialog-title"
				onBackdropClick={this.props.onClose}
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-position-dialog-title">{this.title()}</DialogTitle>
				<DialogContent dividers>
					{
						isLoading &&
						<div>Please wait!</div>
					}
					{
						!isLoading &&
						<Grid container spacing={2}>
							{
								this.canShow("default") &&
								<>
									<Grid item xs={12}>
										<DepartmentDropdown
											value={position.department_id}
											onChange={department_id => this.handleChange(department_id, "department_id")}
											textFieldProps={{
												error: !!errors.department_id,
												helperText: errors.department_id,
												FormHelperTextProps: { variant: "standard" },
												size:"small"
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<DesignationDropdown
											value={position.designation_id}
											onChange={designation_id => this.handleChange(designation_id, "designation_id")}
											textFieldProps={{
												error: !!errors.designation_id,
												helperText: errors.designation_id,
												FormHelperTextProps: { variant: "standard" },
												size:"small"
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<DatePicker
											autoOk
											fullWidth
											error={!!errors.start_date}
											helperText={errors.start_date}
											FormHelperTextProps={{ variant: "standard" }}
											label="Start Date"
											inputVariant="outlined"
											minDate={this.minStartDate()}
											maxDate={this.maxStartDate()}
											format={Config.DISPLAY_DATE_FORMAT}
											value={position.start_date || null}
											onChange={e => this.handleChange(e, "start_date")}
											size={"small"}
										/>
									</Grid>
								</>
							}
							{
								this.canShow("confirmed_at") &&
								<Grid item xs={12}>
									<DatePicker
										autoOk
										fullWidth
										error={!!errors.confirmed_at}
										helperText={errors.confirmed_at}
										FormHelperTextProps={{ variant: "standard" }}
										label="Confirmation Date"
										inputVariant="outlined"
										minDate={position.start_date}
										format={Config.DISPLAY_DATE_FORMAT}
										value={position.confirmed_at}
										onChange={e => this.handleChange(e, "confirmed_at")}
										size={"small"}

									/>
								</Grid>
							}
							{
								this.canShow("start_reason") &&
								<Grid item xs={12}>
									<TextField
										fullWidth
										select
										label="Status"
										variant="outlined"
										error={!!errors.start_reason}
										helperText={errors.start_reason}
										FormHelperTextProps={{ variant: "standard" }}
										value={position.start_reason || ""}
										onChange={e => this.handleChange(e.target.value, "start_reason")}
										size={"small"}
									>
										<MenuItem value="promoted">Promoted</MenuItem>
										<MenuItem value="demoted">Demoted</MenuItem>
									</TextField>
								</Grid>
							}
							{
								this.canShow("end_date") &&
								<Grid item xs={12}>
									<DatePicker
										autoOk
										fullWidth
										error={!!errors.end_date}
										helperText={errors.end_date}
										FormHelperTextProps={{ variant: "standard" }}
										label="Exit Date"
										inputVariant="outlined"
										minDate={position.start_date}
										maxDate={this.maxEndDate()}
										format={Config.DISPLAY_DATE_FORMAT}
										value={moment(position.end_date || null)}
										onChange={e => this.handleChange(e, "end_date")}
										size={"small"}
									/>
								</Grid>
							}
							{
								this.canShow("end_reason") &&
								<Grid item xs={12}>
									<TextField
										fullWidth
										select
										label="Exit Status"
										variant="outlined"
										error={!!errors.end_reason}
										helperText={errors.end_reason}
										FormHelperTextProps={{variant: "standard"}}
										value={position.end_reason || ""}
										onChange={e => this.handleChange(e.target.value, "end_reason")}
										size={"small"}
									>
										<MenuItem value="dismissed">Dismissed</MenuItem>
										<MenuItem value="demised">Demised</MenuItem>
										<MenuItem value="handicapped">Handicapped</MenuItem>
										<MenuItem value="retired">Retired</MenuItem>
										<MenuItem value="resigned">Resigned</MenuItem>
									</TextField>
								</Grid>
							}
						</Grid>
					}
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.props.onClose} color="default">Cancel</Button>
					<UserRole routePermissions={[ Permissions.EMPLOYEES_FULL_ACCESS ]}>
						<Button size="small" variant="contained" onClick={this.save} color="primary">Save</Button>
					</UserRole>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = state => ({
	isSeatAvailable: state.general.isSeatAvailable
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
	addActiveEmployees: (...args) => dispatch(addActiveEmployees(...args)),
	subtractActiveEmployees: (...args) => dispatch(subtractActiveEmployees(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeePositionModal));