import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import EmployeeBankModal from "@/components/Modals/EmployeeBankModal";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { Api } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
class EmployeeBankTable extends DLTableRow {

	state = {
		isEditing: false,
		isDeleting: false
	};

	delete = async () => {
		this.setState({ isDeleting: false });
		this.props.setLoader(true);
		try {
			const { data } = this.props;
			await Api.employees(data.employee_id).bank_accounts(data.id).delete();
			this.props.setSnackbar(true, "Bank account deleted successfully");
			this.props.reload();

		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { employee, data } = this.props;
		const { isEditing, isDeleting } = this.state;
		return [
			<DLTableColumn
				key={`Employee-${data.id}-Column-1`}
				withSeparator={true}>
				 {data.bank.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-2`}
				withSeparator={false}
				align="left"
			>
				{data.title}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-3`}
				align="left"
				withSeparator={false}
			>
				{data.account_number}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-4`}
				align={"right"}
				isCompact={true}
				fillWidth={35}
			>
				<Tooltip title="Edit Bank">
					<IconButton
						onClick={() => this.setState({ isEditing: true })}
						size="small"
					>
						<EditOutlined color="primary"/>
					</IconButton>
				</Tooltip>
				{
					isEditing &&
					<EmployeeBankModal
						isOpen={isEditing}
						employee={employee}
						bankAccount={data}
						title="Edit Bank Account"
						onUpdate={this.props.reload}
						onClose={() => this.setState({ isEditing: false })}
					/>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-5`}
				align={"right"}
				isCompact={true}
				fillWidth={35}
			>
				<UserRole routePermissions={[ Permissions.EMPLOYEES_FULL_ACCESS ]}>
					<Tooltip title="Delete Bank Account">
						<IconButton
							onClick={() => this.setState({ isDeleting: true })}
							size="small"
						>
							<DeleteOutline color="secondary" />
						</IconButton>
					</Tooltip>
					{
						isDeleting &&
					<ConfirmModal
							title="Delete Bank Account"
							isOpen={isDeleting}
							message={`Are you sure want to delete ${data.bank.name} account # ${data.account_number}?`}
							onClickCancel={()=> this.setState({ isDeleting: false })}
							onClickConfirm={this.delete}
						/>
					}
				</UserRole>
			</DLTableColumn>
		]
	}

}

EmployeeBankTable.Columns = [
	{
		key: "bank_id",
		label: "Bank",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "title",
		label: "Title",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "account_number",
		label: "Account Number",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: "",
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeBankTable);