const style = theme => ({

    switch: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 0
    },
    hidden: {
        display: "none"
    },
    closeButton: {
        position: 'absolute',
        right: 9
      },

});

export default style;