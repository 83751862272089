import React from "react";
import { withRouter } from "react-router-dom";
import { Tooltip, IconButton } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, UserRole } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import RolePermissionModal from "@/components/Modals/RolePermissionModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import { Api, AccessManager } from "@/helpers";
import _ from "lodash";
import moment from "moment";
import { Permissions, PermissionsInfo } from "@/config/Permissions";
import { AuthContext } from "@/contexts";

class RoleTableRow extends DLTableRow {

	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			isOpenEditModal: false,
			isModalOpen: false
		}
	}

	delete = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.roles(data.id).delete()
			store.dispatch(setSnackbar(true, `"${data.name}" deleted successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	onUpdateModal = () => {
		this.setState({ isOpenEditModal: false });
		this.props.onUpdate();
	}

	totalPermissions = (data) => {
		let totalPermissions = 0;

		const permissionsName = _.map(data.permissions, (item) => item.name);

		_.map(PermissionsInfo, (item) => {
			_.map(item, (i) => { 
				if (permissionsName.indexOf(i.value) !== -1) {
					let countAllowed = true;
					_.map(i.module, (f) => {
						const isModule = AccessManager.isModuleAccess(f, this.context.organization);
						if (isModule && countAllowed) {
							countAllowed = false;
							totalPermissions++;
						}
					})
				}
			})
		})

		return totalPermissions;
	}

	renderCells() {
		const { data } = this.props;
		const { isOpenEditModal } = this.state;

		return [
			<DLTableColumn
				key={`Bank-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-2`}
				align={"left"}>
				{this.totalPermissions(data)}
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-3`}
				align={"left"}>
				{data.total_users}
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-4`}
				align={"left"}>
				{moment(data.created_at).format("MMM Do, Y")}
			</DLTableColumn>,
			<UserRole routePermissions={[ Permissions.ROLE_EDITABLE, Permissions.ROLE_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Bank-${data.id}-Column-5`}
					isCompact={true}
					align={"center"}
					fillWidth={35}>
					<Tooltip title="Edit Role">
						<IconButton
							onClick={() => this.setState({ isOpenEditModal: true })}
							size="small"
							color="primary">
							<EditOutlined />
						</IconButton>
					</Tooltip>
					{
						isOpenEditModal &&
						<RolePermissionModal
							title="Edit Role"
							role={data}
							onClose={() => this.setState({ isOpenEditModal: false })}
							onUpdate={this.onUpdateModal}
							isOpen={isOpenEditModal}
						/>
					}
				</DLTableColumn>
			</UserRole>,
			<UserRole routePermissions={[ Permissions.ROLE_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Bank-${data.id}-Column-6`}
					isCompact={true}
					align={"center"}
					fillWidth={35}>
					<>
						{
							data.is_update_allow &&
							<Tooltip title="Delete Role">
								<IconButton
									onClick={() => this.setState({ isModalOpen: true })}
									size="small"
									color="secondary">
									<DeleteOutline />
								</IconButton>
							</Tooltip>
						}

						<ConfirmModal
							title="Delete Role"
							isOpen={this.state.isModalOpen}
							message={`Are you sure you want to delete ${data.name}?`}
							onClickCancel={() => {
								this.setState({ isModalOpen: false });
							}}
							onClickConfirm={this.delete} />
					</>

				</DLTableColumn>
			</UserRole>
		]
	}

}

RoleTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "total_permission",
		label: "No. of permissions",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "total_user",
		label: "Total Users",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "create_at",
		label: "Create At",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

export default withRouter(RoleTableRow);