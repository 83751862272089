const drawerWidth = 290;

const styles = theme => ({
	
	root: {
		display: "flex",
		width: "100vw",
		height: "100vh",
		flexDirection: "column",
		overflowX: "hidden"
	},

	drawerContainer: {
		flex: 1,
		display: "flex",
		flexDirection: "row"
	},

	drawer: {
		height: "auto",
		minHeight: "100%",
		zIndex: 999
	},

	drawerPaper: {
		position: "relative",
		height: "auto",
		background: "transparent",
		borderRight: "none",
		zIndex: "auto",
		overflow: "visible",
		width: theme.spacing(7),
		minHeight: "100%",

		[theme.breakpoints.down("sm")]: {
			width: theme.spacing(0),
			minHeight: "100%",
			overflow: "hidden",
			transition: theme.transitions.create(["width"], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.complex,
			}),

			"&.open": {
				width: theme.spacing(32)
			}
		}
	},

	link: {
		textDecoration: 'none'
	},

	menu: {
		borderTopRightRadius: 150,
		borderBottomRightRadius: 150,
		marginTop: 8,
		color: "#000",
		display: "flex",
		position: "relative",
		alignItems: "center",
		justifyContent: "flex-start",
		overflow: "hidden",
		width: theme.spacing(7),
		zIndex: theme.zIndex.appBar - 10,
		transition: theme.transitions.create(["width", "background-color"], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.complex,
		}),

		"&:hover": {
			width: theme.spacing(32),
			backgroundColor: theme.palette.primary.main,
			boxShadow: theme.shadows[8],

			[theme.breakpoints.down("sm")]: {
				width: drawerWidth - theme.spacing(5),
			}
		},
		
		"&:hover $menuIcon": {
			color: "#ffffff"
		},
		
		"&:hover $menuWrapper $menuText": {
			opacity: 1,
			color: "#ffffff",
		},
		
		"&.Mui-focusVisible": {
			backgroundColor: theme.palette.primary.main,
		},
		
		[theme.breakpoints.down("sm")]: {
			width: drawerWidth - theme.spacing(5),
			paddingTop : theme.spacing(1),
			paddingBottom: theme.spacing(1),
			marginTop : theme.spacing(2),
			marginBottom: theme.spacing(2),
			marginRight: theme.spacing(2)
		}
	},

	menuActive: {
		color: "#ffffff",
		backgroundColor: theme.palette.primary.main,
		boxShadow: theme.shadows[4]
	},

	menuIcon: {
		color: "inherit",
		marginRight: theme.spacing(1),
		minWidth: "auto"
	},

	menuWrapper: {
		width: "100%",
		overflow: "hidden",
		display: "block",
		flexShrink: 0,
		padding: 0,
		marginLeft: 16
	},

	menuText: {
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.pxToRem(theme.typography.fontSize + 2),
		width: "100%",
		whiteSpace: "nowrap",
		fontWeight: "600",
		letterSpacing: 0.4,
		height: "100%",
		opacity: 0,
		
		[theme.breakpoints.down("sm")]: {
			opacity: "1",
		},
	},

	childrenContainer: {
		position: "relative",
		boxSizing: "border-box",
		padding: 20,
		flex: 1,
		overflow: "hidden",

		[theme.breakpoints.down("sm")]: {
			flex: "1 0 100%"
		}
	},

	childrenContainerOverlay: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		display: "none",

		[theme.breakpoints.down("sm")]: {
			display: "block"
		}
	}

});

export default styles;