import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton
} from '@material-ui/core';
import PlanDropdown from "@/components/Dropdowns/PlanDropdown";
import { DatePicker } from "@material-ui/pickers";
import { Clear as ClearIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import moment from "moment";
import Config from "@/config";
import { Constants } from "@/config";


class OrganizationPurchaseModal extends Component {

	static propTypes = {
		organization: PropTypes.object.isRequired,
		purchase : PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onUpdate: PropTypes.func,
		onClose: PropTypes.func
	};

	static defaultProps = {
		title: "Add Purchase",
		onUpdate: () => { }
	};

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			purchase: {}
		};
	}
	purchase() {
		return {
			organization_id: this.isNew() ? this.props.organization.id : this.props.purchase.organization_id,
			plan_id: null,
			start_date: this.isNew() ? moment().format("YYYY-MM-DD") : "",
			trial_end: null,
			end_date: null,
			comment : null,
			...this.props.purchase,
			...this.state.purchase
		};
	}

	isNew() {
		return !this.props.purchase || !this.props.purchase.id;
	}
	
	handleChange(value, field) {
		const purchase = this.purchase();
		if(field === "plan_id" && value !== null){
			value = value.id;

		} else if (["start_date", "trial_end", "end_date"].indexOf(field) !== -1 && value) {
			value = moment(value).format("YYYY-MM-DD");
			if (field == "start_date") {
				if (purchase.trial_end && moment(purchase.trial_end).isBefore(value)) {
					purchase.trial_end = value;
				}
				if (purchase.end_date && moment(purchase.end_date).isBefore(value)) {
					purchase.end_date = value;
				}
			}
		}

		this.setState({
			purchase: {
				...purchase,
				[field]: value
			},
			errors: {
				...this.state.errors,
				[field]: false
			}
		});
	}

	validate() {
		const purchase = this.purchase();
		const requiredFields = ["plan_id", "start_date"];
		var errors = {};
		
		_.forOwn(purchase, (value, key) => {
			if (requiredFields.indexOf(key) !== -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const purchase = this.purchase();
		
		const dateFields = ["start_date", "end_date", "trial_end"];
		_.forOwn(purchase, (value, key) => {
			if (dateFields.indexOf(key) !== -1 && value) {
				purchase[key] = moment(value).format(Config.SERVER_DATE_FORMAT);
			}
		});
		delete purchase.plan;
		
		this.props.setLoader(true);
		try {
			var _purchase = null;
			if (this.isNew()) {
				_purchase = await Api.purchases.post(purchase);
				this.props.setSnackbar(true, "New purchase added successfully");
			} else {
				_purchase = await Api.purchases(purchase.id).post(purchase);
				this.props.setSnackbar(true, "Purchase updated successfully");
			}
			this.props.onUpdate(_purchase);
			this.props.onClose();

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					var errors = _.mapValues(response.data, value => value[0]);
					this.setState({ errors });
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	render() {
		const { isOpen, title } = this.props;
		const { errors } = this.state;
		const purchase = this.purchase();

		return (
			<Dialog
				fullWidth
				maxWidth="xs"
				open={isOpen}
				onBackdropClick={this.props.onClose}
				onClose={this.props.onClose}
				aria-labelledby="organization-purchase-dialog-title"
			>
				<DialogTitle id="organization-purchase-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PlanDropdown
								value={purchase.plan_id}
								onChange={plan_id => this.handleChange(plan_id, "plan_id")}
								textFieldProps={{
									error: !!errors.plan_id,
									helperText: errors.plan_id,
									FormHelperTextProps: { variant: "standard" },
									size: "small"
								}}
							/>
						</Grid>
						<Grid item xs={12} key="start_date">
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.start_date}
								helperText={errors.start_date}
								FormHelperTextProps={{ variant: "standard" }}
								label="Start Date"
								inputVariant="outlined"
								// maxDate={moment.now()}
								format= {Config.DISPLAY_DATE_FORMAT}
								value={purchase.start_date}
								onChange={e => this.handleChange(e, "start_date")}
							/>
						</Grid>
						<Grid item xs={12} key="trial_end">
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.trial_end}
								helperText={errors.trial_end}
								FormHelperTextProps={{ variant: "standard" }}
								label="Trial End"
								minDate={purchase.start_date ? purchase.start_date : moment.now()}
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={purchase.trial_end}
								onChange={e => this.handleChange(e, "trial_end")}
								InputProps={{
									endAdornment: (
										!!purchase.trial_end &&
										<IconButton size="small" onClick={e => this.handleChange(null, "trial_end")}>
											<ClearIcon />
										</IconButton>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} key="end_date">
							<DatePicker
								autoOk
								fullWidth
								size="small"
								error={!!errors.end_date}
								helperText={errors.end_date}
								FormHelperTextProps={{ variant: "standard" }}
								label="End Date"
								minDate={purchase.start_date ? purchase.start_date : null}
								inputVariant="outlined"
								format={Config.DISPLAY_DATE_FORMAT}
								value={purchase.end_date}
								onChange={e => this.handleChange(e, "end_date")}
								InputProps={{
									endAdornment: (
										!!purchase.end_date &&
										<IconButton size="small" onClick={e => this.handleChange(null, "end_date")}>
											<ClearIcon />
										</IconButton>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} key="comment">
							<TextField
								fullWidth
								size = {"small"}
								multiline
								rows={4}
								label="Comments"
								error={!!errors.code}
								helperText={errors.code}
								FormHelperTextProps={{ variant: "standard" }}
								value={purchase.comment || ""}
								onChange={e => this.handleChange(e.target.value, "comment")}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={this.props.onClose} color="default">Cancel</Button>
					<Button size="small" variant="contained" onClick={this.save} color="primary">Save</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(OrganizationPurchaseModal);