import _ from 'lodash'
var DepartmentsVariables = {
    list: [],
    meta: {},
    archiveMeta: {},
    archiveList: []
}

export default function (state = DepartmentsVariables, action) {
    var list = []
    switch (action.type) {
        case 'DEPARTMENTS_LIST':

            if (!action.isReset) {
                list = state.list
            }
            list.push(action.list);


            return state = {
                ...state,
                list: _.flattenDeep(list)
            }

        case 'DEPARTMENTS_META':
            return state = {
                ...state,
                meta: action.meta
            }
         
        case 'DEPARTMENTS_ARCHIVE_LIST':
            if (!action.isReset) {
                list = state.archiveList
            }
            list.push(action.archiveList);
 
            return state = {
                ...state,
                archiveList: _.flattenDeep(list)
            }
 
         case 'DEPARTMENTS_ARCHIVE_META':
            return state = {
                ...state,
                archiveMeta: action.archiveMeta
            }

        default:
            return state
    }
}