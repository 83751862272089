import React, { Component } from "react";
import { Button, CardActions, Icon, FormControlLabel, Switch, Grid, Card} from "@material-ui/core";
import { DLTable, BulkAction, InfographicMessage } from "@/components";
import EmployeeTaxAdjustmentModal from "@/components/Modals/EmployeeTaxAdjustmentModal";
import EmployeeTaxAdjustmentTableRow from "./EmployeeTaxAdjustmentTableRow";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import { connect } from "react-redux";
import _ from "lodash";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import Filter from "@/components/Filter";
import Methods from "@/components/Filter/methods";
import moment from "moment";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import PermissionHOC from "@/components/PermissionHOC";
import FilterData from "./FilterData";

class EmployeeTaxAdjustments extends Component {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();

		this.state = {
			isAddingTaxAdjustment: false,
			is_tax_exempted: this.props.employee.is_tax_exempted,
			filters: null
		};
	}

	taxYear() {
		return moment().month() >= 6 ? moment().year() + 1 : moment().year();
	}

	getTaxAdjustment = async (filters) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			!!oldFilters && this.reload();
		});
	};

	reload = () => {
		this.setState({ isAddingTaxAdjustment: false });
		this.tableRef.current && this.tableRef.current.fetchData();
	};

	emptyView() {
		const { employee, hasPermission } = this.props;
		if (hasPermission([Permissions.EMPLOYEES_FULL_ACCESS])) {
			return (
				<InfographicMessage
					illustration="tax"
					message={`${employee.first_name} doesn't have any tax adjustment yet, add one now!`}
					actionLabel={"Add Tax Adjustment"}
					actionIcon={"add"}
					actionHandler={() => this.setState({ isAddingTaxAdjustment: true })}
				/>
			);
		}

		return (
			<InfographicMessage
				illustration="tax"
				message={`${employee.first_name} doesn't have any tax adjustment yet`}
			/>
		);
	}

	changeTaxExempted = async (is_tax_exempted = false) => {
		const { employee } = this.props;
		is_tax_exempted = is_tax_exempted ? 1 : 0;

		try {
			var _employee;
			_employee = await Api.employees(employee.id).put({ is_tax_exempted });
			this.setState({ is_tax_exempted: !!is_tax_exempted });
			this.props.onUpdate(_employee);

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
	}


	async deleteItem(selected) {
		const { employee } = this.props;
		this.props.setLoader(true);
			try {
				await Api.employees(employee.id).tax_adjustments.delete_multiple.delete({  ids: selected.map(e => e.id), })
				this.props.setSnackbar(true, "Selected items deleted successfully");
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}


	getItems() {
		let items = [{
				name: "Delete Selected",
				color: "secondary",
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={"Delete Tax Adjustment"}
						message={`Are you sure to delete ${selected.length} tax adjustment${selected.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							await this.deleteItem(selected);
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}]
		return items;
	}

	render() {
		const { employee } = this.props;
		const { isAddingTaxAdjustment, is_tax_exempted, filters } = this.state;
		const dateFilter = FilterData[0];

		return (
			<Card raised>
				<Grid  container justifyContent={"space-between"}>
					<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
						<CardActions >
							<Button
								variant="outlined"
								size="medium"
								color="primary"
								onClick={() => this.setState({ isAddingTaxAdjustment: true })}>
								<Icon>add</Icon>
								Add Tax Adjustment
							</Button>
							<BulkAction
								ref={this.bulkAction}
								options={this.getItems()}
							/>
						</CardActions>
						<CardActions>
							<FormControlLabel
								value=""
								control={<Switch color="primary" checked={is_tax_exempted} onChange={() => this.changeTaxExempted(!is_tax_exempted)} />}
								label="Tax Exempted"
								disabled={!this.props.hasPermission([Permissions.EMPLOYEES_FULL_ACCESS])}
								labelPlacement="start"
								style={{whiteSpace: "nowrap"}}
							/>
							<Filter
								fields={FilterData}
								filters={
									filters || [
										{
											operator: "and",
											field: dateFilter.key,
											method: Methods[dateFilter.type][4].key,
											value: moment().year(this.taxYear() - 1).month(6).startOf("month").format("YYYY-MM-DD")
										},
										{
											operator: "and",
											field: dateFilter.key,
											method: Methods[dateFilter.type][3].key,
											value: moment().year(this.taxYear()).month(5).endOf("month").format("YYYY-MM-DD")
										}
									]
								}
								withQueryParams
								onUpdate={this.getTaxAdjustment}
							/>
						</CardActions>
					</UserRole>
				</Grid>
				{
					!!filters &&
					<DLTable
						bulkAction={this.bulkAction}
						ref={this.tableRef}
						enableSelection
						api={Api.employees(employee.id).tax_adjustments}
						params={{ filter: filters }}
						rowProps={{ employee }}
						RowTemplate={EmployeeTaxAdjustmentTableRow}
						emptyView={this.emptyView()}
					/>
				}
				{
					isAddingTaxAdjustment &&
					<EmployeeTaxAdjustmentModal
						title={"Add Tax Adjustment"}
						isOpen={isAddingTaxAdjustment}
						employee={employee}
						onUpdate={this.reload}
						onClose={() => this.setState({ isAddingTaxAdjustment: false })}
					/>
				}
			</Card>
		);
	}
}

const mapStateToProps = state => ({
	filterCounter: state.general.filterCounter
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionHOC(EmployeeTaxAdjustments));
