import React, { Component } from "react";
import {
	withRouter,
	Switch,
	Route,
	Link
} from "react-router-dom";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { ArchiveOutlined, Inbox } from "@material-ui/icons";
import {
	BackButton,
	DLTable,
	BulkAction,
	InfographicMessage,
	Page,
	PermissionHOC,
	Tabs,
	UserRole
} from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import DesignationTableRow from "./DesignationTableRow";
import AddDesignationModal from "../AddDesignation";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import { AuthContext } from "@/contexts";
import { Permissions } from "@/config/Permissions";
import _ from "lodash";

class Designation extends Component {
	_table = null;

	constructor(props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();
		this.state = {
			activeTabIndex: 0,
			selectedCount: 0
		}
	}

	static contextType = AuthContext;

	addNew = (e) => {
		e && e.preventDefault();
		this.props.history.push({
			pathname: `/settings/designations/add`,
		})
	};

	reload = () => {
		this.setState({ isModalOpen: false });
		this.tableRef && this.tableRef.current && this.tableRef.current.fetchData();
	};

	emptyView(archived = false) {
		const hasPermission = this.props.hasPermission([Permissions.DESIGNATION_EDITABLE, Permissions.DESIGNATION_FULL_ACCESS]);
		const message = !archived
			? "You don't have any designation yet, add one now!"
			: "Yaayyy! all designations are active and in use";
		return (
			<InfographicMessage
				illustration="designation"
				message={message}
				actionLabel={archived ? "" : hasPermission ? "Add Designation" : ""}
				actionIcon={archived ? "" : hasPermission ? "add" : ""}
				actionHandler={this.addNew}
			/>
		);
	}

	async delete(selected) {
		const { employee } = this.props;
		const { activeTabIndex} = this.state;
		this.props.setLoader(true);
		try {
			await Api.designations.delete_multiple.delete({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items archived successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	async restore(selected) {
		const { employee } = this.props;
		const { activeTabIndex} = this.state;
		this.props.setLoader(true);
		try {
			await Api.designations.restore_multiple.patch({ ids: selected.map(e => e.id) });
			this.props.setSnackbar(true, `Selected items restored successfully`);
		} 
		catch (e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
				if (errors && errors.length) {
					this.props.setSnackbar(true, errors[0], "error");
				} else {
					this.props.setSnackbar(true, "Something went wrong, please try again", "error")
				}
			})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
			.go();
		}
		this.props.setLoader(false);
		if (this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	}

	getItems() {
		const { activeTabIndex} = this.state;
		var buttonName = !!activeTabIndex ? "Restore Selected" : "Archive Selected";
		var buttonColors = !!activeTabIndex ? "primary" : "secondary";

		let items = [{
				name: buttonName,
				color: buttonColors,
				action: (_, action) => action.setState({ enableLeaveModal: true }),
				render: (selected, action) => (
					action.state.enableLeaveModal &&
					<ConfirmModal
						isOpen={action.state.enableLeaveModal}
						title={!!activeTabIndex ? "Restore Designation" : "Archive Designation"}
						message={`Are you sure to ${!!activeTabIndex ? "restore" : "archive"} ${selected.length} Designation${selected.length == 1 ? '' : 's'}?`}
						onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
						onClickConfirm={async () => {
							if (!!activeTabIndex) {
								await this.restore(selected);
							}
							else{
								await this.delete(selected);
							}
							action.setState({ enableLeaveModal: false, activeOption: null });
						}}
					/>
				)
			}];
		return items;
	}

	actionBar() {
		const { activeTabIndex } = this.state;

		return (
			<UserRole routePermissions={[Permissions.DESIGNATION_EDITABLE, Permissions.DESIGNATION_FULL_ACCESS]}>
				
					<CardActions>
						<Link to={{ pathname: `/settings/designations/add` }} style={{ textDecoration: 'none', color: "black" }}>
							<Button
								variant="outlined"
								size="medium"
								color="primary">
								<Icon>add</Icon>
								Add Designation
							</Button>
						</Link>
						<BulkAction
							ref={this.bulkAction}
							options={this.getItems()}
						/>
					</CardActions>
			</UserRole>
		);
	}

	onSelectionChange = selected => {
		this.setState({ selectedCount: selected.length });
	}

	render() {
		return (
			<Page title="Designations">
				<Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
					<BackButton route="/settings" label="Back to Settings" />
				</Link>
				<Card raised>
					<Tabs
						beforePanel={this.actionBar()}
						onChange={activeTabIndex => this.setState({ activeTabIndex }, this.onSelectionChange([]))}>
						<Tabs.Tab
							icon={<Inbox />}
							label="Active">
							<DLTable
								enableSelection
								ref={this.tableRef}
								api={Api.designations}
								emptyView={this.emptyView()}
								RowTemplate={DesignationTableRow}
								bulkAction={this.bulkAction}
								/>
						</Tabs.Tab>
						<Tabs.Tab
							icon={<ArchiveOutlined />}
							label="Archived">
							<DLTable
								enableSelection
								api={Api.designations}
								ref={this.tableRef}
								emptyView={this.emptyView(true)}
								RowTemplate={DesignationTableRow}
								params={{ filter: { archived: true } }}
								bulkAction={this.bulkAction}
								/>
						</Tabs.Tab>
					</Tabs>
				</Card>
				<Switch>
					<Route path="/settings/designations/:id">
						<AddDesignationModal
							title={"Edit Designation"}
							backRoute={"/settings/designations"}
							onUpdate={this.reload} />
					</Route>
					<Route path="/settings/designations/add">
						<AddDesignationModal
							backRoute={"/settings/designations"}
							onUpdate={this.reload} />
					</Route>
				</Switch>
			</Page>
		);
	}
}
const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(Designation));