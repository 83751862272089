import React, { Component } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Chip,
    Grid,
    Typography,
} from "@material-ui/core";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import { InfographicMessage, PDFViewer } from "@/components";
import styles from "./style";
import { Constants } from "@/config";
import Theme from "@/themeStyles";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import {
    ClearOutlined,
    DoneOutlined,
} from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";

class DocumentRequestModal extends Component {
    static propTypes = {
        employee: Proptypes.object.isRequired,
        onClose: Proptypes.func.isRequired,
        isOpen: Proptypes.bool.isRequired,
    };

    state = {
        isDelete: false,
        isConfirmModalOpen: false,
        ext: null,
        to: null,
        url: null,
        enableDownload: false,
    };
    async componentDidMount() {
        const { data } = this.props;
        var exT = data.document_path.split(".");

        this.setState({ ext: exT[exT.length - 1] });
    }
    download = () => {
        const { data, employeeDocument } = this.props;
        if (employeeDocument === true) {
            window.open(
                Api.employee_document(data.pending_request.id).document.getUrl(),
                "_blank"
            );
        } else {
            window.open(
                Api.organization_document(
                    data.pending_request.id
                ).document.getUrl(),
                "_blank"
            );
        }
    };
    delete = async (e) => {
        e.preventDefault();
        this.setState({ isDelete: false });
        const { data } = this.props;
        try {
            await Api.employee_document(data.id).delete();
            store.dispatch(
                setSnackbar(
                    true,
                    `Document "${data.name}" deleted successfully`
                )
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            HttpErrorHandler.intercept(e)
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.message) ||
                            "Something went wrong, please try again later",
                        "error"
                    );
                })
                .on(422, (response) => {
                    this.setState({
                        errors: _.mapValues(response.data, (value) => value[0]),
                    });
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.leave_request) ||
                            "Please fix the highlighted errors",
                        "error"
                    );
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
    };

    onConfirm = async () => {
        const { isConfirmModalOpen } = this.state;
        const { data } = this.props;
        try {
            store.dispatch(setLoader(true));
            var status = isConfirmModalOpen === "approve" ? "approved" : "rejected";
            
            await Api.employee_document(data.pending_request.id).put({
                status,
                _method: "put",
            });

            store.dispatch(setSnackbar(true, `Request ${status} successfully`, "success"));
            this.setState({ isConfirmModalOpen: false });
            this.props.onUpdate();
            this.props.onClose();

        } catch (e) {
            store.dispatch(
                setSnackbar(
                    true,
                    "Something went wrong, please try again later",
                    "error"
                )
            );
        }
        store.dispatch(setLoader(false));
    };
    
    returnStatusColor(status) {
        switch (status) {
            case Constants.Reimbursable.FLAG_PENDING:
                return Theme.palette.tableBorder.main;
            case Constants.Reimbursable.FLAG_APPROVED:
                return Theme.palette.success.main;
            case Constants.Reimbursable.FLAG_REJECTED:
                return Theme.color.red.persianRed;
            case Constants.Reimbursable.FLAG_PAID:
                return Theme.palette.primary.main;
            default:
                break;
        }
    }

    render() {
        const { onClose, isOpen, data, employeeDocument, classes } = this.props;
        var file = "";
        var type = "";

        if (employeeDocument && data.pending_request) {
            file = Api.employee_document(data.pending_request.id).document.getUrl();
            type = this.state.ext;
        } 

        const documentIcon = Constants.DocMimeTypes.doc.indexOf(data.pending_request.mime_type) != -1
						? "file_document"
						: (
							Constants.DocMimeTypes.spreadsheet.indexOf(data.pending_request.mime_type) != -1
							? "file_spreadsheet"
							: (
								Constants.DocMimeTypes.presentation.indexOf(data.pending_request.mime_type) != -1
								? "file_presentation"
								: (
									"file_unknown"
								)
							)
						);

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                aria-labelledby="tax-documents-dialog-title"
                aria-describedby="tax-documents-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="tax-documents-dialog-title">
                    <span>Review Update Request</span>
                    <Grid container justifyContent="space-between" style = {{marginTop:8}} spacing={2} direction="row">
                        <Grid item direction="column"  spacing={2} >
                            <Typography
                                variant="subtitle1"
                                className={classes.name}
                            >
                                Title: {data.pending_request.name.toUpperCase()}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.code}
                            >
                                Category: {data.pending_request.category.toUpperCase()}
                            </Typography>
                        </Grid>
                        <span>
                            <Chip
                                size="small"
                                label={data.pending_request.status.toUpperCase()}
                                color={"primary"}
                                style={{
                                    backgroundColor: this.returnStatusColor(
                                        data.pending_request.status
                                    ),
                                    color: Theme.color.white.main,
                                }}
                            />
                        </span>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid
                        style={{
                            alignSelf: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        {
                            Constants.DocMimeTypes.pdf.indexOf(data.pending_request.mime_type) != -1 &&
                            <PDFViewer url={file}/>
                        }
                        {
                            Constants.DocMimeTypes.image.indexOf(data.pending_request.mime_type) != -1 &&
                            <img className={classes.documentPage} src={file} alt={data.pending_request.name}/>
                        }
                        {
                            [...Constants.DocMimeTypes.pdf, ...Constants.DocMimeTypes.image].indexOf(data.pending_request.mime_type) == -1 &&
                            <div className={classes.noPreview}>
                                <InfographicMessage
                                    illustration={documentIcon}
                                    message="Preview seems shy!"
                                    submessage="The document preview is shy and doesn't want to show itself, but don't worry! You can still download the file to view its contents."
                                    actionIcon={"cloud_download"}
                                    actionLabel="Download"
                                    actionHandler={this.download}
                                />
                            </div>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid>
                            {data.pending_request.status === "pending" && (
                                <Button
                                    size={"small"}
                                    onClick={() => this.setState({ isConfirmModalOpen: "approve" })}
                                    color="primary">
                                    <DoneOutlined style={{height :20, width : 20}} size="small" />
                                    Approve
                                </Button>
                            )}
                            {data.pending_request.status === "pending" && (
                                <Button
                                    size={"small"}
                                    onClick={() => this.setState({ isConfirmModalOpen: "reject" }) }
                                    color="secondary">
                                    <ClearOutlined style={{height :20, width : 20}} size="small" />
                                    Reject
                                </Button>
                                
                            )}
                            {
                                !!this.state.isConfirmModalOpen && (
                                <ConfirmModal
                                    isOpen={!!this.state.isConfirmModalOpen}
                                    title={
                                        this.state.isConfirmModalOpen ===
                                        "approve"
                                            ? "Approve Request"
                                            : "Reject Request"
                                    }
                                    message={`Are you sure you want to ${this.state.isConfirmModalOpen} this request`}
                                    onClose={() => this.setState({ isConfirmModalOpen: false }) }
                                    onClickCancel={() => this.setState({ isConfirmModalOpen: false })}
                                    onClickConfirm={this.onConfirm}
                                />
                            )}
                        </Grid>
                        <Grid>
                            <Button size={"small"} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                size={"small"}
                                onClick={this.download}
                                color="primary"
                            >
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DocumentRequestModal);
