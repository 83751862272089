import {
    DoneOutlined,
    EditOutlined,
    ClearOutlined,
    DeleteOutlined,
} from "@material-ui/icons";
import { DLTableColumn, DLTableRow, SVGIcons, EmployeeAvatar } from "@/components";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import LeaveRequestModal from "@/components/Modals/LeaveRequestModal";
import { Permissions } from "@/config/Permissions";
import React from "react";
import {
    Tooltip, IconButton, Avatar,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Chip
} from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import store from "@/redux";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import UserRole from "@/components/UserRole";

class LeaveTableRow extends DLTableRow {
    constructor (props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isEditing: false,
            isConfirmModalOpen: false
        };
    }

    delete = async (e) => {
        e.preventDefault();
        this.setState({ isModalOpen: false });
        const { data } = this.props;
        try {
            await Api.leave_requests(data.id).delete();
            store.dispatch(
                setSnackbar(true, `Leaves "${data.leave.name}" deleted successfully`)
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            HttpErrorHandler.intercept(e)
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.message) || "Something went wrong, please try again later",
                        "error"
                    )
                })
                .on(422, (response) => {
                    this.setState({
                        errors: _.mapValues(response.data, (value) => value[0]),
                    });
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.leave_request) || "Please fix the highlighted errors",
                        "error"
                    );
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
    };

    onConfirm = async () => {
		const { isConfirmModalOpen } = this.state;
		const { data } = this.props;
        console.log(data);
		try {
			this.props.setLoader(true);
			await Api.leave_requests(data.id).put({
                employee_id: data.employee_id,
                leave_id: data.leave_id,
                reason: data.reason,
                start_date: data.start_date,
                end_date: data.end_date,
				status: isConfirmModalOpen === "approve" ? "approved" : "rejected",
                _method: "put"
			});

            var status    = isConfirmModalOpen === "approve" ? "approved" : "rejected";
            var is_delete = isConfirmModalOpen === "approve" ? "success" : "error";
            store.dispatch(setSnackbar(true, `Leave request ${status}`, is_delete));

			await this.props.tableRef.fetchData();
			this.setState({ isConfirmModalOpen: false });
		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
		this.props.setLoader(false);
	};


    renderCells() {
        const { leaveRequest, data, classes } = this.props;
        const { isEditing, isConfirmModalOpen } = this.state;
        return [
            <DLTableColumn key={`Leaves-${data.id}-Column-1`} withSeparator>
                <EmployeeAvatar data={data} classes={classes}/>
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-2`} align={"left"}>
                {data.leave.name}
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-3`} align={"left"}>
                {data.reason}
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-4`} align={"left"} withSeparator>
                {moment(data.created_at,"YYYY-MM-DD").format("Do MMM, yyyy")}
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-5`} align={"left"}>
                {moment(data.start_date,"YYYY-MM-DD").format("Do MMM, yyyy")}
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-6`} align={"left"}>
                {moment(data.end_date,"YYYY-MM-DD").format("Do MMM, yyyy")}
            </DLTableColumn>,

            <DLTableColumn key={`Leaves-${data.id}-Column-7`} align={"right"}>
                {data.leave_days}
            </DLTableColumn>,

            <DLTableColumn
                key={`Leaves-${data.id}-Column-8`}
                align={"right"}>
                <Chip size="small" label={(data.status).toUpperCase()} color={data.status === "pending" ? "default" : (data.status === "approved" ? "primary" : "secondary")} />
            </DLTableColumn>,

            <DLTableColumn
                key={`Leaves-${data.id}-Column-9`}
                isCompact={true}
                align={"center"}>
                {
                    data.status === "pending" && 
                    <UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>
                        <Tooltip title="Reject Request">
                            <IconButton
                                onClick={() => this.setState({ isConfirmModalOpen: "reject" })}
                                size="small"
                                color="secondary">
                                <ClearOutlined />
                            </IconButton>
                        </Tooltip>
                        
                    </UserRole>
                }
            </DLTableColumn>,

            <DLTableColumn
                key={`Leaves-${data.id}-Column-10`}
                isCompact={true}
                align={"center"}>
                {
                    data.status === "pending" && 
                    <UserRole routePermissions={[Permissions.EMPLOYEES_ATTENDANCE_EDITABLE, Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS]}>

                        <Tooltip title="Approve Request">
                            <IconButton
                                onClick={() => this.setState({ isConfirmModalOpen: "approve" })}
                                size="small"
                                color="primary"
                            >
                                <DoneOutlined />
                            </IconButton>
                        </Tooltip>
                        {
                            !!isConfirmModalOpen &&
                            <ConfirmModal
                                isOpen={!!isConfirmModalOpen}
                                title={isConfirmModalOpen === "approve" ? "Approve Request" : "Reject Request"}
                                message={`Are you sure you want to ${isConfirmModalOpen} this request`}
                                onClose={() => this.setState({ isConfirmModalOpen: false })}
                                onClickCancel={() => this.setState({ isConfirmModalOpen: false })}
                                onClickConfirm={this.onConfirm}
                            />
                        }
                    </UserRole>
                }
            </DLTableColumn>,

            <DLTableColumn
                key={`Employee-${data.id}-Column-11`}
                align={"center"}
                isCompact={true}>
                <Tooltip title="Edit Leaves">
                    <IconButton
                        onClick={() => this.setState({ isEditing: " " })}
                        size="small"
                        color="primary">
                        <EditOutlined />
                    </IconButton>
                </Tooltip>
                <LeaveRequestModal
                    mode={isEditing}
                    Request={leaveRequest}
                    position={data.id}
                    leaveRequest={data}
                    title="Edit Leaves"
                    onUpdate={this.props.reload}
                    onClose={() => this.setState({ isEditing: false })} />
            </DLTableColumn>,
            <DLTableColumn
                key={`Leaves-${data.id}-Column-12`}
                isCompact={true}
                align={"center"}>
                {
                    data.status === "pending" &&
                    <>
                        <Tooltip title="Delete Leaves">
                            <IconButton
                                onClick={() => this.setState({ isModalOpen: true })}
                                size="small"
                                color="secondary">
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>
                        <ConfirmModal
                            title="Delete Leaves"
                            isOpen={this.state.isModalOpen}
                            message={`Are you sure you want to Delete Leaves ${data.leave.name}?`}
                            onClickCancel={() => {
                                this.setState({ isModalOpen: false });
                            }}
                            onClickConfirm={this.delete} />
                    </>
                }
            </DLTableColumn>,
        ];
    }
}

LeaveTableRow.Columns = [
    {
        key: "name",
        label: "Employee",
        align: "left",
        isSortable: true,
        withSeparator: true,
    },
    {
        key: "Leave",
        label: "Leave",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "reason",
        label: "Reason",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "created_at",
        label: "Requested On",
        align: "left",
        isSortable: true,
        withSeparator: true,
        fillWidth: 130
    },
    {
        key: "start_date",
        label: "Start Date",
        align: "left",
        isSortable: true,
        withSeparator: false,
        fillWidth: 130
    },
    {
        key: "end_date",
        label: "End Date",
        align: "left",
        isSortable: true,
        withSeparator: false,
        fillWidth: 130
    },
    {
        key: "leave_days",
        label: "Days",
        align: "right",
        isSortable: true,
        withSeparator: false,
    },

    {
        key: "status",
        label: "Status",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: "action",
        label: "",
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true
    },

    {
        key: null,
        label: null,
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 3,
    },
];

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))

});
export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(LeaveTableRow));
