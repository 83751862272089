import React, { Component } from "react";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { BackButton, DLTable, InfographicMessage, Page } from "@/components";
import AttendanceTerminalModal from "@/components/Modals/AttendanceTerminalModal";
import LinkTerminalEmployeeModal from "@/components/Modals/LinkTerminalEmployeeModal";
import AttendanceTerminalTableRow from "./AttendanceTerminalTableRow";
import { Api } from "@/helpers";
import styles from "./style.js";

class AttendanceTerminal extends Component {

	_table = null;

	addNew = (event) => {
		event && event.preventDefault();
		this.props.history.push('/settings/attendance/terminals/add');
	};

	closeModal = () => {
		if (this.props.history.length > 1) {
			this.props.history.goBack();
		} else {
			this.props.history.push('/settings/attendance/terminals');
		}
	};

	emptyView(archived = false) {
		const message = !archived
			? "You don't have any ZKTeco attendance terminal yet, add one now!"
			: "Yaayyy! all ZKTeco attendance terminals are active and in use";
		return (
			<InfographicMessage
				illustration={"zkteco_terminal"}
				message={message}
				actionLabel={archived ? "" : "Add ZKTeco Terminal"}
				actionIcon={archived ? "" : "add"}
				actionHandler={this.addNew}
			/>
		);
	}

	render() {
		const { classes } = this.props;
        return (
			<Page title="Attendance Terminals">
				<BackButton route="/settings" label="Back to Settings" />
				<Card raised>
					<CardActions>
						<Link
							className={classes.link}
							to="/settings/attendance/terminals/add"
							underline="none">
							<Button
								variant="outlined"
								size="medium"
								color="primary">
								<Icon>add</Icon>
								Add ZKTeco Terminal
							</Button>
						</Link>
					</CardActions>
					<DLTable
						ref={table => this._table = table}
						api={Api.terminals}
						RowTemplate={AttendanceTerminalTableRow}
						emptyView={this.emptyView()} />
				</Card>
				<Switch>
					<Route exact path="/settings/attendance/terminals/add">
						<AttendanceTerminalModal
							mode="add"
							isOpen
							onUpdate={() => this._table && this._table.fetchData()}
							onClose={this.closeModal}
						/>
					</Route>
					<Route exact path="/settings/attendance/terminals/:id">
						<AttendanceTerminalModal
							mode="edit"
							isOpen
							onUpdate={() => this._table && this._table.fetchData()}
							onClose={this.closeModal}
						/>
					</Route>
					<Route exact path="/settings/attendance/terminals/:id/employees">
						<LinkTerminalEmployeeModal
							isOpen
							onClose={this.closeModal}
						/>
					</Route>
				</Switch>
			</Page>
		);
	}
}

export default withStyles(styles, { withTheme: true })(withRouter(AttendanceTerminal));
