const styles = theme => ({

    divider: {
		height: 1,
		flex: 1,
		display: "flex",
		backgroundColor: theme.palette.borderColor.main
	},
    
    taxReportActions: {
        display: "flex",
        justifyContent: "space-between"
    },

    taxReportFilter: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",

        "& > *:not(:last-child)": {
            flex: "0 0 auto",
            marginRight: theme.spacing(1)
        }
    },

    contentCenter: {
        justifyContent: "center"
    },

    inputSelect: {
        paddingRight: theme.spacing(5)
    },

    backFab: {
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(2),
        background: "#FFFFFF",
        height: "25px !important",
        minWidth: "25px !important",
        fontSize: "0.7rem"
    },

    extendedFabIcon: {
        fontSize: "1.2rem",
        marginRight: theme.spacing(1)
    },

    extendedFabText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    filter: {
        flex: 1,
        marginBottom: theme.spacing(2),
    },
    attendanceWidget: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start"
    },
    pieChart: {
        marginLeft: theme.spacing(2),

    },
    separator: {
        display: "flex",
        flex: 0,
        alignItems: "center",

        "&::after": {
            flex: 1,
            display: "block",
            content: '" "',
            height: 1,
            background: theme.palette.borderColor.main,
            marginLeft: theme.spacing(2)
        }
    },
    totalEmployees: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        color: " rgb(90,90,90)"
    },
    label: {
        justifyContent: "space-between",
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        marginRight: theme.spacing(5)
    },
    chip: {
        paddingLeft: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    chipText: {
        fontSize: "0.8125rem",
        lineHeight: "1.43"
    },
    alignCenter: {
        textAlign: "center"
    }


});

export default styles