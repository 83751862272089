import React from "react";
import { withRouter } from "react-router-dom";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn } from "@/components";
import moment from "moment";

class SalaryLedgerRow extends DLTableRow {

    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                key={`SalaryLedger-${data.id}-Column-1`}
                withSeparator={true}>
                {data.transaction_date ? moment(data.transaction_date).format("DD-MM-YYYY") : "N/A"}
            </DLTableColumn>,
            <DLTableColumn
                key={`SalaryLedger-${data.id}-Column-2`}
                withSeparator={true}>
                {data.description}
            </DLTableColumn>,
            <DLTableColumn
                key={`SalaryLedger-${data.id}-Column-3`}
                align="right">
                {currencyFormatter(data.amount > 0 ? data.amount : 0)}
            </DLTableColumn>,
            <DLTableColumn
                key={`SalaryLedger-${data.id}-Column-4`}
                align="right">
                {currencyFormatter(data.amount < 0 ? (data.amount * -1) : 0)}
            </DLTableColumn>,
            <DLTableColumn
                key={`SalaryLedger-${data.id}-Column-5`}
                align="right">
                {currencyFormatter(data.total_paid)}
            </DLTableColumn>
        ];
    }

}

SalaryLedgerRow.Columns = [
    {
        key: "transaction_date",
        label: "Date",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "description",
        label: "Description",
        align: "left",
        isSortable: false,
        withSeparator: true
    },
    {
        key: null,
        label: "Payment",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: null,
        label: "Deduction",
        align: "right",
        isSortable: false,
        withSeparator: false
    },
    {
        key: null,
        label: "Total Paid",
        align: "right",
        isSortable: false,
        withSeparator: false
    }
];

export default withRouter(SalaryLedgerRow);