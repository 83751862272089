import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@material-ui/core";
import { DLTable, InfographicMessage } from "@/components";
import TerminalEmployeeTableRow from "./TerminalEmployeeTableRow";
import { Api } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style.js";

class LinkTerminalEmployeeModal extends Component {

	static propTypes = {
		terminalId: PropTypes.number,
		isOpen: PropTypes.bool,
		onClose: PropTypes.func
	};

	static defaultProps = {
		terminalId: null,
		isOpen: false
	};

	state = {
		errors: { },
		terminalId: null
	};

	async componentDidMount() {
		const { match : { params : { id: _terminalId } }, terminalId } = this.props;
		this.setState({ terminalId: _terminalId || terminalId });
	}

	emptyView() {
		return (
			<InfographicMessage
				illustration="not_found"
				message="Employees not found in this ZKTeco Terminal"
			/>
		);
	}

	render() {
		const { isOpen } = this.props;
		const { terminalId } = this.state;
        return (
			<Dialog 
				fullWidth
				maxWidth="sm"
				open={isOpen} 
				aria-labelledby="employee-import-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="employee-import-dialog-title">Link Employees</DialogTitle>
				<DialogContent dividers style={{ padding: 0 }}>
					<DLTable
						ref={table => this._table = table}
						api={Api.terminals(terminalId).employees}
						RowTemplate={TerminalEmployeeTableRow}
						rowProps={{ terminalId }}
						emptyView={this.emptyView()}
						stickyHeader
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color="default">Cancel</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withRouter(LinkTerminalEmployeeModal)));