import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Tooltip,
	IconButton,
	Icon,
	Chip
} from "@material-ui/core";
import { EditOutlined, ClearOutlined, DoneOutlined } from "@material-ui/icons";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, Money, UserRole } from "@/components";
import store from "@/redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import EditInvoicesModal from "@/components/Modals/EditInvoicesModal";
import InvoicePreviewModal from "@/components/Modals/InvoicePreviewModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Permissions } from "@/config/Permissions";

class InvoicesTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			isPaidModal: false,
			isUnPaidModal: false
		}
	}

	updateStatus = async (data, status) => {

		try {
		store.dispatch(setLoader(true));
			try {
				await Api.invoices(data.invoice_no).pay.put({status});
				if(status === "unpaid") {
					store.dispatch(setSnackbar(true, `Marked Unpaid`));
				} else {
					store.dispatch(setSnackbar(true, `Marked Paid`));
				}
				this.setState({ isModalOpen: false });
				this.props.reload();

			} catch (e) {
				console.error(e);
				store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
			}
		} catch (e) { }
		store.dispatch(setLoader(false));
	};

	confirmMessage = () => {
		const { data } = this.props;
		return (
			<span>
				Are you sure want to mark {data.status == "paid" ? "unpaid" : "paid"}?
			</span>
		);
	}

	renderCells() {
		const { data } = this.props;
		const { isModalOpen, isUnPaidModal, isPaidModal } = this.state
		return [
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-1`}
				withSeparator
				align={"left"}>
				{data.invoice_no}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-2`}
				withSeparator={false}
				align={"left"}>
				{data.plan.title}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-4`}
				withSeparator={false}
				align={"left"}>
				{ moment(data.start_date).format('MMMM YYYY')}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-5`}
				withSeparator={false}
				align={"left"}>
				{moment(data.created_at).format("YYYY-MM-DD")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-6`}
				withSeparator={false}
				align={"left"}>
				{moment(data.due_date).format("YYYY-MM-DD")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-7`}
				withSeparator={false}
				align={"left"}>
				{data.employees_count}
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-8`}
				withSeparator={false}
				align={"left"}>
				<Money amount={data.net_total} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-9`}
				withSeparator={false}
				align={"left"}>
				<Chip size="small" label={data.status.toUpperCase()} color={data.status === "paid" ? "primary" : "secondary"}/>
			</DLTableColumn>,
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-10`}
				isCompact={true}
				align={"left"}
				fillWidth={35}>
				<UserRole routePermissions={[Permissions.ORGANIZATIONS_FULL_ACCESS]}>
					{
						data.status === "paid" ? (
							<Tooltip title="Mark Unpaid">
								<IconButton
									onClick={() => this.setState({isUnPaidModal : true})}
									size="small"
									color="secondary">
									<ClearOutlined />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Mark Paid">
								<IconButton
									onClick={() => this.setState({isPaidModal : true})}
									size="small"
									color="primary">
									<DoneOutlined />
								</IconButton>
							</Tooltip>
						)
					}
					{
						isPaidModal &&
						<ConfirmModal
							title="Payment Status"
							isOpen={isPaidModal}
							message={this.confirmMessage()}
							onClickCancel={() => this.setState({ isPaidModal: false })}
							onClickConfirm={() => { { this.setState({ isPaidModal: false }) }; this.updateStatus(data, "paid") }}
						/>
					}
					{
						isUnPaidModal &&
						<ConfirmModal
							title="Payment Status"
							isOpen={isUnPaidModal}
							message={this.confirmMessage()}
							onClickCancel={() => this.setState({ isUnPaidModal: false })}
							onClickConfirm={() => { { this.setState({ isUnPaidModal: false }) }; this.updateStatus(data,"unpaid") }}
						/>
					}
				</UserRole>
			</DLTableColumn>,
			data.status == "unpaid" &&
			<DLTableColumn
				key={`Invoice-${data.invoice_no}-Column-11`}
				withSeparator={false}
				isCompact={true}
				fillWidth={35}
				align={"left"}>
				<UserRole routePermissions={[Permissions.ORGANIZATIONS_FULL_ACCESS]}>
					<Tooltip title="Edit Invoice">
						<IconButton
							onClick={() => this.setState({ isModalOpen:true })}
							size="small"
							color="primary">
							<EditOutlined />
						</IconButton>
					</Tooltip>
					{
						isModalOpen &&
						<EditInvoicesModal
							mode={isModalOpen}
							invoice={data}
							title="Edit Invoice"
							onUpdate={this.props.reload}
							onClose={() => this.setState({ isModalOpen: false })}
						/>
					}
				</UserRole>
			</DLTableColumn>,
			<DLTableColumn
				withSeparator={false}
				key={`Invoice-${data.invoice_no}-Column-12`}
				isCompact={true}
				fillWidth={35}
				align={"left"}>
				<Tooltip title="View Invoice">
					<Link underline="none" to={{ pathname: `${this.props.match.url}/${data.invoice_no}`}}>
						<IconButton
							size="small"
							color="primary">
							<RemoveRedEyeOutlined/>
						</IconButton>
					</Link>
				</Tooltip>
			</DLTableColumn>
		]
	}
}

InvoicesTableRow.Columns = [
	{
		key: "id",
		label: "Invoice Number",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "plan.name",
		label: "Plan",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "start_date",
		label: "Month",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "created_at",
		label: "Issue Date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "due_date",
		label: "Due Date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "employees_count",
		label: "Employees",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "net_total",
		label: "Amount",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "status",
		label: "Status",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "",
		label: "",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	
];

export default withRouter(withStyles(styles)(InvoicesTableRow));

