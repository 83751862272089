import React, { Component, Fragment } from "react";
import {
	Icon,
	TextField,
	InputAdornment,
	Button,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress
} from "@material-ui/core";
import ContentLoader from "react-content-loader";
import { EmployeeChip } from "@/components";
import PropTypes from "prop-types";
import { Api } from "@/helpers";
import _ from "lodash";
import FilterData from "@/pages/Employee/FilterData";
import InfographicMessage from "@/components/InfographicMessage";

class EmployeeSelectionModal extends Component {

	state = {
		isLoading: false,
		search: '',
		employees: null,
		selectedEmployees: [],
		submitting: false,
		filterData: FilterData
	};

	async componentDidMount() {
		this.setState({ selectedEmployees: [] })
		if (this.shouldLoadFromApi()) {
			await this.loadEmployees();
		}
	}

	uniqueEmployee = (stateEmployee) => {
		var uniqueEmployee = []
		var employeeArray = stateEmployee;
		_.forEach(employeeArray, (item) => {
			var isExist = false;
			_.forEach(this.props.selectedEmployeeList, (subItem) => {
				if (subItem.id === item.id) {
					isExist = true;
				}
			})
			if (!isExist) {
				uniqueEmployee.push(item)
			}
		});
		return uniqueEmployee;
	}

	async componentDidUpdate(prevProps) {
		try {
			if (!_.isEqual(this.props.selectedEmployeeList, prevProps.selectedEmployeeList)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
			{
				await this.loadEmployees();
				var uniqueEmployee = this.uniqueEmployee(this.state.employees);
				this.setState({ employees: uniqueEmployee })
			}
		}
		catch { }
	}

	employees() {
		return this.props.data || this.state.employees || [];
	}

	shouldLoadFromApi() {
		return !this.props.data;
	}

	loadEmployees = async (event = null) => {
	
		const search = (event && event.target.value) || '';
		let filter=[]
		const { date } = this.props;
		var generalFilter = [];
		if(date) {
			generalFilter = [{
				field: "active_during",
				value: date,
				method: "is",
				operator:"and"
			}];
		}

		if (event) {
			generalFilter.push({
				field: "search",
				value: search,
				method: "contains",
				operator:"and"
			});
		}

		const newFilter = _.concat(filter, generalFilter);
		this.setState({ search })

		if (this.shouldLoadFromApi()) {
			this.setState({ isLoading: true });
			const response = await Api.employees.get({ filter: newFilter });
			var uniqueEmployee = []
			try {
				uniqueEmployee = await this.uniqueEmployee(response.data);
			}
			catch (e) {
				console.log(e)
				uniqueEmployee = response.data
			}
			this.setState({ employees: uniqueEmployee, isLoading: false });


		} else {
			this.props.onSearch(search);
		}
	};

	onEmployeeClick = (employee) => {
		const { multiSelect } = this.props;
		var selectedEmployees = multiSelect ? this.state.selectedEmployees : [];
		var isFound = false
		_.forEach(selectedEmployees, (selected) => {
			if (selected.id === employee.id) {
				isFound = true;
			}
		})
		if (isFound) {
			var filtered = selectedEmployees.filter(function (selected) { return selected.id !== employee.id; });
			selectedEmployees = filtered;
		}
		else {
			selectedEmployees.push(employee);
		}

		this.setState({ selectedEmployees });
		this.props.onSelect(employee);
	};

	onClickSelectAll = () => {
		if (!this.props.multiSelect) return;
		const selectedEmployees = this.state.selectedEmployees.length === this.employees().length ? [] : this.employees();
		this.setState({ selectedEmployees });
	};

	onClose = () => {
		this.props.onClose();
		this.setState({ selectedEmployees: [] });
	};

	onSubmit = async () => {
		if (this.props.onSubmit) {
			const { multiSelect, selectColumn } = this.props;
			const { selectedEmployees } = this.state;
			const _selectedEmployees = selectColumn ? selectedEmployees.map(emp => emp[selectColumn]) : selectedEmployees;

			this.setState({ submitting: true });
			await this.props.onSubmit(
				multiSelect
					? _selectedEmployees
					: (_selectedEmployees.length ? _selectedEmployees[0] : null)
			);
			this.setState({ submitting: false, selectedEmployees: [] });
		}
	};

	isActive(employee) {
		return !_.isUndefined(_.find(this.state.selectedEmployees, selected => { return selected.id === employee.id }))
	}

	render() {
		const { isOpen, title, loadingText, multiSelect } = this.props;
		const { search, isLoading } = this.state;

		return (
			<Dialog
				open={isOpen}
				onClose={this.onClose}
				fullWidth
				maxWidth="sm"
				onBackdropClick={this.onClose}
				aria-labelledby="employee-title"
				aria-describedby="employee-description"
			>
				<DialogTitle id="employee-title">
					{title}
					<Grid container justify="space-between" spacing={2}>
						<Grid item xs={12} sm={12} md={multiSelect ? 9 : 12}>
							<TextField
								label="Search"
								variant="outlined"
								fullWidth
								margin="dense"
								value={search}
								style={{ marginTop: 0, marginBottom: 0 }}
								onChange={this.loadEmployees}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Icon>search</Icon>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						{
							multiSelect &&
							<Grid item xs={12} sm={12} md={3} style={{ display: "flex", justifyContent: "center" }}>
								<Button size="small" fullWidth onClick={this.onClickSelectAll}>
									{_.size(this.employees()) === _.size(this.state.selectedEmployees) ? "Unselect all" : "Select all"}
								</Button>
							</Grid>
						}
					</Grid>
				</DialogTitle>
				<DialogContent dividers>
					{
						isLoading &&
						<ContentLoader viewBox="0 0 568 354" style={{ marginBottom: -6, width: "100%" }}>
							{
								Array(5).fill().map((_, i) => (
									<Fragment key={i}>
										<rect x="0" y={i * 74} rx="4" ry="4" width="276" height="58" />
										<rect x="292" y={i * 74} rx="4" ry="4" width="276" height="58" />
									</Fragment>
								))
							}
						</ContentLoader>
					}
					{
						!isLoading && !!this.employees().length &&
						<Grid container spacing={2}>
							{
								_.map(this.employees(), (emp) => {
									return (
										<Grid key={emp.id} item xs={12} sm={12} md={6}>
											<EmployeeChip
												key={emp.id}
												employee={emp}
												isActive={this.isActive(emp)}
												onClick={() => this.onEmployeeClick(emp)}
											/>
										</Grid>
									)
								})
							}
						</Grid>

					}
					{
						!isLoading && !this.employees().length &&
						<InfographicMessage illustration="not_found" message="No employees found" />
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose}>Cancel</Button>
					<Button
						variant="contained"
						onClick={this.onSubmit}
						disabled={!(this.state.selectedEmployees.length > 0 && !loadingText)}
						color="primary">
						{
							!!loadingText ? (
								<>
									<CircularProgress color={"primary"} size={20} />
									<span style={{ marginLeft: "15px" }}>{loadingText}</span>
								</>
							) : (
								this.props.buttonText || "Submit"
							)
						}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

EmployeeSelectionModal.propTypes = {
	title: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
	data: PropTypes.array,
	multiSelect: PropTypes.bool,
	selectColumn: PropTypes.string,
	onSelect: PropTypes.func,
	buttonText: PropTypes.string,
	onSearch: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func,
	loadingText: PropTypes.string
};

EmployeeSelectionModal.defaultProps = {
	isOpen: false,
	multiSelect: false,
	onSubmit: () => { },
	onClose: () => { },
	onSearch: () => { },
	onSelect: () => { }
};

export default EmployeeSelectionModal;