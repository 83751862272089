const styles = theme => ({
	
	currency: {
		fontSize: "70%"
	},
	
	amount: {
		
	},
	
	decimal: {
		fontSize: "50%"
	}
	
});

export default styles;