import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Switch
} from '@material-ui/core';
import ReimbursableTableRow from "./ReimbursableTableRow";
import { DLTable } from "@/components";
import styles from "./style";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
class ReimbursableConfirmModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pay_in_next_payroll: true,
            tableData: []
        }
    }

    componentDidMount = () => {
      if(this.props.mode !== "single"){
          this.mergeData();}

    }

    toggleChange = () => {
        this.setState({ pay_in_next_payroll: !this.state.pay_in_next_payroll });
        this.props.onValueChange(!this.state.pay_in_next_payroll);
    }

    mergeData = () => {
        const data = _.cloneDeep(this.props.data)
        let unpiad = data.filter(function(selected) {
            return selected.status !== "paid"; });
            
        const mergeArray = []
        unpiad.forEach(item => {
            if (!mergeArray.filter(mergeArrayItem => mergeArrayItem.employee_id === item.employee_id && mergeArrayItem.pay_item_id === item.pay_item_id).length) {
                mergeArray.push(item)
            }
            else {

                const findThatItem = _.find(mergeArray, { employee_id: item.employee_id, pay_item_id: item.pay_item_id })
                _.remove(mergeArray, { employee_id: item.employee_id, pay_item_id: item.pay_item_id })
                findThatItem.requested_amount += item.requested_amount;
                mergeArray.push(findThatItem)
            }
        });
        this.setState({ tableData: mergeArray })
    }
    render() {
        const {
            isOpen,
            onClose,
            onClickCancel,
            title,
            onClickConfirm,
            message,
            hideNextPayroll,
            confirmText,
            classes,
            data
        } = this.props;
      
        console.log("🚀 ~ file: index.jsx ~ line 71 ~ ReimbursableConfirmModal ~ render ~   this.props.mode ==! ",   this.props.mode !== "single")
        return (
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="sm"
				onBackdropClick={onClose || onClickCancel}
                onClose={onClose || onClickCancel}
                aria-labelledby="confirmation-title"
                aria-describedby="confirmation-description"
            >
                <DialogTitle id="confirmation-title">{title}</DialogTitle>
                {this.props.mode !== "single"?
                <>{
                    <DialogContentText color="textPrimary">
                        <span className={classes.message}>
                            {
                                confirmText === `Approve` ? message
                                    : confirmText === `Delete` ?
                                        "Are you sure you want to delete " + data.length + " reimbursements?"
                                        : "Are you sure you want to reject " + data.length + " reimbursements?"
                            }
                        </span>
                    </DialogContentText>}
                    {confirmText === `Approve` ? <DLTable
                        data={this.state.tableData}
                        RowTemplate={ReimbursableTableRow}
                    /> : null}
                </> :
                <>{
                    <DialogContentText color="textPrimary">
                        <span className={classes.message}>
                        {this.props.message}
                        </span>
                    </DialogContentText>}
                </>}
                <DialogActions>
                    {
                        !hideNextPayroll &&
                        <div className={classes.dialogueTextBox}>
                            <div>
                                <span className={classes.dialogueText}>
                                    Pay in next Payroll {this.state.pay_in_next_payroll}
                                </span>
                            </div>
                            <div>
                                <Switch
                                    checked={this.state.pay_in_next_payroll}
                                    onChange={() => this.toggleChange()}
                                    color="primary"
                                    size={"small"}
                                />
                            </div>
                        </div>
                    }
                    <Button
                        size={"small"}
                        onClick={onClose || onClickCancel}
                        color="default">
                        Cancel
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={() => { onClickConfirm(this.state.pay_in_next_payroll) }}
                        color={confirmText === `Delete` ? "secondary" : "primary"}>
                        {confirmText ? confirmText : "Approve"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ReimbursableConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    confirmText: PropTypes.string,
    isOpen: PropTypes.bool,
    hideNextPayroll: PropTypes.bool,
    onClickCancel: PropTypes.func,
    onClickConfirm: PropTypes.func,
    onValueChange: PropTypes.func,
    data: PropTypes.object
};

export default withStyles(styles)(ReimbursableConfirmModal);