import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Card, CardActions, Icon  } from "@material-ui/core";
import { ArchiveOutlined, Inbox } from "@material-ui/icons";
import {
    BackButton,
    DLTable,
    BulkAction,
    DLTableColumn,
    InfographicMessage,
	PermissionHOC,
    Page,
    Tabs
} from "@/components";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import LeaveTableRow from "./LeavesTableRow";
import { connect } from "react-redux";
import _ from "lodash";
import { Api, HttpErrorHandler } from "@/helpers";
import LeaveModal from "@/components/Modals/LeaveModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";

class Leaves extends Component {
    constructor(props) {
        super(props);
        this.actionBarRef = React.createRef();
        this.tableRef = React.createRef();
      this.bulkAction = React.createRef();

    }
    state = {
        isEditing: false,
        isDeleting: false,
        selectedTab: 0,
        activeTabIndex: 0
    };
    onUpdate = () => {
        this.props.onUpdate();
    };

    forceUpdate = () => {
        this.tableRef && this.tableRef.fetchData()
        this.setState({ key: Math.random() });
    }
    emptyView(archived = false) {
        const message = !archived
            ? "You don't have any Leaves yet, add one now!"
            : "Yaayyy! all Leaves are active and in use";
        return (
            <InfographicMessage
                illustration="leaves"
                message={message}
                actionLabel={archived ? "" : "Add leave"}
                actionIcon={archived ? "" : "add"}
                actionHandler={() => this.setState({ isEditing: "new" })} />
        );
    }

   async restore(selected) {
        const { activeTabIndex} = this.state;
        this.props.setLoader(true);
        var leaveId = [];
        selected.map((item) => {
            if(item.is_deduct_unapproved_leave !== true) {
                leaveId.push(item.id);
            }
        })
        try {
            await Api.leaves.restore_multiple.patch({leaveId });
            this.props.setSnackbar(true, `Selected items restored successfully`);
        } 
        catch (e) {
            HttpErrorHandler.intercept(e)
            .on(422, response => {
            const errors = _.values(_.mapValues(response.data, value => value[0]));
            if (errors && errors.length) {
                this.props.setSnackbar(true, errors[0], "error");
            } else {
                this.props.setSnackbar(true, "Something went wrong, please try again", "error");
            }
            })
            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
            .go();
        }
        this.props.setLoader(false);
        if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.fetchData();
        }
   }

   async delete(selected) {
    const { activeTabIndex} = this.state;
    this.props.setLoader(true);
    var leaveId = [];
    selected.map((item) => {
        if(item.is_deduct_unapproved_leave !== true) {
            leaveId.push(item.id);
        }
    })
    try {
        await Api.leaves.delete_multiple.delete({leaveId});
        this.props.setSnackbar(true, `Selected items archived successfully`);
    } 
    catch (e) {
        HttpErrorHandler.intercept(e)
        .on(422, response => {
        const errors = _.values(_.mapValues(response.data, value => value[0]));
        if (errors && errors.length) {
            this.props.setSnackbar(true, errors[0], "error");
        } else {
            this.props.setSnackbar(true, "Something went wrong, please try again", "error");
        }
        })
        .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
        .go();
    }
    this.props.setLoader(false);
    if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.fetchData();
    }
}

   getItems() {
        const { activeTabIndex} = this.state
        var buttonName = !!activeTabIndex ? "Restore Selected" : "Archive Selected";
        var buttonColors = !!activeTabIndex ? "primary" : "secondary";
        var items = [{
                name: buttonName,
                color: buttonColors,
                action: (_, action) => action.setState({ enableLeaveModal: true }),
                render: (selected, action) => (
                    action.state.enableLeaveModal &&
                    <ConfirmModal
                    isOpen={action.state.enableLeaveModal}
                    title={!!activeTabIndex ? "Restore Leave" : "Archive Leave"}
                    message={`Are you sure to ${!!activeTabIndex ? "restore" : "archive"} ${selected.length} Leave${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel={() => action.setState({ enableLeaveModal: false, activeOption: null })}
                    onClickConfirm={async () => {
                        if (!!activeTabIndex) {
                        await this.restore(selected);}
                        else{  await this.delete(selected);
                        }
                        action.setState({ enableLeaveModal: false, activeOption: null });
                    }}
                    />
                )
            }];
        return items;
   }

    actionBar() {
        return (
                <CardActions>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        onClick={() => this.setState({ isEditing: "new" })} >
                        <Icon>add</Icon>
                        Add Leaves
                    </Button>
                    <BulkAction
                        ref={this.bulkAction}
                        options={this.getItems()}
                     />
                    <DLTableColumn
                        key={`Employee-Column-7`}
                        align={"center"}
                        isCompact={true}
                        fillWidth={35} >
                    </DLTableColumn>
                </CardActions>
        );
    }

    handleChange = () => {
		this.tableRef && this.tableRef.current && this.tableRef.current.fetchData();
    }


    render() {
        const { isEditing } = this.state;
        return (
            <Page title="Leaves">
                <Link to={{ pathname: `/settings` }} style={{ textDecoration: 'none', color: "primary" }}>
                    <BackButton route="/settings" label="Back to Settings" />
                </Link>
                <Card raised>
                    <Tabs
                        onChange={activeTabIndex => this.setState({ activeTabIndex })}
                        beforePanel={this.actionBar()}>
                        <Tabs.Tab icon={<Inbox />} label="Active">
                            <DLTable
                                enableSelection								
                                ref={this.tableRef}
                                api={Api.leaves}
                                RowTemplate={LeaveTableRow}
                                emptyView={this.emptyView()}
                                bulkAction={this.bulkAction}
                            />
                        </Tabs.Tab>
                        <Tabs.Tab icon={<ArchiveOutlined />} label="Archived">
                            <DLTable
                                enableSelection
								ref={this.tableRef}
                                api={Api.leaves}
                                params={{ filter: { archived: true } }}
                                RowTemplate={LeaveTableRow}
                                bulkAction={this.bulkAction}
                                emptyView={this.emptyView(true)} />
                        </Tabs.Tab>
                    </Tabs>
                </Card>
                {isEditing && (
                    <LeaveModal
                        mode={isEditing}
                        title="Add Leave"
                        onUpdate={() => {
                            this.tableRef.current.fetchData();
                        }}
                        onClose={() => this.setState({ isEditing: false })} />
                )}
            </Page>
        );
    }
}
const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(Leaves));
