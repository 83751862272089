import { Component } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "@/contexts";
import { AccessManager } from "@/helpers";
class UserRole extends Component {

	static contextType = AuthContext;

	static propTypes = {
		permissions: PropTypes.object,
		routePermissions: PropTypes.array.isRequired
	};

	render() {
		const { children, routePermissions } = this.props;

		return AccessManager.hasPermission(routePermissions, this.context.user) ? children : null
	}

}

export default UserRole;