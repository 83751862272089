import randomColor from "random-material-color";

const styles = theme => ({

	root: {
		backgroundColor: "#000"
	},

	toolbar: {
		justifyContent: "space-between"
	},

	logoContainer: {
		flex: 0,
		display: "flex",
		alignItems: "center",
		flexWrap: "nowrap"
	},

	menuIcon:{
		color: "white",
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		cursor: "pointer",

		[theme.breakpoints.up('md')]: {
			display: 'none',
		}
	},

	logo: {
		height: 50,
		width: "auto",
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		objectFit: "contain",

		[theme.breakpoints.down("xs")]: {
			height: 30
		}
	},

	title: {
		position: "absolute",
		left: 0,
		right: 0,
		color: "white",
		textAlign: "center",
		
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			flexGrow: 1,
			textAlign: "left",
		},   
	},

	fab: {
		margin: theme.spacing(1),
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		color: "white",
		boxShadow: "none",

		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.4)"
		},

		[theme.breakpoints.down("sm")]: {
			padding: "0 !important"
		}
	},

	avatar: {
		width: 20,
		height: 20,
		fontSize: "0.5rem",
		marginRight: theme.spacing(1),
		border: "1px #fff solid",
		backgroundColor: randomColor.getColor(),

		[theme.breakpoints.down("sm")]: {
			marginRight: 0
		}
	},

	extendedIcon: {
		marginRight: theme.spacing(1),

		[theme.breakpoints.down("sm")]: {
			marginRight: 0
		}
	},

	extendedText: {
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	}

});

export default styles;
