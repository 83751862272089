const styles = theme => ({
	root: { 
		borderRadius: 5,
		marginRight: 20,
		width: 200,
		height: 200,
		position: "relative",

		"&:hover": {
			borderWidth: 20,
		},

		"&:hover $overlay": {
			display: "flex",
		},

		"&.rounded": {
			borderRadius: "50%"
		}
	},

	overlay: {
		display: "none",
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		alignItems: "center",
		justifyContent: "space-around"
	},

	rounded: {
		borderRadius: "50%"
	},

	image: {
		height: 200,
		width: 200,
	},

	uploadFile:{
		display: "none"
	},

	extendedIcon: {
		marginRight: theme.spacing(1),
	},

	extendedText: {
	
	}
});

export default styles