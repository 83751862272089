import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import theme from '@/themeStyles';
class SVGIcons extends React.Component {
    render() {
        if (this.props.icon === "taxable") {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M12.07,16.62A2.29,2.29,0,0,1,9.65,14.4L7,15.08A4.47,4.47,0,0,0,11.15,19v2h1.71V19a4.57,4.57,0,0,0,3.07-1.4,4.3,4.3,0,0,0,1.06-3,3.92,3.92,0,0,0-.51-2,3.74,3.74,0,0,0-1.38-1.36,16,16,0,0,0-2.38-1,7.25,7.25,0,0,1-1.8-.85,1.2,1.2,0,0,1-.42-1,1.47,1.47,0,0,1,.44-1.07,1.55,1.55,0,0,1,1.16-.44q1.44,0,2.13,2l2.35-1a4.12,4.12,0,0,0-3.7-3.23V3H11.15V4.57A3.94,3.94,0,0,0,8.38,6,4.44,4.44,0,0,0,7.5,8.73a4,4,0,0,0,.49,2,3.63,3.63,0,0,0,1.28,1.33,11.87,11.87,0,0,0,2.2,1,8.2,8.2,0,0,1,2,.91A1.27,1.27,0,0,1,13.94,15a1.61,1.61,0,0,1-.45,1.15A1.88,1.88,0,0,1,12.07,16.62Z" /><rect x="16.5" y="19" width="2" height="2" /><rect x="20.5" y="7" width="2" height="2" /><path d="M1.5,5V19a2,2,0,0,0,2,2h4V19h-4V5h4V3h-4A2,2,0,0,0,1.5,5Z" /><path d="M20.5,3V5h2A2,2,0,0,0,20.5,3Z" /><rect x="20.5" y="15" width="2" height="2" /><rect x="16.5" y="3" width="2" height="2" /><rect x="20.5" y="11" width="2" height="2" /><path d="M20.5,21a2,2,0,0,0,2-2h-2Z" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'permanent') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M12.73,16.09H3.42A1.1,1.1,0,0,1,2.32,15V5a1.11,1.11,0,0,1,1.1-1.11H16.76A1.11,1.11,0,0,1,17.86,5V9.86h.8l.27,0H19V5a2.24,2.24,0,0,0-2.23-2.24H3.42A2.24,2.24,0,0,0,1.19,5V15a2.23,2.23,0,0,0,2.23,2.23h8.32v0a1.19,1.19,0,0,1,.4-.64Z" /><path d="M9.64,7.54A2.07,2.07,0,1,0,7.57,9.61,2.08,2.08,0,0,0,9.64,7.54Zm-1.13,0a.95.95,0,1,1-.95-.95A.95.95,0,0,1,8.52,7.54Z" /><path d="M3.65,12.2v1.57h7.84V12.2c0-1.58-2.55-2.24-3.92-2.24C6.52,10,3.65,10.49,3.65,12.2Zm1.13.44V12.2c0-.54,1.64-1.11,2.79-1.11s2.79.58,2.79,1.11v.44Z" /><path d="M20,16a2.27,2.27,0,0,0-.74-1.69A2.12,2.12,0,0,0,18,13.69a1.63,1.63,0,0,0-.37,0A2.32,2.32,0,0,0,15.33,16a1,1,0,0,0,0,.25,2.09,2.09,0,0,0,.5,1.19,2.29,2.29,0,0,0,1.79.86A2.31,2.31,0,0,0,20,16Zm-3.58,0a1.27,1.27,0,0,1,1.27-1.26l.19,0a1.12,1.12,0,0,1,.66.32,1.23,1.23,0,0,1,.41.92,1.26,1.26,0,0,1-1.27,1.26,1.24,1.24,0,0,1-1-.48,1.07,1.07,0,0,1-.27-.63V16Z" /><path d="M22.51,16.79l-.77-.61c0-.08,0-.16,0-.23s0-.16,0-.24l.8-.62a.77.77,0,0,0,.17-1l-1-1.68a.81.81,0,0,0-.93-.31l-.92.37a4.13,4.13,0,0,0-.4-.23l-.14-1a.75.75,0,0,0-.75-.68H16.69a.76.76,0,0,0-.75.65l-.14,1-.39.23-.94-.38a.74.74,0,0,0-.92.34l-1,1.67a.77.77,0,0,0,.2,1l.77.6c0,.08,0,.16,0,.24a.75.75,0,0,0,0,.17s0,0,0,0l-.78.61a.8.8,0,0,0-.19,1l1,1.64a.78.78,0,0,0,1,.34l.92-.37a3.93,3.93,0,0,0,.39.23l.14,1a.78.78,0,0,0,.75.6H18.6a.75.75,0,0,0,.75-.66l.13-1,.4-.23.94.38a.76.76,0,0,0,.92-.34l1-1.67A.76.76,0,0,0,22.51,16.79Zm-5.58,3.41-.18-1.31-.28-.12a3,3,0,0,1-.69-.4l-.24-.18-1.23.5-.71-1.22,1.07-.84-.06-.31a1.15,1.15,0,0,1,0-.2V16a2,2,0,0,1,0-.37l.06-.32-1.07-.83.71-1.22,1.2.48h0l.24-.17a3.73,3.73,0,0,1,.7-.41l.27-.12.19-1.31h1.41L18.54,13l.27.11a3,3,0,0,1,.69.4l.24.18,1.23-.5.7,1.23-1,.8,0,0,0,.33a3,3,0,0,1,0,.37,2.94,2.94,0,0,1,0,.39l0,.3,1,.82L21,18.69l-1.2-.48h0l-.24.17a3.51,3.51,0,0,1-.71.41l-.28.12-.18,1.31Z" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'on_slip') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M17.58,15.56v1.3H16.34l-1.78,1.86h3a1.85,1.85,0,0,0,1.86-1.86V13.62Z" /><path d="M2.7,16.86V11.28H16.44l3-3.14V5.69a1.86,1.86,0,0,0-1.86-1.86H2.7A1.85,1.85,0,0,0,.84,5.69V16.86A1.85,1.85,0,0,0,2.7,18.72H9.2L7.42,16.86Zm0-11.17H17.58V9.42H2.7Z" /><polygon points="22.56 7.83 21.83 7.07 21.3 7.62 17.58 11.43 12.37 16.86 11.88 17.38 11.38 16.86 9.43 14.83 8.7 15.59 8.09 16.22 8.7 16.86 8.71 16.86 10.49 18.72 11.79 20.08 11.88 20.17 13.19 18.8 13.26 18.72 15.05 16.86 17.58 14.22 19.44 12.28 22.56 9.11 23.17 8.47 22.56 7.83" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'fixed_rate') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M7.47,13.3a2.1,2.1,0,0,1,2.17-2.1,2.11,2.11,0,1,1,0,4.21A2.06,2.06,0,0,1,7.47,13.3Zm6.85-2H15.4L9.7,19.87H8.62Zm-3.86,2c0-.64-.3-1-.82-1s-.84.38-.84,1,.32,1,.84,1S10.46,14,10.46,13.29Zm1.75,4.6a2.1,2.1,0,0,1,2.16-2.11,2.07,2.07,0,0,1,2.16,2.08A2.09,2.09,0,0,1,14.37,20,2.07,2.07,0,0,1,12.21,17.89Zm3,0c0-.65-.3-1-.83-1s-.83.37-.83,1,.32,1,.83,1S15.2,18.52,15.2,17.87Z" /><path d="M18,8.5H17v-2a5,5,0,0,0-10,0v2H6a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2v-10A2,2,0,0,0,18,8.5Zm-9-2a3,3,0,0,1,6,0v2H9Zm9,14H6v-10H18Z" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'fixed_quantity') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M18,8.5H17v-2a5,5,0,0,0-10,0v2H6a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2v-10A2,2,0,0,0,18,8.5Zm-9-2a3,3,0,0,1,6,0v2H9Zm9,14H6v-10H18Z" /><polygon points="14.63 18.33 10.68 18.33 10.68 16.3 14.63 16.3 14.63 11.55 9.37 11.55 9.37 12.91 13.32 12.91 13.32 14.94 9.37 14.94 9.37 19.69 14.63 19.69 14.63 18.33" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'arrow_farword') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path fill="#1A8EE6" fill-rule="evenodd" d="M10.004 4.998L5.157 10l-.699-.735L8.09 5.516H0V4.484h8.069a.419.419 0 0 0 .017-.028L4.463.714l.695-.719 4.846 5.003z" />
                </SvgIcon>
            );
        } else if (this.props.icon === 'reimbursment') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <path d="M16.9803 9.04302H1.0197C0.487685 9.04302 0 9.48637 0 10.0627V20.0381C0 20.5701 0.44335 21.0578 1.0197 21.0578H16.9803C17.5123 21.0578 18 20.6144 18 20.0381V10.0627C18 9.48637 17.5123 9.04302 16.9803 9.04302ZM15.9606 17.0233C14.8522 17.0233 13.9655 17.91 13.9655 19.0184H3.99015C3.99015 17.91 3.10345 17.0233 1.99507 17.0233V13.0332C3.10345 13.0332 3.99015 12.1465 3.99015 11.0381H13.9655C13.9655 12.1465 14.8522 13.0332 15.9606 13.0332V17.0233Z" fill="black" />
                    <path d="M9.00004 11.8361C7.27098 11.8361 5.80793 13.2548 5.80793 15.0282C5.80793 16.8016 7.22664 18.2204 9.00004 18.2204C10.7734 18.2204 12.1922 16.8016 12.1922 15.0282C12.1922 13.2992 10.7291 11.8361 9.00004 11.8361ZM9.39906 16.713V17.2006H8.6897V16.713C8.29068 16.6686 7.84733 16.4913 7.53699 16.2253L7.98034 15.5159C8.37935 15.7819 8.73403 15.8706 9.08871 15.8706C9.31039 15.8706 9.44339 15.8263 9.44339 15.6933C9.44339 15.2942 7.71433 15.3386 7.71433 14.1859C7.71433 13.6539 8.11334 13.2992 8.73403 13.1662V12.7672H9.44339V13.1218C9.84241 13.1662 10.1971 13.3878 10.4188 13.6095L9.93108 14.2302C9.62073 14.0085 9.39906 13.9199 9.08871 13.9199C8.86704 13.9199 8.77837 13.9642 8.77837 14.0972C8.77837 14.4519 10.5074 14.4075 10.5074 15.5159C10.4188 16.1809 10.0641 16.58 9.39906 16.713Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96062 1.72775C6.96062 1.94943 7.00496 2.0381 7.1823 2.0381H11.8375C12.6798 2.08243 13.4335 2.34844 14.0986 2.74746C14.5419 3.0578 14.8522 3.41248 15.1183 3.8115C15.5616 4.52086 15.7833 5.98391 15.9163 6.78194C15.9606 7.13662 16.005 7.44697 15.9606 7.62431C15.9163 7.71298 15.872 7.75731 15.8276 7.75731C15.5173 7.89032 15.1626 7.44697 14.9853 7.22529C14.1429 6.20559 12.8572 5.36322 11.3941 5.27455H7.09363C6.96062 5.31889 6.87195 5.40756 6.91629 5.5849V6.78194C6.91629 7.18096 6.69461 7.31396 6.2956 7.18096L2.61579 4.38785L2.26111 4.07751L2.17244 4.03317C1.8621 3.76716 1.9951 3.50115 2.26111 3.27948L2.52712 3.0578L6.07392 0.264699C6.51727 -0.0899804 6.96062 -0.17865 6.96062 0.575044V1.72775Z" fill="black" />
                </SvgIcon>
            );
        }

        else if (this.props.icon === 'default') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <polygon points="18.68 9.56 10.3 17.69 8.95 16.38 8.95 16.38 6.5 13.99 5.04 15.41 8.95 19.21 8.95 22.5 10.98 22.5 10.98 19.88 13.02 17.9 13.02 22.5 15.05 22.5 15.05 15.93 20.14 10.98 18.68 9.56" /><ellipse cx="12" cy="3.6" rx="2.03" ry="2.1" /><polygon points="18.7 6.75 18.66 6.75 2.85 6.75 2.85 8.85 8.95 8.85 8.95 13.55 10.3 14.87 15.05 10.26 15.05 8.85 16.5 8.85 19.42 8.85 21.15 8.85 21.15 6.75 18.7 6.75" />
                </SvgIcon>
            );

        } else if (this.props.icon === 'deduct_unapproved_leave') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66921 3.48542C6.55224 2.70197 3.11155 3.53155 0.672042 5.97147L3.34155 8.64142C5.14242 6.84024 7.38663 5.77689 9.7025 5.51038C9.63517 5.15053 9.59995 4.77937 9.59995 4.39998C9.59995 4.08903 9.62361 3.78361 9.66921 3.48542ZM9.98219 6.51189C8.17366 7.1656 6.31808 8.33957 4.6763 9.97639L10.0246 15.3256C11.6622 13.6878 12.8365 11.8299 13.4898 10.0184C11.8742 9.41132 10.5898 8.12731 9.98219 6.51189ZM16.5142 10.3307C16.2161 10.3763 15.9108 10.4 15.5999 10.4C15.2207 10.4 14.8497 10.3648 14.49 10.2975C14.2238 12.6141 13.1606 14.8591 11.3594 16.6606L14.0289 19.3306C16.4687 16.8903 17.2981 13.4485 16.5142 10.3307ZM7.3458 13.9879L6.01105 12.6529L0 18.6677L1.33195 19.9999L7.3458 13.9879Z" fill="#666666" />
                        <path d="M15.6 8.80005C18.0288 8.80005 20 6.82886 20 4.40007C20 1.97128 18.0288 9.13804e-05 15.6 9.11681e-05C13.1712 9.09557e-05 11.2 1.97128 11.2 4.40007C11.2 6.82886 13.1712 8.80005 15.6 8.80005ZM15.16 2.20008L16.04 2.20008L16.04 3.08008L15.16 3.08008L15.16 2.20008ZM15.16 3.96007L16.04 3.96007L16.04 6.60006L15.16 6.60006L15.16 3.96007Z" fill="#666666" />
                    </svg>
                </SvgIcon>
            );
        } else if (this.props.icon === 'allowed_in_probation') {
            return (
                <SvgIcon {...this.props} style={{ color: theme.palette.statusLabelIcon.main }}>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.9082 1.06712C15.6214 1.06712 11.8246 1.06712 8.52595 1.06712C8.52595 1.75793 8.52595 2.44313 8.52595 3.1508C8.13439 3.1508 7.76655 3.1508 7.38685 3.1508C7.38685 2.10615 7.38685 1.0615 7.38685 0C11.4152 0 15.9537 0 19.9999 0C19.9999 6.66105 19.9999 13.3221 19.9999 20C16.3096 20 12.0975 20 8.41323 20C8.39543 19.9775 8.37763 19.9663 8.37763 19.9495C8.39543 18.7756 8.27677 17.5962 8.46069 16.4224C8.54375 15.9056 8.787 15.4563 9.17263 15.0688C9.59979 14.642 9.97949 14.1758 10.4482 13.6591C10.2702 13.5187 10.0863 13.3895 9.93203 13.2435C8.3895 11.7607 6.84697 10.2724 5.30443 8.78405C4.94253 8.43583 4.94846 8.18309 5.32816 7.85734C6.17062 7.13283 7.01308 6.41393 7.85554 5.70065C8.25304 5.36366 8.7692 5.2738 9.27349 5.18955C10.5372 4.97613 11.6525 5.26818 12.6077 6.09941C13.0646 6.49817 13.5451 6.86886 14.0375 7.26762C13.7884 7.53159 13.551 7.78995 13.3078 8.05392C12.7561 7.61022 12.2102 7.17776 11.6822 6.73968C10.7864 5.98708 9.58199 6.08818 8.67427 6.44201C8.52002 6.50379 8.39543 6.6442 8.24118 6.76215C8.95312 7.40241 9.64132 8.03145 10.3355 8.64925C10.8516 9.1098 11.3796 9.56473 11.8958 10.0253C12.05 10.1657 12.2043 10.3173 12.3348 10.4802C13.0468 11.3788 13.0349 12.2718 12.2874 13.1424C11.5458 14.0017 10.7923 14.8554 10.0388 15.7091C9.73031 16.0573 9.5464 16.4561 9.53453 16.9054C9.51673 17.5849 9.5286 18.2645 9.5286 18.961C12.4772 18.961 15.9477 18.961 18.9141 18.961C18.9082 13.0076 18.9082 7.0542 18.9082 1.06712Z" fill="#666666" />
                        <path d="M5.99117 5.00712C5.99117 6.10319 5.09727 6.99524 4.00367 6.99998C2.89105 7.00473 1.98764 6.09845 1.99714 4.97865C2.0019 3.88732 2.90531 2.99527 3.99891 3.00002C5.11154 3.00476 5.99593 3.89206 5.99117 5.00712Z" fill="#666666" />
                        <path d="M12.2695 15.1249C12.4712 14.8273 12.667 14.5408 12.8687 14.2432C13.284 14.5128 13.6697 14.788 14.0731 15.0351C14.785 15.4675 15.5504 15.6866 16.3988 15.5069C16.82 15.417 17.17 15.198 17.4845 14.9284C17.7455 15.1811 18.0006 15.4226 18.2735 15.681C17.7099 16.2201 17.0217 16.5571 16.2089 16.6245C15.2241 16.7088 14.3164 16.456 13.4976 15.9393C13.0942 15.6753 12.6967 15.4058 12.2695 15.1249Z" fill="#666666" />
                        <path d="M3.96314 9.14917C4.27758 9.30643 4.59796 9.4693 4.92426 9.63218C4.72848 9.95231 4.45557 10.1826 4.15299 10.3679C2.91897 11.1374 1.52476 11.289 0.0771484 11.3059C0.0771484 10.9408 0.0771484 10.5982 0.0771484 10.25C1.4595 10.1826 2.83591 10.1208 3.96314 9.14917Z" fill="#666666" />
                        <path d="M2.55664 17.1467H6.15786" stroke="#666666" stroke-width="1.26689" stroke-miterlimit="10" />
                        <path d="M2.94861 14.3555V19.9438L0 17.1468L2.94861 14.3555Z" fill="#666666" />
                    </svg>
                </SvgIcon>
            );
        }
    }
}

SVGIcons.propTypes = {
    icon: PropTypes.oneOf(["default", "fixed_quantity", "fixed_rate", "on_slip", "permanent", "taxable", "arrow_farword"])
};

export default SVGIcons;