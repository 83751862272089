import React from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton, Chip } from "@material-ui/core";
import {
	DeleteOutlined as DeleteIcon,
	EditOutlined as EditIcon,
	OfflineBolt as OfflineIcon,
	Public as OnlineIcon,
	Group as EmployeeIcon
} from "@material-ui/icons";
import { DLTableRow, DLTableColumn } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import moment from "moment";

class AttendanceTerminalTableRow extends DLTableRow {

	state = {
        anchorEl: null
    };

    constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		}
	}

	deleteTerminal = async () => {
		this.setState({ isModalOpen: false });
		
		const { data } = this.props;
		try {
			await Api.terminals(data.id).delete();
			store.dispatch(setSnackbar(true, `${data.name} deleted successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });
		
		const { data } = this.props;
		try {
			await Api.terminals(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `${data.name} restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data, classes } = this.props;
		return [
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-2`}
				align={"left"}>
				{data.serial}
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-3`}
				align={"left"}>
				{data.ip}
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-4`}
				align={"left"}>
				{data.port}
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-5`}
				align={"left"}>
				<Tooltip title={`Last active @ ${moment.unix(data.last_active_at).format('YYYY-MM-DD hh:mm:ss a')}`}>
					{
						data.is_live
							? <Chip size="small" color="primary" label="ONLINE" icon={<OnlineIcon />} />
							: <Chip size="small" color="secondary" label="OFFLINE" icon={<OfflineIcon />} />
					}
				</Tooltip>
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-6`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				<Tooltip title="Edit ZKTeco Terminal">
					<Link
						className={classes.link}
						to={{
							pathname: `/settings/attendance/terminals/${data.id}`,
							state	: { terminal: data }
						}}>
						<IconButton
							size="small"
							color="primary">
							<EditIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-7`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>

				<Tooltip title="Remove ZKTeco Terminal">
					<IconButton
						onClick={() => this.setState({ isModalOpen: true })}
						size="small"
						color="secondary">
						<DeleteIcon />
					</IconButton>
				</Tooltip>

				<ConfirmModal 
					title="Remove ZKTeco Terminal" 
					isOpen={this.state.isModalOpen} 
					message={`Are you sure you want to remove attendance terminal "${data.name}"?`} 
					onClickCancel={() => {
						this.setState({ isModalOpen: false });
					}}
					onClickConfirm={this.deleteTerminal}/>
			</DLTableColumn>,
			<DLTableColumn
				key={`AttendanceTerminal-${data.id}-Column-8`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>

				<Tooltip title="Link employees with ZKTeco users">
					<Link
						className={classes.link}
						to={{
							pathname: `/settings/attendance/terminals/${data.id}/employees`,
							state	: { terminal: data }
						}}>
						<IconButton size="small">
							<EmployeeIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</DLTableColumn>
		]
	}

}

AttendanceTerminalTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "serial",
		label: "Serial #",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "ip",
		label: "IP",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "port",
		label: "Port",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "is_live",
		label: "Status",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 3
	}
];

export default withStyles(styles, { withTheme: true })(withRouter(AttendanceTerminalTableRow));