import { blue } from "@material-ui/core/colors";

const style = theme => ({
    
    backdrop: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        color: '#FFFFFF',
        zIndex: theme.zIndex.drawer + 1
    },

    closeDrop: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },

    fallbackIcon: {
        color: blue[500]
    },

    imageContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,

        '& > *': {
            maxWidth: '100vw',
            maxHeight: `calc(100vh - ${theme.spacing(12)}px)`
        },

        '& > $fallbackIcon': {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: '#FFFFFF',
            borderRadius: theme.spacing(0.5)
        }
    },

    closeButton: {
        position: "absolute",
        top: theme.spacing(3),
        right: theme.spacing(3),
        height: theme.spacing(4), 
        width: theme.spacing(4), 
        zIndex: 1
    },

    navigation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        padding: theme.spacing(2),
        zIndex: 1
    },

    thumbnail: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        border: `1px ${blue['500']} solid`,
        backgroundColor: '#FFFFFF',
        position: 'relative',
        cursor: 'pointer',

        '&::after': {
            display: 'block',
            content: '""',
            color: 'transparent',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },

        '&.selected::after': {
            display: 'none'
        },

        '& > $fallbackIcon': {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    }

});

export default style;