import _ from 'lodash';
import Config from "@/config";
import Store from "@/redux";

class Utility {

	static validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static validateURL(url) {
		// eslint-disable-next-line
		var re = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		return re.test(String(url).toLowerCase());
	}

	static validateCNIC(cnic) {
		var re = /^\d{5}-\d{7}-\d$/;
		return re.test(String(cnic));
	}

	static validateNTN(ntn) {
		var re = /^\d{7}-\d$/;
		return re.test(String(ntn));
	}

	static getInitials(name) {
		return (name || "").split(" ").map(segment => segment[0]).join("").slice(0, 2).toUpperCase();
	}

	static camelToSnake(str) {
		return str.replace(
			/([A-Z])/g,
			(group) => "_" + group.toLowerCase()
		).replace(/^_/, "");
	}

	static firstValueFromObject(obj = {}) {
		var error = "";
		_.map(obj, (value) => {
			error = value[0]
			return;
		})
		return error;
	}

	static getReimbursementImageUrl = (reimburseable_id,id) => {
		let baseURL = Config.apiEndpoint.replace(/(.+)\/$/, "$1");
		const token = Store.getState().auth.token;
		return `${baseURL}/reimbursable/${reimburseable_id}/image?image=${id}&token=${token.access_token}&version=1.0&platform=web`;
	}

}

export default Utility;