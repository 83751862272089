import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn, UserRole } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import OrganizationBranchModal from "@/components/Modals/OrganizationBranchModal";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Permissions } from "@/config/Permissions";
import { withRouter, Link } from "react-router-dom";

class OrganizationBranchTableRow extends DLTableRow {

	state = {
		isEditing: false,
		isDeleting: false
	};

	delete = async () => {
		this.setState({ isDeleting: false });
		this.props.setLoader(true);
		try {
			const { data, organization } = this.props;
			await Api.organizations(organization.id).branches(data.id).delete();
			this.props.setSnackbar(true, "Branch deleted successfully");
			this.props.reload();

		} catch (err) {
			HttpErrorHandler
				.intercept(err)
				.on(404, () => this.props.setSnackbar(true, "No account is associated with this email", "error"))
				.on(422, () => this.props.setSnackbar(true, "Can't delete own branch", "error"))
				.on(400, response => this.props.setSnackbar(true, response.data.message, "error"))
				.default(() => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { data, organization, isSettingPage } = this.props;
		const { isEditing, isDeleting } = this.state;
		const route = isSettingPage ? "/settings/organization/" : "/organizations/";
		return [
			<DLTableColumn
				key={`Employee-${data.id}-Column-1`}
				withSeparator={true}>
				{data.name}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-4`}
				align="left">
				{data.address}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-2`}
				align="left">
				{data.city}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-3`}
				align="left">
				{data.state}
			</DLTableColumn>,
			<UserRole routePermissions={[ Permissions.ORGANIZATIONS_FULL_ACCESS, Permissions.ORGANIZATION_FULL_ACCESS, Permissions.ORGANIZATION_EDITABLE ]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-5`}
					align={"right"}
					isCompact={true}
					fillWidth={35}>
					<Tooltip title="Edit Branch">
						<Link to={{
							pathname: (route + `${data.organization_id}/branches/${data.id}`), state: {
								organization,
								branch: data
							}
						}} style={{ textDecoration: 'none', color: "primary" }}>
							<IconButton size="small">
								<EditOutlined color="primary" />
							</IconButton>
						</Link>
					</Tooltip>
					{
						isEditing &&
						<OrganizationBranchModal
							title={"Edit Branch"}
							organization={organization}
							isOpen={true}
							branch={data}
							onUpdate={this.props.reload}
							onClose={() => this.setState({ isEditing: false })}
						/>
					}
				</DLTableColumn>
			</UserRole>,
			<UserRole routePermissions={[ Permissions.ORGANIZATIONS_FULL_ACCESS, Permissions.ORGANIZATION_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-6`}
					align={"right"}
					isCompact={true}
					fillWidth={35}>
					<Tooltip title="Delete Branch">
						<IconButton
							onClick={() => this.setState({ isDeleting: true })}
							size="small">
							<DeleteOutline color="secondary" />
						</IconButton>
					</Tooltip>
					{
						isDeleting &&
						<ConfirmModal
							title="Delete Branch"
							isOpen={isDeleting}
							message={`Are you sure want to delete ${data.name} branch?`}
							onClickCancel={() => this.setState({ isDeleting: false })}
							onClickConfirm={this.delete}
						/>
					}
				</DLTableColumn>
			</UserRole>
		]
	}
}

OrganizationBranchTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "address",
		label: "Address",
		align: "left",
		isSortable: false
	},
	{
		key: "City",
		label: "City",
		align: "left",
		isSortable: true,
	},
	{
		key: "state",
		label: "State",
		align: "left",
		isSortable: true
	},
	{
		key: null,
		label: "",
		align: "right",
		isSortable: false,
		isCompact: true,
		colSpan: 2
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(OrganizationBranchTableRow));