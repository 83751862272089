import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { array, bool, func, node, object, oneOf, string } from "prop-types";
import classNames from "classnames";
import { Grid, Typography } from "@material-ui/core";
import { PayslipItemTextField, ReimbursementTextField } from "@/components";
import styles from "./style";

class PayslipPayItems extends Component {
    
    static defaultProps = {
        viewOnly: false,
        multiColumn: false,
        color: 'primary'
    };

    static propTypes = {
        payslip: object.isRequired,
        payItems: array.isRequired,
        heading: string.isRequired,
        headingAddon: node,
        color: oneOf(['primary', 'secondary', 'warning']),
        viewOnly: bool,
        multiColumn: bool,
        onUpdate: func
    };

    render() {
        const { classes, payslip, payItems, heading, headingAddon, color, viewOnly, multiColumn, isReminder, onUpdate} = this.props;
        return (
            <Grid item sm className={classes.column}>
                <Grid container justifyContent="space-between" alignItems="center" className={classNames(classes.header, color)}>
                    <Typography variant="h6" color={color}>
                        {heading}
                    </Typography>
                    {headingAddon}
                </Grid>
                <Grid className={classNames(classes.fieldListContainer, multiColumn && 'multi')} container xs={12}>
                    {
                        payItems.map(payItem => {
                            return (
                                <Grid key={payItem.id} item className={classes.fieldContainer}>
                                    <Typography component="div">
                                        <PayslipItemTextField
                                            payItem={payItem}
                                            payslip={payslip}
                                            viewOnly={viewOnly}
                                            isReminder={isReminder}
                                            onUpdate={onUpdate}
                                        />
                                    </Typography>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PayslipPayItems);