import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
	Checkbox,
	Table,
	TableCell,
	TableBody,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Tooltip,
	Paper,
	TableContainer
} from "@material-ui/core";
import DLTableRow from "./DLTableRow";
import DLTableColumn from "./DLTableColumn";
import DLTablePaginationActions from "./DLTablePaginationActions";
import ContentLoader from "react-content-loader";
import styles from "./style";
import _ from "lodash";

class DLTable extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: props.data || [],
			meta: {
				total: 0,
				per_page: 50,
				current_page: 1
			},
			params: props.params || {},
			order: props.defaultOrder || "asc",
			orderBy: props.defaultOrderBy || "id",
			selected: []
		};
	}

	enableData = (data = this.state.data) => data.filter(({ disabled }) => !disabled);

	fetchData(resetData = false) {
		const { api, onLoad } = this.props;
		const { data, params } = this.state;
		return new Promise((resolve, reject) => {
			if (!api) { return resolve(data); }

			if (resetData) params.page = 1;
			this.setState({
				loading: true,
				data: resetData ? [] : data,
				params
			});

			api.get(params).then(response => {
				this.setState({
					data: response.data,
					meta: response.meta,
					loading: false,
					selected: []
				});
				if (this.props.bulkAction && this.props.bulkAction.current) {
					this.props.bulkAction.current.setSelected([]);
				}
				onLoad(response);
				resolve(response);
			}).catch(error => {
				this.props.setLoader(false)
				this.setState({ loading: false });
				reject(error);
			});
		});
	}

	handleChange = async () => {
		this.props.onChange(await this.fetchData());
	};

	handleSorting(column, columnIndex) {
		return event => {
			if (!column.isSortable) { return; }

			let { order, orderBy, params } = this.state;
			order = (orderBy === column.key && order === "asc") ? "desc" : "asc";
			orderBy = column.key || "";
			params.sort = {
				order,
				column: orderBy
			};
			this.setState({ order, orderBy, params });
			this.fetchData();
		}
	}

	data() {
		if (this.props.api) { return this.state.data; }

		const data = this.state.data.map((el, index) => [el, index]);
		data.sort((a, b) => {
			const order = this.sortComparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return data.map(el => el[0]);
	}

	sortComparator(a, b) {
		const { order, orderBy } = this.state;
		const reA = /[^a-zA-Z]/g;
		const reN = /[^0-9]/g;

		const valueA = this.getValue(a, orderBy).toLowerCase();
		const valueB = this.getValue(b, orderBy).toLowerCase();

		const alphaA = valueA.replace(reA, "");
		const alphaB = valueB.replace(reA, "");
		if (alphaA === alphaB) {
			const numA = parseInt(valueA.replace(reN, ""), 10);
			const numB = parseInt(valueB.replace(reN, ""), 10);
			return numA === numB ? 0 : numA > numB ? (order === "desc" ? 1 : -1) : (order === "desc" ? -1 : 1);

		} else if (alphaA < alphaB) {
			return order === "desc" ? -1 : 1;

		} else if (alphaA > alphaB) {
			return order === "desc" ? 1 : -1;
		}
		return 0;
	}

	getValue(row, key) {
		const { RowTemplate } = this.props;
		const keys = (key || RowTemplate.Id || "id").split(".");
		const value = keys.reduce(function (value, key) {
			return value[key];
		}, row);
		return value ? value.toString() : "";
	}

	handleSelect(row = null) {
		return event => {
			const { selected, data } = this.state;
			const { onSelectionChange, enableSelection, dataCheck } = this.props;
			var updatedSelection = [];
			var dataFilter = [];
			if (!!selected.length && event && event.shiftKey) {
				updatedSelection =
					selected[0].index < row.index + 1 ?
						data.slice(selected[0].index, row.index + 1) :
						data.slice(row.index, selected[0].index + 1);

			} else if (!row && selected.length) {
				updatedSelection = [];

			} else if (!row) {
				if(dataCheck){
				data.map((item) => {
						if(item.status === "pending") {
							dataFilter.push(item)
						}
						else if( item.details && item.details.is_default === false){
							dataFilter.push(item)
						}
					})
				updatedSelection = dataFilter;
				}
				else{
					updatedSelection = data;
				}

			} else if (row && this.isSelected(row)) {
				const { selectBy } = this.props;
				updatedSelection = _.reduce(selected, (carry, selectedRow) => {
					if (selectedRow[selectBy] !== row[selectBy]) {
						carry.push(selectedRow);
					}
					return carry;
				}, []);

			} else if (row) {
				updatedSelection = [...selected, row];
			}
			updatedSelection = updatedSelection.sort((a, b) => a.index - b.index);

			this.setState({ selected: updatedSelection });
			if (this.props.bulkAction && this.props.bulkAction.current) {
				this.props.bulkAction.current.setSelected(updatedSelection);
			}
			if (_.isFunction(onSelectionChange)) {
				onSelectionChange(this.enableData(updatedSelection));
			}
		};
	}

	isSelected(row = null) {
		const { selected, data, enableSelection } = this.state;
		const { selectBy } = this.props;
		if (!row) {
			return selected.length >= data.length;

		} else {
			return !!_.find(selected, selectedRow => {
				return selectedRow[selectBy] === row[selectBy];
			});
		}
	}

	handlePageChange() {
		return (event, page) => {
			let { params } = this.state;
			params.page = page + 1;
			this.setState({ params });
			this.fetchData();
		}
	}

	handleRowsPerPageChange() {
		return (event) => {
			let { params } = this.state;
			params.page = 1;
			params.per_page = event.target.value;
			this.setState({ params });
			this.fetchData();
		}
	}

	lastKey = "";
	performClick = (key, index, increment = 1) => {
		const { selected } = this.state;
		const inputs = document.getElementById("table-body").querySelectorAll("input");
		if (this.lastKey === "") this.lastKey = key;

		if (this.lastKey !== key) {
			if (selected.length === 1) {
				inputs[index].click();
				inputs[index].focus();
				this.lastKey = key;

			} else {
				inputs[index].click();
				inputs[index + increment].focus();
			}

		} else if (inputs[index + increment]) {
			inputs[index + increment].click();
			inputs[index + increment].focus();
			this.lastKey = key;
		}
	}

	onKeyDown = (event) => {
		const { selected } = this.state;
		const inputs = document.getElementById("table-body").querySelectorAll("input");

		if (event.shiftKey && !!selected.length) {
			const getIndex = () => {
				var index = null;
				inputs.forEach((input, i) => {
					if (input === document.activeElement) index = i;
				})
				return index;
			};
			const index = getIndex();
			if (!!selected.length && _.isNull(index)) {
				document.getElementById(`DLTableRowCheckbox-${_.last(selected)[this.props.selectBy]}`).focus();
			}

			switch (event.key) {
				case "ArrowUp":
					this.performClick("ArrowUp", index, -1);
					break;

				case "ArrowDown":
					this.performClick("ArrowDown", index, 1);
					break;

				default:
					break;
			}
		}
	};

	onKeyUp = (event) => {
		const { selected } = this.state;
		const inputs = document.getElementById("table-body").querySelectorAll("input");

		if (!event.shiftKey && !!selected.length) {
			inputs.forEach(input => {
				if (input === document.activeElement) input.blur();
			})
		}
	};

	componentDidMount() {
		window.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
		this.fetchData();
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.onKeyDown);
		window.removeEventListener("keyup", this.onKeyUp);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { forceUpdate, onForceUpdate } = nextProps;
		if (forceUpdate === true && !this.props.forceUpdate) {
			this.fetchData().then(response => {
				_.isFunction(onForceUpdate) && onForceUpdate();
			});
		}
		return true;
	}

	static getDerivedStateFromProps(props, state) {
		// Update API Params
		state.params = { ...state.params, ...props.params };

		// In case of static data, update state from props
		if (!props.api) {
			state.data = props.data;
		}

		// Clear up unavailable rows from selection
		const { selected, data = [] } = state;
		const { selectBy } = props;

		const tableBody = document.getElementById("table-body");
		const inputs = !!tableBody ? tableBody.querySelectorAll("input") : [];

		state.data = data.map((item, index) => ({
			...item,
			...!!inputs[index] && { disabled: !!inputs[index].disabled }
		}));

		state.selected = _.reduce(selected, (carry, selectedRow) => {
			if (_.find(state.data, { [selectBy]: selectedRow[selectBy] })) {
				carry.push(selectedRow);
			}
			return carry;
		}, []);

		return state;
	}

	columns(rowIndex) {
		const { RowTemplate, rowProps } = this.props;
		return _.filter((_.isFunction(RowTemplate.Columns) ? RowTemplate.Columns(rowProps, rowIndex, this.state.data) : (
			RowTemplate.Columns || (
				this.state.data.length ? Object.keys(this.state.data[0]) : []
			)
		)), function(columns) { return columns });
	}

	totalColumns() {
		return this.columns(0).reduce((len, { colSpan }) => (len + (colSpan || 1)), 0);
	}

	headerRows() {
		const { RowTemplate } = this.props;
		return _.isFunction(RowTemplate.Columns) ? (RowTemplate.HeaderRowsCount || 1) : 1;
	}

	render() {
		const { api,  classes, enableSelection, showPagination, emptyView, RowTemplate, hideHeader, stickyHeader, rowProps, checkboxSize, removeColumn = [], alternatingRowColors } = this.props;
		const { loading, data, selected, meta, order, orderBy } = this.state;
		return (
			// <Paper sx={{ width: '100%', mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 750 }} className={classNames(classes.DLTable, stickyHeader && "DLTableStickyHeader", alternatingRowColors && 'DLAlternativeColoredRows' )}>
				{
					!hideHeader &&
					<TableHead>
						{
							_.range(0, this.headerRows()).map(rowIndex => (
								<TableRow
									key={`DLTableRow-Header-${rowIndex}`}
									className={classNames(classes.DLTableHeader, stickyHeader && "DLTableStickyHeader")}>
									{
										enableSelection && !!this.data().length && rowIndex == 0 &&
										<TableCell
											key={"DLTableRow-Header-Column-Select"}
											align={"center"}
											rowSpan={this.headerRows()}
											className={classNames(classes.DLTableColumn, "DLTableColumnCheckbox", "DLTableColumnSeparate")}>

											<Checkbox
												size={checkboxSize}
												indeterminate={selected.length > 0 && selected.length < data.length}
												checked={this.isSelected()}
												onChange={this.handleSelect()} />
										</TableCell>
									}
									{
										this.columns(rowIndex).map((column, columnIndex) => {
											if (columnIndex >= this.columns().length - removeColumn.length + 1) { return; }

											if (removeColumn.includes(column.key)) { return null }
											return (
												<DLTableColumn
													key={`DLTableRow-Header-Column-${rowIndex}-${columnIndex}`}
													indexes={column}
													variant={"head"}
													sortDirection={orderBy === column.key ? order : false}
													{...column}
													// issortable use for remove warning 
													issortable={column.isSortable ? column.isSortable.toString() : null}>
													{
														column.isSortable ? (
															<Tooltip
																title={`Sort by ${column.label || "this column"}`}
																placement={column.align === "right" ? "bottom-end" : "bottom-start"}
																enterDelay={300}>
																<TableSortLabel
																	active={orderBy === column.key}
																	direction={order}
																	onClick={this.handleSorting(column, columnIndex)}
																	style={{ display: "flex" }}>
																	{column.labelComponent || column.label || ""}
																</TableSortLabel>
															</Tooltip>
														) : (
															<span>{_.isString(column) ? column : (column.labelComponent || column.label || "")}</span>
														)
													}
												</DLTableColumn>
											)
										}, this)
									}
								</TableRow>
							))
						}
					</TableHead>
				}
				<TableBody id="table-body">
					{
						this.data().map((row, rowIndex) => (
							<RowTemplate
								rowIndex={rowIndex}
								selected={selected}
								key={`DLTableRow-${rowIndex}`}
								data={row}
								enableSelection={enableSelection}
								isSelected={this.isSelected(row)}
								onSelection={this.handleSelect(row)}
								reload={this.handleChange}
								tableRef={this}
								checkboxSize={checkboxSize}
								{...rowProps} />
						))
					}
					{
						loading && !data.length &&
						<TableRow
							key={"DLTableRow-Loader"}>
							<TableCell
								colSpan={this.totalColumns()}
								align={"center"}
								className={classNames(classes.DLTableColumn, "DLTableColumnCompact")}>
								<ContentLoader viewBox="0 0 380 90" style={{ marginBottom: -6 }}>
									{
										Array(6).fill().map((_, i) => (
											<Fragment key={i}>
												<rect x="5" y={5 + (i * 15)} rx="3" ry="3" width="60" height="5" />
												<rect x="110" y={5 + (i * 15)} rx="3" ry="3" width="15" height="5" />
												<rect x="135" y={5 + (i * 15)} rx="3" ry="3" width="150" height="5" />
												<rect x="295" y={5 + (i * 15)} rx="3" ry="3" width="40" height="5" />
												<circle cx="352.5" cy={7.5 + (i * 15)} r="2.5" />
												<circle cx="362.5" cy={7.5 + (i * 15)} r="2.5" />
												<circle cx="372.5" cy={7.5 + (i * 15)} r="2.5" />
												<rect x="0" y={15 * (i + 1)} rx="0" ry="0" width="380" height="0.5" />
											</Fragment>
										))
									}
								</ContentLoader>
							</TableCell>
						</TableRow>
					}
					{
						!loading && !data.length && emptyView &&
						<TableRow
							key={"DLTableRow-EmptyView"}>
							<TableCell
								colSpan={this.totalColumns()}
								align={"center"}
								className={classNames(classes.DLTableColumn, "DLTableEmptyView")}>
								{emptyView}
							</TableCell>
						</TableRow>
					}
				</TableBody>
				{
					api &&  !!data.length && meta && showPagination &&
					<TableFooter>
						<TableRow key={"DLTableRow-Footer"}>
							<TablePagination
								key={"DLTableRow-Footer-Pagination"}
								rowsPerPageOptions={[15, 25, 50, 100]}
								colSpan={this.totalColumns()}
								count={meta.total}
								rowsPerPage={meta.per_page}
								page={meta.current_page - 1}
								SelectProps={{ native: false }}
								onPageChange={this.handlePageChange()}
								onRowsPerPageChange={this.handleRowsPerPageChange()}
								ActionsComponent={DLTablePaginationActions} />
						</TableRow>
					</TableFooter>
				}
				</Table>
			</TableContainer>
			// </Paper>
		);
	}
}

DLTable.defaultProps = {
	params: {},
	forceUpdate: false,
	enableSelection: false,
	selectBy: "id",
	hideHeader: false,
	stickyHeader: false,
	RowTemplate: DLTableRow,
	rowProps: {},
	showPagination: true,
	onLoad: () => { },
	onChange: () => { },
	setLoader: () => { },
	checkboxSize: "medium",
	alternatingRowColors: false
};

DLTable.propTypes = {
	api: PropTypes.func,
	params: PropTypes.object,
	enableSelection: PropTypes.bool,
	selectBy: PropTypes.string,
	hideHeader: PropTypes.bool,
	stickyHeader: PropTypes.bool,
	RowTemplate: PropTypes.func,
	rowProps: PropTypes.object,
	forceUpdate: PropTypes.bool,
	onForceUpdate: PropTypes.func,
	onSelectionChange: PropTypes.func,
	onLoad: PropTypes.func,
	onChange: PropTypes.func,
	showPagination: PropTypes.bool,
	setLoader: PropTypes.func,
	checkboxSize: PropTypes.string,
	alternatingRowColors: PropTypes.bool
};

export default withStyles(styles)(DLTable);

export {
	DLTableRow,
	DLTableColumn,
	DLTablePaginationActions
};