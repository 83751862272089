import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardActions, Icon, Badge, Grid } from "@material-ui/core";
import { DLTable, InfographicMessage, Page, UserRole, PermissionHOC } from "@/components";
import OrganizationTableRow from "./OrganizationTableRow";
import Filter from "@/components/Filter";
import Api from "@/helpers/Api";
import FilterData from "@/pages/Organization/FilterData";
import _ from "lodash";

class Organization extends Component {
	_table = null;

	constructor (props) {
		super(props);
		this.actionbarRef = React.createRef();
		this.tableRef = React.createRef();
		this._reimbursableSheet = React.createRef();
		this.state = {
			filters: null,
			fields: FilterData
		};
	}

	addOrganization = () => {
		this.props.history.push("/organizations/add");
	};

	onSearch = async (filters) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			if (!!oldFilters && this.tableRef && this.tableRef.current) {
				this.tableRef.current.fetchData();
			}
		});
	};

	emptyView(archived = false) {
		const { hasPermission, permissions } = this.props;
		const ifPermission = hasPermission([permissions.ORGANIZATIONS_FULL_ACCESS]);
		const message = !archived
			? "You don't have any organization yet, add first organization now!"
			: "Yaayyy! all organizations are active";
		return (
			<InfographicMessage
				illustration="organization"
				message={message}
				actionLabel={archived ? "" : ifPermission ? "Add Organization" : ""}
				actionIcon={archived ? "" : ifPermission ? "add" : ""}
				actionHandler={this.addOrganization}
			/>
		);
	}


	render() {
		const { fields, filters } = this.state;

		return (
			<Page title="Organizations">
				<Card raised>
					<Grid container justify={"space-between"}>
						<UserRole routePermissions={[this.props.permissions.ORGANIZATIONS_FULL_ACCESS]}>
							<CardActions>
								<Link to={{ pathname: `/organizations/add` }} style={{ textDecoration: 'none', color: "primary" }}>
									<Button
										variant="outlined"
										size="medium"
										color="primary"
										onClick={this.addOrganization}>
										<Icon>add</Icon>
										Add Organization
									</Button>
								</Link>
							</CardActions>
							<CardActions>
								<Filter
									fields={fields}
									filters={
										filters || [
											{
												field: fields[5].key,
												value: fields[5].options[0].key,
												method: 'is',
												operator: 'and'
											}
										]
									}
									withQueryParams
									onUpdate={this.onSearch}
								/>
							</CardActions>
						</UserRole>
					</Grid>
					{
						!!filters &&
						<DLTable
							ref={this.tableRef}
							api={Api.organizations}
							params={{
								sort: {
									order: "asc",
									column: "name"
								},
								filter: filters
							}}
							RowTemplate={OrganizationTableRow}
							emptyView={this.emptyView()}
						/>
					}
				</Card>
			</Page>
		);
	}
}

export default PermissionHOC(Organization);