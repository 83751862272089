import React, { Component } from "react";
import Login from "@/pages/Login";
import Reset from "@/pages/Reset";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Drawer, Loader, Snackbar, ErrorBoundary, InfographicMessage, Page, PackageAlerts } from "@/components";
import { AuthContext } from "@/contexts";
import RoutesPermissions from "./RoutesPermissions";
import { Api, HttpErrorHandler, Utility, AccessManager } from "@/helpers";
import { setSnackbar, setActiveEmployees } from "@/redux/actions/general";
import { setToken, resetToken } from "@/redux/actions/auth";
import Store from "@/redux";
import _ from "lodash";
import Expire from "@/pages/Expire";
import { Permissions } from "@/config/Permissions";

class Routes extends Component {

	static contextType = AuthContext;

	constructor (props) {

		super(props);

		// Setup Authentication Process
		this.state = {
			isLoading: true,
			modules: [],
			auth: {
				user: null,

				organization: null,
				
				updateUser: this.setUser,

				updateOrganization: this.setOrganization,

				isSuperAdmin: this.isSuperAdmin,

				isAuthenticated: this.isAuthenticated,

				authenticate: this.authenticate,

				hasModule: this.hasModule,

				logout: this.logout
			}
		};
	}

	isAuthenticated = () => {
		return !!(this.state.auth.user && this.state.auth.user.id);
	};

	setUser = user => {
		this.setState({
			auth: {
				...this.state.auth,
				user: {
					...user,
					roles: (user && user.roles) ? user.roles[0] : {},
					permission_names: (user && user.roles) ? _.map(user.roles[0].permissions, (permission) => {
						return permission.name
					}) : []
				}
			}
		});
	};

	setOrganization = organization => {
		
		Store.dispatch(setActiveEmployees((organization ? organization.active_employees : 1), (organization ? organization.seats_allowed : 0), (organization ? organization.is_grace_period : false), (organization ? organization.grace_end_date : 0)));

		var modules = this.state.modules;
		if (organization && !organization.is_purchase_expired && organization.current_purchase) {
			modules = modules.concat(organization.current_purchase.modules);
		}

		this.setState({
			auth: {
				...this.state.auth,
				organization
			},
			modules
		});
	};

	isSuperAdmin = () => {
		return true;
	};

	authenticate = async (email, password) => {
		if (_.isEmpty(email) || _.isEmpty(password)) {
			Store.dispatch(setSnackbar(true, "Both mobile & password fields are required", "error"));
			return;

		// } else if (!Utility.validateEmail(email)) {
		// 	Store.dispatch(setSnackbar(true, "Invalid email address", "error"));
		// 	return;
		}

		try {
			const data = await Api.login.post({ email, password });
			Store.dispatch(setToken(data.token));
			this.setUser(data.user);
			this.setOrganization(data.user.organization);

		} catch (err) {
			this.setUser(null);
			this.setOrganization(null);
			HttpErrorHandler
				.intercept(err)
				.on(400, response => Store.dispatch(setSnackbar(true, response.data.message, "error")))
				.default(() => Store.dispatch(setSnackbar(true, "Something went wrong, please try again", "error")))
				.go();
		}
	};

	hasModule = (...modules) => {
		return _.every(modules, module => AccessManager.isModuleAccess(module, this.state.auth.organization))
	};

	logout = async (cb) => {
		await Api.logout.get();
		this.setUser(null);
		this.setOrganization(null);
		Store.dispatch(resetToken());
		if (cb) {
			cb();
		}
	};

	async componentDidMount() {
		if (!!Store.getState().auth && !!Store.getState().auth.token.access_token) {
			try {
				// TODO: Should be GET /accounts/me (i.e. Api.accounts.me.get())
				// But for this, need to alter the server
				const user = await Api.me.post();
				this.setUser(user);
				this.setOrganization(user.organization);
			} catch (e) {
				Store.dispatch(resetToken());
				this.setUser(null);
				this.setOrganization(null);
			}
		}
		this.setState({ isLoading: false })
	}

	render() {
		const { isLoading, auth } = this.state;

		/* TODO: Add some styling for loading component */
		if (isLoading) {
			return (<div>Please wait...</div>);
		}

		return (
			<AuthContext.Provider value={auth}>
				<Router>
					<ErrorBoundary>
						<Loader />
						<Snackbar />
						{
							auth.isAuthenticated() ? (
								<Drawer>
									<PackageAlerts />
									<Switch>
										{
											_.map(RoutesPermissions, (route, index) => {

												if (AccessManager.isPurchaseExpired(auth.organization) || auth.organization.current_purchase == null) {
													return <Route component={Expire} />;
												}

												if (_.size(_.intersection(route.permissions, auth.user.permission_names)) < 1) {
													if (route.path === "/" && !auth.user.permission_names.includes(Permissions.COMPANY_DASHBOARD)) {
														if (route.path === "/" && !auth.user.permission_names.includes(Permissions.PROFILE_EDITABLE)) {
															return <Route key={index} exact={true} path={"/"}>
																<Redirect to={"/settings/"} />
															</Route>
														}
														return <Route key={index} exact={true} path={"/"}>
															<Redirect to={"/employees/" + auth.user.id + "/"} />
														</Route>
													}
													return null;
												}

												// If module not allowed then return to view "Page Not Found" screen.
												if (!auth.organization.is_super_organization && route.modules && _.size(_.intersection(route.modules, auth.organization.current_purchase.modules)) < 1) {
													return null;
												}

												return <Route
													key={index}
													exact={route.exact}
													path={route.path}
													component={route.component} />
											})
										}
										<Route>
											<Page title="Page Not Found">
												<InfographicMessage
													illustration="not_found"
													message="The page you are looking for was not found"
													actionLabel="Go to Dashboard"
													actionHandler={() => {
														window.location.href = '/';
													}}
													containerClass="center"
												/>
											</Page>
										</Route>
									</Switch>
								</Drawer>
							) : (
								<Switch>
									<Route path="/reset/:code" component={Reset} />
									<Route component={Login} />
								</Switch>
							)
						}
					</ErrorBoundary>
				</Router>
			</AuthContext.Provider>
		);
	}
}

export default Routes;