const style = theme => ({
	
	switch: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: 0
	},
	hidden: {
		display: "none"
	}
});

export default style;