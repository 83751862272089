import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setLoader } from "@/redux/actions/general";
import styles from "./style";

class Loader extends React.Component {
  
	close() {
		this.props.setLoader(false);
	}

	render() {
		const {classes, isVisible, primaryColor } = this.props;
		if (isVisible) {
			return (
				<div className={classes.backdrop}>
					<CircularProgress
						color={primaryColor}
						className={classes.progress}
						disableShrink
					/>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => ({
	isVisible: state.general.loader.visible,
	primaryColor: state.general.loader.primaryColor
});

const mapDispatchToProps = dispatch => ({
	setLoader: status => dispatch(setLoader(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Loader));