import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card } from "@material-ui/core";
import {
	EmployeeInfo,
	EmployeeBankAccounts,
	EmployeePayItems,
	EmployeeAccount,
	EmployeePosition,
	EmployeeTaxAdjustments,
	EmployeeOtherIncome,
	BackButton,
	Page,
	Tabs,
	EmployeeLeave,
	EmployeeShifts,
	UserRole,
	EmployeeDocument
} from "@/components";
import ContentLoader from "react-content-loader";
import { Api } from "@/helpers";
import { setSnackbar } from "@/redux/actions/general";
import { AccessManager } from "@/helpers/";
import styles from "./style";
import { AuthContext } from "@/contexts";
import Modules from "@/config/Modules";
import { Permissions } from "@/config/Permissions";
import {
	Link,
} from "react-router-dom";

const mainModules = [
	Modules.BRANCH,
	Modules.PAYROLL,
	Modules.ATTENDANCE,
	Modules.EMPLOYEE_HISTORY,
	Modules.ROLE_AND_PERMISSION
];

class EmployeeEdit extends React.Component {

	static contextType = AuthContext;

	state = {
		employee: {}
	};
	async componentDidMount() {
		if (this.context.user.id || this.props.match.params.id) {
			try {
				const employee = await Api.employees(this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : this.context.user.id).get();
				this.setEmployee(employee);
			} catch (e) {
				this.props.history.replace("/employees");
				this.props.setSnackbar(true, "Unable to get employee details, please try again", "error");
			}
		}
	}

	setEmployee = employee => {
		return new Promise(resolve => {
			this.setState({ employee }, resolve);
		});
	};

	render() {
		const { employee } = this.state;
		return (
			<Page title="Employees">
				<UserRole routePermissions={[
					Permissions.EMPLOYEES_VIEWABLE,
					Permissions.EMPLOYEES_EDITABLE,
					Permissions.EMPLOYEES_FULL_ACCESS]}>
					<Link to={{ pathname: `/employees` }} style={{ textDecoration: 'none', color: "primary" }}>
						<BackButton route="/employees" label="Back to Employees" />
					</Link>
				</UserRole>
				<Card raised>
					{
						!!employee && employee.id &&
						<Tabs
							variant="routed"
							tabsProps={{
								variant: "scrollable"
							}}>
							<Tabs.Tab
								hidden={mainModules}
								path={"/info"}
								label="Employee Information">
								<EmployeeInfo
									employee={employee}
									onUpdate={this.setEmployee}
								/>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.DOCUMENT]}
								path={(AccessManager.isModuleAccess(Modules.DOCUMENT, this.context.organization)) ? "/documents" : null}
								label="Documents">
								<EmployeeDocument
									employee={employee}
									onUpdate={this.setEmployee}
								/>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.PAYROLL]}
								path={(AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization)) ? "/salary-details" : null}
								label="Salary Details">
								<EmployeePayItems employee={employee}
									onUpdate={this.setEmployee} />
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.EMPLOYEE_HISTORY]}
								path={(AccessManager.isModuleAccess(Modules.EMPLOYEE_HISTORY, this.context.organization)) ? "/employment-history" : null}
								label="Employment History">
								<EmployeePosition
									employee={employee}
									onUpdate={this.setEmployee} />
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.ATTENDANCE]}
								path="/leaves"
								label="Leaves">
								<EmployeeLeave employee={employee} />
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.ATTENDANCE]}
								path="/shifts"
								label="Shifts">
								<EmployeeShifts employee={employee} />
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.PAYROLL]}
								path={(AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization)) ? "/bank-accounts" : null}
								label="Bank Accounts">
								<EmployeeBankAccounts employee={employee} />
							</Tabs.Tab>
							<Tabs.Tab
								hidden={mainModules}
								path={"/account"}
								label="Login Access">
								<EmployeeAccount
									employee={employee}
									onUpdate={this.setEmployee}
								/>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.TAX]}
								path={(AccessManager.isModuleAccess(Modules.TAX, this.context.organization)) ? "/tax-adjustments" : null}
								label="Tax Adjustments">
								<EmployeeTaxAdjustments
									employee={employee}
									onUpdate={this.setEmployee}
								/>
							</Tabs.Tab>
							<Tabs.Tab
								hidden={[Modules.TAX]}
								path={(AccessManager.isModuleAccess(Modules.TAX, this.context.organization)) ? "/other-income" : null}
								label="Other Income">
								<EmployeeOtherIncome
									employee={employee}
									onUpdate={this.setEmployee}
								/>
							</Tabs.Tab>
						</Tabs>
					}
					{
						(!employee || !employee.id) &&
						<ContentLoader viewBox="0 0 380 337" style={{ marginBottom: -6, width: "100%" }}>
							{
								Array(7).fill().map((_, i) => (
									<rect key={i} x={5 + (40 * i)} y="5" rx="3" ry="3" width="30" height="5" />
								))
							}
							<rect x="0" y="15" rx="0" ry="0" width="380" height="0.2" />
							<rect x="0" y="14" rx="0" ry="0" width="40" height="1" />
							<circle cx="345" cy="50" r="30" />
							{
								Array(10).fill().map((_, i) => (
									<Fragment key={i}>
										<rect x="5" y={20 + (i * 20)} rx="2" ry="2" width={i === 2 ? 305 : 150} height="15" />
										{
											i !== 0 &&
											<rect x="160" y={20 + (i * 20)} rx="2" ry="2" width="150" height="15" />
										}
									</Fragment>
								))
							}
							<rect x="5" y="225" rx="3" ry="3" width="30" height="5" />
							<rect x="160" y="225" rx="3" ry="3" width="30" height="5" />
							{
								Array(4).fill().map((_, i) => (
									<Fragment key={i}>
										<rect x="5" y={235 + (i * 20)} rx="2" ry="2" width="150" height="15" />
										<rect x="160" y={235 + (i * 20)} rx="2" ry="2" width="150" height="15" />
									</Fragment>
								))
							}
							<rect x="5" y="320" rx="1" ry="1" width="40" height="12" />
							<rect x="50" y="320" rx="1" ry="1" width="40" height="12" />
						</ContentLoader>
					}
				</Card>
			</Page>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(EmployeeEdit)));