import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import _ from "lodash";

class EmployeeLeavesDropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            employee: "",
            leaves: null
        }
    }
    static defaultProps = {
        label: "Leaves",
        filter: {},
        onChange: () => {}
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func,
        filter: PropTypes.object,
        employee : PropTypes.number
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.employee.id !== this.props.employee.id) {
            this.setState({ employee: this.props.employee});
            this.loadEmployeeLeaves();
        }

        if ((prevProps.employee !== this.props.employee || this.props.value !== prevProps.value) && (!this.state.leaves || (this.state.leaves.id !== this.props.value))) {
           this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value && this.props.employee) {
            try {
                const response = await Api.employees(this.props.employee).employee_leaves.get();
                var leaves = _.find(response.data, (item) => item.details.id === this.props.value);
                return this.setState({ leaves : leaves });
            } catch(e) {}
        }
        this.setState({ leaves: null });
    }
    
    loadEmployeeLeaves = async () => {
        this.setState({ loading: true});
        
        let response = await Api.employees(this.props.employee).employee_leaves.get({filter: this.props.filter});
        response.data = _.filter(response.data, (item) => item.details.id);
        return response.data;
    };

    onChange = (event, leaves) => {
        this.setState({ leaves });
        this.props.onChange((leaves && leaves.id) ? leaves : null);
    };

    renderOption = option => {
        return (
            <div className={this.props.classes.itemStyle}>
                {option.details.name}
            </div>
        )
    };
    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        const { leaves } = this.state; 
        return (
            <>
                <AsyncDropdown
                    classes={{ root: classes.dropdown, paper: classes.dropdown }}
                    label={leaves ? "" : label}
                    textFieldProps={textFieldProps}
                    getOptions={this.loadEmployeeLeaves}
                    getOptionLabel={option => option.details.name}
                    getOptionSelected={(option, value) => {
                        if(value && value.details) { 
                            return option.details.leave_id === value.details.leave_id;
                        }
                    }}
                    renderOption={this.renderOption}
                    onChange={this.onChange}
                    value={leaves}
                    disabled={!!disabled}
                />
            </>
        );
    }

}

export default withStyles(styles)(EmployeeLeavesDropdown);