import React, { Component } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { ImagePicker, UserRole, LocationMap } from "@/components";
import { FormGroup, FormControl, TextField, CardContent, CardActions, Button, Grid, InputLabel } from "@material-ui/core";
import { Api, HttpErrorHandler, Utility, AccessManager } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import ContentLoader from "react-content-loader";
import _, { isEmpty } from "lodash";
import styles from "./style";
import { Permissions } from "@/config/Permissions";
import Modules from "@/config/Modules";
import CountryDropdown from '@/components/Dropdowns/CountryDropdown.js';
import StatesDropdown from '@/components/Dropdowns/StatesDropdown';
import { AuthContext } from "@/contexts";
import moment from "moment";

class OrganizationInfo extends Component {

	static contextType = AuthContext;

	constructor(props) {
		super(props);

		this.state = {
			errors: {},
			organization: {},
			branch: {
				id: props.organization.branches[0].id,
				zoom: props.organization.branches[0].zoom,
				latitude: props.organization.branches[0].latitude,
				longitude: props.organization.branches[0].longitude,
			},
		};
	}

	organization() {
		return {
			...this.props.organization,
			...this.state.organization
		};
	}

	branch() {
		try {
			var branch =  this.props.organization.branches[0];
			return {
				id: "",
				zoom: 16,
				latitude: null,
				longitude: null,
				...branch,
				...this.state.branch
			};
		} catch {
			return {
				id: this.state.branch.id,
				zoom: this.state.branch.zoom,
				latitude: this.state.branch.latitude,
				longitude: this.state.branch.longitude,
			};
		}
	}

	handleChange(value, field) {
		const { errors, organization } = this.state;

		if (errors[field]) {
			errors[field] = false;
		}

		if (field === "registration") {
			value = value.split("-").join("");
			if (value.length > 0 && !_.toNumber(value.slice(-1)) && value.slice(-1) !== '0') {
				value = organization[field];
			} else if (value.length === 8) {
				value = value.substring(0, 7) + "-" + value.substring(7)
			} else if (value.length === 13) {
				value = value.substring(0, 5) + "-" + value.substring(5, 12) + "-" + value.substring(12);
			} else if (value.length > 8 && value.length < 13) {
				value = value.substring(0, 5) + "-" + value.substring(5, 12);
			} else if (value.length > 13) {
				value = organization[field];
			}
		}

		this.setState({
			organization: {
				...organization,
				[field]: value
			},
			errors
		});
	}

	logo = null;
	imageChangeHandler = (e) => {
		this.logo = e ? e.target.files[0] : null;
	};

	validate() {
		const organization = this.organization();
		const requiredFields = ["name", "address", "city", "country", "state"];
		var errors = {};

		_.forOwn(organization, (value, key) => {
			if (requiredFields.indexOf(key) !== -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;

			} else if (key === "registration" && value && !Utility.validateNTN(value) && !Utility.validateCNIC(value)) {
				errors[key] = "Invalid registration format, should either be NTN (i.e, 1234567-1) or CNIC of proprietor (i.e, 42101-1234567-1)";
			}
		});
		console.log(errors)
		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		const organization = this.organization();
		const branch = this.branch();

		const data = new FormData();
		_.forOwn(organization, (value, key) => {
			// Ignored keys
			if (["is_archived", "deleted_at", "created_at", "updated_at"].indexOf(key) !== -1) return;

			// Send null values as empty string
			if (_.isNil(value)) {
				value = "";
			}

			data.append(key, value);

		});

		if(!AccessManager.isModuleAccess(Modules.BRANCH, this.context.organization)){
			data.append("branch_id", branch.id)
			data.append("latitude", branch.latitude)
			data.append("longitude", branch.longitude)
			data.append("zoom", branch.zoom)
		}

		// Append display picture (if changed)
		if (this.logo) {
			data.append("logo", this.logo);
		}

		this.props.setLoader(true);
		try {
			data.append("_method", "put");
			const updatedOrganization = await Api.organizations(organization.id).post(data);
			this.props.onUpdate(updatedOrganization);
			this.props.setSnackbar(true, "Organization information saved successfully");

		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	onPlacesChanged = (ab, branch) => {
		this.setState({
			branch: {
				...branch,
				latitude: ab[0].geometry.location.lat(),
				longitude: ab[0].geometry.location.lng(),
			}
		});
	}

	onBoundsChange = (center, zoom, branch) => {
		this.setState({
			branch: {
				...branch,
				latitude: center.lat,
				longitude: center.lng,
				zoom
			}
		});
	}

	render() {
		const { errors } = this.state;
		const { classes } = this.props;
		const organization = this.organization();
		const branch = this.branch();
		return (
			<>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} justify="center" style={{ display: "flex" }}>
							{
								isEmpty(organization) ? (
									<ContentLoader
										style={{ width: 200, height: 200 }}
										children={<rect radius={4} style={{ width: "100%", height: "100%" }} />}
									/>
								) : (
									<FormGroup
										className={classes.formField}>
										<FormControl>
											<ImagePicker
												title="Organization Logo"
												mode="contain"
												src={Api.organizations(organization.id).logo.getUrl()}
												onSelect={this.imageChangeHandler}
											/>
										</FormControl>
									</FormGroup>
								)
							}
						</Grid>
						<Grid item xs={12} >
							<Grid container spacing={2}>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										Organization Name
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										placeholder={"Organization Name"}
										variant="outlined"
										error={!!errors.name}
										helperText={errors.name}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.name || ""}
										onChange={e => this.handleChange(e.target.value, "name")}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										Registration # (NTN / CNIC)
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										placeholder={"Registration # (NTN / CNIC)"}
										variant="outlined"
										error={!!errors.registration}
										helperText={errors.registration}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.registration || ""}
										onChange={e => this.handleChange(e.target.value, "registration")}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										Address
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										placeholder={"Address"}
										variant="outlined"
										error={!!errors.address}
										helperText={errors.address}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.address || ""}
										onChange={e => this.handleChange(e.target.value, "address")}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										City
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										placeholder={"City"}
										variant="outlined"
										error={!!errors.city}
										helperText={errors.city}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.city || ""}
										onChange={e => this.handleChange(e.target.value, "city")}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										Country
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									<CountryDropdown
										placeholder={"Country"}
										variant="outlined"
										optionLabel={organization.country}
										FormHelperTextProps={{ variant: "standard" }}
										value={organization.country || ""}
										onChange={country_perment => this.handleChange(country_perment, "country")}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>
										States
									</InputLabel>
								</Grid>
								<Grid item xs={5}>
									{
										organization.country === "Pakistan" ? (
											<StatesDropdown
												placeholder={"State"}
												variant="outlined"
												error={!!errors.country}
												helperText={errors.country}
												optionLabel={organization.state}
												FormHelperTextProps={{ variant: "standard" }}
												value={organization.state || ""}
												onChange={state_perment => this.handleChange(state_perment, "state")}
											/>
										) : (
											<TextField
												fullWidth
												placeholder={"State"}
												variant="outlined"
												error={!!errors.state}
												helperText={errors.state}
												FormHelperTextProps={{ variant: "standard" }}
												value={organization.state || ""}
												onChange={e => this.handleChange(e.target.value, "orgaization_state")}
											/>
										)
									}
								</Grid>
								{
									!AccessManager.isModuleAccess(Modules.BRANCH, this.context.organization) && 
									<>
										<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
											<InputLabel>
												Location
											</InputLabel>
										</Grid>
										<Grid item xs={5}>
											<LocationMap
												isNew={false}
												branch={branch}
												onPlacesChanged={(ab) => this.onPlacesChanged(ab, branch)}
												onBoundsChange={(center, zoom) => this.onBoundsChange(center, zoom, branch)}
											/>
										</Grid>
									</>
								}
							</Grid>
						</Grid>	
					</Grid>
				</CardContent>
				{
					!isEmpty(organization) &&
					<UserRole routePermissions={[Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS, Permissions.ORGANIZATIONS_FULL_ACCESS]}>
						<CardActions className={classes.cardActionsRight}>
							<Button
								variant="contained"
								size="small"
								color="primary"
								onClick={this.save}>
								Save
							</Button>
							<Button
								size="small"
								onClick={() => this.setState({ organization: {}, errors: {} })}>
								Reset
							</Button>
						</CardActions>
					</UserRole>
				}
			</>
		);
	}

}

OrganizationInfo.defaultProps = {
	onUpdate: () => { }
};

OrganizationInfo.propTypes = {
	organization: Proptypes.object.isRequired,
	onUpdate: Proptypes.func
};

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(OrganizationInfo));