import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Avatar,
    ListItemText,
    Icon,
    IconButton,
    Grid,
    Chip,
    Typography
} from "@material-ui/core";
import { DeleteOutline, } from "@material-ui/icons";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import CloseIcon from '@material-ui/icons/Close';
import { Api } from "@/helpers";
import styles from "./style";
import ShiftsPopup from "../../components/Modals/ShiftsPopup";
import moment from "moment";
import { setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import { Popover, ArrowContainer } from 'react-tiny-popover'

const dataColor = [
    { avatarColor: "#53966A", backgrounColor: "#E4FBEC" },
    { avatarColor: "#35A7E8", backgrounColor: "#E4F7FB" },
    { avatarColor: "#9B58B5", backgrounColor: "#F2E4FB" },
    { avatarColor: "#EA8E39", backgrounColor: "#FBF2E4" },
    { avatarColor: "#D1228D", backgrounColor: "#FBE4F5" }
]

class EmployeeList extends Component {
    constructor(props) {
        super(props);
        this._shiftPopup = React.createRef();
    }
    static defaultProps = {
        employeeList: [],
    };

    state = {
        anchorEl: null,
        shiftOpen: false,
        addShiftModal: false,
        isEmployeeDeleteModal: null,
        holidayIndex: null,
        isShown: false
    }


    deleteDayShift = async (roster_assignment) => {
        try {
            this.props.setLoader(true);
            await Api.rosters(roster_assignment.roster_id).roster_assignments(roster_assignment.id).delete();
            await this.props.getRosterAssignments();
            this.setState({ anchorEl: null })
            this.props.setLoader(false);
        }
        catch (e) {
            console.log(e)
            this.props.setLoader(false);
        }
    }

    renderItem = (array) => {
        if (!array) { return null }
        const { classes } = this.props;
        var arr = [];
        for (let index = 0; index < array.length; index++) {
            arr.push(<div className={classes.shiftContainerArrayDiv}>
                <div className={classes.shiftContainerTextType}>{`${String.fromCharCode(index + 1 + 64)}:`}</div><div className={classes.shiftContainerTime}>{`${moment(array[index].start_time, "hh:mm:ss").format("hh:mma")}-${moment(array[index].end_time, "hh:mm:ss").format("hh:mma")}`}</div></div>)
        }
        return arr;
    }

    shiftContainer = (roster_assignments, weekDayIndex, backgroundIndex) => {
        const { classes } = this.props;
        if (!roster_assignments.shifts.length) {
            return (<>
                <Grid justifyContent={"space-around"} item
                    className={classes.gridPlusContainer}
                    key={`${backgroundIndex}${weekDayIndex}-3`}
                    style={{
                        borderRight: `${weekDayIndex < 6 ? "1px" : "0px"} solid #DBEAFF`,
                    }}>
                    <Popover
                        key={`${backgroundIndex}${weekDayIndex}-1`}
                        isOpen={Boolean(this.state.shiftOpen) && this.state.index === `${backgroundIndex}${weekDayIndex}`}
                        positions={['right', 'top', 'bottom', 'left']}
                        className={classes.popoverStyle}
                        onClickOutside={() => {
                            if (this.state.addShiftModal) return;
                            this.setState({ shiftOpen: false, index: null }, () => { this.props.setLoader(false) })
                        }}
                        content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                                position={position}
                                childRect={childRect}
                                popoverRect={popoverRect}
                                arrowColor={'white'}
                                arrowSize={8}
                                arrowStyle={{ opacity: 1 }}
                                className='popover-arrow-container'
                                arrowClassName='popover-arrow'
                            >
                                    <ShiftsPopup
                                        ref={this._shiftPopup}
                                        isOpen={Boolean(this.state.shiftOpen) && this.state.index === `${backgroundIndex}${weekDayIndex}`}
                                        title={`Shifts`}
                                        start_date={moment(this.props.weekDate).startOf("week").add(1, "day").format("YYYY-MM-DD")}
                                        end_date={moment(this.props.weekDate).startOf("week").add(7, "day").format("YYYY-MM-DD")}
                                        selectedDate={moment(this.props.weekDate).startOf("week").add(weekDayIndex, "day").format("YYYY-MM-DD")}
                                        employeeData={roster_assignments}
                                        onClose={() => { this.setState({ shiftOpen: false, index: null }, () => { this.props.setLoader(false) }) }}
                                        getRosterAssignments={this.props.getRosterAssignments}
                                        addShiftModal={this.state.addShiftModal}
                                        setShiftModal={(value) => { this.setState({ addShiftModal: value }) }}
                                        shiftData={this.props.shiftData}
                                        fetchShift={() => this.props.fetchShift()}
                                    />
                            </ArrowContainer>
                        )
                        }
                    >
                        <IconButton onMouseEnter={() => this.setState({ isShown: true, holidayIndex: `${backgroundIndex}${weekDayIndex}` })} onMouseLeave={() => this.setState({ isShown: false })} onClick={() => {
                            this.setState({ shiftOpen: true, index: `${backgroundIndex}${weekDayIndex}` })
                        }} className={classes.plusIconButtonStyle}>
                            {(this.state.isShown === true && this.state.holidayIndex === `${backgroundIndex}${weekDayIndex}`) ||
                                (Boolean(this.state.shiftOpen) && this.state.index === `${backgroundIndex}${weekDayIndex}`) ?
                                <Icon style={{ color: "#2A9DFF" }} key={`${backgroundIndex}${weekDayIndex}-1`}>add</Icon>
                                :
                                <Chip label="HOLIDAY" style={{ backgroundColor: "#FFA337", color: "white" }} />
                            }
                        </IconButton>
                    </Popover>
                   
                </Grid>
            </>)
        }
        return (<Grid justifyContent={"space-around"}
            className={classes.rosterItemGridStyle}
            item style={{ borderRight: `${weekDayIndex < 6 ? "1px" : "0px"} solid #DBEAFF` }}
            key={`${backgroundIndex}${weekDayIndex}-4`}
        >
            <Popover
                onClick={() => {
                    this.setState({ shiftOpen: true, index: `${backgroundIndex}${weekDayIndex}` })
                }}
                key={`${backgroundIndex}${weekDayIndex}-2`}
                isOpen={Boolean(this.state.shiftOpen) && this.state.index === `${backgroundIndex}${weekDayIndex}`}
                positions={['right', 'top', 'bottom', 'left']}
                onClickOutside={() => {
                    if (this.state.addShiftModal) return;
                    this.setState({ shiftOpen: false, index: null }, () => { this.props.setLoader(false) })
                }}
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'white'}
                        arrowSize={8}
                        arrowStyle={{ opacity: 1 }}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                    >
                            <ShiftsPopup
                                ref={this._shiftPopup}
                                isOpen={Boolean(this.state.shiftOpen) && this.state.index === `${backgroundIndex}${weekDayIndex}`}
                                title={`Shifts`}
                                start_date={moment(this.props.weekDate).startOf("week").add(1, "day").format("YYYY-MM-DD")}
                                end_date={moment(this.props.weekDate).startOf("week").add(7, "day").format("YYYY-MM-DD")}
                                selectedDate={moment(this.props.weekDate).startOf("week").add(weekDayIndex, "day").format("YYYY-MM-DD")}
                                employeeData={roster_assignments}
                                onClose={() => { this.setState({ shiftOpen: false, index: null }) }}
                                getRosterAssignments={this.props.getRosterAssignments}
                                addShiftModal={this.state.addShiftModal}
                                setShiftModal={(value) => { this.setState({ addShiftModal: value }) }}
                                shiftData={this.props.shiftData}
                                fetchShift={() => this.props.fetchShift()}
                            />
                    </ArrowContainer>

                )}
            >
                <div className={classes.renderItemContainer} style={{ backgroundColor: `${dataColor[backgroundIndex % 5].backgrounColor}`, }}>
                    <div onClick={() => {
                    
                        this.setState({ shiftOpen: true, index: `${backgroundIndex}${weekDayIndex}` });
                    }

                    } style={{ cursor: "pointer" }}>
                        <Typography style={{ textAlign: "start", color: "#444444", fontSize: "10px", fontWeight: "400", cursor: "pointer" }}>Shifts:</Typography>
                        {this.renderItem(roster_assignments && roster_assignments.shifts)}
                    </div>
                    <React.Fragment key={`${backgroundIndex}${weekDayIndex}`}>
                        <CloseIcon
                            style={{
                                width: "12px",
                                height: "12px",
                                color: "#666666",
                                cursor: "pointer"
                            }}
                            onClick={async () => {
                                this.deleteDayShift(roster_assignments)
                            }}
                        >
                        </CloseIcon>

                    </React.Fragment>
                </div>
            </Popover>

        </Grid >)
    }

    shiftItemRender = (backgroundIndex, item) => {
        const { roster_assignments } = item
        let array = [];
        for (let weekDay = 0; weekDay < 7; weekDay++) {
            if (roster_assignments[weekDay]) {
                array.push(<>{this.shiftContainer(roster_assignments[weekDay], weekDay, backgroundIndex)}
                </>)
            }
            else {
                array.push(<>{this.shiftContainer({ shifts: [] }, weekDay, backgroundIndex)}
                </>)
            }

        }
        return array;
    }

    deleteRosterEmployee = async (employeeData) => {
        this.props.setLoader(true);
        await Api.rosters(employeeData.roster_assignments[0].roster_id).delete({
            employees: [employeeData.id]
        });
        await this.props.getRosterAssignments();
        this.props.setLoader(false);

    }

    render() {
        const { classes, collapse, delayCollapse, employeeList, largeDelay } = this.props;

        return employeeList.map((item, index) => {
            var spaceFirstName = item.first_name.replace(/(\w{11})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
            var spaceLastName = item.last_name.replace(/(\w{11})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
            return (<>
                <Grid key={item.id} xs={12} style={{ display: "flex", borderBottom: "1px solid #DBEAFF" }}>
                    <Grid container xs={collapse ? 1 : 2} className={classes.sideBarAnimate} >
                        <Grid xs={collapse ? 12 : 5} direction={"column"} style={collapse ? { paddingTop: "5px" } : null} className={classes.sideBarAnimate}
                            alignItems={"center"} justifyContent={"center"} container>
                            <Avatar
                                style={{ paddingTop: "0px", border: `3px solid ${dataColor[index % 5].avatarColor}`, width: "40px" }}
                                src={Api.employees(item.id).avatar.getUrl(true)}>
                            </Avatar>
                            {delayCollapse &&
                                <Typography style={{ color: "rgba(0, 0, 0, 0.54)", paddingTop: "5px", fontSize: "0.8rem", fontWeight: "400", letterSpacing: "0.01071em", lineHeight: "1.43" }}>
                                    {item.code}
                                </Typography>
                            }
                        </Grid>
                        {largeDelay ? null : (<>
                            <Grid xs={7} className={classes.sideBarAnimate} container alignContent={"center"}>
                                <ListItemText
                                    primary={`${spaceFirstName} ${spaceLastName} `}
                                    primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
                                    secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                                    secondary={item.code}
                                />
                            </Grid>

                        </>)}
                    </Grid>

                    <Grid container xs={collapse ? 12 : 11} className={classes.sideBarAnimate} style={{ borderLeft: "1px solid #DBEAFF" }}>
                        <Grid xs={12} container justifyContent={"space-evenly"}>
                            {this.shiftItemRender(index, item)}
                            <Grid item className={classes.sideBarAnimate} alignContent={"center"} justifyContent={"center"} style={{ borderLeft: "1px solid #DBEAFF", paddingTop: "5px" }}>
                                <IconButton
                                    onClick={() => {
                                        this.setState({ isEmployeeDeleteModal: item.id });
                                    }}
                                    size="medium">
                                    <DeleteOutline color="secondary" />

                                </IconButton>

                            </Grid>
                        </Grid>

                    </Grid>
                    <ConfirmModal
                        key={item.id}
                        title="Delete Employee"
                        isOpen={this.state.isEmployeeDeleteModal === item.id}
                        message={`Are you sure you want to delete employee "${spaceFirstName}"?`}
                        onClickCancel={() => {
                            this.setState({ isEmployeeDeleteModal: null });
                        }}
                        onClickConfirm={async () => {
                            this.setState({ isEmployeeDeleteModal: null });
                            await this.deleteRosterEmployee(item)
                            this.props.deleteEmployeeListItem(item)
                        }} />
                </Grid>

            </>
            )
        })



    }

}

const mapDispatchToProps = dispatch => ({
    setLoader: (...args) => dispatch(setLoader(...args))
});


export default connect(null, mapDispatchToProps)(withStyles(styles)(EmployeeList));