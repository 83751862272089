import React from "react";
import { withRouter } from "react-router-dom";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import moment from "moment";
import _ from "lodash";

class LeaveBalanceTableRow extends DLTableRow {

    renderCells() {
        const { data } = this.props;
        const totalTaken = data.leaves.paid.reduce((total, leave) => (total + leave.dates.length), 0);
        const totalBalance = data.leaves.paid.reduce((total, leave) => (total + Math.max(0, leave.allowed_leaves - leave.dates.length)), 0);

        return _.flatten([
            // Employee
            <DLTableColumn
                key={`LeaveBalance-${data.id}-Column-1`}
                withSeparator={true}>
                <EmployeeAvatar data={{employee: data}} />
            </DLTableColumn>
        ].concat(
            // Paid Leaves
            data.leaves.paid.map(leave => [
                <DLTableColumn
                    key={`LeaveBalance-${data.id}-Column-1-${leave.leave_id}`}
                    align="center"
                    withSeparator={true}>
                    { leave.dates.length }
                </DLTableColumn>,
                <DLTableColumn
                    key={`LeaveBalance-${data.id}-Column-2-${leave.leave_id}`}
                    align="center"
                    withSeparator={true}>
                    { Math.max(0, leave.allowed_leaves - leave.dates.length) }
                </DLTableColumn>
            ])
        ).concat([
            // Total Paid
            <DLTableColumn
                key={`LeaveBalance-${data.id}-Column-1-paid-total`}
                align="center"
                withSeparator={true}>
                { totalTaken }
            </DLTableColumn>,
            <DLTableColumn
                key={`LeaveBalance-${data.id}-Column-2-paid-total`}
                align="center"
                withSeparator={true}>
                { totalBalance }
            </DLTableColumn>
        ]).concat(
            // Unpaid/Absence
            data.leaves.unpaid.map(leave => [
                <DLTableColumn
                    key={`LeaveBalance-${data.id}-Column-1-${leave.leave_id}`}
                    align="center"
                    withSeparator={true}>
                    { leave.dates.length }
                </DLTableColumn>,
                <DLTableColumn
                    key={`LeaveBalance-${data.id}-Column-2-${leave.leave_id}`}
                    align="center"
                    withSeparator={false}>
                    0
                </DLTableColumn>
            ])
        ));
    }

}

LeaveBalanceTableRow.HeaderRowsCount = 3;

LeaveBalanceTableRow.Columns = (props, row, data) => {
    switch(row) {
        case 0:
            return [
                {
                    key: "code",
                    label: "Employee",
                    align: "left",
                    isSortable: true,
                    withSeparator: true,
                    rowSpan: 3
                },
                {
                    key: "paid",
                    label: "Paid",
                    align: "center",
                    isSortable: false,
                    withSeparator: true,
                    colSpan: data.length ? ((data[0].leaves.paid.length + 1) * 2) : 2
                },
                {
                    key: "unpaid",
                    label: "Unpaid",
                    align: "center",
                    isSortable: false,
                    withSeparator: false,
                    colSpan: 2
                }
            ];
        case 1:
            return (data.length ? data[0].leaves.paid.map(leave => ({
                key: `leave-${leave.leave_id}`,
                label: leave.leave_name,
                align: "center",
                isSortable: false,
                withSeparator: true,
                colSpan: 2
            })) : []).concat([
                {
                    key: "total",
                    label: "Total",
                    align: "center",
                    isSortable: false,
                    withSeparator: true,
                    colSpan: 2
                },
                {
                    key: "absent",
                    label: "Absence",
                    align: "center",
                    isSortable: false,
                    withSeparator: false,
                    colSpan: 2
                },
            ]);
        
        default:
            return _.flatten((data.length ? data[0].leaves.paid.map(leave => [
                {
                    key: `leave-${leave.leave_id}-taken`,
                    label: "Taken",
                    align: "center",
                    isSortable: false,
                    withSeparator: true
                },
                {
                    key: `leave-${leave.leave_id}-balance`,
                    label: "Balance",
                    align: "center",
                    isSortable: false,
                    withSeparator: true
                }
            ]) : []).concat([
                {
                    key: `leave-total-taken`,
                    label: "Taken",
                    align: "center",
                    isSortable: false,
                    withSeparator: true
                },
                {
                    key: `leave-total-balance`,
                    label: "Balance",
                    align: "center",
                    isSortable: false,
                    withSeparator: true
                },
                {
                    key: `leave-absent-taken`,
                    label: "Taken",
                    align: "center",
                    isSortable: false,
                    withSeparator: true
                },
                {
                    key: `leave-absent-balance`,
                    label: "Balance",
                    align: "center",
                    isSortable: false,
                    withSeparator: false
                }
            ]));
    };
};

export default withRouter(LeaveBalanceTableRow);