const styles = theme => { return ({

infoIcon: {
    color: theme.palette.label.main,
    lineHeight: 1
},
iconTooltip: {
    lineHeight: 1.25,
    fontSize: 15,
    marginLeft: 3
}
})};

export default styles;