import React from "react";
import { Chip } from "@material-ui/core";
import { DLTableRow, DLTableColumn, Money } from "@/components";
import Theme from "@/themeStyles";
import { DeleteOutline, Launch } from "@material-ui/icons";
import { Tooltip, IconButton } from "@material-ui/core";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import PropTypes from "prop-types";
import { Api } from "@/helpers";
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Link } from "react-router-dom";

class LoanRepayTableRow extends DLTableRow {
    static propTypes = {
        onUpdate: PropTypes.func,
    };
    state = {
        isDeleting: false,
    };

    delete = async (id) => {
        this.setState({ isDeleting: false });
        this.props.setLoader(true);
        try {
            await Api.loan_transaction(id).delete_deducted.delete();
            this.props.setSnackbar(true, "Loan repayment deleted successfully");
            this.props.onUpdate();
        } catch (e) {
            this.props.setSnackbar(
                true,
                "Something went wrong, please try again",
                "error"
            );
        }
        this.props.setLoader(false);
    };



    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                withSeparator={false}
                key={`Repayment-${data.id}-Column-1`}
                align={"left"}
            >
                {data.paid_date}
            </DLTableColumn>,
            <DLTableColumn
                withSeparator={false}
                key={`Repayment-${data.id}-Column-2`}
                align={"left"}
            >
                <Money amount={data.amount} />
            </DLTableColumn>,
            data.amount > 0 ? (
                <DLTableColumn
                    withSeparator={false}
                    key={`Repayment-${data.id}-Column-3`}
                    align={"center"}
                >
                    {data.manual_granted ? (
                        <Chip
                            size="small"
                            label={"MANUAL"}
                            color={"primary"}
                            style={{
                                backgroundColor:Theme.palette.primary.main,
                                color: Theme.color.white.main,
                            }}
                        />
                    ) : (
                        <Chip
                            size="small"
                            label={"AUTO"}
                            color={"primary"}
                            style={{
                                backgroundColor: Theme.palette.success.main,
                                color: Theme.color.white.main,
                            }}
                        />
                    )}
                </DLTableColumn>
            ) : (
                <DLTableColumn
                    withSeparator={false}
                    key={`Repayment-${data.id}-Column-3`}
                    align={"center"}
                >
                    {data.manual_deduction ? (
                        <Chip
                            size="small"
                            label={"MANUAL"}
                            color={"primary"}
                            style={{
                                backgroundColor:Theme.palette.primary.main,
                                color: Theme.color.white.main,
                            }}
                        />
                    ) : (
                        <>
                            <Chip
                                size="small"
                                label={"AUTO"}
                                color={"primary"}
                                style={{
                                    backgroundColor: Theme.palette.success.main,
                                    color: Theme.color.white.main,
                                }}
                            />
                            <Link to={{pathname:`/payroll/${data.payslip.payrun_id}/payslip/${data.payslip_id}`}} target = "_blank" >
                                <Launch
                                    style={{cursor: "pointer", verticalAlign: "middle"}}
                                    color="primary"
                                />
                            </Link>
                        </>
                    )}
                </DLTableColumn>
            ),
            <DLTableColumn
                withSeparator={false}
                key={`Repayment-${data.id}-Column-4`}
                align={"center"}
            >
                {data.amount > 0 ? (
                    <Chip
                        size="small"
                        label={"GRANT"}
                        color={"primary"}
                        style={{
                            backgroundColor: Theme.palette.primary.main,
                            color: Theme.color.white.main,
                        }}
                    />
                ) : (
                    <Chip
                        size="small"
                        label={"REPAYMENT"}
                        color={"primary"}
                        style={{
                            backgroundColor:"#002652",
                            color: Theme.color.white.main,
                        }}
                    />
                )}
            </DLTableColumn>,
                <DLTableColumn
                    withSeparator={false}
                    key={`Repayment-${data.id}-Column-5`}
                    align={"right"}
                > 
				{(data.manual_deduction || data.manual_granted) && (
                    <Tooltip title="Delete Manual Loan">
                        <IconButton
                            onClick={() => this.setState({ isDeleting: true })}
                            size="small"
                        >
                            <DeleteOutline color="secondary" />
                        </IconButton>
                    </Tooltip>  )}
                </DLTableColumn>,
            this.state.isDeleting && (
                <ConfirmModal
                    title="Delete Loan Deduction"
                    isOpen={this.state.isDeleting}
                    message={`Are you sure want to delete this repayment`}
                    onClickCancel={() => this.setState({ isDeleting: false })}
                    onClickConfirm={() => this.delete(data.id)}
                />
            ),
        ];
    }
}

LoanRepayTableRow.Columns = [
    {
        key: "paid_date",
        label: "Date",
        align: "left",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "paid_amount",
        label: "Amount",
        align: "left",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "method",
        label: "Method",
        align: "center",
        isSortable: false,
        withSeparator: false,
    },
	{
        key: "type",
        label: "Type",
        align: "center",
        isSortable: false,
        withSeparator: false,
    },
    {
        key: "",
        label: "",
        align: "right",
        colspan: 1,
        isSortable: false,
        isCompact: true,
        withSeparator: false,
    },
];

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});

export default connect(null, mapDispatchToProps)(LoanRepayTableRow);
