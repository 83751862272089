import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import styles from "./style";

class PageTitle extends Component {

    render() {
        const { classes, children } = this.props;
        return (
            <Typography component="h2" className={classes.title}>{children}</Typography>    
        );
    }
}

export default withStyles(styles)(PageTitle);