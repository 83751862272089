import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link, Switch, Route } from "react-router-dom";
import { Button, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import { DLTable, PayrunSummary, BackButton, Page, Stepper } from "@/components";
import PayrunForm from "./../PayrunAdd/form";
import PayslipTableRow from "../PayrunAdd/PayslipTableRow";
import SalaryDisbursementLetter from "@/components/Modals/SalaryDisbursementLetter";
import EmployeeSelectionModal from "@/components/Modals/EmployeeSelectionModal";
import PaymentModal from "@/components/Modals/PaymentModal";
import ContentLoader from "react-content-loader";
import { Api, HttpErrorHandler, AccessManager } from "@/helpers";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import { AuthContext } from "@/contexts";
import { PayslipModal } from "@/components";

class PayrunView extends Component {

	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			payrun: {},
			anchorEl: null,
			paymentModal: false,
			salaryDisbursementLetter: false,
			employeesModal: false,
			employeesModalForSendPayslip: false,
			selectEmployees: [],
			searchEmployee: "",
			loading: true,
			search: "",
			sendingPayslipLoadingText: null,
			retentData: null
		};
	}

	componentDidMount() {
		// TODO: Refresh payrun from server in background if it's available in location state
		// if (this.props.location && this.props.location.state && this.props.location.state.payrun) {
		// 	this.setState({ loading: false, payrun: this.props.location.state.payrun });
		// }
		this.getPayrun();
	}

	getPayrun = async () => {
		if (this.props.match.params.id) {
			try {
				var response = await Api.payruns(this.props.match.params.id).get();
				this.setPayrun(response);

			} catch (error) {
				HttpErrorHandler.intercept(error)
					.on(400, () => this.props.setSnackbar(true, "Payroll not found", "error"))
					.default(() => this.props.setSnackbar(true, "Unable to get Payrol details, please try again", "error"))
					.go();
				this.props.history.push("/payroll");
			}
		}
	};

	setPayrun = payrun => {
		if (payrun) {
			if (this.props.location && this.props.location.state && this.props.location.state.payrun) {
				const state = { ...this.props.location.state, payrun };
				this.props.history.replace({ ...this.props.location, state });
			}
			return this.setState({ loading: false, payrun: {...payrun} });
		}

		this.getPayrun();
	};

	onSubmitExportPayslip = employees => {
		/* Export Payslips URL
			http: //api.payroll.local/v1/payroll/108/export/payslips?employees[]=1&employees[]=2
		*/
		if (_.size(employees) > 0) {
			window.open(Api.payruns(this.state.payrun.id).export.payslips.getUrl({ employees }));
		}
		this.setState({ employeesModal: false });
	};

	onSubmitSendPayslip = async employees => {
		try {
			this.setState({ sendingPayslipLoadingText: "Sending" });
			await Api.payruns(this.state.payrun.id).email.payslips.post({ employees });
			this.props.setSnackbar(true, `Payslip emailed to ${employees.length} employee${employees.length === 1 ? '' : 's'}`);
			this.setState({ employeesModalForSendPayslip: false, sendingPayslipLoadingText: null });
		} catch (e) {
			this.setState({ sendingPayslipLoadingText: null });
			this.props.setSnackbar(true, "Unable to email payslips, please try again", "error");
		}
	};

	getEmployeesFromPayslip = search => {
		const payslips = this.state.payrun.payslips;
		return _.reduce(payslips, (employees, slip) => {
			if (_.includes(slip.employee.first_name.toLowerCase(), search.toLowerCase()) || _.includes(slip.employee.last_name.toLowerCase(), search.toLowerCase()) || _.includes(slip.employee.code.toLowerCase(), search.toLowerCase())) {
				employees.push(slip.employee);
			}
			return employees;
		}, []);
	};

	edit = async () => {
		this.props.setLoader(true);
		try {
			const payrun = await Api.payruns(this.props.match.params.id).put({ is_approved: false });
			this.setPayrun(payrun);
		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
		this.props.setLoader(false);
	};
	search = () => {
        const { search,payrun } = this.state;
        return _.filter(payrun.payslips, slip => {
            return _.includes(`${slip.employee.first_name} ${slip.employee.last_name}`.toLowerCase(), search.toLowerCase()) || _.includes(slip.employee.code.toLowerCase(), search.toLowerCase());
        });
    };
	render() {
		const { classes } = this.props;
		const { payrun, loading, sendingPayslipLoadingText } = this.state;
		const isTaxModule = AccessManager.isModuleAccess("TAX", this.context.organization);
		const removeColumn = !isTaxModule ? ["taxes"] : []
		const slips = this.search()
		var id  = this.props.location.pathname.split("/")
		return (
			<Page title="Payroll">
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid>
						<Link to={{ pathname: `/payroll` }} style={{ textDecoration: 'none', color: "primary" }}>
							<BackButton route="/payroll" label="Back to Payroll" />
						</Link>
					</Grid>
					{
						!loading && payrun.is_approved &&
						<Grid>
							<UserRole
								routePermissions={[
									Permissions.PAYROLL_FULL_ACCESS,
									Permissions.PAYROLL_EDITABLE
								]}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									onClick={() => this.setState({ paymentModal: !this.state.paymentModal })}>
									Payment
								</Button>
							</UserRole>
							<IconButton
								aria-label="More"
								aria-owns={Boolean(this.state.anchorEl) ? "long-menu" : undefined}
								aria-haspopup="true"
								onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
								<MoreVert />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={this.state.anchorEl}
								open={Boolean(this.state.anchorEl)}
								onClose={() => this.setState({ anchorEl: null })}
								PaperProps={{
									style: {
										minWidth: 200,
									},
								}}>

								<MenuItem onClick={() => { this.setState({ anchorEl: null }); window.open(Api.payruns(payrun.id).export.csv.getUrl()); }}>Export CSV</MenuItem>
								<MenuItem onClick={() => { this.setState({ anchorEl: null }); window.open(Api.payruns(payrun.id).export.bankformat.getUrl()); }}>Export Bankislami IFT</MenuItem>

								{isTaxModule && <MenuItem onClick={() => { this.setState({ anchorEl: null }); window.open(Api.payruns(payrun.id).export.tax.getUrl()); }}>Export Tax Statement (eFBR Format)</MenuItem>}
								{isTaxModule && <MenuItem onClick={() => { this.setState({ anchorEl: null }); window.open(Api.reports.tax_statement.export.iris.getUrl({ from: moment(payrun.from).format("YYYY-MM-DD"), to: moment(payrun.to).format("YYYY-MM-DD") })); }}>Export Tax Statement (IRIS Format)</MenuItem>}

								<MenuItem onClick={() => this.setState({ anchorEl: null, salaryDisbursementLetter: true })}>Export Salary Disbursement Letter</MenuItem>
								<div className={classes.divider}></div>
								<MenuItem onClick={() => this.setState({ anchorEl: null, employeesModal: true })}>Export Payslips</MenuItem>
								<MenuItem onClick={() => this.setState({ anchorEl: null, employeesModalForSendPayslip: true })}>Send Payslips</MenuItem>
							</Menu>
						</Grid>
					}
				</Grid>
				{
					loading &&
					<ContentLoader viewBox="0 0 380 140">
						{/* Only SVG shapes */}
						<rect x="0" y="0" rx="1" ry="1" width="380" height="40" />
						<rect x="10" y="40" rx="0" ry="0" width="2" height="20" />
						<rect x="0" y="60" rx="1" ry="1" width="380" height="80" />
					</ContentLoader>
				}
				{
					!loading && !payrun.is_approved &&
					<PayrunForm payrun={payrun} id ={id[3]} onUpdate={this.setPayrun} />
				}
				{
					!loading && payrun.is_approved &&
					<>
						<Stepper initialStep={1}>
							<PayrunSummary payrun={payrun} onUpdate={this.setPayrun} pageType="view" />
							<Stepper.Step
								title="Payslips"
								fullWidth={true}
								dense={true}>
								<DLTable
									data={slips}
									rowProps={{
										onRemovePayment: this.setPayrun,
										isView: true,
										isTaxModule,
										search: this.state.search,
										setState: this.setState.bind(this)
									}}
									alternatingRowColors
									RowTemplate={PayslipTableRow}
									removeColumn={removeColumn}
									defaultOrder="asc"
								/>
							</Stepper.Step>
						</Stepper>
						{
							this.state.paymentModal &&
							<PaymentModal
								payrun={payrun}
								open={this.state.paymentModal}
								onClose={() => this.setState({ paymentModal: false })}
								onUpdate={this.setPayrun}
							/>
						}
						{
							this.state.salaryDisbursementLetter &&
							<SalaryDisbursementLetter
								payrun={payrun.id}
								open={this.state.salaryDisbursementLetter}
								onClose={() => this.setState({ salaryDisbursementLetter: false })}
							/>
						}
						{/* For Export PaySlips */}
						<EmployeeSelectionModal
							multiSelect
							title="Export Payslips"
							isOpen={this.state.employeesModal}
							selectColumn="id"
							onSubmit={this.onSubmitExportPayslip}
							data={this.getEmployeesFromPayslip(this.state.searchEmployee)}
							// multiSelect
							onSearch={val => this.setState({ searchEmployee: val })}
							buttonText="Export"
							onClose={() => this.setState({ employeesModal: false })}
						/>

						{/* For Sending Payslips emails */}
						{
							this.state.employeesModalForSendPayslip &&
							<EmployeeSelectionModal
								multiSelect
								title="Send Payslips"
								isOpen={this.state.employeesModalForSendPayslip}
								selectColumn="id"
								onSubmit={this.onSubmitSendPayslip}
								data={this.getEmployeesFromPayslip(this.state.searchEmployee)}
								// multiSelect
								onSearch={val => this.setState({ searchEmployee: val })}
								loadingText={sendingPayslipLoadingText}
								buttonText="Send"
								onClose={() => this.setState({ employeesModalForSendPayslip: false })}
							/>
						}
						<Switch>
							<Route exact path="/payroll/:id/payslip/:payslipId">
								<PayslipModal
									data={slips}
									viewOnly={true}
									onUpdate={this.props.onUpdate}
									onClose={()=> this.props.history.push({ pathname:`/payroll/${payrun.id}`})}
								/>
							</Route>
						</Switch>
					</>
				}
			</Page>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: status => dispatch(setLoader(status))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(PayrunView)));