const styles = theme => ({

    tabsRoot: {
        borderBottom: `1px ${theme.palette.borderColor.main} solid`
    },

    tabItemsLabelIcon: {
        minHeight: theme.spacing(6),
        padding: 0,

        "& $tabItemsWrapper > *:first-child": {
            marginBottom: 0,
            marginRight: theme.spacing(1)
        }
    },

    textColorInherit:{
        opacity:1
    },

    tabItemsWrapper: {
        flexDirection: "row"
    },
    
    linkStyle:{
        paddingtop: "6px",
        paddingRight: "12px",
        position: "relative",
        fontSize: "0.875rem",
        maxWidth: "264px",
        minWidth: "72px",
        boxSizing: "border-box",
        minHeight: "48px",
        textAlign: "center",
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.75,
        whiteSpace: "normal",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        textDecoration: 'none',
    }

});

export default styles;