import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Popper, Grid, InputLabel, Switch } from "@material-ui/core";
import styles from "./style";
import { Api } from "@/helpers";
import _ from "lodash";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";

Popper.defaultProps = {
    placement: "bottom-start"
};

class Modules extends Component {

    state = {
        selectedOption: [],
        modules: [],
        loading: false,
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.loadModules();
    }

    async loadModules() {
        const _modules = await Api.modules.get({per_page: 0});
        _modules.data.sort(function(a, b) {
            if (a.parent < b.parent) return -1;
            if (a.parent > b.parent) return 1;
            return 0;
        });

        const modules = (_modules.data.reduce((groups, module) => {
            if (!module.parent) {
                groups[module.id] = {
                    parent: module,
                    children: []
                };
            } else {
                groups[module.parent].children.push(module);
            }
            return groups;
        }, {}));
        
        var selectedOption = [];
        if (this.props.match && this.props.match.params.id !== undefined) {
            const plans = await Api.plans(this.props.match.params.id).modules.get();
            selectedOption = plans.map(a => a.module_id);
            this.props.onUpdate(selectedOption);
        }

        this.setState({ modules, selectedOption, loading: false });
    }


    handleChange(module) {
        var { modules, selectedOption } = this.state;
        var affected = [ module.id ];
        var state = selectedOption.indexOf(module.id) > -1
        
        if (state) {
            if (!module.parent) {
                affected.push(...modules[module.id].children.map(child => child.id));
            }
            selectedOption = selectedOption.filter(x => !affected.includes(x));
            
        } else {
            if (module.parent && selectedOption.indexOf(module.parent) === -1) {
                affected.push(module.parent);
            }
            selectedOption.push(...affected);
        }

        this.setState({ selectedOption });
        this.props.onUpdate(selectedOption);
    }

    humanize(str) {
        var word = str.toLowerCase();
        var i, frags = word.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    renderModules(modules, children = false) {
        const { selectedOption } = this.state;
        const { classes } = this.props;
        return _.map(modules, _module => {
            var module = _.isObject(_module.parent) ? _module.parent : _module;
            return (
                <Grid container className={children ? classes.childModule : classes.parentModule}>
                    <Grid item className={classes.labelGrid}>
                        <InputLabel className={classes.inputLabel}>
                            {this.humanize(module.name)}
                        </InputLabel>
                    </Grid>
                    <Grid item>
                        <Switch
                            checked={(selectedOption.indexOf(module.id) > -1)}
                            onChange={() => this.handleChange(module)}
                            value="checkedB"
                            color="primary"
                        />
                    </Grid>
                    { !!_module.children && this.renderModules(_module.children, true) }
                </Grid>
            );
        });
    }

    render() {
        const { classes } = this.props;
        const { modules } = this.state;
        return (
            <Grid
                container
                direction="column"
                className={classes.container}
            >
                { this.renderModules(modules) }
            </Grid>
        );
    }

}

Modules.defaultProps = {
    onUpdate: () => { },
};

Modules.propTypes = {
    onUpdate: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Modules)));