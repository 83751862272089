const style = theme => ({

    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "flex-start",
        justifyContent: "flex-start"
    }

});

export default style;