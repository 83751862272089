import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn, UserRole } from "@/components";
import OrganizationPurchaseModal from "@/components/Modals/OrganizationPurchaseModal";
import { Tooltip, IconButton, Chip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined, Block } from "@material-ui/icons";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import moment from "moment";
import store from "@/redux";
import Api from "@/helpers/Api";
import { withRouter } from "react-router-dom";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Permissions } from "@/config/Permissions";

class PurchasesTableRow extends DLTableRow {
	state = {
		isEditing: false,
		isModalOpen : false,
		expireModalOpen : false

	};

    delete = async () => {
		const { data } = this.props;
		try {
			if(this.state.expireModalOpen){
				await Api.purchases(data.id).expire.post();
			}else{
				await Api.purchases(data.id).delete();
			}
			store.dispatch(setSnackbar(true, this.state.expireModalOpen?`${data.plan.title} expired successfully`:`${data.plan.title} deleted successfully`));
			this.props.reload();
		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
		this.setState({ isModalOpen: false, expireModalOpen : false });
	};
	
	renderCells() {
		const { data } = this.props;
		const { isEditing } = this.state;
		data.isEditable = !data.end_date;
		const isTrial = moment().isSameOrBefore(data.trial_end);
        const isEnded = data.end_date && moment().isAfter(data.end_date);
		return [
			<DLTableColumn
				key={`Purchase-${data.id}-Column-1`}
				withSeparator={true}>
				{data.plan.title}
			</DLTableColumn>,
			<DLTableColumn
				key={`Purchase-${data.id}-Column-2`}
				align="left">
				{moment(data.start_date).format("DD-MMM-YYYY")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Purchase-${data.id}-Column-3`}
				align="left">
				{!!data.trial_end ? moment(data.trial_end).format("DD-MMM-YYYY") : "—"}
			</DLTableColumn>,
			<DLTableColumn
				key={`Purchase-${data.id}-Column-3`}
				align="left">
				{!!data.end_date ? moment(data.end_date).format("DD-MMM-YYYY") : "Ongoing"}
			</DLTableColumn>,
			<DLTableColumn
				key={`Purchase-${data.id}-Column-4`}
				align="left">
				{data.comment || "—"}
			</DLTableColumn>,
			<DLTableColumn
				key={`Purchase-${data.id}-Column-4`}
				align="left">
				<Chip size="small" label={isEnded ? "INACTIVE" : (isTrial ? "TRIAL" : "ACTIVE")} color={isEnded ? "secondary" : (isTrial ? "warning" : "primary")}/>
			</DLTableColumn>,
			<UserRole routePermissions={[ Permissions.ORGANIZATIONS_FULL_ACCESS ]}>
				<DLTableColumn
					key={`Purchase-${data.id}-Column-5`}
					align={"right"}
					isCompact={true}
					fillWidth={35}>
					{
						data.isEditable && 
						<>
							<Tooltip title="Edit Purchase">
								<IconButton
									onClick={() => this.setState({ isEditing: true })}
									size="small">
									<EditOutlined color="primary" />
								</IconButton>
							</Tooltip>
							<OrganizationPurchaseModal
								title={"Edit Purchase"}
								purchase={data}
								isOpen={isEditing}
								onUpdate={this.props.reload}
								onClose={() => this.setState({ isEditing: false })}
							/>
						</>
					}
				</DLTableColumn>
				<DLTableColumn
					key={`Purchase-${data.id}-Column-6`}
					isCompact={true}
					align={"right"}
					fillWidth="35">
					<Tooltip title="Delete Purchase">
						<IconButton
							onClick={() => this.setState({ isModalOpen: true })}
							size="small"
							color="secondary">
							<DeleteOutlined color="danger" />
						</IconButton>
					</Tooltip>
					<ConfirmModal 
						title="Delete Purchase" 
						isOpen={this.state.isModalOpen} 
						message={`Are you sure you want to delete purchase "${data.plan.title}"?`} 
						onClickCancel={() => this.setState({ isModalOpen: false })}
						onClickConfirm={this.delete}
					/>
				</DLTableColumn>
				<DLTableColumn
					key={`Purchase-${data.id}-Column-7`}
					isCompact={true}
					align={"right"}
					fillWidth="35">
					{														// When plan is life time, end date is null so expire icon show.
						data.isEditable && (data.end_date > moment.now() || data.end_date === null) &&
						<>
							<Tooltip title="Expire Purchase">
								<IconButton
									onClick={() => this.setState({ expireModalOpen: true })}
									size="small"
									color="secondary">
									<Block color="danger" />
								</IconButton>
							</Tooltip>
							<ConfirmModal 
								title="Expire Purchase" 
								isOpen={this.state.expireModalOpen} 
								message={`Are you sure you want to expire purchase "${data.plan.title}"?`} 
								onClickCancel={() => this.setState({ expireModalOpen: false })}
								onClickConfirm={this.delete}
							/>
						</>
					}
				</DLTableColumn>
			</UserRole>
		]
	}
}

PurchasesTableRow.Columns = [
	{
		key: "plan",
		label: "Plan",
		align: "left",
		isSortable: false,
		withSeparator: true
	},
	{
		key: "start_date",
		label: "Start Date",
		align: "left",
		isSortable: false
	},
	{
		key: "trial_end",
		label: "Trial End",
		align: "left",
		isSortable: false
	},
	{
		key: "end_date",
		label: "End Date",
		align: "left",
		isSortable: false
	},
	{
		key: "comment",
		label: "Comments",
		align: "left",
		isSortable: false
	},
	{
		key: "sataus",
		label: "Status",
		align: "left",
		isSortable: false
	},
	{
		key: "action",
		label: "",
		align: "left",
		isSortable: false,
		colSpan: 3
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(PurchasesTableRow));