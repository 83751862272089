import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";

class ShiftsDropdown extends Component {

    static defaultProps = {
        label: "Shifts",
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        shifts: null
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.shifts || (this.state.shifts.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const shifts = await Api.shifts(this.props.value).get();
                return this.setState({ shifts });
            } catch (e) { }
        }
        this.setState({ shifts: null });
    }
    loadShift = async (keyword = "") => {
        this.setState({ loading: true });
        const response = await Api.shifts.get({
            per_page: 50,
            filter: { name: keyword }
        });
        return response.data;
    };

    onChange = (event, shifts = {}) => {
        this.setState({ shifts });
        this.props.onChange((shifts && shifts.id) ? shifts.id : null);

    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ root: classes.dropdown, paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadShift}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => {
                    if (value && value) {
                        return option.shift_id === value.shift_id;
                    }
                }}
                onChange={this.onChange}
                value={this.state.shifts}
                disabled={!!disabled}
            />
        );
    }

}

export default withStyles(styles)(ShiftsDropdown);