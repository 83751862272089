import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, Icon, Grid, Badge } from "@material-ui/core";
import { DLTable, InfographicMessage, BulkAction } from "@/components";
import LoanTableRow from "./LoanTableRow";
import {setLoader, setSnackbar} from "@/redux/actions/general";
import styles from "./style";
import FilterData from "@/pages/Loans/FilterData";
import _ from "lodash";
import { FilterList } from "@material-ui/icons";
import Store from "@/redux";
import { Api, HttpErrorHandler } from "@/helpers";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Constants } from "@/config";
import LoanPaymentModal from "@/components/Modals/PaymentModalLoan";
import Filter from "@/components/Filter";


class LoanList extends Component {

	constructor(props) {
		super(props);
		
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();
		
		this.state = {
			filters: null,
			fields: FilterData,
			approveOrReject: null
		};
	}

	emptyView() {
		const { classes } = this.props;
		return (
			<div className={classes.infographicMessage}>
				<InfographicMessage
					illustration="Loan"
					message="You have no loan request, create first loan request now!"
					actionLabel="Add Loans"
					actionIcon="add"
					actionHandler={() => this.props.history.push("/loans/add")}
				/>
			</div>
		);
	}

	loanDelete = async (selected) => {
		this.props.setLoader(true);
		try {
			var loanId = []
			selected.map((item) => {
				loanId.push(item.id)
			})
			await Api.loan_requests.delete.post({
				loan_requests:loanId,
			});
			this.props.setLoader(false);
			this.props.setSnackbar(true, `Loan deleted successfully`);
		}catch(e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
			if (errors && errors.length) {
				this.props.setSnackbar(true, errors[0], "error");
			} else {
				this.props.setSnackbar(true, "Something went wrong, please try again", "error")
			}
		})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
			.go();
		};
		this.props.setLoader(false);
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
		return
	}

	loanApproveOrReject = async (selected, status = "rejected") => {
		this.props.setLoader(true);
		
		try {
			var loansId = []
			selected.map((item) => {
					loansId.push(item.id)
			})
			await Api.loan_request.put({
				loan_requests:loansId,
				status,
			});
			this.props.setSnackbar(true, `Loan ${status} successfully`);
		} catch(e) {
			HttpErrorHandler.intercept(e)
			.on(422, response => {
				const errors = _.values(_.mapValues(response.data, value => value[0]));
			if (errors && errors.length) {
				this.props.setSnackbar(true, errors[0], "error");
			} else {
				this.props.setSnackbar(true, "Something went wrong, please try again", "error")
			}
		})
			.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
			.go();
		}
		this.props.setLoader(false);
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	};

    getItems() {
		let items = [];
		items.push({
			name: "Grant",
			action: (_,action) => action.setState({disableAccountAccessModal: true},this.setState({approveOrReject: Constants.Loan.FLAG_REJECTED})),
			render: (selected,action) => (
				action.state.disableAccountAccessModal&&
				<LoanPaymentModal
				loan={selected}
				open={action.state.disableAccountAccessModal}
				onClose={() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
				bulk ={true}
				heading={"Grant Loan"}
				onUpdate = {() =>this.tableRef.current.fetchData()}
				method={"granted"}
			/>
			)
		});
		items.push({
			name: "Deduct",
			action: (_,action) => action.setState({disableAccountAccessModal: true},this.setState({approveOrReject: Constants.Loan.FLAG_REJECTED})),
			render: (selected,action) => (
				action.state.disableAccountAccessModal&&
				<LoanPaymentModal
					loan={selected}
					open={action.state.disableAccountAccessModal}
					onClose={() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
					bulk ={true}
					onUpdate={() =>this.tableRef.current.fetchData()}
					heading={"Deduct Loan"}
					method={"deduction"}
				/>
			)
		});
		items.push({
			name: "Approve",
			action: (_,action) => {action.setState({enableAccountAccessModal: true},this.setState({approveOrReject: Constants.Loan.FLAG_APPROVED}))},
			render: (selected,action) => (
				action.state.enableAccountAccessModal&&
				<ConfirmModal
                    isOpen={action.state.enableAccountAccessModal}
                    title={"Approve"}
                    message={`Are you sure to approve ${selected.length} loan${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel= {() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
                    onClickConfirm = {(pay_in_next_payroll) => {
						action.setState({enableAccountAccessModal: false,activeOption: null});
						this.loanApproveOrReject(selected, this.state.approveOrReject, pay_in_next_payroll)
					}}
                />
			)
		});
		items.push({
			name: "Reject",
			action: (_,action) => {action.setState({enableAccountAccessModal: true},this.setState({approveOrReject: Constants.Loan.FLAG_REJECTED}))},
			render: (selected,action) => (
				action.state.enableAccountAccessModal &&
				<ConfirmModal
                    isOpen={action.state.enableAccountAccessModal}
                    title={"Reject"}
                    message={`Are you sure to reject ${selected.length} loan${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel= {() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
                    onClickConfirm = {(pay_in_next_payroll) => {
						action.setState({enableAccountAccessModal: false,activeOption: null});
						this.loanApproveOrReject(selected, this.state.approveOrReject, pay_in_next_payroll)
					}}
					onClose = {() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
                    />
			)
		});
		items.push({
			name: "Delete",
			action: (_,action) => action.setState({disableAccountAccessModal: true},this.setState({approveOrReject: Constants.Loan.FLAG_REJECTED})),
			render: (selected,action) => (
				action.state.disableAccountAccessModal&&
				<ConfirmModal
                    isOpen={action.state.disableAccountAccessModal}
                    title={"Delete"}
                    message={`Are you sure to delete ${selected.length} loan${selected.length == 1 ? '' : 's'}?`}
                    onClickCancel= {() => {action.setState({disableAccountAccessModal: false, activeOption: null})}}
                    onClickConfirm = {() => {
						action.setState({disableAccountAccessModal: false,activeOption: null});
						this.loanDelete(selected)
					}}
					onClose = {() => {action.setState({disableAccountAccessModal: false, activeOption: null})}}
                    />
			)
		});
		return items;
	}

	onSearch = async (filters, isReset = false) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, async () => {
			if(!!oldFilters && this.tableRef && this.tableRef.current) {
				this.tableRef.current.fetchData();
			}
			this.props.setLoader(false);
		});
	};


	render() {
		const { fields, filters } = this.state;
		const { classes } = this.props;

		return (
			<Card raised>
				<Grid container justifyContent="space-between">
					<CardActions style={{padding: 0}}>
						<Link to={{pathname: `/loans/add`}} className={classes.link}>
							<Button
								className={classes.createBtn}
								variant="outlined"
								size="medium"
								color="primary">
								<Icon>add</Icon>
								Add Loan
							</Button>
						</Link>
						<BulkAction
							ref={this.bulkAction}
							options={this.getItems()}
						/>
					</CardActions>
					<CardActions>
						<Filter
							fields={fields}
							filters={
								filters || [{
									field: "status",
									method: "is",
									value: "all_active",
									operator: "and"
								}]
							}
							withQueryParams
							onUpdate={this.onSearch}
						/>
					</CardActions>
				</Grid>
				{
					!!filters &&
					<DLTable
						enableSelection
						ref={this.tableRef}
						api={Api.loan_request}
						params={{ filter: filters }}
						bulkAction={this.bulkAction}
						RowTemplate={LoanTableRow}
						emptyView={this.emptyView(true)}
						setLoader={this.props.setLoader}
					/>
				}
			</Card>
		);
	}

}

const mapStateToProps = state => ({
	filterCounter: state.general.filterCounter
});

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoanList)));