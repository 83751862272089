import React, { Component } from "react";
import { Button, Card, CardActions, Icon } from "@material-ui/core";
import { DLTable, InfographicMessage, Page, UserRole }from "@/components";
import OrganizationPurchaseModal from "@/components/Modals/OrganizationPurchaseModal";
import PurchasesTableRow from "./PurchasesTableRow";
import Api from "@/helpers/Api";

class Purchases extends Component {
	_table = null;

	state = {
		organization : {},
		isAddingPurchase: false
	}

	reload = () => {
		this.setState({ isAddingPurchase: false });	
        this._table && this._table.fetchData();
	};
	
	organization(){
		return {
			...this.props.organization,
			...this.state.organization
		}
	}

	emptyView(archived = false) {
		const { organization, hasPermission, permissions } = this.props;
		const ifPermission = hasPermission([ permissions.ORGANIZATIONS_FULL_ACCESS ]);
		return (
			<InfographicMessage
				illustration="organization"
				message={`${organization.name} doesn't have any purchase yet, add one now!`}
				actionLabel={ifPermission ? "Add Purchase" : ""}
				actionIcon={ifPermission ? "add" : ""}
				actionHandler={() => this.setState({ isAddingPurchase: true })}
			/>
		);
	}

	actionBar() {
		return (
			<UserRole routePermissions={[ this.props.permissions.ORGANIZATIONS_FULL_ACCESS ]}>
				<CardActions>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						onClick={() => this.setState({ isAddingPurchase: true })}>
						<Icon>add</Icon>
						Add Purchase
					</Button>
				</CardActions>
			</UserRole>
		);
	}

	render() {
		const organization = this.organization();
		const { isAddingPurchase } = this.state;
		return (
			<>
				<UserRole routePermissions={[ this.props.permissions.ORGANIZATIONS_FULL_ACCESS ]}>
					<CardActions>
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							onClick={() => this.setState({ isAddingPurchase: true })}>
							<Icon>add</Icon>
							Add Purchase
						</Button>
					</CardActions>
				</UserRole>
				<DLTable
					ref={table => this._table = table}
					api={Api.purchases(organization.id)}
					RowTemplate={PurchasesTableRow}
					rowProps={{}}
					emptyView={this.emptyView()}
				/>
				{
					isAddingPurchase &&
					<OrganizationPurchaseModal
						title={"Add Purchase"}
						organization={organization}
						isOpen={isAddingPurchase}
						onUpdate={this.reload}
						onClose={() => this.setState({ isAddingPurchase: false })}
					/>
				}
			</>
		);
	}
}

export default Purchases;