const styles = theme => ({

    successColor: {
        color: theme.palette.success.main
    },

    infoIcon: {
        lineHeight: "29px",
        color: theme.palette.label.main
    }

});

export default styles;