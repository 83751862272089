import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem, Dialog, Icon, CardActions, DialogTitle, DialogActions, Grid, Typography, ListItemIcon, Chip, DialogContent} from "@material-ui/core";
import { Stats, Money, PayslipPayItems, ReminderNotificationChip } from "@/components";
import { Api, AccessManager } from "@/helpers";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import { setList as setPayItems } from "@/redux/actions/payitemActions";
import styles from "./style";
import _ from "lodash";
import { EmployeeAvatar } from "@/components";
import AddPayItemModal from "../../../pages/PayItemAdd";
import Divider from "@material-ui/core/Divider";
import Modules from "@/config/Modules";
import { AuthContext } from "@/contexts";
import {Alert} from '@material-ui/lab';
import RemindersModal from "@/components/Modals/RemindersModal";
import { withRouter } from "react-router";

class PayslipModal extends Component {
    static defaultProps = {
        viewOnly: false
    };

    static contextType = AuthContext;

    state = {
        payItemMenuAnchor: null,
        isModalOpen: false,
        payItem: [],
        dataPayItem: [],
        loading: false,
        isReminderOpen: false
    };

    async componentDidMount() {
        if (this.payslip()) {
            this.fetchPayitems();

        } else {
            this.props.setSnackbar(true, "Payslip not found", "error");
            this.props.onClose();
        }
    }

    hasReminderPayItem(slip) {
        return !!_.filter(slip.pay_items, (item) => item.is_reminder).length;
    }

    fetchPayitems = async () => {
        try {
            const payItems = await Api.pay_items.get({ per_page: 0 });
            var createNew = payItems.data;  
            createNew = payItems.data;
            createNew = [
                ...createNew,
                { name: "Create New Pay Item", type: "payItem", id: "payItem" },
            ];
            payItems.data = createNew;
            this.props.setPayItems(payItems.data);
        } catch (e) {}
    };

    handleClose = () => {
        this.setState({ payItemMenuAnchor: null });
    };

    getDeductions = payslip => {
        return payslip.pay_items.map((item) => {
            if (item.is_loan || item.is_reimbursable) {
                const transactions = item.transactions.filter(function(transaction) {
                    return (item.is_loan && transaction.amount <= 0) || (item.is_reimbursable && transaction.approved_amount < 0);
                });
                
                return transactions.map(transaction => {
                    return {
                        ...item,
                        transaction
                    };
                });
            } else if (item.is_deduction && !item.details.is_witholding_tax) {
                return item;
            }
        }).filter(i => i).flat();
    };


    getEarnings = payslip => {
        return payslip.pay_items.map((item) => {
            if (item.is_loan || item.is_reimbursable) {
                const transactions = item.transactions.filter(function(transaction) {
                    return (item.is_loan && transaction.amount > 0) || (item.is_reimbursable && transaction.approved_amount >= 0);
                });
                
                return transactions.map(transaction => {
                    return {
                        ...item,
                        transaction
                    };
                });
            } else if (item.is_earning && !item.details.is_witholding_tax) {
                return item;
            }
        }).filter(i => i).flat();
    };

    createPayslipItem = async (payslipId, payItemId) => {
        if (payItemId === "payItem") {
            this.setState({ isModalOpen: true, payItemMenuAnchor: null });
        } else {
            this.handleClose();
            this.props.setLoader(true);
            try {
                const payrun = await Api.payslips(payslipId).payslip_item.post({
                    pay_item_id: payItemId,
                });
                this.props.onUpdate(payrun);
            } catch (e) {
                this.props.setSnackbar(
                    true,
                    "Unable to add pay item, please try again",
                    "error"
                );
            }
            this.props.setLoader(false);
        }
    };

    getPayItems = () => {
        const { payItems } = this.props;
        var data =  this.payslip() 
        if (!data || !data.pay_items || !payItems) return [];

        const isReimbursement = AccessManager.isModuleAccess(
            Modules.REIMBURSEMENT,
            this.context.organization
        );

        // Only show items available to add (i.e. not already added to slip)
        return _.filter(payItems, (payItem) => {
            return !_.find(
                data.pay_items,
                (item) =>
                    item.details.id === payItem.id ||
                    (payItem.is_reimbursable && isReimbursement)
            );
        });
    };

    payslipSummary = (payslip) => {
        const hasTaxModule = AccessManager.isModuleAccess(Modules.TAX, this.context.organization);
        const { classes } = this.props;
        return hasTaxModule || payslip.taxes !== 0 ? (
            <>
                <Stats label="Gross Salary" className={classes.successColor}>
                    <Money amount={payslip.earnings - payslip.deductions} />
                </Stats>
                <Stats color="secondary">-</Stats>
                <Stats label="Taxes" color="secondary">
                    <Money amount={payslip.taxes} />
                </Stats>
                <Stats color="primary">=</Stats>
                <Stats label="Net Payable" color="primary">
                    <Money amount={payslip.net_payable}/>
                </Stats>
            </>
        ) : (
            <>
                <Stats label="Earnings" className={classes.successColor}>
                    <Money amount={payslip.earnings} />
                </Stats>
                <Stats color="secondary">-</Stats>
                <Stats label="Deductions" color="secondary">
                    <Money amount={payslip.deductions} />
                </Stats>
                <Stats color="primary">=</Stats>
                <Stats label="Net Payable" color="primary">
                    <Money amount={payslip.net_payable} />
                </Stats>
            </>
        );
    };

    payslip = () => {
		const {data} = this.props;
        return _.find(data, { id: parseInt(this.props.match.params.payslipId) });
    };

    render() {
        const { classes, viewOnly } = this.props;
        const { payItemMenuAnchor} = this.state;
        const payslip = this.payslip();
        if (!payslip) return null;

        const payItems = this.getPayItems();
        const earnings = this.getEarnings(payslip);
        const deductions = this.getDeductions(payslip);

        return (
            <Dialog
                open
                fullWidth
                maxWidth="sm"
                aria-labelledby="employee-payitem-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="employee-payslip-dialog-title">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item sm>
                            <EmployeeAvatar data={payslip}/>
                        </Grid>
                        {
                            !viewOnly &&
                            <Grid item sm="auto">
                                <CardActions style={{padding: 0}}>
                                    <Button
                                        aria-owns={ payItemMenuAnchor ? "long-menu" : undefined }
                                        aria-haspopup="true"
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        onClick={(e) => this.setState({payItemMenuAnchor: e.currentTarget })}>
                                        Add Pay Item
                                        <Icon className={classes.actionButtonIcon}>keyboard_arrow_down</Icon>
                                    </Button>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={payItemMenuAnchor}
                                        open={!!payItemMenuAnchor}
                                        onClose={this.handleClose}>
                                        {
                                            _.map(payItems, (option, index) => {
                                                if(option.is_overtime) return
                                                return (
                                                    <>
                                                        {option.id === "payItem" && ( <Divider /> )}
                                                        <MenuItem key={index} onClick={() => this.createPayslipItem( payslip.id, option.id )} >
                                                            {option.name}
                                                        </MenuItem>
                                                    </>
                                                );
                                            })
                                        }
                                    </Menu>
                                </CardActions>
                            </Grid>
                        }
                    </Grid>
                    {
                        !payslip.is_paid && !!payslip.reminders.length &&
                        <Alert variant="outlined" icon={false} onClick={() => this.setState({ isReminderOpen: true})}
                            action={<ReminderNotificationChip count={payslip.reminders.length}/>}
                            className={classes.alertStyle} severity="warning">
                            <Typography variant="h6" color="red">
                                {"Reminders"}
                            </Typography> 
                        </Alert>
                    }
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <Grid container direction="row">
                        {
                            !!earnings.length &&
                            <PayslipPayItems
                                payslip={payslip}
                                payItems={earnings}
                                heading="Earnings"
                                color="primary"
                                viewOnly={viewOnly}
                                multiColumn={!deductions.length}
                                onUpdate={this.props.onUpdate}
                            />
                        }
                        {
                            !!deductions.length && 
                            <PayslipPayItems
                                payslip={payslip}
                                payItems={deductions}
                                heading="Deductions"
                                color="secondary"    
                                viewOnly={viewOnly}
                                multiColumn={!earnings.length}
                                onUpdate={this.props.onUpdate}
                            />
                        }
                    </Grid>
                    {
                        this.state.isModalOpen &&
                        <AddPayItemModal
                            onClose={() => this.setState({ isModalOpen: false })}
                            onUpdate={this.fetchPayitems}
                        />
                    }
                    {
                        this.state.isReminderOpen &&
                        <RemindersModal
                            payslip={payslip}
                            isOpen={this.state.isReminderOpen}
                            onUpdate={this.props.onUpdate}
                            onClose={() => this.setState({ isReminderOpen: false })}
                        />
                    }
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <div className={classes.summary}>
                        {this.payslipSummary(payslip)}
                    </div>
                    {
                        <Button
                            onClick={this.props.onClose}
                            color="primary"
                            size="small"
                            variant={"outlined"}
                        >
                            done
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({ payItems: state.payitem.list });

const mapDispatchToProps = (dispatch) => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setPayItems: (...args) => dispatch(setPayItems(...args)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PayslipModal)));
