import React, { Component } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    MenuItem
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { DLTable, InfographicMessage, BankAccountDropdown } from "@/components";
import ConfirmModal from "../ConfirmModal";
import EmployeePaymentTableRow from "./EmployeePaymentTableRow";
import { currencyFormatter } from "@/helpers/generalHelper";
import { setSnackbar } from "@/redux/actions/general";
import Config, { PaymentMethod } from "@/config";
import Api from "@/helpers/Api.js";
import moment from "moment";
import _ from "lodash";

class PaymentModal extends Component {

    static propTypes = {
        onClose: Proptypes.func.isRequired,
        onUpdate: Proptypes.func,
        open: Proptypes.bool.isRequired
    };

    static defaultProps = {
        onUpdate: () => { }
    };

    constructor(props) {
        super(props);

        this.state = {
            date: moment().format(Config.SERVER_DATETIME_FORMAT),
            paymentMethod: PaymentMethod.CASH,
            sourceAccount: "",
            sourceBank: "",
            check: "",
            selectedPayslips: [],
            payments: [],
            isSubmitting: false
        };
    }

    dateChangeHandler() {
        return (date) => {
            this.setState({ date });
        }
    }

    paymentMethodChangeHandler() {
        return event => {
            var { payments } = this.state;

            _.every(payments, payment => {
                switch (event.target.value) {
                    case PaymentMethod.CASH:
                        payment.bank_account = "";
                        payment.check = "";
                        break;
                    case PaymentMethod.CHECK:
                        payment.bank_account = "";
                        break;
                    case PaymentMethod.IBFT:
                    case PaymentMethod.BANK_TRANSFER:
                    default:
                        payment.check = "";
                        break;
                }
            });
            this.setState({ paymentMethod: event.target.value, payments });
        };
    }

    bankChangeHandler() {
        return account => {
            this.setState({ sourceAccount: account.id, sourceBank: account.bank.id });
        };
    }

    checkChangeHandler() {
        return event => {
            this.setState({ check: event.target.value });
        }
    }

    selectionHandler() {
        return selectedPayslips => {
            this.setState({ selectedPayslips: _.map(selectedPayslips, "id") });
        }
    }

    paymentChangeHandler() {
        return (payslip, paymentDetails) => {
            var payments = _.filter(this.state.payments, payment => {
                return payment.payslip !== payslip;
            });

            payments.push({
                payslip,
                check: paymentDetails.check || undefined,
                bank_account: paymentDetails.account || undefined
            });

            this.setState({ payments });
        }
    }

    isBankChannelSelected() {
        const { paymentMethod } = this.state;
        return [
            PaymentMethod.CHECK,
            PaymentMethod.IBFT,
            PaymentMethod.BANK_TRANSFER
        ].indexOf(paymentMethod) !== -1;
    }

    isBankTransferSelected() {
        const { paymentMethod } = this.state;
        return paymentMethod === PaymentMethod.BANK_TRANSFER;
    }

    getPayslips() {
        return _.reduce(this.props.payrun.payslips, (payments, payslip) => {
            !payslip.is_paid && payments.push(payslip);
            return payments;
        }, []);
    }

    getSelectedPayments() {
        const { paymentMethod, selectedPayslips, payments } = this.state;

        // Payment details not needed in case of Cash.
        // So, don't waste time in validations
        if (paymentMethod === PaymentMethod.CASH) return _.map(selectedPayslips, payslip => ({ payslip }));


        const selectedPayments = _.filter(payments, payment => {
            return selectedPayslips.indexOf(payment.payslip) !== -1;
        });

        return _.reduce(selectedPayments, (carry, payment) => {
            if ((paymentMethod === PaymentMethod.CHECK && payment.check) || (paymentMethod !== PaymentMethod.CHECK && payment.bank_account)) {
                carry.push(payment);
            }
            return carry;
        }, []);
    }

    verifyPayments(showErrors = false) {
        const { paymentMethod, sourceAccount, check, selectedPayslips } = this.state;

        if (this.isBankChannelSelected() && !sourceAccount) {
            if (showErrors) {
                this.props.setSnackbar(true, "Please select source bank account", "error");
            }
            return false;
        }
        if (this.isBankTransferSelected() && !check) {
            if (showErrors) {
                this.props.setSnackbar(true, "Please provide check number", "error");
            }
            return false;
        }
        if (!selectedPayslips.length) {
            if (showErrors) {
                this.props.setSnackbar(true, "Please select employees to mark payment", "error");
            }
            return false;
        }
        if (selectedPayslips.length !== this.getSelectedPayments().length) {
            if (showErrors) {
                if (paymentMethod === PaymentMethod.CHECK) {
                    this.props.setSnackbar(true, "Please provide check number for all selected employees", "error");

                } else if (this.isBankChannelSelected()) {
                    this.props.setSnackbar(true, "Please select bank account for all selected employees", "error");
                }
            }
            return false;
        }

        return true;
    }

    confirmationMessage() {
        var { selectedPayslips, paymentMethod } = this.state;
        const amount = this.props.payrun.payslips.reduce((amount, { net_payable, id }) => (selectedPayslips.indexOf(id) !== -1 ? (amount + net_payable) : amount), 0);
        return (
            <span>
                Are you sure you want to mark payment for <strong>{selectedPayslips.length} payslip{selectedPayslips.length > 1 && "s"}</strong> via {paymentMethod} amounting <strong>{currencyFormatter(amount)}</strong>?
            </span>
        );
    }

    markPaymentsHandler() {
        return event => {
            this.setState({ confirming: false });
            if (this.verifyPayments(true)) {
                const selectedPayments = this.getSelectedPayments();
                const { date, paymentMethod, check, sourceAccount } = this.state;
                const { payrun } = this.props;

                this.setState({ isSubmitting: true });
                Api.payruns(payrun.id).payments.post({
                    date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                    method: paymentMethod,
                    payments: selectedPayments,
                    check: this.isBankTransferSelected() ? check : undefined,
                    bank_account: this.isBankChannelSelected() ? sourceAccount : undefined
                }).then(response => {
                    this.props.onUpdate({
                        ...payrun,
                        payslips: payrun.payslips.map(payslip => {
                            if (_.find(selectedPayments, { payslip: payslip.id })) {
                                payslip.is_paid = true;
                            }
                            return payslip;
                        })
                    });
                    this.props.setSnackbar(true, `Payments marked for ${selectedPayments.length} payslips`);
                    this.resetForm();

                }).catch(() => {
                    this.props.setSnackbar(true, "Something went wrong, please try again later", "error");
                    this.setState({ isSubmitting: false });
                });
            } else {

            }
        }
    }

    resetForm() {
        this.setState({
            date: moment().format(Config.SERVER_DATETIME_FORMAT),
            paymentMethod: PaymentMethod.CASH,
            sourceAccount: "",
            sourceBank: "",
            check: "",
            selectedPayslips: [],
            payments: [],
            isSubmitting: false
        });
    }

    render() {
        const { open, onClose } = this.props;
        const { date, paymentMethod, sourceAccount, sourceBank, check, isSubmitting } = this.state;
        const payslips = this.getPayslips();
        return (
            <>
                <Dialog
                    open={open}
                    onClose={onClose}
                    scroll="paper"
                    onBackdropClick={onClose}
                    aria-labelledby="payment-dialog-title"
                    aria-describedby="payment-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="payment-dialog-title">
                        <div>Record Payment</div>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            !!payslips.length ? (
                                <form>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={12}>
                                            <DatePicker
                                                autoOk
                                                inputVariant="outlined"
                                                label="Payment Date"
                                                format={Config.DISPLAY_DATE_FORMAT}
                                                value={date}
                                                onChange={this.dateChangeHandler()}
                                                disabled={isSubmitting}
                                                fullWidth
                                                size={"small"}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                size={"small"}
                                                fullWidth
                                                select
                                                label="Payment Method"
                                                variant="outlined"
                                                value={paymentMethod}
                                                onChange={this.paymentMethodChangeHandler()}
                                                disabled={isSubmitting}>

                                                <MenuItem value={PaymentMethod.CASH}>{PaymentMethod.CASH}</MenuItem>
                                                <MenuItem value={PaymentMethod.CHECK}>{PaymentMethod.CHECK}</MenuItem>
                                                <MenuItem value={PaymentMethod.BANK_TRANSFER}>{PaymentMethod.BANK_TRANSFER}</MenuItem>
                                                <MenuItem value={PaymentMethod.IBFT}>{PaymentMethod.IBFT}</MenuItem>
                                            </TextField>
                                        </Grid>
                                        {
                                            this.isBankChannelSelected() &&
                                            <Grid item md={this.isBankTransferSelected() ? 6 : 12} sm={12}>
                                                <BankAccountDropdown
                                                    label="Bank"
                                                    value={sourceAccount}
                                                    onChange={this.bankChangeHandler()}
                                                    textFieldProps={{ fullWidth: true, size: "small" }}
                                                    resultType="object"
                                                />
                                            </Grid>
                                        }
                                        {
                                            this.isBankTransferSelected() &&
                                            <Grid item md={6} sm={12}>
                                                <TextField
                                                    size={"small"}
                                                    fullWidth
                                                    value={check}
                                                    variant="outlined"
                                                    label="Check Number"
                                                    onChange={this.checkChangeHandler()}
                                                    disabled={isSubmitting} />
                                            </Grid>
                                        }
                                        {
                                            paymentMethod !== "" &&
                                            <Grid item sm={12} md={12}>
                                                <Card>
                                                    <DLTable
                                                        data={this.getPayslips()}
                                                        RowTemplate={EmployeePaymentTableRow}
                                                        rowProps={{
                                                            paymentMethod: paymentMethod,
                                                            sourceBank: sourceBank,
                                                            onPaymentChange: this.paymentChangeHandler()
                                                        }}
                                                        checkboxSize={"small"}
                                                        enableSelection={true}
                                                        onSelectionChange={this.selectionHandler()}
                                                        disabled={isSubmitting} />
                                                </Card>
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            ) : (
                                <InfographicMessage
                                    illustration="payroll"
                                    message="Great! Salaries are paid in full"
                                />
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        {
                            !!payslips.length &&
                            <Button
                                size={"small"}
                                onClick={() => this.setState({ confirming: true })}
                                disabled={!this.verifyPayments() || isSubmitting}
                                variant="contained"
                                color="primary">
                                {
                                    isSubmitting ? (
                                        <>
                                            <CircularProgress color={"primary"} size={20} />
                                            <span style={{ marginLeft: "15px" }}>Submitting...</span>
                                        </>
                                    ) : (
                                        "Submit"
                                    )
                                }
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    this.state.confirming &&
                    <ConfirmModal
                        title="Mark Payment"
                        isOpen={this.state.confirming}
                        message={this.confirmationMessage()}
                        onClickCancel={() => this.setState({ confirming: false })}
                        onClickConfirm={this.markPaymentsHandler()} />
                }
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(PaymentModal);