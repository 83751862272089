import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Grid,
    InputLabel,
    Chip,
    CircularProgress
} from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import { currencyFormatter } from "@/helpers/generalHelper";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { withStyles } from "@material-ui/core/styles";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import styles from "./style";
import { Illustration, UserRole } from "@/components";
import { Permissions } from "@/config/Permissions";
import _ from "lodash";

class PaymentStatusModal extends React.Component {

    state = {
        details: null,
        confirmModal: false
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.payslip && prevProps.payslip.id !== this.props.payslip.id) {
            this.load();
        }
    }

    async load() {
        const { payslip } = this.props;
        if (!payslip.is_paid) return;

        try {
            let details = await Api.payslips(payslip.id).payment.get();
            this.setState({ details });
        } catch (e) {
            this.setState({ details: false });
        }
    }

    removePayment = async () => {
        this.props.setLoader(true);
        try {
            await Api.payslips(this.props.payslip.id).payment.delete();
            this.props.onDelete();
            this.props.setSnackbar(true, "Payment removed succesfully")

        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }

                })
                .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                .go();
        }
        this.props.setLoader(false)
    };

    render() {
        const { isOpen, onClose, payslip, classes } = this.props;
        const { confirmModal, details } = this.state;
        return (
            <>
                <Dialog
                    open={isOpen}
                    fullWidth
                    maxWidth="sm"
                    onBackdropClick={onClose}
                    scroll="paper"
                    onClose={onClose} aria-labelledby="payment-detail-dialog-title">
                    <DialogTitle id="payment-detail-dialog-title">
                        <span>
                            <Chip className={classes.chip} size="small" color="primary" label={payslip.employee.code} />
                            <span className={classes.employeeName}>{payslip.employee.first_name} {payslip.employee.last_name}</span>
                            Payment Details
                        </span>
                    </DialogTitle>
                    <DialogContent dividers>
                        {
                            details &&
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={3} md={4}>
                                    <InputLabel className={classes.inputLabel}>Payment Method</InputLabel>
                                </Grid>
                                <Grid item xs={7} md={8}>
                                    <TextField
                                        size={"small"}
                                        fullWidth
                                        value={details.method}
                                        variant="outlined"
                                    />
                                </Grid>
                                {
                                    details.method !== "Cash" &&
                                    <>
                                        <Grid item xs={3} md={4}>
                                            <InputLabel className={classes.inputLabel}>From Account</InputLabel>
                                        </Grid>
                                        <Grid item xs={7} md={8}>
                                            <TextField
                                                size={"small"}
                                                fullWidth
                                                value={`${details.company_account.bank.name} (${details.company_account.account_number})`}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                                {
                                    (details.method === "IBFT" ||
                                        details.method === "Bank Transfer") &&
                                    <>
                                        <Grid item xs={3} md={4}>
                                            <InputLabel className={classes.inputLabel}>Beneficiary</InputLabel>
                                        </Grid>
                                        <Grid item xs={7} md={8}>
                                            <TextField
                                                size={"small"}
                                                fullWidth
                                                value={`${details.employee_account.bank.name} (${details.employee_account.account_number})`}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                                {
                                    details.reference &&
                                    <>
                                        <Grid item xs={3} md={4}>
                                            <InputLabel className={classes.inputLabel}>Check #</InputLabel>
                                        </Grid>
                                        <Grid item xs={7} md={8}>
                                            <TextField
                                                size={"small"}
                                                fullWidth
                                                value={details.reference}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={3} md={4}>
                                    <InputLabel className={classes.inputLabel}>Amount</InputLabel>
                                </Grid>
                                <Grid item xs={7} md={8}>
                                    <TextField
                                        size={"small"}
                                        fullWidth
                                        value={currencyFormatter(details.amount, true)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3} md={4}>
                                    <InputLabel className={classes.inputLabel}>Payment Date</InputLabel>
                                </Grid>
                                <Grid item xs={7} md={8}>
                                    <TextField
                                        size={"small"}
                                        fullWidth
                                        value={details.paid_at && moment(details.paid_at).format("MMM Do, YYYY")}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        }
                        {
                            details === null &&
                            <CircularProgress className={classes.loader} />
                        }
                        {
                            details === false &&
                            <Illustration
                                type="not_found"
                                message="Unable to find Payment details"
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button size={"small"} onClick={onClose}>
                            Cancel
                        </Button>
                        <UserRole routePermissions={[ Permissions.PAYROLL_FULL_ACCESS ]}>
                            <Button
                                size={"small"}
                                onClick={() => this.setState({confirmModal: true })}
                                color="secondary">
                                Remove Payment
                            </Button>
                        </UserRole>
                    </DialogActions>
                </Dialog>
                <ConfirmModal
                    isOpen={confirmModal}
                    title="Confirm"
                    message={
                        "Are you sure want to remove payment for " +
                        payslip.employee.first_name + " " + payslip.employee.last_name
                    }
                    onClickCancel={() => { this.setState({ confirmModal: false }) }}
                    onClickConfirm={this.removePayment}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
    dispatch: func => dispatch(func)
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(PaymentStatusModal))