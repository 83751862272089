const styles = theme => ({
	
	addressHeader: {
		display: "flex",
		justifyContent: "space-between"
	},

	actions: {
		padding: theme.spacing(2)
	},

	actionButton: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	
	actionButtonIcon: {
		marginRight: theme.spacing(1)
	}

});

export default styles;