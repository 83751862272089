import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
	Button,
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { connect } from "react-redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import moment from "moment";
import StatesDropdown from '@/components/Dropdowns/StatesDropdown';
import CountryDropdown from '@/components/Dropdowns/CountryDropdown.js';
import LocationMap from "../../LocationMap";
import { AuthContext } from "@/contexts";

class OrganizationBranchModal extends Component {
	static contextType = AuthContext;

	static propTypes = {
		organization: PropTypes.object,
		branch: PropTypes.object,
		title: PropTypes.string,
		isOpen: PropTypes.bool,
		onUpdate: PropTypes.func,
		onClose: PropTypes.func
	};

	static defaultProps = {
		title: "Add Branch",
		onUpdate: () => { }
	};

	constructor (props) {
		super(props);
		this.state = {
			errors: {},
			branch: {
				latitude: 24.85933348138765, longitude: 67.05993291629923
			},
			branchId: ""
		};
	}


	async componentDidMount() {
		if (!this.isNew()) {
			const response = await Api.organizations(this.props.organization.id).branches(this.props.match.params.branchId).get();
			this.setState({
				branch: {
					id: response.id,
					address: response.address,
					city: response.city,
					country: response.country,
					state: response.state,
					name: response.name,
					zoom: response.zoom,
					latitude: response.latitude,
					longitude: response.longitude,
				}
			})
		}
	}


	branch() {
		try {
			var branch = !this.props.avoidRoute ? this.props.location.state.branch : this.props.branch;
			return {
				id: "",
				address: "",
				city: "",
				country: "Pakistan",
				state: "",
				name: "",
				zoom: 16,
				latitude: null,
				longitude: null,
				...branch,
				...this.state.branch
			};
		} catch {
			return {
				id: this.state.branch.id,
				address: this.state.branch.address,
				city: this.state.branch.city,
				country: this.state.branch.country,
				state: this.state.branch.state,
				name: this.state.branch.name,
				zoom: this.state.branch.zoom,
				latitude: this.state.branch.latitude,
				longitude: this.state.branch.longitude,
				check_in: moment(this.state.branch.check_in, "HH:mm:ss").format("hh:mm a"),
				check_out: moment(this.state.branch.check_out, "HH:mm:ss").format("hh:mm a"),
				late: moment(this.state.branch.late, "HH:mm:ss").format("hh:mm a"),
			};
		}
	}

	isNew() {
		return !(this.props.match && this.props.match.params && this.props.match.params.branchId);
	}

	handleChange(value, field) {
		const branch = this.branch();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}

		this.setState({
			branch: {
				...branch,
				[field]: value
			},
			errors
		});

	}

	handleBlur(value, field) {
		const branch = this.branch();
		const { errors } = this.state;
		if (errors[field]) {
			errors[field] = false;
		}


		this.setState({
			branch: {
				...branch,
				[field]: value
			},
			errors
		});

	}

	validate() {
		const branch = this.branch();
		const optionalFields = ["id", "deleted_at"];
		var errors = {};

		_.forOwn(branch, (value, key) => {
			if (optionalFields.indexOf(key) === -1 && !value) {
				errors[key] = `${_.startCase(key)} field is required`;
			}
		});

		if (_.size(errors)) {
			this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
		}
		this.setState({ errors });

		return !_.size(errors);
	}

	save = async (e) => {
		e.preventDefault();
		if (!this.validate()) return;

		let branch = this.branch();
		this.props.setLoader(true);
		try {
			// change time format
			branch = {
				...branch,
			}

			var _branch = null;
			if (this.isNew()) {
				_branch = await Api.organizations(this.props.organization.id).branches.post(branch);
				this.props.setSnackbar(true, "New Branch added successfully");
               
			} else {
				let formData = new FormData();
				formData.append("_method", "put");
				_.map(branch, (value, key) => formData.append(key, value));
				_branch = await Api.organizations(this.props.organization.id).branches(branch.id).post(formData);
				this.props.setSnackbar(true, "Branch updated successfully");
			}
			this.props.onUpdate(_branch); 
            this.closeDialog()
			if (this.props.backRoute === "goback") {
				this.props.history.goBack();
			}
			else {
				this.props.history.push(this.props.backRoute)
			}


		} catch (e) {
			HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.go();
		}
		this.props.setLoader(false);
	};

	getPreviousPath = () => {
		let path = _.split(this.props.location.pathname, "/");
		path = _.dropRight(path);
		path = _.join(path, "/");
		return path;
	}

	closeDialog = () => {
		if (this.props.onClose) {
			this.props.onClose()
		} else {
			console.log(this.getPreviousPath());
			this.props.history.push(this.getPreviousPath());
		}
	}

	onPlacesChanged = (ab, branch) => {
		this.setState({
			branch: {
				...branch,
				latitude: ab[0].geometry.location.lat(),
				longitude: ab[0].geometry.location.lng(),
			}
		});
	}

	onBoundsChange = (center, zoom, branch) => {
		this.setState({
			branch: {
				...branch,
				latitude: center.lat,
				longitude: center.lng,
				zoom
			}
		});
	}

	render() {
		const { title } = this.props;
		const { errors } = this.state;
		const branch = this.branch();

		return (
			<Dialog
				fullWidth
				maxWidth="sm"
				open={"new"}
				onBackdropClick={this.closeDialog}
				aria-labelledby="organization-branch-dialog-title"
				onClose={this.props.onClose}
			>
				<DialogTitle id="organization-branch-dialog-title">{ title || (this.isNew() ? "Add Branch" : "Edit Branch")}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						{
							[
								{ label: "Name", id: "name" },
								{ label: "Address", id: "address" },
								{ label: "City", id: "city" },
							].map((item, index) => {
								return (
									<Grid item xs={12} key={index}>
										<TextField
											size="small"
											label={item.label}
											fullWidth
											error={!!errors[item.id]}
											value={branch[item.id] ? branch[item.id] : ""}
											onChange={e => this.handleChange(e.target.value, item.id)}
											onBlur={e => this.handleBlur(e.target.value, item.id)}
											variant="outlined"
											helperText={errors[item.id]}
											FormHelperTextProps={{ variant: "standard" }}
										/>
									</Grid>
								);
							})
						}
						<Grid item sm={12} xs={12}>
							<CountryDropdown
								fullWidth
								label="Country"
								optionLabel={branch.country}
								size={"small"}
								error={!!errors.country}
								helperText={errors.country}
								FormHelperTextProps={{ variant: "standard" }}
								value={branch.country || ""}
								onChange={(country) => this.handleChange(country, "country")}
							/>
						</Grid>
						<Grid item sm={12} xs={12}>
							{branch.country === "Pakistan"
								?
								<StatesDropdown
									fullWidth
									size={"small"}
									label={"State/Region"}
									variant="outlined"
									error={!!errors.state}
									helperText={errors.state}
									optionLabel={branch.state}
									FormHelperTextProps={{ variant: "standard" }}
									value={branch.state || ""}
									onChange={states => this.handleChange(states, "state")}
								/>
								:
								<TextField
									fullWidth
									label="State"
									error={!!errors.state}
									size={"small"}
									helperText={errors.state}
									FormHelperTextProps={{ variant: "standard" }}
									value={branch.state || ""}
									onChange={e => this.handleChange(e.target.value, "state", true)}
									variant="outlined"
								/>
							}
						</Grid>

						<Grid item sm={12} xs={12}>
							<LocationMap
								onPlacesChanged={(ab) => this.onPlacesChanged(ab, branch)}
								isNew={this.isNew()}
								branch={branch}
								onBoundsChange={(center, zoom) => this.onBoundsChange(center, zoom, branch)}
							/>
						</Grid>

					</Grid>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={() => {
						this.closeDialog()
					}} color="default">Cancel</Button>
					{
						this.context.user.organization_id ===(this.props.organization.id) &&
						<Button size="small" variant="contained" onClick={this.save} color="primary">Save</Button>
					}
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(OrganizationBranchModal));