import moment from "moment";
import styles from "./style";
import Config from "@/config";
import { Api } from "@/helpers";
import React, { Component } from "react";
import { DatePicker } from "@material-ui/pickers";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import LeaveTypeWiseSummaryRow from "./LeaveTypeWiseSummaryRow";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import LeavesDropdown from "@/components/Dropdowns/LeavesDropdown";
import { BackButton, DLTable, Page, InfographicMessage } from "@/components";
import { setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";

class LeaveTypeWiseSummary extends Component {

	_table = null;
	
	constructor(props) {
		super(props);
		this.state = {
			to: null,
			leave_id: null,
			anchorEl: null,
			department: null,
			designation: null,
			branch: null
		};
	}

	export = () => {
		const { leave_id, to, department, designation, branch } = this.state;
		if(!leave_id) return;
		this.setState({ anchorEl: null });
		window.open(Api.reports.leave_type_wise_summary.export.getUrl({
			to:  moment(to || moment()).format("YYYY-MM-DD"),
			leave_id: leave_id || undefined,
			department: department || undefined,
			designation: designation || undefined,
			branch: branch || undefined,
		}))
	};

	reload = () => {
		this._table && this._table.fetchData();
		this.props.setLoader(false);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="LeaveType"
				message="Summary not available for selected filters!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { to, leave_id, department, designation, branch} = this.state;
		return (
			<Page title="Leave Type Wise Summary">
				<BackButton route="/reports" label="Back to Reports"/>
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<Grid container spacing={1}>
							<Grid item xs="auto">
								<DatePicker
									fullWidth
									margin="dense"
									label="End Date"
									inputVariant="outlined"
									format={Config.DISPLAY_DATE_FORMAT}
									value={to || moment().format(Config.SERVER_DATE_FORMAT)}
									maxDate={moment().format("YYYY-MM-DD")}
									onChange={to => this.setState({ to }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<LeavesDropdown
									filter={{encashable_only: false}}
									textFieldProps={{margin: "dense", size: "small"}}
									loadFirst={true}
									value={leave_id}
									onChange={leave_id => this.setState({ leave_id }, this.reload)}
							 />
							</Grid>
							<Grid item xs="auto">
								<BranchDropdown
									textFieldProps={{ margin: "dense" }}
									value={branch}
									onChange={branch => this.setState({ branch }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DepartmentDropdown
									textFieldProps={{ margin: "dense" }}
									value={department}
									onChange={department => this.setState({ department }, this.reload)}
								/>
							</Grid>
							<Grid item xs={2}>
								<DesignationDropdown
									textFieldProps={{ margin: "dense", size:"small" }}
									value={designation}
									onChange={designation => this.setState({ designation }, this.reload)}
								/>
							</Grid>
						</Grid> 
						<Button 
							size="medium"
							disabled={leave_id ? false : true}
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
					</CardActions>
					<DLTable
						ref={ref => this._table = ref}
						api={leave_id ? Api.reports.leave_type_wise_summary : null}
						params={{
							to:  moment(to || moment()).format("YYYY-MM-DD"),
							leave_id: leave_id || undefined,
							department: department || undefined,
							designation: designation || undefined,
							branch: branch || undefined,
							per_page: 50
						}}
						setLoader={this.props.setLoader}
						RowTemplate={LeaveTypeWiseSummaryRow}
						emptyView={this.emptyView()}
					/>
				</Card>
			  
			</Page>
		);
	}

}
const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});
export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(LeaveTypeWiseSummary)));
