import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, Icon, Grid, Badge } from "@material-ui/core";
import { DLTable, InfographicMessage, BulkAction } from "@/components";
import ReimbursableTableRow from "./ReimbursableTableRow";
import { Api, HttpErrorHandler } from "@/helpers";
import {setLoader, setSnackbar} from "@/redux/actions/general";
import styles from "./style";
import { Constants } from "@/config";
import ReimbursableConfirmModal from "@/components/Modals/ReimbursableConfirmModal";
import FilterData from "./FilterData";
import _ from "lodash";
import Filter from "@/components/Filter";

class ReimbursableList extends Component {

	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.bulkAction = React.createRef();
		
		this.state = {
			data: [],
			filters: null,
			fields: FilterData,
			unpaidReimbursement: '',
			approveOrReject: null
		};
	}

	emptyView() {
		const { classes } = this.props;
		return (
			<div className={classes.infographicMessage}>
				<InfographicMessage
					illustration="reimbursement"
					message="You have no Reimbursement, create your first reimbursement now!"
					actionLabel="Add Reimbursement"
					actionIcon="add"
					actionHandler={() => this.props.history.push("/reimbursements/add")}
				/>
			</div>
		);
	}

	updateData = (res) => {
		this.setState({ data: res.data });
	};

	onSearch = async (filters) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, async () => {
			if(!!oldFilters && this.tableRef && this.tableRef.current) {
				this.tableRef.current.fetchData();
			}
			this.props.setLoader(false);
		});
	};

	reimbursementRejectOrApprove = async (selected, status = "rejected",pay_in_next_payroll = false) => {
		this.props.setLoader(true);
		const unpaid = selected.filter(function (selected) {
			return selected.status !==" paid";
		});
		this.setState({unpaidReimbursement: unpaid})
		try {

			var reimbursements = []
			selected.map((item) => {
				if(item.status !== "paid") {
					reimbursements.push(item.id)
				}
			})

			await Api.reimbursable.put({
				reimbursements,
				status,
				pay_in_next_payroll
			});
			this.props.setLoader(false);

		} catch(e) {
			HttpErrorHandler.intercept(e)
				.on(422,response => {
					this.setState({errors: _.mapValues(response.data, value => value[0])})
					this.props.setSnackbar(true,"Please fix the highlighted errors","error");
				})
				.default(_ => this.props.setSnackbar(true,"Something went wrong, please try again later","error"))
				.go();
		}
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
	};

	reimbursementDelete = async (selected) => {
		this.props.setLoader(true);
		try {
			var reimbursements = []
			selected.map((item) => {
				reimbursements.push(item.id)
			})
			await Api.reimbursable.delete.post({
				reimbursements,
			});
			this.props.setLoader(false);
		} catch(e) {
			HttpErrorHandler.intercept(e)
				.on(422,response => {
					this.setState({errors: _.mapValues(response.data,value => value[0])})
					this.props.setSnackbar(true,"Please fix the highlighted errors","error");
				})
				.default(_ => this.props.setSnackbar(true,"Something went wrong, please try again later","error"))
				.go();
		}
		this.props.setLoader(false);
		if(this.tableRef && this.tableRef.current) {
			this.tableRef.current.fetchData();
		}
		return
	}

	getItems() {
		let items = [];

		items.push({
			name: "Approve",
			action: (_,action) => {action.setState({enableAccountAccessModal: true},this.setState({approveOrReject: Constants.Reimbursable.FLAG_APPROVED}))},
			render: (selected,action) => (
				action.state.enableAccountAccessModal&&
				<ReimbursableConfirmModal
					title = {"Approve Reimbursement"}
					data = {selected}
					confirmText = {"Approve"}
					onValueChange = {(value) => {}}
					hideNextPayroll = {(this.state.approveOrReject === Constants.Reimbursable.FLAG_REJECTED)}
					message = {`Are you sure want to perform this action?`}
					isOpen = {action.state.enableAccountAccessModal}
					onClickConfirm = {(pay_in_next_payroll) => {
						action.setState({enableAccountAccessModal: false,activeOption: null});
						this.reimbursementRejectOrApprove(selected, this.state.approveOrReject, pay_in_next_payroll)
					}}
					onClose = {() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
				/>

			)
		});
		items.push({
			name: "Reject",
			action: (_,action) => {action.setState({enableAccountAccessModal: true},this.setState({approveOrReject: Constants.Reimbursable.FLAG_REJECTED}))},
			render: (selected,action) => (
				action.state.enableAccountAccessModal &&
				<ReimbursableConfirmModal
					title = {"Reject Reimbursement"}
					data = {selected}
					confirmText = {this.state.approveOrReject == "rejected" ? "Reject":null}
					onValueChange = {(value) => {}}
					hideNextPayroll = {(this.state.approveOrReject === Constants.Reimbursable.FLAG_REJECTED)}
					message = {`Are you sure want to perform this action?`}
					isOpen = {action.state.enableAccountAccessModal}
					onClickConfirm = {(pay_in_next_payroll) => {
						action.setState({enableAccountAccessModal: false,activeOption: null});
						this.reimbursementRejectOrApprove(selected,this.state.approveOrReject, pay_in_next_payroll)
					}}
					onClose = {() => {action.setState({enableAccountAccessModal: false, activeOption: null})}}
				/>
			)
		});
		items.push({
			name: "Delete Selected",
			action: (_,action) => action.setState({disableAccountAccessModal: true},this.setState({approveOrReject: Constants.Reimbursable.FLAG_REJECTED})),
			render: (selected,action) => (
				action.state.disableAccountAccessModal&&
				<ReimbursableConfirmModal
					title = {`Delete Reimbursement`}
					confirmText = {`Delete`}
					onValueChange = {(value) => {}}
					hideNextPayroll = {true}
					message = {`Delete`}
					data = {selected}
					isOpen = {action.state.disableAccountAccessModal}
					onClickConfirm = {() => {
						action.setState({disableAccountAccessModal: false, activeOption: null});
						this.reimbursementDelete(selected)
					}
					}
					onClose = {() => {action.setState({disableAccountAccessModal: false, activeOption: null})}}
				/>

			)
		});
		return items;
	}

	render() {
		const {fields, filters} = this.state;
		const {classes} = this.props;

		return (
			<Card raised>
				<Grid container justifyContent="space-between">
					<CardActions style={{padding: 0}}>
						<Link to={{pathname: `/reimbursements/add`}} className={classes.link}>
							<Button
								className={classes.createBtn}
								variant="outlined"
								size="medium"
								color="primary">
								<Icon>add</Icon>
								Add Reimbursement
							</Button>
						</Link>
						<BulkAction
							ref={this.bulkAction}
							options={this.getItems()}
						/>
					</CardActions>
					<CardActions>
						<Filter
							fields={fields}
							filters={
								filters || [{
									field: 'status',
									method: 'is',
									value: 'pending',
									operator: 'and'
								}]
							}
							withQueryParams
							onUpdate={this.onSearch}
						/>
					</CardActions>
				</Grid>
				{
					!!filters &&
					<DLTable
						enableSelection
						ref={this.tableRef}
						api={Api.reimbursable}
						params={{ filter: filters }}
						bulkAction={this.bulkAction}
						onLoad={this.updateData}
						RowTemplate={ReimbursableTableRow}
						emptyView={this.emptyView(true)}
						setLoader={this.props.setLoader}
					/>
				}
			</Card>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(ReimbursableList)));