import React, { Component } from "react";
import Proptypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Fab, Typography, Icon, CircularProgress } from "@material-ui/core";
import Illustration from "../Illustration";
import styles from "./style";

class InfographicMessage extends Component {

    render() {
        const { classes, illustration, message, submessage, actionLabel, actionIcon, actionHandler, containerClass, loader } = this.props;

        var myClassNames;

        if (!containerClass) {
          myClassNames = classes.container
        } else if (containerClass === 'center') {
          myClassNames = classes.container + ' ' + classes.center;
        }

        return (
            <div className={myClassNames}>
                {
                    illustration && 
                    <Illustration type={illustration} />
                }
                <Typography
                    className={classes.message}
                    component="h3">
                    {message}
                </Typography>
                {
                    loader &&
                    <Typography
                        className={classes.message}
                        component="div">
                        <CircularProgress size={30} />
                    </Typography>
                }
                {
                    submessage &&
                    <Typography
                        className={classes.submessage}
                        component="h6">
                        {submessage}
                    </Typography>
                }
                {
                    (actionLabel || actionIcon) && actionHandler &&
                    <Fab
                        className={classes.fab}
                        style={{ minHeight: "34px" }}
                        variant="extended"
                        size="small"
                        color="primary"
                        onClick={actionHandler}>
                        {
                            actionIcon &&
                            <Icon className={classes.fabIcon}>{actionIcon}</Icon>
                        }
                        {actionLabel}
                    </Fab>
                }
            </div>
        );
    }

}

InfographicMessage.propTypes = {
    illustration: Proptypes.string,
    message: Proptypes.node.isRequired,
    actionIcon: Proptypes.string,
    actionLabel: Proptypes.node,
    actionHandler: Proptypes.func,
    containerClass: Proptypes.string
};

export default withStyles(styles)(InfographicMessage);