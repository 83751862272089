import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { ActionableCard, Page, UserRole } from "@/components";
import styles from "./style";
import OrganizationBanner from "@/assets/banners/organization.jpg";
import AttendanceTerminalBanner from "@/assets/banners/zkteco_terminal.jpg";
import BankAccountBanner from "@/assets/banners/bank_account.jpg";
import DepartmentBanner from "@/assets/banners/department.jpg";
import DesignationBanner from "@/assets/banners/designation.jpg";
import PayItemBanner from "@/assets/banners/pay_item.jpg";
import LeaveBanner from "@/assets/banners/leaves.jpg";
import ShiftsBanner from "@/assets/banners/shifts.png";
import AttendanceRules from "@/assets/banners/attendance_rules.png";
import OrganizationDays from "@/assets/banners/organization_days.jpg";
import PlanBanner from "@/assets/banners/plans.png";
import Invoices from "@/assets/banners/invoices.png";
import RolePermissionsBanner from "@/assets/banners/role_permissions.png";
import { AuthContext } from "@/contexts";
import Modules from "@/config/Modules";
import { Permissions } from "@/config/Permissions"
import { AccessManager } from "@/helpers/";

class Settings extends Component {
    static contextType = AuthContext;

    render() {
        const { classes } = this.props;
        return (
            <Page title="Settings">
                <Grid container justify="flex-start" spacing={3}>
                    <UserRole routePermissions={[Permissions.ORGANIZATION_FULL_ACCESS]}>
                        <Grid item xs={12}>
                            <Typography component="div" className={classes.separator}>Organization Settings</Typography>
                        </Grid>
                    </UserRole>

                    <UserRole routePermissions={[
                        Permissions.ORGANIZATION_FULL_ACCESS,
                        Permissions.ORGANIZATION_EDITABLE,
                        Permissions.ORGANIZATION_VIEWABLE]}>
                        <Grid item md={8} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/organization/` + this.context.user.organization_id }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={OrganizationBanner}
                                    title="Organization"
                                    description="Adjust your organization settings including name, address, logo, company registeration and more"
                                    action={() => this.props.history.push("/settings/organization")}
                                />
                            </Link>
                        </Grid>
                    </UserRole>
                    {
                        AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization) &&
                        <UserRole routePermissions={[
                            Permissions.BANK_ACCOUNT_FULL_ACCESS,
                            Permissions.BANK_ACCOUNT_EDITABLE,
                            Permissions.BANK_ACCOUNT_VIEWABLE]}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/accounts` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={BankAccountBanner}
                                        title="Bank Accounts"
                                        description="Manage your company bank accounts that are involved in salary disbursement process"
                                        action={() => this.props.history.push("/settings/accounts")}
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                    <UserRole routePermissions={[
                        Permissions.DESIGNATION_FULL_ACCESS,
                        Permissions.DESIGNATION_EDITABLE,
                        Permissions.DESIGNATION_VIEWABLE]}>
                        <Grid item md={4} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/designations` }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={DesignationBanner}
                                    title="Designations"
                                    description="Manage the list of designations in your organizations"
                                    action={() => this.props.history.push("/settings/designations")}
                                />
                            </Link>
                        </Grid>
                    </UserRole>
                    <UserRole routePermissions={[
                        Permissions.DEPARTMENT_FULL_ACCESS,
                        Permissions.DEPARTMENT_EDITABLE,
                        Permissions.DEPARTMENT_VIEWABLE]}>
                        <Grid item md={4} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/departments` }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={DepartmentBanner}
                                    title="Departments"
                                    description="Manage the list of departments in your organizations"
                                    action={() => this.props.history.push("/settings/departments")}
                                />
                            </Link>
                        </Grid>
                    </UserRole>
                    {
                        AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization) &&
                        <UserRole routePermissions={[
                            Permissions.PAYITEM_FULL_ACCESS,
                            Permissions.PAYITEM_EDITABLE,
                            Permissions.PAYITEM_VIEWABLE]}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/payitems` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={PayItemBanner}
                                        title="Pay Items"
                                        description="Manage salary components like bonuses, increments, re-imbursements and more"
                                        action={() => this.props.history.push("/settings/payitems")}
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                    {
                        AccessManager.isModuleAccess(Modules.ROLE_AND_PERMISSION, this.context.organization) &&
                        <UserRole routePermissions={[
                            Permissions.ROLE_FULL_ACCESS,
                            Permissions.ROLE_EDITABLE,
                            Permissions.ROLE_VIEWABLE]}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/roles` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={RolePermissionsBanner}
                                        title="Roles & Permissions"
                                        description="Manage role & permissions in your organizations"
                                        action={() => this.props.history.push("/settings/roles")}
                                    />
                                </Link>
                            </Grid>
                        </UserRole>
                    }
                </Grid>
                {
                    AccessManager.isModuleAccess(Modules.ATTENDANCE, this.context.organization) &&
                    <UserRole routePermissions={[
                        Permissions.ORGANIZATION_VIEWABLE,
                        Permissions.ORGANIZATION_EDITABLE,
                        Permissions.ORGANIZATION_FULL_ACCESS,
                        Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
                    ]}>
                        <Grid container justify="flex-start" spacing={3} className={classes.section}>
                            <Grid item xs={12}>
                                <Typography component="div" className={classes.separator}>Attendance Settings</Typography>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/attendance/rules` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={AttendanceRules}
                                        title="Attendance Rules"
                                        description="Manage your attendance rules here"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/leaves` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={LeaveBanner}
                                        title="Leave Catagories"
                                        description="Manage the list of leaves categories in your organization"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/shifts` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={ShiftsBanner}
                                        title="Shifts"
                                        description="Manage the list of shifts in your organization"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/holidays` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={OrganizationDays}
                                        title="Holidays"
                                        description="Manage your organization holidays"
                                    />
                                </Link>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Link to={{ pathname: `/settings/attendance/terminals` }} style={{ textDecoration: 'none', color: "primary" }}>
                                    <ActionableCard
                                        image={AttendanceTerminalBanner}
                                        title="Attendance Terminals"
                                        description="Connect your ZKTeco attendance machines for realtime attendance records"
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                    </UserRole>
                }
                <UserRole routePermissions={[ Permissions.BILLING_FULL_ACCESS ]}>
                    <Grid container justify="flex-start" spacing={3} className={classes.section}>
                        <Grid item xs={12}>
                            <Typography component="div" className={classes.separator}>Billing</Typography>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/invoices` }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={Invoices}
                                    title="Invoices"
                                    description="Monthly invoices for subscription"
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </UserRole>
                <UserRole routePermissions={[Permissions.ORGANIZATIONS_FULL_ACCESS]}>
                    <Grid container justify="flex-start" spacing={3} className={classes.section}>
                        <Grid item xs={12}>
                            <Typography component="div" className={classes.separator}>System Settings</Typography>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/banks` }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={BankAccountBanner}
                                    title="Banks"
                                    description="Manage list of banks, which is to be used by all organizations"
                                />
                            </Link>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Link to={{ pathname: `/settings/plans` }} style={{ textDecoration: 'none', color: "primary" }}>
                                <ActionableCard
                                    image={PlanBanner}
                                    title="Plans"
                                    description="Manage list of plans"
                                />
                            </Link>
                        </Grid>
                       
                    </Grid>
                </UserRole>
            </Page>
        );
    }

}
export default withRouter(withStyles(styles)(Settings));
