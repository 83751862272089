import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import _ from "lodash";

class Tab extends Component {
	
	static propTypes = {
		label: PropTypes.string.isRequired,
		icon: PropTypes.node,
		path: PropTypes.string,
		tabProps: PropTypes.object,
		children: PropTypes.node,
		tabs: PropTypes.object
	};

	constructor(props) {
		super(props);
		if (!_.isNil(props.tabs) && !_.isNil(props.tab)) {
			props.tabs.activateTab(props.tab);
		}
	}

	shouldComponentUpdate() {
		return false;
	}
	
	render() {
		const { children } = this.props;
		return (
			<Typography component="div" style={{ overflowX: "hidden" }}>
				{children}
			</Typography>
		);	
	}
}

export default Tab;