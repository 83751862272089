const style = theme => ({
	
	switch: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: 0
	},
	textFeildLoaderIndicator: {
		right: "34px",
		position: "absolute",
	}

});

export default style;