import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Switch,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    ButtonGroup,
    Divider,
    InputAdornment,
    Typography,
    Tooltip,
    Icon
} from "@material-ui/core";
import { AccessManager, Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";
import styles from "./style";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormattedNumericInput } from "@/components";
import { Clear } from "@material-ui/icons";
import { Modules } from "@/config";
import { AuthContext } from "@/contexts";
import { FormLabel } from "react-bootstrap";


class ShiftAdd extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            id: "",
            name: "",
            working_hours: 1,
            start_time: "09:10:00",
            end_time: "17:00:00",
            grace_time: 10,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            is_auto_assign: false,
            is_overtime_enabled: false,
            overtime_grace: 30,
            overtime_multiplier: 1.5,
            title: props.title || "Add Shifts"
        };
    }

    async componentDidMount() {
        if ((this.props.match.params && this.props.match.params.id > 0) && !this.props.avoidRoute) {
            this.props.setLoader(true);
            this.setState({ isLoading: true, isEdit: true });
            let response = await Api.shifts(this.props.match.params.id).get();
            this.setState({ ...response }, () => {
                this.props.setLoader(false);
            });
        }
    }

    handleChange = (val, fieldName) => {
        this.setState({ [fieldName]: val })
    };

    shifts() {
        return {
            name: this.state.name,
            working_hours: this.state.working_hours,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            grace_time: this.state.grace_time,
            is_auto_assign: this.state.is_auto_assign,
            monday: this.state.monday,
            tuesday: this.state.tuesday,
            wednesday: this.state.wednesday,
            thursday: this.state.thursday,
            friday: this.state.friday,
            saturday: this.state.saturday,
            sunday: this.state.sunday,
            is_overtime_enabled: this.state.is_overtime_enabled,
            overtime_grace: this.state.overtime_grace,
            overtime_multiplier: this.state.overtime_multiplier
        };
    }

    validate() {
        const shifts = this.shifts();
        const optionalFields = ["monday", "tuesday", "wednesday", "thursday", "friday", "sunday", "saturday", "is_auto_assign", "grace_time", "working_hours", "is_overtime_enabled", "overtime_grace", "overtime_multiplier"];

        var errors = {};
        _.forOwn(shifts, (value, key) => {
            if (optionalFields.indexOf(key) === -1 && !value) {
                errors[key] = `${_.startCase(key)} field is required`;
            }
        });
        if (_.size(errors)) {
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
        }
        this.setState({ errors });

        return !_.size(errors);

    }

    createShiftItem = async () => {
        if (this.validate()) {
            this.props.setLoader(true);

            var booleanFields = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "is_auto_assign", "is_overtime_enabled"];
            var data = new FormData();

            data.append("name", this.state.name);
            data.append("working_hours", this.state.working_hours || 0);
            data.append("start_time", this.state.start_time);
            data.append("end_time", this.state.end_time);
            data.append("grace_time", this.state.grace_time || 0);
            data.append("overtime_grace", this.state.overtime_grace || 0);
            data.append("overtime_multiplier", this.state.overtime_multiplier || 0);
            booleanFields.forEach(field => {
                data.append(field, this.state[field] ? 1 : 0);
            });

            try {
                if (!this.state.id) {
                    await Api.shifts.post(data);
                } else {
                    data.append("_method", "put");
                    await Api.shifts(this.state.id).post(data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    );
                }
                this.props.setSnackbar(true, this.state.id ? "Shifts updated" : "Shifts Added");
                this.props.onUpdate();
                this.props.setLoader(false);
                this.closeDialog();
            }
            catch (e) {
                HttpErrorHandler.intercept(e)
                    .on(422, response => {
                        console.log({ response })
                        this.setState({ errors: _.mapValues(response.data, value => value[0]) },()=>{console.log(this.state.errors)})
                        this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
                    })
                    .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
                    .go();
            }
            this.props.setLoader(false);
        }


    }

    closeDialog = () => {
        if (this.props.avoidRoute) {
            this.props.onClose()
        } else if (this.props.backRoute === "goback") {
            this.props.history.goBack();
        } else {
            this.props.history.push(this.props.backRoute)
        }
    }

    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={"new"}
				aria-labelledby="version-dialog-title"
                onClose={this.props.onClose}
            >
                <DialogTitle id="version-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent dividers>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name}
                                placeholder="Name"
                                label="Name"
                                value={this.state.name}
                                onChange={(e) => this.handleChange(e.target.value, "name")}
                                variant="outlined"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel><Typography component="span">Working Days</Typography></FormLabel>
                            <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.workingDays}>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.monday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.monday, "monday")}>
                                    M
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.tuesday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.tuesday, "tuesday")}>
                                    T
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.wednesday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.wednesday, "wednesday")}>
                                    W
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.thursday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.thursday, "thursday")}>
                                    T
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.friday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.friday, "friday")}>
                                    F
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.saturday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.saturday, "saturday")}>
                                    S
                                </Button>
                                <Button
                                    className={classes.workingDayButton}
                                    variant={this.state.sunday ? 'contained' : 'outlined'}
                                    onClick={() => this.handleChange(!this.state.sunday, "sunday")}>
                                    S
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.working_hours}
                                helperText={errors.working_hours}
                                placeholder="Required Working Hours"
                                label="Required Working Hours"
                                value={this.state.working_hours}
                                onChange={(e) => this.handleChange(e.target.value, "working_hours")}
                                variant="outlined"
                                size={"small"}
                                InputProps={{
                                    endAdornment: (<
                                    InputAdornment position="end">
                                            <Typography component="span" style={{ marginRight: "2px" }}>Hours</Typography>
                                            <Tooltip title="Working hours of employees below the required working hours will be considered as Half Day">
                                                <Icon color="primary" fontSize="small">helpoutlineoutlined</Icon>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                    inputComponent: FormattedNumericInput
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.duration}
                                helperText={errors.duration}
                                placeholder="Start Time"
                                label="Start Time"
                                value={moment(this.state.start_time, "HH:mm:ss").format("HH:mm")}
                                onChange={(e) => {
                                    this.handleChange(moment(e.target.value, "hh:mm a").format("HH:mm:ss"), "start_time")
                                }}
                                type={"time"}
                                variant="outlined"
                                size={"small"}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.duration}
                                helperText={errors.duration}
                                placeholder="End Time"
                                label="End Time"
                                value={moment(this.state.end_time, "HH:mm:ss").format("HH:mm")}
                                onChange={(e) => {
                                    this.handleChange(moment(e.target.value, "hh:mm a").format("HH:mm:ss"), "end_time")
                                }}
                                type={"time"}
                                variant="outlined"
                                size={"small"}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: "5px" }}
                                fullWidth
                                error={!!errors.grace_time}
                                helperText={errors.grace_time}
                                // placeholder="Enter number of minutes"
                                label="Late After"
                                value={this.state.grace_time}
                                onChange={(e) => this.handleChange(e.target.value, "grace_time")}
                                variant="outlined"
                                size={"small"}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">min{this.state.grace_time == 1 ? '' : 's'}</InputAdornment>),
                                    inputComponent: FormattedNumericInput
                                }}
                            />
                        </Grid>
                        
                        {
                            AccessManager.isModuleAccess(Modules.OVERTIME, this.context.organization) &&
                            <>
                                <Divider className={classes.divider} />
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        className={classes.labelPlacementStart}
                                        labelPlacement="start"
                                        label="Enable Overtime"
                                        control={
                                            <Switch
                                                checked={this.state.is_overtime_enabled}
                                                onChange={() => this.handleChange(!this.state.is_overtime_enabled, "is_overtime_enabled")}
                                                value="checkedB"
                                                color="primary"
                                                size={"small"}
                                            />
                                        }
                                    />
                                </Grid>

                                {
                                    this.state.is_overtime_enabled &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ marginTop: "5px" }}
                                                fullWidth
                                                error={!!errors.overtime_grace}
                                                helperText={errors.overtime_grace}
                                                label="Overtime Threshold"
                                                value={this.state.overtime_grace}
                                                onChange={(e) => this.handleChange(e.target.value, "overtime_grace")}
                                                variant="outlined"
                                                size={"small"}
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">min{this.state.overtime_grace == 1 ? '' : 's'}</InputAdornment>),
                                                    inputComponent: FormattedNumericInput
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ marginTop: "5px" }}
                                                fullWidth
                                                error={!!errors.overtime_multiplier}
                                                helperText={errors.overtime_multiplier}
                                                label="Overtime Multiplier"
                                                value={this.state.overtime_multiplier}
                                                onChange={(e) => this.handleChange(e.target.value, "overtime_multiplier")}
                                                variant="outlined"
                                                size={"small"}
                                                InputProps={{ inputComponent: FormattedNumericInput }}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Divider className={classes.divider} />
                            </>
                        }

                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.labelPlacementStart}
                                labelPlacement="start"
                                label="Assign automatically to the new employees"
                                control={
                                    <Switch
                                        checked={this.state.is_auto_assign}
                                        onChange={() => this.handleChange(!this.state.is_auto_assign, "is_auto_assign")}
                                        value="checkedB"
                                        color="primary"
                                        size={"small"}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={() => { this.closeDialog(); }} color="default">Cancel</Button>
                    <Button size="small" variant="contained" onClick={this.createShiftItem} color="primary">Save</Button>
                </DialogActions>

            </Dialog>
        )
    
    }
}
const mapDispatchToProps = (dispatch) => ({
    dispatch: (func) => dispatch(func),
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args)),
});



export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(ShiftAdd)));
