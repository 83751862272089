export default theme => ({

    document: {
        width: "100%"
    },

    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        padding: 20
    },

    documentPage: {
        boxShadow: theme.shadows[2],
        borderRadius: 5,
        overflow: "hidden",
        height: "auto !important",

        "&:not(:first-child)": {
            marginTop: theme.spacing(2)
        },

        "& .react-pdf__Page__svg": {
            width: "100% !important",
            height: "auto !important"
        },

        "& svg": {
            width: "100%",
            height: "auto !important"
        }
    }

});