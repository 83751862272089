import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";
import _ from "lodash";

class EmployeePayItemDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: "",
            options: [],
            payItem: null
        }
    }
    static defaultProps = {
        label: "Pay Item",
        filter: {},
        onChange: () => { }
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func,
        filter: PropTypes.object,
        employee: PropTypes.number,
        getSelected: PropTypes.func
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.employee.id !== this.props.employee.id) {
            this.setState({ employee: this.props.employee });
            this.loadOptions();
        }

        if ((prevProps.employee !== this.props.employee || this.props.value !== prevProps.value) && (!this.state.payItem || (this.state.payItem.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value && this.props.employee) {
            try {
                const response = await Api.employees(this.props.employee).pay_items.get();
                var payItem = _.find(response.data, (item) => item.details.id === this.props.value);
                if (this.props.getSelected) 
                    this.props.getSelected(payItem);
                return this.setState({ payItem: payItem });
            } catch (e) { }
        }
        this.setState({ payItem: null });
    }

     loadEmployeePayitem= async () => {
        this.setState({ loading: true });

        let response = await Api.employees(this.props.employee).pay_items.get({ filter: this.props.filter });
        response.data = _.filter(response.data, (item) => item.details.is_reimbursable);
        return response.data;
    };

    onChange = (event, payItem) => {
        this.setState({ payItem });
        this.props.onChange((payItem) || null);
    };

    renderOption = option => {
        return (
            <div className={this.props.classes.itemStyle}>
                {option.details.name}
            </div>
        )
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        const { payItem } = this.state;
        return (
            <>
                <AsyncDropdown
                    classes={{ root: classes.dropdown, paper: classes.dropdown }}
                    label={payItem ? "" : label}
                    textFieldProps={textFieldProps}
                    getOptions={this.loadEmployeePayitem}
                    getOptionLabel={option => option.details.name}
                    getOptionSelected={(option, value) => {
                        if (value && value.details) {
                            return option.details.id === value.details.id;
                        } else {
                            return false;
                        }
                    }}
                    onChange={this.onChange}
                    value={payItem}
                    disabled={!!disabled}
                    renderOption={this.renderOption}
                />
            </>
        );
    }

}

export default withStyles(styles)(EmployeePayItemDropdown);