import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Card,
	CardActions,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import { DateRange, DLTable, BackButton, InfographicMessage, Page } from "@/components";
import TaxStatementRow from "./TaxStatementRow";
import { Api } from "@/helpers";
import styles from "./style";

class TaxStatement extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.state = {
			from: null,
			to: null,
			anchorEl: null
		};
	}

	exportInIrisFormat = () => {
		const { from, to } = this.state;
		this.setState({ anchorEl: null });
		window.open(Api.reports.tax_statement.export.iris.getUrl({
			from: from.format("YYYY-MM-DD"),
			to: to.format("YYYY-MM-DD")
		}))
	};

	reload = () => {
		this._table && this._table.fetchData(true);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="tax"
				message="No tax deduction found in selected period!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, to } = this.state;
		return (
			<Page title="Tax Statement">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<div className={classes.taxReportFilter}>
							<DateRange
								onChange={(from, to) => this.setState({ from, to }, this.reload)} />
						</div>
						<IconButton
							onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
							<Icon>more_vert</Icon>
						</IconButton>
						<Menu
							id="long-menu"
							anchorEl={this.state.anchorEl}
							open={!!this.state.anchorEl}
							onClose={() => this.setState({ anchorEl: null })}
							PaperProps={{
								style: {
									width: 200,
								},
							}}>

							<MenuItem onClick={this.exportInIrisFormat}>Export in IRIS Format</MenuItem>
						</Menu>
					</CardActions>
					{
						from && to &&
						<DLTable
							ref={ref => this._table = ref}
							api={Api.reports.tax_statement}
							params={{ from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") }}
							RowTemplate={TaxStatementRow}
							emptyView={this.emptyView()} />
					}
				</Card>
			</Page>
		);
	}

}

export default withRouter(withStyles(styles)(TaxStatement));