import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Icon,
    Menu,
    MenuItem,
    CardActions
} from "@material-ui/core";
import moment from "moment";
import { InfographicMessage, DLTable } from "@/components";
import EmployeeLeaveTableRow from "./EmployeeLeaveTableRow";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import _ from "lodash";

class Employeeleaves extends Component {
    constructor (props) {
        super(props);

        this.state = {
            anchorEl: null,
            leaves: {}
        };

        this.getLeave();
    }

    _table = null;

    getLeave = async () => {
        let leaves = await Api.leaves.get({ per_page: 0, filter: { employee_id: this.props.employee.id } });
        this.setState({ leaves: leaves.data });
    };
    emptyView() {
        return (
            <InfographicMessage
                illustration="leaves"
                message={"You don't have any Leaves yet"}
                 />
        );
    }
    
    addLeaves = async leave => {
        this.setState({ anchorEl: null });
        const { employee } = this.props;
        this.props.setLoader(true);
        try {
            await Api.employees(employee.id).employee_leaves.post({ leave_id: leave.id, start_date: moment().format("YYYY-MM-DD"), quantity: leave.quantity });
            this._table && this._table.fetchData();
            this.props.setSnackbar(true, "New Leave added successfully");
            this.getLeave();
        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    if (errors && errors.length) {
                        this.props.setSnackbar(true, errors[0], "error");
                    } else {
                        this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                    }
                })
                .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                .go();
        }
        this.props.setLoader(false);
    };

    render() {
        const { employee } = this.props;
        const { anchorEl, leaves } = this.state;

        return (
            <>
                {
                    _.size(leaves) > 0 &&
                    <CardActions>
                        <Button variant="outlined" size="medium"
                            aria-owns={anchorEl ? "long-menu" : undefined}
                            aria-haspopup="true"
                            color="primary"
                            onClick={(e) => { this.setState({ anchorEl: e.currentTarget }) }}
                        >
                            Add Leaves &nbsp; <Icon>keyboard_arrow_down</Icon>
                        </Button>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            PaperProps={{
                                style: {
                                    minWidth: 190,
                                },
                            }}
                            onClose={() => this.setState({ anchorEl: null })}
                        >
                            {
                                _.map(leaves, (item, index) => (
                                    <MenuItem key={index} onClick={() => this.addLeaves(item)}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                    </CardActions>
                }
                <DLTable
                    ref={table => this._table = table}
                    api={Api.employees(employee.id).employee_leaves}
                    emptyView={this.emptyView()}
                    RowTemplate={EmployeeLeaveTableRow}
                    rowProps={{ employee, getLeave: this.getLeave }}
                />
            </>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    setLoader: (...args) => dispatch(setLoader(...args)),
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(Employeeleaves);
