import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Fab, Icon } from "@material-ui/core";
import classNames from "classnames";
import styles from "./style";

class BackButton extends Component {

	render() {
		const { classes, label = null, dense = false, route } = this.props;
        return (
            <Link to={{ pathname: route }} style={{ textDecoration: 'none', color: "primary" }}>
                <Fab
                    className={classNames([classes.backFab, !dense && classes.withMargin])}
                    variant="extended"
                    size="small"
                >
                    <Icon className={classes.extendedFabIcon}>arrow_back</Icon>
                    {
                        label &&
                        <span>{label}</span>
                    }
                </Fab>
            </Link>
        );
    }

}

export default withRouter(withStyles(styles)(BackButton));