import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@material-ui/core';

class ConfirmModal extends Component {

    static defaultProps = {
		isOpen: false,
		title: null,
		
	};
    static propTypes = {
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onClose: PropTypes.func,
        onClickCancel: PropTypes.func,
        onClickConfirm: PropTypes.func
	};

	render() {
		const {
			isOpen,
			title,
			message,
			onClose,
			onClickCancel,
			onClickConfirm,
			disabled
		} = this.props;
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="xs"
				onClose={onClose || onClickCancel}
				onBackdropClick={onClose}
				aria-labelledby="confirmation-title"
				aria-describedby="confirmation-description"
			>
				<DialogTitle id="confirmation-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText color="textPrimary">
						{message}{this.props.isOverWrite}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={disabled}
						size={"small"}
						onClick={onClose || onClickCancel}
						color="default">
						Cancel
					</Button>
					<Button
						disabled={disabled}
						size={"small"}
						variant="contained"
						onClick={onClickConfirm}
						color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

// ConfirmModal.propTypes = {
// 	title: PropTypes.string,
// 	isOpen: PropTypes.bool,
// 	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
// 	onClickCancel: PropTypes.func,
// 	onClickConfirm: PropTypes.func
// };

export default ConfirmModal;