import {combineReducers} from 'redux';
import general from './general.js'
import payitem from './payitemReducer';
import bankAccount from './bankAccountReducer';
import departments from './departmentReducer';
import designations from './designationReducer';
import bank from "./bankReducer";
import auth from "./authReducer";;
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//     key: 'store',
//     storage: storage,
// }

export default combineReducers({
    auth,
    general,
    bank,
    payitem,
    bankAccount,
    departments,
    designations
});