export default theme => ({

    backdrop: {
        zIndex: theme.zIndex.appBar + 1,
        margin: '0 !important'
    },

    searchbarContainer: {
        position: 'relative',
        '&:focus-within': {
            position: 'sticky',
            zIndex: theme.zIndex.appBar + 2
        }
    },

    searchbar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        paddingTop: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        columnGap: theme.spacing(1),

        '&:hover': {
            borderColor: '#000000'
        },

        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },

    filterChip: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5)
    },

    searchField: {
        flex: 1,
        minWidth: '300px',
        
        "& input": {
            margin: 0,
            paddingTop: theme.spacing(0.5),
            paddingRight: 0,
            paddingBottom: theme.spacing(0.5),
            paddingLeft: 0,
            minWidth: '300px',
            height: theme.spacing(4)
        }
    },

    popper: {
        zIndex: theme.zIndex.appBar + 2
    },

    dropdown: {
        maxHeight: 425,
        overflowY: 'auto',
        boxShadow: 'none',
        border: `1px ${theme.palette.primary.main} solid`,
        borderTop: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: theme.spacing(0.5)
    }

});