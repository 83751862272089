import Modules from "@/config/Modules";

export const Permissions = {
	EMPLOYEES_VIEWABLE: "viewable_employees",
	EMPLOYEES_FULL_ACCESS: "full_access_employees",
	
	PROFILE_EDITABLE: "editable_profile",

	ATTENDANCE_EDITABLE: "editable_attendance",

	EMPLOYEES_ATTENDANCE_VIEWABLE: "viewable_employees_attendance",
	EMPLOYEES_ATTENDANCE_EDITABLE: "editable_employees_attendance",
	EMPLOYEES_ATTENDANCE_FULL_ACCESS: "full_access_employees_attendance",

	PAYROLL_VIEWABLE: "viewable_payroll",
	PAYROLL_EDITABLE: "editable_payroll",
	PAYROLL_FULL_ACCESS: "full_access_payroll",

	TAX: "tax",
	SALARY_LEDGER_REPORT: "salary_ledger_reports",

	BANK_ACCOUNT_VIEWABLE: "viewable_bank_accounts",
	BANK_ACCOUNT_EDITABLE: "editable_bank_accounts",
	BANK_ACCOUNT_FULL_ACCESS: "full_access_bank_accounts",

	ORGANIZATION_VIEWABLE: "viewable_organization",
	ORGANIZATION_EDITABLE: "editable_organization",
	ORGANIZATION_FULL_ACCESS: "full_access_organization",

	DEPARTMENT_VIEWABLE: "viewable_department",
	DEPARTMENT_EDITABLE: "editable_department",
	DEPARTMENT_FULL_ACCESS: "full_access_department",

	DESIGNATION_VIEWABLE: "viewable_designation",
	DESIGNATION_EDITABLE: "editable_designation",
	DESIGNATION_FULL_ACCESS: "full_access_designation",

	PAYITEM_VIEWABLE: "viewable_payitem",
	PAYITEM_EDITABLE: "editable_payitem",
	PAYITEM_FULL_ACCESS: "full_access_payitem",

	ROLE_VIEWABLE: "viewable_role",
	ROLE_EDITABLE: "editable_role",
	ROLE_FULL_ACCESS: "full_access_role",

	LOAN_FULL_ACCESS: "full_access_loan",
	
	COMPANY_DASHBOARD: "view_company_dashboard",

	EMPLOYEE_DASHBOARD: "view_employee_dashboard",

	ORGANIZATIONS_FULL_ACCESS: "full_access_organizations",
	ORGANIZATIONS_VIEW_ACCESS: "view_access_organizations",

	DOCUMENT_FULL_ACCESS: "full_access_documents",
	DOCUMENT_EDITABLE_ACCESS: "editable_access_documents",
	DOCUMENT_VIEWABLE_ACCESS: "view_access_documents",

	BILLING_FULL_ACCESS: "full_access_billing",
}
export const PermissionsInfo = {
	"profile": [
		{
			label: "Editable",
			value: Permissions.PROFILE_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
	],
	"attendance": [
		{
			label: "Editable",
			value: Permissions.ATTENDANCE_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.ATTENDANCE
			]
		}
	],
	"company_dashboard": [
		{
			label: "Company Dashboard",
			value: Permissions.COMPANY_DASHBOARD,
			tooltip: "View Company Dashboard",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"employee_dashboard": [
		{
			label: "Employee Dashboard",
			value: Permissions.EMPLOYEE_DASHBOARD,
			tooltip: "View Employee Dashboard",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"employees": [
		{
			label: "Viewable",
			value: Permissions.EMPLOYEES_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY
			]
		},
		{
			label: "Full Access",
			value: Permissions.EMPLOYEES_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY
			]
		}
	],
	"employees_attendance": [
		{
			label: "Viewable",
			value: Permissions.EMPLOYEES_ATTENDANCE_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.ATTENDANCE
			]
		},
		{
			label: "Editable",
			value: Permissions.EMPLOYEES_ATTENDANCE_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.ATTENDANCE
			]
		},
		{
			label: "Full Access",
			value: Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.ATTENDANCE
			]
		}
	],
	"payroll": [
		{
			label: "Viewable",
			value: Permissions.PAYROLL_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Editable",
			value: Permissions.PAYROLL_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Full Access",
			value: Permissions.PAYROLL_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.PAYROLL
			]
		}
	],
	"bank_account": [
		{
			label: "Viewable",
			value: Permissions.BANK_ACCOUNT_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Editable",
			value: Permissions.BANK_ACCOUNT_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Full Access",
			value: Permissions.BANK_ACCOUNT_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.PAYROLL
			]
		}
	],
	"tax_report": [
		{
			label: "Tax Report",
			value: Permissions.TAX,
			tooltip: "View",
			module: [
				Modules.TAX
			]
		}
	],
	"salary_report": [
		{
			label: "Salary Ledger",
			value: Permissions.SALARY_LEDGER_REPORT,
			tooltip: "View",
			module: [
				Modules.PAYROLL
			]
		}
	],
	"organization": [
		{
			label: "Viewable",
			value: "viewable_organization",
			tooltip: "View",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Editable",
			value: "editable_organization",
			tooltip: "View, Add, Edit",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Full Access",
			value: "full_access_organization",
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"department": [
		{
			label: "Viewable",
			value: Permissions.DEPARTMENT_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Editable",
			value: Permissions.DEPARTMENT_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Full Access",
			value: Permissions.DEPARTMENT_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"designation": [
		{
			label: "Viewable",
			value: Permissions.DESIGNATION_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Editable",
			value: Permissions.DESIGNATION_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Full Access",
			value: Permissions.DESIGNATION_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"document": [
		{
			label: "Editable",
			value: Permissions.DOCUMENT_EDITABLE_ACCESS,
			tooltip: "Edit",
			module: [
				Modules.DOCUMENT
			]
		},
		{
			label: "Full Access",
			value: Permissions.DOCUMENT_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.DOCUMENT
			]
		},
		{
			label: "View Access",
			value: Permissions.DOCUMENT_VIEWABLE_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.DOCUMENT
			]
		}
	],
	"payitem": [
		{
			label: "Viewable",
			value: Permissions.PAYITEM_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Editable",
			value: Permissions.PAYITEM_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.PAYROLL
			]
		},
		{
			label: "Full Access",
			value: Permissions.PAYITEM_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.PAYROLL
			]
		}
	],
	"role": [
		{
			label: "Viewable",
			value: Permissions.ROLE_VIEWABLE,
			tooltip: "View",
			module: [
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Editable",
			value: Permissions.ROLE_EDITABLE,
			tooltip: "View, Add, Edit",
			module: [
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Full Access",
			value: Permissions.ROLE_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"loan": [
		
		{
			label: "Full Access",
			value: Permissions.LOAN_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"super_organization": [
		{
			label: "Viewable",
			value: Permissions.ORGANIZATIONS_VIEW_ACCESS,
			tooltip: "View",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		},
		{
			label: "Full Access",
			value: Permissions.ORGANIZATIONS_FULL_ACCESS,
			tooltip: "View, Add, Edit, Delete",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	],
	"billing": [
		{
			label: "Full Access",
			value: Permissions.BILLING_FULL_ACCESS,
			tooltip: "Manage your subscription and access invoices",
			module: [
				Modules.BRANCH,
				Modules.PAYROLL,
				Modules.ATTENDANCE,
				Modules.EMPLOYEE_HISTORY,
				Modules.ROLE_AND_PERMISSION
			]
		}
	]
}
export default {
	Permissions,
	PermissionsInfo
}