import Proptypes from "prop-types";
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Place } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import { withStyles } from "@material-ui/core/styles";

import styles from "./style";
import MapStyle from "./mapStyle";
import SearchBox from "./searchBox";

const MAP = {
	defaultZoom: 8,
	options: {
		fullscreenControl: false,
		styles: MapStyle,
		maxZoom: 19,
	},
};

class LocationMap extends Component {

	static defaultProps = {
		isNew: true,
		branch: {
			latitude: 24.85933348138765, 
			longitude: 67.05993291629923,
			zoom: 16
		},
		onPlacesChanged: () => {},
		onBoundsChange: () => {}
	};

	static propTypes = {
		branch: Proptypes.object,
		onPlacesChanged: Proptypes.func,
		onBoundsChange: Proptypes.func,
		isNew: Proptypes.bool
	};

	constructor (props) {
		super(props);
		this.state = {
			apiReady: false,
			googleMaps: null,
			map: null
		};
	}

	apiHasLoaded = (map, googleMaps) => {
		if (map && googleMaps) {
			this.setState({
				apiReady: true,
				map: map,
				googleMaps: googleMaps
			});
		}
	}

	render () {
		const { onPlacesChanged, isNew, branch, onBoundsChange } = this.props;
		const { apiReady, googleMaps } = this.state;

		const initialCenter = isNew ? { lat: 24.85933348138765, lng: 67.05993291629923 } : { lat: parseFloat(branch.latitude), lng: parseFloat(branch.longitude) };
		const latitude = isNew && !branch.latitude ? initialCenter.lat : parseFloat(branch.latitude);
		const longitude = isNew && !branch.longitude ? initialCenter.lng : parseFloat(branch.longitude);

		return (
			<>
				{
					apiReady &&
						<SearchBox
							placeholder="Search"
							googleMaps={googleMaps}
							onPlacesChanged={onPlacesChanged}
						/>
				}

				<Grid item xs={12} style={{ paddingTop: 0 }}>
					<div style={{ position: "relative", height: '300px', width: '100%' }}>
						<GoogleMapReact
							options={MAP.options}
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
							defaultCenter={initialCenter}
							defaultZoom={branch.zoom}
							mapTypeControl
							center={{ lat: latitude, lng: longitude }}
							bootstrapURLKeys={{ key: 'AIzaSyAdnanGhf1IhukOCC06xS5FnzGwCkep3TQ' }}
							onBoundsChange={onBoundsChange}
						>
						</GoogleMapReact>
						<Place
							color="secondary"
							fontSize={"large"}
							style={{ position: "absolute", zIndex: 1, top: "50%", left: "50%", transform: "translate(-50%, -100%)" }} 
						/>
					</div>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(LocationMap);
