import Types from "@/components/Filter/types";
import { Constants } from "@/config";
import { Api } from "@/helpers";

const data = [
	{
		key: "employee_name",
		label: "Employee Name",
		type: Types.string,
		example: 'Ahmed'
	},
	{
		key: "employee.code",
		label: "Employee Code",
		type: Types.string,
		example: 'EMP-001'
	},
	{
		key: "employee.work_email",
		label: "Work Email",
		type: Types.string,
		example: 'ahmed@company.com'
	},
	{
		key: "code",
		label: "Request Code",
		type: Types.string,
		example: 'cf4g0n'
	},
	{
		key: "pay_item_id",
		label: "Pay Item",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.pay_items.get();
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: "date",
		label: "Expense Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "created_at",
		label: "Request Date",
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: "requested_amount",
		label: "Requested Amount",
		type: Types.number,
		example: 3450
	},
	{
		key: "status",
		label: "Status",
		type: Types.selection,
		options: [
			{
				key: Constants.Reimbursable.FLAG_APPROVED,
				label: "Approved"
			},
			{
				key: Constants.Reimbursable.FLAG_REJECTED,
				label: "Rejected"
			},
			{
				key: Constants.Reimbursable.FLAG_PENDING,
				label: "Pending"
			},
			{
				key: Constants.Reimbursable.FLAG_PAID,
				label: "Paid"
			},
		]
	}
];

export default data;