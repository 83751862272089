import React from "react";
import { withRouter } from "react-router-dom";
import { currencyFormatter } from "@/helpers/generalHelper";
import { DLTableRow, DLTableColumn } from "@/components";
import moment from "moment";

class TaxStatementTableRow extends DLTableRow {

    renderCells() {
        const { data } = this.props;
        return [
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-1`}
                withSeparator={true}>
                {data.code}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-2`}
                withSeparator={true}>
                {data.name}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-3`}>
                {data.cnic}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-4`}>
                {data.ntn}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-5`}
                align={"right"}>
                {currencyFormatter(data.total_amount)}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-6`}
                align={"right"}>
                {currencyFormatter(data.tax)}
            </DLTableColumn>,
            <DLTableColumn
                key={`TaxStatement-${data.id}-Column-7`}
                align={"left"}>
                {data.transaction_date ? moment(data.transaction_date).format("DD-MM-YYYY") : "N/A"}
            </DLTableColumn>
        ];
    }

}

TaxStatementTableRow.Columns = [
    {
        key: "code",
        label: "Code",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "name",
        label: "Name",
        align: "left",
        isSortable: true,
        withSeparator: true
    },
    {
        key: "cnic",
        label: "CNIC",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "ntn",
        label: "NTN",
        align: "left",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "total_amount",
        label: "Taxable Amount",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "tax",
        label: "Tax",
        align: "right",
        isSortable: true,
        withSeparator: false
    },
    {
        key: "transaction_date",
        label: "Last Transaction Date",
        align: "left",
        isSortable: true,
        withSeparator: false
    }
];

export default withRouter(TaxStatementTableRow);