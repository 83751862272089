import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles"
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import styles from './style.js';

class ReminderNotificationChip extends Component {

    render() {
        const { count, classes, onClick } = this.props;
        return (
            <Chip
                label={count}
                icon={<NotificationsActive/>}
                className={classes.container}
                size="medium"
                onClick={onClick}
            />
        );
    }
}

ReminderNotificationChip.propTypes = {
    count: PropTypes.number.isRequired
}

export default withStyles(styles)(ReminderNotificationChip);
