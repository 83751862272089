import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Icon, Typography, Button, Grid, TablePagination } from "@material-ui/core";
import { Stepper, EmployeeChip, InfographicMessage } from "@/components";
import { Api, HttpErrorHandler } from "@/helpers";
import Config from "@/config";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import moment from "moment";
import styles from "./style";
import DLTablePaginationActions from "@/components/DLTable/DLTablePaginationActions";
import { AuthContext } from "@/contexts";
import FilterData from "@/pages/Employee/FilterData";
import Filter from "@/components/Filter";

class EmployeeSelection extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			search: "",
			employees: [],
			selectedEmployees: (props.payrun && props.payrun.id) ? _.map(props.payrun.payslips, payslip => payslip.employee_id) : [],
			removedEmployees: [],
			isLoading: true,
			meta: {
				current_page: 0,
				per_page: 52
			},
			filters: [],
			fields: FilterData,
			getEmployees: this.getEmployees
		};
	}

	componentDidMount() {
		const { payrun } = this.props;

		if (payrun && payrun.id) {
			this.getEmployees();
		}

		this.setupBranchFilter();
	}

	getEmployees = async () => {
		const { filters } = this.state;
		const { payrun, stepper } = this.props;
		const from = payrun.from || stepper.getData(1).from;
		const to = payrun.to || stepper.getData(1).to;

		var generalFilter = [{
			field: "available_for_payrun",
			value: [moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD")],
			method: "is"
		}];

		if (this.getPayrollEmployees() > 0) {
			generalFilter.push({
				field: "employees",
				value: this.getPayrollEmployees(),
				method: "is"
			});
		}

		const newFilter = _.concat(filters, generalFilter);

		const { meta } = this.state;
		try {
			const response = await Api.employees.get({ per_page: meta.per_page, page: meta.current_page, filter: newFilter , payrun_id: payrun.id });
			this.setState({ employees: response.data, isLoading: false, meta: response.meta });

			// this.resolveMissingEmployees();
		} catch (error) {
			this.props.setSnackbar(true, "Unable to get employee, please try again", "error");
		}
	}

	async setupBranchFilter () {
		const { fields } = this.state;
		try {
			const branches = await Api.organizations(this.context.user.organization_id).branches.get();
			this.setState({
				fields: fields.concat([{
					key: "branch_id",
					label: "Branch",
					type: "selection",
					options: _.map(branches.data, (branch) => {
						return {
							label: branch.name,
							key: branch.id
						}
					})
				}])
			});
		} catch (e) { }
	};

	getPayrollEmployees = () => {
		const { payrun } = this.props;
		return _.map(payrun.payslips, payslip => payslip.employee_id);
	};

	onPageChange = (e, page) => {
		const { meta } = this.state;
		this.setState({
				meta: {
					...meta,
					current_page: page + 1
				},
			},
			this.getEmployees
		)
	}

	resolveMissingEmployees = () => {
		const { payrun } = this.props;
		const { employees } = this.state;

		if (payrun && payrun.id) {
			payrun.payslips.forEach(payslip => {
				if (!(_.find(employees, (employee) => payslip.employee.id === employee.id))) {
					employees.push(payslip.employee)
					this.setState({ employees })
				}

			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		const stepper = props.stepper.getData(1);
		if (stepper && (stepper.from !== state.previousFrom || stepper.to !== state.previousTo)) {
			state.getEmployees(stepper.from, stepper.to);

			return {
				...state,
				isLoading: true,
				previousFrom: stepper.from,
				previousTo: stepper.to
			}
		}

		return state;
	}

	toggleAllEmployees = () => {
		const { employees, selectedEmployees } = this.state;
		const { payrun } = this.props;

		let employeeIntersection = _.intersectionWith(
			selectedEmployees,
			(_.map(employees, "id")),
			_.isEqual
		);

		let selectedEmployeeIntersection = _.intersectionWith(
			selectedEmployees,
			employeeIntersection,
			_.isEqual
		);

		if (employees.length === selectedEmployeeIntersection.length) {

			const paidSlips = _.filter(payrun.payslips, slip => this.isPayslipPaid(slip.employee_id));
			const unpaidSlips = _.filter(payrun.payslips, slip => !this.isPayslipPaid(slip.employee_id));
			this.setState({
				selectedEmployees: _.union(_.map(paidSlips, "employee_id"), _.differenceWith(selectedEmployees, _.map(employees, "id"), _.isEqual)),
				removedEmployees: _.map(unpaidSlips, "employee_id")
			});
			if (paidSlips.length) {
				this.props.setSnackbar(true, "Can't remove employees with paid salary", "error"); 
			}
		} else {
			this.setState({
				selectedEmployees: _.union(_.map(employees, "id"), selectedEmployees),
				removedEmployees: []
			});
		}
	};

	toggleEmployee = employeeId => {
		const { selectedEmployees, removedEmployees } = this.state;
		if (selectedEmployees.indexOf(employeeId) === -1) {
			// Select Employee
			this.setState({
				selectedEmployees: [...selectedEmployees, employeeId],
				removedEmployees: _.filter(removedEmployees, id => id !== employeeId)
			});

		} else {
			// Unselect Employee
			if (this.isPayslipPaid(employeeId)) {
				return this.props.setSnackbar(true, "Can't remove employees with paid salary", "error");
			}

			this.setState({
				selectedEmployees: _.filter(selectedEmployees, id => id !== employeeId),
				removedEmployees: [...removedEmployees, employeeId],
			});
		}
	};

	isPayslipPaid(employeeId) {
		const { payrun } = this.props;
		const payslip = (payrun && payrun.id) && _.find(payrun.payslips, { employee_id: employeeId });
		return payslip && payslip.is_paid;
	}

	isEmployeeSelected(employeeId) {
		return this.state.selectedEmployees.indexOf(employeeId) !== -1;
	}

	getEmployeeOnSearch = () => {
		var employees = this.state.employees;
		let search = this.state.search;
		if (this.state.search) {
			employees = _.filter(this.state.employees, function (emp, key) {
				return _.includes(`${emp.first_name} ${emp.last_name}`.toLowerCase(), search.toLowerCase()) || _.includes(emp.code.toLowerCase(), search.toLowerCase())
			});
		}
		return employees;
	};

	validate() {
		const { selectedEmployees } = this.state;
		if (!selectedEmployees.length) {
			this.props.setSnackbar(true, "Please select employees", "error");
			return false;
		}
		return true;
	}

	errorHandler(e) {
		HttpErrorHandler.intercept(e)
			.on(422, response => {
				if (response.data.duration) {
					this.props.setSnackbar(true, response.data.duration[0], "error");
				} else {
					this.props.setSnackbar(true, "Please select employees", "error");
				}
			})
			.default(() => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
			.go();
	}

	isEdit() {
		return !!(this.props.payrun && this.props.payrun.id);
	}

	next = async () => {
		const { stepper, payrun, onUpdate } = this.props;
		const { from, to, name } = stepper.getData(1);
		const { selectedEmployees, removedEmployees } = this.state;
		
		if ((payrun.id || (from && to && name)) && this.validate()) {
			try {
				this.props.setLoader(true);
				const _payrun = this.isEdit()
									? await Api.payruns(payrun.id).put({
										employee_ids: selectedEmployees || payrun.payslips.map(p => p.employee_id),
										unselect_employees: removedEmployees
									})
									: await Api.payruns.post({
										from: moment(from).format(Config.SERVER_DATETIME_FORMAT),
										to: moment(to).format(Config.SERVER_DATETIME_FORMAT),
										payrun_name: name,
										employee_ids: selectedEmployees
									});
	
				if (!this.isEdit()) {
					this.props.history.push(`/payroll/${_payrun.id}`, { payrun: _payrun });
				}
	
				_.isFunction(onUpdate) && onUpdate(_payrun);
				stepper && stepper.next({});
	
			} catch(e) {
				HttpErrorHandler.intercept(e)
					.on(422,response => {
						console.log(e)
						if(response.data.duration) {
							this.props.setSnackbar(true,response.data.duration[0], "error");
						}
					})
					.default(() => this.props.setSnackbar(true,"Something went wrong, please try again","error"))
					.go();
	
			} finally {
				this.props.setLoader(false);
			}
			

		} else {
			this.props.setSnackbar(true, "Select at least one employee to proceed", "error");
		}
	};

	summary() {
		return (
			<Grid container alignItems="center" spacing={1}>
				<Grid item><Icon color="primary">info</Icon></Grid>
				<Grid item>
					<Typography color="primary"><b>{_.size(this.state.selectedEmployees)} Employees selected</b></Typography>
				</Grid>
			</Grid>
		);
	}

	footerActions(disabled = false) {
		return (
			<Button
				variant="contained"
				color="primary"
				disabled={disabled}
				onClick={this.next}>
				Next
			</Button>
		);
	}

	addEmployee = () => {
		this.props.history.push("/employees/add");
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="employee"
				message={"You don't have active employees within the selected duration"}
				actionLabel={"Add Employee"}
				actionIcon={"add"}
				actionHandler={this.addEmployee}
			/>
		);
	};

	mapDataForServer = (addedFilters = []) => {
		let filter = [];
		let employee_position_columns = ['joined_at', "confirmed_at"];
		addedFilters.forEach(f => {
			if (_.find(employee_position_columns, (column) => column === f.key)) {
				filter.push({
					field: "current_position." + f.key,
					value: f.value,
					method: f.method,
					operator: _.lowerCase(f.operator || "and")
				});
			} else if (f.key === "department") {
				filter.push({
					field: "current_position.department_id",
					value: f.value,
					method: f.method,
					operator: _.lowerCase(f.operator || "and")
				});

			} else if (f.key === "designation") {
				filter.push({
					field: "current_position.designation_id",
					value: f.value,
					method: f.method,
					operator: _.lowerCase(f.operator || "and")
				});

			} else if (f.key === "branch") {
				filter.push({
					field: "branch.id",
					value: f.value,
					method: f.method,
					operator: _.lowerCase(f.operator || "and")
				});

			} else {
				filter.push({
					field: f.key,
					value: f.value,
					method: f.method,
					operator: _.lowerCase(f.operator || "and")
				});
			}

		});

		return filter;
	};

	onSearch = (filters) => {
		const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			!!oldFilters && this.getEmployees();
		});
	};

	render() {
		const { step, isActive, stepper, classes, ...otherProps } = this.props;
		const { selectedEmployees, employees, isLoading, meta, fields, filters } = this.state;
		const getEmployeeOnSearch = this.getEmployeeOnSearch();
		return (
			<Stepper.Step
				title="Employees"
				step={step}
				isActive={isActive}
				summary={this.summary()}
				alwaysShowSummary={true}
				stepper={stepper}
				canEdit={stepper.isCompleted(step)}
				fullWidth
				{...otherProps}>
				<Grid container spacing={2} direction="row">
					<Grid item xs={12}>
						<Grid container alignItems="center" spacing={2}>
							<Grid item xs>
								<Filter
									fields={fields}
									filters={filters}
									onUpdate={this.onSearch}
								/>
							</Grid>
							<Grid item>
								<Button onClick={this.toggleAllEmployees}>
									{
										employees.length === (_.intersection(selectedEmployees, _.map(employees, (emp) => emp.id))).length
											? "Unselect All"
											: "Select All"
									}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<Grid container spacing={2} justifyContent={_.size(getEmployeeOnSearch) > 0 ? "flex-start" : "center"}>
							{
								isLoading ? (
									<ContentLoader viewBox="0 0 1312 222">
										{
											Array(3).fill().map((_, i) => (
												<Fragment key={i}>
													<rect x="8" y={8 + (i * 74)} rx="3" ry="3" width="310" height="58" />
													<rect x="334" y={8 + (i * 74)} rx="3" ry="3" width="310" height="58" />
													<rect x="660" y={8 + (i * 74)} rx="3" ry="3" width="310" height="58" />
													<rect x="986" y={8 + (i * 74)} rx="3" ry="3" width="310" height="58" />
												</Fragment>
											))
										}
									</ContentLoader>
								) : (
									_.size(getEmployeeOnSearch) > 0 ? (
										_.map(getEmployeeOnSearch, (employee, key) => (
											<Grid key={key} item xs={12} sm={6} md={4} lg={3}>
												<EmployeeChip
													employee={employee}
													onClick={this.toggleEmployee}
													isActive={this.isEmployeeSelected(employee.id)}
													isLocked={this.isPayslipPaid(employee.id)}
												/>
											</Grid>
										))
									) : (
										this.emptyView()
									)
								)
							}
						</Grid>

					</Grid>
					<Grid item xs={12} sm={8}>
						{
							meta.total > 0 &&
							<TablePagination
								classes={{ root: classes.employeePaginationRoot, toolbar: classes.employeePaginationToolbarRoot }}
								key={"DLTableRow-Footer-Pagination"}
								rowsPerPageOptions={[16, 24, 52, 100]}
								colSpan={1}
								count={meta.total}
								rowsPerPage={meta.per_page}
								page={meta.current_page - 1}
								SelectProps={{ native: false }}
								onPageChange={this.onPageChange}
								onRowsPerPageChange={(e) => this.setState({ meta: { ...meta, per_page: e.target.value , current_page:1} }, this.getEmployees)}
								ActionsComponent={DLTablePaginationActions}
							/>
						}
					</Grid>
					<Grid item xs={12} sm={4} style={{ textAlign: "right", alignSelf: "flex-end" }}>
						{this.footerActions((_.size(getEmployeeOnSearch) === 0))}
					</Grid>
				</Grid>
			</Stepper.Step>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setLoader: status => dispatch(setLoader(status)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(EmployeeSelection)));