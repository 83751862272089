import React, { Component } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api.js";

class QRModal extends Component {

    static propTypes = {
        onClose: Proptypes.func.isRequired,
        onUpdate: Proptypes.func,
        employee: Proptypes.object.isRequired,
        open: Proptypes.bool.isRequired
    };

    static defaultProps = {
        onUpdate: () => { }
    };

    render() {
        const { open, onClose, employee } = this.props;

        return (
            <>
                <Dialog
                    open={open}
                    onClose={onClose}
                    onBackdropClick={onClose}
                    scroll="paper"
                    aria-labelledby="payment-dialog-title"
                    aria-describedby="payment-dialog-description"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="payment-dialog-title">
                        <div>QR Code</div>
                    </DialogTitle>
                    <DialogContent dividers={true} style={{display: "flex", justifyContent: "center" }}>
                        <img
                            title="QR Code"
                            alt="QR Code"
                            src={Api.employees(employee.id).qr.getUrl(true) } />
                    </DialogContent>
                    <DialogActions>
                        <Button size={"small"} onClick={onClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(QRModal);