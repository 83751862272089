import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Tooltip,
	IconButton,
	Icon,
	Menu,
	MenuItem,
	Chip
} from "@material-ui/core";
import { DoneOutlined, ClearOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn, UserRole, EmployeeAvatar, Money } from "@/components";
import store from "@/redux";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import Api from "@/helpers/Api";
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import { Constants } from "@/config";
import Utility from "@/helpers/Utility";
import ReimbursableConfirmModal from "@/components/Modals/ReimbursableConfirmModal"
import PictureViewModal from '@/components/Modals/PictureViewModal';
import Theme from "@/themeStyles";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { Permissions } from "@/config/Permissions";

class ReimbursableTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			payInNextPayroll: true,
			imageIndex: 0,
			isPictureViewModalOpen: false,
			isApproving: false,
			isRejecting: false,
			isDeleting: false,
			approveOrReject: null
		}
	}

	modalClose = () => {
		this.setState({ isPictureViewModalOpen: false });
	}

	viewPicture = (imageIndex) => {
		this.setState({ isPictureViewModalOpen: true, imageIndex });
	}

	deleteReimbursement = async (id) => {
		this.setState({ isDeleting: false });
		store.dispatch(setLoader(true));
		try {
			store.dispatch(setLoader(true))
			await Api.reimbursable(id).delete();
			store.dispatch(setSnackbar(true, `Reimbursement deleted successfully`))
			this.props.reload();
		}
		catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
		store.dispatch(setLoader(false))

	}

	updateStatus = async (data, status, pay_in_next_payroll) => {
		let { payInNextPayroll } = this.state;
		payInNextPayroll = !(status === Constants.Reimbursable.FLAG_REJECTED);

		try {
		store.dispatch(setLoader(true));
			try {
				await Api.reimbursable(data.id).put({ status, pay_in_next_payroll: pay_in_next_payroll });
				if(status === Constants.Reimbursable.FLAG_REJECTED) {
					store.dispatch(setSnackbar(true, `Reimbursement rejected for ${data.employee.first_name}`, "error"));
				} else {
					store.dispatch(setSnackbar(true, `Reimbursement approved for ${data.employee.first_name}`));
				}
				this.setState({ isModalOpen: false });
				this.props.reload();

			} catch (e) {
				console.error(e);
				store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
			}
		} catch (e) { }
		store.dispatch(setLoader(false));
	};

	returnStatusColor(status) {
		switch (status) {
			case Constants.Reimbursable.FLAG_PENDING:
				return Theme.palette.tableBorder.main
			case Constants.Reimbursable.FLAG_APPROVED:
				return Theme.palette.success.main;
			case Constants.Reimbursable.FLAG_REJECTED:
				return Theme.color.red.persianRed
			case Constants.Reimbursable.FLAG_PAID:
				return Theme.palette.primary.main
			default:
				break;
		}
	}

	confirmMessage = () => {
		const { data } = this.props;
		const exceedAmount = data.remaining - data.approved_amount;
		if (exceedAmount < 0) {
			return <span>Are you sure want to Approve <b><Money amount={data.requested_amount}/></b>, it exceeds quota by <b><Money amount={exceedAmount * -1}/></b>?</span>
		} else {
			return <span>Are you sure want to Approve <b><Money amount={data.requested_amount}/></b>?</span>
		}
	}

	images() {
        const { data } = this.props;
        return data.reimbursable_images.map(image => Api.reimbursable(data.id).image.getUrl({ image: image.id }));
    }

	renderCells() {
		const { data, classes } = this.props;
		const amount = data.status === (Constants.Reimbursable.FLAG_PAID || Constants.Reimbursable.FLAG_APPROVED) ? data.approved_amount : data.requested_amount;
		const { imageIndex, isPictureViewModalOpen, isApproving, isRejecting, isDeleting } = this.state;
		return [
			<DLTableColumn
				withSeparator={true}
				key={`Reimbursable-${data.id}-Column-1`}>
				<EmployeeAvatar data={data} classes={classes} />
			</DLTableColumn>,
			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-2`}
				align={"left"}>
				{data.pay_item.name}
			</DLTableColumn>,
			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-3`}
				align={"left"}>
				{moment(data.date).format("DD MMM, YYYY")}
			</DLTableColumn>,
			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-images-Column-4`}
				align={"left"}>
				<div style={{ display: "flex", justifyContent: "flex-start" }}>
					{
						_.map(data.reimbursable_images, (file, i) => {

							if (i === 2) {
								return (
									<div
										className={classes.extraImage}
										onClick={() => this.viewPicture(0)}>
										<p className={classes.extraImageText}>+{data.reimbursable_images.length - i}</p>
									</div>
								)
							}
							else if (i > 2) { return null }
							return (
								<img key={i} alt="reimbursement" className={classes.reimburseImage}
									src={Utility.getReimbursementImageUrl(file.reimbursable_id, file.id)}
									onClick={() => this.viewPicture(i)}
								/>
							)
						})
					}
				</div>
			</DLTableColumn>,

			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-5`}
				align={"right"}>
				{
					data.status == 'pending'
						? <Money amount={data.remaining}/>
						: '—'
				}
			</DLTableColumn>,

			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-4`}
				align={"right"}>
				<Money amount={amount}/>
			</DLTableColumn>,

			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-6`}
				align={"left"}>
				<Chip size="small" label={data.status.toUpperCase()} color={"primary"} style={{ backgroundColor: this.returnStatusColor(data.status), color: Theme.color.white.main }} />
			</DLTableColumn>,

			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-6`}
				align={"left"}>
				<code>{data.code}</code>
			</DLTableColumn>,

			<DLTableColumn
				withSeparator={false}
				key={`Reimbursable-${data.id}-Column-7`}
				align={"right"}>
				{
					data.status === Constants.Reimbursable.FLAG_PENDING &&
					<>
						<Tooltip title="Approve Reimbursement">
							<IconButton
								onClick={() => this.setState({ isApproving: true , approveOrReject: Constants.Reimbursable.FLAG_PENDING  })}
								size="small"
								color="primary">
								<DoneOutlined />
							</IconButton>
						</Tooltip>
						<Tooltip title="Reject Reimbursement">
							<IconButton
								onClick={() => this.setState({ isRejecting: true })}
								size="small"
								className={classes.rejectButton}
							>
								<ClearOutlined />
							</IconButton>
						</Tooltip>
					</>
				}
				<UserRole routePermissions={[
					Permissions.ROLE_VIEWABLE,
					Permissions.ROLE_EDITABLE,
					Permissions.ROLE_FULL_ACCESS]}>

					<Tooltip title="More Options">
						<IconButton
							onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
							size="small"
						>
							<Icon>more_vert</Icon>
						</IconButton>
					</Tooltip>
				</UserRole>
				<Menu
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={() => this.setState({ anchorEl: null })}
					PaperProps={{
						style: {
							minWidth: 200,
						}
					}}>
					<MenuItem onClick={() => this.props.history.push(`/reimbursements/${data.id}`)}>
						{
							data.status === Constants.Reimbursable.FLAG_PAID ? "View" : "Edit"
						}
					</MenuItem>
					{
						data.status === Constants.Reimbursable.FLAG_PENDING &&
						<>
							<MenuItem onClick={() => this.setState({ anchorEl: null, isDeleting: true })}>Delete</MenuItem>
						
						</>
					}
				</Menu>
				{
					isPictureViewModalOpen &&
					<PictureViewModal
                        images={this.images()}
                        defaultIndex={imageIndex}
                        onClose={this.modalClose}
                    />
				}
			</DLTableColumn>,
			isDeleting &&
			<ConfirmModal
				title="Delete Reimbursement"
				isOpen={isDeleting}
				message={`Are you sure want to delete this reimbursement?`}
				onClickCancel={() => this.setState({ isDeleting: false })}
				onClickConfirm={() => { this.deleteReimbursement(data.id) }}
			/>,
			isApproving &&
			<UserRole routePermissions={[
				Permissions.ROLE_FULL_ACCESS,
				Permissions.ROLE_EDITABLE]}
			>
				<ReimbursableConfirmModal
					title="Approve Reimbursement"
					mode= {"single"}
					message={this.confirmMessage()}
					onValueChange={(value) => { this.setState({ payInNextPayroll: value }) }}
					hideNextPayroll={(this.state.approveOrReject === Constants.Reimbursable.FLAG_REJECTED)}
					isOpen={isApproving}
					onClickConfirm={(pay_in_next_payroll) => { { this.setState({ isApproving: false }) }; this.updateStatus(data, Constants.Reimbursable.FLAG_APPROVED, pay_in_next_payroll) }}
					onClickCancel={() => this.setState({ isApproving: false })}
				/>
			</UserRole>,
			isRejecting &&
			<ConfirmModal
				title="Reject Reimbursement"
				isOpen={isRejecting}
				message={`Are you sure want to reject this Reimbursement?`}
				onClickCancel={() => this.setState({ isRejecting: false })}
				onClickConfirm={() => { { this.setState({ isRejecting: false }) }; this.updateStatus(data, Constants.Reimbursable.FLAG_REJECTED) }}
			/>,
			isDeleting &&
			<ConfirmModal
				title="Delete Reimbursement"
				isOpen={isDeleting}
				message={`Are you sure want to delete this reimbursement?`}
				onClickCancel={() => this.setState({ isDeleting: false })}
				onClickConfirm={() => { this.deleteReimbursement(data.id) }}
			/>
		]
	}
}

ReimbursableTableRow.Columns = [
	{
		key: "first_name",
		label: "Employee",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "payitem",
		label: "Pay Item",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "date",
		label: "Date",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "image",
		label: "Receipts",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "remaining",
		label: "Remaining Quota",
		align: "right",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "requested_amount",
		label: "Amount",
		align: "right",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "status",
		label: "Status",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "code",
		label: "Code",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "action",
		label: "",
		align: "right",
		isSortable: true,
		withSeparator: false
	}
];

export default withRouter(withStyles(styles)(ReimbursableTableRow));

