import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
	Card,
	CardActions,
	Button
} from "@material-ui/core";
import { DateRange, DLTable, EmployeeDropdown, BackButton, InfographicMessage, Page } from "@/components";
import LoanLedgerRow from "./LoanLedgerRow";
import { Api } from "@/helpers";
import styles from "./style";

class LoanLedger extends Component {

	_table = null;

	constructor(props) {
		super(props);
		this.state = {
			from: null,
			to: null,
			employee: null
		};
	}

	export = () => {
		const { from, to, employee } = this.state;
		window.open(Api.reports.loan_ledger.export.getUrl({
			from: from.format("YYYY-MM-DD"),
			to: to.format("YYYY-MM-DD"),
			employee_id: employee || undefined,
		}))
	};

	reload = () => {
		this._table && this._table.fetchData(true);
	};

	emptyView() {
		return (
			<InfographicMessage
				illustration="loan_ledger"
				message="Loan are not yet processed for employees!"
			/>
		);
	}

	render() {
		const { classes } = this.props;
		const { from, to, employee } = this.state;
		return (
			<Page title="Loan Ledger">
				<BackButton route="/reports" label="Back to Reports" />
				<Card raised>
					<CardActions className={classes.taxReportActions}>
						<div className={classes.taxReportFilter}>
							<DateRange
								onChange={(from, to) => this.setState({ from, to }, this.reload)}/>
							<EmployeeDropdown
								textFieldProps={{ margin: "dense" }}
								value={employee}
								onChange={employee => this.setState({ employee }, this.reload)}
							/>
						</div>
						<Button 
							size="medium"
							onClick={this.export}
							variant="outlined">
							Export
						</Button>
					</CardActions>
					{
						from && to &&
						<DLTable
							ref={ref => this._table = ref}
							api={Api.reports.loan_ledger}
							params={{
								from: from.format("YYYY-MM-DD"),
								to: to.format("YYYY-MM-DD"),
								employee_id: employee || undefined
							}}
							defaultOrder="desc"
							RowTemplate={LoanLedgerRow}
							emptyView={this.emptyView()}/>
					}
				</Card>
			</Page>
		);
	}

}

export default withRouter(withStyles(styles)(LoanLedger));