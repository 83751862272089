import React, { Component } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    MenuItem
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { DLTable, InfographicMessage, BankAccountDropdown } from "@/components";
import ConfirmModal from "../ConfirmModal";
import EmployeePaymentTableRow from "./EmployeePaymentTableRow";
import { currencyFormatter } from "@/helpers/generalHelper";
import { setSnackbar } from "@/redux/actions/general";
import Config, { PaymentMethod } from "@/config";
import { Api, HttpErrorHandler } from "@/helpers";
import moment from "moment";
import _, { method } from "lodash";

class PaymentModal extends Component {

    static propTypes = {
        onClose: Proptypes.func.isRequired,
        onUpdate: Proptypes.func,
        open: Proptypes.bool.isRequired
    };

    static defaultProps = {
        onUpdate: () => { }
    };

    constructor(props) {
        super(props);

        this.state = {
            date: moment(),
            paymentMethod: PaymentMethod.CASH,
            sourceAccount: "",
            sourceBank: "",
            check: "",
            selectedPayslips: [],
            payments: [],
            isSubmitting: false,
            amount: "",
            data:[]
        };
    }

    dateChangeHandler() {
        return (date) => {
            this.setState({ date });
        }
    }

    handleChange = (val, fieldName) => {
        this.setState({ [fieldName]: val })
    }

    componentDidMount () {
        var newData = []
        if(this.props.method=== "granted" ){
            this.props.loan.map((item) => {
				if(item.status === "approved") {
					newData.push(item)
				}
			})
        }
		else if(this.props.method === "deduction"){
			this.props.loan.map((item) => {
				if(item.status === "granted" || item.status === "partial")  {
					newData.push(item)
				}
			})
		}
        else{
            newData.push(this.props.loan)
        }
        this.setState({data:newData})
        this.setState({ selectedPayslips: _.map( newData, "id") });
    }

    paymentMethodChangeHandler() {
        return event => {
            var { payments } = this.state;

            _.forEach(payments, payment => {
                switch (event.target.value) {
                    case PaymentMethod.CASH:
                        payment.bank_account =null;
                        payment.check =null;
                        break;
                    case PaymentMethod.CHECK:
                        if(this.props.method !== "deduction"){
                            payment.bank_account = "";
                        }
                        break;
                    case PaymentMethod.IBFT:
                        payment.check = null
                        break;
                    case PaymentMethod.BANK_TRANSFER:
                        break;
                    default:
                        payment.check = "";
                        break;
                }
            });
            this.paymentChangeHandler()
            this.setState({ paymentMethod: event.target.value, payments});
        };
       
    }

    bankChangeHandler() {
        return account => {
            this.setState({ sourceAccount: account.id, sourceBank: account.bank.id });
        };
    }

    checkChangeHandler() {
        return event => {
            this.setState({ check: event.target.value });
        }
    }


    paymentChangeHandler() {
        const { paymentMethod } = this.state;
        return (loan, paymentDetails) => {
            var payments = _.filter(this.state.payments, payment => {
                return payment.loan_request_id !== loan;
            });
        if(this.props.method === "deduction"){
            if(paymentMethod === PaymentMethod.IBFT){
                payments.push({
                    loan_request_id:loan,
                    amount:parseInt(paymentDetails.amount) || undefined,
                    bank_account: paymentDetails.account || undefined
                });
            }
            else{

                payments.push({
                    loan_request_id:loan,
                    amount:parseInt(paymentDetails.amount) || undefined,    
                    check: paymentDetails.check || undefined,
                    bank_account: paymentDetails.account || undefined
                });
            }
        }
        else{
            if(paymentMethod === PaymentMethod.IBFT){
                payments.push({
                    loan_request_id:loan,
                    amount:parseInt(paymentDetails.amount) || undefined,
                    bank_account: paymentDetails.account || undefined
                });
            }
            else{
                payments.push({
                    loan_request_id:loan,
                    check: paymentDetails.check || undefined,
                    bank_account: paymentDetails.account || undefined
                });
            }
        }
            this.setState({ payments });
        }
    }

    isBankChannelSelected() {
        const { paymentMethod } = this.state;
        return [
            PaymentMethod.CHECK,
            PaymentMethod.IBFT,
            PaymentMethod.BANK_TRANSFER
        ].indexOf(paymentMethod) !== -1;
    }

    isBankTransferSelected() {
        const { paymentMethod } = this.state;
        return paymentMethod === PaymentMethod.BANK_TRANSFER;
    }


    getSelectedPayments() {
        const { paymentMethod, selectedPayslips, payments } = this.state;
        var cash = []
       
        const selectedPayments = _.filter(payments, payment => {
            return selectedPayslips.indexOf(payment.loan_request_id) !== -1;
        });

        // Payment details not needed in case of Cash.
        // So, don't waste time in validations
        if(this.props.method === "deduction" && paymentMethod === PaymentMethod.CASH ){
            return _.reduce(selectedPayments, (carry, payment) => {
                cash.push(payment);
             
             return cash;
         }, []);
        }
     
        if (paymentMethod === PaymentMethod.CASH) return _.map(selectedPayslips, loan_request_id =>{ 
            if(this.props.method !== "deduction"){
            return({ loan_request_id })
            }
            else{
              return cash
            }})

        return _.reduce(selectedPayments, (carry, payment) => {
            if ((paymentMethod === PaymentMethod.CHECK && payment.check) || (paymentMethod !== PaymentMethod.CHECK && payment.bank_account)) {
                carry.push(payment);
            }
            return carry;
        }, []);
    }

    verifyPayments(showErrors = false) {
        const { paymentMethod, sourceAccount, check, selectedPayslips } = this.state;
        if(this.props.method !== "deduction"){
            if (this.isBankChannelSelected() && !sourceAccount) {
                if (showErrors) {
                    this.props.setSnackbar(true, "Please select source bank account", "error");
                }
                return false;
            }
        }
        if (this.isBankTransferSelected() && !check) {
            if (showErrors) {
                this.props.setSnackbar(true, "Please provide check number", "error");
            }
            return false;
        }
        if (!selectedPayslips.length) {
            if (showErrors) {
                this.props.setSnackbar(true, "Please select employees to mark payment", "error");
            }
            return false;
        }
        if (selectedPayslips.length !== this.getSelectedPayments().length) {
            if (showErrors) {
                if (paymentMethod === PaymentMethod.CHECK) {
                    this.props.setSnackbar(true, "Please provide check number for all selected employees", "error");

                } else if (this.isBankChannelSelected()) {
                    this.props.setSnackbar(true, "Please select bank account for all selected employees", "error");
                }
            }
            return false;
        }

        return true;
    }

    confirmationMessage() {
        var { selectedPayslips, paymentMethod } = this.state;
        const { loan} = this.props
        if(loan.status === "approved") {
            return (
                <span>
                    Are you sure you want to mark payment for <strong> loan request{selectedPayslips.length > 1 && "s"}</strong> via {paymentMethod} ?
                </span>
            );
        } else if(this.props.method === "deduction"){
            return (
                <span>
                    Are you sure you want make deduction for <strong> loan request </strong> via {paymentMethod} ?
                </span>
            );
        }
        else if(this.props.method === "granted"){
            return (
                <span>
                    Are you sure you want to grant <strong> loan request </strong> via {paymentMethod} ?
                </span>
            );
        }
    }

    markPaymentsHandler() {
        return event => {
            this.setState({ confirming: false });
            if (this.verifyPayments(true)) {
                const selectedPayments = this.getSelectedPayments();
                const { date, paymentMethod, check, sourceAccount } = this.state;
                const { loan } = this.props;
                var payments =[]
                payments = selectedPayments.filter(function( obj ) {
                    for (var key in obj) {
                        if (obj[key] == null) delete obj[key];
                    }
                    return obj;
                });
                this.setState({ isSubmitting: true });
                if(this.props.bulk === true) {
                    if(this.props.method !== "deduction") {
                        Api.loan_requests.bulk_grant_deduct.put({
                            date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                            method: paymentMethod,
                            type : this.props.method,
                            bank_account: this.isBankChannelSelected() ? sourceAccount : undefined,
                            payments: payments
                        }).then(response => {
                            this.props.setSnackbar(true, `Loan granted successfully`);
                            this.resetForm();
                            this.props.onClose();
                            this.props.onUpdate();
                        }).catch((e) => {
                            HttpErrorHandler.intercept(e)
                            .on(422, response => {
                                const errors = _.values(_.mapValues(response.data, value => value[0]));
                            if (errors && errors.length) {
                                this.props.setSnackbar(true, errors[0], "error");
                            } else {
                                this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                            }
                        })
                            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                            .go();
                            this.setState({ isSubmitting: false });
                        });
                    }
                    else{
                        Api.loan_requests.bulk_grant_deduct.put({
                            date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                            method: paymentMethod,
                            type:this.props.method,
                            payments: payments,
                        }).then(response => {
                        
                            this.props.setSnackbar(true, `Loan deducted successfully`);
                            this.resetForm();
                            this.props.onClose();
                            this.props.onUpdate();
                        }).catch((e) => {
                            HttpErrorHandler.intercept(e)
                            .on(422, response => {
                                const errors = _.values(_.mapValues(response.data, value => value[0]));
                            if (errors && errors.length) {
                                this.props.setSnackbar(true, errors[0], "error");
                            } else {
                                this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                            }
                        })
                            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                            .go();
                            this.setState({ isSubmitting: false });
                        });
                    }
                }
                else{
                    payments.push({
                        "check":selectedPayments[0].check,
                        "amount":selectedPayments[0].amount,
                        "bank_account":selectedPayments[0].bank_account
                    }) 
                    if(loan.status === "approved") {
                        Api.loan_request(loan.id).grant_loan.post({
                            date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                            method: paymentMethod,
                            payments: payments[0],
                            check: this.isBankTransferSelected() ? check : undefined,
                            bank_account: this.isBankChannelSelected() ? sourceAccount : undefined
                        }).then(response => {
                        
                            this.props.setSnackbar(true, `Loan payed for ${loan.employee.first_name } ${loan.employee.last_name}`);
                            this.resetForm();
                            this.props.onClose();
                            this.props.onUpdate();
                        }).catch((e) => {
                            HttpErrorHandler.intercept(e)
                            .on(422, response => {
                                const errors = _.values(_.mapValues(response.data, value => value[0]));
                            if (errors && errors.length) {
                                this.props.setSnackbar(true, errors[0], "error");
                            } else {
                                this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                            }
                        })
                            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                            .go();
                            this.setState({ isSubmitting: false });
                        });

                    } else {
                        var data = {};
                        if(this.state.amount !== "") {
                            data = {
                                date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                                method: paymentMethod,
                                payments: payments[0],
                                amount: parseInt(this.state.amount)
                            }
                        } else if(this.state.amount === "") {
                            data = {
                                date: moment(date).format(Config.SERVER_DATETIME_FORMAT),
                                method: paymentMethod,
                                payments: payments[0],
                            }
                        }
                        
                        Api.loan_request(loan[0].id).deduct_loan.post({
                            ...data
                        }).then(response => {
                            this.props.setSnackbar(true, `Loan deducted for ${loan[0].employee.first_name } ${loan[0].employee.last_name}`);
                            this.resetForm();
                            this.props.onClose();
                            this.props.onUpdate();
                        }).catch((e) => {
                            HttpErrorHandler.intercept(e)
                            .on(422, response => {
                                const errors = _.values(_.mapValues(response.data, value => value[0]));
                            if (errors && errors.length) {
                                this.props.setSnackbar(true, errors[0], "error");
                            } else {
                                this.props.setSnackbar(true, "Something went wrong, please try again", "error")
                            }
                        })
                            .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
                            .go();
                            this.setState({ isSubmitting: false });
                        });
                    }
                }
                
            } 
        }
    }

    resetForm() {
        this.setState({
            date: moment().format(Config.SERVER_DATETIME_FORMAT),
            paymentMethod: PaymentMethod.CASH,
            sourceAccount: "",
            sourceBank: "",
            check: "",
            amount: "",
            selectedPayslips: [],
            payments: [],
            isSubmitting: false
        });
    }

    render() {
        const { open, onClose, loan, heading } = this.props;
        const { date, paymentMethod, sourceAccount, sourceBank, check, isSubmitting, data, amount } = this.state;
        return (
            <>
                <Dialog
                    open={open}
                    onClose={onClose}
                    scroll="paper"
                    onBackdropClick={onClose}
                    aria-labelledby="payment-dialog-title"
                    aria-describedby="payment-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="payment-dialog-title">
                        <div>{heading}</div>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            !!loan ? (
                                <form>
                                    <Grid container spacing={2}>
                                        {
                                            (loan.status === "granted" || loan.status === "partial") &&
                                            <Grid item md={6} sm={12}>
                                                <TextField
                                                    size={"small"}
                                                    fullWidth
                                                    value={amount}
                                                    variant="outlined"
                                                    label="Deduction Amount"
                                                    type="number"
                                                    onChange={(e) => this.handleChange(e.currentTarget.value.replace(/[^0-9]/g, ""), "amount")}
                                                    disabled={isSubmitting} />
                                            </Grid>
                                        }
                                        <Grid item md={6} sm={12}>
                                            <DatePicker
                                                autoOk
                                                inputVariant="outlined"
                                                label="Payment Date"
                                                format={Config.DISPLAY_DATE_FORMAT}
                                                value={date}
                                                onChange={this.dateChangeHandler()}
                                                disabled={isSubmitting}
                                                fullWidth
                                                size={"small"}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                size={"small"}
                                                fullWidth
                                                select
                                                label="Payment Method"
                                                variant="outlined"
                                                value={paymentMethod}
                                                onChange={this.paymentMethodChangeHandler()}
                                                disabled={isSubmitting}>
                                                <MenuItem value={PaymentMethod.CASH}>{PaymentMethod.CASH}</MenuItem>
                                                <MenuItem value={PaymentMethod.CHECK}>{PaymentMethod.CHECK}</MenuItem>
                                                <MenuItem value={PaymentMethod.IBFT}>{PaymentMethod.IBFT}</MenuItem>
                                            </TextField>
                                        </Grid>
                                        {
                                            this.isBankChannelSelected() && this.props.method !== "deduction" &&
                                            <Grid item md={this.isBankTransferSelected() ? 6 : 12} sm={12}>
                                                <BankAccountDropdown
                                                    label="Bank"
                                                    value={sourceAccount}
                                                    onChange={this.bankChangeHandler()}
                                                    textFieldProps={{ fullWidth: true, size: "small" }}
                                                    resultType="object"
                                                />
                                            </Grid>
                                        }
                                        {
                                            this.isBankTransferSelected() &&
                                            <Grid item md={6} sm={12}>
                                                <TextField
                                                    size={"small"}
                                                    fullWidth
                                                    value={check}
                                                    variant="outlined"
                                                    label="Check Number"
                                                    onChange={this.checkChangeHandler()}
                                                    disabled={isSubmitting} />
                                            </Grid>
                                        }
                                        {
                                            paymentMethod !== "" &&
                                            <Grid item sm={12} md={12}>
                                                <Card>
                                                    <DLTable
                                                        data={data}
                                                        RowTemplate={EmployeePaymentTableRow}
                                                        rowProps={{
                                                            paymentMethod: paymentMethod,
                                                            sourceBank: sourceBank,
                                                            onPaymentChange: this.paymentChangeHandler(),
                                                            method :this.props.method
                                                        }}
                                                        checkboxSize={"small"}
                                                        disabled={isSubmitting} />
                                                </Card>
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            ) : (
                                <InfographicMessage
                                    illustration="payroll"
                                    message="Great! Salaries are paid in full"
                                />
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        {
                            !!loan &&
                            <Button
                                size={"small"}
                                onClick={() => this.setState({ confirming: true })}
                                disabled={!this.verifyPayments() || isSubmitting}
                                variant="contained"
                                color="primary">
                                {
                                    isSubmitting ? (
                                        <>
                                            <CircularProgress color={"primary"} size={20} />
                                            <span style={{ marginLeft: "15px" }}>Submitting...</span>
                                        </>
                                    ) : (
                                        "Submit"
                                    )
                                }
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
                {
                    this.state.confirming &&
                    <ConfirmModal
                        title="Mark Payment"
                        isOpen={this.state.confirming}
                        message={this.confirmationMessage()}
                        onClickCancel={() => this.setState({ confirming: false })}
                        onClickConfirm={this.markPaymentsHandler()} />
                }
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(PaymentModal);