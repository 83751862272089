import {
	Dashboard,
	Business,
	AccountBox,
	LocalAtm,
	ShowChart,
	Build,
	AccessTime,
	Description
} from "@material-ui/icons";
import { Permissions, Modules } from "../../config";
import ReimbursementIcon from '@/components/ReimbursmentIcon';
import LoanIcon from '@/components/LoanIcon';

export const Menu = [
	{
		Icon: Dashboard,
		title: "Dashboard",
		badge: "",
		route: "/",
		routePermissions: [
			Permissions.Permissions.COMPANY_DASHBOARD,
			// Permissions.Permissions.EMPLOYEE_DASHBOARD
		], 
		modulePermissions: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		Icon: Business,
		title: "Organizations",
		badge: "",
		route: "/organizations",
		permissions: {
			isSuperOrganization: true
		},
		routePermissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,
			Permissions.Permissions.ORGANIZATIONS_VIEW_ACCESS
		],
		modulePermissions: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		Icon: AccountBox,
		title: "Employees",
		badge: "",
		route: "/employees",
		routePermissions: [
			Permissions.Permissions.EMPLOYEES_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_VIEWABLE
		],
		modulePermissions: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		Icon: AccessTime,
		title: "Attendance",
		badge: "",
		route: "/attendance/dashboard",
		routePermissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_EDITABLE,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_VIEWABLE
		],
		modulePermissions: [
			Modules.ATTENDANCE
		]
	},
	{
		Icon: LocalAtm,
		title: "Payroll",
		badge: "",
		route: "/payroll",
		routePermissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modulePermissions: [
			Modules.PAYROLL
		]
	},
	{
		Icon: ReimbursementIcon,
		title: "Reimbursements",
		badge: "",
		route: "/reimbursements",
		routePermissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modulePermissions: [
			Modules.REIMBURSEMENT
		]
	},
	{
		Icon: LoanIcon,
		title: "Loans",
		badge: "",
		route: "/loans",
		routePermissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modulePermissions: [
			Modules.LOAN,
		]
	},
	{
		Icon: Description,
		title: "Documents",
		badge: "",
		route: "/documents",
		routePermissions: [
			Permissions.Permissions.DOCUMENT_VIEWABLE_ACCESS,
			Permissions.Permissions.DOCUMENT_FULL_ACCESS,
			Permissions.Permissions.DOCUMENT_EDITABLE_ACCESS
		],
		modulePermissions: [
			Modules.DOCUMENT
		]
	},
	{
		Icon: ShowChart,
		title: "Reports",
		badge: "",
		route: "/reports",
		routePermissions: [
			Permissions.Permissions.TAX,
			Permissions.Permissions.SALARY_LEDGER_REPORT,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modulePermissions: [
			Modules.PAYROLL,
			Modules.ATTENDANCE
		]
	},
	{
		Icon: Build,
		title: "Settings",
		badge: "",
		route: "/settings",
		routePermissions: [
			Permissions.Permissions.BANK_ACCOUNT_VIEWABLE,
			Permissions.Permissions.BANK_ACCOUNT_EDITABLE,
			Permissions.Permissions.BANK_ACCOUNT_FULL_ACCESS,

			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,

			Permissions.Permissions.DEPARTMENT_VIEWABLE,
			Permissions.Permissions.DEPARTMENT_EDITABLE,
			Permissions.Permissions.DEPARTMENT_FULL_ACCESS,

			Permissions.Permissions.DESIGNATION_VIEWABLE,
			Permissions.Permissions.DESIGNATION_EDITABLE,
			Permissions.Permissions.DESIGNATION_FULL_ACCESS,

			Permissions.Permissions.PAYITEM_VIEWABLE,
			Permissions.Permissions.PAYITEM_EDITABLE,
			Permissions.Permissions.PAYITEM_FULL_ACCESS,

			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,

			Permissions.Permissions.ROLE_VIEWABLE,
			Permissions.Permissions.ROLE_EDITABLE,
			Permissions.Permissions.ROLE_FULL_ACCESS
		],
		modulePermissions: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	}
];