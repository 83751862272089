import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress, Backdrop, Avatar, IconButton, Dialog } from "@material-ui/core";
import styles from "./style";
import { Cancel, BrokenImage } from '@material-ui/icons';
import { setSnackbar } from "@/redux/actions/general";
import { arrayOf, func, number } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

class PictureViewModal extends Component {

    static propTypes = {
        images: arrayOf(function(value, key, componentName, location, propName) {
            const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
            if (!urlRegex.test(value[key])) {
                return new Error(
                    'Invalid prop `' + propName + '` supplied to' +
                    ' `' + componentName + '`. Validation failed.'
                );
            }
        }).isRequired,
        defaultIndex: number,
        onClose: func
    };

    static defaultProps = {
        defaultIndex: 0
    };

    state = {
        statuses: [],
        imageIndex: 0
    };

    constructor(props) {
        super(props);

        if (props.images.length > props.defaultIndex && props.defaultIndex >= 0) {
            this.state.imageIndex = props.defaultIndex;
        }

        this.state.statuses = props.images.map(_ => 'loading');
        
        // Load Images
        props.images.forEach((src, index) => {
            var image = new Image();
            image.src = src;
            image.onload = () => {
                this.setState({
                    statuses: {
                        ...this.state.statuses,
                        [index]: 'loaded'
                    }
                });
            };

            image.onerror = () => {
                this.setState({
                    statuses: {
                        ...this.state.statuses,
                        [index]: 'error'
                    }
                });
            };
        });
    }

    async componentDidMount() {
        if (!this.props.images.length) return this.props.onClose();
        
        window.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }

    currentIndex() {
        return this.state.imageIndex;
    }

    currentImage() {
        return this.props.images[this.currentIndex()];
    }

    currentImageStatus() {
        return this.state.statuses[this.currentIndex()];
    }

    nextImage = () => {
        this.setState({ imageIndex: (this.state.imageIndex + 1) % this.props.images.length });
    }

    prevImage = () => {
        const imageIndex = --this.state.imageIndex;
        this.setState({ imageIndex: imageIndex < 0 ? (this.props.images.length - 1) : imageIndex});
    }

    onKeyDown = (event) => {
        switch (event.key) {
            case "ArrowLeft":
                this.prevImage();
                break;

            case "ArrowRight":
                this.nextImage();
                break;

            case 'Escape':
                this.props.onClose();
                break;

            default:
                break;
        }
    };

    render() {
        const { images, classes, onClose } = this.props;
        const status = this.currentImageStatus();
        return (
            !!images.length &&
            <Backdrop className={classes.backdrop} open>
                <div className={classes.closeDrop} onClick={() => onClose()}/>
                <IconButton color="primary" className={classes.closeButton} onClick={() => onClose()}>
                    <Cancel color="primary"/>
                </IconButton>
                <div className={classes.imageContainer}>
                    {
                        status === 'loading' &&
                        <CircularProgress color="inherit" size={50} />
                    }
                    {
                        status === 'error' &&
                        <BrokenImage color="primary" className={classes.fallbackIcon}/>
                    }
                    {
                        status === 'loaded' &&
                        <img alt="Preview" src={this.currentImage()}/>
                    }
                </div>
                <div className={classes.navigation}>
                    {
                        images.map((src, i) => (
                            <Avatar
                                key={i}
                                variant="rounded"
                                className={classNames(classes.thumbnail, (i == this.currentIndex()) && 'selected')}
                                src={status === 'loaded' ? src : undefined}
                                onClick={() => this.setState({ imageIndex: i })}>
                                {
                                    status === 'loading' &&
                                    <CircularProgress color="primary" size={20} />
                                }
                                {
                                    status === 'error' &&
                                    <BrokenImage className={classes.fallbackIcon}/>
                                }
                            </Avatar>
                        ))
                    }
                </div>
            </Backdrop>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(PictureViewModal));