
import { Permissions, Modules } from "@/config";
import Accounts from "@/pages/Accounts";
import AddBank from "@/pages/AddBank";
import AddDepartment from "@/pages/AddDepartment";
import AddDesignation from "@/pages/AddDesignation";
import AddReimbursable from "@/pages/AddReimbursable";
import AddLoan from "@/pages/AddLoan";
import Attendance from "@/pages/Attendance";
import AttendanceTerminals from "@/pages/AttendanceTerminals";
import Bank from "@/pages/Bank";
import Dashboard from "@/pages/Dashboard";
import Departments from "@/pages/Departments";
import Designations from "@/pages/Designations";
import Employee from "@/pages/Employee";
import EmployeeAdd from "@/pages/EmployeeAdd";
import EmployeeEdit from "@/pages/EmployeeEdit";
import Error from "@/pages/Error";
import Expire from "@/pages/Expire";
import Holiday from "@/pages/Holidays";
import Leaves from "@/pages/Leaves";
import Shifts from "@/pages/Shifts";
import Documents from "@/pages/Documents";

import AttendanceRules from "@/pages/AttendanceRules";
import Organization from "@/pages/Organization";
import OrganizationAdd from "@/pages/OrganizationAdd";
import OrganizationEdit from "@/pages/OrganizationEdit";
import OrganizationSetting from "@/pages/OrganizationSetting";
import PayItem from "@/pages/PayItem";
import Payrun from "@/pages/Payrun";
import PayrunAdd from "@/pages/PayrunAdd";
import PayrunView from "@/pages/PayrunView";
import Plan from "@/pages/Plan";
import Invoices from "@/pages/Invoices";
import PlanAdd from "@/pages/PlanAdd";
import PlanEdit from "@/pages/PlanEdit";
import Reimbursable from "@/pages/Reimbursable";
import Loan from "@/pages/Loans";
import Reports from "@/pages/Reports";
import Roles from "@/pages/Roles";
import SalaryLedgerReport from "@/pages/Reports/SalaryLedger";
import LoanLedgerReport from "@/pages/Reports/LoanLedger";
import LeaveStatementReport from "@/pages/Reports/LeaveStatement";
import DailyAttendanceStatus from "@/pages/Reports/DailyAttendanceStatus";
import MonthlyAttendanceSummary from "../pages/Reports/MonthlyAttendanceSummary";
import ResourceAvailability from "../pages/Reports/ResourceAvailability";
import EmployeeAttendanceSummaryReport from "../pages/Reports/EmployeeAttendanceSummaryReport";
import CheckInOutStatus from "@/pages/Reports/CheckInOutStatus";
import PayrollLeaveData from "@/pages/Reports/PayrollLeaveData";
import LeaveTypeWiseSummary from "@/pages/Reports/LeaveTypeWiseSummary";
import Settings from "@/pages/Settings";
import TaxStatementReport from "@/pages/Reports/TaxStatement";
import LeaveBalanceReport from "@/pages/Reports/LeaveBalance";

const RoutesPermissions = [
	{
		path: "/",
		component: Dashboard,
		exact: true,
		permissions: [
			Permissions.Permissions.COMPANY_DASHBOARD,
			// Permissions.Permissions.EMPLOYEE_DASHBOARD
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/dashboard",
		component: Dashboard,
		exact: true,
		permissions: [
			Permissions.Permissions.COMPANY_DASHBOARD,
			// Permissions.Permissions.EMPLOYEE_DASHBOARD
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/attendance",
		component: Attendance,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_EDITABLE,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_VIEWABLE
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/payroll/run",
		component: PayrunAdd,
		exact: true,
		permissions: [
			Permissions.Permissions.PAYROLL_FULL_ACCESS,
			Permissions.Permissions.PAYROLL_EDITABLE
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/payroll/:id",
		component: PayrunView,
		exact: false,
		permissions: [
			Permissions.Permissions.PAYROLL_FULL_ACCESS,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_VIEWABLE
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/payroll",
		component: Payrun,
		exact: false,
		permissions: [
			Permissions.Permissions.PAYROLL_FULL_ACCESS,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_VIEWABLE
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/employees/add",
		component: EmployeeAdd,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_FULL_ACCESS
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/employees/:id",
		component: EmployeeEdit,
		exact: false,
		permissions: [
			Permissions.Permissions.EMPLOYEES_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_VIEWABLE,
			Permissions.Permissions.PROFILE_EDITABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/employees",
		component: Employee,
		exact: false,
		permissions: [
			Permissions.Permissions.EMPLOYEES_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_VIEWABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/organizations/add",
		component: OrganizationAdd,
		exact: true,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/organizations/:id",
		component: OrganizationEdit,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,
			Permissions.Permissions.ORGANIZATIONS_VIEW_ACCESS
		]
	},
	{
		path: "/organizations",
		component: Organization,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,
			Permissions.Permissions.ORGANIZATIONS_VIEW_ACCESS
		]
	},
	{
		path: "/reports/tax-statement",
		component: TaxStatementReport,
		exact: true,
		permissions: [
			Permissions.Permissions.TAX
		],
		modules: [
			Modules.TAX
		]
	},
	{
		path: "/reports/salary-ledger",
		component: SalaryLedgerReport,
		exact: true,
		permissions: [
			Permissions.Permissions.SALARY_LEDGER_REPORT
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/reports/loan-ledger",
		component: LoanLedgerReport,
		exact: true,
		permissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modules: [
			Modules.LOAN
		]
	},
	{
		path: "/reports/leaves-statement",
		component: LeaveStatementReport,
		exact: true,
		permissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.LEAVE_ENCASHMENT
		]
	},
	{
		path: "/reports/payroll-leave-data",
		component: PayrollLeaveData,
		exact: true,
		permissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/reports/leaves-type-wise-summary",
		component: LeaveTypeWiseSummary,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/reports/leave-balances",
		component: LeaveBalanceReport,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},{
		path: "/reports/daily-attendance-status",
		component: DailyAttendanceStatus,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/reports/check-in-out-status",
		component: CheckInOutStatus,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	// {
	// 	path: "/reports/presence-hour-breakup",
	// 	component: PresenceHourBreakup,
	// 	exact: true,
	// 	permissions: [
	// 		Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
	// 	],
	// 	modules: [
	// 		Modules.ATTENDANCE
	// 	]
	// },
	{
		path: "/reports/monthly-attendance-summary",
		component: MonthlyAttendanceSummary,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/reports/resource-availablility",
		component: ResourceAvailability,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/reports/employee-attendance-summary",
		component: EmployeeAttendanceSummaryReport,
		exact: true,
		permissions: [
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/reports",
		component: Reports,
		exact: false,
		permissions: [
			Permissions.Permissions.TAX,
			Permissions.Permissions.SALARY_LEDGER_REPORT,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modules: [
			Modules.PAYROLL,
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/organization/:organizationId",
		component: OrganizationSetting,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/settings/attendance/terminals",
		component: AttendanceTerminals,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/leaves",
		component: Leaves,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/shifts",
		component: Shifts,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/documents",
		component: Documents,
		exact: false,
		permissions: [
			Permissions.Permissions.DOCUMENT_FULL_ACCESS,
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/holidays",
		component: Holiday,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/attendance/rules",
		component: AttendanceRules,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,
			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS
		],
		modules: [
			Modules.ATTENDANCE
		]
	},
	{
		path: "/settings/accounts/",
		component: Accounts,
		exact: false,
		permissions: [
			Permissions.Permissions.BANK_ACCOUNT_FULL_ACCESS,
			Permissions.Permissions.BANK_ACCOUNT_EDITABLE,
			Permissions.Permissions.BANK_ACCOUNT_VIEWABLE
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/settings/departments",
		component: Departments,
		exact: false,
		permissions: [
			Permissions.Permissions.DEPARTMENT_FULL_ACCESS,
			Permissions.Permissions.DEPARTMENT_EDITABLE,
			Permissions.Permissions.DEPARTMENT_VIEWABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
    {
		path: "/settings/departments/add",
		component: AddDepartment,
		exact: true,
		permissions: [
			Permissions.Permissions.DEPARTMENT_FULL_ACCESS
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/settings/departments/:departmentId",
		component: AddDepartment,
		exact: true,
		permissions: [
			Permissions.Permissions.DEPARTMENT_FULL_ACCESS
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/settings/designations",
		component: Designations,
		exact: false,
		permissions: [
			Permissions.Permissions.DESIGNATION_FULL_ACCESS,
			Permissions.Permissions.DESIGNATION_EDITABLE,
			Permissions.Permissions.DESIGNATION_VIEWABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
    {
		path: "/settings/designations/add",
		component: AddDesignation,
		exact: true,
		permissions: [
			Permissions.Permissions.DESIGNATION_FULL_ACCESS,
			Permissions.Permissions.DESIGNATION_EDITABLE,
			Permissions.Permissions.DESIGNATION_VIEWABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
    {
		path: "/settings/designations/:designationId",
		component: AddDesignation,
		exact: true,
		permissions: [
			Permissions.Permissions.DESIGNATION_FULL_ACCESS,
			Permissions.Permissions.DESIGNATION_EDITABLE,
			Permissions.Permissions.DESIGNATION_VIEWABLE
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},

	{
		path: "/settings/payitems",
		component: PayItem,
		exact: false,
		permissions: [
			Permissions.Permissions.PAYITEM_FULL_ACCESS,
			Permissions.Permissions.PAYITEM_EDITABLE,
			Permissions.Permissions.PAYITEM_VIEWABLE
		],
		modules: [
			Modules.PAYROLL
		]
	},
	{
		path: "/settings/banks/add",
		component: AddBank,
		exact: true,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/banks/:id",
		component: AddBank,
		exact: true,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/banks",
		component: Bank,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/roles",
		component: Roles,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS,
			Permissions.Permissions.ROLE_VIEWABLE,
			Permissions.Permissions.ROLE_EDITABLE,
			Permissions.Permissions.ROLE_FULL_ACCESS
		],
		modules: [
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/settings/plans/add",
		component: PlanAdd,
		exact: true,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/plans/:id",
		component: PlanEdit,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/plans",
		component: Plan,
		exact: false,
		permissions: [
			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		]
	},
	{
		path: "/settings/invoices",
		component: Invoices,
		exact: false,
		permissions: [
			Permissions.Permissions.BILLING_FULL_ACCESS
		]
	},
	{
		path: "/settings",
		component: Settings,
		exact: false,
		permissions: [
			Permissions.Permissions.BANK_ACCOUNT_VIEWABLE,
			Permissions.Permissions.BANK_ACCOUNT_EDITABLE,
			Permissions.Permissions.BANK_ACCOUNT_FULL_ACCESS,

			Permissions.Permissions.ORGANIZATION_VIEWABLE,
			Permissions.Permissions.ORGANIZATION_EDITABLE,
			Permissions.Permissions.ORGANIZATION_FULL_ACCESS,

			Permissions.Permissions.DEPARTMENT_VIEWABLE,
			Permissions.Permissions.DEPARTMENT_EDITABLE,
			Permissions.Permissions.DEPARTMENT_FULL_ACCESS,

			Permissions.Permissions.DESIGNATION_VIEWABLE,
			Permissions.Permissions.DESIGNATION_EDITABLE,
			Permissions.Permissions.DESIGNATION_FULL_ACCESS,

			Permissions.Permissions.PAYITEM_VIEWABLE,
			Permissions.Permissions.PAYITEM_EDITABLE,
			Permissions.Permissions.PAYITEM_FULL_ACCESS,

			Permissions.Permissions.EMPLOYEES_ATTENDANCE_FULL_ACCESS,
			
			Permissions.Permissions.BILLING_FULL_ACCESS,

			Permissions.Permissions.ORGANIZATIONS_FULL_ACCESS
		],
		modules: [
			Modules.BRANCH,
			Modules.PAYROLL,
			Modules.ATTENDANCE,
			Modules.EMPLOYEE_HISTORY,
			Modules.ROLE_AND_PERMISSION
		]
	},
	{
		path: "/reimbursements/add",
		component: AddReimbursable,
		exact: true,
		permissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modules: [
			Modules.REIMBURSEMENT
		]
	},
	{
		path: "/reimbursements/:id",
		component: AddReimbursable,
		exact: false,
		permissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modules: [
			Modules.REIMBURSEMENT
		]
	},
	{
		path: "/reimbursements",
		component: Reimbursable,
		exact: false,
		permissions: [
			Permissions.Permissions.PAYROLL_VIEWABLE,
			Permissions.Permissions.PAYROLL_EDITABLE,
			Permissions.Permissions.PAYROLL_FULL_ACCESS
		],
		modules: [
			Modules.REIMBURSEMENT
		]
	},
	{
		path: "/loans/add",
		component: AddLoan,
		exact: true,
		permissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modules: [
			Modules.LOAN
		]
	},
	{
		path: "/loans/:loansId",
		component: AddLoan,
		exact: false,
		permissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modules: [
			Modules.LOAN
		]
	},
	{
		path: "/loan/:id",
		component: AddLoan,
		exact: false,
		permissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modules: [
			Modules.LOAN
		]
	},
	{
		path: "/loans",
		component: Loan,
		exact: false,
		permissions: [
			Permissions.Permissions.LOAN_FULL_ACCESS
		],
		modules: [
			Modules.LOAN
		]
	},
	
	{
		path: "/error",
		component: Error,
		exact: true
	},
	{
		path: "/expired",
		component: Expire,
		exact: true
	}
];

export default RoutesPermissions;