import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Utility from "@/helpers/Utility";
import { currencyFormatter } from "@/helpers/generalHelper";
import { Permissions } from "@/config/Permissions";
import { UserRole } from "@/components";

import {
	Grid,
	TextField,
	Button,
	InputLabel,
	Card,
	Icon,
	CircularProgress,
	Switch,
	RadioGroup,
	Radio,
	FormControlLabel,
	Chip,
	InputAdornment
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import styles from "./style";
import {
	BackButton,
	PageTitle,
	Page,
	EmployeePayItemDropdown,
	EmployeeDropdown,
	FormattedNumericInput
} from "@/components";
import moment from "moment";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import PictureViewModal from "@/components/Modals/PictureViewModal";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import Config, { Constants } from "@/config";
import store from "@/redux";

class AddReimbursable extends Component {
	constructor (props) {
		super(props);
		this.state = {
			errors: {},
			requested_amount: "",
			approved_amount: "",
			admin_message: "",
			pay_item_id: "",
			employee: "",
			date: moment(moment.now(), "x").format("YYYY-MM-DD HH:mm:ss"),
			files: [],
			reimbursableId: "",
			reimbursable: "",
			disabled: false,
			filesList: [],
			removeFiles: [],
			isPictureViewModalOpen: false,
			imageIndex: 0,
			isLoading: false,
			isEdit: false,
			remaining: 0,
			pay_in_next_payroll: true,
			status: "pending",
			remainingAmount: null,
			selectedReimbursable: "",
			rate: 0,
			employeePayItem: {}
		};
	}
	static propTypes = {
		textFieldProps: PropTypes.object,
		value: PropTypes.number,
		onChange: PropTypes.func,
	};

	async componentDidMount() {
		if (this.props.match.params && this.props.match.params.id > 0) {
			store.dispatch(setLoader(true));
			this.setState({ isLoading: true, isEdit: true });
			let reimbursable = await Api.reimbursable(
				this.props.match.params.id
			).get();
			
			this.setState({
				requested_amount: reimbursable.requested_amount,
				approved_amount: reimbursable.approved_amount,
				admin_message: reimbursable.admin_message || "",
				pay_item_id: reimbursable.pay_item_id,
				employee: reimbursable.employee_id,
				date: reimbursable.date,
				reimbursable,
				isLoading: false,
				remaining: reimbursable.remaining,
				status: reimbursable.status,
				pay_in_next_payroll: reimbursable.pay_in_next_payroll,
				filesList: reimbursable.reimbursable_images,
				disabled: reimbursable.status == Constants.Reimbursable.FLAG_PAID || reimbursable.is_paid,
			}, () => {
				store.dispatch(setLoader(false));
			});
		}
	}
	modalOpen = () => { };

	modalClose = () => {
		this.setState({ isPictureViewModalOpen: false });
	};

	viewPicture = (imageIndex) => {
		this.setState({ isPictureViewModalOpen: true, imageIndex });
	};

	images() {
        const { filesList } = this.state;
        return filesList.map(image => {
			if (image.id) {
				return Api.reimbursable(image.reimbursable_id).image.getUrl({ image: image.id })
			}
			return URL.createObjectURL(image);
		});
    }

	isValidData = () => {
		const { isEdit } = this.state;
		var errors = {};
		if (this.state.employee === "" && !isEdit) {
			errors["employee"] = `${_.startCase("employee")} field is required`;
		}
		if (this.state.pay_item_id === "") {
			errors["pay_item_id"] = `${_.startCase("pay_item_id")} field is required`;
		}
		if (this.state.requested_amount === "") {
			errors["requested_amount"] = `${_.startCase("requested_amount")} field is required`;
		}
		if (!this.state.status) {
			errors["status"] = `${_.startCase("status")} field is required`;
		}
		if (this.state.admin_message.match("^[0-9]") || this.state.admin_message.match("^[^a-zA-Z0-9]+$")) {
			errors["admin_message"] = `${_.startCase("not valid value")} for this field`;
		}
		if (this.state.status === Constants.Reimbursable.FLAG_APPROVED) {
			if (this.state.approved_amount === "" || this.state.approved_amount === null) {
				errors["approved_amount"] = `${_.startCase("approved_amount")} field is required`;
			} else if (parseFloat(this.state.approved_amount) <= 0) {
				errors["approved_amount"] = `${_.startCase("approved_amount")} should be greater than zero`;
			} else if (parseFloat(this.state.approved_amount) > parseFloat(this.state.requested_amount)) {
				errors["approved_amount"] = `${_.startCase("approved_amount")} should not be greater than requested amount`;
			}
		}
		if(!this.state.filesList.length){
			errors["attachment"] = `${_.startCase("attachment")} field is required`;
		}
		if (_.size(errors)) {
			this.props.setSnackbar(
				true,
				"Please fix the highlighted errors",
				"error"
			);
		}
		this.setState({ errors });
		return !(_.size(errors) > 0);
	};

	nonFieldErrors = (errorArray) => {
		if (errorArray[0].hasOwnProperty("reimbursable")) {

			this.props.setSnackbar(
				true,
				errorArray[0]["reimbursable"][0],
				"error"
			);
		}
		else if (errorArray[0].hasOwnProperty("remove_files")) {
			this.props.setSnackbar(
				true,
				errorArray[0]["remove_files"][0],
				"error"
			);
		}
		else if (errorArray[0].hasOwnProperty("attachment")) {
			this.setState({ errors: { attachment: errorArray[0]["attachment"][0] } })
		}
		else {
			this.props.setSnackbar(
				true,
				"Unable to add reimbursement for employee",
				"error"
			);
		}

	}

	createReimbursable = async () => {

		if (this.isValidData()) {

			const files = _.reject(this.state.filesList, (file) => {
				return !!file.id;
			});
			var data = new FormData();

			if (this.state.status === Constants.Reimbursable.FLAG_REJECTED) {
				data.append("pay_in_next_payroll", 0);
			} else {
				data.append("pay_in_next_payroll", this.state.pay_in_next_payroll ? 1 : 0);
			}
			data.append("pay_item_id", this.state.pay_item_id);
			data.append("requested_amount", this.state.requested_amount);
			data.append("admin_message", this.state.admin_message);
			data.append("date", moment(this.state.date).format("YYYY-MM-DD"));
			data.append("status", this.state.status);
			if (this.state.status === Constants.Reimbursable.FLAG_APPROVED) {
				data.append("approved_amount", this.state.approved_amount);
			}
			files.forEach((file, i) => { data.append(`attachment[${i}]`, file); });
			if (this.state.reimbursable !== "") {
				try {
					this.state.removeFiles.forEach((id, i) => { data.append(`remove_files[${i}]`, id); });
					data.append("_method", "put");
					store.dispatch(setLoader(true));
					await Api.reimbursable(this.state.reimbursable.id).post(data);
					this.props.setSnackbar(true, "Reimbursement updated for employee");
					this.setState({ filesList: [], removeFiles: [] }, () => {
						store.dispatch(setLoader(false));
					});
					this.props.history.push("/reimbursements");
				} catch (e) {
					store.dispatch(setLoader(false));
					HttpErrorHandler.intercept(e)
						.on(422, response => {
							this.setState({ errors: _.mapValues(response.data, value => value[0]) });
							if (_.size(response.data) === 1 && _.has(response.data, "pay_item_id")) {
								this.props.setSnackbar(true, response.data["pay_item_id"], "error");
							}
							else {
								this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
							}
						})
						.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
						.go();
						
					this.setState({ errors: { ...e.response.data } }, () => {
						var errorArray = [{ ...e.response.data }]
						this.nonFieldErrors(errorArray)
					})
				}
			} else {
				try {
					store.dispatch(setLoader(true));
					await Api.reimbursable(this.state.employee)
						.pay_items(this.state.pay_item_id)
						.post(data, {
							headers: {
								"Content-Type": "multipart/form-data",
							}
						});
					this.props.setSnackbar(true, "Reimbursement added for employee");
					this.setState({ filesList: [], removeFiles: [] });
					store.dispatch(setLoader(false));
					this.props.history.push("/reimbursements");
				} catch (e) {
					store.dispatch(setLoader(false));
					HttpErrorHandler.intercept(e)
						.on(422, response => {
							this.setState({ errors: _.mapValues(response.data, value => value[0]) });
							if (_.size(response.data) === 1 && _.has(response.data, "pay_item_id")) {
								this.props.setSnackbar(true, response.data["pay_item_id"], "error");
							}
							else {
								this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
							}
						})
						.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
						.go();

					this.setState({ errors: { ...e.response.data } }, () => {
						var errorArray = [{ ...e.response.data }]
						this.nonFieldErrors(errorArray)
					})
				}
			}
		}
	};

	handleChange = (e, fieldName) => {
		if (
			fieldName === "date" ||
			fieldName === "employee" ||
			fieldName === "pay_item_id" ||
			fieldName === "pay_in_next_payroll" ||
			fieldName === "status" ||
			fieldName === "remainingAmount"
		) {
			if (fieldName === "status" && (this.state.status === Constants.Reimbursable.FLAG_PAID)) {
				return;
			}
			else if (fieldName === "status" && (e === Constants.Reimbursable.FLAG_REJECTED || e === Constants.Reimbursable.FLAG_PENDING)) {
				this.setState({ [fieldName]: e, pay_in_next_payroll: false });
			} else {
				if (fieldName === "remainingAmount") {

					if (e >= 0) {
						this.setState({ [fieldName]: `Remaining ${currencyFormatter(e)} out of ${currencyFormatter(this.state.rate)}` });
					} else if (e < 0) {
						this.setState({ [fieldName]: `Limit exceeded by ${currencyFormatter(e)}` });
					} else {
						this.setState({ [fieldName]: null });
					}
				} else {
					this.setState({ [fieldName]: e });
				}
			}
		} else {
			this.setState({ [fieldName]: e.currentTarget.value });
		}
	};

	UploadFiles = (e) => {
		const { filesList } = this.state;
		if (e.target.files) {
			_.forEach(e.target.files, (file) => {
				if (file && file.name && file.name.match(/(png|jpg|jpeg)$/)) {
					filesList.push(file);
				}
			});
		}
		this.setState({ filesList });
	};

	removePicture = (file, i) => {
		const { filesList, removeFiles } = this.state;
		filesList.splice(i, 1);
		if (file.id) {
			removeFiles.push(file.id);
		}
		this.setState({ filesList, removeFiles });
	};

	render() {
		const { classes } = this.props;
		const {
			isEdit,
			errors,
			disabled,
			isPictureViewModalOpen,
			imageIndex,
			selectedReimbursable,
			isLoading,
			filesList,
			status
		} = this.state;
		const remaining = this.state.remaining;

		return (
			<Page title="Reimbursement">
				<Link to={{ pathname: `/reimbursements` }} className={classes.link}>
					<BackButton route="/reimbursements" label="Back to Reimbursement" />
				</Link>
				{!this.state.reimbursable && <PageTitle>Add Reimbursement</PageTitle>}
				<Card raised>
					<Grid container className={classes.containerGrid}>
						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Status</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							{
								this.state.status === Constants.Reimbursable.FLAG_PAID
									? <Chip className={classes.chip} size="small" label={this.state.status.toUpperCase()} color="primary"/>
									: (
										<RadioGroup
											aria-label="Status"
											name="status"
											value={this.state.status || ""}
											onChange={(e) => this.handleChange(e.target.value, "status")}
											row
										>
											<FormControlLabel value={Constants.Reimbursable.FLAG_PENDING} control={<Radio className={classes.switchPending} />} label="Pending" />
											<FormControlLabel value={Constants.Reimbursable.FLAG_APPROVED} control={<Radio className={classes.switchApproved} />} label="Approved" />
											<FormControlLabel value={Constants.Reimbursable.FLAG_REJECTED} control={<Radio className={classes.switchReject} />} label="Rejected" />
										</RadioGroup>
									)
							}
						</Grid>
						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Employee</InputLabel>
						</Grid>
						<Grid item xs={7} sm={7} md={8}>
							<div className={classNames(classes.textField)}>
								<EmployeeDropdown
									disabled={disabled || isEdit}
									label={this.state.employee ? "" : "Employee"}
									placeholder="Employee"
									textFieldProps={{
										error: !!errors.employee,
										helperText: errors.employee,
										FormHelperTextProps: { variant: "standard" },
									}}
									onChange={(employee) =>
										this.handleChange(employee, "employee")
									}
									value={this.state.employee}
									isActive = {true}
								/>
							</div>
						</Grid>
						{
							this.state.employee &&
							<>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>Pay Item</InputLabel>
								</Grid>
								<Grid item xs={7} sm={7} md={8} className={classes.marginGrid}>
									<div className={classNames(classes.textField)}>
										<EmployeePayItemDropdown
											disabled={disabled || isEdit}
											textFieldProps={{
												error: !!errors.pay_item_id,
												helperText: errors.pay_item_id,
												FormHelperTextProps: { variant: "standard" },
											}}
											value={this.state.pay_item_id}
											filter={{ reimbursable: true }}
											employee={this.state.employee}
											getSelected={(employeePayItem) => this.setState({ employeePayItem })}
											onChange={(pay_item) => {
												if (pay_item) {
													this.setState({ rate: pay_item.rate }, () => {
														this.handleChange(pay_item.remaining, "remainingAmount")
														this.handleChange(pay_item.details.id, "pay_item_id")
													})
												} else {
													this.handleChange("", "remainingAmount")
													this.handleChange("", "pay_item_id")
												}
											}
											}
										/>
									</div>
								</Grid>
							</>
						}
						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Requested Amount</InputLabel>
						</Grid>
						<Grid container xs={7} sm={7} md={8}>
							<TextField
								disabled={disabled}
								InputProps={{
									endAdornment: <InputAdornment position="end"><span style={{color: "rgba(0, 0, 0, 0.54)"}}>{ Config.CURRENCY_SYMBOL }</span></InputAdornment>,
									inputComponent: FormattedNumericInput,
								}}
								error={!!errors.requested_amount}
								helperText={
									errors.requested_amount || (this.state.employeePayItem &&
										((remaining > 0 && `Remaining ${currencyFormatter(remaining)} out of ${currencyFormatter(this.state.employeePayItem.rate)}`)
											||
											(remaining < 0 && `Limit exceeded by ${currencyFormatter(remaining)}`)))
								}
								FormHelperTextProps={{ variant: "standard" }}
								placeholder="Amount"
								className={classNames([
									classes.textField,
									classes.marginTextInput,
								])}
								value={this.state.requested_amount || ""}
								onChange={(e) => this.handleChange(e, "requested_amount")}
								variant="outlined"
							/>
						</Grid>
						{
							(this.state.status === Constants.Reimbursable.FLAG_APPROVED || this.state.status === Constants.Reimbursable.FLAG_PAID) &&
							<>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>Approved Amount</InputLabel>
								</Grid>
								<Grid container xs={7} sm={7} md={8}>
									<TextField
										disabled={disabled}
										InputProps={{
											endAdornment: <InputAdornment position="end"><span style={{color: "rgba(0, 0, 0, 0.54)"}}>{ Config.CURRENCY_SYMBOL }</span></InputAdornment>,
											inputComponent: FormattedNumericInput,
										}}
										error={!!errors.approved_amount}
										helperText={errors.approved_amount}
										FormHelperTextProps={{ variant: "standard" }}
										placeholder="Amount"
										className={classNames([
											classes.textField,
											classes.marginTextInput,
										])}
										value={this.state.approved_amount || ""}
										onChange={(e) => this.handleChange(e, "approved_amount")}
										variant="outlined"
									/>
								</Grid>
							</>

						}

						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Date</InputLabel>
						</Grid>

						<Grid item xs={7} sm={7} md={8}>
							<DatePicker
								disabled={disabled}
								// className={classes.marginGrid}
								fullWidth
								label={this.state.date ? "" : "Date"}
								className={classNames([classes.textField, classes.marginGrid])}
								inputVariant="outlined"
								format="dd MMMM, yyyy"
								FormHelperTextProps={{ variant: "standard" }}
								value={moment(this.state.date || "")}
								onChange={(date) => this.handleChange(date, "date")}
							/>
						</Grid>


						<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
							<InputLabel>Admin Message</InputLabel>
						</Grid>
						<Grid container xs={7} sm={7} md={8}>
							<TextField
								disabled={disabled}
								error={!!errors.admin_message}
								helperText={errors.admin_message}
								FormHelperTextProps={{ variant: "standard" }}
								placeholder="write a message"
								className={classNames([
									classes.textField,
									classes.marginTextInput,
								])}
								multiline={true}
								rows={4}
								value={this.state.admin_message || ""}
								onChange={(e) => this.handleChange(e, "admin_message")}
								variant="outlined"
							/>
						</Grid>

						<Grid item xs={3} sm={3} md={4} className={[classes.labelGrid, classes.attachmentGrid]}>
							<InputLabel>Attachment</InputLabel>
						</Grid>
						<Grid item className={classes.circularGrid} xs={7} sm={7} md={8}>
							<div className={classNames(classes.textField)}>
								{isLoading && (
									<CircularProgress color="inherit" size={20} />
								)}
								{filesList.map((file, i) => {
									return (
										<div className={classes.imageContainer}>
											{
												status !== Constants.Reimbursable.FLAG_PAID &&
												<div className={classes.crossButton} onClick={() => this.removePicture(file, i)}>
													<Icon className={classes.cross}>close</Icon>
												</div>
											}
											{
												file.id
													? <img alt="Reimbursement" className={classes.image} src={Utility.getReimbursementImageUrl(file.reimbursable_id, file.id)} onClick={() => this.viewPicture(i, filesList)} />
													: <img alt="Reimbursement" className={classes.image} src={URL.createObjectURL(file)} />
											}
										</div>
									);
								})}
								{
									!isLoading && status !== Constants.Reimbursable.FLAG_PAID && 
									<div className={classNames(classes.uploadButton, errors.attachment ? classes.uploadButtonColorError : classes.uploadButtonColor)}>
										<AddAPhotoOutlinedIcon className={errors.attachment ? classes.addPhotoIconError : classes.addPhotoIcon} fontSize="large" />
										<input
											className={classes.fileInput}
											accept=".jpg, .png, .jpeg"
											multiple
											type="file"
											name="files"
											id="files"
											onChange={this.UploadFiles}
										/>
									</div>
								}
							</div>
							<span className={classes.attachementError}>{errors.attachment}</span>

							{
								isPictureViewModalOpen &&
								<PictureViewModal
									images={this.images()}
									defaultIndex={imageIndex}
									onClose={this.modalClose}
								/>
							}
						</Grid>
						{
							(this.state.status === Constants.Reimbursable.FLAG_APPROVED) &&
							<>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}>
									<InputLabel>Pay in next Payroll</InputLabel>
								</Grid>
								<Grid item xs={7} sm={7} md={8}>
									<Switch
										disabled={disabled}
										checked={this.state.pay_in_next_payroll}
										onChange={() =>
											this.handleChange(
												!this.state.pay_in_next_payroll,
												"pay_in_next_payroll"
											)
										}
										color="primary"
									/>
								</Grid>
							</>
						}
					</Grid>
					<Grid
						container
						className={classNames(classes.containerGrid, classes.footer)}
					>
						{!disabled && !isLoading && (
							<>
								<Grid item xs={3} sm={3} md={4} className={classes.labelGrid}></Grid>
								{status !== Constants.Reimbursable.FLAG_PAID &&
									<Grid item xs={7} sm={7} md={8}>
										<UserRole routePermissions={[
											Permissions.ROLE_EDITABLE,
											Permissions.ROLE_FULL_ACCESS]}>
											<Button
												className={classes.actionButton}
												variant="contained"
												color="primary"
												onClick={this.createReimbursable}
											>
												{isEdit ? "Save" : "Create"}
											</Button>
										</UserRole>
										<Button
											className={classes.actionButton}
											variant="contained"
											color="secondary"
											onClick={() => this.props.history.push("/reimbursements")}
										>
											Cancel
										</Button>
									</Grid>}
							</>
						)}
					</Grid>
				</Card>
			</Page >
		);
	}
}

AddReimbursable.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
	connect(null, mapDispatchToProps)(withStyles(styles)(AddReimbursable))
);
