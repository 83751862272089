export default theme => ({

    chip: {
        marginTop: -5,
        marginRight: theme.spacing(1)
    },

    employeeName: {
        marginRight: theme.spacing(1),

        "&::after": {
            display: "inline",
            content: "'-'",
            marginLeft: theme.spacing(1)
        }
    }
    
});