export default {
    GATrackingID: "G-L43R",
    payitem: {
        type: [
            'Wages',
            'Taxes',
            'Deduction',
            'Allowances',
            'Non-taxable Allowances',
            'Post-tax Deductions',
            // 'Loans'
            // 'Employer Contributions'
        ],
        taxType: [
            'Cash',
            'Vehicle',
            'Domestic Employee',
            'Utility Bills',
            'Interest Free Loan',
            'Property',
            'Charitable Donation',
            'Life Insurance',
            'Mutual Fund',
            'IPO',
            'Health Insurance'
        ],
        accountFor: [
            '310 - Cost of Goods Sold',
            '400 - Advertising',
            '404 - Bank Fees',
            '408 - Cleaning',
            '412 - Consulting & Accounting',
            '416 - Depreciation',
            '420 - Entertainment',
            '425 - Freight & Courier',
            '429 - General Expenses',
            '433 - Insurance',
            '437 - Interest Expense',
            '441 - Legal expenses',
            '445 - Light, Power, Heating',
            '449 - Motor Vehicle Expenses',
            '453 - Office Expenses',
            '461 - Printing & Stationery',
            '469 - Rent',
            '473 - Repairs and Maintenance',
            '477 - Wages and Salaries',
            '478 - Superannuation',
            '485 - Subscriptions',
            '489 - Telephone & Internet',
            '493 - Travel - National',
            '494 - Travel - International',
            '499 - Realised Currency Gains',
            '505 - Income Tax Expense',
            ''
        ],
        paymentFrequency: [
            'Monthly',
            'Quarterly',
            'Bi-Annually',
            'Annually'
        ]
    },
    plan: {
        type: {
            ONE_TIME: 'One Time',
            RECURRING: 'Recurring',
        },
        validity: {
            MINUTES: "Minutes",
            HOURS: "Hours",
            DAYS: "Days",
            WEEKS: "Weeks",
            QUARTERS: "Quarters",
            MONTHS: "Months",
            YEARS: "Years"
        }
    },
    Reimbursable: {
        FLAG_PENDING: "pending",
        FLAG_APPROVED: "approved",
        FLAG_REJECTED: "rejected",
        FLAG_PAID: "paid"
    },
    Loan: {
        FLAG_PENDING: "pending",
        FLAG_APPROVED: "approved",
        FLAG_REJECTED: "rejected",
        FLAG_GRANT: "granted",
        FLAG_RETURNED: "returned",
        FLAG_PARTIAL: "partial",

    },
    leaveStatus: {
        FLAG_PENDING: "pending",
        FLAG_APPROVED: "approved",
        FLAG_REJECTED: "rejected",

    },
    EmployeeLeaves: {
        leaveFrequency: [
            'Monthly',
            'Quarterly',
            'Bi-Annually',
            'Annually'
        ],
        leaveDeduction: [
            'Manually',
            'Monthly',
            'Annually'
        ]
    },
    EmployeeLates: {
        lateDeduction: [
            'Manually',
            'Monthly',
            'Annually'
        ]
    },
    AttendanceCorrection: {
        Check: [
            "check-in",
            "check-out",
        ]
    },
    Options: [
        "attendance_rule_late_occurrence",
        "attendance_rule_leave_deduction",
        "attendance_rule_leave_deduction_type",
        "attendance_rule_leave_deduct_from_other_quota",
        "salary_calculate_by_day"
    ],
    EmployeeDailySalaryTypesDetail: [
        {
            key: "SALARY_CALCULATE_30_DAYS",
            value:   "30_days",
            label: "30 Days",
        },
        {
            key: "SALARY_CALCULATE_NUMBER_OF_WORKING_DAYS",
            value:   "working_days",
            label: "Working days in a month",
        },
        {
            key: "SALARY_CALCULATE_DAYS_IN_MONTH",
            value: "days_in_month",
            label: "Days in a month",
        }
    ],
    payItemsName: {
        deductionLeaves: "Leaves"
    },
    DocMimeTypes: {
        pdf: ["application/pdf"],
        image: ["image/jpeg", "image/png", "image/bmp"],
        doc: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
        spreadsheet: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
        presentation: ["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"]
    }
};
