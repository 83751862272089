import React, { Component } from "react";
import { Button, Card, CardActions, Icon, Grid, Badge } from "@material-ui/core";
import { DLTable, InfographicMessage, Page, PermissionHOC } from "@/components";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { connect } from "react-redux";
import DocumentTableRow from "./DocumentTableRow";
import _ from "lodash";
import { Api } from "@/helpers";
import AddDocumentModal from "../AddDocument";
import Filter from "@/components/Filter";
import Types from "@/components/Filter/types";

const FilterData = [
    {
        key: "name",
        label: "Name",
        type: Types.string,
        example: "HR Policy"
    },
    {
        key: "category",
        label: "Category",
        type: Types.selection,
		options: async () => {
			try {
				const response = await Api.organization_document.categories.get({ per_page: 0, sort: { column: 'name', order: 'asc' } });
				return response.map(category => ({ key: category, label: category }));

			} catch {
				return [];
			}
		},
        example: "Policies"
    },
    {
        key: "created_at",
        label: "Added On",
        type: Types.date
    },
    {
        key: "updated_at",
        label: "Last Updated",
        type: Types.date
    }
];

class Document extends Component {
    constructor(props) {
        super(props);
        this.actionBarRef = React.createRef();
        this.bulkAction = React.createRef();
        this.tableRef = React.createRef();
    }
    
    state = {
        filters: [],
        fields: FilterData,
        isModalOpen:false
    };

    getDocuments = (filters = []) => {
        const { filters: oldFilters } = this.state;
		this.setState({ filters }, () => {
			if (!!oldFilters && this.tableRef && this.tableRef.current) {
				this.tableRef.current.fetchData();
			}
		});
    };

    reload = () => {
        this.setState({ isModalOpen: false });
        this.tableRef && this.tableRef.current.fetchData();
    };

    addNew = () => {
        this.setState({ isModalOpen: true });
    };
    
    emptyView() {
        const message = "You don't have any documents yet, add one now!";
        return (
            <InfographicMessage
                illustration="document"
                message={message}
                actionLabel={"Add Document"}
                actionIcon={"add"}
                actionHandler={this.addNew}
            />
        );
    }

    render() {
        const { filters, fields } = this.state;
        return (
            <Page title="Organization Documents">
                <Card raised>
                <Grid container justifyContent={"space-between"}>
                    <CardActions>
                        <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={this.addNew}>
                            <Icon>add</Icon>
                            Add Document
                        </Button>
                    </CardActions>
                    <CardActions>
                        <Filter
                            fields={fields}
                            filters={filters}
                            withQueryParams
                            onUpdate={(filters) => this.getDocuments(filters)}
                        />
                    </CardActions>
                </Grid>
                <DLTable
                    ref={this.tableRef}
                    api={Api.organization_document}
                    params={{ filter: filters }}
                    RowTemplate={DocumentTableRow}
                    emptyView={this.emptyView()} />
            </Card>
            {
                this.state.isModalOpen &&
                <AddDocumentModal
                    employeeDocument = {false}
                    title={"Add Document"}
                    employee={this.props.employee}
                    onClose = {() => this.setState({ isModalOpen: false })}
                    onUpdate={this.reload}
                />
            }
        </Page>
        );
    }

}
const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(PermissionHOC(Document));
