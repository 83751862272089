const styles = theme => ({
	chip: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
	containerGrid: {
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2)
	},
	textField: {
		width: "50%",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		'& p': {
			color: theme.color.blue.dodger
		},
	},
	formControl: {
		width: "50%",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		marginTop: theme.spacing(1)
	},
	labelGrid: {
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: theme.spacing(2),
		alignItems: "center"
	},
	inputLabel: {
		textAlign: "end"
	},
	footer: {
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(2),
		justifyContent: "flex-end",
		[theme.breakpoints.down("sm")]: {
			paddingRight: 0
		}
	},
	createBtn: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	imageContainer: {
		width: "75px",
		height: "75px",
		position: "relative",
		display: "inline-block",
		cursor: "pointer",
		verticalAlign: "top",
		marginBottom: "15px",
		border: "0.5px solid #EDEDED",
		borderRadius: "6px",
		marginRight: "20px"
	},
	crossButton: {
		backgroundColor: "rgb(0, 0, 0)",
		borderRadius: "50%",
		position: "absolute",
		right: "0px",
		width: "18px",
		height: "18px",
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		transform: "translateX(50%) translateY(-50%)"
	},
	cross: {
		color: "white",
		fontSize: "14px"
	},
	image: {
		width: "100%",
		height: "100%",
		borderRadius: "6px"
	},
	uploadButtonColor: {
		border: "2px dashed rgba(42, 157, 255, 0.5)",
	},
	uploadButtonColorError: {
		border: "2px dashed #FA4322",
	},
	uploadButton: {
		display: "inline-flex",
		flexDirection: "column",
		position: "relative",
		background: theme.palette.background.main,
		boxSizing: "border-box",
		borderRadius: "6px",
		justifyContent: "center",
		alignItems: "center",
		width: "75px",
		height: "75px",
		fontFamily: theme.typography.fontFamily,
		cursor: "pointer",
		verticalAlign: "top"
	},
	fileInput: {
		display: "block",
		cursor: "pointer",
		outline: "none",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		overflow: "hidden",
		filter: "alpha(opacity=0)",
		opacity: "0"
	},
	actions: {
		padding: theme.spacing(2)
	},

	actionButton: {
		margin: "0px 5px",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},

	actionButtonIcon: {
		marginRight: theme.spacing(1)
	},
	marginTextInput: {
		marginTop: "8px"
	},
	link: {
		textDecoration: 'none',
		color: "primary"
	},
	marginGrid: {
		marginTop: "10px"
	},
	attachmentGrid: {
		marginTop: "20px",
		marginBottom: "10px",
		alignItems: "flex-start"
	},
	circularGrid: {
		marginTop: "20px",
		marginBottom: "10px",

	},
	addPhotoIconError: {
		color: theme.color.red.pomegranate

	},
	addPhotoIcon: {
		color: "rgba(42, 157, 255, 0.5)"
	},
	attachementError: {
		color: theme.color.red.pomegranate,
		fontSize: "0.75rem",
		marginTop: "3px",
		textAlign: "left",
		fontWeight: "400",
		lineHeightL: "1.66",
		fontFamily: theme.typography.fontFamily,
		letterSpacing: "0.03333em"
	},
	switchApproved:{
		// color: "#1DA729",
		'&.Mui-checked': {
			color: "#1976d2",
		},
	},
	switchReject:{
		// color: "#E20613" ,
		'&.Mui-checked': {
			color: "#1976d2",
		},
	},
	switchPending:{
		// color: "#666",
		'&.Mui-checked': {
			color: "#1976d2",
		},
	},
	
});

export default styles;