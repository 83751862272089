import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AsyncDropdown } from "@/components";
import { Api } from "@/helpers";
import styles from "./style";

class PlanDropdown extends Component {

    static defaultProps = {
        label: "Plan",
        onChange: () => {}
    };

    static propTypes = {
        textFieldProps: PropTypes.object,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        plan: null
    };

    componentDidMount() {
        this.loadSelected();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && (!this.state.plan || (this.state.plan.id !== this.props.value))) {
            this.loadSelected();
        }
    }

    async loadSelected() {
        if (this.props.value) {
            try {
                const plan = await Api.plans(this.props.value).get();
                this.props.onChange((plan));
                return this.setState({ plan });
            } catch(e) { }
        }
        this.setState({ plan: null });
    }

    loadOptions = async (keyword = "") => {
        this.setState({ loading: true });
        const response = await Api.plans.get({ filter: { title: keyword } });
        return response.data;
    };

    onChange = (event, plan) => {
        this.setState({ plan });
        this.props.onChange((plan) || null);
    };

    renderOption = option => {
        return (
            <div className={this.props.classes.itemStyle}>
                {option.title}
            </div>
        )
    };

    render() {
        const { classes, label, disabled, textFieldProps } = this.props;
        return (
            <AsyncDropdown
                classes={{ paper: classes.dropdown }}
                label={label}
                textFieldProps={textFieldProps}
                getOptions={this.loadOptions}
                getOptionLabel={option => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.onChange}
                value={this.state.plan}
                disabled={!!disabled}
                renderOption={this.renderOption}
            />
        );
    }

}

export default withStyles(styles)(PlanDropdown);