const styles = theme => ({
    
    title: {
        fontSize: "28px",
        lineHeight: "48px",
        verticalAlign: "middle",
        fontFamily: theme.typography.fontFamily,
        marginBottom: theme.spacing(1)
    }

});

export default styles;