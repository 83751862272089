const styles = theme => ({
    containerGrid: {
        marginTop: theme.spacing(2),
    },
    switch: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: 0
	},
    textField: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formControl: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        marginTop: theme.spacing(1)
    },
    labelGrid: {
        display: 'flex',
        // justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    inputLabel: {
        textAlign: 'end'
    },
    footer: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    createBtn: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    hidden: {
        display: 'none'
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    labelPlacementStart: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "-11px",
        marginLeft: "5px"
    },
    attachementError: {
		color: theme.color.red.pomegranate,
		fontSize: "0.75rem",
		marginTop: "3px",
		textAlign: "left",
		fontWeight: "400",
		lineHeightL: "1.66",
		fontFamily: "Roboto",
		letterSpacing: "0.03333em"
	},
    attachement: {
		color: theme.color.silver.main,
		fontSize: "0.75rem",
		marginTop: "5px",
		textAlign: "left",
		fontWeight: "500",
		lineHeightL: "1.66",
		fontFamily: "Roboto",
		letterSpacing: "0.03333em"
	},
});

export default styles