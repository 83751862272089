export default{
    PAYROLL                 : "PAYROLL",
    TAX                     : "TAX",
    REIMBURSEMENT           : "REIMBURSEMENT",
    
    ATTENDANCE              : "ATTENDANCE",
    ROSTER                  : "ROSTER",
    LEAVE_ENCASHMENT        : "LEAVE_ENCASHMENT",
    OVERTIME                : "OVERTIME",
    
    ROLE_AND_PERMISSION     : "ROLE_AND_PERMISSION",
    EMPLOYEE_HISTORY        : "EMPLOYEE_HISTORY",
    BRANCH                  : "BRANCH",
    LOAN                    : "LOAN",
    SALARY_LEDGER           : "SALARY_LEDGER",

    DOCUMENT                : "DOCUMENT"
};