import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Icon, IconButton, Badge, InputAdornment, TextField, Grid } from "@material-ui/core";
import EmployeeList from "./EmployeeList";
import InfographicMessage from "../../components/InfographicMessage"
import styles from "./style";
import _ from "lodash";
import { Add } from "@material-ui/icons";
import { FilterList } from "@material-ui/icons";
import FilterData from "@/pages/Employee/FilterData";
import moment from "moment";
import DrawerOpenIcon from "@/assets/icons/drawer-open-icn.png";
import DrawerCloseIcon from "@/assets/icons/drawer-close-icn.png";
import classNames from "classnames";
import WeekPicker from "../../components/WeekPicker";
import EmployeeSelectionModal from "@/components/Modals/EmployeeSelectionModal";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { setLoader, setSnackbar } from "@/redux/actions/general";
import { Api, HttpErrorHandler } from "@/helpers";
import { connect } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";
import DLTablePaginationActions from "@/components/DLTable/DLTablePaginationActions";
import Filter from "@/components/Filter";


class RosterContainer extends Component {
	constructor(props) {
		moment.updateLocale('en', {
			week: {
			  dow : 1, // Monday is the first day of the week.
			}
		  });
		super(props)
		this.weekCalender = React.createRef();

		var showFilter = false;
		this.state = {
			selectedEmployee: 1,
			collapse: false,
			delayCollapse: false,
			largeDelay: false,
			showFilter,
			fields: FilterData,
			filters: [],
			search: "",
			weekDate: moment().utc(),
			employeesModal: false,
			searchEmployee: "",
			exportEmployeeList: [],
			rosterEmployee: [],
			meta: {
				per_page: 50,
				page: 1
			},
			shiftData: [],

		};
	}
	async componentDidMount() {
		this.getRosterAssignments();
	}

	fetchShift = async () => {
		try {
			var response = await Api.shifts.get();
			this.setState({ shiftData: response.data })
		} catch (e) {
			console.log(e)
		}
	}

	getRosterAssignments = async (isLoader = true, filter = [], metaData = this.state.meta, search = this.state.search) => {
		this.fetchShift();
		this.props.setLoader(isLoader);
		var body = {
			week: moment(this.state.weekDate).isoWeek(),
			year: moment(this.state.weekDate).format("YYYY"),
			...metaData
		}
		delete body.total;
		delete body.last_page;
		delete body.current_page;
		if (search || filter.length) {
			if (this.state.meta.page < 2) {
				delete body.page
			}
			if (search) {
				body.filter = [{
					field: "search",
					value: search,
					method: "contains",
					operator: "and"
				}, ...filter]
			}
			else {
				body.filter = [...filter]
			}
		}

		try {
			const response = await Api.roster_assignments.get(body);
			this.setState({ rosterEmployee: response.data, meta: search ? this.state.meta : response.meta })
			this.props.setLoader(false);
		} catch (e) {
			console.log(e)
			this.props.setLoader(false);
		}
	}

	handleDrawerClose = () => {
		if (!this.state.collapse) {
			this.setState({ delayCollapse: !this.state.delayCollapse, largeDelay: !this.state.largeDelay })
			setTimeout(() => {
				this.setState({ collapse: !this.state.collapse })
			}, 10);
		}
		else {
			this.setState({ collapse: !this.state.collapse })
			setTimeout(() => {
				this.setState({ delayCollapse: !this.state.delayCollapse })
			}, 10);
			setTimeout(() => {
				this.setState({ largeDelay: !this.state.largeDelay })
			}, 250);
		}
	};

	getEmployeeList = async () => {
		this.setState({
			employeesModal: true
		})
	}


	renderDayLabel = (day, addDay) => {
		const { classes } = this.props;
		const { weekDate } = this.state
		return (<Grid item fullWidth alignContent={"center"} justifyContent={"center"} className={classes.renderDayLabelGridContainer}>
			<div className={classes.dayStyle}>{day}</div>
			<div className={classes.dateStyle}>{moment(weekDate).startOf("week").add(addDay-1, "day").format("MMMM DD")}</div>
		</Grid>)
	}


	emptyView() {
		return (
			<div style={{ padding: "10px 0px 10px 0px" }}>
				<InfographicMessage
					illustration="roster"
					message={`Shift schedules are not yet setup for this week`}
					actionLabel={"Add Employees"}
					actionIcon={"add"}
					actionHandler={() => { this.getEmployeeList(); }}
				/>
			</div>
		);
	}

	deleteEmployeeListItem = (item) => {
		var employeeList = _.filter(this.state.rosterEmployee, (obj) => { return obj.id !== item.id })
		this.setState({ rosterEmployee: employeeList })
	}

	onPageChange = (e, page) => {
		const { meta } = this.state;
		this.setState({
			meta: {
				...meta,
				page: page + 1
			},
		},
			this.getRosterAssignments
		)
	}

	createRosterCall = async (employeeList) => {
		if (!employeeList.length) {
			this.setState({ employeesModal: false }, () => {
				this.props.setLoader(false)
			})
		}
		var employeeIDList = []
		employeeList.forEach(item => employeeIDList.push(item.id))
		try {
			this.props.setLoader(true)
			await Api.rosters.post({
				week: moment(this.state.weekDate).isoWeek(),
				year: moment(this.state.weekDate).format("YYYY"),
				employees: employeeIDList
			});
			this.getRosterAssignments();
			this.setState({ employeesModal: false })

		} catch (e) {
			this.props.setLoader(false);
			HttpErrorHandler.intercept(e)
				.on(403, response => this.props.setSnackbar(true, response.data.message, "error"))
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
		}
	}

	onFilterSearch = async (filters) => {
		this.setState({ filters });
		await this.getRosterAssignments(true, filters)
	}


	render() {
		const { selectedEmployee, fields, filters, collapse, showFilter, delayCollapse, largeDelay, meta, rosterEmployee } = this.state;
		const { classes } = this.props;
		var from= moment(this.state.weekDate).startOf('week').format("YYYY-MM-DD")
		var to = moment(this.state.weekDate).endOf('week').format("YYYY-MM-DD")
		
		return (
			<Grid xs={12} container alignItems="stretch">
				<TableContainer >
					<Table aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ padding: 0, backgroundColor: "white", }}>
									{/* 1stRow */}
									<Grid xs={12} direction={"row"} className={classes.displayFlex}>
										<Grid container xs={collapse ? 1 : 2} className={classNames([classes.sideBarAnimate, classes.firstRowFirstContainer])}
											direction={"row"} justifyContent={"space-between"}>
											<Grid xs={12} container alignItems={"center"}>
												{
													delayCollapse ? <IconButton onClick={() => { this.getEmployeeList(); }}>
														<Add color="primary"></Add>
													</IconButton> : <Button
														onClick={() => {
															this.getEmployeeList();
														}}
														className={classes.addButtonStyle}
														size="medium" color="primary" >
														<Add color="primary"></Add>Add
													</Button>
												}
												<EmployeeSelectionModal
													multiSelect
													title="Select Employees"
													date={[from, to]}
													isOpen={this.state.employeesModal}
													selectedEmployeeList={rosterEmployee}
													onSubmit={(selectedEmployees) => {
														var rosterEmployeeList = [];
														selectedEmployees.forEach((item) => {
															var isExist = false;
															this.state.rosterEmployee.forEach((employeeItem) => {
																if (item.id === employeeItem.id) {
																	isExist = true;
																	return
																}
															});
															if (!isExist) { rosterEmployeeList.push(item) }
														})
														if (rosterEmployeeList.length) {
															this.createRosterCall(rosterEmployeeList)
														} else {
															this.setState({ employeesModal: false })
														}

													}}
													buttonText="Add"
													onClose={() => this.setState({ employeesModal: false })}
												/>
											</Grid>
											<Grid xs={0} container direction={"row"} justifyContent={"flex-end"} className={classes.drawerContainer}>
												<IconButton onClick={() => { this.handleDrawerClose() }}
													size="medium">
													<img  src={delayCollapse ? DrawerCloseIcon : DrawerOpenIcon} alt="drawer icon" className={classes.reminderIcon} />
												</IconButton>
											</Grid>
										</Grid>

										<Grid container xs={collapse ? 12 : 11} alignItems={"center"} className={classNames([classes.sideBarAnimate, classes.positionRelative])} direction={"row"}>
											<Grid xs={12} container justifyContent={"center"} >
												<IconButton
													onClick={() => {
														this.weekCalender.current.previousWeek();
														this.setState({
															weekDate: moment(this.state.weekDate, "YYYY-MM-DD").subtract(1, "week")
														}, async () => { await this.getRosterAssignments() })
													}}
													aria-label="Previous Week">
													<KeyboardArrowLeft />
												</IconButton>
												<WeekPicker
													ref={this.weekCalender}
													setWeekDate={(weekDate) => { this.setState({ weekDate }, async () => { await this.getRosterAssignments() }) }}
													weekDate={this.state.weekDate}
												/>
												<IconButton
													onClick={() => {
														this.weekCalender.current.nextWeek();
														this.setState({ weekDate: moment(this.state.weekDate, "YYYY-MM-DD").add(1, "week") },
															async () => { await this.getRosterAssignments() })
													}}
													aria-label="Next Week">
													<KeyboardArrowRight />
												</IconButton>
											</Grid>
											<Grid xs={0} container justifyContent={"flex-end"} alignItems={"center"} className={classes.filterGridContainer}>
												<Button
													size="medium"
													color="primary"
													onClick={() => { this.setState({ showFilter: !this.state.showFilter }) }}>
													<FilterList />
													<Badge badgeContent={filters.length} color="secondary">
														Filter
													</Badge>
												</Button>
											</Grid>
										</Grid>
									</Grid>
									{/* 2ndRow */}
									{
										showFilter &&
										<Grid xs={12} className={classes.gridTopBorder}>
											<Filter
												fields={fields}
												filters={filters}
												preLoaded
												onUpdate={this.onFilterSearch}
											/>
										</Grid>
									}
									{/* 3rd Row */}
									<Grid xs={12} className={classes.thirdGridRowContainer}>
										<Grid container xs={collapse ? 1 : 2} style={showFilter ? { borderTop: "1px solid #DBEAFF" } : null} className={classNames([classes.sideBarAnimate])}>
											<Grid xs={12} container alignContent={"center"} justifyContent={"center"} className={classes.thirdRowGridContainerText}>
												{delayCollapse ? <div className={classes.employeeText}>Employee{!collapse ? null : <div className={classNames([classes.sideBarAnimate, classes.outOfHundred])} style={{ display: !delayCollapse ? "none" : null, paddingTop: "5px" }}>{this.state.rosterEmployee.length} / {meta.total}</div>}
												</div> : <>
													<>
														<div className={classNames([classes.sideBarAnimate, classes.employeeText])}>Employee
														</div><div className={classNames([classes.sideBarAnimate, classes.employeeText, classes.outOfHundred])} style={{ display: delayCollapse ? "none" : null, marginLeft: "5px" }}> {this.state.rosterEmployee.length} out of {meta.total}</div></>
													<TextField
														style={{ display: delayCollapse ? "none" : null }}
														variant="outlined"
														margin="dense"
														fullWidth
														value={this.state.search}
														onChange={e => {
															this.setState({ search: e.target.value }, () => {
																this.getRosterAssignments(false);
															})
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Icon>search</Icon>
																</InputAdornment>
															),
														}}
													/></>}
											</Grid>
										</Grid>

										<Grid container xs={collapse ? 12 : 11} className={classes.sideBarAnimate} style={{ top: "5px", borderTop: "1px solid #DBEAFF" }}>
											<Grid xs={12} container justifyContent={"space-evenly"}>
												{this.renderDayLabel("MONDAY", 1)}
												{this.renderDayLabel("TUESDAY", 2)}
												{this.renderDayLabel("WEDNESDAY", 3)}
												{this.renderDayLabel("THURSDAY", 4)}
												{this.renderDayLabel("FRIDAY", 5)}
												{this.renderDayLabel("SATURDAY", 6)}
												{this.renderDayLabel("SUNDAY", 7)}
												<Grid item className={classes.sideBarAnimate} alignContent={"center"} justifyContent={"center"} style={{ borderLeft: "1px solid #DBEAFF" }}>
													<IconButton
														disabled
														size="medium">
														<DeleteOutline style={{ color: "white" }} />
													</IconButton>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody >
							<TableRow>
								<TableCell style={{ padding: 0, backgroundColor: "white" }}>
									{/* 4th Row */}
									{this.state.rosterEmployee.length ? (
										<EmployeeList
											largeDelay={largeDelay}
											collapse={collapse}
											delayCollapse={delayCollapse}
											search={this.state.search}
											employeeList={this.state.rosterEmployee}
											selected={selectedEmployee}
											weekDate={this.state.weekDate}
											deleteEmployeeListItem={(item) => { this.deleteEmployeeListItem(item) }}
											getRosterAssignments={this.getRosterAssignments}
											shiftData={this.state.shiftData}
											fetchShift={() => this.fetchShift()}
										// onSelect={employee => this.setState({ selectedEmployee: employee.id })}
										/>) : this.emptyView()}
								</TableCell>
							</TableRow>

						</TableBody>
						<TableFooter>
							<TableRow key={"DLTableRow-Footer"}>
								{this.state.meta.total > 0 &&
									<TablePagination
										rowsPerPageOptions={[5, 10, 15, 20, 25]}
										key={"DLTableRow-Footer-Pagination"}
										count={this.state.meta.total}
										colSpan={1}
										rowsPerPage={this.state.meta.per_page || 50}
										page={this.state.meta.current_page - 1}
										SelectProps={{ native: false }}
										onPageChange={this.onPageChange}
										onRowsPerPageChange={(e) => this.setState({ meta: { ...meta, per_page: e.target.value } }, this.getRosterAssignments)}
										ActionsComponent={DLTablePaginationActions}
									/>}
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>

			</Grid>
		)



	}
}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args)),
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(RosterContainer));
