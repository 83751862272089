// import { lighten } from '@material-ui/core/styles/colorManipulator';


export default( theme ,props)=> (
    {

    DLTable: {
        width: '100%',
        minWidth: 750,
        '&.DLTableStickyHeader': {
            borderCollapse: 'separate',
            overflowX: "auto",
        },

        '&.DLAlternativeColoredRows .MuiTableRow-root:not($DLTableHeader):nth-of-type(odd)': {
            backgroundColor: '#2f80ed17',

            "&:hover": {
                backgroundColor: '#2f80ed29'
            }
        }
    },

    DLTableHeader: {
        borderTop: '1px solid ' + theme.palette.tableBorder.main,
        borderBottom: '2px solid ' + theme.palette.tableBorder.main,
        textTransform: 'uppercase',
        overflowX: "auto",
        backgroundColor: '#FFFFFF',

        '&.DLTableStickyHeader': {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#fff',
            overflowX: "auto",

        }
    },

    DLTableColumn: {
        padding: 10,
        overflowX: "auto",
        '&:last-child': {
            padding: 10,
        },
        '&.DLTableColumnStickyHeader': {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor: '#fff',
            overflowX: "auto",

        },
        '&.DLTableColumnSeparated': {
            borderRight: '2px solid ' + theme.palette.tableBorder.main,
        },

        '&.DLTableColumnCheckbox': {
            width: 48,
            padding: 0
        },

        '&.DLTableColumnCompact': {
            padding: 0
        },

        '&.DLTableEmptyView': {
            padding: '30px 10px'
        }
    },


});