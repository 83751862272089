import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, CardMedia, Grid, InputAdornment, Slide, TextField, Typography, Card, CardContent, CircularProgress } from "@material-ui/core";
import { ArrowForward, Mail, Lock } from "@material-ui/icons";
import { Page } from "@/components";
import { AuthContext } from "@/contexts";
import { Api, HttpErrorHandler, Utility } from "@/helpers";
import { setSnackbar } from "@/redux/actions/general";
import classNames from "classnames";
import styles from "./style";
import Logo from "@/assets/logo/rsz_al_burhan_logo.png";

class Login extends Component {

	static contextType = AuthContext;
	constructor(props) {
		super(props);
		this.state = {
			credentials: {
				email: "",
				password: ""
			},
			loading: false,
			errors: {},
			forgotPassword: false
		};
	}

	inputChangeHandler = (e) => {
		if (e.target.name) {
			const { credentials } = this.state;
			this.setState({
				credentials: {
					...credentials,
					[e.target.name]: e.target.value
				}
			});
		}
	};

	validate(onlyEmail = false) {
		const { email, password } = this.state.credentials;

		if (!onlyEmail && !email && !password) {
			this.props.setSnackbar(true, "Both mobile & password fields are required!", "error");
			return false;
		} else if (!email) {
			this.props.setSnackbar(true, "Please provide your mobile number!", "error");
			return false;

		// } else if (!Utility.validateEmail(email)) {
		// 	this.props.setSnackbar(true, "Invalid email address!", "error");
		// 	return false;
		}

		return true;
	}

	forgotPassword = async (e) => {
		e.preventDefault();

		if (!this.validate(true)) return;

		const { email } = this.state.credentials;
		// TODO: Show loader
		try {
			await Api.reset.post({ email });
			this.props.setSnackbar(true, `If your account is registered with ${email}, you should receive email with password reset instructions.`, "success");

		} catch (err) {
			HttpErrorHandler
				.intercept(err)
				.on(403, response => this.props.setSnackbar(true, response.data.message, "error"))
				.on(400, response => this.props.setSnackbar(true, response.data.message, "error"))
				.default(() => this.props.setSnackbar(true, "Something went wrong, please try again", "error"))
				.go();
		}
	};

	login = (e) => {
		e.preventDefault();

		if (!this.validate()) return;

		const { email, password } = this.state.credentials;

		this.setState({loading: true}, async () => {			
			await this.context.authenticate(email, password);
			this.setState({loading: false});
		});
	};

	render() {
		const { classes } = this.props;
		const { credentials, isForgotPassword, loading } = this.state;

		return (
			<Page title="Login">
				<div className={classes.root}>
					<Grid item xs={12} sm={8} md={5}>
						{
							!isForgotPassword &&
							<Slide direction="right" in={!isForgotPassword}>
								<Card raised className={classes.frosted}>
									<CardContent>
										<CardMedia
											className={classes.logo}
											image={Logo}
											title="Al-Burhan Attendance"
										/>
										<form className={classes.form} onSubmit={this.login}>
											<TextField
												className={classes.formControl}
												placeholder="Mobile"
												value={credentials.email}
												name="email"
												onChange={this.inputChangeHandler}
												margin="dense"
												variant="outlined"
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Mail style={{ color: "grey" }} />
														</InputAdornment>
													),
												}}
											/>

											<TextField
												className={classes.formControl}
												placeholder="Password"
												type={"password"}
												value={credentials.password}
												name="password"
												onChange={this.inputChangeHandler}
												margin="dense"
												variant="outlined"
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Lock style={{ color: "grey" }} />
														</InputAdornment>
													),
												}}
											/>
											
											<div>
												<Button
													className={classNames(classes.button, classes.supportiveButton)}
													onClick={() => this.setState({ isForgotPassword: true })} >
													Forgot Password
												</Button>
											</div>
											
											<div className={classes.buttonWrapper}>
												<Button
													className={classNames(classes.button, classes.loginButton)}
													type="submit"
													variant="contained"
													disabled={loading}
													color="primary">
													Login <ArrowForward className={classes.buttonIcon}/>
												</Button>
												{
													loading && 
													<CircularProgress size={24} className={classes.buttonProgress} />
												}
											</div>
										</form>
									</CardContent>
								</Card>
							</Slide>
						}
						{
							isForgotPassword &&
							<Slide direction="left" in={isForgotPassword}>
								<Card raised className={classes.frosted}>
									<CardContent>
										<CardMedia
											className={classes.logo}
											image={Logo}
											title="Al-Burhan Attendance"
										/>
										<form className={classes.form} onSubmit={this.forgotPassword}>
											<Typography
												className={classes.heading}
												variant="h6"
												component="h1"
												align="center"
												gutterBottom>
												Forgot Password
											</Typography>
											
											<TextField
												className={classes.formControl}
												placeholder="Mobile"
												value={credentials.email}
												name="email"
												onChange={this.inputChangeHandler}
												margin="dense"
												variant="outlined"
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Mail style={{ color: "grey" }} />
														</InputAdornment>
													),
												}}
											/>

											<Button
												className={classes.button}
												type="submit"
												variant="contained"
												color="primary">
												Forgot Password <ArrowForward className={classes.buttonIcon} />
											</Button>

											<div>
												<Link to={{ pathname: `/` }} style={{ textDecoration: 'none', color: "primary" }}>
													<Button route="/"
														className={classNames(classes.button, classes.supportiveButton)}
														onClick={() => this.setState({ isForgotPassword: false })} >
														Back to Login
												</Button>
												</Link>
											</div>
										</form>
									</CardContent>
								</Card>
							</Slide>
						}
					</Grid>
				</div>
			</Page>
		);
	}

}

const mapDispatchToProps = (dispatch) => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Login)));
