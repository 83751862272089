const style = theme => ({

    image: {
        display: 'block',
        width: '25px',
        height: '25px',
        borderRadius: '3px',
        cursor: 'pointer',
        background: '#C6E5FF',
        border: 'none'
    },
    
    extraImages: {
        display: 'flex',
        width: '25px',
        height: '25px',
        borderRadius: '3px',
        cursor: 'pointer',
        fontSize: '0.7em',
        marginLeft: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#C6E5FF',
        border: 'none'
    }

});

export default style;