import React from "react";
import { withRouter } from "react-router-dom";
import {
	ArrowDownward,
	ArrowUpward,
} from "@material-ui/icons";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import moment from "moment";
import { ListItemText, Chip } from '@material-ui/core';
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";

class CheckInOutStatusRow extends DLTableRow {

	convertMinutes(minutes_time) {
		return moment.utc(Math.abs(minutes_time * 60 * 1000)).format("HH:mm");
	}

	renderCells() {
		const { data, classes } = this.props;
		var end_time = data.shift.length < 1 ? null : moment(data.shift.end_time, "hh:mm:ss").format("hh:mm A")
		var start_time = data.shift.length < 1 ? null : moment(data.shift.start_time, "hh:mm:ss").format("hh:mm A")
		var shiftTime = data.shift.length < 1 ? null : <span> ({start_time} to {end_time})</span>
		
		return [
			<DLTableColumn key={`Attendance-${data.id}-Column-1`}
				withSeparator={true}
                stickyHeader={true}	>
				<EmployeeAvatar data={{
					employee: {
						id: data.id,
						first_name: data.first_name,
						last_name: data.last_name,
						code: data.code
					}
				}} classes={classes} />
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-2`}
				align={"left"}
				withSeparator={false}>
				{data.check_in}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-3`}
				align={"left"}
				withSeparator={false}>
				{data.check_out}
			</DLTableColumn>,
			
			<DLTableColumn
				key={`Attendance-${data.id}-Column-4`}
				align={"left"}
				withSeparator={false}>
				<span style={{ color: "#1DA729" }}>
					{
						data.check_in_diff < 0
							? this.convertMinutes(data.check_in_diff)
							: '-'
					}
				</span>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-5`}
				align={"left"}
				withSeparator={false}>
				<span style={{ color: "#d73625" }}>
					{
						data.check_in_diff > 0
							? this.convertMinutes(data.check_in_diff)
							: '-'
					}
				</span>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-6`}
				align={"left"}
				withSeparator={false}>
				<span style={{ color: "#1DA729" }}>
					{
						data.check_out_diff < 0
							? this.convertMinutes(data.check_out_diff)
							: '-'
					}
				</span>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-7`}
				align={"left"}
				withSeparator={false}>
				<span style={{ color: "#1DA729" }}>
					{
						data.check_out_diff > 0
							? this.convertMinutes(data.check_out_diff)
							: '-'
					}
				</span>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-8`}
				align={"left"}
				withSeparator={false}>
				{data.required_hours}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-9`}
				align={"left"}
				withSeparator={false}>
				{data.total_hours}
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-10`}
				align={"left"}
				withSeparator={false}>
				{
					data.net_hours < 0 &&
					<span style={{ color: "#d73625" }}>
						{this.convertMinutes(data.net_hours * 60)}
						<ArrowDownward style={{ color: "#d73625", width: "14px", height: "14px" }} />
					</span>
				}
				{
					data.net_hours > 0 &&
					<span style={{ color: "#1DA729" }}>
						{this.convertMinutes(data.net_hours * 60)}
						<ArrowUpward style={{ color: "#1DA729", width: "14px", height: "14px" }} />
					</span>
				}
				{ !parseFloat(data.net_hours) && '00:00' }
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-11`}
				align={"left"}
				withSeparator={false}>
				<Chip
					style={{
						backgroundColor: data.status === "Present"
							? "#00D454"
							: (
								data.status === "Absent"
									? "#281B1B"
									: (
										data.status === "Leave"
											? "#64182A"
											: (
												data.status === "Late"
													? "#FABD60"
													: (
														data.status === "Half Day"
														? "#00C7D4"
														: "#FF7300"
													)
											)
									)
							),
						color: "white"
					}}
					label={<> {data.status}</>}
					size="small"
				/>
			</DLTableColumn>,
			<DLTableColumn
				key={`Attendance-${data.id}-Column-12`}
				align={"left"}
				withSeparator={false}>
				{<ListItemText
					primary={`${data.shift_name} `}
					primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
					secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
					secondary={shiftTime}
				/>}
			</DLTableColumn>
		];
	}

}

CheckInOutStatusRow.Columns = [
	{
		key: "employee_details",
		label: "Employee",
		align: "left",
		isSortable: false,
		withSeparator: true,
        stickyHeader:true,
	},
	{
		key: "type",
		label: "First In",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "request_date",
		label: "Last Out",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "entry1",
		label: "Early Entry",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "entry2",
		label: "Late Entry",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "exit1",
		label: "Early Exit",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "exit2",
		label: "Late Exit",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "required",
		label: "Required Hours",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "total",
		label: "Hours Worked",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "net_hours",
		label: "Net Difference",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "status",
		label: "Status",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: "date",
		label: "Shift",
		align: "left",
		isSortable: false,
		withSeparator: false
	}
];

export default withRouter(withStyles(styles)((CheckInOutStatusRow)));
