const style = theme => ({
    
    row: {
        flexDirection: "row",

        "& > .MuiFormControl-root:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },

    inputSelect: {
        paddingRight: theme.spacing(5)
    },

    dropdownItem: {
        display: "flex",
        justifyContent: "space-between"
    },

    dropdownChip: {
        marginLeft: theme.spacing(1),
        boxShadow: theme.shadows[1],
        height: theme.spacing(2),
        fontSize: "0.7rem"
    }
    
});

export default style;