class HttpErrorHandler {

	error = null;

	handlers = {};

	static intercept(e) {
		return new HttpErrorHandler(e);
	}

	constructor(e) {
		this.error = e;
	}

	on(code, handler) {
		this.handlers[code] = handler;
		return this;
	}

	default(handler) {
		this.handlers.default = handler;
		return this;
	}

	go () {
		if (this.error.response && this.handlers[this.error.response.status]) {
			this.handlers[this.error.response.status](this.error.response);

		} else if (this.handlers.default) {
			this.handlers.default(this.error);
		}
	}

}

export default HttpErrorHandler;