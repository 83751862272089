export function setToken(token) {
    return {
        type: 'SET_TOKEN',
        token: {
            ...token
        }
    }
}

export function resetToken() {
    return {
        type: 'RESET_TOKEN'
    }
}