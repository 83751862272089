const style = theme => ({

    paper: {
        width: "auto !important",
        minWidth: 250,
    },
    option: {
        padding: "0px",
        margin: "0px",
    },
    divider: {
        borderBottom: "0px solid",
        borderTop: "1px solid #CCCC",
    },
    itemStyle: {
        width: "100%", height: "40px",
        alignItems: "center",
        paddingLeft: "10px",
        display: "flex",
    }
});

export default style;