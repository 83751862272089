import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import  PropTypes from "prop-types";
import { currencyFormatter } from "@/helpers/generalHelper";
import Constants from "@/config";
import styles from "./style";
import { nFormatter } from '@/helpers/generalHelper';
import classNames from "classnames";

class Money extends Component {

	static defaultProps = {
		currency: Constants.CURRENCY_SYMBOL
	};

	static propTypes = {
		currency: PropTypes.string
	};
	isMillions = (n)=>{
		return (n < 1e6) ? false : true; 
	}
	render () {
		const { currency, classes, style} = this.props;
		var { amount } = this.props;
		var whole, decimals;
		// if(this.isMillions(amount)){
		// 	decimals = null;
		// 	whole = nFormatter(amount);
		// }else{
			var formatNumber = currencyFormatter(amount, false).split('.');
			whole = formatNumber[0];
			decimals = formatNumber[1];
		// }
		return (
				(decimals == null) ? (
					<span className={classes.amount}>
						<span className={classes.currency}>{currency}.</span>
						<span>{whole}</span>
					</span>
				):(
					<span className={classNames(classes.amount, style) }>
						<span className={classes.currency}>{currency}.</span>
						<span style={style}>{whole}</span>
						<sup className={classes.decimal}>.{decimals.replace(")", "")}</sup>
						{
							amount < 0 &&
							<span>)</span>
						}
					</span>
				)
		);
	}

}

export default withStyles(styles)(Money);