import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Step from "./step";
import styles from "./style";

class Stepper extends Component {

    childRefs = [];

	constructor(props) {
        super(props);
        this.state = {
            activeStep: props.initialStep || 0,
            responses: {}
        };
    }
    
    next = (data, next = null) => {
        const nextStep = next || (this.state.activeStep + 1);
        this.setState({
            activeStep: nextStep,
            responses: {
                ...this.state.responses,
                [this.state.activeStep]: data
            }
        });
        this.childRefs[nextStep] && this.childRefs[nextStep].scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    pervious = () => {
        const prevStep = this.state.activeStep - 1;
        this.setState({
            activeStep: prevStep
        });
    };

    activateStep = step => {
        this.setState({ activeStep: step - 1 });
    };

    isActive = step => {
        return this.state.activeStep === (step - 1);
    };

    isCompleted = step => {
        return this.state.activeStep >= step;
    };

    getData = step => {
        return this.state.responses[step - 1] || { };
    };

    bindStep = (child, index = 0) => {
        const { activeStep } = this.state;
        const { initialStep, classes, children, ...props } = this.props;
    	return (
            <child.type
                refProp={ref => this.childRefs[index] = ref}
                key={`step-${index}`}
                {...props}
                {...child.props}
                stepper={this}
                step={index + 1}
                isActive={index === activeStep}
                activeStep={activeStep + 1}
            />
    	);
	};

	render () {
		const { classes, children } = this.props;
		return (
            <div className={classes.root}>
                {
                    Array.isArray(children)
                        ? children.map(this.bindStep)
                        : this.bindStep(children)
                }
            </div>
        );
	}

}

Stepper.Step = Step;

export default withStyles(styles)(Stepper);