import {
    ArchiveOutlined,
    EditOutlined,
    HelpOutline,
    HourglassEmptyOutlined,
    UnarchiveOutlined
} from "@material-ui/icons";
import { DLTableColumn, DLTableRow, SVGIcons } from "@/components";
import _ from "lodash";
import { HttpErrorHandler } from "@/helpers";
import Api from "@/helpers/Api";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { AccountBalanceWalletOutlined as WalletIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import LeaveModal from "@/components/Modals/LeaveModal";
import { setSnackbar } from "@/redux/actions/general";
import store from "@/redux";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";

class LeaveTableRow extends DLTableRow {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isEditing: false
        };
    }

    archive = async (e) => {
        e.preventDefault();
        this.setState({ isModalOpen: false });
        const { data } = this.props;
        try {
            await Api.leaves(data.id).delete();
            store.dispatch(
                setSnackbar(true, `Leaves "${data.name}" archived successfully`)
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            HttpErrorHandler.intercept(e)
                .on(422, (response) => {
                    this.setState({
                        errors: _.mapValues(response.data, (value) => value[0]),
                    });
                    this.props.setSnackbar(
                        true,
                        "Please fix the highlighted errors",
                        "error"
                    );
                })
                .on(403, (response) => {
                    this.props.setSnackbar(
                        true,
                        JSON.stringify(response.data.message) || "Something went wrong, please try again later",
                        "error"
                    )
                })
                .default((_) =>
                    this.props.setSnackbar(
                        true,
                        "Something went wrong, please try again later",
                        "error"
                    )
                )
                .go();
        }
    };

    restore = async () => {
        this.setState({ isModalOpen: false });
        const { data } = this.props;
        try {
            await Api.leaves(data.id).restore.patch();
            store.dispatch(
                setSnackbar(true, `Leaves "${data.name}" restored successfully`)
            );
            this.props.reload();
        } catch (e) {
            console.error(e);
            store.dispatch(
                setSnackbar(
                    true,
                    "Something went wrong, please try again later",
                    "error"
                )
            );

        }
    };

    renderCells() {
        const {data } = this.props;
        const { isEditing } = this.state;
        return [
            <DLTableColumn key={`Payrun-${data.id}-Column-1`} withSeparator={true}>
                <strong>{data.name}</strong>
            </DLTableColumn>,
            <DLTableColumn key={`Payrun-${data.id}-Column-2`} withSeparator={true}>
                <strong>{data.code}</strong>
            </DLTableColumn>,
            <DLTableColumn key={`Payrun-${data.id}-Column-3`} align={"center"}>
                {data.quantity}
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-4`}
                fillWidth={35}
                isCompact={true}>
                {
                    data.is_auto_assign &&
                    <Tooltip title="Adds automatically for new employees">
                        <SVGIcons icon="default" />
                    </Tooltip>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-5`}
                fillWidth={35}
                isCompact={true}>
                {
                    data.allowed_in_probation &&
                    <Tooltip title="Allowed in probation">
                        <HourglassEmptyOutlined style={{color: "#666"}}/>
                    </Tooltip>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-6`}
                fillWidth={35}
                isCompact={true}>
                {
                    data.is_encashable &&
                    <Tooltip title="Unavailed Leaves are Encashable">
                        <WalletIcon style={{color: "#666"}} />
                    </Tooltip>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-7`}
                fillWidth={35}
                isCompact={true}>
                {
                    data.is_deduct_unapproved_leave &&
                    <Tooltip title={`Uninformed and Unapproved leaves will be deducted from ${data.name}`}>
                        <HelpOutline style={{color: "#666"}}/>
                    </Tooltip>
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Employee-${data.id}-Column-8`}
                align={"right"}
                fillWidth={80}
                isCompact={true}>
                {

                    (isEditing || !data.is_archived) &&
                    <Tooltip title="Edit Leaves">
                        <IconButton
                            onClick={() => this.setState({ isEditing: " " })}
                            size="small"
                            color="primary">
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                }
                {
                    <LeaveModal
                        mode={isEditing}
                        position={data.id}
                        leaves={data}
                        title="Edit Leaves"
                        onUpdate={this.props.reload}
                        onClose={() => this.setState({ isEditing: false })} />
                }
            </DLTableColumn>,
            <DLTableColumn
                key={`Payrun-${data.id}-Column-9`}
                align={"center"}
                fillWidth={35}
                isCompact={true}>
                {!data.is_archived && (
                    !data.is_deduct_unapproved_leave ? 
                    <>
                        <Tooltip title="Archive Leaves">
                            <IconButton
                                onClick={() => this.setState({ isModalOpen: true })}
                                size="small"
                                color="secondary">
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                        <ConfirmModal
                            title="Archive Leaves"
                            isOpen={this.state.isModalOpen}
                            message={`Are you sure you want to archive Leaves ${data.name}?`}
                            onClickCancel={() => {
                                this.setState({ isModalOpen: false });
                            }}
                            onClickConfirm={this.archive} />
                    </> : " "
                )}
                {data.is_archived && (
                    <>
                        <Tooltip title="Restore Leaves">
                            <IconButton
                                onClick={() => this.setState({ isModalOpen: true })}
                                size="small"
                                color="primary">
                                <UnarchiveOutlined />
                            </IconButton>
                        </Tooltip>

                        <ConfirmModal
                            title="Restore Leaves"
                            isOpen={this.state.isModalOpen}
                            message={`Are you sure you want to restore Leaves${data.name}?`}
                            onClickCancel={() => {
                                this.setState({ isModalOpen: false });
                            }}
                            onClickConfirm={this.restore} />
                    </>
                )}
            </DLTableColumn>,
        ];
    }
}

LeaveTableRow.Columns = [
    {
        key: "name",
        label: "Name",
        align: "left",
        isSortable: true,
        withSeparator: true,
    },
    {
        key: "code",
        label: "code",
        align: "left",
        isSortable: true,
        withSeparator: true,
    },
    {
        key: "quantity",
        label: "Quantity",
        align: "center",
        isSortable: true,
        withSeparator: false,
    },
    {
        key: "is_auto_assign",
        label: "Status",
        align: "left",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 4
    },
    {
        key: null,
        label: null,
        align: "right",
        isSortable: false,
        withSeparator: false,
        isCompact: true,
        colSpan: 2,
    },
];

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),

});
export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(LeaveTableRow));
