import React from "react";
import { connect } from "react-redux";
import { DLTableRow, DLTableColumn } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import EmployeeTaxAdjustmentModal from "@/components/Modals/EmployeeTaxAdjustmentModal";
import { Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { Api } from "@/helpers";
import { currencyFormatter } from '@/helpers/generalHelper';
import moment from "moment";
import UserRole from "@/components/UserRole";
import { Permissions } from "@/config/Permissions";
import { setSnackbar, setLoader } from "@/redux/actions/general";

class EmployeeTaxAdjustmentTable extends DLTableRow {

	state = {
		isEditing: false,
		isDeleting: false
	};

	delete = async () => {
		this.setState({ isDeleting: false });
		this.props.setLoader(true);
		try {
			const { data } = this.props;
			await Api.employees(data.employee_id).tax_adjustments(data.id).delete();
			this.props.setSnackbar(true, "Tax adjustment deleted successfully");
			this.props.reload();

		} catch (e) {
			this.props.setSnackbar(true, "Something went wrong, please try again", "error");
		}
		this.props.setLoader(false);
	};

	renderCells() {
		const { employee, data } = this.props;
		const { isEditing, isDeleting } = this.state;
		return [
			<DLTableColumn
				key={`Employee-${data.id}-Column-1`}
				withSeparator={true}>
				{moment(data.date).format("MMM Do, Y")}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-2`}
				withSeparator={false}
				align="left"
			>
				{currencyFormatter(data.amount, true)}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-3`}
				align="left"
				withSeparator={false}
			>
				{data.message}
			</DLTableColumn>,
			<DLTableColumn
				key={`Employee-${data.id}-Column-4`}
				align={"right"}
				isCompact={true}
				fillWidth={35}
			>
				<Tooltip title="Edit Bank">
					<IconButton
						onClick={() => this.setState({ isEditing: true })}
						size="small"
					>
						<EditOutlined color="primary" />
					</IconButton>
				</Tooltip>
				{
					isEditing &&
					<EmployeeTaxAdjustmentModal
						isOpen={isEditing}
						employee={employee}
						taxAdjustment={data}
						title="Edit Tax Adjustment"
						onUpdate={this.props.reload}
						onClose={() => this.setState({ isEditing: false })}
					/>
				}
			</DLTableColumn>,
			<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
				<DLTableColumn
					key={`Employee-${data.id}-Column-5`}
					align={"right"}
					isCompact={true}
					fillWidth={35}
				>
					<Tooltip title="Delete Tax Adjustment">
						<IconButton
							onClick={() => this.setState({ isDeleting: true })}
							size="small"
						>
							<DeleteOutline color="secondary" />
						</IconButton>
					</Tooltip>
					{
						isDeleting &&
						<ConfirmModal
							title="Delete Tax Adjustment"
							isOpen={isDeleting}
							message={`Are you sure want to delete tax adjustment?`}
							onClickCancel={() => this.setState({ isDeleting: false })}
							onClickConfirm={this.delete}
						/>
					}
				</DLTableColumn>
			</UserRole>
		]
	}

}

EmployeeTaxAdjustmentTable.Columns = [
	{
		key: "id",
		label: "Date",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "amount",
		label: "Amount",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: "message",
		label: "Comment",
		align: "left",
		isSortable: false,
		withSeparator: false
	},
	{
		key: null,
		label: "",
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(EmployeeTaxAdjustmentTable);