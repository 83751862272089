import { Api } from "@/helpers";
import Types from "@/components/Filter/types";

const data = [
	{
		key: "name",
		label: "Employee Name",
		type: Types.string,
		example: 'Ahmed'
	},
	{
		key: "employee.code",
		label: "Employee Code",
		type: Types.string,
		example: 'EMP-054'
	},
	{
		key: "employee.work_email",
		label: "Work Email",
		type: Types.string,
		example: 'ahmed@company.com'
	},
	{
		key: "employee.personal_email",
		label: "Personal Email",
		type: Types.string,
		example: 'ahmed@gmail.com'
	},
	{
		key: "employee.mobile",
		label: "Mobile Number",
		type: Types.string,
		example: '0333-1234567'
	},
	{
		key: "employee.cnic",
		label: "CNIC",
		type: Types.string,
		example: '42101-1234567-8'
	},
	{
		key: "employee.ntn",
		label: "NTN",
		type: Types.string,
		example: '123456-7'
	},
	{
		key: "current_position.designation_id",
		label: "Designation",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.designations.get({ per_page: 0, sort: { column: 'name', order: 'asc' }  });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: "current_position.department_id",
		label: "Department",
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.departments.get({ per_page: 0, sort: { column: 'name', order: 'asc' }  });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: 'code',
		label: 'Request Code',
		type: Types.string,
		example: '3wtqyx'
	},
	{
		key: 'leave_id',
		label: 'Leave Category',
		type: Types.selection,
		options: async () => {
			try {
				const response = await Api.leaves.get({ per_page: 0, sort: { column: 'name', order: 'asc' }  });
				return response.data.map(s => ({ key: s.id, label: s.name }));

			} catch {
				return [];
			}
		}
	},
	{
		key: 'start_date',
		label: 'Leave Start Date',
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: 'end_date',
		label: 'Leave End Date',
		type: Types.date,
		example: '2022/03/23'
	},
	{
		key: 'leave_days',
		label: 'Leave Duration',
		type: Types.number,
		example: 7
	},
	{
		key: 'status',
		label: 'Request Status',
		type: Types.selection,
		options: [
			{
				key: 'pending',
				label: 'Pending'
			},
			{
				key: 'approved',
				label: 'Approved'
			},
			{
				key: 'rejected',
				label: 'Rejected'
			}
		]
	}
];

export default data;