import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
    Grid,
    TextField,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { DatePicker, TimePicker } from "@material-ui/pickers";
import moment from "moment";
import {EmployeeDropdown } from "@/components";
import _, { wrap } from "lodash";
import styles from "./style";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler, Utility } from "@/helpers";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { HelpOutline } from '@material-ui/icons';
class AddCorrectionRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			type: "check-in",
			employee: "",
			date: moment().format("YYYY-MM-DD"),
			correctionTime: moment(), 
			message: "",	
			isEdit: false,
			title:props.title|| "Add Correction Request",
			selectedDate : "",
			today : "",
			tomorrow: ""
		};
	}
	static propTypes = {
		textFieldProps: PropTypes.object,
		value: PropTypes.number,
		onChange: PropTypes.func,
	};
	
	async componentDidMount() {
		if (this.props.match.params && this.props.match.params.id > 0) {
			this.props.setLoader(true);
			try {
				let response = await Api.attendance_correction(
					this.props.match.params.id
				).get();
			
				this.setState({
					attendance_correction_id: response.id,
					type: response.attendance.type,
					date: response.date,
					employee: response.employee_id,
					correctionTime: response.correct_time,
					message: response.message,
					today: moment(response.date).format("DD MMMM, yyyy"),
					tomorrow:moment(response.date).add(1, 'day').format("DD MMMM, yyyy")
				}, () => {this.props.setLoader(false);
				});
				this.setState({ selectedDate: moment.unix(this.state.correctionTime).format("DD MMMM, yyyy")})
			}
			catch (e) {
				HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: response.data[Object.keys(response.data)[0]][0] })
					this.props.setSnackbar(true, response.data[Object.keys(response.data)[0]][0], "error");
				})
				.on(400, response => {
					this.props.setSnackbar(true, 'Request Correction detail not fetch', "error");
					this.closeDialog();
					this.props.setLoader(false);
					this.props.history.push('/attendance/correction');
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
				this.setState({ errors: { ...e.response } }, () => {
					this.props.dispatch(setLoader(false));
				})
			}	
		}	
	}

	isEdit = () => {
		return !!this.props.match.params && !!this.props.match.params.id
	}

	handleSelect = (value) => {
		this.setState({
			selectedDate :value
		})
	}

	isValidData = () => {
		const { isEdit } = this.state;
		var errors = {};
		if (this.state.employee === "" ) {
			errors["employee"] = `${_.startCase("employee")} field is required`;
		}
		if (this.state.message === "") {
			errors["message"] = `${_.startCase("message")} field is required`;
		}
		if (_.size(errors)) {
			this.props.setSnackbar(
				true,
				"Please fix the highlighted errors",
				"error"
			);
		}
		this.setState({ errors });
		return !(_.size(errors) > 0);	
	};

	closeDialog = () => {
		if (this.props.avoidRoute) {
			this.props.onClose()
		} else if (this.props.backRoute === "goback") {
			this.props.history.goBack();
		} else {
			this.props.history.push(this.props.backRoute)
		}
	}

	correctionRequestConfirm = async () => {
		if (this.isValidData()) {
			this.props.setLoader(true);
			var correctTime = moment.unix(this.state.correctionTime).format("HH:mm:ss");
			var select = moment(this.state.selectedDate||this.state.date).format("YYYY-MM-DD");
			var createCorrectDate = moment(`${select} ${correctTime}`).unix()
			var data = new FormData();
			data.append("type", this.state.type);
			data.append("employee_id", this.state.employee);
			data.append("date", moment(this.state.date).format("YYYY-MM-DD"));
			data.append("correct_time", createCorrectDate);
			data.append("message", this.state.message);
			try {
				if (!this.state.attendance_correction_id) {
					await Api.attendance_correction.post(data);
				} else {
					data.append("_method", "put");
					await Api.attendance_correction(this.state.attendance_correction_id).post(data);
				}
				this.props.setSnackbar(true, this.state.attendance_correction_id ? "Correction Request Updated" : "Correction Request Added");
				this.props.onUpdate();
				this.closeDialog();
			}
			catch (e) {
				HttpErrorHandler.intercept(e)
				.on(422, response => {
					this.setState({ errors: response.data[Object.keys(response.data)[0]][0] })
					this.props.setSnackbar(true, response.data[Object.keys(response.data)[0]][0], "error");
				})
				.default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
				.go();
				this.setState({ errors: { ...e.response } }, () => {
					this.props.dispatch(setLoader(false));
				})
			}
			this.props.setLoader(false);
		}
	}

	handleChange = (e, fieldName) => {
		if( 
			fieldName === "employee" ||
			fieldName === "date" ||
			fieldName === "message"
		) {
			this.setState({ [fieldName]: e });
		} else {
			this.setState({ [fieldName]: e.target.value });
		}
	};
	
	render() {
		const { data, classes } = this.props;
		const {
			isOpen,
			onClose,
			onClickCancel,
			initialTime
		} = this.props;
		const {
			isEdit,
			errors,
			disabled,
			correctionTime,
			selectedDate,
			today,
			tomorrow,
			date
		} = this.state;
		const isToday = moment().isSame(moment(date), "day");
		return (
			<Dialog				
				fullWidth
				maxWidth="xs"
				open={"new"}
				aria-labelledby="correction-request-title"
				aria-describedby="correction-request-description"
				onBackdropClick={this.closeDialog}
				onClose={this.closeDialog}
			>
				<DialogTitle id="correction-request-title">{this.state.title}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item sm={12} xs={12}>
							<Grid container spacing={1}>
								<>
								{!this.isEdit() && 
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset" className={classes.requestCorrectionGrid} >
											<RadioGroup 
												row 
												aria-label="correction-request-time" 
												name="correction-request-time" 
												defaultValue="check-in" 
												onChange={type => this.handleChange(type, "type")} 
											>
											<Grid item sm={6} xs={6}>
												<FormControlLabel value="check-in" checked={this.state.type === 'check-in'} control={<Radio />} label="Check-in" className={classes.radioColor} />
											</Grid>
											<Grid item sm={6} xs={6}>
												<FormControlLabel value="check-out" checked={this.state.type === 'check-out'} control={<Radio />} label="Check-out" className={classes.radioColor} />
											</Grid>
											</RadioGroup>
										</FormControl>
									</Grid>
								}
								</>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item sm={12} xs={12}>
							<Grid container spacing={2} rowSpacing={1}>
								<Grid item sm={12} xs={12}>
									<EmployeeDropdown
										disabled= {this.isEdit()}
										label={"Employee"}
										placeholder="Employee"
										textFieldProps={{
											
											error: !!errors.employee,
											helperText: errors.employee,
											FormHelperTextProps: { variant: "standard" },
										}}
										onChange={(employee) =>
											this.handleChange(employee, "employee")
										}
										value={this.state.employee}
									/>
								</Grid>
								<Grid item sm={12} xs={12}>
									<DatePicker
										disabled={disabled}
										fullWidth
										label={"Date"}
										inputVariant="outlined"
										format="dd MMMM, yyyy"
										FormHelperTextProps={{ variant: "standard" }}
										value={moment(this.state.date || "")}
										maxDate={moment()}
										onChange={(date) =>{ this.handleChange(date, "date");
										this.setState({
											today: moment(date).format("DD MMMM, yyyy"),
											tomorrow:moment(date).add(1, 'day').format("DD MMMM, yyyy")})	
										}}
									/>
								</Grid>
							{!isToday && tomorrow !=="" &&
								<Grid item style={{flexDirection: "row",display: "flex"}}  sm={12} xs={12}>
									<Grid onClick={() => this.handleSelect(this.state.today)}
									className={classes.dateBox}
										style={{
											borderTopLeftRadius:8,
											borderBottomLeftRadius:8,
											backgroundColor: (selectedDate == today ? "#2F80ED" : "#EEF6FF")
										}} sm={4} xs={4}
									>
										<span style={{
											color: (selectedDate == today ? "white" : "black"),fontSize: 15}} >
											{this.state.today}
										</span>
									</Grid>
									<Grid onClick={() => this.handleSelect(this.state.tomorrow)}
										className={classes.dateBox}
										style={{
											backgroundColor:( selectedDate == tomorrow? "#2F80ED" : "#EEF6FF"),
											borderTopRightRadius:8,
											borderBottomRightRadius:8
										}}
										sm={4} xs={4}
									>
										<span style={{color: (selectedDate == tomorrow ? "white" : "black"),fontSize: 15}} >
											{this.state.tomorrow}
										</span>
									</Grid>
								<Grid style = {{paddingLeft:5}}  sm={4} xs={4}>
									<TimePicker
										inputVariant="outlined"
										label={"Time"}
										fullWidth
										autoOk
										size="small"
										value={moment.unix(correctionTime)}
										onChange={correctionTime => this.setState({ correctionTime: moment(correctionTime).unix() })}
									/>
								</Grid>
								</Grid>}
								{isToday  &&
								<Grid item style = {{paddingLeft:5}}  sm={12} xs={12}>
								<TimePicker
									inputVariant="outlined"
									label={"Time"}
									fullWidth
									autoOk
									value={moment.unix(correctionTime)}
									onChange={correctionTime => this.setState({ correctionTime: moment(correctionTime).unix() })}
								/>
								</Grid>
								}
								<Grid item sm={12} xs={12}>
									<TextField
										fullWidth
										multiline
										rows={5}
										label={"Message"}
										error={!!errors.message}
										helperText={errors.message}
										FormHelperTextProps={{ variant: "standard" }}
										value={this.state.message || ""}
										onChange={e => this.handleChange(e.target.value, "message")}
										variant="outlined"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
                </DialogContent>
				<DialogActions>
					<Button size="small" onClick={() => {
						this.closeDialog();
					}} color="default">Cancel</Button>
					<Button
						size={"small"}
						variant="contained"
						onClick={this.correctionRequestConfirm}
						color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

AddCorrectionRequest.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object,
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClickCancel: PropTypes.func,
	onClickConfirm: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	dispatch: (func) => dispatch(func),
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args)),
});

export default withRouter(
	connect(null, mapDispatchToProps)(withStyles(styles)(AddCorrectionRequest))
);