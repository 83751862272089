const style = theme => ({

    header:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    actionButtonIcon: {
        marginLeft: theme.spacing(2)
    },

    alertStyle:{
        backgroundColor: theme.palette.warning.background,
        color: theme.palette.warning.main,
        marginTop: theme.spacing(2),
        cursor: "pointer",
        fontFamily: theme.typography.fontFamily,
        flexDirection: "row"
    },
    
    successColor: {
        color: theme.palette.success.main
    },
    
    actionContainer: {
        padding: "20px",
        flexDirection:"row", 
        display: "flex", 
        borderTop: `2px #E7F1FF solid`,
        justifyContent:"space-between"
    },
    
    summary: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between"
    }

});

export default style;