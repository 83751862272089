import React, { Component } from "react";
import { Route } from "react-router";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { Page, Tabs } from "@/components";
import AttendanceList from "./AttendanceList";
import CorrectionRequestList from "./CorrectionRequestList";
import LeaveRequestList from "./LeaveRequestList";
import RosterContainer from "@/pages/Roster/RosterContainer";
import Modules from "@/config/Modules"
import { setLoader } from "@/redux/actions/general";
import styles from "./style";

class Attendance extends Component {

	render() {
		return (
			<Page title="Attendance">
				<Card raised>
					<Tabs variant="routed">
						<Tabs.Tab
							hidden={[Modules.ATTENDANCE]}
							path="/dashboard"
							label="Attendance">
						</Tabs.Tab>
						<Tabs.Tab
							hidden={[Modules.ATTENDANCE]}
							path="/correction"
							label="Correction Requests">
							<CorrectionRequestList />
						</Tabs.Tab>
						<Tabs.Tab
							hidden={[Modules.ATTENDANCE]}
							path="/leaves"
							label="Leave Requests">
							<LeaveRequestList />
						</Tabs.Tab>
						<Tabs.Tab
							hidden={[Modules.ROSTER]}
							path="/roster"
							label="Shift Schedules">
							<RosterContainer />
						</Tabs.Tab>
					</Tabs>
				</Card>
				<Route
                    path="/attendance/dashboard"
                    exact
                    render={() => (
                        <AttendanceList />
                    )}
                />
			</Page>
		);
	}

}

const mapDispatchToProps = dispatch => ({
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Attendance));