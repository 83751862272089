const style = theme => ({

	dayStyle: {
		padding: "4px",
		fontFamily: theme.typography.fontFamily,
		fontWeight: "bold",
	}

});

export default style;