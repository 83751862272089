import React from "react";
import { withRouter } from "react-router-dom";
import { DLTableRow, DLTableColumn, EmployeeAvatar } from "@/components";
import _ from 'lodash';
import NoRecord from '../../assets/icons/no-use-bg.svg';

class MonthlyAttendanceSummaryRow extends DLTableRow {

    columnExtra = () => {
        const { data } = this.props;

        var records = data.records
        if (records && records.length < 31) {
            var skip = 31 - records.length;
            for (var i = 0; i < skip; i++) {
                return (<DLTableColumn  colSpan={skip}
                    key={`Attendance-${records.date}-Column-${i}`}
                    align={"center"}
                    withSeparator={true}
                    style={{
                        backgroundImage: `url(${NoRecord})`
                    }}>
                </DLTableColumn>)
            }
        }
    }


    renderCells() {
        const { data, classes } = this.props;

        return [
            <DLTableColumn
           stickyHeader={true}
                key={`Attendance-${data.employee_id}-Column-1`}
                withSeparator={true}>
                <EmployeeAvatar data={{
                    employee: {
                        id: data.employee_id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        code: data.code
                    }
                }} classes={classes} />
            </DLTableColumn>,
            data.records.map((record, i) => {

                return (
                    <DLTableColumn
                        key={`Attendance-${record.date}-Column-${i}`}
                        align={"center"}
                        withSeparator={true}
                        style={{
                            padding: "15px",
                            backgroundColor: (record.status == "H" ? "#FF7300" :null),
                            backgroundImage: record.status == " " ? `url(${NoRecord})` : null,
                            color: record.status == "H" ? "white" 
                                : 
                                (record.status == "A" ? "#281B1B"
                                    :
                                    (record.status == "P" ? "#00D454"
                                        :
                                        (record.status == "LT" ? "#FABD60"
                                            :
                                            (record.status == "HD" ? "#00C7D4"
                                                :
                                                "#64182A")))
                                ),
                        }}>
                        {record.status}

                    </DLTableColumn>
                )
            }),
            this.columnExtra(),
            <DLTableColumn
                key={`Attendance-${data.month}-Column-36`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.hours}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-37`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.days}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-38`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.total_present}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-39`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.total_absents}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-40`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.total_leaves}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-41`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.total_late}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-42`}
                align={"center"}
                isCompact={true}
                withSeparator={true}>
                {data.total_half_day}
            </DLTableColumn>,
            <DLTableColumn
                key={`Attendance-${data.month}-Column-43`}
                align={"center"}
                isCompact={true}
                withSeparator={false}>
                {data.total_holiday}
            </DLTableColumn>,
        ];
    }
}

MonthlyAttendanceSummaryRow.Columns = props => {

    var dataColumnsArray = Array.from(Array(31), (_, i) => i + 1)
    var dateColumns = {}
    var columns = [
      
        {
            key: 'employee.first_name',
            label: 'Employee',
            align: 'left',
            isSortable: false,
            stickyHeader:true,
            withSeparator: true

        }
        ]
    dateColumns = _.map(dataColumnsArray, (label) => {
        return {
            label: label,
            align: "center",
            withSeparator: true
        }
    })
    var columnDate = [
        {
            key: "total_hours",
            label: "Total Hours",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "total_days",
            label: "Working Days",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "present",
            label: "Presence",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },

        {
            key: "absent",
            label: "Absence",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "leaves",
            label: "Leaves",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "late",
            label: "Lates",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "halfDay",
            label: "Half Days",
            align: "center",
            isSortable: false,
            withSeparator: true,
        },
        {
            key: "holidays",
            label: "Holidays",
            align: "center",
            isSortable: false,

        }
    ]
    columns.push(...dateColumns, ...columnDate)
    return columns

}
export default withRouter(MonthlyAttendanceSummaryRow)