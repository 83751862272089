import React, { Component } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
// import classNames from "classnames";
import { Card, FormGroup, FormControl, TextField, CardHeader, CardContent, CardActions, Button, Grid, MenuItem, InputLabel, InputAdornment } from "@material-ui/core";
// import { ImagePicker, EmployeeDropdown } from "@/components";
import { EmployeeDropdown, UserRole, FormattedNumericInput } from "@/components";
import { Api, HttpErrorHandler } from "@/helpers";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import styles from "./style";
import _ from "lodash";
import { Permissions } from "@/config/Permissions";

class LetterheadSettings extends Component {

    state = {
        errors: {},
        settings: {}
    };

    settings() {
        const page_size      = _.find(this.props.option, ["key", "letterhead_setting_page_size"]);
        const margin_top     = _.find(this.props.option, ["key", "letterhead_setting_margin_top"]);
        const margin_bottom  = _.find(this.props.option, ["key", "letterhead_setting_margin_bottom"]);
        const margin_left    = _.find(this.props.option, ["key", "letterhead_setting_margin_left"]);
        const margin_right   = _.find(this.props.option, ["key", "letterhead_setting_margin_right"]);
        const signatory      = _.find(this.props.option, ["key", "letterhead_setting_signatory"]);

        const option_object = {
            page_size: page_size.value,
            margin_top: margin_top.value,
            margin_bottom: margin_bottom.value,
            margin_left: margin_left.value,
            margin_right: margin_right.value,
            signatory: signatory ? signatory.value : null
        }

        return {
            ...option_object,
            ...this.state.settings
        };
    };

    handleChange(value, field) {
        const { errors, settings } = this.state;

        if (errors[field]) {
            errors[field] = false;
        }

        this.setState({
            settings: {
                ...settings,
                [field]: value
            },
            errors
        });
    }

    letterhead = null;
    imageChangeHandler = (e) => {
        this.letterhead = e ? e.target.files[0] : null;
    };

    validate() {
        const settings = this.settings();
        const optionalFields = ["signatory", "check_in", "check_out", "late"];
        var errors = {};

        _.forOwn(settings, (value, key) => {
            if (optionalFields.indexOf(key) === -1 && !value) {
                errors[key] = `${_.startCase(key)} field is required`;
            }
        });

        if (_.size(errors)) {
            this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
        }
        this.setState({ errors });
        console.log("LetterheadSettings -> validate -> errors", errors)

        return !_.size(errors);
    }

    save = async (e) => {
        e.preventDefault();
        if (!this.validate()) return;

        const settings = this.settings();

        // Preparing data for option api call
        var records = [
            { key: "letterhead_setting_page_size", value: settings.page_size},
            { key: "letterhead_setting_margin_top", value: settings.margin_top},
            { key: "letterhead_setting_margin_bottom", value: settings.margin_bottom},
            { key: "letterhead_setting_margin_left", value: settings.margin_left},
            { key: "letterhead_setting_margin_right", value: settings.margin_right},
            { key: "letterhead_setting_signatory", value: settings.signatory}
        ]

        const data = new FormData();
        _.forOwn(settings, (value, key) => {
            // Ignored keys
            if (["is_archived", "deleted_at", "created_at", "updated_at"].indexOf(key) !== -1) return;

            // Send null values as empty string
            if (_.isNil(value)) {
                value = "";
            }
            data.append(key, value);
        });

        // Append letterhead image (if changed)
        if (this.letterhead) {
            data.append("letterhead", this.letterhead);
        }

        this.props.setLoader(true);
        try {
            data.append("_method", "put");
            // Storing in option table
            await Api.options.post({
                group: "letterhead",
                records: records,
            });

            const organization = await Api.organizations(this.props.organization.id).post(data);
            this.props.onUpdate(organization);
            this.props.setSnackbar(true, "Letterhead settings saved successfully");

        } catch (e) {
            HttpErrorHandler.intercept(e)
                .on(422, response => {
                    this.setState({ errors: _.mapValues(response.data, value => value[0]) })
                    this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
                })
                .default(_ => this.props.setSnackbar(true, "Something went wrong, please try again later", "error"))
                .go();
        }
        this.props.setLoader(false);
    };

    render() {
        const { errors } = this.state;
        const { classes } = this.props;
        const settings = this.settings();
        return (
            <Card raised>
                <CardHeader title="Letterhead Settings" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.flex}>
                            <Grid container spacing={2}>

                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Page Size</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup className={classes.formField}>
                                        <FormControl>
                                            <TextField
                                                select
                                                placeholder="Page Size"
                                                className={classes.textField}
                                                variant="outlined"
                                                error={!!errors.page_size}
                                                helperText={errors.page_size}
                                                FormHelperTextProps={{ variant: "standard" }}
                                                value={settings.page_size || "Letter"}
                                                onChange={e => this.handleChange(e.target.value, "page_size")}
                                            >
                                                <MenuItem value="a3">A3</MenuItem>
                                                <MenuItem value="a4">A4</MenuItem>
                                                <MenuItem value="letter">Letter</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Margin Top (in)</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup className={classes.formField}>
                                        <FormControl>
                                            <TextField
                                                placeholder="Margin Top (in)"
                                                error={!!errors.margin_top}
                                                helperText={errors.margin_top}
                                                FormHelperTextProps={{ variant: "standard" }}
                                                value={settings.margin_top || ""}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                                    inputComponent: FormattedNumericInput
                                                }}
                                                onChange={e => this.handleChange(e.currentTarget.value, "margin_top")}
                                                className={classes.textField}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Margin Bottom (in)</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup className={classes.formField}>
                                        <FormControl>
                                            <TextField
                                                placeholder="Margin Bottom (in)"
                                                error={!!errors.margin_bottom}
                                                helperText={errors.margin_bottom}
                                                FormHelperTextProps={{ variant: "standard" }}
                                                value={settings.margin_bottom || ""}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                                    inputComponent: FormattedNumericInput
                                                }}
                                                onChange={e => this.handleChange(e.currentTarget.value, "margin_bottom")}
                                                className={classes.textField}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Margin Left (in)</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup className={classes.formField}>
                                        <FormControl>
                                            <TextField
                                                placeholder="Margin Left (in)"
                                                error={!!errors.margin_left}
                                                helperText={errors.margin_left}
                                                FormHelperTextProps={{ variant: "standard" }}
                                                value={settings.margin_left || ""}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                                    inputComponent: FormattedNumericInput
                                                }}
                                                onChange={e => this.handleChange(e.currentTarget.value, "margin_left")}
                                                className={classes.textField}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Margin Right (in)</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup className={classes.formField}>
                                        <FormControl>
                                            <TextField
                                                placeholder="Margin Right (in)"
                                                error={!!errors.margin_right}
                                                helperText={errors.margin_right}
                                                FormHelperTextProps={{ variant: "standard" }}
                                                value={settings.margin_right || ""}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                                    inputComponent: FormattedNumericInput
                                                }}
                                                onChange={e => this.handleChange(e.currentTarget.value, "margin_right")}
                                                className={classes.textField}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3} className={classes.labelGrid}>
                                    <InputLabel>Signatory</InputLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <EmployeeDropdown
                                        label={settings.signatory ? "" : "Signatory"}
                                        placeholder="Signatory"
                                        onChange={signatory => this.handleChange(signatory, "signatory")}
                                        value={settings.signatory}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
				<UserRole routePermissions={[ Permissions.ORGANIZATION_EDITABLE, Permissions.ORGANIZATION_FULL_ACCESS, Permissions.ORGANIZATIONS_FULL_ACCESS ]}>
                    <CardActions className={classes.cardActionsRight}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={this.save}>
                            Save
                        </Button>
                        <Button
                            size="small"
                            onClick={() => this.setState({ settings: {}, errors: {} })}>
                            Reset
                        </Button>
                    </CardActions>
                </UserRole>
            </Card>
        );
    }

}

LetterheadSettings.defaultProps = {
    onUpdate: () => { }
};

LetterheadSettings.propTypes = {
    organization: Proptypes.object.isRequired,
    onUpdate: Proptypes.func
};

const mapDispatchToProps = dispatch => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(LetterheadSettings));