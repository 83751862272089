import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Tooltip, IconButton } from "@material-ui/core";
import { ArchiveOutlined, UnarchiveOutlined, EditOutlined } from "@material-ui/icons";
import { DLTableRow, DLTableColumn } from "@/components";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import store from "@/redux";
import { setSnackbar } from "@/redux/actions/general";
import Api from "@/helpers/Api";
class BankTableRow extends DLTableRow {

	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		}
	}

	archive = async () => {
		this.setState({ isModalOpen: false });

		const { data } = this.props;
		try {
			await Api.banks(data.id).delete()
			store.dispatch(setSnackbar(true, `"${data.name}" archived successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	restore = async () => {
		this.setState({ isModalOpen: false });
		
		const { data } = this.props;
		try {
			await Api.banks(data.id).restore.patch();
			store.dispatch(setSnackbar(true, `"${data.name}" restored successfully`));
			this.props.reload();

		} catch (e) {
			console.error(e);
			store.dispatch(setSnackbar(true, "Something went wrong, please try again later", "error"));
		}
	};

	renderCells() {
		const { data } = this.props;
		return [
			<DLTableColumn
				key={`Bank-${data.id}-Column-1`}
				withSeparator={true}>
				<strong>{data.name}</strong>
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-2`}
				align={"left"}>
				{data.code}
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-3`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				{
					!data.is_archived &&
					<Tooltip title="Edit Bank">
						<Link to={{ pathname: `/settings/banks/${data.id}` }} style={{ textDecoration: 'none', color: "black" }}>
							<IconButton
								onClick={() => this.props.history.push(`/settings/banks/${data.id}`)}
								size="small"
								color="primary">
								<EditOutlined />
							</IconButton>
						</Link>
					</Tooltip>
				}
			</DLTableColumn>,
			<DLTableColumn
				key={`Bank-${data.id}-Column-4`}
				isCompact={true}
				align={"center"}
				fillWidth={35}>
				{
					!data.is_archived ? (
						<>
							<Tooltip title="Archive Bank">
								<IconButton
									onClick={() => this.setState({ isModalOpen: true })}
									size="small"
									color="secondary">
									<ArchiveOutlined />
								</IconButton>
							</Tooltip>

							<ConfirmModal
								title="Archive Bank"
								isOpen={this.state.isModalOpen}
								message={`Are you sure you want to archive ${data.name}?`}
								onClickCancel={() => {
									this.setState({ isModalOpen: false });
								}}
								onClickConfirm={this.archive} />
						</>
					) : (
						<>
							<Tooltip title="Restore Bank">
								<IconButton
									onClick={() => this.setState({ isModalOpen: true })}
									size="small"
									color="primary">
									<UnarchiveOutlined />
								</IconButton>
							</Tooltip>

							<ConfirmModal
								title="Restore Bank"
								isOpen={this.state.isModalOpen}
								message={`Are you sure you want to restore ${data.name}?`}
								onClickCancel={() => {
									this.setState({ isModalOpen: false });
								}}
								onClickConfirm={this.restore} />
						</>
					)
				}
			</DLTableColumn>
		]
	}

}

BankTableRow.Columns = [
	{
		key: "name",
		label: "Name",
		align: "left",
		isSortable: true,
		withSeparator: true
	},
	{
		key: "code",
		label: "Code",
		align: "left",
		isSortable: true,
		withSeparator: false
	},
	{
		key: null,
		label: null,
		align: "right",
		isSortable: false,
		withSeparator: false,
		isCompact: true,
		colSpan: 2
	}
];

export default withRouter(BankTableRow);