import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography, Tooltip, Icon } from "@material-ui/core";
import { InfographicMessage, Money } from "@/components";
import { currencyFormatter } from "@/helpers/generalHelper";
import Constants from "@/config";
import classNames from "classnames";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import Chart from "chart.js";
import styles from "./style";
import moment from "moment";
import _ from "lodash";
import Modules from "@/config/Modules";
import { AccessManager }  from "@/helpers/";
import { AuthContext } from "@/contexts";

Chart.plugins.register([ChartAnnotation]); // Global

class PayrunChart extends React.Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			target: null,
			to: null
		};
	}
	chartRef = React.createRef();


	componentDidUpdate() {
		if(this.state.to === null) {
			this.chart();
		}
	}

	chart = async () => {
		if (!this.chartRef.current) { return; }
		const { theme } = this.props;
		let payrunData = await this.mapPayrunData(); 

		const myChartRef = this.chartRef.current.getContext("2d");
		new Chart(myChartRef, {
			type: "line",
			data: {
				labels: payrunData.labels,
				datasets: [{
					label: "",
					data: payrunData.data,
					backgroundColor: "rgba(255, 255, 255, 0.25)",
					borderColor: "#fff",
					borderWidth: 2,
					pointBackgroundColor: theme.palette.success.main,
					pointRadius: 5,
					pointBorderColor: "#fff"
				}]
			},
			options: {
				annotation: {
					drawTime: "afterDatasetsDraw",
					events: ["click"],
					dblClickSpeed: 1000,
		
					annotations: [{
						drawTime: "afterDraw",
						id: "a-line-1",
						type: "line",
						mode: "horizontal",
						scaleID: "y-axis-0",
						value: this.props.stats.averagePayrunAmount,
						borderColor: "#fff",
						borderWidth: 1,
						borderDashOffset: 50
					}]
				},
				tooltips: {
					enabled: false,
					custom: this.customTooltip
				},
				legend: {
					display: false
				},
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							padding: 10,
							fontColor: "#ffffff",
							callback: label => currencyFormatter(label, false)
						},
						gridLines: {
							drawTicks: false,
							display: true,
							drawBorder: true,
							zeroLineColor: "rgba(255, 255, 255, 0.2)",
							color: [
								null, 
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
								"rgba(255, 255, 255, 0.2)", 
								"rgba(255, 255, 255, 0.2)",
							]
						}
					}],
					xAxes: [{
						ticks: {
							sampleSize: 100,
							beginAtZero: true,
							padding: 10,
							fontColor: "#fff",
							minRotation: 60
						},
						gridLines: {
							drawTicks: false,
							display: true,
							color: [
								"#fff", 
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null,
								null
							],
						}
					}]
				}
			}
		});
	}

	mapPayrunData = () => {
		const { stats } = this.props;
		var data = [];
		var labels = [];

		stats.payrunData.forEach((payrun) => {
			labels.push(moment(payrun.to).format("MMM YY"));
			data.push(payrun.amount);
		});
		
		if(stats.payrunData.length === 13) {
			this.setState({ to: moment((stats.payrunData[stats.payrunData.length - 1]).to).endOf("month").format("DD/MM/Y") })
		}

		var lastData = stats.payrunData[stats.payrunData.length - 1];
		for(var x = data.length; x < 13; x++) {
			let date = moment(lastData.to).add(1, "M");
			labels.push(moment(date).format("MMM YY"));
			lastData = {to: date};
			if(x === 12) {
				this.setState({ to: moment(date).endOf("month").format("DD/MM/Y") })
			}
		}
		return {data, labels};
	};

	customTooltip(tooltipModel) {
		var tooltipEl = document.getElementById("chartjs-tooltip");

		if (!tooltipEl) {
			tooltipEl = document.createElement("div");
			tooltipEl.id = "chartjs-tooltip";
			tooltipEl.innerHTML = "<table></table>";
			document.body.appendChild(tooltipEl);
		}

		// Hide if no tooltip
		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}

		// Set caret Position
		tooltipEl.classList.remove("above", "below", "no-transform");
		if (tooltipModel.yAlign) {
			tooltipEl.classList.add(tooltipModel.yAlign);
		} else {
			tooltipEl.classList.add("no-transform");
		}

		function getBody(bodyItem) {
			return bodyItem.lines;
		}

		if (tooltipModel.body) {
			var titleLines = tooltipModel.title || [];
			var bodyLines = tooltipModel.body.map(getBody);

			var innerHtml = "<thead>";

			titleLines.forEach(function (title) {
				innerHtml += `<tr><th style="color: #fff">${moment(title, "MMM YY").format("MMMM Y")}</th></tr>`;
			});
			innerHtml += "</thead><tbody>";
			bodyLines.forEach(function (body, i) {
				let money = currencyFormatter(body, false).split(".");
				const pickNumber = money[1].match(/\d+/g);
				const pickAmountIn = money[1].split(pickNumber)[1].length > 0 ? money[1].split(pickNumber)[1][0] : "";
				var colors = tooltipModel.labelColors[i];
				var style = "background:" + colors.backgroundColor;
				style += "; border-color:" + colors.borderColor;
				style += "; border-width: 2px";
				var span = `<span style="${style}, color: #fff"></span>`;
				innerHtml += `<tr><td style="color: #fff">${span}
					<span style="font-size: 12px; font-weight: normal">${Constants.CURRENCY_SYMBOL}.</span>
					<span style="font-size: 16px; font-weight: bold">${money[0]}</span>
					<sup  style="font-size: 12px; font-weight: normal">.${pickNumber}</sup>
					<span style="font-size: 16px; font-weight: bold">${pickAmountIn}</span>`;
				innerHtml += body < 0 ? `<span style="font-size: 16px; font-weight: bold">)</span>` : "";
				innerHtml += `</td></tr>`;
			});
			innerHtml += "</tbody>";

			var tableRoot = tooltipEl.querySelector("table");
			tableRoot.innerHTML = innerHtml;
		}
		
		var position = this._chart.canvas.getBoundingClientRect();

		tooltipEl.style.opacity = 1;
		tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
		tooltipEl.style.position = "absolute";
		tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
		tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
		tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
		tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
		tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
		tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
		tooltipEl.style.pointerEvents = "none";
	}

	addPayrun = () => {
		this.props.history.push("/payroll/run");
	};

	emptyView() {
		const { classes } = this.props;
		return (
			<div className={classes.infographicMessage}>
				<InfographicMessage
					illustration="payroll"
					message="You haven't run Payroll yet, run your first payroll now!"
					actionLabel="Run Payroll"
					actionIcon="add"
					actionHandler={this.addPayrun}
				/>
			</div>
		);
	}

	render() {
		const { classes, stats } = this.props;
		const { to } = this.state;
		const minPayroll = _.minBy(_.filter(stats.payrunData, (i) => !!i.id ), (o) =>  o.amount  ) || 0;
		const maxPayroll = _.maxBy(stats.payrunData, (o) => o.amount ) || 0;
		const { payrunData } = stats;
		return (
			AccessManager.isModuleAccess(Modules.PAYROLL, this.context.organization) &&
			<div className={classNames(classes.root, stats.payrunData.length !== 0 && classes.chartBackground)}>
				<div className={classes.chartHeadingContainer}>
					<Typography className={classes.chartHeading}>
						Payroll Growth  
						{
							stats.payrunData.length > 0 && 
							<Tooltip title={moment(payrunData[0].from).format("01/MM/Y") + " to " + to} aria-label={"Add"} placement={"right"}>
								<Icon className={classes.infoIcon}>info</Icon>
							</Tooltip>
						}
						<span className={classes.chartSubtitle}>For the period of last 13 months</span>
					</Typography>
					<div className={classes.chartStatsContianer}>
						<div className={classes.chartStatsInnerContianer}>
							<div>
								<Typography className={classes.chartStatsValue} variant="h5"><Money amount={minPayroll.amount} /></Typography>
								<Typography className={classes.chartStatsHeading}>MIN</Typography>
							</div>
						</div>
						<div className={classes.chartStatsInnerContianer}>
							<div>
								<Typography className={classes.chartStatsValue} variant="h5"><Money amount={maxPayroll.amount} /></Typography>
								<Typography className={classes.chartStatsHeading}>MAX</Typography>
							</div>
						</div>
						<div className={classes.chartStatsInnerContianer}>
							<div>
								<Typography className={classes.chartStatsValue} variant="h5" ><Money amount={stats.averagePayrunAmount} /></Typography>
								<Typography className={classes.chartStatsHeading}>AVERAGE</Typography>
							</div>
						</div>
					</div>
				</div>
				<div style={{ paddingTop: 16, paddingBottom: 16, paddingRight: 8, position: "relative"}}>
					{
						stats.payrunData.length > 0 ? (
							<canvas
								id="myChart"
								ref={this.chartRef}
							/> 
						) : (
							this.emptyView()
						)
					}
				</div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(withRouter(PayrunChart));