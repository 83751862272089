import moment from "moment";
import styles from "./style";
import Config from "@/config";
import { Api } from "@/helpers";
import { connect } from "react-redux";
import FusionCharts from "fusioncharts";
import ReactFC from "react-fusioncharts";
import React, { Component } from "react";
import { DatePicker } from "@material-ui/pickers";
import { withRouter, Link } from "react-router-dom";
import chart from "fusioncharts/fusioncharts.charts";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActions } from "@material-ui/core";
import { setLoader, setSnackbar } from "@/redux/actions/general";
import BranchDropdown from "@/components/Dropdowns/BranchDropdown";
import { BackButton, Page, InfographicMessage } from "@/components";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import DepartmentDropdown from "@/components/Dropdowns/DepartmentDropdown";
import DesignationDropdown from "@/components/Dropdowns/DesignationDropdown";

ReactFC.fcRoot(FusionCharts, chart, FusionTheme);
FusionCharts.options.creditLabel = false;

class DailyAttendanceStatus extends Component {

    constructor (props) {
        super(props);
        this.state = {
            date: null,
            department: null,
            designation: null,
            branch: null,
            data: {
                absent: 0,
                present: 0,
                total: 0,
                holiday: 0,
                total_leaves: 0,
                total_late: 0,
                total_half_day: 0,
                check_in: 0,
                check_out: 0,
                yet_check_in: 0
            }
        };
    }

    makeApiCall = async () => {
        try {
            this.props.setLoader(true);
            const { date, department, designation, branch } = this.state;
            const response = await Api.reports.daily_attendance_status.get({
                date: moment(date || moment()).format(Config.SERVER_DATE_FORMAT),
                department: department || undefined,
                designation: designation || undefined,
                branch: branch || undefined
            });
            this.setState({
                data: response.data
            });
            this.props.setLoader(false);
        } catch (e) {
            this.props.setLoader(false);
            this.props.history.replace("/reports");
            this.props.setSnackbar(
                true,
                "Unable to load attendance detail",
                "error"
            );
        }
    }

    getRecords = () => {
        this.makeApiCall()
    }

    componentDidMount() {
        this.makeApiCall()
    }

    render() {
        const { classes } = this.props;
        const { date, department, designation, branch, data } = this.state;
        return (
            <Page title="Daily Attendance Status">
                <Link to={{ pathname: `/reports` }} style={{ textDecoration: 'none', color: "primary" }}>
                    <BackButton route="/reports" label="Back to Reports" />
                </Link>
                <Card className={classes.filter} >
                    <CardActions className={classes.taxReportActions}>
                        <Grid container spacing={1}>
                            <Grid item xs={3} md={2}>
                                <DatePicker
                                    fullWidth
                                    margin="dense"
                                    maxDate={moment().format("YYYY-MM-DD")}
                                    label="Select Date"
                                    inputVariant="outlined"
                                    format={Config.DISPLAY_DATE_FORMAT}
                                    value={date || moment().format(Config.SERVER_DATE_FORMAT)}
                                    onChange={date => this.setState({ date }, this.getRecords)}
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <BranchDropdown
                                    textFieldProps={{ margin: "dense" }}
                                    value={branch}
                                    onChange={branch => this.setState({ branch }, this.getRecords)}
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DepartmentDropdown
                                    textFieldProps={{ margin: "dense" }}
                                    value={department}
                                    onChange={department => this.setState({ department }, this.getRecords)}
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DesignationDropdown
                                    textFieldProps={{ margin: "dense", size: "small" }}
                                    value={designation}
                                    onChange={designation => this.setState({ designation }, this.getRecords)}
                                />
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
                {
                    data.total !== 0
                        ?
                        <div className={classes.attendanceWidget}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Card>
                                        <CardActions className={classes.contentCenter}>
                                            <Grid container>
                                                <Grid item xs={10} className={classes.alignCenter}>
                                                {
                                                    <ReactFC {...{
                                                        type: 'pie2d',
                                                        renderAt: 'chartContainer',
                                                        width: '65%',
                                                        height: '69%',
                                                        dataFormat: 'json',
                                                        dataSource: {
                                                            "chart": {
                                                                "caption": "Employees Attendance Summary",
                                                                "subCaption": moment(date || moment()).format("MMMM Do YYYY"),
                                                                "paletteColors": "#00D454, #FF7300, #64182A, #FABD60, #281B1B, #00C7D4",
                                                                "showPercentInTooltip": "0",
                                                                "showPercentValues": "0",
                                                                "startingAngle": "20",
                                                                "showLegend": "1",
                                                                "theme": "fusion",
                                                                "labelFontBold": "1",
                                                                "showLabels": "1",
                                                                "showValues": "1",
                                                                "labelSepChar": " ",
                                                                "chartLeftMargin": "0",
                                                                "chartTopMargin": "0",
                                                                "chartRightMargin": "0",
                                                                "chartBottomMargin": "0",
                                                                "showPlotBorder": "1",
                                                                "plotBorderColor": "#FFFFFF",
                                                                "plotBorderThickness": "2"
                                                            },
                                                            "data": [
                                                                {
                                                                    "label": "Present",
                                                                    "value": data.present
                                                                },
                                                                {
                                                                    "label": "Holiday",
                                                                    "value": data.holiday
                                                                },
                                                                {
                                                                    "label": "Leave",
                                                                    "value": data.total_leaves
                                                                },
                                                                {
                                                                    "label": "Late",
                                                                    "value": data.total_late
                                                                },
                                                                {
                                                                    "label": "Absent",
                                                                    "value": data.absent
                                                                },
                                                                {
                                                                    "label": "Half Day",
                                                                    "value": data.total_half_day
                                                                }
                                                            ]
                                                        }
                                                    }} />
                                                }
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div id="option-label" className={classes.totalEmployees}>
                                                        <text>Total Employees: {data.total}</text>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Card className={classes.pieChart}>
                                        <CardActions className={classes.contentCenter}>
                                            {
                                                <ReactFC {...{
                                                    type: 'doughnut2d',
                                                    renderAt: 'chartContainer',
                                                    width: '35%',
                                                    dataFormat: 'json',
                                                    dataSource: {
                                                        "chart": {
                                                            "caption": "Current Day Status",
                                                            "subCaption": moment().format("MMMM Do YYYY"),
                                                            "paletteColors": "#2ED871, #50ACFA , #FABD60,",
                                                            "showPercentInTooltip": "0",
                                                            "showPercentValues": "0",
                                                            "startingAngle": "0",
                                                            "showLegend": "1",
                                                            "theme": "fusion",
                                                            "labelFontBold": "1",
                                                            "showLabels": "1",
                                                            "showValues": "1",
                                                            "labelSepChar": " "
                                                        },
                                                        "data": [{
                                                            "label": "Check In",
                                                            "value": data.check_in
                                                        },
                                                        {
                                                            "label": "Check Out",
                                                            "value": data.check_out
                                                        },
                                                        {
                                                            "label": "Yet to check in",
                                                            "value": data.yet_check_in
                                                        },
                                                        ]
                                                    }
                                                }} />
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <div className={classes.contentCenter}>
                            <InfographicMessage
                                illustration="AttendanceStatus"
                                message="No attendance records found!"
                            />
                        </div>
                }
            </Page>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
    setLoader: (...args) => dispatch(setLoader(...args))
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(withStyles(styles)(DailyAttendanceStatus)));