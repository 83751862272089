import Types from "../Filter/types";

export default [
    {
        key: "date",
        label: "Date",
        type: Types.date
    },
	{
		key: "amount",
		label: "Amount",
		type: Types.number
	},
	{
		key: "message",
		label: "Comment",
		type: Types.string
	}
];