import React from "react";
import { connect } from "react-redux";
import { setTitle } from "@/redux/actions/general";

class Page extends React.Component {

    render() {
        const { title, setTitle, children } = this.props;
        
        document.title = `${title} - Al-Burhan Attendance`;
        setTitle(title);

        return (
            <>
                {children}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setTitle: (...args) => dispatch(setTitle(...args))
});

export default connect(null, mapDispatchToProps)(Page);