import { blue, green, orange, red } from '@material-ui/core/colors';
import { createTheme  } from '@material-ui/core/styles';

const theme = createTheme ({
  status: {
    // My business variables
  },
  palette: {
    primary: {
      main: "#2F80ED",
      background: blue['50'],
    },
    danger : {
      main : "#D73625",
      background: red['50']
    },
    warning: {
      main: orange['500'],
      background: orange['50']
    },
    success: {
      main: green['500'],
      background: green['50']
    },
    borderColor: {
      main: '#E0E0E0'
    },
    background: {
      main: '#fff',
    },
    tableBorder: {
      main: '#b8b8b8'
    },
    label: {
      main: '#888888',
    },
    statusLabelIcon: {
      main: '#666' 
    },
    removeItem: {
      main: "#E20613"
    },
    extraImage: {
      main: "#C6E5FF"
    }
  },
  color:{
    blue:{
      dodger:"#2A9DFF",
      lilyWhite:"#ebf8fe"
    },
    black:{
      main:"#000",
    },
    silver:{
      main:"#BCBCBC",
    },
    white:{
      main:"#ffff",
      zumthor:"#e7f1ff",
    },
    red:{
      pomegranate:"#FA4322",
      persianRed:"#D32F2F"
    },
    gray:{
      main:"#888888"
    },
    orange:{
      warningOpacity:"rgb(250, 178, 57, 0.2)",
      warning:"rgb(255, 160, 0)"
    }
  },
  payslip: {
    headerColor: '#F8F8F8',
    bodyColor: '#e7f1ff'
  },
  typography: { 
    useNextVariants: true,
    fontFamily: [
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

export default theme