import React, { Component } from "react";
import { Button, Icon, Menu, MenuItem } from "@material-ui/core";
import _ from "lodash";

class BulkAction extends Component {

    state = { anchorEl: null, selected: [], activeOption: null };

    setSelected(selected) {
        this.setState({ selected });
    }

    render() {
        const { options = [] } = this.props;
        const { anchorEl, selected, activeOption } = this.state;
        return (
            <>
                {
                    options.length === 1 && !!selected.length  ? 
                    (
                        <Button
                            variant="outlined"
                            size="medium"
                            color={options[0].color}
                            onClick={() => this.setState(
                                { activeOption: 0 },
                                _.isFunction(options[0].action) && options[0].action(selected, this)
                            )}>
                            {options[0].name}
                        </Button>
                    ) : (
                        !!selected.length && <>
                            <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                onClick={e => this.setState({ anchorEl: e.currentTarget })}>
                                Bulk Action
                                <Icon>keyboard_arrow_down</Icon>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({ anchorEl: null })}
                                PaperProps={{ style: { minWidth: 200 } }}>
                                {
                                    options.map((option, index) => (
                                        <>
                                            <MenuItem
                                                onClick={() => this.setState(
                                                    { anchorEl: null, activeOption: index },
                                                    _.isFunction(option.action) && option.action(selected, this)
                                                )}>
                                                {option.name}
                                            </MenuItem>
                                        </>
                                    ))
                                }
                            </Menu>
                        </>
                    )
                }
                {
                    activeOption >= 0 &&
                    options[activeOption] &&
                    _.isFunction(options[activeOption].render) &&
                    options[activeOption].render(selected, this)
                }
            </>
        );
    }

}

export default BulkAction;