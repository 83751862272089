import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RoleDropdown from "@/components/Dropdowns/RoleDropdown"
import { Grid, TextField, Button, Switch, InputLabel, CardContent } from "@material-ui/core";
import { setSnackbar, setLoader } from "@/redux/actions/general";
import { Api, HttpErrorHandler, AccessManager } from "@/helpers";
import styles from "./style";
import _ from "lodash";
import ModuleManager from "@/components/ModuleManager";

import { UserRole } from "@/components";
import { Permissions, PermissionsInfo } from "@/config/Permissions";
import Modules from "@/config/Modules";
import { AuthContext } from "@/contexts"; 

class EmployeeAccount extends Component {
	static contextType = AuthContext;

	static propTypes = {
		employee: PropTypes.object,
		onUpdate: PropTypes.func
	};

	static defaultProps = {
		employee: {},
		onUpdate: () => { }
	};

	constructor(props) {
		super(props);
		this.state = {
			data: {
				pin: "",
				password: "",
				confirm_password: "",
				role: (props.employee.role && props.employee.role.id) || null,
				inactive: this.props.employee.is_inactive,
				is_geofencing:  this.props.employee.is_geofencing
			},
			roles: {},
			permission_names: [],
			errors: {}
		};
	}

	componentDidMount() {
		this.getRoles();
	}

	getRoles = async () => {
		try {
			const roles = await Api.roles.get();
			this.setState({ roles }, () => this.getPermissionNames());

		} catch (error) { }
	}

	getPermissionNames = async () => {
		const { roles, data } = this.state;

		if (data.role && roles.data) {
			let permission_names = [];

			const roleData = roles.data.filter(obj => obj.id === data.role);

			const rolePermissions = roleData.permissions;

			_.map(rolePermissions, (permission) => {
				_.find(PermissionsInfo, (p, key) => {
					_.map(p, (item) => {
						if (item.value === permission.name) {
							permission_names.push(item.value)
						}
					})

				})
			});
			this.setState({ permission_names });
		}
	}

	handleChange(field, value) {
		this.setState({
			data: {
				...this.state.data,
				[field]: value,
			},
			errors: {
				...this.state.errors,
				[field]: false
			}
		}, () => this.getPermissionNames());
	}

	updateAccount = async () => {
		const { employee, onUpdate } = this.props;
		const { data } = this.state;

		this.props.setLoader(true);
		try {
			const _employee = await Api.employees(employee.id).put({
				...data,
				pin: data.pin || undefined,
				password: data.password || undefined,
				confirm_password: data.confirm_password || undefined,
				is_geofencing: data.is_geofencing ? 1 : 0,
				role: data.role || null,

			});
			onUpdate(_employee);
			this.props.setSnackbar(true, "Login information saved successfully");
			this.setState({
				data: {
					...data,
					pin: "",
					password: "",
					confirm_password: "",
				}
			});

		} catch (e) {
			HttpErrorHandler
				.intercept(e)
				.on(400, response => this.props.setSnackbar(true, response.data.message, "error"))
				.on(422, response => {
					this.setState({ errors: _.mapValues(response.data, value => value[0]) })
					this.props.setSnackbar(true, "Please fix the highlighted errors", "error");
				})
				.default(() => this.props.setSnackbar(true, "Unable to save login information, please try again", "error"))
				.go();
		}
		this.props.setLoader(false);
	};

	render() {
		const { classes, employee } = this.props;
		const { data, errors} = this.state;
		const { user } = this.context;
		var disabled = user.id == employee.id ? true : false

		return (
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={8}>
						<Grid container spacing={2}>
							<Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
								<InputLabel className={classes.inputLabel}>Active Account
								</InputLabel>
							</Grid>
							<Grid item xs={7} sm={7} md={6}>
								<Switch
									disabled={disabled}
									checked={!this.state.data.inactive}
									onChange={() => this.handleChange("inactive", !data.inactive)}
									color="primary"
								/>
							</Grid>
							<ModuleManager modulePermissions={[Modules.ATTENDANCE]}>
								<Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
									<InputLabel className={classes.inputLabel}>
										Geofencing
									</InputLabel>
								</Grid>
								<Grid item xs={7} sm={7} md={6}>
									<Switch
										checked={this.state.data.is_geofencing}
										onChange={() => this.handleChange("is_geofencing", !data.is_geofencing)}
										color="primary"
									/>
								</Grid>
							</ModuleManager>
							{/* <Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
								<InputLabel>Attendance kiosk Pin</InputLabel>
							</Grid>
							<Grid item xs={7} sm={7} md={6}>
								<TextField
									placeholder="Pin"
									type="password"
									fullWidth
									error={!!errors.pin}
									helperText={errors.pin}
									FormHelperTextProps={{ variant: "standard" }}
									value={data.pin}
									onChange={(e) => this.handleChange("pin", e.target.value)}
									variant="outlined"
								/>
							</Grid> */}
							{
								AccessManager.hasPermission([Permissions.EMPLOYEES_FULL_ACCESS], this.context.user) &&
									<>
										<Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
											<InputLabel>Password</InputLabel>
										</Grid>
										<Grid item xs={7} sm={7} md={6}>
											<TextField
												placeholder="Password"
												type="password"
												fullWidth
												error={!!errors.password}
												helperText={errors.password}
												FormHelperTextProps={{ variant: "standard" }}
												value={data.password}
												onChange={(e) => this.handleChange("password", e.target.value)}
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
											<InputLabel>
												Confirm Password
											</InputLabel>
										</Grid>
										<Grid item xs={7} sm={7} md={6}>
											<TextField
												type="password"
												placeholder="Confirm Password"
												fullWidth
												error={!!errors.confirm_password}
												helperText={errors.confirm_password}
												FormHelperTextProps={{ variant: "standard" }}
												value={data.confirm_password}
												onChange={(e) => this.handleChange("confirm_password", e.target.value)}
												variant="outlined"
											/>
										</Grid>
									</>
							}
							
							<ModuleManager modulePermissions={[Modules.ROLE_AND_PERMISSION]}>
								<Grid item xs={3} sm={3} md={6} className={classes.labelGrid}>
									<InputLabel>
										Employee Role
									</InputLabel>
								</Grid>
								<Grid item xs={7} sm={7} md={6}>
									{
										AccessManager.hasPermission([
											Permissions.ROLE_FULL_ACCESS,
											Permissions.ROLE_EDITABLE], this.context.user) ?
											<RoleDropdown
												value={data.role}
												onChange={role => {
													if (role) {
														this.handleChange("role", role.id)
													}
													else {
														this.handleChange("role", "" )
													}
												}}/>
										:
											<TextField
												type="text"
												fullWidth
												disabled
												value={employee.role.name}
												variant="outlined"/>
									}
								</Grid>
							</ModuleManager>
							<Grid item xs={3} sm={3} md={6}></Grid>
							<Grid item xs={7} sm={7} md={6}>
								<UserRole routePermissions={[Permissions.EMPLOYEES_FULL_ACCESS]}>
									<Button
										variant="contained"
										color="primary"
										onClick={this.updateAccount}>
										Save
									</Button>
								</UserRole>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					{/* <img
							title="QR Code"
							alt="QR Code"
							className={classes.qrImage}
							src={Api.employees(employee.id).qr.getUrl(true)} /> */}
				</Grid>
			</CardContent>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setSnackbar: (...args) => dispatch(setSnackbar(...args)),
	setLoader: (...args) => dispatch(setLoader(...args))
});

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(EmployeeAccount)));