import React from "react";
import { AuthContext } from "@/contexts";
import _ from "lodash";
import Permissions from "@/config/Permissions";

function PermissionHOC(WrappedComponent) {
    return class extends React.Component {
        static contextType = AuthContext;
       
        hasPermission = (permissions) => {
            return !!_.size(_.intersection(permissions, this.context.user.permission_names));
        }

        render() {
            return <WrappedComponent 
                permissions={Permissions.Permissions}
                permissionsInfo={Permissions.PermissionsInfo}
                hasPermission={this.hasPermission} 
                {...this.props} />
        }
    }
}

export default PermissionHOC;
