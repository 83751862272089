const styles = theme => ({
    containerGrid: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formControl : {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        marginTop: theme.spacing(1)
    },
    labelGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    inputLabel: {
        textAlign: 'end'
    },
    footer: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    createBtn: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    hidden: {
        display: 'none'
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    labelPlacementStart: {
        display: "flex",
        justifyContent: "space-between",
        marginRight:"-11px",
        marginLeft:"5px"
    },
    buttonGroup: {
        marginTop: "5px", 
        display: "flex", 
        maxHeight: "85%"
    }
});

export default styles