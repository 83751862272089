import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { InputAdornment, TextField } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { FormattedNumericInput } from "@/components";
import Config from "@/config";
import styles from "./style.js";
import _ from "lodash";
import { bool } from "prop-types";
import Api from "@/helpers/Api.js";
import { connect } from "react-redux";
import { setSnackbar } from "@/redux/actions/general";
import HttpErrorHandler from "@/helpers/HttpErrorHandler.js";

class LoanTextField extends Component {

    static propTypes = {
        showSign: bool
    }
    
    static defaultProps = {
        showSign: false
    };

    constructor(props) {
        super(props);
        
        this.state = {
            rate: Math.abs(props.payItem.transaction.amount)
        };
    }

    handleChange = (value) => {
        if (this.props.viewOnly) return;
        // check if it is not number e.g. - sign if not then parseFloat it else skip parseFloat
        if (!isNaN(parseFloat(value))) {
            value = parseFloat(value);
        }

        this.setState({ rate: value });
    };

    update = async (value, item) => {
        if (this.props.viewOnly) return;

        const { onUpdate } = this.props;
        this.handleChange(value);
        try {
            const payrun = await Api.payslips(item.payslip_id)
                .payslip_item(item.pay_item_id)
                .post({
                    rate: this.state.rate * (item.transaction.amount / Math.abs(item.transaction.amount)),
                    loan_transaction_id: item.transaction.id,
                    _method: "put"
                });

            _.isFunction(onUpdate) && onUpdate(payrun);
        } catch (e) {
            console.error(e);
            HttpErrorHandler
				.intercept(e)
				.on(422, response => {
                    const errors = _.values(_.mapValues(response.data, value => value[0]));
                    this.props.setSnackbar(true, errors[0], "error");
				})
				.default(() => this.props.setSnackbar(true, `Unable to update ${item.details.name}, please try again`, "error"))
				.go();
        }
    };

    render() {
        const { payItem, showSign, viewOnly } = this.props;
        console.log("-----", payItem)
        return (
            payItem.is_loan &&
            <TextField
                label={payItem.details.name}
                variant="outlined"
                size={"small"}
                defaultValue={(showSign ? payItem.transaction.amount : Math.abs(payItem.transaction.amount)) || 0}
                onChange={(e) => this.handleChange(e.currentTarget.value)}
                onBlur={(e) => this.update(e.currentTarget.value.replace(/[^0-9.-]/,"" ), payItem)}
                FormHelperTextProps={{ variant: "standard" }}
                fullWidth
                disabled={viewOnly || payItem.transaction.amount > 0}
                InputProps={{
                    startAdornment: (<InputAdornment position="start">{Config.CURRENCY_SYMBOL}</InputAdornment>),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Link to={{pathname: `/loans/${payItem.transaction.loan_request_id || payItem.transaction.id}`}} target="_blank">
                                <Launch style={{cursor: "pointer"}} color="primary"/>
                            </Link>
                        </InputAdornment>
                    ),
                    inputComponent: FormattedNumericInput
                }}
            />
        );
    }

}

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (...args) => dispatch(setSnackbar(...args)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoanTextField));
